import {
  CircularProgress,
  Grid,
  Box,
  makeStyles,
  Chip,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BirthdayIcon from 'assets/icons/Birthday.svg';
import { Formik, Field } from 'formik';
import { TextField, Checkbox } from 'formik-material-ui';
import * as Yup from 'yup';
import { Colors } from 'res/Enums';
import {
  diagnosisControllerBeginDiagnosis,
  DiagnosisDTO,
  useDiagnosisControllerGetDiagnosisData,
} from 'gen/client';

interface Diagnosis {
  systolicIsTreated: boolean;
  cholesterol: number;
  hdl: number;
  systolic: number;
  smoker: boolean;
  age: number;
}
export const HeartDiagnosis = () => {
  const { data, isLoading } = useDiagnosisControllerGetDiagnosisData();
  const [diagnosisData, setDiagnosisData] = useState<DiagnosisDTO>();
  const classes = useStyles();
  const { t } = useTranslation();
  const [result, setResult] = useState<string>();
  useEffect(() => {
    if (data) {
      setDiagnosisData(data.data);
    }
  }, [data]);
  const schema = Yup.object().shape({
    hdl: Yup.number().required(),
    systolic: Yup.number().required(),
    cholesterol: Yup.number().required(),
    systolicIsTreated: Yup.boolean().required(),
  });
  if (isLoading || !diagnosisData) {
    return <CircularProgress />;
  }
  return (
    <Grid container>
      <Grid xs={12}>
        <Fragment>
          <Typography style={{ fontSize: 14, marginBottom: 10 }}>
            Για τον έλεγχο του 10-ετούς κινδύνου για καρδιαγγειακές παθήσεις
            χρησιμοποιούμε το Framingham Risk Score, το οποίο είναι ένα από τα
            πολλά συστήματα βαθμολογίας που χρησιμοποιούνται για να
            προσδιορίσουν τις πιθανότητες ενός ατόμου να αναπτύξει
            καρδιαγγειακές παθήσεις. Το σύστημα βαθμολόγησης καρδιαγγειακού
            κινδύνου δίνει μια εκτίμηση της πιθανότητας ότι ένα άτομο θα
            αναπτύξει καρδιαγγειακές παθήσεις εντός καθορισμένου χρονικού
            διαστήματος 10 ετών. Ο υπολογισμός κινδύνου επιτρέπει στους παρόχους
            υγειονομικής περίθαλψης και τους ασθενείς να εκτιμήσουν τους
            κινδύνους 10 ετών και διάρκειας ζωής για αθηροσκληρωτική
            καρδιαγγειακή νόσο (ASCVD), που ορίζεται ως στεφανιαίος θάνατος ή μη
            θανατηφόρο έμφραγμα του μυοκαρδίου, ή θανατηφόρο ή μη θανατηφόρο
            εγκεφαλικό επεισόδιο. Σε περίπτωση αυξημένης πιθανότητας δεν
            σημαίνει ότι υπάρχει άμεσως κίνδυνος, άλλα ότι είναι σκόπιμο να
            συζητήσεις για την υγεία σου με τον ιατρό σου.
          </Typography>
          <Typography
            variant="h6"
            style={{ marginBottom: 20, textAlign: 'center' }}
          >
            Συμπλήρωσε τα παρακάτω στοιχεία για να ενημερωθείς για το επίπεδο
            του Δεκαετούς καρδιαγγειακού κινδύνου.
          </Typography>
          <Box className={classes.column}>
            <Formik
              initialValues={
                { ...diagnosisData, systolicIsTreated: false } as Diagnosis
              }
              validateOnMount
              validationSchema={schema}
              onSubmit={(values: Diagnosis) => {
                diagnosisControllerBeginDiagnosis(values).then((data) =>
                  setResult(data.data.score)
                );
              }}
            >
              {({ submitForm, isValid }) => (
                <Fragment>
                  <Chip
                    style={{
                      marginBottom: 3,
                      backgroundColor: Colors.White,
                    }}
                    avatar={
                      <Avatar>
                        <img
                          src={BirthdayIcon}
                          alt=""
                          style={{ width: '140%' }}
                        />
                      </Avatar>
                    }
                    color="primary"
                    label={`${t('Age')}: ${diagnosisData.age}`}
                  />

                  <FormControlLabel
                    style={{ justifyContent: 'center' }}
                    control={
                      <Field
                        component={Checkbox}
                        color="primary"
                        type="checkbox"
                        name="smoker"
                      />
                    }
                    label={t('Smoker')}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    style={{ justifyContent: 'center' }}
                    control={
                      <Field
                        component={Checkbox}
                        color="primary"
                        type="checkbox"
                        name="systolicIsTreated"
                      />
                    }
                    label={t('DoTakePressureMedication')}
                    labelPlacement="start"
                  />
                  <Field
                    component={TextField}
                    label={t('Systolic')}
                    name="systolic"
                    type="number"
                  />
                  <Field
                    component={TextField}
                    label={t('HDL')}
                    name="hdl"
                    type="number"
                  />
                  <Field
                    component={TextField}
                    label={t('Cholesterol')}
                    name="cholesterol"
                    type="number"
                  />
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: 8,
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!isValid}
                      onClick={submitForm}
                    >
                      {t('StartDiagnosis')}
                    </Button>
                  </Box>
                </Fragment>
              )}
            </Formik>
          </Box>
        </Fragment>
      </Grid>
      <Dialog open={result !== undefined} onClose={() => setResult(undefined)}>
        <DialogTitle>Αποτελέσμα αυτοδιάγνωσης</DialogTitle>
        <DialogContent>{`Σύμφωνα με τις απαντήσεις σου ο 10ετής καρδιαγγειακός κίνδυνος ανέρχεται σε ${result}`}</DialogContent>
      </Dialog>
    </Grid>
  );
};
const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    marginLeft: '20%',
    marginRight: '20%',
    flexDirection: 'column',
  },
}));
