import React, { useState } from 'react';
import { Field, FieldProps } from 'formik';
import { DatePickerProps } from '@material-ui/pickers';
import { DateFormats } from 'res/Enums';
import { StyledDatePicker } from 'components/DatePicker';
interface FormDateFieldProps extends Partial<DatePickerProps> {
  name: string;
  label: string;
  beginEmpty?: boolean;
  required?: boolean;
}
const _FormDateField = ({
  name,
  label,
  required = false,
  beginEmpty = false,
  ...rest
}: FormDateFieldProps) => {
  const [empty, setEmpty] = useState(beginEmpty);
  return (
    <Field name={name}>
      {({ field, form, ...other }: FieldProps<any>) => {
        const currentError = form.errors[field.name];
        return (
          <StyledDatePicker
            disableFuture
            fullWidth
            autoOk
            margin="normal"
            label={label}
            name={field.name}
            value={empty ? null : field.value}
            format={DateFormats.GrNoTime}
            helperText={currentError}
            error={Boolean(currentError)}
            // onError={error => {
            //   // handle as a side effect
            //   if (error !== currentError) {
            //     form.setFieldError(field.name, error);
            //   }
            // }}
            // if you are using custom validation schema you probably want to pass `true` as third argument
            onChange={(date) => {
              if (empty) {
                setEmpty(false);
              }
              form.setFieldValue(field.name, date, true);
            }}
            variant="inline"
            {...other}
            {...rest}
          />
        );
      }}
    </Field>
  );
};
export const FormDateField = _FormDateField;
