/*
 * Generated by orval v4.2.1 🍺
 * Do not edit manually.
 * Healthier API
 * eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImtmcmFnb3NAZ21haWwuY29tIiwic3ViIjoxLCJyZW1lbWJlck1lIjpmYWxzZSwiY29tcGxldGUiOnRydWUsImlhdCI6MTYwODcxOTg5MCwiZXhwIjoxNjg4ODIwNzkwfQ.bRCh5kUZTCaEANqu6yOn552L5z61GodE0QDIvTK1TP0
 * OpenAPI spec version: 3.0
 */
import axios from 'axios'
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions
} from 'react-query'
export interface ActiveUsersDTO {
  year: number;
  month: string;
}

export interface LongTermActivityDTO {
  id: number;
  date: string;
  activeUsers: number;
}

export interface SimpleUserDTO {
  id: number;
  email: string;
}

export interface AndroidVerify {
  token: string;
}

export interface LoginResp {
  token: string;
}

export type RegisterDtoSex = 'Male' | 'Female' | 'Kids';


export const RegisterDtoSex = {
  Male: 'Male' as RegisterDtoSex,
  Female: 'Female' as RegisterDtoSex,
  Kids: 'Kids' as RegisterDtoSex,
};

export interface RegisterDto {
  name: string;
  surname: string;
  password: string;
  email: string;
  birthDate: string;
  sex: RegisterDtoSex;
  signUpDate?: string;
  termsAgreedDate?: string;
  mailList: boolean;
}

export interface RegisterResp {
  status: boolean;
  token?: string;
  code?: number;
}

export interface LoginDto {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface LoginDtoAndroid {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface RequestBody {
  email: string;
}

export interface RequestResponse {
  sent: boolean;
  message: string;
}

export interface ResetBody {
  token: string;
  pass: string;
  repeat: string;
}

export interface ChangePasswordDto {
  password: string;
  repeat: string;
}

export type SituationDTOCategory = 'Allergy' | 'ChronicDisease' | 'Addiction' | 'TemporaryDisease' | 'Disability' | 'Situation';


export const SituationDTOCategory = {
  Allergy: 'Allergy' as SituationDTOCategory,
  ChronicDisease: 'ChronicDisease' as SituationDTOCategory,
  Addiction: 'Addiction' as SituationDTOCategory,
  TemporaryDisease: 'TemporaryDisease' as SituationDTOCategory,
  Disability: 'Disability' as SituationDTOCategory,
  Situation: 'Situation' as SituationDTOCategory,
};

export interface SituationDTO {
  id: number;
  name: string;
  permanent: boolean;
  normalDuration: number;
  effect: number;
  code: string;
  category: SituationDTOCategory;
}

export interface UserSituationDTO {
  id: number;
  dateDeclared: string;
  valid: boolean;
  situation: SituationDTO;
}

export interface BasicSituation {
  smoker: boolean;
  diabetic?: UserSituationDTO;
}

export type FamilyHistoryDTOFamilyType = 'Father' | 'Mother' | 'Grandparent' | 'Sibling';


export const FamilyHistoryDTOFamilyType = {
  Father: 'Father' as FamilyHistoryDTOFamilyType,
  Mother: 'Mother' as FamilyHistoryDTOFamilyType,
  Grandparent: 'Grandparent' as FamilyHistoryDTOFamilyType,
  Sibling: 'Sibling' as FamilyHistoryDTOFamilyType,
};

export interface FamilyHistoryDTO {
  id: number;
  familyType: FamilyHistoryDTOFamilyType;
  comments: string;
  situation: SituationDTO;
}

export type UserAddictionDTOType = 'Smoker' | 'DrugUse' | 'Alcohol';


export const UserAddictionDTOType = {
  Smoker: 'Smoker' as UserAddictionDTOType,
  DrugUse: 'DrugUse' as UserAddictionDTOType,
  Alcohol: 'Alcohol' as UserAddictionDTOType,
};

export interface UserAddictionDTO {
  id: number;
  value: number;
  type: UserAddictionDTOType;
  time: string;
  endTime?: string;
}

export interface SituationMoreDTO {
  kid: SituationDTO[];
  sex: SituationDTO[];
}

export interface VaccineDTO {
  id: number;
  name: string;
  description: string;
}

export interface UserVaccineDTO {
  id: number;
  date: string;
  vaccine: VaccineDTO;
}

export interface BodySystemDTO {
  id: number;
  system: string;
}

export interface UserBodySystemDTO {
  id: number;
  comment: string;
  bodySystem: BodySystemDTO;
}

export type UserHospitalizationDTOType = 'Surgery' | 'Hospitalization' | 'Chemotherapy' | 'Dialysis';


export const UserHospitalizationDTOType = {
  Surgery: 'Surgery' as UserHospitalizationDTOType,
  Hospitalization: 'Hospitalization' as UserHospitalizationDTOType,
  Chemotherapy: 'Chemotherapy' as UserHospitalizationDTOType,
  Dialysis: 'Dialysis' as UserHospitalizationDTOType,
};

export interface UserHospitalizationDTO {
  id: number;
  type: UserHospitalizationDTOType;
  time: string;
  endTime?: string;
  comment: string;
}

export interface CreateSituation {
  dateDeclared?: string;
  situationId: number;
  valid?: boolean;
}

export type CreateFamilyHistoryFamilyType = 'Father' | 'Mother' | 'Grandparent' | 'Sibling';


export const CreateFamilyHistoryFamilyType = {
  Father: 'Father' as CreateFamilyHistoryFamilyType,
  Mother: 'Mother' as CreateFamilyHistoryFamilyType,
  Grandparent: 'Grandparent' as CreateFamilyHistoryFamilyType,
  Sibling: 'Sibling' as CreateFamilyHistoryFamilyType,
};

export interface CreateFamilyHistory {
  familyType: CreateFamilyHistoryFamilyType;
  comments: string;
  situation: SituationDTO;
}

export interface CreateVaccine {
  date: string;
  vaccine: VaccineDTO;
}

export interface CreateUserBodySystem {
  comment: string;
  bodySystem: BodySystemDTO;
}

export type CreateAddictionType = 'Smoker' | 'DrugUse' | 'Alcohol';


export const CreateAddictionType = {
  Smoker: 'Smoker' as CreateAddictionType,
  DrugUse: 'DrugUse' as CreateAddictionType,
  Alcohol: 'Alcohol' as CreateAddictionType,
};

export interface CreateAddiction {
  value: number;
  type: CreateAddictionType;
  time: string;
  endTime?: string;
}

export interface CreateAddictionBatch {
  data: CreateAddiction[];
}

export type CreateHospitalizationType = 'Surgery' | 'Hospitalization' | 'Chemotherapy' | 'Dialysis';


export const CreateHospitalizationType = {
  Surgery: 'Surgery' as CreateHospitalizationType,
  Hospitalization: 'Hospitalization' as CreateHospitalizationType,
  Chemotherapy: 'Chemotherapy' as CreateHospitalizationType,
  Dialysis: 'Dialysis' as CreateHospitalizationType,
};

export interface CreateHospitalization {
  type: CreateHospitalizationType;
  time: string;
  endTime?: string;
  comment: string;
}

export type SetSituationType = 'Smoking' | 'Diabetic1' | 'Diabetic2';


export const SetSituationType = {
  Smoking: 'Smoking' as SetSituationType,
  Diabetic1: 'Diabetic1' as SetSituationType,
  Diabetic2: 'Diabetic2' as SetSituationType,
};

export interface SetSituation {
  type: SetSituationType;
  set: boolean;
}

export interface CreateSituationBatch {
  data: CreateSituation[];
}

export interface UpdateSituation {
  dateDeclared?: string;
  situationId: number;
  valid?: boolean;
}

export interface UserExtraDto {
  shortNote: string;
}

export interface UserEmergencyDTO {
  emergencyNumber: string;
  contactName: string;
}

export type UserDtoSex = 'Male' | 'Female' | 'Kids';


export const UserDtoSex = {
  Male: 'Male' as UserDtoSex,
  Female: 'Female' as UserDtoSex,
  Kids: 'Kids' as UserDtoSex,
};

export type UserDtoBloodType = 'Oneg' | 'Opos' | 'Bneg' | 'Bpos' | 'Aneg' | 'Apos' | 'ABneg' | 'ABpos';


export const UserDtoBloodType = {
  Oneg: 'Oneg' as UserDtoBloodType,
  Opos: 'Opos' as UserDtoBloodType,
  Bneg: 'Bneg' as UserDtoBloodType,
  Bpos: 'Bpos' as UserDtoBloodType,
  Aneg: 'Aneg' as UserDtoBloodType,
  Apos: 'Apos' as UserDtoBloodType,
  ABneg: 'ABneg' as UserDtoBloodType,
  ABpos: 'ABpos' as UserDtoBloodType,
};

export interface UserDto {
  gId: boolean;
  email: string;
  name: string;
  surname: string;
  birthDate: string;
  age: number;
  sex: UserDtoSex;
  bloodType?: UserDtoBloodType;
  extra?: UserExtraDto;
  emergency?: UserEmergencyDTO;
  tutorial: boolean;
}

export interface CreateBugReport {
  text: string;
  relatedPage: string;
}

export interface ExtraUpdate {
  emergencyNumber: string;
  contactName: string;
}

export type UpdateUserSex = 'Male' | 'Female' | 'Kids';


export const UpdateUserSex = {
  Male: 'Male' as UpdateUserSex,
  Female: 'Female' as UpdateUserSex,
  Kids: 'Kids' as UpdateUserSex,
};

export interface UpdateUser {
  email: string;
  name: string;
  surname: string;
  birthDate: string;
  tutorial: boolean;
  sex: UpdateUserSex;
  mailChimp?: boolean;
  emergency?: ExtraUpdate;
  terms?: string;
}

export interface ShortNote {
  id?: number;
  shortNote: string;
}

export interface DisableUser {
  user: UpdateUser;
  date: string;
}

export interface LimitDTO {
  min: number;
  max: number;
  unit: string;
}

export interface MeasurementDTO {
  id: number;
  name: string;
  limits: LimitDTO[];
}

export type UserMeasurementDTOSpecial = 'BEFORE' | 'AFTER' | 'INDIFFERENT';


export const UserMeasurementDTOSpecial = {
  BEFORE: 'BEFORE' as UserMeasurementDTOSpecial,
  AFTER: 'AFTER' as UserMeasurementDTOSpecial,
  INDIFFERENT: 'INDIFFERENT' as UserMeasurementDTOSpecial,
};

export interface UserMeasurementDTO {
  time: string;
  value: number;
  id: number;
  special: UserMeasurementDTOSpecial;
  measurement: MeasurementDTO;
  high?: number;
  low?: number;
}

export type CreateMeasurementSpecial = 'BEFORE' | 'AFTER' | 'INDIFFERENT';


export const CreateMeasurementSpecial = {
  BEFORE: 'BEFORE' as CreateMeasurementSpecial,
  AFTER: 'AFTER' as CreateMeasurementSpecial,
  INDIFFERENT: 'INDIFFERENT' as CreateMeasurementSpecial,
};

export interface CreateMeasurement {
  value: number;
  time: string;
  special?: CreateMeasurementSpecial;
  high?: number;
  low?: number;
  measurement: MeasurementDTO;
}

export type UpdateMeasurementSpecial = 'BEFORE' | 'AFTER' | 'INDIFFERENT';


export const UpdateMeasurementSpecial = {
  BEFORE: 'BEFORE' as UpdateMeasurementSpecial,
  AFTER: 'AFTER' as UpdateMeasurementSpecial,
  INDIFFERENT: 'INDIFFERENT' as UpdateMeasurementSpecial,
};

export interface UpdateMeasurement {
  value: number;
  time: string;
  special?: UpdateMeasurementSpecial;
  measurement: MeasurementDTO;
  high?: number;
  low?: number;
}

export type MessageDtoMessageType = 'Normal' | 'Link' | 'Dialog';


export const MessageDtoMessageType = {
  Normal: 'Normal' as MessageDtoMessageType,
  Link: 'Link' as MessageDtoMessageType,
  Dialog: 'Dialog' as MessageDtoMessageType,
};

export interface MessageDto {
  link: string;
  id: number;
  textEn: string;
  text: string;
  messageType: MessageDtoMessageType;
}

export interface UserMessagesDto {
  id: number;
  textEn: string;
  text: string;
  message: MessageDto;
  declined: boolean;
  eventNo: string;
}

export interface ReadMessagesDTO {
  messages: UserMessagesDto[];
  now: string;
}

export interface MedicineColorDto {
  id: number;
  name?: string;
  code: string;
}

export interface MedicineShapeDto {
  id: number;
  type: string;
}

export interface MedicineDTO {
  id: number;
  name: string;
  color: MedicineColorDto;
  shape: MedicineShapeDto;
}

export interface GetNextDosages {
  until: string;
  now: string;
}

export interface Timetable {
  id?: number;
  time: string;
  frequency: number;
  dosage: string;
}

export interface NextTake {
  time: string;
  dosage: string;
}

export interface GetNextDosagesDTO {
  dosages: string[];
  id: number;
  startDate: string;
  endDate: string;
  duration: number;
  color: MedicineColorDto;
  shape: MedicineShapeDto;
  expiryDate: string;
  barcode: number;
  medicine: MedicineDTO;
  repeatedNormally: boolean;
  timetable: Timetable[];
  nextTake?: NextTake;
}

export interface UserMedicationDTO {
  id: number;
  startDate: string;
  endDate: string;
  duration: number;
  color: MedicineColorDto;
  shape: MedicineShapeDto;
  expiryDate: string;
  barcode: number;
  medicine: MedicineDTO;
  repeatedNormally: boolean;
  timetable: Timetable[];
  nextTake?: NextTake;
}

export interface CreateMedication {
  startDate: string;
  duration: number;
  medicine: MedicineDTO;
  color: MedicineColorDto;
  shape: MedicineShapeDto;
  repeatedNormally: boolean;
  timetable: Timetable[];
}

export interface UpdateMedication {
  id: number;
  startDate: string;
  duration: number;
  medicine: MedicineDTO;
  color: MedicineColorDto;
  shape: MedicineShapeDto;
  repeatedNormally: boolean;
  timetable: Timetable[];
}

export interface DurationDTO {
  id: number;
  averageDuration: number;
  userGenerated: boolean;
}

export interface CreateDuration {
  averageDuration: number;
  userGenerated: boolean;
}

export interface CreatePrediction {
  prediction?: CreateDuration;
  temp?: boolean;
}

export interface CycleResult {
  message: string;
  avg: number;
  nextDates: string[];
  averageDuration: number;
}

export interface PredictionDTO {
  result: CycleResult;
}

export interface CycleDTO {
  id: number;
  startDate: string;
  endDate: string;
}

export interface CreateCycle {
  startDate: string;
  endDate?: string;
}

export interface UpdateCycle {
  startDate: string;
  endDate?: string;
}

export interface SymptomDTO {
  id: number;
  name: string;
  isBoolean: boolean;
}

export interface UserSymptomDTO {
  id: number;
  value: number;
  time: string;
  description: string;
  symptom: SymptomDTO;
  diabetes?: number;
}

export interface SearchSymptom {
  value: number;
  label: string;
}

export interface CreateSymptom {
  value: number;
  time: string;
  description: string;
  symptom: SymptomDTO;
  diabetes?: number;
}

export interface UpdateSymptom {
  value: number;
  time: string;
  description: string;
  symptom: SymptomDTO;
  diabetes?: number;
}

export interface UserScoreDTO {
  id: number;
  value: number;
  date: string;
}

export interface QuestionDTO {
  id: number;
  text: string;
  scale: string;
}

export interface UserFeedbackDTO {
  id: number;
  date: string;
  score: number;
  question: QuestionDTO;
}

export interface CreateFeedbackDTO {
  score: number;
  question: number;
  time: string;
}

export type FeedbackResponseError = {
  code?: number;
  message?: string;
};

export interface FeedbackResponse {
  status: boolean;
  error: FeedbackResponseError;
}

export type MedicalCenterDTOType = 'HOSPITAL' | 'MEDICALCENTER';


export const MedicalCenterDTOType = {
  HOSPITAL: 'HOSPITAL' as MedicalCenterDTOType,
  MEDICALCENTER: 'MEDICALCENTER' as MedicalCenterDTOType,
};

export interface MedicalCenterDTO {
  id: number;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  phone: string;
  website: string;
  email: string;
  region: string;
  type: MedicalCenterDTOType;
}

export interface PdfCategoryDTO {
  id: number;
  name: string;
}

export interface UserPdfDTO {
  id: number;
  url: string;
  fileName: string;
  date: string;
  category: PdfCategoryDTO;
  medicalCenter: MedicalCenterDTO;
}

export interface FileUploadDto {
  file: Blob;
}

export type UpdateUserPdfMedicalCenter = {};

export interface UpdateUserPdf {
  id: number;
  date: string;
  category: PdfCategoryDTO;
  medicalCenter: UpdateUserPdfMedicalCenter;
}

export interface NoteDto {
  id: number;
  text: string;
  time: string;
}

export interface CreateNoteDto {
  id?: number;
  text: string;
  time: string;
}

export type PreventiveResultDtoData = {};

export interface PreventiveResultDto {
  data: PreventiveResultDtoData;
}

export interface AddPreventiveDTO {
  examID: number;
}

export interface FitnessDTO {
  id: number;
  name: string;
  hasDistance: boolean;
  units: string;
}

export interface UserFitnessDTO {
  id: number;
  distance?: number;
  activityTime?: number;
  activityScore: number;
  time: string;
  endTime: string;
  comments: string;
  fitness: FitnessDTO;
}

export interface SearchFitness {
  value: number;
  label: string;
}

export interface CreateFitness {
  distance?: number;
  activityTime?: number;
  activityScore: number;
  time: string;
  endTime: string;
  comments: string;
  fitness: FitnessDTO;
}

export interface UpdateFitness {
  distance?: number;
  activityTime?: number;
  activityScore: number;
  time: string;
  endTime: string;
  comments: string;
  fitness: FitnessDTO;
}

export interface InsulinDTO {
  id: number;
  name: string;
}

export type UserInsulinDTOSpecial = 'BEFORE' | 'AFTER' | 'INDIFFERENT';


export const UserInsulinDTOSpecial = {
  BEFORE: 'BEFORE' as UserInsulinDTOSpecial,
  AFTER: 'AFTER' as UserInsulinDTOSpecial,
  INDIFFERENT: 'INDIFFERENT' as UserInsulinDTOSpecial,
};

export interface UserInsulinDTO {
  id: number;
  quantity: number;
  insulin: InsulinDTO;
  time: string;
  special: UserInsulinDTOSpecial;
}

export type CreateInsulinSpecial = 'BEFORE' | 'AFTER' | 'INDIFFERENT';


export const CreateInsulinSpecial = {
  BEFORE: 'BEFORE' as CreateInsulinSpecial,
  AFTER: 'AFTER' as CreateInsulinSpecial,
  INDIFFERENT: 'INDIFFERENT' as CreateInsulinSpecial,
};

export interface CreateInsulin {
  quantity: number;
  insulin: InsulinDTO;
  time: string;
  special?: CreateInsulinSpecial;
}

export type UpdateInsulinSpecial = 'BEFORE' | 'AFTER' | 'INDIFFERENT';


export const UpdateInsulinSpecial = {
  BEFORE: 'BEFORE' as UpdateInsulinSpecial,
  AFTER: 'AFTER' as UpdateInsulinSpecial,
  INDIFFERENT: 'INDIFFERENT' as UpdateInsulinSpecial,
};

export interface UpdateInsulin {
  quantity: number;
  insulin: InsulinDTO;
  time: string;
  special?: UpdateInsulinSpecial;
}

export type BloodDTOType = 'Oneg' | 'Opos' | 'Bneg' | 'Bpos' | 'Aneg' | 'Apos' | 'ABneg' | 'ABpos';


export const BloodDTOType = {
  Oneg: 'Oneg' as BloodDTOType,
  Opos: 'Opos' as BloodDTOType,
  Bneg: 'Bneg' as BloodDTOType,
  Bpos: 'Bpos' as BloodDTOType,
  Aneg: 'Aneg' as BloodDTOType,
  Apos: 'Apos' as BloodDTOType,
  ABneg: 'ABneg' as BloodDTOType,
  ABpos: 'ABpos' as BloodDTOType,
};

export interface BloodDTO {
  id: number;
  type: BloodDTOType;
}

export type CreateBloodType = 'Oneg' | 'Opos' | 'Bneg' | 'Bpos' | 'Aneg' | 'Apos' | 'ABneg' | 'ABpos';


export const CreateBloodType = {
  Oneg: 'Oneg' as CreateBloodType,
  Opos: 'Opos' as CreateBloodType,
  Bneg: 'Bneg' as CreateBloodType,
  Bpos: 'Bpos' as CreateBloodType,
  Aneg: 'Aneg' as CreateBloodType,
  Apos: 'Apos' as CreateBloodType,
  ABneg: 'ABneg' as CreateBloodType,
  ABpos: 'ABpos' as CreateBloodType,
};

export interface CreateBlood {
  type: CreateBloodType;
}

export type UserEnviromentalDTOType = 'TEMPERATURE' | 'HUMIDITY' | 'AIR';


export const UserEnviromentalDTOType = {
  TEMPERATURE: 'TEMPERATURE' as UserEnviromentalDTOType,
  HUMIDITY: 'HUMIDITY' as UserEnviromentalDTOType,
  AIR: 'AIR' as UserEnviromentalDTOType,
};

export interface UserEnviromentalDTO {
  id: number;
  type: UserEnviromentalDTOType;
  value: number;
  time: string;
}

export interface LatestEmviromentals {
  type1?: UserEnviromentalDTO;
  type2?: UserEnviromentalDTO;
  type3?: UserEnviromentalDTO;
}

export type CreateEnviromentalType = 'TEMPERATURE' | 'HUMIDITY' | 'AIR';


export const CreateEnviromentalType = {
  TEMPERATURE: 'TEMPERATURE' as CreateEnviromentalType,
  HUMIDITY: 'HUMIDITY' as CreateEnviromentalType,
  AIR: 'AIR' as CreateEnviromentalType,
};

export interface CreateEnviromental {
  type: CreateEnviromentalType;
  value: number;
  time: string;
}

export type UpdateEnviromentalType = 'TEMPERATURE' | 'HUMIDITY' | 'AIR';


export const UpdateEnviromentalType = {
  TEMPERATURE: 'TEMPERATURE' as UpdateEnviromentalType,
  HUMIDITY: 'HUMIDITY' as UpdateEnviromentalType,
  AIR: 'AIR' as UpdateEnviromentalType,
};

export interface UpdateEnviromental {
  type: UpdateEnviromentalType;
  value: number;
  time: string;
}

export type UserCovidDTOType = 'type1' | 'type2' | 'type3' | 'type4' | 'type5' | 'type6' | 'type7' | 'type8' | 'type9';


export const UserCovidDTOType = {
  type1: 'type1' as UserCovidDTOType,
  type2: 'type2' as UserCovidDTOType,
  type3: 'type3' as UserCovidDTOType,
  type4: 'type4' as UserCovidDTOType,
  type5: 'type5' as UserCovidDTOType,
  type6: 'type6' as UserCovidDTOType,
  type7: 'type7' as UserCovidDTOType,
  type8: 'type8' as UserCovidDTOType,
  type9: 'type9' as UserCovidDTOType,
};

export interface UserCovidDTO {
  id: number;
  date?: string;
  type: UserCovidDTOType;
}

export type CreateCovidType = 'type1' | 'type2' | 'type3' | 'type4' | 'type5' | 'type6' | 'type7' | 'type8' | 'type9';


export const CreateCovidType = {
  type1: 'type1' as CreateCovidType,
  type2: 'type2' as CreateCovidType,
  type3: 'type3' as CreateCovidType,
  type4: 'type4' as CreateCovidType,
  type5: 'type5' as CreateCovidType,
  type6: 'type6' as CreateCovidType,
  type7: 'type7' as CreateCovidType,
  type8: 'type8' as CreateCovidType,
  type9: 'type9' as CreateCovidType,
};

export interface CreateCovid {
  date?: string;
  type: CreateCovidType;
}

export interface UpdateCovid {
  date: string;
}

export interface OsteoDTO {
  age: number;
  weight: number;
}

export interface OsteoCalculateDTO {
  estrogen: number;
  race: number;
  arthritis: number;
  fracture: number;
  weight?: number;
}

export interface OsteoResultDTO {
  result: string;
}

export interface StrokeDTO {
  weight: number;
  height: number;
  cholesterol: number;
  high: number;
  low: number;
}

export interface DiagnosisDTO {
  cholesterol?: number;
  hdl?: number;
  systolic?: number;
  smoker: boolean;
  age: number;
}

export interface PostDiagnosisDTO {
  cholesterol: number;
  hdl: number;
  systolic: number;
  smoker: boolean;
  age: number;
  systolicIsTreated: boolean;
}

export interface DiagnosisResultDTO {
  score: string;
}

export type PregnancyDetailsDTOType = 'fetus' | 'mother' | 'information';


export const PregnancyDetailsDTOType = {
  fetus: 'fetus' as PregnancyDetailsDTOType,
  mother: 'mother' as PregnancyDetailsDTOType,
  information: 'information' as PregnancyDetailsDTOType,
};

export interface PregnancyDetailsDTO {
  week: number;
  type: PregnancyDetailsDTOType;
  text: string;
}

export interface PregnancyPdfDTO {
  id: number;
  name: string;
  description: string;
}

export interface UserPregnancyPdfDTO {
  id: number;
  date: string;
  pdfCategory: number;
  pdfId?: number | null;
}

export interface KidMeasurementDTO {
  time: string;
  value: number;
  id: number;
  measurement: MeasurementDTO;
}

export interface KidSymptomDTO {
  id: number;
  value: number;
  time: string;
  description: string;
  symptom: SymptomDTO;
}

export interface KidVaccineDTO {
  id: number;
  date: string;
  vaccine: VaccineDTO;
}

export interface KidDTO {
  id: number;
  birthDate: string;
}

export type PregnancyDTOType = 'BIRTH' | 'ABORTION' | 'MISCARRIAGE' | 'OTHER';


export const PregnancyDTOType = {
  BIRTH: 'BIRTH' as PregnancyDTOType,
  ABORTION: 'ABORTION' as PregnancyDTOType,
  MISCARRIAGE: 'MISCARRIAGE' as PregnancyDTOType,
  OTHER: 'OTHER' as PregnancyDTOType,
};

export interface PregnancyDTO {
  id: number;
  startDate: string;
  endDate?: string;
  type?: PregnancyDTOType;
  comments?: string;
  UserPregnancy: KidDTO[];
}

export interface CreateKid {
  birthdate: string;
  userPregnancyId: number;
}

export interface CreatePregnancyPdf {
  date: string;
  userPdf?: number;
  pregnancyCategory: PregnancyPdfDTO;
}

export interface PregnancyBodyDTO {
  categoryId: number;
  date: string;
  centerId: number;
  pregnancyCategoryId?: number;
  pregnancyCategoryName?: string;
  pregnancyCategoryDate?: string;
  pregnancyCategoryDescription?: string;
  id?: number;
}

export interface PdfResponseDTO {
  resp: boolean;
  id?: UserPdfDTO;
}

export interface PregnancyBMIDTO {
  weight: number;
  height: number;
}

export interface BMICurve {
  low: string[];
  high: string[];
}

export type BMIType = 'UnderWeight' | 'Normal' | 'OverWeight' | 'Obese';


export const BMIType = {
  UnderWeight: 'UnderWeight' as BMIType,
  Normal: 'Normal' as BMIType,
  OverWeight: 'OverWeight' as BMIType,
  Obese: 'Obese' as BMIType,
};

export interface Bmi {
  type: BMIType;
  weight: BMICurve;
}

export interface Bmidto {
  result: Bmi;
}

export interface CreateKidMeasurement {
  time: string;
  value: number;
  measurement: MeasurementDTO;
}

export interface CreateKidSymptom {
  value: number;
  time: string;
  description: string;
  symptom: SymptomDTO;
}

export interface CreateKidVaccine {
  date: string;
  vaccine: VaccineDTO;
}

export interface CreatePregnancy {
  startDate: string;
}

export type UpdatePregnancyType = 'BIRTH' | 'ABORTION' | 'MISCARRIAGE' | 'OTHER';


export const UpdatePregnancyType = {
  BIRTH: 'BIRTH' as UpdatePregnancyType,
  ABORTION: 'ABORTION' as UpdatePregnancyType,
  MISCARRIAGE: 'MISCARRIAGE' as UpdatePregnancyType,
  OTHER: 'OTHER' as UpdatePregnancyType,
};

export interface UpdatePregnancy {
  startDate: string;
  endDate?: string;
  type?: UpdatePregnancyType;
  comments?: string;
}

export interface ActiveMedicationDTO {
  userId: number;
  time: string;
}

export type SituationControllerGetSituationTypesParams = { category: SituationControllerGetSituationTypesCategory; name: string };

export type SituationControllerGetSituationTypesCategory = 'Allergy' | 'ChronicDisease' | 'Addiction' | 'TemporaryDisease' | 'Disability' | 'Situation';


export const SituationControllerGetSituationTypesCategory = {
  Allergy: 'Allergy' as SituationControllerGetSituationTypesCategory,
  ChronicDisease: 'ChronicDisease' as SituationControllerGetSituationTypesCategory,
  Addiction: 'Addiction' as SituationControllerGetSituationTypesCategory,
  TemporaryDisease: 'TemporaryDisease' as SituationControllerGetSituationTypesCategory,
  Disability: 'Disability' as SituationControllerGetSituationTypesCategory,
  Situation: 'Situation' as SituationControllerGetSituationTypesCategory,
};

export type EventsControllerTest200 = {};

export type MedicationControllerGetMyMedicationParams = { activeMedication: boolean; date?: string; medicineID?: number };

export type NoteControllerGetMyNotesParams = { date?: string };

export type ExportedControllerGetUserMeasurementsParams = { measurementId: number };

export type ExportedControllerGetUserSymptomsParams = { symptomId: number };

export type ExportedControllerGetUserSituationParams = { situationId: number; category: string };

export type ExportedControllerGetActiveMedication201 = {};



type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;

export const appControllerGetstats = <Data = unknown>(
    email: string,
  ) => {
    return axios.get<Data extends unknown ? string[] : Data>(
      `/getStats/${email}`,
    );
  }


export const getAppControllerGetstatsQueryKey = (email: string,) => [`/getStats/${email}`]

    
export const useAppControllerGetstats = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 email: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof appControllerGetstats>, Error>
  ) => {
  const queryKey = getAppControllerGetstatsQueryKey(email);

  const query = useQuery<AsyncReturnType<typeof appControllerGetstats>, Error>(queryKey, () => appControllerGetstats<Data>(email), {enabled: !!(email), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const appControllerGetUsersWithStats = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? string[] : Data>(
      `/getUsersWithStats`,
    );
  }


export const getAppControllerGetUsersWithStatsQueryKey = () => [`/getUsersWithStats`]

    
export const useAppControllerGetUsersWithStats = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof appControllerGetUsersWithStats>, Error>
  ) => {
  const queryKey = getAppControllerGetUsersWithStatsQueryKey();

  const query = useQuery<AsyncReturnType<typeof appControllerGetUsersWithStats>, Error>(queryKey, () => appControllerGetUsersWithStats<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const appControllerGetActiveUsers = <Data = unknown>(
    activeUsersDTO: ActiveUsersDTO,
  ) => {
    return axios.post<Data extends unknown ? unknown : Data>(
      `/activeUsers`,
      activeUsersDTO,
    );
  }



export const useAppControllerGetActiveUsers = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof appControllerGetActiveUsers>, Error, {data: ActiveUsersDTO}>
  ) => {
  return useMutation<AsyncReturnType<typeof appControllerGetActiveUsers>, Error, {data: ActiveUsersDTO}>((props) => {
    const {data} = props || {};

    return  appControllerGetActiveUsers<Data>(data)
  }, mutationConfig)
}
export const appControllerLongTermActive = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? LongTermActivityDTO[] : Data>(
      `/longTermActive`,
    );
  }


export const getAppControllerLongTermActiveQueryKey = () => [`/longTermActive`]

    
export const useAppControllerLongTermActive = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof appControllerLongTermActive>, Error>
  ) => {
  const queryKey = getAppControllerLongTermActiveQueryKey();

  const query = useQuery<AsyncReturnType<typeof appControllerLongTermActive>, Error>(queryKey, () => appControllerLongTermActive<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const appControllerImportMeds = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? unknown : Data>(
      `/importMeds`,
    );
  }


export const getAppControllerImportMedsQueryKey = () => [`/importMeds`]

    
export const useAppControllerImportMeds = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof appControllerImportMeds>, Error>
  ) => {
  const queryKey = getAppControllerImportMedsQueryKey();

  const query = useQuery<AsyncReturnType<typeof appControllerImportMeds>, Error>(queryKey, () => appControllerImportMeds<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const usersControllerGetUsers = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? SimpleUserDTO[] : Data>(
      `/users`,
    );
  }


export const getUsersControllerGetUsersQueryKey = () => [`/users`]

    
export const useUsersControllerGetUsers = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof usersControllerGetUsers>, Error>
  ) => {
  const queryKey = getUsersControllerGetUsersQueryKey();

  const query = useQuery<AsyncReturnType<typeof usersControllerGetUsers>, Error>(queryKey, () => usersControllerGetUsers<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const usersControllerTest = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? unknown : Data>(
      `/users/test`,
    );
  }


export const getUsersControllerTestQueryKey = () => [`/users/test`]

    
export const useUsersControllerTest = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof usersControllerTest>, Error>
  ) => {
  const queryKey = getUsersControllerTestQueryKey();

  const query = useQuery<AsyncReturnType<typeof usersControllerTest>, Error>(queryKey, () => usersControllerTest<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const usersControllerCountUsers = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? number : Data>(
      `/users/count`,
    );
  }


export const getUsersControllerCountUsersQueryKey = () => [`/users/count`]

    
export const useUsersControllerCountUsers = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof usersControllerCountUsers>, Error>
  ) => {
  const queryKey = getUsersControllerCountUsersQueryKey();

  const query = useQuery<AsyncReturnType<typeof usersControllerCountUsers>, Error>(queryKey, () => usersControllerCountUsers<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const authControllerGetGoogleRefreshToken = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? unknown : Data>(
      `/auth/google/getrefreshtoken`,
    );
  }


export const getAuthControllerGetGoogleRefreshTokenQueryKey = () => [`/auth/google/getrefreshtoken`]

    
export const useAuthControllerGetGoogleRefreshToken = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof authControllerGetGoogleRefreshToken>, Error>
  ) => {
  const queryKey = getAuthControllerGetGoogleRefreshTokenQueryKey();

  const query = useQuery<AsyncReturnType<typeof authControllerGetGoogleRefreshToken>, Error>(queryKey, () => authControllerGetGoogleRefreshToken<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const authControllerGoogleLogin = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? unknown : Data>(
      `/auth/google`,
    );
  }


export const getAuthControllerGoogleLoginQueryKey = () => [`/auth/google`]

    
export const useAuthControllerGoogleLogin = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof authControllerGoogleLogin>, Error>
  ) => {
  const queryKey = getAuthControllerGoogleLoginQueryKey();

  const query = useQuery<AsyncReturnType<typeof authControllerGoogleLogin>, Error>(queryKey, () => authControllerGoogleLogin<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const authControllerGoogleLoginCallback = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? unknown : Data>(
      `/auth/google/callback`,
    );
  }


export const getAuthControllerGoogleLoginCallbackQueryKey = () => [`/auth/google/callback`]

    
export const useAuthControllerGoogleLoginCallback = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof authControllerGoogleLoginCallback>, Error>
  ) => {
  const queryKey = getAuthControllerGoogleLoginCallbackQueryKey();

  const query = useQuery<AsyncReturnType<typeof authControllerGoogleLoginCallback>, Error>(queryKey, () => authControllerGoogleLoginCallback<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const authControllerAndroidGoogle = <Data = unknown>(
    androidVerify: AndroidVerify,
  ) => {
    return axios.post<Data extends unknown ? unknown : Data>(
      `/auth/android/google`,
      androidVerify,
    );
  }



export const useAuthControllerAndroidGoogle = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof authControllerAndroidGoogle>, Error, {data: AndroidVerify}>
  ) => {
  return useMutation<AsyncReturnType<typeof authControllerAndroidGoogle>, Error, {data: AndroidVerify}>((props) => {
    const {data} = props || {};

    return  authControllerAndroidGoogle<Data>(data)
  }, mutationConfig)
}
export const authControllerRefreshToken = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? LoginResp : Data>(
      `/auth/refresh`,
    );
  }


export const getAuthControllerRefreshTokenQueryKey = () => [`/auth/refresh`]

    
export const useAuthControllerRefreshToken = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof authControllerRefreshToken>, Error>
  ) => {
  const queryKey = getAuthControllerRefreshTokenQueryKey();

  const query = useQuery<AsyncReturnType<typeof authControllerRefreshToken>, Error>(queryKey, () => authControllerRefreshToken<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const authControllerAndroidRefresh = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? string : Data>(
      `/auth/androidrefresh`,
    );
  }


export const getAuthControllerAndroidRefreshQueryKey = () => [`/auth/androidrefresh`]

    
export const useAuthControllerAndroidRefresh = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof authControllerAndroidRefresh>, Error>
  ) => {
  const queryKey = getAuthControllerAndroidRefreshQueryKey();

  const query = useQuery<AsyncReturnType<typeof authControllerAndroidRefresh>, Error>(queryKey, () => authControllerAndroidRefresh<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const authControllerRegister = <Data = unknown>(
    registerDto: RegisterDto,
  ) => {
    return axios.post<Data extends unknown ? RegisterResp : Data>(
      `/auth/register`,
      registerDto,
    );
  }



export const useAuthControllerRegister = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof authControllerRegister>, Error, {data: RegisterDto}>
  ) => {
  return useMutation<AsyncReturnType<typeof authControllerRegister>, Error, {data: RegisterDto}>((props) => {
    const {data} = props || {};

    return  authControllerRegister<Data>(data)
  }, mutationConfig)
}
export const authControllerLogin = <Data = unknown>(
    loginDto: LoginDto,
  ) => {
    return axios.post<Data extends unknown ? LoginResp : Data>(
      `/auth/login`,
      loginDto,
    );
  }



export const useAuthControllerLogin = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof authControllerLogin>, Error, {data: LoginDto}>
  ) => {
  return useMutation<AsyncReturnType<typeof authControllerLogin>, Error, {data: LoginDto}>((props) => {
    const {data} = props || {};

    return  authControllerLogin<Data>(data)
  }, mutationConfig)
}
export const authControllerLoginAndroid = <Data = unknown>(
    loginDtoAndroid: LoginDtoAndroid,
  ) => {
    return axios.post<Data extends unknown ? string : Data>(
      `/auth/loginandroid`,
      loginDtoAndroid,
    );
  }



export const useAuthControllerLoginAndroid = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof authControllerLoginAndroid>, Error, {data: LoginDtoAndroid}>
  ) => {
  return useMutation<AsyncReturnType<typeof authControllerLoginAndroid>, Error, {data: LoginDtoAndroid}>((props) => {
    const {data} = props || {};

    return  authControllerLoginAndroid<Data>(data)
  }, mutationConfig)
}
export const authControllerLogout = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? boolean : Data>(
      `/auth/logout`,
    );
  }


export const getAuthControllerLogoutQueryKey = () => [`/auth/logout`]

    
export const useAuthControllerLogout = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof authControllerLogout>, Error>
  ) => {
  const queryKey = getAuthControllerLogoutQueryKey();

  const query = useQuery<AsyncReturnType<typeof authControllerLogout>, Error>(queryKey, () => authControllerLogout<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const authControllerRequestReset = <Data = unknown>(
    requestBody: RequestBody,
  ) => {
    return axios.post<Data extends unknown ? RequestResponse : Data>(
      `/auth/requestReset`,
      requestBody,
    );
  }



export const useAuthControllerRequestReset = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof authControllerRequestReset>, Error, {data: RequestBody}>
  ) => {
  return useMutation<AsyncReturnType<typeof authControllerRequestReset>, Error, {data: RequestBody}>((props) => {
    const {data} = props || {};

    return  authControllerRequestReset<Data>(data)
  }, mutationConfig)
}
export const authControllerReset = <Data = unknown>(
    resetBody: ResetBody,
  ) => {
    return axios.post<Data extends unknown ? RequestResponse : Data>(
      `/auth/reset`,
      resetBody,
    );
  }



export const useAuthControllerReset = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof authControllerReset>, Error, {data: ResetBody}>
  ) => {
  return useMutation<AsyncReturnType<typeof authControllerReset>, Error, {data: ResetBody}>((props) => {
    const {data} = props || {};

    return  authControllerReset<Data>(data)
  }, mutationConfig)
}
export const authControllerChangePassword = <Data = unknown>(
    changePasswordDto: ChangePasswordDto,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/auth/changepassword`,
      changePasswordDto,
    );
  }



export const useAuthControllerChangePassword = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof authControllerChangePassword>, Error, {data: ChangePasswordDto}>
  ) => {
  return useMutation<AsyncReturnType<typeof authControllerChangePassword>, Error, {data: ChangePasswordDto}>((props) => {
    const {data} = props || {};

    return  authControllerChangePassword<Data>(data)
  }, mutationConfig)
}
export const situationControllerGetBasicSituation = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? BasicSituation : Data>(
      `/me/situation/basic`,
    );
  }


export const getSituationControllerGetBasicSituationQueryKey = () => [`/me/situation/basic`]

    
export const useSituationControllerGetBasicSituation = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetBasicSituation>, Error>
  ) => {
  const queryKey = getSituationControllerGetBasicSituationQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetBasicSituation>, Error>(queryKey, () => situationControllerGetBasicSituation<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerGetSituationTypes = <Data = unknown>(
    params?: SituationControllerGetSituationTypesParams,
  ) => {
    return axios.get<Data extends unknown ? SituationDTO[] : Data>(
      `/me/situation/types`,
      {
        params,
      },
    );
  }


export const getSituationControllerGetSituationTypesQueryKey = (params?: SituationControllerGetSituationTypesParams,) => [`/me/situation/types`, ...(params ? [params]: [])]

    
export const useSituationControllerGetSituationTypes = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 params?: SituationControllerGetSituationTypesParams,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetSituationTypes>, Error>
  ) => {
  const queryKey = getSituationControllerGetSituationTypesQueryKey(params);

  const query = useQuery<AsyncReturnType<typeof situationControllerGetSituationTypes>, Error>(queryKey, () => situationControllerGetSituationTypes<Data>(params), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerGetMyFamilyHistory = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? FamilyHistoryDTO[] : Data>(
      `/me/situation/history`,
    );
  }


export const getSituationControllerGetMyFamilyHistoryQueryKey = () => [`/me/situation/history`]

    
export const useSituationControllerGetMyFamilyHistory = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetMyFamilyHistory>, Error>
  ) => {
  const queryKey = getSituationControllerGetMyFamilyHistoryQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetMyFamilyHistory>, Error>(queryKey, () => situationControllerGetMyFamilyHistory<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerCreateMyFamilyHistory = <Data = unknown>(
    createFamilyHistory: CreateFamilyHistory,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/situation/history`,
      createFamilyHistory,
    );
  }



export const useSituationControllerCreateMyFamilyHistory = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerCreateMyFamilyHistory>, Error, {data: CreateFamilyHistory}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerCreateMyFamilyHistory>, Error, {data: CreateFamilyHistory}>((props) => {
    const {data} = props || {};

    return  situationControllerCreateMyFamilyHistory<Data>(data)
  }, mutationConfig)
}
export const situationControllerGetMyAddiction = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserAddictionDTO[] : Data>(
      `/me/situation/addiction`,
    );
  }


export const getSituationControllerGetMyAddictionQueryKey = () => [`/me/situation/addiction`]

    
export const useSituationControllerGetMyAddiction = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetMyAddiction>, Error>
  ) => {
  const queryKey = getSituationControllerGetMyAddictionQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetMyAddiction>, Error>(queryKey, () => situationControllerGetMyAddiction<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerGetHereditary = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? SituationDTO[] : Data>(
      `/me/situation/hereditary`,
    );
  }


export const getSituationControllerGetHereditaryQueryKey = () => [`/me/situation/hereditary`]

    
export const useSituationControllerGetHereditary = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetHereditary>, Error>
  ) => {
  const queryKey = getSituationControllerGetHereditaryQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetHereditary>, Error>(queryKey, () => situationControllerGetHereditary<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerGetMore = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? SituationMoreDTO : Data>(
      `/me/situation/more`,
    );
  }


export const getSituationControllerGetMoreQueryKey = () => [`/me/situation/more`]

    
export const useSituationControllerGetMore = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetMore>, Error>
  ) => {
  const queryKey = getSituationControllerGetMoreQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetMore>, Error>(queryKey, () => situationControllerGetMore<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerGetMyVaccines = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserVaccineDTO[] : Data>(
      `/me/situation/myVaccine`,
    );
  }


export const getSituationControllerGetMyVaccinesQueryKey = () => [`/me/situation/myVaccine`]

    
export const useSituationControllerGetMyVaccines = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetMyVaccines>, Error>
  ) => {
  const queryKey = getSituationControllerGetMyVaccinesQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetMyVaccines>, Error>(queryKey, () => situationControllerGetMyVaccines<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerGetVaccineTypes = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? VaccineDTO[] : Data>(
      `/me/situation/vaccine`,
    );
  }


export const getSituationControllerGetVaccineTypesQueryKey = () => [`/me/situation/vaccine`]

    
export const useSituationControllerGetVaccineTypes = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetVaccineTypes>, Error>
  ) => {
  const queryKey = getSituationControllerGetVaccineTypesQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetVaccineTypes>, Error>(queryKey, () => situationControllerGetVaccineTypes<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerGetMyBodySystem = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserBodySystemDTO[] : Data>(
      `/me/situation/myBody`,
    );
  }


export const getSituationControllerGetMyBodySystemQueryKey = () => [`/me/situation/myBody`]

    
export const useSituationControllerGetMyBodySystem = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetMyBodySystem>, Error>
  ) => {
  const queryKey = getSituationControllerGetMyBodySystemQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetMyBodySystem>, Error>(queryKey, () => situationControllerGetMyBodySystem<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerGetBodyTypes = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? BodySystemDTO[] : Data>(
      `/me/situation/body`,
    );
  }


export const getSituationControllerGetBodyTypesQueryKey = () => [`/me/situation/body`]

    
export const useSituationControllerGetBodyTypes = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetBodyTypes>, Error>
  ) => {
  const queryKey = getSituationControllerGetBodyTypesQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetBodyTypes>, Error>(queryKey, () => situationControllerGetBodyTypes<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerGetMyHospital = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserHospitalizationDTO[] : Data>(
      `/me/situation/hospital`,
    );
  }


export const getSituationControllerGetMyHospitalQueryKey = () => [`/me/situation/hospital`]

    
export const useSituationControllerGetMyHospital = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetMyHospital>, Error>
  ) => {
  const queryKey = getSituationControllerGetMyHospitalQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetMyHospital>, Error>(queryKey, () => situationControllerGetMyHospital<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerGetMySituation = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserSituationDTO[] : Data>(
      `/me/situation`,
    );
  }


export const getSituationControllerGetMySituationQueryKey = () => [`/me/situation`]

    
export const useSituationControllerGetMySituation = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetMySituation>, Error>
  ) => {
  const queryKey = getSituationControllerGetMySituationQueryKey();

  const query = useQuery<AsyncReturnType<typeof situationControllerGetMySituation>, Error>(queryKey, () => situationControllerGetMySituation<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerCreateMySituation = <Data = unknown>(
    createSituation: CreateSituation,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/situation`,
      createSituation,
    );
  }



export const useSituationControllerCreateMySituation = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerCreateMySituation>, Error, {data: CreateSituation}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerCreateMySituation>, Error, {data: CreateSituation}>((props) => {
    const {data} = props || {};

    return  situationControllerCreateMySituation<Data>(data)
  }, mutationConfig)
}
export const situationControllerGetSituationByType = <Data = unknown>(
    types: string,
  ) => {
    return axios.get<Data extends unknown ? SituationDTO[] : Data>(
      `/me/situation/${types}`,
    );
  }


export const getSituationControllerGetSituationByTypeQueryKey = (types: string,) => [`/me/situation/${types}`]

    
export const useSituationControllerGetSituationByType = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 types: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerGetSituationByType>, Error>
  ) => {
  const queryKey = getSituationControllerGetSituationByTypeQueryKey(types);

  const query = useQuery<AsyncReturnType<typeof situationControllerGetSituationByType>, Error>(queryKey, () => situationControllerGetSituationByType<Data>(types), {enabled: !!(types), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerSetDialogSituation = <Data = unknown>(
    id: number,
  ) => {
    return axios.get<Data extends unknown ? boolean : Data>(
      `/me/situation/setDialog/${id}`,
    );
  }


export const getSituationControllerSetDialogSituationQueryKey = (id: number,) => [`/me/situation/setDialog/${id}`]

    
export const useSituationControllerSetDialogSituation = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 id: number,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof situationControllerSetDialogSituation>, Error>
  ) => {
  const queryKey = getSituationControllerSetDialogSituationQueryKey(id);

  const query = useQuery<AsyncReturnType<typeof situationControllerSetDialogSituation>, Error>(queryKey, () => situationControllerSetDialogSituation<Data>(id), {enabled: !!(id), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const situationControllerCreateMyVaccine = <Data = unknown>(
    createVaccine: CreateVaccine,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/situation/createVaccine`,
      createVaccine,
    );
  }



export const useSituationControllerCreateMyVaccine = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerCreateMyVaccine>, Error, {data: CreateVaccine}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerCreateMyVaccine>, Error, {data: CreateVaccine}>((props) => {
    const {data} = props || {};

    return  situationControllerCreateMyVaccine<Data>(data)
  }, mutationConfig)
}
export const situationControllerCreateMyBodySystem = <Data = unknown>(
    createUserBodySystem: CreateUserBodySystem,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/situation/createBody`,
      createUserBodySystem,
    );
  }



export const useSituationControllerCreateMyBodySystem = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerCreateMyBodySystem>, Error, {data: CreateUserBodySystem}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerCreateMyBodySystem>, Error, {data: CreateUserBodySystem}>((props) => {
    const {data} = props || {};

    return  situationControllerCreateMyBodySystem<Data>(data)
  }, mutationConfig)
}
export const situationControllerCreateMyAddiction = <Data = unknown>(
    createAddiction: CreateAddiction,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/situation/createAddiction`,
      createAddiction,
    );
  }



export const useSituationControllerCreateMyAddiction = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerCreateMyAddiction>, Error, {data: CreateAddiction}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerCreateMyAddiction>, Error, {data: CreateAddiction}>((props) => {
    const {data} = props || {};

    return  situationControllerCreateMyAddiction<Data>(data)
  }, mutationConfig)
}
export const situationControllerCreateMyFirstAddiction = <Data = unknown>(
    createAddictionBatch: CreateAddictionBatch,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/situation/crateAddiction/multiple`,
      createAddictionBatch,
    );
  }



export const useSituationControllerCreateMyFirstAddiction = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerCreateMyFirstAddiction>, Error, {data: CreateAddictionBatch}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerCreateMyFirstAddiction>, Error, {data: CreateAddictionBatch}>((props) => {
    const {data} = props || {};

    return  situationControllerCreateMyFirstAddiction<Data>(data)
  }, mutationConfig)
}
export const situationControllerCreateMyHospital = <Data = unknown>(
    createHospitalization: CreateHospitalization,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/situation/createHospital`,
      createHospitalization,
    );
  }



export const useSituationControllerCreateMyHospital = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerCreateMyHospital>, Error, {data: CreateHospitalization}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerCreateMyHospital>, Error, {data: CreateHospitalization}>((props) => {
    const {data} = props || {};

    return  situationControllerCreateMyHospital<Data>(data)
  }, mutationConfig)
}
export const situationControllerSetSituation = <Data = unknown>(
    setSituation: SetSituation,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/situation/set`,
      setSituation,
    );
  }



export const useSituationControllerSetSituation = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerSetSituation>, Error, {data: SetSituation}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerSetSituation>, Error, {data: SetSituation}>((props) => {
    const {data} = props || {};

    return  situationControllerSetSituation<Data>(data)
  }, mutationConfig)
}
export const situationControllerSetMyFirstSituation = <Data = unknown>(
    createSituationBatch: CreateSituationBatch,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/situation/multiple`,
      createSituationBatch,
    );
  }



export const useSituationControllerSetMyFirstSituation = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerSetMyFirstSituation>, Error, {data: CreateSituationBatch}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerSetMyFirstSituation>, Error, {data: CreateSituationBatch}>((props) => {
    const {data} = props || {};

    return  situationControllerSetMyFirstSituation<Data>(data)
  }, mutationConfig)
}
export const situationControllerSetDanger = <Data = unknown>(
    
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/situation/danger`,
      undefined,
    );
  }



export const useSituationControllerSetDanger = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerSetDanger>, Error>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerSetDanger>, Error>(() => {
    ;

    return  situationControllerSetDanger<Data>()
  }, mutationConfig)
}
export const situationControllerUpdateMySituation = <Data = unknown>(
    id: number,
    updateSituation: UpdateSituation,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/situation/${id}`,
      updateSituation,
    );
  }



export const useSituationControllerUpdateMySituation = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerUpdateMySituation>, Error, {id: number;data: UpdateSituation}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerUpdateMySituation>, Error, {id: number;data: UpdateSituation}>((props) => {
    const {id,data} = props || {};

    return  situationControllerUpdateMySituation<Data>(id,data)
  }, mutationConfig)
}
export const situationControllerDeleteMySituation = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/situation/${id}`,
    );
  }



export const useSituationControllerDeleteMySituation = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerDeleteMySituation>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerDeleteMySituation>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  situationControllerDeleteMySituation<Data>(id)
  }, mutationConfig)
}
export const situationControllerUpdateMyVaccine = <Data = unknown>(
    id: number,
    createVaccine: CreateVaccine,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/situation/createVaccine/${id}`,
      createVaccine,
    );
  }



export const useSituationControllerUpdateMyVaccine = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerUpdateMyVaccine>, Error, {id: number;data: CreateVaccine}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerUpdateMyVaccine>, Error, {id: number;data: CreateVaccine}>((props) => {
    const {id,data} = props || {};

    return  situationControllerUpdateMyVaccine<Data>(id,data)
  }, mutationConfig)
}
export const situationControllerDeleteMyVaccine = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/situation/createVaccine/${id}`,
    );
  }



export const useSituationControllerDeleteMyVaccine = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerDeleteMyVaccine>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerDeleteMyVaccine>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  situationControllerDeleteMyVaccine<Data>(id)
  }, mutationConfig)
}
export const situationControllerUpdateMyBodySystem = <Data = unknown>(
    id: number,
    createUserBodySystem: CreateUserBodySystem,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/situation/createBody/${id}`,
      createUserBodySystem,
    );
  }



export const useSituationControllerUpdateMyBodySystem = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerUpdateMyBodySystem>, Error, {id: number;data: CreateUserBodySystem}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerUpdateMyBodySystem>, Error, {id: number;data: CreateUserBodySystem}>((props) => {
    const {id,data} = props || {};

    return  situationControllerUpdateMyBodySystem<Data>(id,data)
  }, mutationConfig)
}
export const situationControllerDeleteMyBodySystem = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/situation/createBody/${id}`,
    );
  }



export const useSituationControllerDeleteMyBodySystem = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerDeleteMyBodySystem>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerDeleteMyBodySystem>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  situationControllerDeleteMyBodySystem<Data>(id)
  }, mutationConfig)
}
export const situationControllerUpdateMyFamilyHistory = <Data = unknown>(
    id: number,
    createFamilyHistory: CreateFamilyHistory,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/situation/history/${id}`,
      createFamilyHistory,
    );
  }



export const useSituationControllerUpdateMyFamilyHistory = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerUpdateMyFamilyHistory>, Error, {id: number;data: CreateFamilyHistory}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerUpdateMyFamilyHistory>, Error, {id: number;data: CreateFamilyHistory}>((props) => {
    const {id,data} = props || {};

    return  situationControllerUpdateMyFamilyHistory<Data>(id,data)
  }, mutationConfig)
}
export const situationControllerDeleteMyFamilyHistory = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/situation/history/${id}`,
    );
  }



export const useSituationControllerDeleteMyFamilyHistory = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerDeleteMyFamilyHistory>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerDeleteMyFamilyHistory>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  situationControllerDeleteMyFamilyHistory<Data>(id)
  }, mutationConfig)
}
export const situationControllerUpdateMyHospital = <Data = unknown>(
    id: number,
    createHospitalization: CreateHospitalization,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/situation/hospital/${id}`,
      createHospitalization,
    );
  }



export const useSituationControllerUpdateMyHospital = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerUpdateMyHospital>, Error, {id: number;data: CreateHospitalization}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerUpdateMyHospital>, Error, {id: number;data: CreateHospitalization}>((props) => {
    const {id,data} = props || {};

    return  situationControllerUpdateMyHospital<Data>(id,data)
  }, mutationConfig)
}
export const situationControllerDeleteMyHospital = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/situation/hospital/${id}`,
    );
  }



export const useSituationControllerDeleteMyHospital = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerDeleteMyHospital>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerDeleteMyHospital>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  situationControllerDeleteMyHospital<Data>(id)
  }, mutationConfig)
}
export const situationControllerUpdateMyAddiction = <Data = unknown>(
    id: number,
    createAddiction: CreateAddiction,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/situation/addiction/${id}`,
      createAddiction,
    );
  }



export const useSituationControllerUpdateMyAddiction = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerUpdateMyAddiction>, Error, {id: number;data: CreateAddiction}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerUpdateMyAddiction>, Error, {id: number;data: CreateAddiction}>((props) => {
    const {id,data} = props || {};

    return  situationControllerUpdateMyAddiction<Data>(id,data)
  }, mutationConfig)
}
export const situationControllerDeleteMyAddiction = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/situation/addiction/${id}`,
    );
  }



export const useSituationControllerDeleteMyAddiction = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerDeleteMyAddiction>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerDeleteMyAddiction>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  situationControllerDeleteMyAddiction<Data>(id)
  }, mutationConfig)
}
export const situationControllerDeleteMySmoking = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/situation/addiction/smoking/${id}`,
    );
  }



export const useSituationControllerDeleteMySmoking = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof situationControllerDeleteMySmoking>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof situationControllerDeleteMySmoking>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  situationControllerDeleteMySmoking<Data>(id)
  }, mutationConfig)
}
export const meControllerGetMe = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserDto : Data>(
      `/me`,
    );
  }


export const getMeControllerGetMeQueryKey = () => [`/me`]

    
export const useMeControllerGetMe = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof meControllerGetMe>, Error>
  ) => {
  const queryKey = getMeControllerGetMeQueryKey();

  const query = useQuery<AsyncReturnType<typeof meControllerGetMe>, Error>(queryKey, () => meControllerGetMe<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const meControllerUpdateMe = <Data = unknown>(
    updateUser: UpdateUser,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me`,
      updateUser,
    );
  }



export const useMeControllerUpdateMe = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof meControllerUpdateMe>, Error, {data: UpdateUser}>
  ) => {
  return useMutation<AsyncReturnType<typeof meControllerUpdateMe>, Error, {data: UpdateUser}>((props) => {
    const {data} = props || {};

    return  meControllerUpdateMe<Data>(data)
  }, mutationConfig)
}
export const meControllerCreateBugReport = <Data = unknown>(
    createBugReport: CreateBugReport,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/bug`,
      createBugReport,
    );
  }



export const useMeControllerCreateBugReport = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof meControllerCreateBugReport>, Error, {data: CreateBugReport}>
  ) => {
  return useMutation<AsyncReturnType<typeof meControllerCreateBugReport>, Error, {data: CreateBugReport}>((props) => {
    const {data} = props || {};

    return  meControllerCreateBugReport<Data>(data)
  }, mutationConfig)
}
export const meControllerGetShortNote = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? ShortNote : Data>(
      `/me/shortNote`,
    );
  }


export const getMeControllerGetShortNoteQueryKey = () => [`/me/shortNote`]

    
export const useMeControllerGetShortNote = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof meControllerGetShortNote>, Error>
  ) => {
  const queryKey = getMeControllerGetShortNoteQueryKey();

  const query = useQuery<AsyncReturnType<typeof meControllerGetShortNote>, Error>(queryKey, () => meControllerGetShortNote<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const meControllerSetShortNote = <Data = unknown>(
    shortNote: ShortNote,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/shortNote`,
      shortNote,
    );
  }



export const useMeControllerSetShortNote = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof meControllerSetShortNote>, Error, {data: ShortNote}>
  ) => {
  return useMutation<AsyncReturnType<typeof meControllerSetShortNote>, Error, {data: ShortNote}>((props) => {
    const {data} = props || {};

    return  meControllerSetShortNote<Data>(data)
  }, mutationConfig)
}
export const meControllerDisableAccount = <Data = unknown>(
    disableUser: DisableUser,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/disable`,
      disableUser,
    );
  }



export const useMeControllerDisableAccount = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof meControllerDisableAccount>, Error, {data: DisableUser}>
  ) => {
  return useMutation<AsyncReturnType<typeof meControllerDisableAccount>, Error, {data: DisableUser}>((props) => {
    const {data} = props || {};

    return  meControllerDisableAccount<Data>(data)
  }, mutationConfig)
}
export const measurementsControllerGetMeasurementTypes = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? MeasurementDTO[] : Data>(
      `/me/measurements/types`,
    );
  }


export const getMeasurementsControllerGetMeasurementTypesQueryKey = () => [`/me/measurements/types`]

    
export const useMeasurementsControllerGetMeasurementTypes = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof measurementsControllerGetMeasurementTypes>, Error>
  ) => {
  const queryKey = getMeasurementsControllerGetMeasurementTypesQueryKey();

  const query = useQuery<AsyncReturnType<typeof measurementsControllerGetMeasurementTypes>, Error>(queryKey, () => measurementsControllerGetMeasurementTypes<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const measurementsControllerLatestMeasurements = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserMeasurementDTO[] : Data>(
      `/me/measurements/latest`,
    );
  }


export const getMeasurementsControllerLatestMeasurementsQueryKey = () => [`/me/measurements/latest`]

    
export const useMeasurementsControllerLatestMeasurements = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof measurementsControllerLatestMeasurements>, Error>
  ) => {
  const queryKey = getMeasurementsControllerLatestMeasurementsQueryKey();

  const query = useQuery<AsyncReturnType<typeof measurementsControllerLatestMeasurements>, Error>(queryKey, () => measurementsControllerLatestMeasurements<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const measurementsControllerGetMyMeasurementByType = <Data = unknown>(
    typeId: string,
  ) => {
    return axios.get<Data extends unknown ? UserMeasurementDTO[] : Data>(
      `/me/measurements/${typeId}`,
    );
  }


export const getMeasurementsControllerGetMyMeasurementByTypeQueryKey = (typeId: string,) => [`/me/measurements/${typeId}`]

    
export const useMeasurementsControllerGetMyMeasurementByType = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 typeId: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof measurementsControllerGetMyMeasurementByType>, Error>
  ) => {
  const queryKey = getMeasurementsControllerGetMyMeasurementByTypeQueryKey(typeId);

  const query = useQuery<AsyncReturnType<typeof measurementsControllerGetMyMeasurementByType>, Error>(queryKey, () => measurementsControllerGetMyMeasurementByType<Data>(typeId), {enabled: !!(typeId), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const measurementsControllerGetMeasurementByType = <Data = unknown>(
    typeId: string,
  ) => {
    return axios.get<Data extends unknown ? MeasurementDTO : Data>(
      `/me/measurements/types/${typeId}`,
    );
  }


export const getMeasurementsControllerGetMeasurementByTypeQueryKey = (typeId: string,) => [`/me/measurements/types/${typeId}`]

    
export const useMeasurementsControllerGetMeasurementByType = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 typeId: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof measurementsControllerGetMeasurementByType>, Error>
  ) => {
  const queryKey = getMeasurementsControllerGetMeasurementByTypeQueryKey(typeId);

  const query = useQuery<AsyncReturnType<typeof measurementsControllerGetMeasurementByType>, Error>(queryKey, () => measurementsControllerGetMeasurementByType<Data>(typeId), {enabled: !!(typeId), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const measurementsControllerCreateMyMeasurement = <Data = unknown>(
    createMeasurement: CreateMeasurement,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/measurements`,
      createMeasurement,
    );
  }



export const useMeasurementsControllerCreateMyMeasurement = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof measurementsControllerCreateMyMeasurement>, Error, {data: CreateMeasurement}>
  ) => {
  return useMutation<AsyncReturnType<typeof measurementsControllerCreateMyMeasurement>, Error, {data: CreateMeasurement}>((props) => {
    const {data} = props || {};

    return  measurementsControllerCreateMyMeasurement<Data>(data)
  }, mutationConfig)
}
export const measurementsControllerGetMyMeasurements = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserMeasurementDTO[] : Data>(
      `/me/measurements`,
    );
  }


export const getMeasurementsControllerGetMyMeasurementsQueryKey = () => [`/me/measurements`]

    
export const useMeasurementsControllerGetMyMeasurements = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof measurementsControllerGetMyMeasurements>, Error>
  ) => {
  const queryKey = getMeasurementsControllerGetMyMeasurementsQueryKey();

  const query = useQuery<AsyncReturnType<typeof measurementsControllerGetMyMeasurements>, Error>(queryKey, () => measurementsControllerGetMyMeasurements<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const measurementsControllerUpdateMyMeasurement = <Data = unknown>(
    id: number,
    updateMeasurement: UpdateMeasurement,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/measurements/${id}`,
      updateMeasurement,
    );
  }



export const useMeasurementsControllerUpdateMyMeasurement = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof measurementsControllerUpdateMyMeasurement>, Error, {id: number;data: UpdateMeasurement}>
  ) => {
  return useMutation<AsyncReturnType<typeof measurementsControllerUpdateMyMeasurement>, Error, {id: number;data: UpdateMeasurement}>((props) => {
    const {id,data} = props || {};

    return  measurementsControllerUpdateMyMeasurement<Data>(id,data)
  }, mutationConfig)
}
export const measurementsControllerDeleteMyMeasurement = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/measurements/${id}`,
    );
  }



export const useMeasurementsControllerDeleteMyMeasurement = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof measurementsControllerDeleteMyMeasurement>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof measurementsControllerDeleteMyMeasurement>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  measurementsControllerDeleteMyMeasurement<Data>(id)
  }, mutationConfig)
}
export const eventsControllerTest = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? EventsControllerTest200 : Data>(
      `/me/events`,
    );
  }


export const getEventsControllerTestQueryKey = () => [`/me/events`]

    
export const useEventsControllerTest = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof eventsControllerTest>, Error>
  ) => {
  const queryKey = getEventsControllerTestQueryKey();

  const query = useQuery<AsyncReturnType<typeof eventsControllerTest>, Error>(queryKey, () => eventsControllerTest<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const eventsControllerGetMyEventMessages = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserMessagesDto[] : Data>(
      `/me/events/messages`,
    );
  }


export const getEventsControllerGetMyEventMessagesQueryKey = () => [`/me/events/messages`]

    
export const useEventsControllerGetMyEventMessages = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof eventsControllerGetMyEventMessages>, Error>
  ) => {
  const queryKey = getEventsControllerGetMyEventMessagesQueryKey();

  const query = useQuery<AsyncReturnType<typeof eventsControllerGetMyEventMessages>, Error>(queryKey, () => eventsControllerGetMyEventMessages<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const eventsControllerDecline = <Data = unknown>(
    id: number,
  ) => {
    return axios.get<Data extends unknown ? boolean : Data>(
      `/me/events/decline/${id}`,
    );
  }


export const getEventsControllerDeclineQueryKey = (id: number,) => [`/me/events/decline/${id}`]

    
export const useEventsControllerDecline = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 id: number,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof eventsControllerDecline>, Error>
  ) => {
  const queryKey = getEventsControllerDeclineQueryKey(id);

  const query = useQuery<AsyncReturnType<typeof eventsControllerDecline>, Error>(queryKey, () => eventsControllerDecline<Data>(id), {enabled: !!(id), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const eventsControllerReadMessages = <Data = unknown>(
    readMessagesDTO: ReadMessagesDTO,
  ) => {
    return axios.post<Data extends unknown ? unknown : Data>(
      `/me/events/read`,
      readMessagesDTO,
    );
  }



export const useEventsControllerReadMessages = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof eventsControllerReadMessages>, Error, {data: ReadMessagesDTO}>
  ) => {
  return useMutation<AsyncReturnType<typeof eventsControllerReadMessages>, Error, {data: ReadMessagesDTO}>((props) => {
    const {data} = props || {};

    return  eventsControllerReadMessages<Data>(data)
  }, mutationConfig)
}
export const eventsControllerRunHealthScore = <Data = unknown>(
    
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/events/healthscore`,
      undefined,
    );
  }



export const useEventsControllerRunHealthScore = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof eventsControllerRunHealthScore>, Error>
  ) => {
  return useMutation<AsyncReturnType<typeof eventsControllerRunHealthScore>, Error>(() => {
    ;

    return  eventsControllerRunHealthScore<Data>()
  }, mutationConfig)
}
export const medicationControllerUserAddsColdMedicine = <Data = unknown>(
    usermedicineid: number,
  ) => {
    return axios.post<Data extends unknown ? unknown : Data>(
      `/me/medication/c15i/${usermedicineid}`,
      undefined,
    );
  }



export const useMedicationControllerUserAddsColdMedicine = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof medicationControllerUserAddsColdMedicine>, Error, {usermedicineid: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof medicationControllerUserAddsColdMedicine>, Error, {usermedicineid: number}>((props) => {
    const {usermedicineid} = props || {};

    return  medicationControllerUserAddsColdMedicine<Data>(usermedicineid)
  }, mutationConfig)
}
export const medicationControllerGetMedicines = <Data = unknown>(
    name: string,
  ) => {
    return axios.get<Data extends unknown ? MedicineDTO[] : Data>(
      `/me/medication/medicines/${name}`,
    );
  }


export const getMedicationControllerGetMedicinesQueryKey = (name: string,) => [`/me/medication/medicines/${name}`]

    
export const useMedicationControllerGetMedicines = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 name: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof medicationControllerGetMedicines>, Error>
  ) => {
  const queryKey = getMedicationControllerGetMedicinesQueryKey(name);

  const query = useQuery<AsyncReturnType<typeof medicationControllerGetMedicines>, Error>(queryKey, () => medicationControllerGetMedicines<Data>(name), {enabled: !!(name), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const medicationControllerGetMedicineShapes = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? MedicineShapeDto[] : Data>(
      `/me/medication/shapes`,
    );
  }


export const getMedicationControllerGetMedicineShapesQueryKey = () => [`/me/medication/shapes`]

    
export const useMedicationControllerGetMedicineShapes = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof medicationControllerGetMedicineShapes>, Error>
  ) => {
  const queryKey = getMedicationControllerGetMedicineShapesQueryKey();

  const query = useQuery<AsyncReturnType<typeof medicationControllerGetMedicineShapes>, Error>(queryKey, () => medicationControllerGetMedicineShapes<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const medicationControllerStopMedication = <Data = unknown>(
    id: number,
  ) => {
    return axios.get<Data extends unknown ? boolean : Data>(
      `/me/medication/stop/${id}`,
    );
  }


export const getMedicationControllerStopMedicationQueryKey = (id: number,) => [`/me/medication/stop/${id}`]

    
export const useMedicationControllerStopMedication = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 id: number,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof medicationControllerStopMedication>, Error>
  ) => {
  const queryKey = getMedicationControllerStopMedicationQueryKey(id);

  const query = useQuery<AsyncReturnType<typeof medicationControllerStopMedication>, Error>(queryKey, () => medicationControllerStopMedication<Data>(id), {enabled: !!(id), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const medicationControllerGetMedicineColors = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? MedicineColorDto[] : Data>(
      `/me/medication/colors`,
    );
  }


export const getMedicationControllerGetMedicineColorsQueryKey = () => [`/me/medication/colors`]

    
export const useMedicationControllerGetMedicineColors = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof medicationControllerGetMedicineColors>, Error>
  ) => {
  const queryKey = getMedicationControllerGetMedicineColorsQueryKey();

  const query = useQuery<AsyncReturnType<typeof medicationControllerGetMedicineColors>, Error>(queryKey, () => medicationControllerGetMedicineColors<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const medicationControllerGetMedicineByID = <Data = unknown>(
    id: number,
  ) => {
    return axios.get<Data extends unknown ? MedicineDTO : Data>(
      `/me/medication/medicine/${id}`,
    );
  }


export const getMedicationControllerGetMedicineByIDQueryKey = (id: number,) => [`/me/medication/medicine/${id}`]

    
export const useMedicationControllerGetMedicineByID = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 id: number,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof medicationControllerGetMedicineByID>, Error>
  ) => {
  const queryKey = getMedicationControllerGetMedicineByIDQueryKey(id);

  const query = useQuery<AsyncReturnType<typeof medicationControllerGetMedicineByID>, Error>(queryKey, () => medicationControllerGetMedicineByID<Data>(id), {enabled: !!(id), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const medicationControllerGetNextDosagesCalendar = <Data = unknown>(
    getNextDosages: GetNextDosages,
  ) => {
    return axios.post<Data extends unknown ? GetNextDosagesDTO[] : Data>(
      `/me/medication/dosages/calendar`,
      getNextDosages,
    );
  }



export const useMedicationControllerGetNextDosagesCalendar = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof medicationControllerGetNextDosagesCalendar>, Error, {data: GetNextDosages}>
  ) => {
  return useMutation<AsyncReturnType<typeof medicationControllerGetNextDosagesCalendar>, Error, {data: GetNextDosages}>((props) => {
    const {data} = props || {};

    return  medicationControllerGetNextDosagesCalendar<Data>(data)
  }, mutationConfig)
}
export const medicationControllerGetNextDosages = <Data = unknown>(
    getNextDosages: GetNextDosages,
  ) => {
    return axios.post<Data extends unknown ? GetNextDosagesDTO[] : Data>(
      `/me/medication/dosages`,
      getNextDosages,
    );
  }



export const useMedicationControllerGetNextDosages = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof medicationControllerGetNextDosages>, Error, {data: GetNextDosages}>
  ) => {
  return useMutation<AsyncReturnType<typeof medicationControllerGetNextDosages>, Error, {data: GetNextDosages}>((props) => {
    const {data} = props || {};

    return  medicationControllerGetNextDosages<Data>(data)
  }, mutationConfig)
}
export const medicationControllerGetMyMedication = <Data = unknown>(
    params?: MedicationControllerGetMyMedicationParams,
  ) => {
    return axios.get<Data extends unknown ? UserMedicationDTO[] : Data>(
      `/me/medication`,
      {
        params,
      },
    );
  }


export const getMedicationControllerGetMyMedicationQueryKey = (params?: MedicationControllerGetMyMedicationParams,) => [`/me/medication`, ...(params ? [params]: [])]

    
export const useMedicationControllerGetMyMedication = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 params?: MedicationControllerGetMyMedicationParams,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof medicationControllerGetMyMedication>, Error>
  ) => {
  const queryKey = getMedicationControllerGetMyMedicationQueryKey(params);

  const query = useQuery<AsyncReturnType<typeof medicationControllerGetMyMedication>, Error>(queryKey, () => medicationControllerGetMyMedication<Data>(params), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const medicationControllerCreateMyMedication = <Data = unknown>(
    createMedication: CreateMedication,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/medication`,
      createMedication,
    );
  }



export const useMedicationControllerCreateMyMedication = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof medicationControllerCreateMyMedication>, Error, {data: CreateMedication}>
  ) => {
  return useMutation<AsyncReturnType<typeof medicationControllerCreateMyMedication>, Error, {data: CreateMedication}>((props) => {
    const {data} = props || {};

    return  medicationControllerCreateMyMedication<Data>(data)
  }, mutationConfig)
}
export const medicationControllerDeleteMyMedication = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/medication/${id}`,
    );
  }



export const useMedicationControllerDeleteMyMedication = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof medicationControllerDeleteMyMedication>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof medicationControllerDeleteMyMedication>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  medicationControllerDeleteMyMedication<Data>(id)
  }, mutationConfig)
}
export const medicationControllerUpdateMyMedication = <Data = unknown>(
    id: number,
    updateMedication: UpdateMedication,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/medication/${id}`,
      updateMedication,
    );
  }



export const useMedicationControllerUpdateMyMedication = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof medicationControllerUpdateMyMedication>, Error, {id: number;data: UpdateMedication}>
  ) => {
  return useMutation<AsyncReturnType<typeof medicationControllerUpdateMyMedication>, Error, {id: number;data: UpdateMedication}>((props) => {
    const {id,data} = props || {};

    return  medicationControllerUpdateMyMedication<Data>(id,data)
  }, mutationConfig)
}
export const cycleControllerGetMyCycleDuration = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? DurationDTO : Data>(
      `/me/cycle/duration`,
    );
  }


export const getCycleControllerGetMyCycleDurationQueryKey = () => [`/me/cycle/duration`]

    
export const useCycleControllerGetMyCycleDuration = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof cycleControllerGetMyCycleDuration>, Error>
  ) => {
  const queryKey = getCycleControllerGetMyCycleDurationQueryKey();

  const query = useQuery<AsyncReturnType<typeof cycleControllerGetMyCycleDuration>, Error>(queryKey, () => cycleControllerGetMyCycleDuration<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const cycleControllerGetCyclePrediction = <Data = unknown>(
    createPrediction: CreatePrediction,
  ) => {
    return axios.post<Data extends unknown ? PredictionDTO : Data>(
      `/me/cycle/prediction`,
      createPrediction,
    );
  }



export const useCycleControllerGetCyclePrediction = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof cycleControllerGetCyclePrediction>, Error, {data: CreatePrediction}>
  ) => {
  return useMutation<AsyncReturnType<typeof cycleControllerGetCyclePrediction>, Error, {data: CreatePrediction}>((props) => {
    const {data} = props || {};

    return  cycleControllerGetCyclePrediction<Data>(data)
  }, mutationConfig)
}
export const cycleControllerGetCycle = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? CycleDTO[] : Data>(
      `/me/cycle`,
    );
  }


export const getCycleControllerGetCycleQueryKey = () => [`/me/cycle`]

    
export const useCycleControllerGetCycle = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof cycleControllerGetCycle>, Error>
  ) => {
  const queryKey = getCycleControllerGetCycleQueryKey();

  const query = useQuery<AsyncReturnType<typeof cycleControllerGetCycle>, Error>(queryKey, () => cycleControllerGetCycle<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const cycleControllerCreateMyCycle = <Data = unknown>(
    createCycle: CreateCycle,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/cycle`,
      createCycle,
    );
  }



export const useCycleControllerCreateMyCycle = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof cycleControllerCreateMyCycle>, Error, {data: CreateCycle}>
  ) => {
  return useMutation<AsyncReturnType<typeof cycleControllerCreateMyCycle>, Error, {data: CreateCycle}>((props) => {
    const {data} = props || {};

    return  cycleControllerCreateMyCycle<Data>(data)
  }, mutationConfig)
}
export const cycleControllerSetMyDuration = <Data = unknown>(
    createDuration: CreateDuration,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/cycle/set`,
      createDuration,
    );
  }



export const useCycleControllerSetMyDuration = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof cycleControllerSetMyDuration>, Error, {data: CreateDuration}>
  ) => {
  return useMutation<AsyncReturnType<typeof cycleControllerSetMyDuration>, Error, {data: CreateDuration}>((props) => {
    const {data} = props || {};

    return  cycleControllerSetMyDuration<Data>(data)
  }, mutationConfig)
}
export const cycleControllerUpdateMyCycle = <Data = unknown>(
    id: number,
    updateCycle: UpdateCycle,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/cycle/${id}`,
      updateCycle,
    );
  }



export const useCycleControllerUpdateMyCycle = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof cycleControllerUpdateMyCycle>, Error, {id: number;data: UpdateCycle}>
  ) => {
  return useMutation<AsyncReturnType<typeof cycleControllerUpdateMyCycle>, Error, {id: number;data: UpdateCycle}>((props) => {
    const {id,data} = props || {};

    return  cycleControllerUpdateMyCycle<Data>(id,data)
  }, mutationConfig)
}
export const cycleControllerDeleteMyCycle = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/cycle/${id}`,
    );
  }



export const useCycleControllerDeleteMyCycle = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof cycleControllerDeleteMyCycle>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof cycleControllerDeleteMyCycle>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  cycleControllerDeleteMyCycle<Data>(id)
  }, mutationConfig)
}
export const symptomControllerGetSymptomTypes = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? SymptomDTO[] : Data>(
      `/me/symptoms/alltypes`,
    );
  }


export const getSymptomControllerGetSymptomTypesQueryKey = () => [`/me/symptoms/alltypes`]

    
export const useSymptomControllerGetSymptomTypes = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof symptomControllerGetSymptomTypes>, Error>
  ) => {
  const queryKey = getSymptomControllerGetSymptomTypesQueryKey();

  const query = useQuery<AsyncReturnType<typeof symptomControllerGetSymptomTypes>, Error>(queryKey, () => symptomControllerGetSymptomTypes<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const symptomControllerGetMySymptomsByMonth = <Data = unknown>(
    month: string,
  ) => {
    return axios.get<Data extends unknown ? UserSymptomDTO[] : Data>(
      `/me/symptoms/all/${month}`,
    );
  }


export const getSymptomControllerGetMySymptomsByMonthQueryKey = (month: string,) => [`/me/symptoms/all/${month}`]

    
export const useSymptomControllerGetMySymptomsByMonth = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 month: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof symptomControllerGetMySymptomsByMonth>, Error>
  ) => {
  const queryKey = getSymptomControllerGetMySymptomsByMonthQueryKey(month);

  const query = useQuery<AsyncReturnType<typeof symptomControllerGetMySymptomsByMonth>, Error>(queryKey, () => symptomControllerGetMySymptomsByMonth<Data>(month), {enabled: !!(month), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const symptomControllerGetMySymptomsByType = <Data = unknown>(
    typeId: string,
  ) => {
    return axios.get<Data extends unknown ? UserSymptomDTO[] : Data>(
      `/me/symptoms/${typeId}`,
    );
  }


export const getSymptomControllerGetMySymptomsByTypeQueryKey = (typeId: string,) => [`/me/symptoms/${typeId}`]

    
export const useSymptomControllerGetMySymptomsByType = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 typeId: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof symptomControllerGetMySymptomsByType>, Error>
  ) => {
  const queryKey = getSymptomControllerGetMySymptomsByTypeQueryKey(typeId);

  const query = useQuery<AsyncReturnType<typeof symptomControllerGetMySymptomsByType>, Error>(queryKey, () => symptomControllerGetMySymptomsByType<Data>(typeId), {enabled: !!(typeId), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const symptomControllerGetMySymptomByDay = <Data = unknown>(
    day: string,
  ) => {
    return axios.get<Data extends unknown ? UserSymptomDTO[] : Data>(
      `/me/symptoms/search/${day}`,
    );
  }


export const getSymptomControllerGetMySymptomByDayQueryKey = (day: string,) => [`/me/symptoms/search/${day}`]

    
export const useSymptomControllerGetMySymptomByDay = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 day: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof symptomControllerGetMySymptomByDay>, Error>
  ) => {
  const queryKey = getSymptomControllerGetMySymptomByDayQueryKey(day);

  const query = useQuery<AsyncReturnType<typeof symptomControllerGetMySymptomByDay>, Error>(queryKey, () => symptomControllerGetMySymptomByDay<Data>(day), {enabled: !!(day), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const symptomControllerGetMySymptomByWeek = <Data = unknown>(
    week: string,
  ) => {
    return axios.get<Data extends unknown ? UserSymptomDTO[] : Data>(
      `/me/symptoms/covid/${week}`,
    );
  }


export const getSymptomControllerGetMySymptomByWeekQueryKey = (week: string,) => [`/me/symptoms/covid/${week}`]

    
export const useSymptomControllerGetMySymptomByWeek = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 week: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof symptomControllerGetMySymptomByWeek>, Error>
  ) => {
  const queryKey = getSymptomControllerGetMySymptomByWeekQueryKey(week);

  const query = useQuery<AsyncReturnType<typeof symptomControllerGetMySymptomByWeek>, Error>(queryKey, () => symptomControllerGetMySymptomByWeek<Data>(week), {enabled: !!(week), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const symptomControllerSearchSymptom = <Data = unknown>(
    name: string,
  ) => {
    return axios.get<Data extends unknown ? SearchSymptom[] : Data>(
      `/me/symptoms/search/${name}`,
    );
  }


export const getSymptomControllerSearchSymptomQueryKey = (name: string,) => [`/me/symptoms/search/${name}`]

    
export const useSymptomControllerSearchSymptom = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 name: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof symptomControllerSearchSymptom>, Error>
  ) => {
  const queryKey = getSymptomControllerSearchSymptomQueryKey(name);

  const query = useQuery<AsyncReturnType<typeof symptomControllerSearchSymptom>, Error>(queryKey, () => symptomControllerSearchSymptom<Data>(name), {enabled: !!(name), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const symptomControllerGetSymptomById = <Data = unknown>(
    typeId: string,
  ) => {
    return axios.get<Data extends unknown ? SymptomDTO : Data>(
      `/me/symptoms/types/${typeId}`,
    );
  }


export const getSymptomControllerGetSymptomByIdQueryKey = (typeId: string,) => [`/me/symptoms/types/${typeId}`]

    
export const useSymptomControllerGetSymptomById = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 typeId: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof symptomControllerGetSymptomById>, Error>
  ) => {
  const queryKey = getSymptomControllerGetSymptomByIdQueryKey(typeId);

  const query = useQuery<AsyncReturnType<typeof symptomControllerGetSymptomById>, Error>(queryKey, () => symptomControllerGetSymptomById<Data>(typeId), {enabled: !!(typeId), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const symptomControllerCreateMySymptom = <Data = unknown>(
    createSymptom: CreateSymptom,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/symptoms`,
      createSymptom,
    );
  }



export const useSymptomControllerCreateMySymptom = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof symptomControllerCreateMySymptom>, Error, {data: CreateSymptom}>
  ) => {
  return useMutation<AsyncReturnType<typeof symptomControllerCreateMySymptom>, Error, {data: CreateSymptom}>((props) => {
    const {data} = props || {};

    return  symptomControllerCreateMySymptom<Data>(data)
  }, mutationConfig)
}
export const symptomControllerUpdateMySymptom = <Data = unknown>(
    id: number,
    updateSymptom: UpdateSymptom,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/symptoms/${id}`,
      updateSymptom,
    );
  }



export const useSymptomControllerUpdateMySymptom = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof symptomControllerUpdateMySymptom>, Error, {id: number;data: UpdateSymptom}>
  ) => {
  return useMutation<AsyncReturnType<typeof symptomControllerUpdateMySymptom>, Error, {id: number;data: UpdateSymptom}>((props) => {
    const {id,data} = props || {};

    return  symptomControllerUpdateMySymptom<Data>(id,data)
  }, mutationConfig)
}
export const symptomControllerDeleteMySymptom = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/symptoms/${id}`,
    );
  }



export const useSymptomControllerDeleteMySymptom = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof symptomControllerDeleteMySymptom>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof symptomControllerDeleteMySymptom>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  symptomControllerDeleteMySymptom<Data>(id)
  }, mutationConfig)
}
export const scoreControllerGetMyScore = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserScoreDTO[] : Data>(
      `/me/score`,
    );
  }


export const getScoreControllerGetMyScoreQueryKey = () => [`/me/score`]

    
export const useScoreControllerGetMyScore = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof scoreControllerGetMyScore>, Error>
  ) => {
  const queryKey = getScoreControllerGetMyScoreQueryKey();

  const query = useQuery<AsyncReturnType<typeof scoreControllerGetMyScore>, Error>(queryKey, () => scoreControllerGetMyScore<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const scoreControllerGetQuestions = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? QuestionDTO[] : Data>(
      `/me/score/questions`,
    );
  }


export const getScoreControllerGetQuestionsQueryKey = () => [`/me/score/questions`]

    
export const useScoreControllerGetQuestions = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof scoreControllerGetQuestions>, Error>
  ) => {
  const queryKey = getScoreControllerGetQuestionsQueryKey();

  const query = useQuery<AsyncReturnType<typeof scoreControllerGetQuestions>, Error>(queryKey, () => scoreControllerGetQuestions<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const scoreControllerGetFeedbackByQuestionID = <Data = unknown>(
    questionID: number,
  ) => {
    return axios.get<Data extends unknown ? UserFeedbackDTO[] : Data>(
      `/me/score/feedback/${questionID}`,
    );
  }


export const getScoreControllerGetFeedbackByQuestionIDQueryKey = (questionID: number,) => [`/me/score/feedback/${questionID}`]

    
export const useScoreControllerGetFeedbackByQuestionID = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 questionID: number,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof scoreControllerGetFeedbackByQuestionID>, Error>
  ) => {
  const queryKey = getScoreControllerGetFeedbackByQuestionIDQueryKey(questionID);

  const query = useQuery<AsyncReturnType<typeof scoreControllerGetFeedbackByQuestionID>, Error>(queryKey, () => scoreControllerGetFeedbackByQuestionID<Data>(questionID), {enabled: !!(questionID), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const scoreControllerCreateMyFeedback = <Data = unknown>(
    createFeedbackDTO: CreateFeedbackDTO,
  ) => {
    return axios.post<Data extends unknown ? FeedbackResponse : Data>(
      `/me/score/feedback`,
      createFeedbackDTO,
    );
  }



export const useScoreControllerCreateMyFeedback = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof scoreControllerCreateMyFeedback>, Error, {data: CreateFeedbackDTO}>
  ) => {
  return useMutation<AsyncReturnType<typeof scoreControllerCreateMyFeedback>, Error, {data: CreateFeedbackDTO}>((props) => {
    const {data} = props || {};

    return  scoreControllerCreateMyFeedback<Data>(data)
  }, mutationConfig)
}
export const pdfControllerGetMedicalCenters = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? MedicalCenterDTO[] : Data>(
      `/me/pdf/centers`,
    );
  }


export const getPdfControllerGetMedicalCentersQueryKey = () => [`/me/pdf/centers`]

    
export const usePdfControllerGetMedicalCenters = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pdfControllerGetMedicalCenters>, Error>
  ) => {
  const queryKey = getPdfControllerGetMedicalCentersQueryKey();

  const query = useQuery<AsyncReturnType<typeof pdfControllerGetMedicalCenters>, Error>(queryKey, () => pdfControllerGetMedicalCenters<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const pdfControllerGetPdfCategories = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? PdfCategoryDTO[] : Data>(
      `/me/pdf/categories`,
    );
  }


export const getPdfControllerGetPdfCategoriesQueryKey = () => [`/me/pdf/categories`]

    
export const usePdfControllerGetPdfCategories = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pdfControllerGetPdfCategories>, Error>
  ) => {
  const queryKey = getPdfControllerGetPdfCategoriesQueryKey();

  const query = useQuery<AsyncReturnType<typeof pdfControllerGetPdfCategories>, Error>(queryKey, () => pdfControllerGetPdfCategories<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const pdfControllerGetExamById = <Data = unknown>(
    id: number,
  ) => {
    return axios.get<Data extends unknown ? UserPdfDTO : Data>(
      `/me/pdf/${id}`,
    );
  }


export const getPdfControllerGetExamByIdQueryKey = (id: number,) => [`/me/pdf/${id}`]

    
export const usePdfControllerGetExamById = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 id: number,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pdfControllerGetExamById>, Error>
  ) => {
  const queryKey = getPdfControllerGetExamByIdQueryKey(id);

  const query = useQuery<AsyncReturnType<typeof pdfControllerGetExamById>, Error>(queryKey, () => pdfControllerGetExamById<Data>(id), {enabled: !!(id), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const pdfControllerDeleteMyExam = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/pdf/${id}`,
    );
  }



export const usePdfControllerDeleteMyExam = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pdfControllerDeleteMyExam>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof pdfControllerDeleteMyExam>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  pdfControllerDeleteMyExam<Data>(id)
  }, mutationConfig)
}
export const pdfControllerGetMyExams = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserPdfDTO[] : Data>(
      `/me/pdf`,
    );
  }


export const getPdfControllerGetMyExamsQueryKey = () => [`/me/pdf`]

    
export const usePdfControllerGetMyExams = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pdfControllerGetMyExams>, Error>
  ) => {
  const queryKey = getPdfControllerGetMyExamsQueryKey();

  const query = useQuery<AsyncReturnType<typeof pdfControllerGetMyExams>, Error>(queryKey, () => pdfControllerGetMyExams<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const pdfControllerUploadFile = <Data = unknown>(
    fileUploadDto: FileUploadDto,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/pdf`,
      fileUploadDto,
    );
  }



export const usePdfControllerUploadFile = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pdfControllerUploadFile>, Error, {data: FileUploadDto}>
  ) => {
  return useMutation<AsyncReturnType<typeof pdfControllerUploadFile>, Error, {data: FileUploadDto}>((props) => {
    const {data} = props || {};

    return  pdfControllerUploadFile<Data>(data)
  }, mutationConfig)
}
export const pdfControllerUpdateExam = <Data = unknown>(
    updateUserPdf: UpdateUserPdf,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/pdf`,
      updateUserPdf,
    );
  }



export const usePdfControllerUpdateExam = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pdfControllerUpdateExam>, Error, {data: UpdateUserPdf}>
  ) => {
  return useMutation<AsyncReturnType<typeof pdfControllerUpdateExam>, Error, {data: UpdateUserPdf}>((props) => {
    const {data} = props || {};

    return  pdfControllerUpdateExam<Data>(data)
  }, mutationConfig)
}
export const noteControllerGetMyNotes = <Data = unknown>(
    params?: NoteControllerGetMyNotesParams,
  ) => {
    return axios.get<Data extends unknown ? NoteDto[] : Data>(
      `/me/notes`,
      {
        params,
      },
    );
  }


export const getNoteControllerGetMyNotesQueryKey = (params?: NoteControllerGetMyNotesParams,) => [`/me/notes`, ...(params ? [params]: [])]

    
export const useNoteControllerGetMyNotes = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 params?: NoteControllerGetMyNotesParams,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof noteControllerGetMyNotes>, Error>
  ) => {
  const queryKey = getNoteControllerGetMyNotesQueryKey(params);

  const query = useQuery<AsyncReturnType<typeof noteControllerGetMyNotes>, Error>(queryKey, () => noteControllerGetMyNotes<Data>(params), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const noteControllerCreateMyNote = <Data = unknown>(
    createNoteDto: CreateNoteDto,
  ) => {
    return axios.post<Data extends unknown ? FeedbackResponse : Data>(
      `/me/notes`,
      createNoteDto,
    );
  }



export const useNoteControllerCreateMyNote = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof noteControllerCreateMyNote>, Error, {data: CreateNoteDto}>
  ) => {
  return useMutation<AsyncReturnType<typeof noteControllerCreateMyNote>, Error, {data: CreateNoteDto}>((props) => {
    const {data} = props || {};

    return  noteControllerCreateMyNote<Data>(data)
  }, mutationConfig)
}
export const noteControllerGetMyNotesMonth = <Data = unknown>(
    month: string,
  ) => {
    return axios.get<Data extends unknown ? NoteDto[] : Data>(
      `/me/notes/${month}`,
    );
  }


export const getNoteControllerGetMyNotesMonthQueryKey = (month: string,) => [`/me/notes/${month}`]

    
export const useNoteControllerGetMyNotesMonth = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 month: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof noteControllerGetMyNotesMonth>, Error>
  ) => {
  const queryKey = getNoteControllerGetMyNotesMonthQueryKey(month);

  const query = useQuery<AsyncReturnType<typeof noteControllerGetMyNotesMonth>, Error>(queryKey, () => noteControllerGetMyNotesMonth<Data>(month), {enabled: !!(month), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const noteControllerUpdateMyNote = <Data = unknown>(
    id: number,
    createNoteDto: CreateNoteDto,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/notes/${id}`,
      createNoteDto,
    );
  }



export const useNoteControllerUpdateMyNote = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof noteControllerUpdateMyNote>, Error, {id: number;data: CreateNoteDto}>
  ) => {
  return useMutation<AsyncReturnType<typeof noteControllerUpdateMyNote>, Error, {id: number;data: CreateNoteDto}>((props) => {
    const {id,data} = props || {};

    return  noteControllerUpdateMyNote<Data>(id,data)
  }, mutationConfig)
}
export const noteControllerDeleteMyNote = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/notes/${id}`,
    );
  }



export const useNoteControllerDeleteMyNote = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof noteControllerDeleteMyNote>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof noteControllerDeleteMyNote>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  noteControllerDeleteMyNote<Data>(id)
  }, mutationConfig)
}
export const preventiveControllerGetMyPreventive = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? PreventiveResultDto : Data>(
      `/me/preventive`,
    );
  }


export const getPreventiveControllerGetMyPreventiveQueryKey = () => [`/me/preventive`]

    
export const usePreventiveControllerGetMyPreventive = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof preventiveControllerGetMyPreventive>, Error>
  ) => {
  const queryKey = getPreventiveControllerGetMyPreventiveQueryKey();

  const query = useQuery<AsyncReturnType<typeof preventiveControllerGetMyPreventive>, Error>(queryKey, () => preventiveControllerGetMyPreventive<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const preventiveControllerAddPreventive = <Data = unknown>(
    addPreventiveDTO: AddPreventiveDTO,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/preventive`,
      addPreventiveDTO,
    );
  }



export const usePreventiveControllerAddPreventive = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof preventiveControllerAddPreventive>, Error, {data: AddPreventiveDTO}>
  ) => {
  return useMutation<AsyncReturnType<typeof preventiveControllerAddPreventive>, Error, {data: AddPreventiveDTO}>((props) => {
    const {data} = props || {};

    return  preventiveControllerAddPreventive<Data>(data)
  }, mutationConfig)
}
export const preventiveControllerDeletePreventive = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/preventive/${id}`,
    );
  }



export const usePreventiveControllerDeletePreventive = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof preventiveControllerDeletePreventive>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof preventiveControllerDeletePreventive>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  preventiveControllerDeletePreventive<Data>(id)
  }, mutationConfig)
}
export const fitnessControllerGetMyFitness = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserFitnessDTO[] : Data>(
      `/me/fitness`,
    );
  }


export const getFitnessControllerGetMyFitnessQueryKey = () => [`/me/fitness`]

    
export const useFitnessControllerGetMyFitness = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof fitnessControllerGetMyFitness>, Error>
  ) => {
  const queryKey = getFitnessControllerGetMyFitnessQueryKey();

  const query = useQuery<AsyncReturnType<typeof fitnessControllerGetMyFitness>, Error>(queryKey, () => fitnessControllerGetMyFitness<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const fitnessControllerCreateMyFitness = <Data = unknown>(
    createFitness: CreateFitness,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/fitness`,
      createFitness,
    );
  }



export const useFitnessControllerCreateMyFitness = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof fitnessControllerCreateMyFitness>, Error, {data: CreateFitness}>
  ) => {
  return useMutation<AsyncReturnType<typeof fitnessControllerCreateMyFitness>, Error, {data: CreateFitness}>((props) => {
    const {data} = props || {};

    return  fitnessControllerCreateMyFitness<Data>(data)
  }, mutationConfig)
}
export const fitnessControllerGetFitnessTypes = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? FitnessDTO[] : Data>(
      `/me/fitness/types`,
    );
  }


export const getFitnessControllerGetFitnessTypesQueryKey = () => [`/me/fitness/types`]

    
export const useFitnessControllerGetFitnessTypes = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof fitnessControllerGetFitnessTypes>, Error>
  ) => {
  const queryKey = getFitnessControllerGetFitnessTypesQueryKey();

  const query = useQuery<AsyncReturnType<typeof fitnessControllerGetFitnessTypes>, Error>(queryKey, () => fitnessControllerGetFitnessTypes<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const fitnessControllerGetMyFitnessByType = <Data = unknown>(
    typeId: string,
  ) => {
    return axios.get<Data extends unknown ? UserFitnessDTO[] : Data>(
      `/me/fitness/${typeId}`,
    );
  }


export const getFitnessControllerGetMyFitnessByTypeQueryKey = (typeId: string,) => [`/me/fitness/${typeId}`]

    
export const useFitnessControllerGetMyFitnessByType = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 typeId: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof fitnessControllerGetMyFitnessByType>, Error>
  ) => {
  const queryKey = getFitnessControllerGetMyFitnessByTypeQueryKey(typeId);

  const query = useQuery<AsyncReturnType<typeof fitnessControllerGetMyFitnessByType>, Error>(queryKey, () => fitnessControllerGetMyFitnessByType<Data>(typeId), {enabled: !!(typeId), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const fitnessControllerSearchFitness = <Data = unknown>(
    name: string,
  ) => {
    return axios.get<Data extends unknown ? SearchFitness[] : Data>(
      `/me/fitness/search/${name}`,
    );
  }


export const getFitnessControllerSearchFitnessQueryKey = (name: string,) => [`/me/fitness/search/${name}`]

    
export const useFitnessControllerSearchFitness = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 name: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof fitnessControllerSearchFitness>, Error>
  ) => {
  const queryKey = getFitnessControllerSearchFitnessQueryKey(name);

  const query = useQuery<AsyncReturnType<typeof fitnessControllerSearchFitness>, Error>(queryKey, () => fitnessControllerSearchFitness<Data>(name), {enabled: !!(name), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const fitnessControllerGetFitnessById = <Data = unknown>(
    typeId: string,
  ) => {
    return axios.get<Data extends unknown ? FitnessDTO : Data>(
      `/me/fitness/types/${typeId}`,
    );
  }


export const getFitnessControllerGetFitnessByIdQueryKey = (typeId: string,) => [`/me/fitness/types/${typeId}`]

    
export const useFitnessControllerGetFitnessById = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 typeId: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof fitnessControllerGetFitnessById>, Error>
  ) => {
  const queryKey = getFitnessControllerGetFitnessByIdQueryKey(typeId);

  const query = useQuery<AsyncReturnType<typeof fitnessControllerGetFitnessById>, Error>(queryKey, () => fitnessControllerGetFitnessById<Data>(typeId), {enabled: !!(typeId), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const fitnessControllerUpdateMyFitness = <Data = unknown>(
    id: number,
    updateFitness: UpdateFitness,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/fitness/${id}`,
      updateFitness,
    );
  }



export const useFitnessControllerUpdateMyFitness = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof fitnessControllerUpdateMyFitness>, Error, {id: number;data: UpdateFitness}>
  ) => {
  return useMutation<AsyncReturnType<typeof fitnessControllerUpdateMyFitness>, Error, {id: number;data: UpdateFitness}>((props) => {
    const {id,data} = props || {};

    return  fitnessControllerUpdateMyFitness<Data>(id,data)
  }, mutationConfig)
}
export const fitnessControllerDeleteMyFitness = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/fitness/${id}`,
    );
  }



export const useFitnessControllerDeleteMyFitness = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof fitnessControllerDeleteMyFitness>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof fitnessControllerDeleteMyFitness>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  fitnessControllerDeleteMyFitness<Data>(id)
  }, mutationConfig)
}
export const insulinControllerGetMyInsulin = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserInsulinDTO[] : Data>(
      `/me/insulin`,
    );
  }


export const getInsulinControllerGetMyInsulinQueryKey = () => [`/me/insulin`]

    
export const useInsulinControllerGetMyInsulin = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof insulinControllerGetMyInsulin>, Error>
  ) => {
  const queryKey = getInsulinControllerGetMyInsulinQueryKey();

  const query = useQuery<AsyncReturnType<typeof insulinControllerGetMyInsulin>, Error>(queryKey, () => insulinControllerGetMyInsulin<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const insulinControllerCreateMyInsulin = <Data = unknown>(
    createInsulin: CreateInsulin,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/insulin`,
      createInsulin,
    );
  }



export const useInsulinControllerCreateMyInsulin = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof insulinControllerCreateMyInsulin>, Error, {data: CreateInsulin}>
  ) => {
  return useMutation<AsyncReturnType<typeof insulinControllerCreateMyInsulin>, Error, {data: CreateInsulin}>((props) => {
    const {data} = props || {};

    return  insulinControllerCreateMyInsulin<Data>(data)
  }, mutationConfig)
}
export const insulinControllerGetInsulinTypes = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? InsulinDTO[] : Data>(
      `/me/insulin/types`,
    );
  }


export const getInsulinControllerGetInsulinTypesQueryKey = () => [`/me/insulin/types`]

    
export const useInsulinControllerGetInsulinTypes = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof insulinControllerGetInsulinTypes>, Error>
  ) => {
  const queryKey = getInsulinControllerGetInsulinTypesQueryKey();

  const query = useQuery<AsyncReturnType<typeof insulinControllerGetInsulinTypes>, Error>(queryKey, () => insulinControllerGetInsulinTypes<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const insulinControllerUpdateMyInsulin = <Data = unknown>(
    id: number,
    updateInsulin: UpdateInsulin,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/insulin/${id}`,
      updateInsulin,
    );
  }



export const useInsulinControllerUpdateMyInsulin = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof insulinControllerUpdateMyInsulin>, Error, {id: number;data: UpdateInsulin}>
  ) => {
  return useMutation<AsyncReturnType<typeof insulinControllerUpdateMyInsulin>, Error, {id: number;data: UpdateInsulin}>((props) => {
    const {id,data} = props || {};

    return  insulinControllerUpdateMyInsulin<Data>(id,data)
  }, mutationConfig)
}
export const insulinControllerDeleteMyInsulin = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/insulin/${id}`,
    );
  }



export const useInsulinControllerDeleteMyInsulin = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof insulinControllerDeleteMyInsulin>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof insulinControllerDeleteMyInsulin>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  insulinControllerDeleteMyInsulin<Data>(id)
  }, mutationConfig)
}
export const bloodControllerGetMyBlood = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? BloodDTO : Data>(
      `/me/blood`,
    );
  }


export const getBloodControllerGetMyBloodQueryKey = () => [`/me/blood`]

    
export const useBloodControllerGetMyBlood = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof bloodControllerGetMyBlood>, Error>
  ) => {
  const queryKey = getBloodControllerGetMyBloodQueryKey();

  const query = useQuery<AsyncReturnType<typeof bloodControllerGetMyBlood>, Error>(queryKey, () => bloodControllerGetMyBlood<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const bloodControllerSetMyBlood = <Data = unknown>(
    createBlood: CreateBlood,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/blood`,
      createBlood,
    );
  }



export const useBloodControllerSetMyBlood = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof bloodControllerSetMyBlood>, Error, {data: CreateBlood}>
  ) => {
  return useMutation<AsyncReturnType<typeof bloodControllerSetMyBlood>, Error, {data: CreateBlood}>((props) => {
    const {data} = props || {};

    return  bloodControllerSetMyBlood<Data>(data)
  }, mutationConfig)
}
export const bloodControllerDeleteMyBlood = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/blood/${id}`,
    );
  }



export const useBloodControllerDeleteMyBlood = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof bloodControllerDeleteMyBlood>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof bloodControllerDeleteMyBlood>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  bloodControllerDeleteMyBlood<Data>(id)
  }, mutationConfig)
}
export const enviromentalControllerGetMyEnviromental = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserEnviromentalDTO[] : Data>(
      `/me/enviromental`,
    );
  }


export const getEnviromentalControllerGetMyEnviromentalQueryKey = () => [`/me/enviromental`]

    
export const useEnviromentalControllerGetMyEnviromental = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof enviromentalControllerGetMyEnviromental>, Error>
  ) => {
  const queryKey = getEnviromentalControllerGetMyEnviromentalQueryKey();

  const query = useQuery<AsyncReturnType<typeof enviromentalControllerGetMyEnviromental>, Error>(queryKey, () => enviromentalControllerGetMyEnviromental<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const enviromentalControllerCreateMyEnviromental = <Data = unknown>(
    createEnviromental: CreateEnviromental,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/enviromental`,
      createEnviromental,
    );
  }



export const useEnviromentalControllerCreateMyEnviromental = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof enviromentalControllerCreateMyEnviromental>, Error, {data: CreateEnviromental}>
  ) => {
  return useMutation<AsyncReturnType<typeof enviromentalControllerCreateMyEnviromental>, Error, {data: CreateEnviromental}>((props) => {
    const {data} = props || {};

    return  enviromentalControllerCreateMyEnviromental<Data>(data)
  }, mutationConfig)
}
export const enviromentalControllerGetMyLatestEnviromentals = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? LatestEmviromentals : Data>(
      `/me/enviromental/latest`,
    );
  }


export const getEnviromentalControllerGetMyLatestEnviromentalsQueryKey = () => [`/me/enviromental/latest`]

    
export const useEnviromentalControllerGetMyLatestEnviromentals = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof enviromentalControllerGetMyLatestEnviromentals>, Error>
  ) => {
  const queryKey = getEnviromentalControllerGetMyLatestEnviromentalsQueryKey();

  const query = useQuery<AsyncReturnType<typeof enviromentalControllerGetMyLatestEnviromentals>, Error>(queryKey, () => enviromentalControllerGetMyLatestEnviromentals<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const enviromentalControllerGetMyEnviromentalByType = <Data = unknown>(
    typeId: number,
  ) => {
    return axios.get<Data extends unknown ? UserEnviromentalDTO[] : Data>(
      `/me/enviromental/${typeId}`,
    );
  }


export const getEnviromentalControllerGetMyEnviromentalByTypeQueryKey = (typeId: number,) => [`/me/enviromental/${typeId}`]

    
export const useEnviromentalControllerGetMyEnviromentalByType = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 typeId: number,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof enviromentalControllerGetMyEnviromentalByType>, Error>
  ) => {
  const queryKey = getEnviromentalControllerGetMyEnviromentalByTypeQueryKey(typeId);

  const query = useQuery<AsyncReturnType<typeof enviromentalControllerGetMyEnviromentalByType>, Error>(queryKey, () => enviromentalControllerGetMyEnviromentalByType<Data>(typeId), {enabled: !!(typeId), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const enviromentalControllerUpdateMyEnviromental = <Data = unknown>(
    id: number,
    updateEnviromental: UpdateEnviromental,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/enviromental/${id}`,
      updateEnviromental,
    );
  }



export const useEnviromentalControllerUpdateMyEnviromental = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof enviromentalControllerUpdateMyEnviromental>, Error, {id: number;data: UpdateEnviromental}>
  ) => {
  return useMutation<AsyncReturnType<typeof enviromentalControllerUpdateMyEnviromental>, Error, {id: number;data: UpdateEnviromental}>((props) => {
    const {id,data} = props || {};

    return  enviromentalControllerUpdateMyEnviromental<Data>(id,data)
  }, mutationConfig)
}
export const enviromentalControllerDeleteMyEnviromental = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/enviromental/${id}`,
    );
  }



export const useEnviromentalControllerDeleteMyEnviromental = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof enviromentalControllerDeleteMyEnviromental>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof enviromentalControllerDeleteMyEnviromental>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  enviromentalControllerDeleteMyEnviromental<Data>(id)
  }, mutationConfig)
}
export const covidControllerGetMyCovid = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserCovidDTO[] : Data>(
      `/me/covid`,
    );
  }


export const getCovidControllerGetMyCovidQueryKey = () => [`/me/covid`]

    
export const useCovidControllerGetMyCovid = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof covidControllerGetMyCovid>, Error>
  ) => {
  const queryKey = getCovidControllerGetMyCovidQueryKey();

  const query = useQuery<AsyncReturnType<typeof covidControllerGetMyCovid>, Error>(queryKey, () => covidControllerGetMyCovid<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const covidControllerCreateMyCovid = <Data = unknown>(
    createCovid: CreateCovid,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/covid`,
      createCovid,
    );
  }



export const useCovidControllerCreateMyCovid = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof covidControllerCreateMyCovid>, Error, {data: CreateCovid}>
  ) => {
  return useMutation<AsyncReturnType<typeof covidControllerCreateMyCovid>, Error, {data: CreateCovid}>((props) => {
    const {data} = props || {};

    return  covidControllerCreateMyCovid<Data>(data)
  }, mutationConfig)
}
export const covidControllerUpdateMyCovid = <Data = unknown>(
    id: string,
    updateCovid: UpdateCovid,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/covid/${id}`,
      updateCovid,
    );
  }



export const useCovidControllerUpdateMyCovid = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof covidControllerUpdateMyCovid>, Error, {id: string;data: UpdateCovid}>
  ) => {
  return useMutation<AsyncReturnType<typeof covidControllerUpdateMyCovid>, Error, {id: string;data: UpdateCovid}>((props) => {
    const {id,data} = props || {};

    return  covidControllerUpdateMyCovid<Data>(id,data)
  }, mutationConfig)
}
export const covidControllerDeleteMyCovid = <Data = unknown>(
    id: string,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/covid/${id}`,
    );
  }



export const useCovidControllerDeleteMyCovid = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof covidControllerDeleteMyCovid>, Error, {id: string}>
  ) => {
  return useMutation<AsyncReturnType<typeof covidControllerDeleteMyCovid>, Error, {id: string}>((props) => {
    const {id} = props || {};

    return  covidControllerDeleteMyCovid<Data>(id)
  }, mutationConfig)
}
export const diagnosisControllerGetOsteoData = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? OsteoDTO : Data>(
      `/diagnosis/osteo`,
    );
  }


export const getDiagnosisControllerGetOsteoDataQueryKey = () => [`/diagnosis/osteo`]

    
export const useDiagnosisControllerGetOsteoData = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof diagnosisControllerGetOsteoData>, Error>
  ) => {
  const queryKey = getDiagnosisControllerGetOsteoDataQueryKey();

  const query = useQuery<AsyncReturnType<typeof diagnosisControllerGetOsteoData>, Error>(queryKey, () => diagnosisControllerGetOsteoData<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const diagnosisControllerBeingOsteoDiagnosis = <Data = unknown>(
    osteoCalculateDTO: OsteoCalculateDTO,
  ) => {
    return axios.post<Data extends unknown ? OsteoResultDTO : Data>(
      `/diagnosis/osteo`,
      osteoCalculateDTO,
    );
  }



export const useDiagnosisControllerBeingOsteoDiagnosis = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof diagnosisControllerBeingOsteoDiagnosis>, Error, {data: OsteoCalculateDTO}>
  ) => {
  return useMutation<AsyncReturnType<typeof diagnosisControllerBeingOsteoDiagnosis>, Error, {data: OsteoCalculateDTO}>((props) => {
    const {data} = props || {};

    return  diagnosisControllerBeingOsteoDiagnosis<Data>(data)
  }, mutationConfig)
}
export const diagnosisControllerGetStrokeAssesmentData = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? StrokeDTO : Data>(
      `/diagnosis/stroke`,
    );
  }


export const getDiagnosisControllerGetStrokeAssesmentDataQueryKey = () => [`/diagnosis/stroke`]

    
export const useDiagnosisControllerGetStrokeAssesmentData = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof diagnosisControllerGetStrokeAssesmentData>, Error>
  ) => {
  const queryKey = getDiagnosisControllerGetStrokeAssesmentDataQueryKey();

  const query = useQuery<AsyncReturnType<typeof diagnosisControllerGetStrokeAssesmentData>, Error>(queryKey, () => diagnosisControllerGetStrokeAssesmentData<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const diagnosisControllerGetDiagnosisData = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? DiagnosisDTO : Data>(
      `/diagnosis`,
    );
  }


export const getDiagnosisControllerGetDiagnosisDataQueryKey = () => [`/diagnosis`]

    
export const useDiagnosisControllerGetDiagnosisData = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof diagnosisControllerGetDiagnosisData>, Error>
  ) => {
  const queryKey = getDiagnosisControllerGetDiagnosisDataQueryKey();

  const query = useQuery<AsyncReturnType<typeof diagnosisControllerGetDiagnosisData>, Error>(queryKey, () => diagnosisControllerGetDiagnosisData<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const diagnosisControllerBeginDiagnosis = <Data = unknown>(
    postDiagnosisDTO: PostDiagnosisDTO,
  ) => {
    return axios.post<Data extends unknown ? DiagnosisResultDTO : Data>(
      `/diagnosis`,
      postDiagnosisDTO,
    );
  }



export const useDiagnosisControllerBeginDiagnosis = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof diagnosisControllerBeginDiagnosis>, Error, {data: PostDiagnosisDTO}>
  ) => {
  return useMutation<AsyncReturnType<typeof diagnosisControllerBeginDiagnosis>, Error, {data: PostDiagnosisDTO}>((props) => {
    const {data} = props || {};

    return  diagnosisControllerBeginDiagnosis<Data>(data)
  }, mutationConfig)
}
export const pregnancyControllerGetPregnancyDetails = <Data = unknown>(
    week: number,
  ) => {
    return axios.get<Data extends unknown ? PregnancyDetailsDTO[] : Data>(
      `/me/pregnancy/details/${week}`,
    );
  }


export const getPregnancyControllerGetPregnancyDetailsQueryKey = (week: number,) => [`/me/pregnancy/details/${week}`]

    
export const usePregnancyControllerGetPregnancyDetails = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 week: number,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pregnancyControllerGetPregnancyDetails>, Error>
  ) => {
  const queryKey = getPregnancyControllerGetPregnancyDetailsQueryKey(week);

  const query = useQuery<AsyncReturnType<typeof pregnancyControllerGetPregnancyDetails>, Error>(queryKey, () => pregnancyControllerGetPregnancyDetails<Data>(week), {enabled: !!(week), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const pregnancyControllerGetPregnancyPdf = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? PregnancyPdfDTO[] : Data>(
      `/me/pregnancy/pdf`,
    );
  }


export const getPregnancyControllerGetPregnancyPdfQueryKey = () => [`/me/pregnancy/pdf`]

    
export const usePregnancyControllerGetPregnancyPdf = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pregnancyControllerGetPregnancyPdf>, Error>
  ) => {
  const queryKey = getPregnancyControllerGetPregnancyPdfQueryKey();

  const query = useQuery<AsyncReturnType<typeof pregnancyControllerGetPregnancyPdf>, Error>(queryKey, () => pregnancyControllerGetPregnancyPdf<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const pregnancyControllerGetMyPregnancyExams = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? UserPregnancyPdfDTO[] : Data>(
      `/me/pregnancy/userPdf`,
    );
  }


export const getPregnancyControllerGetMyPregnancyExamsQueryKey = () => [`/me/pregnancy/userPdf`]

    
export const usePregnancyControllerGetMyPregnancyExams = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pregnancyControllerGetMyPregnancyExams>, Error>
  ) => {
  const queryKey = getPregnancyControllerGetMyPregnancyExamsQueryKey();

  const query = useQuery<AsyncReturnType<typeof pregnancyControllerGetMyPregnancyExams>, Error>(queryKey, () => pregnancyControllerGetMyPregnancyExams<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const pregnancyControllerGetMyPregnancyMeasurements = <Data = unknown>(
    time: string,
  ) => {
    return axios.get<Data extends unknown ? UserMeasurementDTO[] : Data>(
      `/me/pregnancy/PregnancyMeasurements/${time}`,
    );
  }


export const getPregnancyControllerGetMyPregnancyMeasurementsQueryKey = (time: string,) => [`/me/pregnancy/PregnancyMeasurements/${time}`]

    
export const usePregnancyControllerGetMyPregnancyMeasurements = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 time: string,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pregnancyControllerGetMyPregnancyMeasurements>, Error>
  ) => {
  const queryKey = getPregnancyControllerGetMyPregnancyMeasurementsQueryKey(time);

  const query = useQuery<AsyncReturnType<typeof pregnancyControllerGetMyPregnancyMeasurements>, Error>(queryKey, () => pregnancyControllerGetMyPregnancyMeasurements<Data>(time), {enabled: !!(time), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const pregnancyControllerGetMyKidsMeasurements = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? KidMeasurementDTO[] : Data>(
      `/me/pregnancy/kid/measurements`,
    );
  }


export const getPregnancyControllerGetMyKidsMeasurementsQueryKey = () => [`/me/pregnancy/kid/measurements`]

    
export const usePregnancyControllerGetMyKidsMeasurements = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pregnancyControllerGetMyKidsMeasurements>, Error>
  ) => {
  const queryKey = getPregnancyControllerGetMyKidsMeasurementsQueryKey();

  const query = useQuery<AsyncReturnType<typeof pregnancyControllerGetMyKidsMeasurements>, Error>(queryKey, () => pregnancyControllerGetMyKidsMeasurements<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const pregnancyControllerGetMyKidsSymptoms = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? KidSymptomDTO[] : Data>(
      `/me/pregnancy/kid/symptoms`,
    );
  }


export const getPregnancyControllerGetMyKidsSymptomsQueryKey = () => [`/me/pregnancy/kid/symptoms`]

    
export const usePregnancyControllerGetMyKidsSymptoms = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pregnancyControllerGetMyKidsSymptoms>, Error>
  ) => {
  const queryKey = getPregnancyControllerGetMyKidsSymptomsQueryKey();

  const query = useQuery<AsyncReturnType<typeof pregnancyControllerGetMyKidsSymptoms>, Error>(queryKey, () => pregnancyControllerGetMyKidsSymptoms<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const pregnancyControllerGetMyKidsVaccines = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? KidVaccineDTO[] : Data>(
      `/me/pregnancy/kid/vaccines`,
    );
  }


export const getPregnancyControllerGetMyKidsVaccinesQueryKey = () => [`/me/pregnancy/kid/vaccines`]

    
export const usePregnancyControllerGetMyKidsVaccines = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pregnancyControllerGetMyKidsVaccines>, Error>
  ) => {
  const queryKey = getPregnancyControllerGetMyKidsVaccinesQueryKey();

  const query = useQuery<AsyncReturnType<typeof pregnancyControllerGetMyKidsVaccines>, Error>(queryKey, () => pregnancyControllerGetMyKidsVaccines<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const pregnancyControllerGetMyPregnancy = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? PregnancyDTO[] : Data>(
      `/me/pregnancy`,
    );
  }


export const getPregnancyControllerGetMyPregnancyQueryKey = () => [`/me/pregnancy`]

    
export const usePregnancyControllerGetMyPregnancy = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof pregnancyControllerGetMyPregnancy>, Error>
  ) => {
  const queryKey = getPregnancyControllerGetMyPregnancyQueryKey();

  const query = useQuery<AsyncReturnType<typeof pregnancyControllerGetMyPregnancy>, Error>(queryKey, () => pregnancyControllerGetMyPregnancy<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const pregnancyControllerCreateMyPregnancy = <Data = unknown>(
    createPregnancy: CreatePregnancy,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/pregnancy`,
      createPregnancy,
    );
  }



export const usePregnancyControllerCreateMyPregnancy = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerCreateMyPregnancy>, Error, {data: CreatePregnancy}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerCreateMyPregnancy>, Error, {data: CreatePregnancy}>((props) => {
    const {data} = props || {};

    return  pregnancyControllerCreateMyPregnancy<Data>(data)
  }, mutationConfig)
}
export const pregnancyControllerCreateMyKids = <Data = unknown>(
    createKid: CreateKid,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/kids`,
      createKid,
    );
  }



export const usePregnancyControllerCreateMyKids = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerCreateMyKids>, Error, {data: CreateKid}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerCreateMyKids>, Error, {data: CreateKid}>((props) => {
    const {data} = props || {};

    return  pregnancyControllerCreateMyKids<Data>(data)
  }, mutationConfig)
}
export const pregnancyControllerCreateMyPregnancyPdfRelation = <Data = unknown>(
    createPregnancyPdf: CreatePregnancyPdf,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/userpdf/relation`,
      createPregnancyPdf,
    );
  }



export const usePregnancyControllerCreateMyPregnancyPdfRelation = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerCreateMyPregnancyPdfRelation>, Error, {data: CreatePregnancyPdf}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerCreateMyPregnancyPdfRelation>, Error, {data: CreatePregnancyPdf}>((props) => {
    const {data} = props || {};

    return  pregnancyControllerCreateMyPregnancyPdfRelation<Data>(data)
  }, mutationConfig)
}
export const pregnancyControllerCreateMyPregnancyPdf = <Data = unknown>(
    pregnancyBodyDTO: PregnancyBodyDTO,
  ) => {
    return axios.post<Data extends unknown ? PdfResponseDTO : Data>(
      `/me/pregnancy/userpdf`,
      pregnancyBodyDTO,
    );
  }



export const usePregnancyControllerCreateMyPregnancyPdf = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerCreateMyPregnancyPdf>, Error, {data: PregnancyBodyDTO}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerCreateMyPregnancyPdf>, Error, {data: PregnancyBodyDTO}>((props) => {
    const {data} = props || {};

    return  pregnancyControllerCreateMyPregnancyPdf<Data>(data)
  }, mutationConfig)
}
export const pregnancyControllerGetMyBMI = <Data = unknown>(
    pregnancyBMIDTO: PregnancyBMIDTO,
  ) => {
    return axios.post<Data extends unknown ? Bmidto : Data>(
      `/me/pregnancy/BMI`,
      pregnancyBMIDTO,
    );
  }



export const usePregnancyControllerGetMyBMI = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerGetMyBMI>, Error, {data: PregnancyBMIDTO}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerGetMyBMI>, Error, {data: PregnancyBMIDTO}>((props) => {
    const {data} = props || {};

    return  pregnancyControllerGetMyBMI<Data>(data)
  }, mutationConfig)
}
export const pregnancyControllerCreateMyKidsMeasurement = <Data = unknown>(
    createKidMeasurement: CreateKidMeasurement,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/kid/measurement`,
      createKidMeasurement,
    );
  }



export const usePregnancyControllerCreateMyKidsMeasurement = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerCreateMyKidsMeasurement>, Error, {data: CreateKidMeasurement}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerCreateMyKidsMeasurement>, Error, {data: CreateKidMeasurement}>((props) => {
    const {data} = props || {};

    return  pregnancyControllerCreateMyKidsMeasurement<Data>(data)
  }, mutationConfig)
}
export const pregnancyControllerCreateMyKidsSymptom = <Data = unknown>(
    createKidSymptom: CreateKidSymptom,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/kid/symptom`,
      createKidSymptom,
    );
  }



export const usePregnancyControllerCreateMyKidsSymptom = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerCreateMyKidsSymptom>, Error, {data: CreateKidSymptom}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerCreateMyKidsSymptom>, Error, {data: CreateKidSymptom}>((props) => {
    const {data} = props || {};

    return  pregnancyControllerCreateMyKidsSymptom<Data>(data)
  }, mutationConfig)
}
export const pregnancyControllerCreateMyKidsVaccine = <Data = unknown>(
    createKidVaccine: CreateKidVaccine,
  ) => {
    return axios.post<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/kid/vaccine`,
      createKidVaccine,
    );
  }



export const usePregnancyControllerCreateMyKidsVaccine = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerCreateMyKidsVaccine>, Error, {data: CreateKidVaccine}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerCreateMyKidsVaccine>, Error, {data: CreateKidVaccine}>((props) => {
    const {data} = props || {};

    return  pregnancyControllerCreateMyKidsVaccine<Data>(data)
  }, mutationConfig)
}
export const pregnancyControllerUpdateMyKidsMeasurement = <Data = unknown>(
    id: number,
    createKidMeasurement: CreateKidMeasurement,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/kid/measurement/${id}`,
      createKidMeasurement,
    );
  }



export const usePregnancyControllerUpdateMyKidsMeasurement = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerUpdateMyKidsMeasurement>, Error, {id: number;data: CreateKidMeasurement}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerUpdateMyKidsMeasurement>, Error, {id: number;data: CreateKidMeasurement}>((props) => {
    const {id,data} = props || {};

    return  pregnancyControllerUpdateMyKidsMeasurement<Data>(id,data)
  }, mutationConfig)
}
export const pregnancyControllerDeleteMyKidMeasurement = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/kid/measurement/${id}`,
    );
  }



export const usePregnancyControllerDeleteMyKidMeasurement = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerDeleteMyKidMeasurement>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerDeleteMyKidMeasurement>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  pregnancyControllerDeleteMyKidMeasurement<Data>(id)
  }, mutationConfig)
}
export const pregnancyControllerUpdateMyKidsSymptom = <Data = unknown>(
    id: number,
    createKidSymptom: CreateKidSymptom,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/kid/symptom/${id}`,
      createKidSymptom,
    );
  }



export const usePregnancyControllerUpdateMyKidsSymptom = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerUpdateMyKidsSymptom>, Error, {id: number;data: CreateKidSymptom}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerUpdateMyKidsSymptom>, Error, {id: number;data: CreateKidSymptom}>((props) => {
    const {id,data} = props || {};

    return  pregnancyControllerUpdateMyKidsSymptom<Data>(id,data)
  }, mutationConfig)
}
export const pregnancyControllerDeleteMyKidSymptom = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/kid/symptom/${id}`,
    );
  }



export const usePregnancyControllerDeleteMyKidSymptom = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerDeleteMyKidSymptom>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerDeleteMyKidSymptom>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  pregnancyControllerDeleteMyKidSymptom<Data>(id)
  }, mutationConfig)
}
export const pregnancyControllerUpdateMyKidsVaccine = <Data = unknown>(
    id: number,
    createKidVaccine: CreateKidVaccine,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/kid/vaccine/${id}`,
      createKidVaccine,
    );
  }



export const usePregnancyControllerUpdateMyKidsVaccine = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerUpdateMyKidsVaccine>, Error, {id: number;data: CreateKidVaccine}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerUpdateMyKidsVaccine>, Error, {id: number;data: CreateKidVaccine}>((props) => {
    const {id,data} = props || {};

    return  pregnancyControllerUpdateMyKidsVaccine<Data>(id,data)
  }, mutationConfig)
}
export const pregnancyControllerDeleteMyKidVaccine = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/kid/vaccine/${id}`,
    );
  }



export const usePregnancyControllerDeleteMyKidVaccine = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerDeleteMyKidVaccine>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerDeleteMyKidVaccine>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  pregnancyControllerDeleteMyKidVaccine<Data>(id)
  }, mutationConfig)
}
export const pregnancyControllerUpdateMyPregnancy = <Data = unknown>(
    id: number,
    updatePregnancy: UpdatePregnancy,
  ) => {
    return axios.patch<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/${id}`,
      updatePregnancy,
    );
  }



export const usePregnancyControllerUpdateMyPregnancy = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerUpdateMyPregnancy>, Error, {id: number;data: UpdatePregnancy}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerUpdateMyPregnancy>, Error, {id: number;data: UpdatePregnancy}>((props) => {
    const {id,data} = props || {};

    return  pregnancyControllerUpdateMyPregnancy<Data>(id,data)
  }, mutationConfig)
}
export const pregnancyControllerDeleteMyPregnancy = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/${id}`,
    );
  }



export const usePregnancyControllerDeleteMyPregnancy = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerDeleteMyPregnancy>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerDeleteMyPregnancy>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  pregnancyControllerDeleteMyPregnancy<Data>(id)
  }, mutationConfig)
}
export const pregnancyControllerDeleteMyPregnancyPdf = <Data = unknown>(
    id: number,
  ) => {
    return axios.delete<Data extends unknown ? boolean : Data>(
      `/me/pregnancy/userPdf/${id}`,
    );
  }



export const usePregnancyControllerDeleteMyPregnancyPdf = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof pregnancyControllerDeleteMyPregnancyPdf>, Error, {id: number}>
  ) => {
  return useMutation<AsyncReturnType<typeof pregnancyControllerDeleteMyPregnancyPdf>, Error, {id: number}>((props) => {
    const {id} = props || {};

    return  pregnancyControllerDeleteMyPregnancyPdf<Data>(id)
  }, mutationConfig)
}
export const exportedControllerGetMeasurements = <Data = unknown>(
    
  ) => {
    return axios.get<Data extends unknown ? MeasurementDTO[] : Data>(
      `/exported/measurements`,
    );
  }


export const getExportedControllerGetMeasurementsQueryKey = () => [`/exported/measurements`]

    
export const useExportedControllerGetMeasurements = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof exportedControllerGetMeasurements>, Error>
  ) => {
  const queryKey = getExportedControllerGetMeasurementsQueryKey();

  const query = useQuery<AsyncReturnType<typeof exportedControllerGetMeasurements>, Error>(queryKey, () => exportedControllerGetMeasurements<Data>(), queryConfig )

  return {
    queryKey,
    ...query
  }
}

export const exportedControllerGetUserMeasurements = <Data = unknown>(
    userid: number,
    params?: ExportedControllerGetUserMeasurementsParams,
  ) => {
    return axios.get<Data extends unknown ? UserMeasurementDTO[] : Data>(
      `/exported/usermeasurements/${userid}`,
      {
        params,
      },
    );
  }


export const getExportedControllerGetUserMeasurementsQueryKey = (userid: number,
    params?: ExportedControllerGetUserMeasurementsParams,) => [`/exported/usermeasurements/${userid}`, ...(params ? [params]: [])]

    
export const useExportedControllerGetUserMeasurements = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 userid: number,
    params?: ExportedControllerGetUserMeasurementsParams,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof exportedControllerGetUserMeasurements>, Error>
  ) => {
  const queryKey = getExportedControllerGetUserMeasurementsQueryKey(userid,params);

  const query = useQuery<AsyncReturnType<typeof exportedControllerGetUserMeasurements>, Error>(queryKey, () => exportedControllerGetUserMeasurements<Data>(userid,params), {enabled: !!(userid), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const exportedControllerGetUserSymptoms = <Data = unknown>(
    userid: number,
    params?: ExportedControllerGetUserSymptomsParams,
  ) => {
    return axios.get<Data extends unknown ? UserSymptomDTO[] : Data>(
      `/exported/usersymptoms/${userid}`,
      {
        params,
      },
    );
  }


export const getExportedControllerGetUserSymptomsQueryKey = (userid: number,
    params?: ExportedControllerGetUserSymptomsParams,) => [`/exported/usersymptoms/${userid}`, ...(params ? [params]: [])]

    
export const useExportedControllerGetUserSymptoms = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 userid: number,
    params?: ExportedControllerGetUserSymptomsParams,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof exportedControllerGetUserSymptoms>, Error>
  ) => {
  const queryKey = getExportedControllerGetUserSymptomsQueryKey(userid,params);

  const query = useQuery<AsyncReturnType<typeof exportedControllerGetUserSymptoms>, Error>(queryKey, () => exportedControllerGetUserSymptoms<Data>(userid,params), {enabled: !!(userid), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const exportedControllerGetUserSituation = <Data = unknown>(
    userid: number,
    params?: ExportedControllerGetUserSituationParams,
  ) => {
    return axios.get<Data extends unknown ? UserSituationDTO[] : Data>(
      `/exported/usersituation/${userid}`,
      {
        params,
      },
    );
  }


export const getExportedControllerGetUserSituationQueryKey = (userid: number,
    params?: ExportedControllerGetUserSituationParams,) => [`/exported/usersituation/${userid}`, ...(params ? [params]: [])]

    
export const useExportedControllerGetUserSituation = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 userid: number,
    params?: ExportedControllerGetUserSituationParams,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof exportedControllerGetUserSituation>, Error>
  ) => {
  const queryKey = getExportedControllerGetUserSituationQueryKey(userid,params);

  const query = useQuery<AsyncReturnType<typeof exportedControllerGetUserSituation>, Error>(queryKey, () => exportedControllerGetUserSituation<Data>(userid,params), {enabled: !!(userid), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const exportedControllerGetUserHistory = <Data = unknown>(
    userid: number,
  ) => {
    return axios.get<Data extends unknown ? unknown : Data>(
      `/exported/userHistory/${userid}`,
    );
  }


export const getExportedControllerGetUserHistoryQueryKey = (userid: number,) => [`/exported/userHistory/${userid}`]

    
export const useExportedControllerGetUserHistory = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 userid: number,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof exportedControllerGetUserHistory>, Error>
  ) => {
  const queryKey = getExportedControllerGetUserHistoryQueryKey(userid);

  const query = useQuery<AsyncReturnType<typeof exportedControllerGetUserHistory>, Error>(queryKey, () => exportedControllerGetUserHistory<Data>(userid), {enabled: !!(userid), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

export const exportedControllerGetActiveMedication = <Data = unknown>(
    activeMedicationDTO: ActiveMedicationDTO,
  ) => {
    return axios.post<Data extends unknown ? ExportedControllerGetActiveMedication201 : Data>(
      `/exported/medication/active`,
      activeMedicationDTO,
    );
  }



export const useExportedControllerGetActiveMedication = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
    mutationConfig?: UseMutationOptions<AsyncReturnType<typeof exportedControllerGetActiveMedication>, Error, {data: ActiveMedicationDTO}>
  ) => {
  return useMutation<AsyncReturnType<typeof exportedControllerGetActiveMedication>, Error, {data: ActiveMedicationDTO}>((props) => {
    const {data} = props || {};

    return  exportedControllerGetActiveMedication<Data>(data)
  }, mutationConfig)
}
export const exportedControllerGetUserExams = <Data = unknown>(
    userId: number,
  ) => {
    return axios.get<Data extends unknown ? UserPdfDTO[] : Data>(
      `/exported/exams/${userId}`,
    );
  }


export const getExportedControllerGetUserExamsQueryKey = (userId: number,) => [`/exported/exams/${userId}`]

    
export const useExportedControllerGetUserExams = <
  Data extends unknown = unknown,
  Error extends unknown = unknown
>(
 userId: number,
 queryConfig?: UseQueryOptions<AsyncReturnType<typeof exportedControllerGetUserExams>, Error>
  ) => {
  const queryKey = getExportedControllerGetUserExamsQueryKey(userId);

  const query = useQuery<AsyncReturnType<typeof exportedControllerGetUserExams>, Error>(queryKey, () => exportedControllerGetUserExams<Data>(userId), {enabled: !!(userId), ...queryConfig} )

  return {
    queryKey,
    ...query
  }
}

