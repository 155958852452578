import React from 'react';
import { hot } from 'react-hot-loader/root';
import { CircularProgress, Box } from '@material-ui/core';
const _LoadingPage = () => {
  return (
    <Box
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
      }}
    >
      <CircularProgress style={{ width: 80, height: 80 }} />
    </Box>
  );
};
export const LoadingPage = hot(_LoadingPage);
