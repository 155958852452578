import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  Divider,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Drawer,
  IconButton,
  Dialog,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';
import { ShortInfo } from 'components/Home/LatestMeasurement';
import { StyledLineChart } from 'components/LineChart';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Tooltip, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { toast } from 'react-toastify';
import { MeasurementsBox } from 'components/Measurements/MeasurementsBox';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import AddIcon from '@material-ui/icons/Add';
import { StyledAreaChart } from 'components/AreaChart';
import { StyledPregnancyChart } from 'components/PregnancyChart';
import {
  Bmidto,
  MeasurementDTO,
  PregnancyDTO,
  useMeasurementsControllerDeleteMyMeasurement,
  UserMeasurementDTO,
} from 'gen/client';

interface PregnancyMeasurements {
  rawData: UserMeasurementDTO[];
  onRefresh: () => void;
  onChange: (measurement: MeasurementDTO) => void;
  activeMeasurement: MeasurementDTO[];
  weight?: boolean;
  bmi?: Bmidto;
  pregnancy?: PregnancyDTO;
  week: number;
}

const _PregnancyMeasurements = ({
  rawData,
  activeMeasurement,
  onRefresh,
  onChange,
  weight,
  bmi,
  pregnancy,
  week,
}: PregnancyMeasurements) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editOn, setEditOn] = useState(false);
  const [measurement, setMeasurement] = useState<MeasurementDTO>(
    activeMeasurement[0]
  );
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [bmiData, setBmiData] = useState<any[]>();
  const firstUpdate = useRef(true);
  const deleteMutation = useMeasurementsControllerDeleteMyMeasurement();

  const [measurementToEdit, setMeasurementToEdit] =
    useState<UserMeasurementDTO>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteMeasurement = async (id: number) => {
    try {
      const response = await deleteMutation.mutateAsync({ id });
      if (response) {
        toast(t('MeasurementDeleted'));
        onRefresh();
      } else {
        toast(t('FailedDeleting'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const compare = (a: any, b: any) => {
    if (moment(a.time).isBefore(b.time)) {
      return -1;
    }
    if (moment(a.time).isAfter(b.time)) {
      return 1;
    }
    return 0;
  };

  const filterData = (data: Bmidto) => {
    const temp = [];
    for (let i = 0; i < data.result.weight.low.length; i++) {
      if (i === 0) {
      } else if (
        moment().year() >
          moment(pregnancy?.startDate)
            .add(i - 1, 'weeks')
            .year() ||
        (i > 1 &&
          moment(pregnancy?.startDate)
            .add(i - 1, 'weeks')
            .year() >
            moment(pregnancy?.startDate)
              .add(i - 2, 'weeks')
              .year())
          ? moment(pregnancy?.startDate)
              .add(i - 1, 'weeks')
              .week() <
            moment().week() + moment(pregnancy?.startDate).endOf('year').week()
          : (moment(pregnancy?.startDate)
              .add(i - 1, 'weeks')
              .week() < moment().week() &&
              i > 0) ||
            moment(pregnancy?.startDate)
              .add(i - 1, 'weeks')
              .isSameOrBefore(moment(pregnancy?.startDate).add(41, 'weeks'))
      )
        // if (
        //   moment(pregnancy?.startDate).add(i, 'weeks').endOf('day') <=
        //     moment().endOf('day')
        // )
        temp.push({
          id: i + 1,
          time: moment(pregnancy?.startDate)
            .add(i, 'weeks')
            .format(DateFormats.ISO),
          under:
            parseFloat(data.result.weight.low[i]) +
            rawData.slice().reverse()[0].value,
          over:
            parseFloat(data.result.weight.high[i]) +
            rawData.slice().reverse()[0].value,
          value: undefined,
        });
      else break;
    }
    setBmiData(
      [
        ...rawData.reverse().map((item, i) =>
          i === 0
            ? {
                ...item,
                under: parseFloat(data.result.weight.low[i]) + rawData[0].value,
                over: parseFloat(data.result.weight.high[i]) + rawData[0].value,
              }
            : {
                ...item,
                under: 0,
                over: 0,
              }
        ),
        ...temp,
      ]
        .sort(compare)
        .map((item, i, array) =>
          i !== 0 && item.value === undefined && i + 1 <= array.length
            ? {
                ...item,
                // value: array
                //   .filter((item, imp) => imp <= i && item.value !== 0)
                //   .reverse()[0].value,
                time: (
                  i -
                  array.filter(
                    (item, imp) => imp <= i && item.value !== undefined
                  ).length +
                  2
                )
                  .toString()
                  .concat('η εβδομάδα'),
              }
            : i !== 0 && item.under === 0 && i + 1 <= array.length
            ? {
                ...item,
                under: array
                  .filter((item, imp) => imp <= i && item.under !== 0)
                  .reverse()[0].under,
                over: array
                  .filter((item, imp) => imp <= i && item.under !== 0)
                  .reverse()[0].over,
              }
            : { ...item }
        )
    );
    rawData.reverse();
  };

  useEffect(() => {
    if (measurementToEdit !== undefined) setOpen(true);
  }, [measurementToEdit]);

  useEffect(() => {
    onChange(measurement);
  }, [measurement]);

  useEffect(() => {
    if (bmi !== undefined && rawData.length > 0) filterData(bmi);
  }, [bmi]);

  useEffect(() => {
    if (rawData.length > 0) {
      if (rawData[0].measurement.id !== 4) firstUpdate.current = false;
      else firstUpdate.current = true;
    } else firstUpdate.current = false;
  }, [rawData]);

  useEffect(() => {
    setLoading(firstUpdate.current);
  }, [firstUpdate.current]);

  if (loading === true) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }

  return (
    <Box>
      {editOn === false ? (
        <Fragment>
          <Paper>
            <Box
              display="flex"
              className={classes.padd}
              style={isBelowXs ? { padding: 0 } : {}}
            >
              {!weight && (
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    className={clsx([classes.graph])}
                    style={{ width: isBelowSm ? '85%' : '50%' }}
                  >
                    <Select
                      value={measurement.id}
                      onChange={(e) =>
                        setMeasurement(
                          activeMeasurement.filter(
                            (item) => item.id === e.target.value
                          )[0]
                        )
                      }
                    >
                      {activeMeasurement.map((type, i) => (
                        <MenuItem value={type.id} key={i}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <IconButton onClick={() => setOpen(true)}>
                    <AddIcon />
                  </IconButton>
                </Box>
              )}
              <Divider
                style={{
                  marginTop: '3%',
                  backgroundColor: '#E3DFFA',
                }}
              />
              <Box display="flex" style={{ width: '100%' }}>
                <ShortInfo latest={rawData[0]} />
              </Box>
              <Divider
                style={{
                  marginTop: '3%',
                  backgroundColor: '#E3DFFA',
                }}
              />
              <Box
                className={clsx([classes.graph])}
                style={isBelowXs ? { padding: 6 } : {}}
              >
                {rawData.length > 0 && !weight ? (
                  <StyledLineChart
                    data={rawData
                      .map(({ time, ...rest }) => ({
                        ...rest,
                        time: moment(time).format(DateFormats.GR),
                      }))
                      .slice(0, 100)
                      .reverse()}
                    dataKey="value"
                    aspect={3}
                  >
                    <XAxis dataKey="time" axisLine={false} tickLine={false} />
                    <YAxis
                      dataKey="value"
                      axisLine={false}
                      tickLine={false}
                      label={{
                        value:
                          activeMeasurement !== null && measurement.limits[0]
                            ? measurement.limits[0].unit
                            : undefined,
                        angle: -90,
                        offset: 10,
                        position: 'insideLeft',
                        textAnchor: 'middle',
                      }}
                    />
                    <Tooltip formatter={(datakey) => [datakey, t('Value')]} />
                  </StyledLineChart>
                ) : rawData.length > 0 &&
                  weight &&
                  bmiData !== undefined &&
                  bmi ? (
                  <StyledPregnancyChart
                    data={bmiData}
                    dataKey="value"
                    dataKey2="over"
                    dataKey3="under"
                    aspect={3}
                  >
                    <XAxis dataKey="time" axisLine={false} tickLine={false} />
                    <YAxis
                      dataKey="value"
                      axisLine={false}
                      tickLine={false}
                      label={{
                        value:
                          activeMeasurement !== null && measurement.limits[0]
                            ? measurement.limits[0].unit
                            : undefined,
                        angle: -90,
                        offset: 10,
                        position: 'insideLeft',
                        textAnchor: 'middle',
                      }}
                      domain={[
                        rawData[0].value - 2,
                        Math.max(
                          rawData[0].value +
                            parseInt(
                              bmi?.result.weight.high.slice().reverse()[0]
                            ) +
                            2,
                          Math.max(...rawData.map((item) => item.value), 0) + 4
                        ),
                      ]}
                    />
                    <YAxis
                      dataKey="over"
                      axisLine={false}
                      tickLine={false}
                      label={{
                        value:
                          activeMeasurement !== null && measurement.limits[0]
                            ? measurement.limits[0].unit
                            : undefined,
                        angle: -90,
                        offset: 10,
                        position: 'insideLeft',
                        textAnchor: 'middle',
                      }}
                    />
                    <YAxis
                      dataKey="under"
                      axisLine={false}
                      tickLine={false}
                      label={{
                        value:
                          activeMeasurement !== null && measurement.limits[0]
                            ? measurement.limits[0].unit
                            : undefined,
                        angle: -90,
                        offset: 10,
                        position: 'insideLeft',
                        textAnchor: 'middle',
                      }}
                    />
                    <Tooltip
                      formatter={(datakey, name) => [
                        datakey,
                        name === 'under'
                          ? t('Under')
                          : name === 'over'
                          ? t('Over')
                          : t('Value'),
                      ]}
                    />
                  </StyledPregnancyChart>
                ) : (
                  <Box
                    style={{
                      display: 'flex',
                      height: '65%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: Colors.Black,
                    }}
                  >
                    {t('NoData')}
                  </Box>
                )}
              </Box>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: weight ? 'space-between' : 'flex-end',
                  marginTop: '2%',
                }}
              >
                {weight && (
                  <IconButton onClick={() => setFullscreen(true)}>
                    <FullscreenIcon />
                  </IconButton>
                )}
                <Button
                  variant="text"
                  style={{ padding: 0 }}
                  onClick={() => {
                    if (editOn === false) setEditOn(true);
                    else setEditOn(false);
                  }}
                >
                  {t('ChangeGraph1')}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Fragment>
      ) : (
        <Fragment>
          <Box
            display="flex"
            className={classes.padd}
            style={isBelowXs ? { padding: 0 } : {}}
          >
            {!weight && (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  className={clsx([classes.graph])}
                  style={{ width: isBelowSm ? '85%' : '50%' }}
                >
                  <Select
                    value={measurement.id}
                    onChange={(e) =>
                      setMeasurement(
                        activeMeasurement.filter(
                          (item) => item.id === e.target.value
                        )[0]
                      )
                    }
                  >
                    {activeMeasurement.map((type, i) => (
                      <MenuItem value={type.id} key={i}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <IconButton onClick={() => setOpen(true)}>
                  <AddIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box style={{ margin: '8px 0px' }}>
            <Typography
              style={{
                padding: 16,
                color: Colors.Black,
                fontSize: isBelowXs ? 18 : 20,
                fontWeight: 'bold',
              }}
            >
              {t('DiagramT')}
            </Typography>
          </Box>
          <Paper style={{ overflow: 'auto' }}>
            <Table>
              <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                <TableRow>
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('Value')}
                  </TableCell>
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('Date')}
                  </TableCell>
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('Time')}
                  </TableCell>
                  <TableCell padding="none" />
                  <TableCell padding="none" />
                  <TableCell padding="none" />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? rawData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rawData
                ).map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.value}</TableCell>
                    <TableCell>
                      {moment(row.time).format(DateFormats.GrNoTime)}
                    </TableCell>
                    <TableCell>
                      {moment(row.time).format(DateFormats.TIME)}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      style={isBelowXs ? { padding: '0px 8px' } : {}}
                    >
                      <EditIcon
                        style={{
                          color: 'rgba(0, 0, 0, 0.58)',
                          fontSize: 18,
                          cursor: 'pointer',
                        }}
                        onClick={() => setMeasurementToEdit(row)}
                      />
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Box
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, 0.34)',
                          width: 2,
                          height: 40,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      style={isBelowXs ? { padding: '0px 8px' } : {}}
                    >
                      <DeleteIcon
                        style={{
                          color: 'rgba(0, 0, 0, 0.58)',
                          fontSize: 18,
                          cursor: 'pointer',
                        }}
                        onClick={async () => {
                          await deleteMeasurement(row.id);
                          setMeasurementToEdit(undefined);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      20,
                      100,
                      { label: 'All', value: -1 },
                    ]}
                    classes={{
                      spacer: classes.spacer,
                      input: classes.input,
                    }}
                    labelRowsPerPage={t('RowsText')}
                    count={rawData.length}
                    onChangePage={handleChangePage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '2%',
                paddingBottom: '2%',
                paddingRight: 16,
              }}
            >
              <Button
                variant="text"
                style={{ padding: 0 }}
                onClick={() => {
                  setEditOn(false);
                }}
              >
                {t('ChangeGraph2')}
              </Button>
            </Box>
          </Paper>
        </Fragment>
      )}
      <Drawer
        open={open}
        anchor="right"
        PaperProps={{ style: { width: '30vw', minWidth: 430 } }}
        onClose={() => setOpen(false)}
      >
        <Box style={{ padding: '16px 48px 0px' }}>
          <MeasurementsBox
            onAdd={() => {
              onRefresh();
              setOpen(false);
            }}
            onEdit={() => {
              onRefresh();
              setOpen(false);
            }}
            onCancel={() => {
              setMeasurementToEdit(undefined);
              setOpen(false);
            }}
            drawer
            measurement={measurementToEdit}
            active={measurement}
            lastMeasurement={rawData[0]}
            pregnancy={pregnancy}
          />
        </Box>
      </Drawer>
      <Dialog
        open={fullscreen}
        onClose={() => {
          setFullscreen(false);
        }}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: 4000,
            height: '75%',
            display: 'flex',
            justifyContent: 'center',
          },
        }}
      >
        <Box style={{ padding: '0px 50px' }}>
          {rawData.length > 0 && bmi ? (
            <Box>
              <StyledPregnancyChart
                data={bmiData}
                dataKey="value"
                dataKey2="over"
                dataKey3="under"
                aspect={3}
              >
                <XAxis dataKey="time" axisLine={false} tickLine={false} />
                <YAxis
                  dataKey="value"
                  axisLine={false}
                  tickLine={false}
                  label={{
                    value:
                      activeMeasurement !== null && measurement.limits[0]
                        ? measurement.limits[0].unit
                        : undefined,
                    angle: -90,
                    offset: 10,
                    position: 'insideLeft',
                    textAnchor: 'middle',
                  }}
                  domain={[
                    rawData[0].value - 2,
                    Math.max(
                      rawData[0].value +
                        parseInt(bmi?.result.weight.high.slice().reverse()[0]) +
                        2,
                      Math.max(...rawData.map((item) => item.value), 0) + 4
                    ),
                  ]}
                />
                <YAxis
                  dataKey="over"
                  axisLine={false}
                  tickLine={false}
                  label={{
                    value:
                      activeMeasurement !== null && measurement.limits[0]
                        ? measurement.limits[0].unit
                        : undefined,
                    angle: -90,
                    offset: 10,
                    position: 'insideLeft',
                    textAnchor: 'middle',
                  }}
                />
                <YAxis
                  dataKey="under"
                  axisLine={false}
                  tickLine={false}
                  label={{
                    value:
                      activeMeasurement !== null && measurement.limits[0]
                        ? measurement.limits[0].unit
                        : undefined,
                    angle: -90,
                    offset: 10,
                    position: 'insideLeft',
                    textAnchor: 'middle',
                  }}
                />
                <Tooltip
                  formatter={(datakey, name) => [
                    datakey,
                    name === 'under'
                      ? t('Under')
                      : name === 'over'
                      ? t('Over')
                      : t('Value'),
                  ]}
                />
              </StyledPregnancyChart>
            </Box>
          ) : (
            <Box
              style={{
                display: 'flex',
                height: '65%',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 16,
                fontWeight: 'bold',
                color: Colors.Black,
              }}
            >
              {t('NoData')}
            </Box>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: 60,
  },
  graph: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    height: '100%',
  },
  padd: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
  spacer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  input: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
    },
  },
}));
export const PregnancyMeasurements = hot(_PregnancyMeasurements);
