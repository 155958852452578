import {
  Box,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import React, { Fragment, useEffect, useReducer } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Colors } from 'res/Enums';
import { isNullOrUndefined } from 'util';
import { DateSelect } from './DateSelect';
import { Filter } from './Filter';
import { Search } from '@material-ui/icons';

interface Enables {
  date: boolean;
  sort: boolean;
  special: boolean;
  time: boolean;
  group: boolean;
  search: boolean;
}
interface FilterBarProps<T> {
  data: T[];
  enables: Partial<Enables>;
  dateKey?: string;
  dataKey?: string;
  numeric?: boolean;
  searchKey?: string;
  activeFilter: ActiveFilter;
  avg?: boolean;
  medicalDateSearch?: boolean;
  onFilter: (data: T[], group: GroupProps) => void;
  setActiveFilter: (state: Partial<ActiveFilter>) => void;
}
interface DateActive {
  to: { active: boolean; value: Moment };
  from: { active: boolean; value: Moment };
}
enum SortProps {
  ASC,
  DESC,
}
export enum GroupProps {
  ALL = 'ALL',
  DAY = 'dddd-ww-MM',
  WEEK = 'ww-YYYY',
  MONTH = 'MM-YYYY',
  YEAR = 'YYYY',
}

enum SpecialProps {
  INDIFFERENT = 'INDIFFERENT',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

enum TimeProps {
  ALL,
  MORNING,
  NOON,
  NIGHT,
}
export interface ActiveFilter {
  time: TimeProps;
  group: GroupProps;
  sort: SortProps;
  date: DateActive;
  special: SpecialProps;
  search: string;
}
export const initActive: ActiveFilter = {
  time: TimeProps.ALL,
  group: GroupProps.ALL,
  sort: SortProps.ASC,
  date: {
    from: { active: false, value: moment() },
    to: { active: false, value: moment() },
  },
  special: SpecialProps.INDIFFERENT,
  search: '',
};
export const useFilter = () =>
  useReducer((state: ActiveFilter, action: Partial<ActiveFilter>) => {
    return !isNullOrUndefined(action.group)
      ? {
          ...state,
          ...action,
          group: action.group,
        }
      : { ...state, ...action };
  }, initActive);
const _FilterBar = <T extends { [key: string]: any }>({
  data,
  enables,
  dateKey,
  onFilter,
  setActiveFilter,
  activeFilter,
  searchKey,
  dataKey = 'value',
  numeric = true,
  avg = false,
  medicalDateSearch = false,
}: FilterBarProps<T>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));

  const callFilter = () => {
    let filter = new Filter<T>(data, dateKey, dataKey, numeric);
    if (activeFilter.group === GroupProps.ALL) {
      filter =
        activeFilter.time === TimeProps.MORNING
          ? filter.morning()
          : activeFilter.time === TimeProps.NIGHT
          ? filter.night()
          : activeFilter.time === TimeProps.NOON
          ? filter.evening()
          : filter;

      filter =
        activeFilter.special === SpecialProps.AFTER
          ? filter.filterBy(SpecialProps.AFTER, 'special')
          : activeFilter.special === SpecialProps.BEFORE
          ? filter.filterBy(SpecialProps.BEFORE, 'special')
          : filter;
      if (
        medicalDateSearch &&
        (activeFilter.date.from.active || activeFilter.date.to.active)
      ) {
        if (activeFilter.date.from.active) {
          filter = filter.isAfterMed(activeFilter.date.from.value);
        } else if (activeFilter.date.to.active) {
          filter = filter.isBeforeMed(activeFilter.date.to.value);
        } else {
          filter = filter.isBetweenDateSpace(
            activeFilter.date.from.value,
            activeFilter.date.to.value
          );
        }
      } else {
        filter = activeFilter.date.from.active
          ? filter.isSameAfter(activeFilter.date.from.value)
          : filter;
        filter = activeFilter.date.to.active
          ? filter.isSameBefore(activeFilter.date.to.value)
          : filter;
      }

      filter =
        searchKey && activeFilter.search !== ''
          ? filter.search(activeFilter.search, searchKey)
          : filter;

      activeFilter.sort === SortProps.ASC
        ? filter.ascending()
        : filter.descending();
    } else {
      filter =
        activeFilter.special === SpecialProps.AFTER
          ? filter.filterBy(SpecialProps.AFTER, 'special')
          : activeFilter.special === SpecialProps.BEFORE
          ? filter.filterBy(SpecialProps.BEFORE, 'special')
          : filter;
      if (medicalDateSearch) {
        if (activeFilter.date.from.active) {
          filter = filter.isAfterMed(activeFilter.date.from.value);
        } else if (activeFilter.date.to.active) {
          filter = filter.isBeforeMed(activeFilter.date.to.value);
        } else {
          filter = filter.isBetweenDateSpace(
            activeFilter.date.from.value,
            activeFilter.date.to.value
          );
        }
      } else {
        filter = activeFilter.date.from.active
          ? filter.isSameAfter(activeFilter.date.from.value)
          : filter;
        filter = activeFilter.date.to.active
          ? filter.isSameBefore(activeFilter.date.to.value)
          : filter;
      }
      filter.groupBy(activeFilter.group, avg);
    }
    onFilter(filter.get(), activeFilter.group);
  };

  useEffect(() => {
    if (enables.group) {
      setActiveFilter({ group: GroupProps.DAY });
    }
    callFilter();
  }, []);

  useEffect(() => {
    if (enables.group) {
      setActiveFilter({
        group:
          activeFilter.group === GroupProps.ALL
            ? GroupProps.DAY
            : activeFilter.group,
      });
    } else callFilter();
  }, [data]);

  useEffect(() => {
    callFilter();
  }, [activeFilter]);

  return (
    <Box
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {(enables.sort || enables.group) && (
        <Box
          className={classes.container}
          style={{ justifyContent: 'space-evenly', width: '100%' }}
        >
          {enables.sort && (
            <Fragment>
              <Box
                className={clsx([
                  classes.item,
                  activeFilter.sort === SortProps.ASC && classes.clicked,
                ])}
                onClick={() => setActiveFilter({ sort: SortProps.ASC })}
              >
                ASC
              </Box>
              <Box
                className={clsx([
                  classes.item,
                  activeFilter.sort === SortProps.DESC && classes.clicked,
                ])}
                onClick={() => setActiveFilter({ sort: SortProps.DESC })}
              >
                DESC
              </Box>
            </Fragment>
          )}
          {enables.group && (
            <Fragment>
              <Box
                className={clsx([
                  classes.item,
                  activeFilter.group === GroupProps.DAY && classes.clicked,
                ])}
                style={isBelowXs ? { padding: 2, fontSize: 13 } : {}}
                onClick={() => setActiveFilter({ group: GroupProps.DAY })}
              >
                {t('Days')}
              </Box>
              <Box
                className={clsx([
                  classes.item,
                  activeFilter.group === GroupProps.WEEK && classes.clicked,
                ])}
                style={isBelowXs ? { padding: 2, fontSize: 13 } : {}}
                onClick={() => setActiveFilter({ group: GroupProps.WEEK })}
              >
                {t('Week')}
              </Box>
              <Box
                className={clsx([
                  classes.item,
                  activeFilter.group === GroupProps.MONTH && classes.clicked,
                ])}
                style={isBelowXs ? { padding: 2, fontSize: 13 } : {}}
                onClick={() => setActiveFilter({ group: GroupProps.MONTH })}
              >
                {t('Month')}
              </Box>
              {!isBelowXs && (
                <Box
                  className={clsx([
                    classes.item,
                    activeFilter.group === GroupProps.YEAR && classes.clicked,
                  ])}
                  onClick={() => setActiveFilter({ group: GroupProps.YEAR })}
                >
                  {t('Year')}
                </Box>
              )}
            </Fragment>
          )}
        </Box>
      )}

      {(enables.search || enables.date) && (
        <Box
          style={{
            display: 'flex',
            flex: '1 1 auto',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
          }}
        >
          {enables.date && (
            <DateSelect
              onSelect={(dates) => setActiveFilter({ date: { ...dates } })}
            />
          )}
          {enables.search && (
            <TextField
              placeholder={`${t('Search')}...`}
              onChange={(e) => setActiveFilter({ search: e.target.value })}
              style={{
                justifyContent: 'center',
                marginTop: isBelowSm ? 10 : 'unset',
                marginBottom: isBelowSm ? 4 : 'unset',
              }}
              size={isBelowSm ? 'small' : 'medium'}
              inputProps={{ className: classes.input }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize={isBelowSm ? 'small' : 'large'} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Box>
      )}
      {enables.time && (
        <Box
          style={{
            marginBottom: 10,
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 2,
            marginTop: 8,
            marginRight: 10,
          }}
        >
          <Typography
            style={{
              padding: '10px 0px 0px 40px',
              fontSize: isBelowXs ? 16 : 20,
              fontWeight: 'bold',
            }}
          >
            {t('TimeOF')}
          </Typography>
          <FormControl
            style={{ paddingLeft: isBelowXs ? 0 : 30, fontSize: 18 }}
            component="fieldset"
          >
            <RadioGroup
              value={activeFilter.time}
              onChange={(e) =>
                ((e) => {
                  switch (e.target.value as string) {
                    case TimeProps.ALL.toString():
                      setActiveFilter({ time: TimeProps.ALL });
                      return TimeProps.ALL;
                    case TimeProps.NOON.toString():
                      setActiveFilter({ time: TimeProps.NOON });
                      return TimeProps.NOON;
                    case TimeProps.MORNING.toString():
                      setActiveFilter({ time: TimeProps.MORNING });
                      return TimeProps.MORNING;
                    case TimeProps.NIGHT.toString():
                      setActiveFilter({ time: TimeProps.NIGHT });
                      return TimeProps.NIGHT;
                    default:
                      setActiveFilter({ time: TimeProps.ALL });
                      return TimeProps.ALL;
                  }
                })(e)
              }
              row
            >
              <FormControlLabel
                value={TimeProps.ALL}
                control={<Radio style={{ color: Colors.PrimaryLight }} />}
                label={t('All')}
                classes={{ label: classes.label }}
              />
              <FormControlLabel
                value={TimeProps.MORNING}
                control={<Radio style={{ color: Colors.PrimaryLight }} />}
                label={t('Proi')}
                classes={{ label: classes.label }}
              />
              <FormControlLabel
                value={TimeProps.NOON}
                control={<Radio style={{ color: Colors.PrimaryLight }} />}
                label={t('Mesimeri')}
                classes={{ label: classes.label }}
              />
              <FormControlLabel
                value={TimeProps.NIGHT}
                control={<Radio style={{ color: Colors.PrimaryLight }} />}
                label={t('Vrady')}
                classes={{ label: classes.label }}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      )}
      {enables.special && (
        <Box
          style={{
            marginBottom: 10,
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: 2,
            marginTop: 8,
            marginRight: 10,
          }}
        >
          <FormControl
            style={{ paddingLeft: isBelowXs ? 4 : 30, fontSize: 18 }}
            component="fieldset"
          >
            <RadioGroup
              value={activeFilter.special}
              onChange={(e) =>
                ((e) => {
                  switch (e.target.value as string) {
                    case SpecialProps.INDIFFERENT.toString():
                      setActiveFilter({
                        special: SpecialProps.INDIFFERENT,
                      });
                      return SpecialProps.INDIFFERENT;
                    case SpecialProps.AFTER.toString():
                      setActiveFilter({
                        special: SpecialProps.AFTER,
                      });
                      return SpecialProps.AFTER;
                    case SpecialProps.BEFORE.toString():
                      setActiveFilter({ special: SpecialProps.BEFORE });
                      return SpecialProps.BEFORE;
                    default:
                      setActiveFilter({
                        special: SpecialProps.INDIFFERENT,
                      });
                      return SpecialProps.INDIFFERENT;
                  }
                })(e)
              }
              row
            >
              <FormControlLabel
                value={SpecialProps.INDIFFERENT}
                classes={{ label: classes.labelFood }}
                control={
                  <Radio size="small" style={{ color: Colors.PrimaryLight }} />
                }
                label={t('All')}
              />
              <FormControlLabel
                value={SpecialProps.BEFORE}
                classes={{ label: classes.labelFood }}
                control={
                  <Radio size="small" style={{ color: Colors.PrimaryLight }} />
                }
                label={t('Before')}
              />
              <FormControlLabel
                value={SpecialProps.AFTER}
                classes={{ label: classes.labelFood }}
                control={
                  <Radio size="small" style={{ color: Colors.PrimaryLight }} />
                }
                label={t('After')}
              />
            </RadioGroup>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'flex',
    flex: '1 1 auto',
    cursor: 'pointer',
    justifyContent: 'center',
    color: '#000000',
    padding: 8,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#263238ab',
      color: Colors.IceWhite,
    },
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    border: '1px solid #BDBDBD',
    borderRadius: 6,
    margin: '6px 0',
  },
  clicked: {
    backgroundColor: '#263238',
    color: Colors.IceWhite,
  },
  input: {
    fontSize: theme.breakpoints.down('md') ? 14 : 18,
  },
  label: {},
  labelFood: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
}));
export const FilterBar = hot(_FilterBar);
