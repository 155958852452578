import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { useDiagnosisControllerGetStrokeAssesmentData } from 'gen/client';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
type ChoiceType = '0' | '1' | '2';
interface Choices {
  smoking: ChoiceType;
  diabetic: ChoiceType;
  atrial: ChoiceType;
  history: ChoiceType;
  exercise: ChoiceType;
  overweight: ChoiceType;
  cholesterol?: number;
  pressureHigh?: number;
  pressureLow?: number;
}
const _StrokeDiagnosis = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, isLoading } = useDiagnosisControllerGetStrokeAssesmentData();
  const [message, setMessage] = useState<string>();
  const chooseChol = (cholesterol?: number): ChoiceType | undefined => {
    if (!cholesterol) {
      return undefined;
    } else if (cholesterol >= 240) {
      return '2';
    } else if (cholesterol < 200) {
      return '0';
    } else {
      return '1';
    }
  };
  const choosePressure = (
    high?: number,
    low?: number
  ): ChoiceType | undefined => {
    if (!high || !low) {
      return undefined;
    } else if (high >= 14 || low >= 9) {
      return '2';
    } else if (high >= 12 || low >= 8) {
      return '1';
    } else {
      return '0';
    }
  };
  const calculateScore = () => {
    const chol = chooseChol(choice.cholesterol);
    const press = choosePressure(choice.pressureHigh, choice.pressureLow);
    const { cholesterol, pressureHigh, pressureLow, ...newChoices } = choice;

    const counters: Array<number> = [0, 0, 0];
    for (const [key, value] of Object.entries(newChoices)) {
      if (value === '0') {
        counters[0]++;
      } else if (value === '1') {
        counters[1]++;
      } else {
        counters[2]++;
      }
    }
    if (chol === '0') {
      counters[0]++;
    } else if (chol === '1') {
      counters[1]++;
    } else {
      counters[2]++;
    }
    if (press === '0') {
      counters[0]++;
    } else if (press === '1') {
      counters[1]++;
    } else {
      counters[2]++;
    }
    if (counters[2] >= 3) {
      setMessage('HighRisk');
    } else if (counters[0] >= 6 && counters[0] <= 8) {
      setMessage('LowRisk');
    } else {
      setMessage('ModerateRisk');
    }
  };
  const [choice, setChoice] = useState<Choices>({
    smoking: '0',
    diabetic: '0',
    atrial: '0',
    history: '0',
    exercise: '0',
    overweight: '0',
    cholesterol: data?.data.cholesterol,
    pressureHigh: data?.data.high,
    pressureLow: data?.data.low,
  });
  useEffect(
    () =>
      setChoice({
        ...choice,
        cholesterol: data?.data.cholesterol,
        pressureLow: data?.data.low,
        pressureHigh: data?.data.high,
      }),
    [data]
  );
  if (isLoading || !data) {
    return <CircularProgress />;
  }
  return (
    <Fragment>
      <Typography style={{ fontSize: 14, marginBottom: 10 }}>
        Αυτό το εργαλείο εκτίμησης κινδύνου εγκεφαλικού βοηθάει στην
        ποσοτικοποίηση του κίνδυνου εμφάνισης εγκεφαλικού επεισοδίου. Η
        ταυτοποίηση ατόμων με υψηλό κίνδυνο εγκεφαλικού επεισοδίου είναι
        σημαντική για την κλινική διαχείριση των ασθενών, καθώς και για την
        αποτελεσματική πρωτογενή πρόληψη εγκεφαλικού επεισοδίου.
      </Typography>
      <Typography
        variant="h6"
        style={{ marginBottom: 20, textAlign: 'center' }}
      >
        Συμπλήρωσε τα παρακάτω στοιχεία για να ενημερωθείς για το επίπεδο του
        κινδύνου εγκεφαλικού επεισοδίου.
      </Typography>
      <Box
        display="flex"
        marginY={3}
        flexDirection="column"
        alignContent="flex-start"
        width="100%"
      >
        <Typography>{t('LastCholesterol')}</Typography>
        <TextField
          style={{ marginBottom: 8, maxWidth: 150 }}
          color="primary"
          required
          value={choice.cholesterol}
          defaultValue={choice.cholesterol}
          onChange={(e) =>
            setChoice({
              ...choice,
              cholesterol: parseInt(e.target.value),
            })
          }
        />
        <Typography>{t('LastPressureHigh')}</Typography>
        <TextField
          style={{ marginBottom: 8, maxWidth: 150 }}
          color="primary"
          required
          value={choice.pressureHigh}
          defaultValue={choice.pressureHigh}
          onChange={(e) =>
            setChoice({
              ...choice,
              pressureHigh: parseInt(e.target.value),
            })
          }
        />
        <Typography>{t('LastPressureLow')}</Typography>
        <TextField
          style={{ marginBottom: 8, maxWidth: 150 }}
          color="primary"
          required
          defaultValue={choice.pressureLow}
          value={choice.pressureLow}
          onChange={(e) =>
            setChoice({
              ...choice,
              pressureLow: parseInt(e.target.value),
            })
          }
        />
      </Box>
      <FormControl fullWidth component="fieldset">
        <FormLabel component="legend">{t('Overweight')}</FormLabel>
        <RadioGroup
          row
          value={choice.overweight}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setChoice({ ...choice, overweight: e.target.value as ChoiceType })
          }
        >
          <FormControlLabel
            className={classes.label}
            value="2"
            control={<Radio color="primary" />}
            label={t('Overweight2')}
          />
          <FormControlLabel
            className={classes.label}
            value="1"
            control={<Radio color="primary" />}
            label={t('Overweight1')}
          />
          <FormControlLabel
            className={classes.label}
            value="0"
            control={<Radio color="primary" />}
            label={t('Overweight0')}
          />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth component="fieldset">
        <FormLabel component="legend">{t('Exercise')}</FormLabel>
        <RadioGroup
          row
          value={choice.exercise}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setChoice({ ...choice, exercise: e.target.value as ChoiceType })
          }
        >
          <FormControlLabel
            className={classes.label}
            value="2"
            control={<Radio color="primary" />}
            label={t('Exercise0')}
          />
          <FormControlLabel
            className={classes.label}
            value="1"
            control={<Radio color="primary" />}
            label={t('Exercise1')}
          />
          <FormControlLabel
            className={classes.label}
            value="0"
            control={<Radio color="primary" />}
            label={t('Exercise2')}
          />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth component="fieldset">
        <FormLabel component="legend">{t('Smoking2')}</FormLabel>
        <RadioGroup
          row
          value={choice.smoking}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setChoice({ ...choice, smoking: e.target.value as ChoiceType })
          }
        >
          <FormControlLabel
            className={classes.label}
            value="2"
            control={<Radio color="primary" />}
            label={t('SmokingYes')}
          />
          <FormControlLabel
            className={classes.label}
            value="1"
            control={<Radio color="primary" />}
            label={t('TryingQuit')}
          />
          <FormControlLabel
            className={classes.label}
            value="0"
            control={<Radio color="primary" />}
            label={t('SmokingNo')}
          />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth component="fieldset">
        <FormLabel component="legend">{t('Diabetes')}</FormLabel>
        <RadioGroup
          row
          value={choice.diabetic}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setChoice({ ...choice, diabetic: e.target.value as ChoiceType })
          }
        >
          <FormControlLabel
            className={classes.label}
            value="2"
            control={<Radio color="primary" />}
            label={t('Diabetic2')}
          />
          <FormControlLabel
            className={classes.label}
            value="1"
            control={<Radio color="primary" />}
            label={t('Diabetic1')}
          />
          <FormControlLabel
            className={classes.label}
            value="0"
            control={<Radio color="primary" />}
            label={t('Diabetic0')}
          />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth component="fieldset">
        <FormLabel component="legend">{t('Atrial')}</FormLabel>
        <RadioGroup
          row
          value={choice.atrial}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setChoice({ ...choice, atrial: e.target.value as ChoiceType })
          }
        >
          <FormControlLabel
            className={classes.label}
            value="2"
            control={<Radio color="primary" />}
            label={t('Atrial2')}
          />
          <FormControlLabel
            className={classes.label}
            value="1"
            control={<Radio color="primary" />}
            label={t('Atrial1')}
          />
          <FormControlLabel
            className={classes.label}
            value="0"
            control={<Radio color="primary" />}
            label={t('Atrial0')}
          />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth component="fieldset">
        <FormLabel component="legend">{t('StrokeHistory')}</FormLabel>
        <RadioGroup
          row
          value={choice.history}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setChoice({ ...choice, history: e.target.value as ChoiceType })
          }
        >
          <FormControlLabel
            className={classes.label}
            value="2"
            control={<Radio color="primary" />}
            label={t('StrokeHistory2')}
          />
          <FormControlLabel
            className={classes.label}
            value="1"
            control={<Radio color="primary" />}
            label={t('StrokeHistory1')}
          />
          <FormControlLabel
            className={classes.label}
            value="0"
            control={<Radio color="primary" />}
            label={t('StrokeHistory0')}
          />
        </RadioGroup>
      </FormControl>

      <Box>
        <Button
          variant="contained"
          color="primary"
          disabled={
            choice.cholesterol && choice.pressureHigh && choice.pressureLow
              ? false
              : true
          }
          onClick={calculateScore}
        >
          {t('StartDiagnosis')}
        </Button>
      </Box>
      <Dialog
        open={message !== undefined}
        onClose={() => {
          setMessage(undefined);
          setChoice({
            smoking: '0',
            diabetic: '0',
            atrial: '0',
            history: '0',
            exercise: '0',
            overweight: '0',
            cholesterol: 0,
            pressureHigh: 0,
            pressureLow: 0,
          });
        }}
      >
        <DialogTitle>Αποτελέσμα αυτοδιάγνωσης</DialogTitle>
        <DialogContent>{`Σύμφωνα με τις απαντήσεις σου ο κίνδυνος εγκεφαλικού επεισοδίου είναι  ${t(
          message as string
        )}`}</DialogContent>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  paper: {
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  label: {
    width: 250,
  },
}));

export const StrokeDiagnosis = hot(_StrokeDiagnosis);
