import { Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Colors } from 'res/Enums';
import MedBottle from 'assets/icons/MedBottle.svg';
import stetho from 'assets/icons/Stetho.svg';
import { useTranslation } from 'react-i18next';
const _IntroPage1 = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isBelowXl = useMediaQuery(theme.breakpoints.down(1200));
  return (
    <Box
      height="100%"
      style={{
        backgroundColor: Colors.PRIMARY,
        position: 'relative',
      }}
    >
      <img
        alt=""
        src={MedBottle}
        style={{ position: 'absolute', left: 75, top: 190 }}
      />

      <Box
        style={{
          color: Colors.IceWhite,
          position: 'absolute',
          left: 75,
          top: 385,
        }}
      >
        <Typography align="left" variant="h4">
          {t('Intro2')}
        </Typography>
        <Typography align="left" variant="h2">
          {t('Intro3')}
        </Typography>
        <Typography
          align="left"
          style={{ fontSize: '3vh', maxWidth: '80%', marginTop: 50 }}
        >
          {t('Intro1')}
        </Typography>
        <img
          alt=""
          src={stetho}
          style={{
            position: 'absolute',
            left: isBelowXl ? 385 : 550,
            top: '-35%',
          }}
        />
      </Box>
    </Box>
  );
};
export const IntroPage1 = hot(_IntroPage1);
