import {
  Box,
  Button,
  IconButton,
  Slider,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import moment, { Moment } from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Colors, DateFormats } from 'res/Enums';
import { isUndefined } from 'util';
import CloseIcon from '@material-ui/icons/Close';
import { StyledDateTimePicker } from 'components/DateTimePicker';
import {
  PregnancyDTO,
  SymptomDTO,
  UserSymptomDTO,
  useSymptomControllerCreateMySymptom,
  useSymptomControllerUpdateMySymptom,
} from 'gen/client';

interface SymptomBoxProps extends RouteComponentProps<{ id: string }> {
  onAdd: () => void;
  onEdit: () => void;
  onCancel: () => void;
  symptom?: UserSymptomDTO;
  active: SymptomDTO;
  lastSymptom?: UserSymptomDTO;
  drawer?: boolean;
  pregnancy?: PregnancyDTO;
}

const _SymptomBox = ({
  match,
  symptom,
  pregnancy,
  onAdd,
  onEdit,
  active,
  onCancel,
  drawer,
}: SymptomBoxProps) => {
  const activeSymptom = active;
  const [time, setTime] = useState(moment());
  const [value, setValue] = useState(0);
  const [description, setDescription] = useState<string>('');
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const createMutation = useSymptomControllerCreateMySymptom();
  const updateMutation = useSymptomControllerUpdateMySymptom();

  const newSymptom = async () => {
    try {
      const response = await createMutation.mutateAsync({
        data: {
          value: value,
          time: time.format(DateFormats.ISO),
          symptom: activeSymptom,
          description: description,
        },
      });
      if (response) {
        toast(t('SymptomAdded'));
        handleRefresh();
        onAdd();
      } else {
        toast(t('FailedAdding'), { containerId: 'delete' });
      }
    } catch (error) {
      toast(t('Problem'), { containerId: 'deleted' });
    }
  };

  const updateSymptom = async (symptom: UserSymptomDTO) => {
    try {
      const response = await updateMutation.mutateAsync({
        id: symptom.id,
        data: {
          value: value,
          time: time.format(DateFormats.ISO),
          symptom: symptom.symptom,
          description: description,
        },
      });
      if (response) {
        toast(t('SymptomEdited'));
        handleRefresh();
        onEdit();
      }
    } catch (error) {
      toast(t('Problem'), { containerId: 'deleted' });
    }
  };

  const handleRefresh = async () => {
    setValue(1);
    setTime(moment());
    setDescription('');
  };

  const handleChange = (
    event: any,
    newValue: React.SetStateAction<number> | React.SetStateAction<number[]>
  ) => {
    setValue(newValue as number);
  };

  useEffect(() => {
    if (!isUndefined(symptom)) {
      setValue(symptom.value);
      setDescription(symptom.description);
      if (pregnancy !== undefined && pregnancy.UserPregnancy.length > 0) {
        setTime(moment(pregnancy.UserPregnancy[0].birthDate));
      } else setTime(moment(symptom.time));
      setEditMode(true);
    } else {
      setValue(1);
      setDescription('');
      if (pregnancy !== undefined && pregnancy.UserPregnancy.length > 0) {
        setTime(moment(pregnancy.UserPregnancy[0].birthDate));
      } else setTime(moment());
      setEditMode(false);
    }
  }, [symptom]);
  useEffect(() => {
    if (activeSymptom.isBoolean === true) setValue(1);
  });
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.addBox}>
      <Box
        style={{
          display: 'flex',
          paddingRight: isBelowXs ? 16 : 30,
          alignItems: 'center',
        }}
      >
        {editMode === true ? (
          <Box style={{ flexGrow: 1 }}>
            <IconButton
              onClick={() => {
                handleRefresh();
                setEditMode(false);
                onCancel();
              }}
              style={{ marginLeft: 16 }}
            >
              <CloseIcon style={{ fontSize: 30 }} />
            </IconButton>
          </Box>
        ) : (
          <Box style={{ flexGrow: 1 }} />
        )}
        <IconButton style={{ fontSize: 20 }} onClick={handleRefresh}>
          <RefreshIcon
            fontSize="inherit"
            style={{
              fontWeight: 'bold',
              color: Colors.PRIMARY,
              transform: 'rotateY(180deg)',
            }}
          />
        </IconButton>
        <Typography
          style={{
            fontSize: isBelowXs ? 12 : 14,
            fontWeight: 'bold',
            color: '#263238',
          }}
        >
          {t('CleanData')}
        </Typography>
      </Box>
      {activeSymptom.isBoolean === false ? (
        <Fragment>
          <Typography
            style={{
              marginTop: 30,
              color: '#263238',
              fontWeight: 600,
              fontSize: 18,
              paddingLeft: 40,
              paddingBottom: 30,
            }}
          >
            {activeSymptom.name}
          </Typography>
          {activeSymptom.id === 12 ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingRight: 40,
                paddingLeft: 40,
              }}
            >
              <Slider
                valueLabelDisplay="on"
                step={0.1}
                marks
                min={35}
                max={42}
                value={value}
                onChange={handleChange}
              />
            </Box>
          ) : (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingRight: 40,
                paddingLeft: 40,
              }}
            >
              <Slider
                valueLabelDisplay="on"
                step={1}
                marks
                min={1}
                max={10}
                value={value}
                onChange={handleChange}
              />
            </Box>
          )}
        </Fragment>
      ) : (
        <Typography
          style={{
            display: 'flex',
            marginTop: 30,
            color: '#263238',
            fontWeight: 600,
            fontSize: 18,
            justifyContent: 'center',
          }}
        >
          {activeSymptom.name}
        </Typography>
      )}
      <Typography
        style={{
          marginTop: 30,
          color: '#263238',
          fontWeight: 'bold',
          fontSize: 20,
          paddingLeft: 40,
        }}
      >
        {t('Comments')}:
      </Typography>
      <TextField
        className={classes.text}
        value={description}
        multiline
        onChange={(e) => setDescription(e.target.value)}
      />
      <Typography
        style={{
          margin: '20px 40px 0px 40px',
          color: '#263238',
          fontSize: 14,
          fontWeight: 600,
        }}
      >
        {t('DateTime')}
      </Typography>
      <Fragment>
        <StyledDateTimePicker
          autoOk
          autoFocus
          style={{ margin: '0px 40px 100px 40px' }}
          value={time}
          margin="normal"
          format={DateFormats.GR}
          onChange={(date) => setTime(date as Moment)}
          animateYearScrolling
          minDate={
            pregnancy !== undefined
              ? moment(pregnancy.startDate)
              : moment('1900-01-01')
          }
          maxDate={
            pregnancy !== undefined && pregnancy.UserPregnancy.length > 0
              ? moment(pregnancy.UserPregnancy[0].birthDate)
              : moment()
          }
          disableFuture
        />
      </Fragment>
      {editMode === true && !isUndefined(symptom) ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            updateSymptom(symptom);
            setEditMode(false);
          }}
          classes={{ label: classes.label }}
          style={drawer ? { margin: '0px 40px' } : {}}
        >
          {t('EditMeasurement')}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          endIcon={<AddIcon style={{ marginLeft: 20, fontSize: 40 }} />}
          onClick={newSymptom}
        >
          {t('RecordMeasurement')}
        </Button>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  addBox: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
    flexDirection: 'column',
    height: 'fit-content',
  },
  text: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  label: {
    padding: '2% 0% 2% 0%',
  },
}));
export const SymptomsBox = withRouter(_SymptomBox);
