import { Search } from '@material-ui/icons';
import React from 'react';
import { components } from 'react-select';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { Colors } from 'res/Enums';
import { NoticeProps } from 'react-select/src/components/Menu';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@emotion/serialize';
const ValueContainer = (props: ValueContainerProps<any, any>) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        <Search />
        {!props.children && (
          <i
            className="fa fa-search"
            aria-hidden="true"
            style={{ position: 'absolute', left: 6 }}
          />
        )}
        {props.children}
      </components.ValueContainer>
    )
  );
};

const NoOptionsMessage = (props: NoticeProps<any, any>) => {
  const { t } = useTranslation();
  return (
    <components.NoOptionsMessage {...props}>
      {t('NoOptions')}
    </components.NoOptionsMessage>
  );
};

export const AutoCompleteStyle = {
  styles: {
    menu: (styles: CSSObject) => ({ ...styles, zIndex: 9999 }),
    placeholder: (styles: CSSObject) => ({
      ...styles,
      marginLeft: 25,
    }),
    singleValue: (styles: CSSObject) => ({
      ...styles,
      marginLeft: 25,
    }),
    control: (styles: CSSObject) => ({
      ...styles,
      borderColor: Colors.PRIMARY,
      display: 'flex',
      flex: '1 1 auto',
    }),
    container: (styles: CSSObject) => ({
      ...styles,
      display: 'flex',
      flex: '1 1 auto',
    }),
    menuPortal: (styles: CSSObject) => ({ ...styles, zIndex: 9999 }),
  },
  components: { ValueContainer, NoOptionsMessage },
};
