import {
  Box,
  Button,
  Divider,
  Typography,
  Theme,
  Checkbox,
  FormControlLabel,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { TrendingFlat } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { Colors } from 'res/Enums';
import { makeStyles } from '@material-ui/styles';
import { FormTextField } from 'components/Forms/FormTextField';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { GoogleLogin } from 'components/GoogleLogin';
import queryString from 'query-string';
import { useAuthContext } from '../../context/AuthContext';
import { useAuthControllerLogin } from 'gen/client';

const initialValues = {
  email: '',
  pass: '',
};

const Login = () => {
  const { login, loginGoogle } = useAuthContext();
  const history = useHistory();
  const classes = useStyles();
  const query = useLocation().search;
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t('ReEmail2')).required(t('ReEmail')),
    pass: Yup.string().required(t('RePassword')),
  });
  const loginMutation = useAuthControllerLogin();
  const [rememberMe, setRememberMe] = useState(false);
  const requestLogin = async (values: typeof initialValues) => {
    try {
      const resp = await loginMutation.mutateAsync({
        data: {
          email: values.email,
          password: values.pass,
          rememberMe,
        },
      });
      login(resp.data.token, true);
    } catch (error) {
      toast(t('Problem'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(query);
    if (Boolean(parsed.status) && parsed.token) {
      loginGoogle(parsed.token as string, true);
    }
  }, [queryString.parse(query)]);
  return (
    <Box className={classes.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={requestLogin}
        validationSchema={loginSchema}
        validateOnChange
        validateOnBlur={false}
      >
        {({ dirty, isValid, errors }) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <FormTextField
              autoFocus
              required
              fullWidth
              margin="normal"
              label="Email"
              name="email"
              variant="outlined"
              error={errors.email !== undefined}
            />

            <FormTextField
              required
              fullWidth
              margin="normal"
              label={t('Password')}
              type="password"
              variant="outlined"
              name="pass"
              error={errors.pass !== undefined}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  color="primary"
                  title={t('RememberMe')}
                />
              }
              label={t('RememberMe')}
              classes={isBelowXs ? { label: classes.label } : {}}
            />
            <Button
              variant="outlined"
              endIcon={<TrendingFlat style={{ marginLeft: 20 }} />}
              size="medium"
              style={
                !dirty || !isValid
                  ? {
                      backgroundColor: 'rgba(0, 0, 0, 0.12)',
                      color: 'rgba(0, 0, 0, 0.26)',
                      border: 'none',
                    }
                  : { backgroundColor: '#5CA595', color: Colors.White }
              }
              type="submit"
              className={classes.button}
              // disabled={!dirty || !isValid}
            >
              {t('Login')}
            </Button>
            <GoogleLogin
              display="flex"
              justifyContent="center"
              onClick={() => {
                window.location.href =
                  process.env['REACT_APP_STAGE'] === 'production'
                    ? 'https://api-prod.healthier-app.com/auth/google'
                    : process.env['REACT_APP_STAGE'] === 'development'
                    ? 'https://api.healthier-app.com/auth/google'
                    : 'http://lvh.me:5000/auth/google';
              }}
            />
            <Typography
              variant="h6"
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#000000',
                lineHeight: '3.5',
                textAlign: 'center',
              }}
            >
              {t('Or')}
            </Typography>
            <Button
              variant="text"
              style={{
                color: '#5CA595',
                width: '25%',
                minWidth: 250,
                fontSize: 16,
                lineHeight: '0.5',
                fontWeight: 'bold',
                marginBottom: 50,
              }}
              onClick={() => history.push('/register')}
            >
              {t('Create')}
            </Button>
          </Form>
        )}
      </Formik>

      <Divider
        variant="middle"
        style={{ width: 350, paddingBottom: 1, backgroundColor: '#434242' }}
      />
      <Button
        variant="text"
        style={{
          color: '#5CA595',
          width: '25%',
          minWidth: 250,
          fontSize: 12,
          lineHeight: '0.5',
          fontWeight: 500,
          marginTop: 7,
        }}
        onClick={() => history.push('/request')}
      >
        {t('Forgot')}
      </Button>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  label: {
    fontSize: 15,
  },
}));
export default Login;
