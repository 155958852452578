import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { authControllerRequestReset } from 'gen/client';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Colors } from 'res/Enums';
const _RequestReset = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState<string>();
  const [hasError, setHasError] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');

  const sendRequest = async () => {
    try {
      if (email) {
        const r = await authControllerRequestReset({ email });
        if (r.data.sent) {
          setHasError(false);
          setErrorMessage('EmailSent');
        } else {
          setHasError(true);
          setErrorMessage(r.data.message);
        }
      }
    } catch (e) {}
  };

  return (
    <Box className={classes.root}>
      <IconButton
        style={{ position: 'absolute', top: 0, left: 0 }}
        onClick={() => history.goBack()}
      >
        <ArrowBack style={{ fontSize: 40 }} />
      </IconButton>
      <Box
        style={{
          position: 'absolute',
          top: 0,
          display: 'flex',
          justifyContent: 'flex-end',
          width: '90%',
        }}
      >
        {/* <ChangeLanguageButton /> */}
      </Box>
      <Paper className={classes.wrapper}>
        <Typography align="center" variant="h5">
          {t('RequestPasswordReset')}
        </Typography>
        <TextField
          label={t('Email')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={hasError}
          helperText={t(errorMessage)}
        />
        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 10 }}
            onClick={sendRequest}
          >
            {t('SendRequest')}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    backgroundColor: Colors.IceWhite,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '35%',
    minWidth: 200,
    padding: theme.spacing(2),
  },
}));
export const RequestReset = hot(_RequestReset);
