import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import { CalendarTodaySharp } from '@material-ui/icons';
import {
  situationControllerGetSituationTypes,
  SituationDTOCategory,
  useSituationControllerCreateMySituation,
} from 'gen/client';
import { AutoCompleteStyle } from 'components/AutoComplete';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { DateFormats } from 'res/Enums';
import { StyledDatePicker } from 'components/DatePicker';
export interface AddSituationOptions {
  open: boolean;
  category?: SituationDTOCategory;
}
interface AddSituationProps {
  options: AddSituationOptions;
  onClose: () => void;
  onAdd: () => void;
}
const _AddSituation = ({ options, onClose, onAdd }: AddSituationProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [select, setSelect] = useState<{ value: number; label: string }>();
  const [date, setDate] = useState(moment());
  const createMutation = useSituationControllerCreateMySituation();

  const handleAdd = async () => {
    try {
      if (select) {
        const r = await createMutation.mutateAsync({
          data: {
            dateDeclared: date.format(DateFormats.ISO),
            situationId: select.value,
          },
        });
        if (r) {
          toast(t('Added'));
          onAdd();
          handleClose();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const handleClose = () => {
    setSelect(undefined);
    onClose();
  };

  return (
    <Dialog
      open={options.open}
      onClose={handleClose}
      classes={{ paperScrollPaper: classes.dialog }}
    >
      <DialogTitle>{t('Add')}</DialogTitle>
      <DialogContent className={classes.dialog}>
        <List className={classes.list}>
          <ListItem>
            <AsyncSelect
              isLoading
              {...AutoCompleteStyle}
              loadOptions={(inputValue, callback) => {
                try {
                  if (inputValue.length >= 2 && options.category) {
                    situationControllerGetSituationTypes({
                      category: options.category,
                      name: inputValue,
                    }).then((data) => {
                      callback(
                        data.data.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                      );
                    });
                  }
                } catch (e) {}
              }}
              value={select}
              onChange={(e) => {
                setSelect(e);
              }}
              placeholder={`${t('Search')}...`}
            />
          </ListItem>
          <ListItem>
            <StyledDatePicker
              fullWidth
              value={date}
              onChange={(date) => setDate(date as Moment)}
              label={t('StartDate')}
              showTodayButton
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodaySharp />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Cancel')}</Button>
        <Button disabled={!select && true} onClick={handleAdd}>
          {t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  list: {
    margin: '0 20px',
    minWidth: 300,
    [theme.breakpoints.down('xs')]: {
      minWidth: 200,
    },
  },
  dialog: {
    overflowY: 'visible',
  },
}));
export const AddSituation = hot(_AddSituation);
