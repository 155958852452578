import {
  makeStyles,
  Tab,
  Tabs,
  createMuiTheme,
  ThemeProvider
} from '@material-ui/core';
import { Home } from '@material-ui/icons';
import { useAppContext } from 'context/AppContext';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Colors } from 'res/Enums';
import { navCategories } from 'res/navCategories';
import useResizeObserver from 'use-resize-observer';
import { isUndefined } from 'util';

const SelectTheme = createMuiTheme({
  typography: {
    fontFamily: ['IBM Plex Sans', 'Roboto'].join(',')
  },
  overrides: {
    MuiTab: {
      root: { textTransform: 'none' },
      labelIcon: {
        '& $wrapper > *:first-child': {
          marginBottom: 0,
          marginRight: 8
        }
      }
    },
    MuiButton: {
      root: { textTransform: 'none' },
      containedPrimary: {
        color: Colors.IceWhite
      }
    },
    MuiInputBase: {
      root: { color: Colors.FadeBlack }
    }
  },
  palette: {
    primary: { main: Colors.PRIMARY },
    secondary: { main: Colors.SECONDARY },
    text: { primary: Colors.PRIMARY, secondary: Colors.FadeBlack }
  }
});

const TopNav = ({ history }: RouteComponentProps) => {
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();
  const { navCat, setNavCategory } = useAppContext();
  const classes = useStyles();

  const setNav = () => {
    const navigation: any[] = navCat.map((category, i) => {
      const result = category.url.split('/');
      return result[1];
    });

    let pathName = '';
    const result = /\/\w+\/\w+/g.test(history.location.pathname);
    if (result === true) {
      const nav = history.location.pathname.split('/');
      pathName = `/${nav[1]}`;
    } else pathName = history.location.pathname;

    switch (pathName) {
      case `/${navigation[0]}`:
        setTab(0);
        break;
      case `/${navigation[1]}`:
        setTab(1);
        break;
      case `/${navigation[2]}`:
        setTab(2);
        break;
      case `/${navigation[3]}`:
        setTab(3);
        break;
      case `/${navigation[4]}`:
        setTab(4);
        break;
      case `/${navigation[5]}`:
        setTab(5);
        break;
      default:
        setTab(0);
        break;
    }
  };

  const setNavRefresh = () => {
    const nav = history.location.pathname.split('/');
    const pathName = `/${nav[1]}`;
    let found = navCategories.find(element => {
      return element.items.find(e => e.url === `${pathName}`);
    });
    if (!isUndefined(found)) setNavCategory(found.items);
    else
      setNavCategory([
        {
          label: 'Home',
          icon: { active: <Home />, idle: <Home /> },
          url: '/'
        }
      ]);
  };

  useEffect(() => {
    setNavRefresh();
  }, []);

  useEffect(() => {
    setNav();
    setNavRefresh();
  }, [history.location.pathname]);

  useEffect(() => {
    setNav();
  }, [navCat]);
  const ref = useRef<HTMLButtonElement>(null);
  useResizeObserver<HTMLButtonElement>({ ref });
  return (
    <ThemeProvider theme={SelectTheme}>
      <Fragment>
        <Tabs
          ref={ref}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          value={tab}
          classes={{
            root: classes.root,
            scroller: classes.scroller,
            flexContainer:
              navCat.length > 1 ? classes.flexContainer : classes.container
          }}
          onChange={(_, newVal: number) => setTab(newVal)}
          style={{
            backgroundColor: Colors.IceWhite
          }}
        >
          {navCat.map((category, i) => (
            <Tab
              key={i}
              label={t(category.label)}
              icon={tab === i ? category.icon.active : category.icon.idle}
              classes={{
                wrapper: classes.iconLabelWrapper
              }}
              fullWidth
              onClick={() => history.push(category.url)}
              style={{}}
            />
          ))}
        </Tabs>
      </Fragment>
    </ThemeProvider>
  );
};

const useStyles = makeStyles(theme => ({
  iconLabelWrapper: {
    flexDirection: 'row'
  },
  root: {
    justifyContent: 'center'
  },
  scroller: {
    flexGrow: 1
  },
  flexContainer: {
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between'
    }
  },
  container: {
    justifyContent: 'center'
  }
}));
export default withRouter(TopNav);
