import {
  ThemeProvider,
  Popover,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import { DateTimePickTheme } from './DatePicker';
import { DateTimePicker, DateTimePickerProps } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import Cursor from 'assets/icons/cursor.png';
import { Demo } from './Demo';
import { useTutorialContext } from './../context/TutorialContext';
import { Colors } from 'res/Enums';

const _StyledTutorialDateTimePicker = ({ ...props }: DateTimePickerProps) => {
  const { t } = useTranslation();
  const { openT } = useTutorialContext();
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <ThemeProvider theme={DateTimePickTheme}>
      <DateTimePicker
        {...props}
        cancelLabel={t('Cancel')}
        todayLabel={t('Today')}
      />
      <Popover
        open={parseInt(openT) > 9 && parseInt(openT) < 15}
        anchorOrigin={{
          horizontal: 'left',
          vertical: isBelowMd ? 'top' : 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            WebkitTapHighlightColor: 'transparent',
            boxShadow: 'initial',
            marginLeft: openT === '10' ? '4%' : '1%',
            marginTop: isBelowMd ? '2%' : '-10%',
          },
        }}
      >
        <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
          {openT === '10'
            ? t('Tut20')
            : openT === '12'
            ? t('Tut21')
            : openT === '14' && t('Tut22')}
        </Typography>
      </Popover>
      <Popover
        open={parseInt(openT) > 9 && parseInt(openT) < 15}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            WebkitTapHighlightColor: 'transparent',
            boxShadow: 'initial',
            marginTop: openT === '10' ? -187 : openT === '12' ? -220 : -190,
            marginLeft: openT === '10' ? -130 : openT === '12' ? -150 : 10,
          },
        }}
      >
        <img alt="" src={Cursor} style={{ width: 42 }} />
        <Demo />
      </Popover>
      {openT === '9' && <Demo />}
    </ThemeProvider>
  );
};
export const StyledTutorialDateTimePicker = hot(_StyledTutorialDateTimePicker);
