import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  Divider,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import { FilterBar, useFilter } from 'components/Filter/FilterBar';
import { StyledLineChart } from 'components/LineChart';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Tooltip, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';
import InsulinBox from './InsulinBox';
import { ShortInfo } from 'components/Diabetes/ShortInfo';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { useInsulinControllerGetMyInsulin, UserInsulinDTO } from 'gen/client';
import { useInsulinControllerDeleteMyInsulin } from './../../gen/client';

interface InsulinProps {
  onLoad: () => void;
}

const Insulin = ({ onLoad }: InsulinProps) => {
  const [rawData, setRawData] = useState<UserInsulinDTO[]>([]);
  const [insulin, setInsulin] = useState<UserInsulinDTO[]>([]);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [insulinToEdit, setInsulinToEdit] = useState<UserInsulinDTO>();
  const [editOn, setEditOn] = useState(false);
  const classes = useStyles();
  const [activeFilter, setActiveFilter] = useFilter();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { data, isLoading, refetch } = useInsulinControllerGetMyInsulin();
  const deleteMutation = useInsulinControllerDeleteMyInsulin();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteInsulin = async (id: number) => {
    try {
      const response = await deleteMutation.mutateAsync({ id });
      if (response) {
        toast(t('MeasurementDeleted'));
        refetch();
      } else {
        toast(t('FailedDeleting'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (data) {
      setRawData(data.data);
      setInsulin(data.data);
    }
  }, [data]);

  if (isLoading) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: Colors.Black,
            fontWeight: 'bold',
            fontSize: isBelowXs ? 20 : 26,
          }}
        >
          {t('Insulin')}
        </Typography>
        <Grid container spacing={isBelowXs ? 0 : 3}>
          <Grid item md={7} xs={12}>
            {editOn === false ? (
              <Fragment>
                <Box style={{ margin: '8px 0px' }}>
                  <Typography
                    style={{
                      padding: '16px 0px',
                      color: Colors.Black,
                      fontSize: isBelowXs ? 18 : 20,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('Diagram')}
                  </Typography>
                </Box>
                <Paper>
                  <Box
                    display="flex"
                    className={classes.padd}
                    style={isBelowXs ? { padding: 0 } : {}}
                  >
                    <Box
                      display="flex"
                      style={{ width: isBelowXs ? '100%' : '60%' }}
                    >
                      {insulin.length > 0 && <ShortInfo latest={insulin[0]} />}
                    </Box>
                    <Divider
                      style={{
                        marginTop: '3%',
                        backgroundColor: '#E3DFFA',
                      }}
                    />
                    <Box
                      className={clsx([classes.graph])}
                      style={isBelowXs ? { padding: 6 } : {}}
                    >
                      <FilterBar<UserInsulinDTO>
                        data={rawData}
                        enables={{ date: true, special: true }}
                        onFilter={(data) => {
                          setInsulin(data);
                          onLoad();
                        }}
                        dateKey="time"
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                      />
                      {insulin.length > 0 ? (
                        <StyledLineChart
                          data={insulin
                            .map(({ time, ...rest }) => ({
                              ...rest,
                              time: moment(time).format(DateFormats.GR),
                            }))
                            .slice(0, 100)
                            .reverse()}
                          dataKey="quantity"
                          aspect={3}
                        >
                          <XAxis
                            dataKey="time"
                            axisLine={false}
                            tickLine={false}
                          />
                          <YAxis
                            dataKey="quantity"
                            axisLine={false}
                            tickLine={false}
                          />
                          <Tooltip
                            formatter={(datakey) => [datakey, t('Quantity')]}
                          />
                        </StyledLineChart>
                      ) : rawData.length > 0 ? (
                        <Box
                          style={{
                            display: 'flex',
                            height: '65%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: Colors.Black,
                          }}
                        >
                          {t('NoFilter')}
                        </Box>
                      ) : (
                        <Box
                          style={{
                            display: 'flex',
                            height: '65%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: Colors.Black,
                          }}
                        >
                          {t('NoData')}
                        </Box>
                      )}
                    </Box>
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '2%',
                      }}
                    >
                      <Button
                        variant="text"
                        style={{ padding: 0 }}
                        onClick={() => {
                          if (editOn === false) setEditOn(true);
                          else setEditOn(false);
                        }}
                      >
                        {t('Change')}
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Fragment>
            ) : (
              <Fragment>
                <Box style={{ margin: '8px 0px' }}>
                  <Typography
                    style={{
                      padding: '16px 0px',
                      color: Colors.Black,
                      fontSize: isBelowXs ? 18 : 20,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('DiagramT')}
                  </Typography>
                </Box>
                <Paper style={{ overflow: 'auto' }}>
                  <Box style={{ margin: '8px 0px' }}>
                    <FilterBar<UserInsulinDTO>
                      data={rawData}
                      enables={{ date: true, special: true }}
                      onFilter={(data) => setInsulin(data)}
                      dateKey="time"
                      activeFilter={activeFilter}
                      setActiveFilter={setActiveFilter}
                    />
                  </Box>
                  <Table>
                    <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                      <TableRow>
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('Value')}
                        </TableCell>
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('Date')}
                        </TableCell>
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('Time')}
                        </TableCell>
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('Details')}
                        </TableCell>
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('InsulinType')}
                        </TableCell>
                        <TableCell padding="none" />
                        <TableCell padding="none" />
                        <TableCell padding="none" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? rawData.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : rawData
                      ).map((row, i) => (
                        <TableRow key={i}>
                          <TableCell>{row.quantity}</TableCell>
                          <TableCell>
                            {moment(row.time).format(DateFormats.GrNoTime)}
                          </TableCell>
                          <TableCell>
                            {moment(row.time).format(DateFormats.TIME)}
                          </TableCell>
                          <TableCell>
                            {row.special === 'BEFORE'
                              ? t('Before')
                              : row.special === 'AFTER'
                              ? t('After')
                              : t('Other')}
                          </TableCell>
                          <TableCell>{row.insulin.name}</TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            style={isBelowXs ? { padding: '0px 8px' } : {}}
                          >
                            <EditIcon
                              style={{
                                color: 'rgba(0, 0, 0, 0.58)',
                                fontSize: 18,
                                cursor: 'pointer',
                              }}
                              onClick={() => setInsulinToEdit(row)}
                            />
                          </TableCell>
                          <TableCell align="center" padding="none">
                            <Box
                              style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.34)',
                                width: 2,
                                height: 40,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            style={isBelowXs ? { padding: '0px 8px' } : {}}
                          >
                            <DeleteIcon
                              style={{
                                color: 'rgba(0, 0, 0, 0.58)',
                                fontSize: 18,
                                cursor: 'pointer',
                              }}
                              onClick={async () => {
                                await deleteInsulin(row.id);
                                setInsulinToEdit(undefined);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            10,
                            20,
                            100,
                            { label: 'All', value: -1 },
                          ]}
                          labelRowsPerPage={t('RowsText')}
                          classes={{
                            spacer: classes.spacer,
                            input: classes.input,
                          }}
                          count={rawData.length}
                          onChangePage={handleChangePage}
                          page={page}
                          rowsPerPage={rowsPerPage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '2%',
                      paddingBottom: '2%',
                    }}
                  >
                    <Button
                      variant="text"
                      style={{ padding: 0 }}
                      onClick={() => {
                        setEditOn(false);
                      }}
                    >
                      {t('Change')}
                    </Button>
                  </Box>
                </Paper>
              </Fragment>
            )}
          </Grid>
          <Grid item md={5} xs={12}>
            <Box style={{ margin: '8px 0px', width: 'fit-content' }}>
              <Typography
                style={{
                  padding: '16px 0px',
                  color: Colors.Black,
                  fontSize: !isBelowXs ? 20 : 18,
                  fontWeight: 'bold',
                }}
              >
                {t('InsulinAdd')}
              </Typography>
            </Box>
            <InsulinBox
              onAdd={() => {
                refetch();
              }}
              onEdit={() => {
                refetch();
              }}
              onCancel={() => setInsulinToEdit(undefined)}
              insulin={insulinToEdit}
              lastInsulin={rawData[0]}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  graph: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
  },
  padd: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
  spacer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  input: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
    },
  },
}));

export default hot(Insulin);
