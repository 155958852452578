import { Home } from '@material-ui/icons';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Locale } from 'res/Enums';
import moment from 'moment';
import { NavItem } from 'res/navCategories';
interface AppContext {
  lang: Locale;
  navCat: {
    label: string;
    icon: { active: any; idle: any };
    url: string;
  }[];
  changeLanguage: (changeTo: Locale) => void;
  setNavCategory: (nav: NavItem[]) => void;
}

const domain =
  process.env['REACT_APP_STAGE'] === 'production'
    ? 'healthier-app.com'
    : process.env['REACT_APP_STAGE'] === 'development'
    ? 'healthier-app.com'
    : 'localhost';

const initialContext: AppContext = {
  lang: Locale.GR,
  navCat: [
    { label: 'Home', icon: { active: <Home />, idle: <Home /> }, url: '/' },
  ],
  changeLanguage: (changeTo: Locale) => {},
  setNavCategory: (nav: NavItem[]) => {},
};

export const AppContext = createContext<AppContext>(initialContext);
const _StateProvider = ({
  children,
  location,
  history,
}: PropsWithChildren<RouteComponentProps>) => {
  const [navCat, setNavCat] = useState<NavItem[]>([
    {
      label: 'Home',
      icon: { active: <Home />, idle: <Home /> },
      url: '/',
    },
  ]);
  const [lang, setLang] = useState(
    localStorage.getItem('i18nextLng')?.toLowerCase() === 'en-gb'
      ? (localStorage.getItem('i18nextLng') as Locale)
      : Locale.GR
  );
  const { i18n } = useTranslation();
  const changeLanguage = async (changeTo: Locale) => {
    if (changeTo === Locale.GR) {
      moment.locale('el');
      // document.cookie = 'lang=gr;';
    } else if (changeTo === Locale.EN) {
      moment.locale('en-gb');
      // document.cookie = 'lang=en;';
    }
    document.cookie =
      'lang='
        .concat(changeTo[0].toUpperCase() + changeTo.slice(1))
        .split('-')[0] +
      ';path=/;domain=' +
      domain;
    await i18n.changeLanguage(changeTo);
    await setLang(changeTo);
  };

  const setNavCategory = (nav: NavItem[]) => {
    setNavCat(nav);
  };

  useEffect(() => {
    changeLanguage(lang);
  }, []);

  return (
    <AppContext.Provider
      value={{
        lang,
        navCat,
        changeLanguage,
        setNavCategory,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const StateProvider = withRouter(_StateProvider);
export const useAppContext = () => useContext(AppContext);
