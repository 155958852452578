import {
  Box,
  makeStyles,
  List,
  ListItem,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { UserMedicationDTO, Timetable } from 'gen/client';
import { useTimetableContext } from 'context/TimetableContext';
import { getDays } from 'res/helpers';
import moment from 'moment';
import { Colors, DateFormats } from 'res/Enums';
import { Delete, Save, Refresh } from '@material-ui/icons';
import { MedicationTimetableRow } from './MedicationTimetableRow';
import { uuid } from 'uuidv4';
interface MedicationTimetableProps {
  medication: Partial<UserMedicationDTO>;
  onSave: (rows: Timetable[]) => void;
  onCancel: () => void;
}
export interface TimetableItem extends Timetable {
  uid: string;
}
const _MedicationTimetable = ({
  medication,
  onSave,
  onCancel,
}: MedicationTimetableProps) => {
  const classes = useStyles();
  const { timetableRows, setRows } = useTimetableContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const init = () =>
    getDays(moment()).map((day): TimetableItem => {
      return {
        uid: uuid(),
        dosage: 'dosage',
        frequency: 168,
        time: day.format(DateFormats.ISO),
      };
    });

  const save = () => {
    onSave(timetableRows.filter((item) => item.dosage !== 'dosage'));
  };
  useEffect(() => {
    if (medication.timetable) {
      if (medication.repeatedNormally) {
        setRows([...init()]);
      } else {
        const temp = [
          ...init().filter(
            (init) =>
              medication.timetable?.findIndex((row) =>
                moment(row.time).isSame(moment(init.time), 'day')
              ) === -1
          ),
          ...medication.timetable.map((item) => ({ uid: uuid(), ...item })),
        ]

          .map((item) => ({
            ...item,
            time: moment(item.time).format(DateFormats.ISO),
          }))
          .sort((a, b) => moment(a.time).diff(moment(b.time), 'days'));
        setRows(temp);
      }
    } else {
      setRows(init());
    }
  }, []);
  return (
    <List className={classes.timetableCont}>
      <ListItem>
        <Typography variant="h5">{t('TimetableTitle')}</Typography>
      </ListItem>
      <ListItem
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          paddingRight: '5%',
        }}
      >
        <Button
          startIcon={<Delete />}
          variant="outlined"
          fullWidth={isBelowMd}
          style={{ color: Colors.FadeBlack, marginBottom: 5, minWidth: 150 }}
          onClick={onCancel}
        >
          {t('Cancel')}
        </Button>
        <Button
          startIcon={<Refresh />}
          fullWidth={isBelowMd}
          variant="outlined"
          style={{ color: Colors.FadeBlack, marginBottom: 5, minWidth: 150 }}
          onClick={() => setRows(init())}
        >
          {t('ClearData')}
        </Button>
        <Button
          startIcon={<Save />}
          fullWidth
          variant="contained"
          color="primary"
          onClick={save}
          style={{ marginBottom: 5 }}
          disabled={
            timetableRows.reduce<number>(
              (sum, row) => (row.dosage !== 'dosage' ? sum + 1 : sum),
              0
            ) === 0
          }
        >
          {t('Save')}
        </Button>
      </ListItem>
      <Box style={{ overflowY: 'auto', height: '85%' }}>
        {timetableRows.length > 0 &&
          timetableRows
            .reduce<Timetable[]>(
              (unique, item) =>
                unique
                  .map((item) => moment(item.time).format('dddd'))
                  .includes(moment(item.time).format('dddd'))
                  ? unique
                  : [...unique, item],
              []
            )
            .sort(
              (a, b) =>
                moment(a.time).isoWeekday() - moment(b.time).isoWeekday()
            )
            .map((row, i) => (
              <MedicationTimetableRow key={i} date={moment(row.time)} />
            ))}
      </Box>
    </List>
  );
};
const useStyles = makeStyles(() => ({
  timetableCont: {
    maxWidth: 400,
    paddingRight: '5%',
    display: 'flex',
    flexDirection: 'column',
  },
  valuesCont: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
    paddingTop: 3,
  },
  input: {
    display: 'flex',
    padding: 10,
    backgroundColor: Colors.IceWhite,
    borderRadius: 4,
    alignItems: 'center',
    maxHeight: 40,
    color: Colors.FadeBlack,
    cursor: 'pointer',
  },
}));
export const MedicationTimetable = hot(_MedicationTimetable);
