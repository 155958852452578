import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  Link,
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  CreateAddiction,
  situationControllerCreateMyFirstAddiction,
  SituationDTOCategory,
  UserAddictionDTOType,
} from 'gen/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { Colors, DateFormats } from 'res/Enums';
import { AutoCompleteStyle } from './AutoComplete';
import { isUndefined, isNullOrUndefined } from 'util';
import { useTutorialContext } from 'context/TutorialContext';
import {
  bloodControllerSetMyBlood,
  measurementsControllerCreateMyMeasurement,
  measurementsControllerGetMeasurementByType,
  situationControllerGetSituationTypes,
  situationControllerSetDanger,
  situationControllerSetMyFirstSituation,
  situationControllerSetSituation,
  CreateBloodType,
  CreateSituation,
  SetSituationType,
  MeasurementDTO,
  BloodDTOType,
} from 'gen/client';
import { AddictionListItem } from 'Pages/Profile/EditConcise';
import { useAuthContext } from 'context/AuthContext';

export const RegisterOptional = () => {
  const history = useHistory();
  const classes = useStyles();
  const { registerEnd } = useAuthContext();
  const [blood, setBlood] = useState<CreateBloodType | number>(1);
  const [diseases, setDiseases] = useState<CreateSituation[]>([]);
  // const firstUpdate = useRef(true);
  const { t } = useTranslation();
  const [openDiseases, setOpenDiseases] = useState(false);
  const [openAllergies, setOpenAllergies] = useState(false);
  const [select, setSelect] = useState<
    { situationId: number; label: string }[]
  >([]);
  const [checkbox, setCheckbox] = useState(false);
  const [activeWeight, setActiveWeight] = useState<MeasurementDTO | null>(null);
  const [activeHeight, setActiveHeight] = useState<MeasurementDTO | null>(null);
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { setCont } = useTutorialContext();
  const [addiction, setAddiction] = useState<CreateAddiction[]>([]);

  const setMySmoke = async () => {
    try {
      const responce = await situationControllerCreateMyFirstAddiction({
        data: addiction,
      });
      if (responce.data) {
        toast(t('Success'));
      } else {
        toast(t('FailedAdding'), { containerId: 'delete' });
      }
    } catch (error) {
      toast(t('Problem'));
    }
  };

  const getActiveMeasurement = async (id: string) => {
    try {
      const data1 = await measurementsControllerGetMeasurementByType(id);
      if (id === '8') setActiveWeight(data1.data);
      else setActiveHeight(data1.data);
    } catch (e) {}
  };
  const setMyBlood = async () => {
    if (typeof blood !== 'number') {
      try {
        const response = await bloodControllerSetMyBlood({
          type: blood,
        });
        if (response.data) {
          toast(t('Register2Added'));
        } else {
          toast(t('FailedAdding'), { containerId: 'delete' });
        }
      } catch (error) {
        toast(t('Problem'));
      }
    } else {
      toast(Error);
    }
  };

  const setDanger = async () => {
    try {
      const response = await situationControllerSetDanger();
      if (response.data) {
        toast(t('Success'));
      } else {
        toast(t('FailedAdding'), { containerId: 'delete' });
      }
    } catch (error) {
      toast(t('Problem'));
    }
  };

  const setMyDiseases = async (
    data: CreateSituation[] | { situationId: number; label: string }[]
  ) => {
    try {
      const response = await situationControllerSetMyFirstSituation({
        data: data,
      });
      if (response.data) {
        toast(t('Register2Added'));
      } else {
        toast(t('FailedAdding'), { containerId: 'delete' });
      }
    } catch (error) {
      toast(t('Problem'));
    }
  };

  const setMySituation = async ({
    type,
    set,
  }: {
    type: SetSituationType;
    set: boolean;
  }) => {
    try {
      const response = await situationControllerSetSituation({ type, set });
      if (response.data) {
        toast(t('SymptomAdded'));
      } else {
        toast(t('FailedAdding'), { containerId: 'delete' });
      }
    } catch (error) {
      toast(t('Problem'));
    }
  };

  const newMeasurement = async (measurement: MeasurementDTO) => {
    try {
      const response = await measurementsControllerCreateMyMeasurement({
        value: measurement.id === 8 ? parseInt(weight) : parseInt(height),
        time: moment().format(DateFormats.ISO),
        measurement: measurement,
      });
      if (response.data) {
        toast(t('MeasurementAdded'));
      } else {
        toast(t('FailedAdding'), { containerId: 'deleted' });
      }
    } catch (error) {
      toast(t('Problem'));
    }
  };

  // useEffect(() => {
  //   // if (firstUpdate.current) {
  //   //   firstUpdate.current = false;
  //   if (typeof blood !== 'number') {
  //     setMyBlood();
  //   }
  // }, [blood]);

  useEffect(() => {
    getActiveMeasurement('8');
    getActiveMeasurement('9');
  }, []);

  useEffect(() => {
    if (parseInt(height) < 0) setHeight('');
  }, [height]);

  useEffect(() => {
    if (parseInt(weight) < 0) setWeight('');
  }, [weight]);

  return (
    <Box className={classes.container}>
      <Grid container spacing={4}>
        <Grid xs={12} item>
          <Paper
            style={{
              background: Colors.IceWhite,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                style={{
                  flexGrow: 1,
                  fontSize: isBelowXs ? 16 : 22,
                  padding:
                    diseases.length > 0
                      ? '10px 0px 0px 20px'
                      : isBelowXs
                      ? '10px 0px 10px 8px'
                      : '10px 0px 10px 20px',
                  color: Colors.PrimaryLight,
                }}
              >
                {t('Chronic')}
              </Typography>
              <IconButton onClick={() => setOpenDiseases(true)}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
            {diseases.map((disease, i) => (
              <Typography
                key={i}
                style={{ fontSize: 14, padding: '0px 0px 5px 20px' }}
              >
                {i < 2
                  ? disease.situationId === 7
                    ? 'Optional1'
                    : disease.situationId === 16
                    ? 'Optional2'
                    : disease.situationId === 10
                    ? 'Optional3'
                    : disease.situationId === 18
                    ? 'Optional4'
                    : disease.situationId === 9
                    ? 'Optional5'
                    : 'Optional6'
                  : i === 2 && '. . .'}
              </Typography>
            ))}
            <Dialog
              open={openDiseases === true}
              onClose={() => setOpenDiseases(false)}
              style={{ width: isBelowXs ? '100%' : '160%' }}
            >
              <Typography
                style={{
                  fontSize: isBelowXs ? 16 : 18,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {t('Epil')} {t('ChronicDisease')}
              </Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell padding="checkbox">
                      {/* <Checkbox
                        onChange={e => {
                          e.target.checked
                            ? setTick(tick.concat(7))
                            : tick.map((e, i) => {
                                e === 7 && tick.splice(i, 1);
                              });
                        }}
                        color="primary"
                      /> */}
                      <Checkbox
                        checked={
                          !isUndefined(
                            diseases.find((item, i) => item.situationId === 7)
                          )
                        }
                        onChange={(e) => {
                          e.target.checked
                            ? setDiseases(
                                diseases.concat([
                                  {
                                    situationId: 7,
                                    dateDeclared: moment().format(
                                      DateFormats.ISO
                                    ),
                                  },
                                ])
                              )
                            : setDiseases(
                                diseases.filter(
                                  (item) => item.situationId !== 7
                                )
                              );
                        }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>{t('Optional1')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          !isUndefined(
                            diseases.find((item, i) => item.situationId === 16)
                          )
                        }
                        onChange={(e) => {
                          e.target.checked
                            ? setDiseases(
                                diseases.concat([
                                  {
                                    situationId: 16,
                                    dateDeclared: moment().format(
                                      DateFormats.ISO
                                    ),
                                  },
                                ])
                              )
                            : setDiseases(
                                diseases.filter(
                                  (item) => item.situationId !== 16
                                )
                              );
                        }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>{t('Optional2')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          !isUndefined(
                            diseases.find((item, i) => item.situationId === 10)
                          )
                        }
                        onChange={(e) => {
                          e.target.checked
                            ? setDiseases(
                                diseases.concat([
                                  {
                                    situationId: 10,
                                    dateDeclared: moment().format(
                                      DateFormats.ISO
                                    ),
                                  },
                                ])
                              )
                            : setDiseases(
                                diseases.filter(
                                  (item) => item.situationId !== 10
                                )
                              );
                        }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>{t('Optional3')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          !isUndefined(
                            diseases.find((item, i) => item.situationId === 18)
                          )
                        }
                        onChange={(e) => {
                          e.target.checked
                            ? setDiseases(
                                diseases.concat([
                                  {
                                    situationId: 18,
                                    dateDeclared: moment().format(
                                      DateFormats.ISO
                                    ),
                                  },
                                ])
                              )
                            : setDiseases(
                                diseases.filter(
                                  (item) => item.situationId !== 18
                                )
                              );
                        }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>{t('Optional4')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          !isUndefined(
                            diseases.find((item, i) => item.situationId === 9)
                          )
                        }
                        onChange={(e) => {
                          e.target.checked
                            ? setDiseases(
                                diseases.concat([
                                  {
                                    situationId: 9,
                                    dateDeclared: moment().format(
                                      DateFormats.ISO
                                    ),
                                  },
                                ])
                              )
                            : setDiseases(
                                diseases.filter(
                                  (item) => item.situationId !== 9
                                )
                              );
                        }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>{t('Optional5')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          !isUndefined(
                            diseases.find((item, i) => item.situationId === 13)
                          )
                        }
                        onChange={(e) => {
                          e.target.checked
                            ? setDiseases(
                                diseases.concat([
                                  {
                                    situationId: 13,
                                    dateDeclared: moment().format(
                                      DateFormats.ISO
                                    ),
                                  },
                                ])
                              )
                            : setDiseases(
                                diseases.filter(
                                  (item) => item.situationId !== 13
                                )
                              );
                        }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>{t('Optional6')}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '10% 10%',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: '2% 40%' }}
                  onClick={() => {
                    setOpenDiseases(false);
                  }}
                >
                  OK
                </Button>
              </Box>
            </Dialog>
          </Paper>
        </Grid>
        <Grid xs={12} item>
          <Paper
            style={{
              background: Colors.IceWhite,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                style={{
                  flexGrow: 1,
                  fontSize: isBelowXs ? 18 : 22,
                  padding:
                    diseases.length > 0
                      ? '10px 0px 0px 20px'
                      : isBelowXs
                      ? '10px 0px 10px 8px'
                      : '10px 0px 10px 20px',
                  color: Colors.PrimaryLight,
                }}
              >
                {t(t('Allergies'))}
              </Typography>
              <IconButton onClick={() => setOpenAllergies(true)}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
            {select.map((allergy, i) => (
              <Typography
                key={i}
                style={{ fontSize: 14, padding: '0px 0px 5px 20px' }}
              >
                {i < 2 ? allergy.label : i === 2 && '. . .'}
              </Typography>
            ))}
            <Dialog
              open={openAllergies === true}
              onClose={() => {
                setOpenAllergies(false);
                // setSelect([]);
              }}
              style={{ width: isBelowXs ? '100%' : '160%' }}
              classes={{ paperScrollPaper: classes.dialog }}
            >
              <Typography
                style={{
                  fontSize: isBelowXs ? 16 : 18,
                  fontWeight: 'bold',
                  padding: isBelowXs
                    ? '20px 50px 0px 50px'
                    : '20px 100px 0px 100px',
                }}
              >
                {t('Epil')} {t('Aller')}
              </Typography>
              <ListItem>
                <AsyncSelect
                  {...AutoCompleteStyle}
                  loadOptions={(inputValue, callback) => {
                    if (inputValue.length >= 2) {
                      try {
                        situationControllerGetSituationTypes({
                          category: 'Allergy' as SituationDTOCategory,
                          name: inputValue,
                        }).then((data) => {
                          callback(
                            data.data.map((item, i) => ({
                              situationId: item.id,
                              label: item.name,
                            }))
                          );
                        });
                      } catch (error) {}
                    }
                  }}
                  value={''}
                  onChange={(e) => {
                    select.every((x) => x.label !== e.label) &&
                      setSelect([...select, e]);
                  }}
                  placeholder={`${t('Search')}...`}
                />
              </ListItem>
              <Table size="small">
                <TableBody>
                  {select.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell size="small">
                        <IconButton
                          size="small"
                          style={{ padding: 0 }}
                          onClick={() =>
                            setSelect(
                              select.filter((e) => e.label !== row.label)
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.label}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '10% 10%',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: '2% 40%' }}
                  onClick={() => {
                    // setSelect([]);
                    setOpenAllergies(false);
                  }}
                >
                  OK
                </Button>
              </Box>
            </Dialog>
          </Paper>
        </Grid>
        <Grid xs={12} item>
          <Paper
            style={{
              background: Colors.IceWhite,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography
              style={{
                flexGrow: 1,
                fontSize: isBelowXs ? 18 : 22,
                padding: isBelowXs ? '10px 0px 10px 8px' : '10px 0px 10px 20px',
                color: Colors.PrimaryLight,
              }}
            >
              {t('Smoking')}
            </Typography>
            {checkbox === false && addiction.length === 0 ? (
              <Checkbox
                onChange={(e) =>
                  e.target.checked ? setCheckbox(true) : setCheckbox(false)
                }
                color="primary"
              />
            ) : (
              <IconButton onClick={() => setCheckbox(!checkbox)}>
                <KeyboardArrowDownIcon />
              </IconButton>
            )}
            <Dialog
              open={checkbox === true}
              onClose={() => {
                setCheckbox(false);
              }}
              style={{ width: isBelowXs ? '100%' : '153%' }}
              classes={{
                paperScrollPaper: classes.dialog,
              }}
              PaperProps={{ style: { width: 850, maxWidth: 5000 } }}
            >
              <Typography
                style={{
                  fontSize: isBelowXs ? 16 : 18,
                  fontWeight: 'bold',
                  padding: '16px 0px 0px 24px',
                }}
              >
                {t('RegisterSmoke')}
              </Typography>
              <Box style={{ marginLeft: -100 }}>
                <AddictionListItem
                  type={UserAddictionDTOType.Smoker}
                  register={true}
                  onUpdate={() => {}}
                  onRegister={(item) => setAddiction([...addiction, item])}
                />
                {addiction.length > 0 &&
                  addiction.map((item, i) => (
                    <AddictionListItem
                      key={i}
                      register={true}
                      registerItem={item}
                      type={UserAddictionDTOType.Smoker}
                      onUpdate={() => {}}
                      onRegister={(item) => setAddiction([...addiction, item])}
                      onDelete={(item) =>
                        setAddiction(addiction.filter((temp) => temp !== item))
                      }
                    />
                  ))}
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '2% 2% 2% 4%',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ padding: '6px 32px' }}
                    onClick={() => {
                      setCheckbox(false);
                    }}
                  >
                    OK
                  </Button>
                </Box>
              </Box>
            </Dialog>
          </Paper>
        </Grid>
        <Grid xs={12} item>
          <Paper
            style={{
              background: Colors.IceWhite,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography
              style={{
                flexGrow: 1,
                fontSize: isBelowXs ? 18 : 22,
                padding: isBelowXs ? '10px 0px 10px 8px' : '10px 0px 10px 20px',
                color: Colors.PrimaryLight,
              }}
            >
              {t('BloodType')}
            </Typography>
            <Select
              value={blood}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                setBlood(e.target.value as CreateBloodType);
              }}
              style={isBelowXs ? { fontSize: 13 } : {}}
            >
              <MenuItem hidden disabled value={1}>
                {t('BloodType')}
              </MenuItem>

              {Object.values(BloodDTOType).map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item === BloodDTOType.ABneg
                    ? 'AB-'
                    : item === BloodDTOType.ABpos
                    ? 'AB+'
                    : item === BloodDTOType.Aneg
                    ? 'A-'
                    : item === BloodDTOType.Apos
                    ? 'A+'
                    : item === BloodDTOType.Bneg
                    ? 'B-'
                    : item === BloodDTOType.Bpos
                    ? 'B+'
                    : item === BloodDTOType.Oneg
                    ? 'O-'
                    : 'O+'}
                </MenuItem>
              ))}
            </Select>
          </Paper>
        </Grid>
        <Grid xs={12} item>
          <Paper
            style={{
              background: Colors.IceWhite,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography
              style={{
                flexGrow: 1,
                fontSize: isBelowXs ? 18 : 22,
                padding: isBelowXs ? '10px 0px 10px 8px' : '10px 0px 10px 20px',
                color: Colors.PrimaryLight,
              }}
            >
              {t('Weight')}
            </Typography>
            <TextField
              value={weight}
              type="number"
              style={{
                display: 'flex',
                width: 80,
                justifyContent: 'flex-end',
                marginBottom: 10,
                marginRight: 16,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      color: 'rgba(0, 0, 0, 0.34)',
                    }}
                  >
                    Kg
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setWeight(e.target.value)}
            />
          </Paper>
        </Grid>
        <Grid xs={12} item>
          <Paper
            style={{
              background: Colors.IceWhite,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography
              style={{
                flexGrow: 1,
                fontSize: isBelowXs ? 18 : 22,
                padding: isBelowXs ? '10px 0px 10px 8px' : '10px 0px 10px 20px',
                color: Colors.PrimaryLight,
              }}
            >
              {t('Height')}
            </Typography>
            <TextField
              value={height}
              type="number"
              style={{
                display: 'flex',
                width: 80,
                justifyContent: 'flex-end',
                marginBottom: 10,
                marginRight: 16,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      color: 'rgba(0, 0, 0, 0.34)',
                    }}
                  >
                    {t('cm')}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setHeight(e.target.value)}
            />
          </Paper>
        </Grid>
      </Grid>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            fontSize: 16,
            marginTop: 24,
            padding: '20px 50px 20px 50px',
          }}
          onClick={() => {
            setCont(0);
            if (typeof blood !== 'number') {
              setMyBlood();
            }
            if (addiction.length > 0) setMySmoke();
            if (diseases.length > 0) setMyDiseases(diseases);
            if (select.length > 0) setMyDiseases(select);
            if (checkbox === true)
              setMySituation({
                type: SetSituationType.Smoking,
                set: true,
              });
            if (weight !== '' && !isNullOrUndefined(activeWeight))
              newMeasurement(activeWeight);
            if (height !== '' && !isNullOrUndefined(activeHeight))
              newMeasurement(activeHeight);
            registerEnd();
          }}
        >
          {t('SaveCont')}
        </Button>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginTop: 16,
          marginBottom: isBelowXs ? 24 : 0,
        }}
      >
        <Link
          underline="always"
          style={{
            color: Colors.PRIMARY,
            fontWeight: 'normal',
            fontSize: isBelowXs ? 15 : 16,
            cursor: 'pointer',
          }}
          onClick={() => {
            setCont(0);
            setDanger();
            registerEnd();
          }}
        >
          {t('SkipNoSave')}
        </Link>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  dialog: {
    overflowY: 'visible',
  },
}));
