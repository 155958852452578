import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import Timering from 'assets/icons/avg_timer.svg';
import Walking from 'assets/icons/avg_walk.svg';
import Timer from 'assets/icons/timer.svg';
import Walk from 'assets/icons/Walk.svg';
import { FitnessDTO, UserFitnessDTO } from 'gen/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'res/Enums';

interface FitnessStatisticsProps {
  fitness: UserFitnessDTO[];
  active: FitnessDTO;
}

const _Statistics = ({ fitness, active }: FitnessStatisticsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filterData, setFilterData] = useState<UserFitnessDTO[]>(fitness);
  const days = '';
  const [sumDistance, setSumDistance] = useState(0);
  const [avgDistance, setAvgDistance] = useState(0);
  const [sumTime, setSumTime] = useState(0);
  const [avgTime, setAvgTime] = useState(0);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));

  const sum = (data: any[], property: string) => {
    let counter = 0;
    data = data.filter((e) => e[property] !== null);
    data.reduce((accu: any[], obj: any) => {
      counter = counter + obj[property];
    }, {});
    return counter;
  };

  const avg = (data: any[], property: string) => {
    let counter = 0;
    data = data.filter((e) => e[property] !== null);
    data.reduce((accu: any[], obj: any) => {
      counter = counter + obj[property];
    }, {});
    let average = 0;
    if (data.length > 0) average = Math.round(counter / data.length);
    return average;
  };

  const filter = (x: number) => {
    const temp = filterData.filter((data: any) => {
      const temp = moment(data.time).isSameOrAfter(
        moment().subtract(x, 'days')
      );
      return temp;
    });
    return temp;
  };

  const stats = () => {
    if (days !== '') {
      const a = filter(parseInt(days));
      setSumDistance(sum(a, 'distance'));
      setAvgDistance(avg(a, 'distance'));
      setSumTime(sum(a, 'activityTime'));
      setAvgTime(avg(a, 'activityTime'));
    } else {
      setSumDistance(sum(fitness, 'distance'));
      setAvgDistance(avg(fitness, 'distance'));
      setSumTime(sum(fitness, 'activityTime'));
      setAvgTime(avg(fitness, 'activityTime'));
    }
  };

  useEffect(() => {
    stats();
  }, [filterData]);

  useEffect(() => {
    stats();
  }, [days]);

  useEffect(() => {
    setFilterData(fitness);
  }, [fitness]);

  return (
    <Container style={{ display: 'flex', marginTop: '3%' }}>
      <Grid container spacing={5}>
        {active.hasDistance && (
          <Grid item sm={3} xs={6}>
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '5%',
                paddingBottom: '5%',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Box style={{ position: 'absolute', top: 8 }}>
                <img alt="" src={Walk} />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  className={classes.text}
                  style={isBelowXs ? { fontSize: 20 } : {}}
                >
                  {sumDistance} {t('Meters')}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  className={classes.textLight}
                  style={isBelowXs ? { fontSize: 16 } : {}}
                >
                  {t('SumDistance')}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}
        <Grid item sm={active.hasDistance ? 3 : 6} xs={6}>
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '5%',
              paddingBottom: '5%',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Box style={{ width: 32, position: 'absolute', top: 8 }}>
              <img alt="" src={Timer} />
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography
                className={classes.text}
                style={isBelowXs ? { fontSize: 20 } : {}}
              >
                {Math.round(sumTime / 60)} {t('Minutes')}
              </Typography>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                className={classes.textLight}
                style={isBelowXs ? { fontSize: 16 } : {}}
              >
                {t('SumDuration')}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        {active.hasDistance && (
          <Grid item sm={3} xs={6}>
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '5%',
                paddingBottom: '5%',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Box style={{ position: 'absolute', top: 8 }}>
                <img alt="" src={Walking} />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  className={classes.text}
                  style={isBelowXs ? { fontSize: 20 } : {}}
                >
                  {avgDistance} {t('Meters')}
                </Typography>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  className={classes.textLight}
                  style={isBelowXs ? { fontSize: 16 } : {}}
                >
                  {t('AvgDistperday')}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}
        <Grid item sm={active.hasDistance ? 3 : 6} xs={6}>
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '5%',
              paddingBottom: '5%',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Box style={{ position: 'absolute', top: 8 }}>
              <img alt="" src={Timering} />
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography
                className={classes.text}
                style={isBelowXs ? { fontSize: 20 } : {}}
              >
                {Math.round(avgTime / 60)} {t('Minutes')}
              </Typography>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                className={classes.textLight}
                style={isBelowXs ? { fontSize: 16 } : {}}
              >
                {t('AvgDirperday')}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {/* <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '30%',
          background: '#303f9f'
        }}
      >
        <Typography style={{ color: Colors.IceWhite }}>Statistics</Typography>
        <TextField
          InputProps={{
            style: { color: Colors.IceWhite }
          }}
          InputLabelProps={{
            style: { color: Colors.IceWhite }
          }}
          label={
            days === ''
              ? 'Statistics for all your Measurements'
              : 'Get statistics for the last '.concat(days).concat(' days')
          }
          value={days}
          type="number"
          onChange={e => setDays(e.target.value)}
        />
        <Typography style={{ color: Colors.IceWhite }}>
          Sum Distance: {sumDistance} Km
        </Typography>
        <Typography style={{ color: Colors.IceWhite }}>
          Sum Duration: {sumTime} seconds
        </Typography>
        <Typography style={{ color: Colors.IceWhite }}>
          Avg Distance: {avgDistance} Km
        </Typography>
        <Typography style={{ color: Colors.IceWhite }}>
          Avg Duration: {avgTime} seconds
        </Typography>
      </Box> */}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  text: {
    color: Colors.Black,
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    width: '60%',
  },
  textLight: {
    color: Colors.Black,
    fontSize: 17,
    textAlign: 'center',
  },
}));

export const Statistics = _Statistics;
