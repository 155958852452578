import {
  makeStyles,
  Theme,
  Typography,
  Box,
  Button,
  Link,
  IconButton,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  ThemeProvider,
} from '@material-ui/core';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Colors, DateFormats } from 'res/Enums';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { TrendingFlat, ArrowRightAlt } from '@material-ui/icons';
import moment, { Moment } from 'moment';
import { FormikSelectItem } from 'components/Forms/FormSelectField';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { TextField, Checkbox, Select } from 'formik-material-ui';
import { Terms } from '../../res/Terms';
import { useAuthContext } from 'context/AuthContext';
import { DatePicker } from '@material-ui/pickers';
import { DateTimePickTheme } from 'components/DatePicker';
import { authControllerRegister, RegisterDtoSex } from 'gen/client';
import { isNull, isUndefined } from 'util';
interface InitialValues {
  email: string;
  password: string;
  repeatPass: string;
  name: string;
  surname: string;
  birthDate: Moment | null;
  terms: boolean;
  mailList: boolean;
  sex?: RegisterDtoSex;
}
const initialValues: InitialValues = {
  email: '',
  password: '',
  repeatPass: '',
  name: '',
  surname: '',
  birthDate: null,
  terms: false,
  mailList: false,
  sex: undefined,
};
export const sexSelectItems: FormikSelectItem[] = [
  {
    label: 'Άνδρας',
    value: 'Male',
  },
  {
    label: 'Γυναίκα',
    value: 'Female',
  },
];
const _Register = () => {
  const [submit, setSubmit] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { login } = useAuthContext();
  const [termsDialog, setTermsDialog] = useState(false);
  const registerSchema = Yup.object().shape({
    email: Yup.string().email(t('ReEmail2')).required(t('ReEmail')),
    password: Yup.string().min(8, t('RePassword2')).required(t('RePassword')),
    repeatPass: Yup.string()
      .oneOf([Yup.ref('password'), null], t('RePassword3'))
      .min(8, t('RePassword2'))
      .required(t('RePassword')),
    name: Yup.string().min(2, t('ReName2')).required(t('ReName')),
    surname: Yup.string().min(3, t('ReSurname2')).required(t('ReSurname')),
    sex: Yup.string().required(t('ReSex')),
    terms: Yup.boolean().oneOf([true], t('ReTerms')),
    mailList: Yup.boolean(),
    birthDate: Yup.date().required(t('ReBirthDate')),
  });
  const requestRegister = async (values: typeof initialValues) => {
    try {
      if (values.birthDate) {
        const response = await authControllerRegister({
          sex: values.sex ?? RegisterDtoSex.Male,
          ...values,
          birthDate: moment(values.birthDate).format(DateFormats.ISONoTime),
          termsAgreedDate: moment().format(DateFormats.ISO),
          signUpDate: moment().format(DateFormats.ISO),
        });
        if (response.data.status === true && response.data.token) {
          login(response.data.token);
          history.push('/registeroptional');
        } else {
          if (response.data.code) {
            return toast(t('DuplicateUser'));
          }
          return toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {}
  };

  return (
    <Box className={classes.container}>
      <Box style={{ width: '100%', display: 'flex' }}>
        <IconButton
          className={classes.registerTitle}
          onClick={() => history.push('/login')}
          style={{ padding: 0, transform: 'rotateY(180deg)' }}
        >
          <ArrowRightAlt style={{ fontSize: 38 }} />
        </IconButton>
        <Typography
          className={classes.registerTitle}
          style={{ marginLeft: '5%' }}
        >
          {t('CreateAccount')}
        </Typography>
      </Box>
      <Formik
        validationSchema={registerSchema}
        validateOnChange
        validateOnBlur={false}
        initialValues={initialValues}
        onSubmit={requestRegister}
      >
        {({ isValid, dirty, isSubmitting, errors, values, setFieldValue }) => (
          <Form>
            <Field
              fullWidth
              margin="normal"
              variant="outlined"
              component={TextField}
              name="email"
              type="email"
              label={t('Email')}
            />

            <Field
              fullWidth
              margin="normal"
              variant="outlined"
              component={TextField}
              name="password"
              type="password"
              label={t('Password')}
            />
            <Field
              fullWidth
              margin="normal"
              variant="outlined"
              component={TextField}
              name="repeatPass"
              type="password"
              style={
                {
                  // overflow: 'hidden',
                }
              }
              label={t('RepeatPassword')}
            />
            <Field
              fullWidth
              margin="normal"
              variant="outlined"
              component={TextField}
              name="name"
              label={t('Name')}
            />
            <Field
              fullWidth
              margin="normal"
              variant="outlined"
              component={TextField}
              name="surname"
              label={t('Surname')}
            />
            {/* <FormDateField name="birthDate" beginEmpty label={t('BirthDate')} /> */}
            <DatePickerField
              name="birthDate"
              label={t('BirthDate')}
              value={values.birthDate}
              onChange={setFieldValue}
              submit={submit}
            />
            <FormHelperText
              error={isNull(values.birthDate) && submit}
              style={{
                display: !submit
                  ? 'none'
                  : isNull(values.birthDate)
                  ? 'block'
                  : 'none',
                margin: !submit
                  ? '0px'
                  : isNull(values.birthDate)
                  ? '0px 14px'
                  : '0px',
              }}
            >
              {t('ReBirthDate')}
            </FormHelperText>
            {/* <FormikSelectField
              name="sex"
              label={t('Sex')}
              items={sexSelectItems}
              required
            /> */}
            <FormControl style={{ width: '100%' }}>
              <InputLabel
                htmlFor="register-sex"
                style={{
                  color:
                    (!isUndefined(errors.sex) && !dirty) ||
                    (!isUndefined(errors.sex) && submit)
                      ? 'red'
                      : Colors.Black,
                }}
              >
                {t('Sex')}
              </InputLabel>
              <Field
                component={Select}
                name="sex"
                error={
                  (!isUndefined(errors.sex) && !dirty) ||
                  (!isUndefined(errors.sex) && submit)
                }
                inputProps={{
                  id: 'register-sex',
                }}
              >
                <MenuItem value="Male">{t('Male')}</MenuItem>
                <MenuItem value="Female">{t('Female')}</MenuItem>
              </Field>
              <FormHelperText
                error={!isUndefined(errors.sex)}
                style={{
                  display:
                    isUndefined(errors.sex) || (dirty && !submit)
                      ? 'none'
                      : 'block',
                  margin:
                    isUndefined(errors.sex) || (dirty && !submit)
                      ? '0px'
                      : '0px 14px',
                }}
              >
                {t('ReSex')}
              </FormHelperText>
            </FormControl>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'lighter',
                color: Colors.FadeBlack,
              }}
            >
              <Field
                component={Checkbox}
                color="primary"
                name="terms"
                style={{ padding: 0 }}
              />
              {t('Reg') + ' '}
              <Link
                href="#"
                underline="none"
                style={{ color: Colors.BlueText, fontWeight: 'normal' }}
                onClick={() => setTermsDialog(true)}
              >
                {t('Terms')} {t('And')} {t('The')} {t('Policy')}
              </Link>
            </Typography>
            <FormHelperText
              error={!isUndefined(errors.terms)}
              style={{
                display:
                  isUndefined(errors.terms) || (dirty && !submit)
                    ? 'none'
                    : 'block',
                margin:
                  isUndefined(errors.terms) || (dirty && !submit)
                    ? '0px'
                    : '0px 14px 8px 14px',
              }}
            >
              {t('ReTerms')}
            </FormHelperText>

            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'lighter',
                color: Colors.FadeBlack,
              }}
            >
              <Field
                component={Checkbox}
                color="primary"
                name="mailList"
                style={{ padding: 0 }}
              />
              {t('MailList')}
            </Typography>

            {isSubmitting && <LinearProgress />}
            <Button
              variant="outlined"
              endIcon={<TrendingFlat style={{ marginLeft: 20 }} />}
              size="medium"
              fullWidth
              onClick={() => setSubmit(true)}
              style={
                !dirty || !isValid || isNull(values.birthDate)
                  ? {
                      backgroundColor: 'rgba(0, 0, 0, 0.12)',
                      color: 'rgba(0, 0, 0, 0.26)',
                      border: 'none',
                    }
                  : { backgroundColor: '#5CA595', color: Colors.White }
              }
              type="submit"
              // disabled={!dirty || !isValid}
            >
              {t('Register')}
            </Button>
          </Form>
        )}
      </Formik>
      <Dialog open={termsDialog} onClose={() => setTermsDialog(false)}>
        <DialogTitle>
          {t('Terms')} {t('Policy')}
        </DialogTitle>
        <DialogContent>
          <Terms />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  registerTitle: {
    display: 'flex',
    flex: '1 1 auto',
    fontSize: 22,
    [theme.breakpoints.down(1700)]: {
      fontSize: 20,
    },
    marginTop: theme.spacing(2),
    color: Colors.Black,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    marginBottom: 16,
  },
}));

const DatePickerField = ({
  submit,
  ...props
}: {
  [x: string]: any;
  name: string;
  submit: boolean;
}) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [date, setDate] = useState<Moment | null>(null);
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={DateTimePickTheme}>
      <DatePicker
        {...field}
        {...props}
        onChange={(val) => {
          setDate(val);
          setFieldValue(field.name, val);
        }}
        cancelLabel={t('Cancel')}
        todayLabel={t('Today')}
        disableFuture
        fullWidth
        openTo="year"
        margin="normal"
        format={DateFormats.GrNoTime}
        style={{ marginTop: 0 }}
        inputProps={{ style: { marginTop: 8, color: Colors.Black } }}
        InputLabelProps={{
          style: isNull(date) && submit ? { color: 'red' } : {},
        }}
        error={isNull(date) && submit}
        name="birthDate"
        label={t('BirthDate')}
      />
    </ThemeProvider>
  );
};
export const Register = hot(_Register);
