import {
  Box,
  Container,
  makeStyles,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  Divider,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';
import Graph from 'components/Diabetes/Graph';
import Insulin from 'components/Diabetes/Insulin';
import { FilterBar, GroupProps, useFilter } from 'components/Filter/FilterBar';
import { ShortInfo } from 'components/Home/LatestMeasurement';
import { StyledLineChart } from 'components/LineChart';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Tooltip, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';
import { toast } from 'react-toastify';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { MeasurementsBox } from 'components/Measurements/MeasurementsBox';
import {
  MeasurementDTO,
  useMeasurementsControllerDeleteMyMeasurement,
  useMeasurementsControllerGetMyMeasurementByType,
  UserMeasurementDTO,
  UserSymptomDTO,
  useSymptomControllerGetMySymptomsByType,
  useMeasurementsControllerGetMeasurementByType,
} from 'gen/client';

const Diabetes = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [rawData, setRawData] = useState<UserMeasurementDTO[]>([]);
  const [rawHyper, setRawHyper] = useState<UserSymptomDTO[]>([]);
  const [rawHypo, setRawHypo] = useState<UserSymptomDTO[]>([]);
  const [editOn, setEditOn] = useState(false);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [load, setLoad] = useState(true);
  const [activeMeasurement, setActiveMeasurement] =
    useState<MeasurementDTO | null>(null);
  const [userMeasurement, setUserMeasurement] = useState<UserMeasurementDTO[]>(
    []
  );
  const [measurementToEdit, setMeasurementToEdit] =
    useState<UserMeasurementDTO>();
  const [activeFilter, setActiveFilter] = useFilter();
  const [activeFilter2, setActiveFilter2] = useFilter();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    data: userMeasurementsData,
    isLoading: userMeasurementsLoading,
    refetch: userMeasurementsReFetch,
  } = useMeasurementsControllerGetMyMeasurementByType('191');
  const {
    data: hyperData,
    isLoading: hyperLoading,
    refetch: hyperRefetch,
  } = useSymptomControllerGetMySymptomsByType('6');
  const {
    data: hypoData,
    isLoading: hypoLoading,
    refetch: hypoRefetch,
  } = useSymptomControllerGetMySymptomsByType('7');
  const { data: activeMeasurementData, isLoading: activeMeasurementsLoading } =
    useMeasurementsControllerGetMeasurementByType('191');
  const deleteMutation = useMeasurementsControllerDeleteMyMeasurement();

  useEffect(() => {
    if (userMeasurementsData) setRawData(userMeasurementsData.data);
  }, [userMeasurementsData]);

  useEffect(() => {
    if (hyperData) setRawHyper(hyperData.data);
  }, [hyperData]);

  useEffect(() => {
    if (hypoData) setRawHypo(hypoData.data);
  }, [hypoData]);

  useEffect(() => {
    if (activeMeasurementData) setActiveMeasurement(activeMeasurementData.data);
  }, [activeMeasurementData]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteMeasurement = async (id: number) => {
    try {
      const response = await deleteMutation.mutateAsync({ id });
      if (response) {
        toast(t('MeasurementDeleted'));
        userMeasurementsReFetch();
      } else {
        toast(t('FailedDeleting'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (load === false) window.scrollTo(0, 0);
  }, [load]);

  if (
    activeMeasurementsLoading ||
    userMeasurementsLoading ||
    hypoLoading ||
    hyperLoading ||
    activeMeasurement === null
  ) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  } else
    return (
      <Container maxWidth={false}>
        <Box className={classes.box}>
          <Box>
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                color: Colors.Black,
                fontWeight: 'bold',
                fontSize: isBelowXs ? 20 : 26,
              }}
            >
              {t('Sugar')}
            </Typography>
            <Grid container spacing={isBelowXs ? 0 : 3}>
              <Grid item md={7} xs={12}>
                {editOn === false ? (
                  <Fragment>
                    <Box style={{ margin: '8px 0px' }}>
                      <Typography
                        style={{
                          padding: '16px 0px',
                          color: Colors.Black,
                          fontSize: isBelowXs ? 18 : 20,
                          fontWeight: 'bold',
                        }}
                      >
                        {t('Diagram')}
                      </Typography>
                    </Box>
                    <Paper>
                      <Box
                        display="flex"
                        className={classes.padd}
                        style={isBelowXs ? { padding: 0 } : {}}
                      >
                        <Box
                          display="flex"
                          style={{ width: isBelowXs ? '100%' : '60%' }}
                        >
                          <ShortInfo latest={rawData[0]} />
                        </Box>
                        <Divider
                          style={{
                            marginTop: '3%',
                            backgroundColor: '#E3DFFA',
                          }}
                        />
                        <Box
                          className={clsx([classes.graph])}
                          style={isBelowXs ? { padding: 6 } : {}}
                        >
                          <FilterBar<UserMeasurementDTO>
                            data={rawData}
                            enables={{ date: true, special: true }}
                            onFilter={(data, group) =>
                              setUserMeasurement(
                                ((_data) => {
                                  if (
                                    data.length > 1 &&
                                    group !== GroupProps.ALL &&
                                    group !== GroupProps.YEAR
                                  ) {
                                    let sorted = data.sort((a, b) =>
                                      moment(a.time).diff(moment(b.time))
                                    );
                                    let temp: UserMeasurementDTO[] = sorted;
                                    sorted.forEach((item, i) => {
                                      if (sorted[i + 1]) {
                                        const next = sorted[i + 1];
                                        const diff = Math.abs(
                                          moment(item.time).diff(
                                            next.time,
                                            group === GroupProps.MONTH
                                              ? 'months'
                                              : group === GroupProps.WEEK
                                              ? 'weeks'
                                              : 'days'
                                          )
                                        );
                                        if (diff > 1) {
                                          const newDates: string[] = [];
                                          for (let i = 1; i < diff; i++) {
                                            newDates.push(
                                              group === GroupProps.WEEK
                                                ? moment(item.time)
                                                    .startOf('week')
                                                    .add(i, 'weeks')
                                                    .format(DateFormats.ISO)
                                                : moment(item.time)
                                                    .add(
                                                      i,
                                                      group === GroupProps.MONTH
                                                        ? 'month'
                                                        : 'day'
                                                    )
                                                    .format(DateFormats.ISO)
                                            );
                                          }
                                          temp = temp.concat(
                                            ...(newDates.map((item) => ({
                                              time: item,
                                              value: 0,
                                            })) as unknown as UserMeasurementDTO[])
                                          );
                                        }
                                      }
                                    });
                                    return temp.sort((a, b) =>
                                      moment(b.time).diff(moment(a.time))
                                    );
                                  }
                                  return _data;
                                })(data)
                              )
                            }
                            dateKey="time"
                            numeric={false}
                            activeFilter={activeFilter}
                            setActiveFilter={setActiveFilter}
                          />
                          {userMeasurement.length > 0 ? (
                            <StyledLineChart
                              data={userMeasurement
                                .map(({ time, ...rest }) => ({
                                  ...rest,
                                  time: moment(time).format(DateFormats.GR),
                                }))
                                .slice(0, 100)
                                .reverse()}
                              dataKey="value"
                              aspect={3}
                            >
                              <XAxis
                                dataKey="time"
                                axisLine={false}
                                tickLine={false}
                              />
                              <YAxis
                                dataKey="value"
                                axisLine={false}
                                tickLine={false}
                                label={{
                                  value:
                                    activeMeasurement !== null &&
                                    activeMeasurement.limits[0]
                                      ? activeMeasurement.limits[0].unit
                                      : undefined,
                                  angle: -90,
                                  offset: 10,
                                  position: 'insideLeft',
                                  textAnchor: 'middle',
                                }}
                              />
                              <Tooltip
                                formatter={(datakey) => [datakey, t('Value')]}
                              />
                            </StyledLineChart>
                          ) : rawData.length > 0 ? (
                            <Box
                              style={{
                                display: 'flex',
                                height: '65%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 16,
                                fontWeight: 'bold',
                                color: Colors.Black,
                              }}
                            >
                              {t('NoFilter')}
                            </Box>
                          ) : (
                            <Box
                              style={{
                                display: 'flex',
                                height: '65%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 16,
                                fontWeight: 'bold',
                                color: Colors.Black,
                              }}
                            >
                              {t('NoData')}
                            </Box>
                          )}
                        </Box>
                        <Box
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '2%',
                          }}
                        >
                          <Button
                            variant="text"
                            style={{ padding: 0 }}
                            onClick={() => {
                              if (editOn === false) setEditOn(true);
                              else setEditOn(false);
                            }}
                          >
                            {t('ChangeGraph1')}
                          </Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Box style={{ margin: '8px 0px' }}>
                      <Typography
                        style={{
                          padding: '16px 0px',
                          color: Colors.Black,
                          fontSize: isBelowXs ? 18 : 20,
                          fontWeight: 'bold',
                        }}
                      >
                        {t('DiagramT')}
                      </Typography>
                    </Box>
                    <Paper style={{ overflow: 'auto' }}>
                      <Box style={{ margin: '8px 0px' }}>
                        <FilterBar<UserMeasurementDTO>
                          data={rawData}
                          enables={{ date: true, special: true }}
                          onFilter={(data) => setUserMeasurement(data)}
                          dateKey="time"
                          numeric={false}
                          activeFilter={activeFilter2}
                          setActiveFilter={setActiveFilter2}
                        />
                      </Box>
                      <Table>
                        <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                          <TableRow>
                            <TableCell style={{ color: '#FFFFFF' }}>
                              {t('Value')}
                            </TableCell>
                            <TableCell style={{ color: '#FFFFFF' }}>
                              {t('Date')}
                            </TableCell>
                            <TableCell style={{ color: '#FFFFFF' }}>
                              {t('Time')}
                            </TableCell>
                            <TableCell style={{ color: '#FFFFFF' }}>
                              {t('Details')}
                            </TableCell>
                            <TableCell padding="none" />
                            <TableCell padding="none" />
                            <TableCell padding="none" />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(rowsPerPage > 0
                            ? userMeasurement.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                            : userMeasurement
                          ).map((row, i) => (
                            <TableRow key={i}>
                              <TableCell>{row.value}</TableCell>
                              <TableCell>
                                {moment(row.time).format(DateFormats.GrNoTime)}
                              </TableCell>
                              <TableCell>
                                {moment(row.time).format(DateFormats.TIME)}
                              </TableCell>
                              <TableCell>
                                {row.special === 'BEFORE'
                                  ? t('Before')
                                  : row.special === 'AFTER'
                                  ? t('After')
                                  : t('Other')}
                              </TableCell>
                              <TableCell
                                align="center"
                                padding="none"
                                style={isBelowXs ? { padding: '0px 8px' } : {}}
                              >
                                <EditIcon
                                  style={{
                                    color: 'rgba(0, 0, 0, 0.58)',
                                    fontSize: 18,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setMeasurementToEdit(row)}
                                />
                              </TableCell>
                              <TableCell align="center" padding="none">
                                <Box
                                  style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.34)',
                                    width: 2,
                                    height: 40,
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                padding="none"
                                style={isBelowXs ? { padding: '0px 8px' } : {}}
                              >
                                <DeleteIcon
                                  style={{
                                    color: 'rgba(0, 0, 0, 0.58)',
                                    fontSize: 18,
                                    cursor: 'pointer',
                                  }}
                                  onClick={async () => {
                                    await deleteMeasurement(row.id);
                                    setMeasurementToEdit(undefined);
                                    hypoRefetch();
                                    hyperRefetch();
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[
                                10,
                                20,
                                100,
                                { label: 'All', value: -1 },
                              ]}
                              classes={{
                                spacer: classes.spacer,
                                input: classes.input,
                              }}
                              labelRowsPerPage={t('RowsText')}
                              count={rawData.length}
                              onChangePage={handleChangePage}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onChangeRowsPerPage={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                      <Box
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '2%',
                          paddingBottom: '2%',
                          paddingRight: 16,
                        }}
                      >
                        <Button
                          variant="text"
                          style={{ padding: 0 }}
                          onClick={() => {
                            setEditOn(false);
                          }}
                        >
                          {t('ChangeGraph2')}
                        </Button>
                      </Box>
                    </Paper>
                  </Fragment>
                )}
              </Grid>
              <Grid item md={5} xs={12}>
                <Box style={{ margin: '8px 0px', width: 'fit-content' }}>
                  <Typography
                    style={{
                      padding: '16px 0px',
                      color: Colors.Black,
                      fontSize: !isBelowXs ? 20 : 18,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('RecordMeasurement')}
                  </Typography>
                </Box>
                <MeasurementsBox
                  onAdd={() => {
                    hypoRefetch();
                    hyperRefetch();
                    userMeasurementsReFetch();
                  }}
                  onEdit={() => {
                    hypoRefetch();
                    hyperRefetch();
                    userMeasurementsReFetch();
                  }}
                  onCancel={() => setMeasurementToEdit(undefined)}
                  measurement={measurementToEdit}
                  active={activeMeasurement}
                  lastMeasurement={rawData[0]}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.box}>
          <Insulin onLoad={() => setLoad(false)} />
        </Box>
        <Grid container spacing={3} className={classes.box}>
          <Grid item md={6} xs={12}>
            <Graph active={activeMeasurement} symptoms={rawHypo} id="hypo" />
          </Grid>
          <Grid item md={6} xs={12}>
            <Graph active={activeMeasurement} symptoms={rawHyper} id="hyper" />
          </Grid>
        </Grid>
      </Container>
    );
};
const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: 60,
  },
  graph: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    height: '100%',
  },
  padd: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
  spacer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  input: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
    },
  },
}));
export default hot(Diabetes);
