import {
  Box,
  IconButton,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import CalendarIcon from 'assets/icons/calendarIcon.svg';
import moment, { Moment } from 'moment';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { DateFormats } from 'res/Enums';
import { isUndefined } from 'util';
import { StyledDatePicker } from 'components/DatePicker';

interface Dates {
  from: { active: boolean; value: Moment };
  to: { active: boolean; value: Moment };
}
interface DateSelectProps {
  resetDates?: boolean;
  onSelect: (dates: Dates) => void;
  temp?: Dates;
}
const _DateSelect = ({
  onSelect,
  resetDates,
  temp,
  children,
}: PropsWithChildren<DateSelectProps>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [dates, setDates] = useState<Dates>({
    from: { active: false, value: moment() },
    to: { active: false, value: moment() },
  });
  const [open, setOpen] = useState({ from: false, to: false });
  useEffect(() => {
    onSelect(dates);
  }, [dates]);

  useEffect(() => {
    if (!isUndefined(temp) && resetDates) setDates(temp);
  }, [temp]);

  return (
    <Box
      className={classes.container}
      style={
        children
          ? {
              flexDirection: isBelowSm ? 'column' : 'row',
              justifyContent: !isBelowSm ? 'space-evenly' : 'flex-start',
            }
          : {}
      }
    >
      <Box
        display="flex"
        style={
          children
            ? {
                marginBottom: children && !isBelowSm ? 0 : 24,
                width: isBelowSm ? '100%' : '50%',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                height: '100%',
              }
            : {
                width: '100%',
                justifyContent: 'space-evenly',
                justifyItems: 'center',
                alignItems: 'center',
                height: 'center',
              }
        }
      >
        <Box className={classes.item} style={{ flex: '0 1 auto' }}>
          <DateField
            label={
              !dates.from.active || !isUndefined(resetDates)
                ? t('SearchFrom')
                : dates.from.value.format(DateFormats.GrNoTime)
            }
            onClick={() => setOpen({ ...open, from: true })}
            onRemove={() =>
              setDates({ ...dates, from: { active: false, value: moment() } })
            }
          />
          <StyledDatePicker
            style={{ display: 'none' }}
            open={open.from}
            onClose={() => setOpen({ ...open, from: false })}
            value={dates.from.value}
            showTodayButton
            autoOk
            onChange={(date) =>
              setDates({
                ...dates,
                from: { active: true, value: date as Moment },
              })
            }
          />
        </Box>
        <Box className={classes.item} style={{ flex: '0 1 auto' }}>
          <DateField
            label={
              !dates.to.active || !isUndefined(resetDates)
                ? t('SearchTo')
                : dates.to.value.format(DateFormats.GrNoTime)
            }
            onClick={() => setOpen({ ...open, to: true })}
            onRemove={() =>
              setDates({ ...dates, to: { active: false, value: moment() } })
            }
          />
          <StyledDatePicker
            style={{ display: 'none' }}
            open={open.to}
            onClose={() => setOpen({ ...open, to: false })}
            value={dates.to.value}
            showTodayButton
            autoOk
            onChange={(date) =>
              setDates({
                ...dates,
                to: { active: true, value: date as Moment },
              })
            }
          />
        </Box>
      </Box>
      {children && (
        <Box
          className={classes.item}
          style={{
            width: isBelowSm ? '100%' : '50%',
            justifyContent: 'space-evenly',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: '1 1 auto',
  },
  item: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
  },
}));
export const DateSelect = hot(_DateSelect);
interface DateFieldProps {
  label: string;
  onClick: () => void;
  onRemove: () => void;
}
const DateField = ({ label, onClick, onRemove }: DateFieldProps) => {
  const classes = useStyles1();
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box
      className={classes.main}
      style={
        isBelowXs
          ? {
              margin: '8px 8px 8px 6px',
              padding: '4px 0px',
              width: 132,
              justifyContent: 'space-evenly',
            }
          : isBelowSm
          ? {
              margin: '8px 8px 8px 6px',
              padding: '4px 0px',
              width: 180,
              justifyContent: 'space-evenly',
            }
          : { width: 180, justifyContent: 'space-evenly' }
      }
    >
      <IconButton size="small" onClick={onRemove}>
        <Clear style={isBelowSm ? { fontSize: 20 } : {}} />
      </IconButton>
      <Box className={classes.wrap} onClick={onClick}>
        <Box
          className={classes.label}
          style={isBelowSm ? { fontSize: 11 } : {}}
        >
          {label}
        </Box>
        <Box className={classes.icon}>
          <img
            alt=""
            src={CalendarIcon}
            style={isBelowSm ? { height: 18, width: 18 } : {}}
          />
        </Box>
      </Box>
    </Box>
  );
};
const useStyles1 = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    margin: '8px 8px 8px 0px',
    backgroundColor: 'rgba(196, 196, 196, 0.27)',
    padding: 6,
    alignItems: 'center',
    borderRadius: 4,
    color: '#525A5E',
    fontSize: 12,
    cursor: 'pointer',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  wrap: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    flex: '1 1 auto',
  },
}));
