import React from 'react';
import { Box, Button } from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { ToastOptions, toast } from 'react-toastify';
import { Colors } from 'res/Enums';
import { useTranslation } from 'react-i18next';

interface ToastProps {
  closeToast?: () => void;
  onUndo: () => void;
}
const DeleteToastContainer = ({ closeToast, onUndo }: ToastProps) => {
  const { t } = useTranslation();
  const handleUndo = () => {
    if (!isNullOrUndefined(closeToast)) {
      closeToast();
    }
    onUndo();
  };
  return (
    <Box>
      <Box> {t('Deleting')}...</Box>
      <Box display="flex">
        <Button
          style={{ color: Colors.PRIMARY }}
          size="small"
          onClick={handleUndo}
        >
          {t('Undo')}
        </Button>
      </Box>
    </Box>
  );
};
export const DeleToastOptions: ToastOptions = {
  closeOnClick: false,
  bodyClassName: 'toast-delete-main',
  progressClassName: 'toast-delete-prog',
};
export const DeleteToast = () => {
  // const [undo, setUndo] = useState(false);
  let undo = false;
  return new Promise((resolve) => {
    toast(<DeleteToastContainer onUndo={() => (undo = true)} />, {
      ...DeleToastOptions,
      onClose: () => {
        if (!undo) {
          resolve(null);
        }
      },
    });
  });
};
