import React, { useEffect, useState } from 'react';
import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Colors } from 'res/Enums';
import { useTranslation } from 'react-i18next';
import theme from '../../assets/theme';
import { CssProps } from 'typings';
import {
  MedicineColorDto,
  useMedicationControllerGetMedicineColors,
} from 'gen/client';
interface MedicineColorPickerProps {
  onChange: (medColor: MedicineColorDto) => void;
  selected: MedicineColorDto;
}
export const MedicineColorPicker = ({
  onChange,
  selected,
}: MedicineColorPickerProps) => {
  const [colors, setColors] = useState<MedicineColorDto[]>([]);
  const [selectedColor, setSelectedColor] = useState(selected);
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: colorsData } = useMedicationControllerGetMedicineColors();
  const { t } = useTranslation();
  useEffect(() => {
    if (colorsData) setColors(colorsData.data);
  }, [colorsData]);
  useEffect(() => setSelectedColor(selected), [selected]);
  return (
    <Box
      display="flex"
      flexGrow={1}
      style={{
        backgroundColor: Colors.IceWhite,
        border: '1px solid rgba(38, 50, 56, 0.12)',
        padding: 5,
        fontSize: 18,
        flexDirection: isBelowSm ? 'column' : 'row',
      }}
    >
      <Box
        display="flex"
        flexGrow={2}
        marginX={3}
        alignItems="center"
        justifyContent="center"
      >
        {t('Color')}
      </Box>
      <Box display="flex" flexGrow={1}>
        <RadioGroup
          row
          value={selectedColor.code}
          style={{ display: 'flex', flexWrap: 'wrap', padding: 3 }}
          onChange={(e) =>
            onChange(
              colors.find(
                (item) => item.code === e.target.value
              ) as MedicineColorDto
            )
          }
        >
          {colors.map((color, i) => (
            <FormControlLabel
              key={i}
              value={color.code}
              control={
                <Radio
                  icon={
                    <Circle
                      color={color.code}
                      style={{
                        height: isBelowSm ? 15 : 25,
                        width: isBelowSm ? 15 : 25,
                      }}
                    />
                  }
                  style={{}}
                  color="primary"
                  checkedIcon={
                    <Check
                      style={{
                        color: Colors.FadeBlack,
                        fontSize: isBelowSm ? 15 : 25,
                        opacity: 0.7,
                        border: '1px solid ' + Colors.PRIMARY,
                        backgroundColor: color.code,
                        borderRadius: '100%',
                      }}
                    />
                  }
                />
              }
              label=""
            />
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
};

interface CircleProps {
  color: string;
  style: CssProps;
}
const Circle = ({ color, ...rest }: CircleProps) => (
  <div
    style={{
      margin: 3,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: color,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      ...rest.style,
    }}
  />
);
