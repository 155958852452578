import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Colors, DateFormats } from 'res/Enums';
import CloseIcon from '@material-ui/icons/Close';
import { StyledDatePicker } from 'components/DatePicker';
import { Moment } from 'moment';
import { toast } from 'react-toastify';
import {
  MeasurementDTO,
  measurementsControllerGetMeasurementByType,
  measurementsControllerGetMyMeasurementByType,
  pregnancyControllerGetMyPregnancyMeasurements,
  PregnancyDTO,
  useMeasurementsControllerCreateMyMeasurement,
  usePregnancyControllerCreateMyPregnancy,
  usePregnancyControllerUpdateMyPregnancy,
} from 'gen/client';

interface PregnancyBox {
  pregnancy?: PregnancyDTO;
  onSuccess: () => void;
}

const _PregnancyBox = ({ pregnancy, onSuccess }: PregnancyBox) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const [time, setTime] = useState<Moment | null>(null);
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [weightType, setWeightType] = useState<MeasurementDTO>();
  const [heightType, setHeightType] = useState<MeasurementDTO>();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const createMutation = usePregnancyControllerCreateMyPregnancy();
  const updateMutation = usePregnancyControllerUpdateMyPregnancy();
  const createMeasurementMutation =
    useMeasurementsControllerCreateMyMeasurement();

  const addPregnancy = async () => {
    try {
      if (time !== null) {
        const resp = await createMutation.mutateAsync({
          data: {
            startDate: time.format(DateFormats.ISONoTime),
          },
        });
        if (resp) {
          toast(t('PregnancyAdded'));
          addWeight();
          addHeight();
          onSuccess();
        } else {
          toast(t('FailedAdding'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('FailedAdding'), { containerId: 'deleted' });
    }
  };

  const addWeight = async () => {
    try {
      if (time !== null && weightType !== undefined) {
        const resp = await createMeasurementMutation.mutateAsync({
          data: {
            value: parseInt(weight),
            time: time.format(DateFormats.ISONoTime),
            measurement: weightType,
          },
        });
      }
    } catch (error) {
      toast(t('FailedAdding'), { containerId: 'deleted' });
    }
  };

  const addHeight = async () => {
    try {
      if (time !== null && heightType !== undefined) {
        const resp = await createMeasurementMutation.mutateAsync({
          data: {
            value: parseInt(height),
            time: time.format(DateFormats.ISONoTime),
            measurement: heightType,
          },
        });
      }
    } catch (error) {
      toast(t('FailedAdding'), { containerId: 'deleted' });
    }
  };

  const editPregnancy = async () => {
    try {
      if (pregnancy !== undefined && time !== null) {
        const resp = await updateMutation.mutateAsync({
          id: pregnancy.id,
          data: {
            startDate: time?.format(DateFormats.ISONoTime),
          },
        });
        if (resp) {
          toast(t('PregnancyAdded'));
          // !isUndefined(onClose) && onClose();
        } else {
          toast(t('FailedAdding'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('FailedAdding'), { containerId: 'deleted' });
    }
  };

  const getMeasurementType = async () => {
    const data = await measurementsControllerGetMeasurementByType('8');
    const data1 = await measurementsControllerGetMeasurementByType('9');
    const data2 = await measurementsControllerGetMyMeasurementByType('9');
    setWeightType(data.data);
    setHeightType(data1.data);
    if (data2.data.length > 0) setHeight(data2.data[0].value.toString());
  };

  const getPregnancyMeasurements = async () => {
    if (pregnancy !== undefined) {
      const data = await pregnancyControllerGetMyPregnancyMeasurements(
        pregnancy.startDate
      );
      setWeight(data.data[0].value.toString());
      setHeight(data.data[1].value.toString());
    }
  };

  useEffect(() => {
    getMeasurementType();
    if (pregnancy !== undefined) getPregnancyMeasurements();
  }, []);

  return (
    <Box
      style={{
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 6,
        backgroundColor: Colors.IceWhite,
      }}
    >
      <Box
        style={{
          display: 'flex',
          paddingLeft: isBelowXs ? 4 : 16,
          paddingTop: isBelowXs ? 4 : 16,
        }}
      >
        <Box style={{ display: 'flex', flexGrow: 1 }}>
          <Typography
            style={{
              fontSize: isBelowXs ? 20 : 22,
              fontWeight: 'bold',
              color: Colors.Black,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t('PreAsk1')}
          </Typography>
        </Box>
        {pregnancy !== undefined && (
          <Box>
            <IconButton style={!isBelowXs ? { marginLeft: 16 } : {}}>
              <CloseIcon
                style={
                  !isBelowXs ? { fontSize: 40 } : { fontSize: 30, padding: 0 }
                }
              />
            </IconButton>
          </Box>
        )}
      </Box>
      <Grid container>
        <Grid item xs={5} sm={8}>
          <Typography
            style={{
              padding: isBelowXs ? '10px 0px 0px 4px' : '24px 24px 0% 24px',
              color: Colors.Black,
              fontSize: isBelowXs ? 14 : 16,
              fontWeight: 'bold',
            }}
          >
            {t('PQuestion1')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={7}
          sm={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <StyledDatePicker
            autoOk
            autoFocus
            style={{
              paddingRight: isBelowXs ? 4 : 24,
              marginLeft: isBelowXs ? 4 : 0,
            }}
            inputProps={{ style: { fontSize: 16 } }}
            value={time}
            margin="normal"
            format={DateFormats.GrNoTime}
            onChange={(date) => setTime(date as Moment)}
            animateYearScrolling
            disableFuture
          />
        </Grid>
        <Grid item xs={5} sm={8}>
          <Typography
            style={{
              padding: isBelowXs ? '10px 0px 0px 4px' : '24px 24px 0% 24px',
              color: Colors.Black,
              fontSize: isBelowXs ? 14 : 16,
              fontWeight: 'bold',
            }}
          >
            {t('QWeight')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={7}
          sm={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            style={{
              paddingRight: isBelowXs ? 4 : 24,
              marginLeft: isBelowXs ? 4 : 0,
            }}
            inputProps={{ style: { fontSize: 16 } }}
            value={weight}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{
                    color: 'rgba(0, 0, 0, 0.34)',
                    fontSize: 16,
                  }}
                >
                  Kg
                </InputAdornment>
              ),
            }}
            placeholder={t('Placeholder')}
            onChange={(e) => setWeight(e.target.value)}
          />
        </Grid>
        <Grid item xs={5} sm={8}>
          <Typography
            style={{
              padding: isBelowXs ? '10px 0px 0px 4px' : '24px 24px 0% 24px',
              color: Colors.Black,
              fontSize: isBelowXs ? 14 : 16,
              fontWeight: 'bold',
            }}
          >
            {t('QHeight')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={7}
          sm={4}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            style={{
              paddingRight: isBelowXs ? 4 : 24,
              marginLeft: isBelowXs ? 4 : 0,
            }}
            inputProps={{ style: { fontSize: 16 } }}
            value={height}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{
                    color: 'rgba(0, 0, 0, 0.34)',
                    fontSize: 16,
                  }}
                >
                  {t('cm')}
                </InputAdornment>
              ),
            }}
            placeholder={t('Placeholder')}
            onChange={(e) => setHeight(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 50, marginBottom: 24 }}>
          <Box
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={pregnancy === undefined ? addPregnancy : editPregnancy}
              disabled={time === null || weight === '' || height === ''}
            >
              {t('PreAsk1')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({}));
export const PregnancyBox = hot(_PregnancyBox);
