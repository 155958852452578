import { useState, useEffect, useRef } from 'react';
import {
  IconButton,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  makeStyles,
  Select,
  MenuItem,
  useMediaQuery,
  Popover,
  Container,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Clear, CalendarTodaySharp, Add } from '@material-ui/icons';

import { AutoCompleteStyle } from 'components/AutoComplete';
import AsyncSelect from 'react-select/async';
import { ValueType } from 'react-select/src/types';
import { isNullOrUndefined, isUndefined } from 'util';
import { MedicineColorPicker } from './MedicineColorPicker';
import { MedicineShapePicker } from './MedicineShapePicker';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import { MedicationTimetable } from './Timetable/MedicationTimetable';
import { hot } from 'react-hot-loader/root';
import { Colors, dosage, DateFormats } from 'res/Enums';
import { StyledNumberPicker } from 'components/StyledNumberPicker';
import { TimetableProvider } from 'context/TimetableContext';
import { useTheme } from '@material-ui/core';
import { StyledDateTimePicker } from 'components/DateTimePicker';
import { useTutorialContext } from 'context/TutorialContext';
import { Demo } from 'components/Demo';
import Cursor from 'assets/icons/cursor.png';
import {
  medicationControllerGetMedicines,
  medicationControllerCreateMyMedication,
  medicationControllerUpdateMyMedication,
  UserMedicationDTO,
  MedicineDTO,
  CreateMedication,
  UpdateMedication,
} from './../../gen/client';

interface MedicationDrawerProps {
  userData?: Partial<UserMedicationDTO>;
  onUpdated: () => void;
  onClose: () => void;
  onTimetable?: () => void;
}
interface SelectedMedicine {
  value: number;
  label: string;
}

const defaultMedication: Partial<UserMedicationDTO> = {
  startDate: moment().set('minute', 0).format(DateFormats.ISO),
  duration: 6,
  color: { id: 8, name: 'White', code: '#ffffff' },
  shape: { id: 8, type: 'Δισκίο' },
  repeatedNormally: true,
  timetable: [
    {
      frequency: 6,
      time: moment().set('minute', 0).format(DateFormats.ISO),
      dosage: '1',
    },
  ],
};

const _MedicationDrawer = ({
  userData,
  onClose,
  onUpdated,
  onTimetable,
}: MedicationDrawerProps) => {
  const { t } = useTranslation();
  const [medication, setMedication] = useState(defaultMedication);
  const [select, setSelect] = useState<SelectedMedicine>();
  const [medicines, setMedicines] = useState<MedicineDTO[]>([]);
  const [timetableOpen, setTimetableOpen] = useState(false);
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const [temp, setTemp] = useState('');
  const [infinite, setInfinite] = useState(
    medication.duration === 10000 ? true : false
  );
  const inputEl = useRef<any | null>();
  const { me, openT } = useTutorialContext();
  const [as, setAs] = useState(false);
  const classes = useStyles();
  const handleInfinite = () => {
    if (!infinite) {
      setMedication({ ...medication, duration: 10000 });
    } else {
      setMedication({ ...medication, duration: 7 });
    }
    setInfinite(!infinite);
  };
  const handleDrawer = (state: boolean) => {
    if (state === false) {
      setTimetableOpen(false);
      onClose();
    }
  };

  const handleSelectChange = (newVal: ValueType<SelectedMedicine, false>) => {
    if (newVal) {
      setSelect(newVal as SelectedMedicine);
      const med = medicines.find(
        (item) => item.id === (newVal as SelectedMedicine).value
      ) as MedicineDTO;
      setMedication({
        ...medication,
        medicine: med,
        color: med.color,
        shape: med.shape,
      });
    }
  };
  const clear = () => {
    setMedication(defaultMedication);
    setSelect(undefined);
  };
  useEffect(() => {
    setMedication(userData ? userData : defaultMedication);
    setSelect(
      userData && userData.medicine
        ? {
            value: userData?.medicine?.id,
            label: userData?.medicine?.name,
          }
        : undefined
    );
    if (userData && userData.duration === 10000) setInfinite(true);
  }, [userData]);

  useEffect(() => {
    if (openT === '17') setTemp('17');
    else setTemp('');
    if (openT === '17') {
      if (!isUndefined(onTimetable)) onTimetable();
      setTimetableOpen(true);
    }
    if (openT === '16') {
      if (!isUndefined(onTimetable) && temp === '17') onTimetable();
      setTimetableOpen(false);
    }
    if (openT === '15') onClose();
  }, [openT]);

  useEffect(() => {
    if (!isUndefined(inputEl.current)) inputEl.current.focus();
  }, [inputEl.current]);

  return (
    <Container style={{ display: 'flex', flexDirection: 'column' }}>
      <Box className={classes.drawer}>
        <Box style={{ padding: '0 5%' }}>
          <Box
            style={{
              padding: '8px 16px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography variant={isBelowSm ? 'subtitle1' : 'h4'}>
              {userData ? t('EditMedication') : t('AddMedication')}
            </Typography>
            <IconButton
              size={isBelowSm ? 'small' : 'medium'}
              onClick={() => handleDrawer(false)}
            >
              <Clear fontSize={isBelowSm ? 'small' : 'default'} />
            </IconButton>
          </Box>
          <Box style={{ padding: '8px 16px' }}>
            <AsyncSelect
              {...AutoCompleteStyle}
              loadOptions={(inputValue, callback) => {
                if (inputValue.length >= 2) {
                  try {
                    medicationControllerGetMedicines(inputValue).then(
                      (data) => {
                        setMedicines(data.data);
                        callback(
                          data.data.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))
                        );
                      }
                    );
                  } catch (e) {}
                }
              }}
              value={select}
              onChange={handleSelectChange}
              placeholder={
                isBelowSm
                  ? `${t('Search')}...`
                  : `${t('Search')} ${t('OfMedication')}...`
              }
            />
          </Box>
          <Box style={{ padding: '8px 16px' }}>
            <StyledDateTimePicker
              fullWidth
              value={moment(medication.startDate)}
              onChange={(date) => {
                const mdate = date as Moment;
                medication.timetable &&
                  setMedication({
                    ...medication,
                    startDate: moment(mdate).format(DateFormats.ISO),
                    timetable: [
                      {
                        ...medication.timetable[0],
                        time: moment(mdate).format(DateFormats.ISO),
                      },
                    ],
                  });
              }}
              label={t('StartDate')}
              showTodayButton
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodaySharp />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {medication.shape && (
            <Box style={{ padding: '8px 16px' }}>
              <MedicineShapePicker
                onChange={(shape) => setMedication({ ...medication, shape })}
                selected={medication.shape}
              />
            </Box>
          )}
          {medication.timetable && (
            <Box
              style={{
                fontSize: isBelowSm ? 12 : 'inherit',
                padding: '8px 16px',
              }}
            >
              <Box className={classes.greyBox}>
                <Box style={{ padding: '8px 16px' }}>
                  <Typography color="primary">{t('FreqAndDosage')}</Typography>
                </Box>
                <Box style={{ padding: '8px 16px' }}>
                  <Box display="flex" flex="1 1 auto">
                    <Box display="flex" flexGrow={2}>
                      {t('Quantity')}
                    </Box>
                    <Box display="flex" flexGrow={1} justifyContent="flex-end">
                      {!medication.repeatedNormally || timetableOpen ? (
                        <Typography style={{ cursor: 'pointer' }}>
                          {t('TimetableLabel')}...
                        </Typography>
                      ) : (
                        <Select
                          value={medication.timetable[0].dosage}
                          style={{ fontSize: isBelowSm ? 12 : 'inherit' }}
                          onChange={(e) =>
                            medication.timetable &&
                            setMedication({
                              ...medication,
                              timetable: [
                                {
                                  ...medication.timetable[0],
                                  dosage: e.target.value as string,
                                },
                              ],
                            })
                          }
                        >
                          {dosage.map((item, i) => (
                            <MenuItem key={i} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ padding: '8px 16px' }}>
                  <Box display="flex" flex="1 1 auto">
                    <Box display="flex" flexGrow={2}>
                      {t('FreqEvery')}
                    </Box>
                    <Box display="flex" flexGrow={1} justifyContent="flex-end">
                      {!medication.repeatedNormally || timetableOpen ? (
                        <Typography style={{ cursor: 'pointer' }}>
                          {t('TimetableLabel')}...
                        </Typography>
                      ) : (
                        <StyledNumberPicker
                          min={1}
                          unit={t('Hours')}
                          value={medication.timetable[0].frequency}
                          onChange={(value) =>
                            medication.timetable &&
                            setMedication({
                              ...medication,
                              timetable: [
                                {
                                  ...medication.timetable[0],
                                  frequency: value >= 1 ? value : 0,
                                },
                              ],
                            })
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box style={{ padding: '8px 16px' }}>
                  <Box display="flex" flex="1 1 auto">
                    <Box display="flex" flexGrow={1}>
                      {t('TimetableLabel')}
                    </Box>
                    <Box>
                      <Button
                        variant={
                          medication.repeatedNormally ? 'outlined' : 'contained'
                        }
                        size={isBelowSm ? 'small' : 'medium'}
                        style={{ fontSize: isBelowSm ? 12 : 'inherit' }}
                        color="primary"
                        ref={inputEl}
                        onFocus={() => setAs(true)}
                        onClick={() => {
                          // setRepeatedNormally(!medication.repeatedNormally);
                          setTimetableOpen(!timetableOpen);
                          if (!isUndefined(onTimetable)) onTimetable();
                        }}
                      >
                        {t('TimetableLabel')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {medication.color &&
            ![3, 12, 34, 21, 10, 13, 15, 11].includes(
              medication?.shape?.id ?? 0
            ) && (
              <Box style={{ padding: '8px 16px' }}>
                <MedicineColorPicker
                  onChange={(color) => setMedication({ ...medication, color })}
                  selected={medication.color}
                />
              </Box>
            )}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '8px 16px',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  size={isBelowSm ? 'small' : 'medium'}
                  checked={infinite}
                  onChange={handleInfinite}
                  color="primary"
                />
              }
              label={t('ForLife')}
            />
            {medication.duration && !infinite && (
              <Box className={classes.greyBox} width="100%">
                <Box style={{ padding: '8px 16px' }}>
                  <Box display="flex" flex="1 1 auto" alignItems="center">
                    <Box
                      display="flex"
                      flexGrow={2}
                      style={{ fontSize: isBelowSm ? 12 : 'inherit' }}
                    >
                      {t('Duration')}
                    </Box>
                    <StyledNumberPicker
                      value={medication.duration}
                      onChange={(value) =>
                        setMedication({
                          ...medication,
                          duration: value > 0 ? value : 1,
                        })
                      }
                      unit={t('Days')}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          {isNullOrUndefined(userData) ? (
            <Box
              style={{
                display: 'flex',
                flex: '1 1 auto',
                justifyContent: 'center',
                marginBottom: 10,
              }}
            >
              <Button
                startIcon={<Add />}
                color="primary"
                size={isBelowSm ? 'small' : 'large'}
                fullWidth={!isBelowSm}
                style={{
                  color: 'white',
                  borderRadius: 0,
                  marginTop: 5,
                  fontSize: isBelowSm ? 14 : '18',
                }}
                variant="contained"
                disabled={isNullOrUndefined(medication.medicine)}
                onClick={async () => {
                  try {
                    medication.medicine &&
                      (await medicationControllerCreateMyMedication({
                        ...medication,
                        startDate: moment(medication.startDate).format(
                          DateFormats.ISO
                        ),
                      } as CreateMedication)
                        .then((resp) => {
                          if (resp) {
                            toast(t('MedicationAdded'));
                          } else {
                            toast(t('Failed'), { containerId: 'deleted' });
                          }
                          handleDrawer(false);
                          onUpdated();
                        })
                        .finally(() => clear()));
                  } catch (e) {}
                }}
              >
                {t('AddMedication')}
              </Button>
            </Box>
          ) : (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 10,
              }}
            >
              <Button
                color="primary"
                size={isBelowSm ? 'small' : 'large'}
                fullWidth={!isBelowSm}
                style={{
                  color: 'white',
                  borderRadius: 0,
                  marginTop: 5,
                  fontSize: isBelowSm ? 14 : '18',
                }}
                variant="contained"
                disabled={isNullOrUndefined(medication.medicine)}
                startIcon={<Add />}
                onClick={async () => {
                  try {
                    medication.medicine &&
                      medication.id &&
                      medicationControllerUpdateMyMedication(medication.id, {
                        ...medication,
                        startDate: moment(medication.startDate).format(
                          DateFormats.ISO
                        ),
                      } as UpdateMedication)
                        .then(() => {
                          toast(t('MedicationUpdated'));
                          handleDrawer(false);
                          onUpdated();
                        })
                        .finally(() => clear());
                  } catch (e) {}
                }}
              >
                {t('EditMedication')}
              </Button>
            </Box>
          )}
        </Box>
        {timetableOpen && (
          <Box className={isBelowSm ? classes.timetableSmall : ''}>
            <TimetableProvider>
              <MedicationTimetable
                medication={medication}
                onSave={(rows) => {
                  setMedication({
                    ...medication,
                    repeatedNormally: false,
                    timetable: rows,
                  });
                  setTimetableOpen(false);
                }}
                onCancel={() => {
                  setMedication({
                    ...medication,
                    repeatedNormally: true,
                    timetable: [
                      {
                        time: moment().format(DateFormats.ISO),
                        dosage: '1',
                        frequency: 6,
                      },
                    ],
                  });
                  setTimetableOpen(false);
                }}
              />
            </TimetableProvider>
          </Box>
        )}
      </Box>
      <Popover
        open={!me?.tutorial && (openT === '16' || openT === '17')}
        anchorOrigin={{
          horizontal: isBelowMd ? 'left' : 'center',
          vertical: 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            WebkitTapHighlightColor: 'transparent',
            boxShadow: 'initial',
            marginLeft: isBelowMd ? '2%' : '-30%',
            marginTop: '-8%',
            width: '33vw',
            textAlign: 'center',
          },
        }}
      >
        <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
          {openT === '16' ? t('Tut25') : openT === '17' && ''}
        </Typography>
      </Popover>
      <Popover
        open={
          !me?.tutorial &&
          (openT === '16' || (openT === '17' && !isBelowMd)) &&
          as === true
        }
        anchorEl={inputEl.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            WebkitTapHighlightColor: 'transparent',
            boxShadow: 'initial',
            marginLeft: openT === '16' ? -100 : -50,
          },
        }}
      >
        <img alt="" src={Cursor} style={{ width: 42 }} />
        {me?.tutorial === false && <Demo />}
      </Popover>
      {openT === '17' && isBelowMd && <Demo />}
    </Container>
  );
};
const useStyles = makeStyles(() => ({
  greyBox: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    border: '1px solid rgba(38, 50, 56, 0.12)',
    backgroundColor: Colors.IceWhite,
    borderRadius: 2,
    color: Colors.FadeBlack,
    fontWeight: 'bold',
  },
  drawer: {
    display: 'flex',
    overflowY: 'auto',
    maxWidth: 730,
  },
  timetableSmall: {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    background: Colors.White,
    width: '100vw',
    height: '100%',
  },
}));
export const MedicationDrawer = hot(_MedicationDrawer);
