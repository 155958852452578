import React, { useState, useEffect, Fragment } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  Button,
  Checkbox,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Colors, DateFormats } from 'res/Enums';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import { StyledDatePicker } from 'components/DatePicker';
import {
  DurationDTO,
  PredictionDTO,
  useCycleControllerCreateMyCycle,
  useCycleControllerGetCyclePrediction,
  useCycleControllerGetMyCycleDuration,
  useCycleControllerSetMyDuration,
} from 'gen/client';

interface Prediction {
  predictionInfo?: PredictionDTO;
  onClick?: () => PredictionDTO;
  onClose?: () => void;
}

export const PredictionBox = ({
  predictionInfo,
  onClick,
  onClose,
}: Prediction) => {
  const [time, setTime] = useState<Moment | undefined>(undefined);
  const [duration, setDuration] = useState('');
  const [prediction, setPrediction] = useState<PredictionDTO>();
  const [tick, setTick] = useState(false);
  const { t } = useTranslation();
  const [cycleDuration, setCycleDuration] = useState<DurationDTO>();
  const [error, setError] = useState(false);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { data: durationData, refetch: durationRefetch } =
    useCycleControllerGetMyCycleDuration();
  const predictionPromise = useCycleControllerGetCyclePrediction();
  const setMutation = useCycleControllerSetMyDuration();
  const createMutation = useCycleControllerCreateMyCycle();

  useEffect(() => {
    if (durationData) {
      setCycleDuration(durationData.data);
      if (durationData.data.userGenerated === false) setTick(true);
    }
  }, [durationData]);

  const getPrediction = async (temp?: boolean) => {
    try {
      if (cycleDuration) {
        const data = await predictionPromise.mutateAsync({
          data: { temp: temp },
        });
        setPrediction(data.data);
      } else {
        await predictionPromise.mutateAsync({
          data: {
            prediction: { userGenerated: tick, averageDuration: 28 },
          },
        });
        durationRefetch();
      }
    } catch (e) {}
  };

  const addCycleDuration = async () => {
    try {
      const resp = await setMutation.mutateAsync({
        data: {
          userGenerated: !tick,
          averageDuration: parseInt(duration),
        },
      });
      if (resp) {
        toast(t('Success'));
        onClose && onClose();
      } else toast('Fail', { containerId: 'deleted' });
    } catch (e) {
      toast(t('Error'), { containerId: 'deleted' });
    }
  };

  const addCycle = async () => {
    try {
      const resp = await createMutation.mutateAsync({
        data: {
          startDate: time
            ? time.format(DateFormats.ISO)
            : moment().format(DateFormats.ISO),
        },
      });
      if (resp) {
        toast(t('CycleAdded'));
        onClose && onClose();
      } else {
        toast(t('FailedAdding'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('FailedAdding'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (predictionInfo) {
      setDuration(predictionInfo.result.avg.toString());
    }
    durationRefetch();
  }, []);

  useEffect(() => {
    if (cycleDuration) getPrediction();
  }, [cycleDuration]);

  useEffect(() => {
    if (prediction) setDuration(prediction?.result.avg.toString());
  }, [prediction]);

  useEffect(() => {
    if (parseInt(duration) < 15) setError(true);
    if (error === true && parseInt(duration) >= 15) setError(false);
  }, [duration]);

  return (
    <Box
      style={{
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 6,
        backgroundColor: Colors.IceWhite,
      }}
    >
      <Box
        style={{
          display: 'flex',
          paddingLeft: isBelowXs ? 4 : 16,
          paddingTop: isBelowXs ? 4 : 16,
        }}
      >
        <Box style={{ display: 'flex', flexGrow: 1 }}>
          <Typography
            style={{
              fontSize: isBelowXs ? 20 : 22,
              fontWeight: 'bold',
              color: Colors.Black,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {!predictionInfo ? t('PAsk1') : t('PAsk2')}
          </Typography>
        </Box>
        {predictionInfo && (
          <Box>
            <IconButton
              style={!isBelowXs ? { marginLeft: 16 } : {}}
              onClick={onClose}
            >
              <CloseIcon
                style={
                  !isBelowXs ? { fontSize: 40 } : { fontSize: 30, padding: 0 }
                }
              />
            </IconButton>
          </Box>
        )}
      </Box>
      <Grid container>
        {!predictionInfo && (
          <Fragment>
            <Grid item xs={5} sm={8}>
              <Typography
                style={{
                  padding: isBelowXs ? '10px 0px 0px 4px' : '24px 24px 0% 24px',
                  color: Colors.Black,
                  fontSize: isBelowXs ? 14 : 16,
                  fontWeight: 'bold',
                }}
              >
                {t('PQuestion1')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              sm={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StyledDatePicker
                autoOk
                autoFocus
                style={{
                  paddingRight: isBelowXs ? 4 : 24,
                  width: '100%',
                  marginLeft: isBelowXs ? 4 : 0,
                }}
                inputProps={{ style: { fontSize: 16 } }}
                value={time}
                margin="normal"
                format={DateFormats.GR}
                onChange={(date) => setTime(date as Moment)}
                animateYearScrolling
                disableFuture
              />
            </Grid>
          </Fragment>
        )}
        <Grid
          container
          style={{
            margin: isBelowXs ? '16px 0px 0px 0px' : '16px 24px 0px 24px',
            backgroundColor: Colors.White,
            border: '1px solid rgba(160, 140, 255, 0.34)',
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid item xs={3} sm={5}>
            <Typography
              style={{
                paddingLeft: isBelowXs ? 4 : 16,
                fontSize: isBelowXs ? 12 : 16,
                fontWeight: 'bold',
                color: Colors.Black,
                textAlign: 'center',
              }}
            >
              {t('PQuestion2')}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              style={{
                width: '100%',
                padding:
                  error === false
                    ? isBelowXs
                      ? ''
                      : '16px 4px 16px 0px'
                    : '16px 16px 8px 0px',
              }}
              inputProps={{
                style:
                  tick === true
                    ? {
                        color: Colors.Black,
                        fontWeight: 'bold',
                        fontSize: 18,
                      }
                    : {},
              }}
              value={duration}
              error={error}
              helperText={error && t('CycleError')}
              FormHelperTextProps={{
                style: {
                  fontSize: 12,
                  width: '170%',
                  position: 'relative',
                  left: '-33%',
                  top: 2,
                },
              }}
              disabled={tick === true}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      color: 'rgba(0, 0, 0, 0.34)',
                      fontSize: isBelowXs ? 14 : 16,
                    }}
                    disableTypography
                  >
                    {t('Days')}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setDuration(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={5}
            sm={4}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={tick}
              color="primary"
              style={{ padding: isBelowXs ? '9px 2px' : '9px 9px 9px 20px' }}
              size={isBelowXs ? 'small' : 'medium'}
              onChange={(e) => {
                if (e.target.checked) {
                  if (predictionInfo && cycleDuration?.userGenerated === true)
                    getPrediction(true);
                  else getPrediction();
                  setTick(true);
                } else {
                  setTick(false);
                }
              }}
            />
            <Typography
              style={{
                fontSize: isBelowXs ? 13 : 14,
                color: Colors.Black,
                textAlign: 'center',
              }}
            >
              {t('AutoC')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 50, marginBottom: 24 }}>
          <Box
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              style={{
                backgroundColor: (
                  !predictionInfo
                    ? time === null ||
                      (tick === false ? duration === '' : !prediction)
                    : tick !== true && duration === ''
                )
                  ? 'rgba(0, 0, 0, 0.12)'
                  : '#BB6BD9',
                width: '92%',
                margin: '0px 24px',
                color: (
                  !predictionInfo
                    ? time === null ||
                      (tick === false ? duration === '' : !prediction)
                    : tick !== true && duration === ''
                )
                  ? 'rgba(0, 0, 0, 0.26)'
                  : '#FFFFFF',
                fontSize: 16,
                fontWeight: 'bold',
              }}
              onClick={() => {
                if (!predictionInfo) addCycle();
                addCycleDuration();
              }}
              disabled={
                !predictionInfo
                  ? time === null ||
                    (tick === false ? duration === '' : !prediction) ||
                    error
                  : (tick !== true && duration === '') || error
              }
            >
              {!predictionInfo ? t('CycleSubmit') : t('SaveChanges')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
