import {
  Box,
  createStyles,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { UserFeedbackDTO } from 'gen/client';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, DateFormats } from 'res/Enums';

interface LatestQuestionProps {
  latest: UserFeedbackDTO;
  style?: CSSProperties;
}
export const ShortInfo = ({ latest }: LatestQuestionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className={classes.latestInfo}>
      <Box
        className={clsx([classes.column, classes.valueBox])}
        style={isBelowXs ? { padding: 0 } : {}}
      >
        <Box
          style={{
            fontSize: isBelowXs ? 26 : 34,
            fontWeight: 'bold',
            minWidth: isBelowXs ? 60 : 80,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {latest.score}
        </Box>
      </Box>
      <Box
        className={classes.column}
        justifyContent="space-evenly"
        style={isBelowXs ? { paddingLeft: 6 } : {}}
        paddingLeft={2}
      >
        <Box style={{ fontSize: isBelowXs ? 16 : 17 }}>
          {latest.question.text}
        </Box>
        <Box style={{ fontSize: 14, fontWeight: 'lighter', color: 'gray' }}>
          {t('MeasurementAt')}
        </Box>
        <Box style={{ fontSize: isBelowXs ? 16 : 18, color: Colors.FadeBlack }}>
          {moment(latest.date).format(DateFormats.GR)}
        </Box>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    padd: {
      padding: theme.spacing(2),
      border: '1px solid #E3DFFA',
    },
    valueBox: {
      alignItems: 'center',
      padding: theme.spacing(3),
      borderRight: '2px solid #F5F6F7',
      color: Colors.FadeBlack,
      justifyContent: 'center',
    },
    latestInfo: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      display: 'flex',
      flexGrow: 1,
    },
    addButton: {
      backgroundColor: '#F5F6F7',
      border: ' 1px solid rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      borderRadius: 90,
      margin: '0px 10px',
      float: 'right',
      width: 'auto',
    },
    pressBox: {
      alignItems: 'center',
      color: Colors.FadeBlack,
      fontSize: 24,
      fontWeight: 'bold',
      margin: theme.spacing(1),
      textAlign: 'center',
    },
  })
);
