import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  makeStyles,
  ExpansionPanelDetails,
  ExpansionPanel,
  ExpansionPanelSummary,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Add, ExpandMore } from '@material-ui/icons';
import { CycleBox } from 'components/Cycle/CycleBox';
import moment from 'moment';
import React, {
  useEffect,
  useReducer,
  useState,
  Fragment,
  useRef,
} from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DateFormats, Colors } from 'res/Enums';
import { PredictionBox } from 'components/Cycle/PredictionBox';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DeleteIcon from '@material-ui/icons/Delete';
import { useAppContext } from 'context/AppContext';
import { CycleScheduler } from 'components/CycleScheduler/CycleScheduler';
import { CycleDot } from 'components/Cycle/CycleDot';
import {
  cycleControllerGetMyCycleDuration,
  CycleDTO,
  DurationDTO,
  PredictionDTO,
  useCycleControllerGetCycle,
  useCycleControllerGetCyclePrediction,
  useCycleControllerGetMyCycleDuration,
  useCycleControllerSetMyDuration,
  useCycleControllerDeleteMyCycle,
  cycleControllerGetCycle,
} from 'gen/client';

interface EditState {
  cycle?: CycleDTO;
  forEnd: boolean;
  add: boolean;
}
const _Cycle = () => {
  const [prediction, setPrediction] = useState<PredictionDTO>();
  const [cycles, setCycles] = useState<CycleDTO[]>([]);
  const [month, setMonth] = useState(moment());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const [cycleDuration, setCycleDuration] = useState<DurationDTO>();
  const [edit, setEdit] = useReducer(
    (state: EditState, action: EditState) => {
      return { cycle: action.cycle, forEnd: action.forEnd, add: action.add };
    },
    { cycle: undefined, forEnd: false, add: false }
  );
  const classes = useStyles();
  const { t } = useTranslation();
  const [day, setDay] = useState('');
  const [nextPeriod, setNextPeriod] = useState('');
  const [duration, setDuration] = useState<number>();
  const firstUpdate = useRef(0);
  const { lang } = useAppContext();
  const { data: durationData, refetch: durationRefetch } =
    useCycleControllerGetMyCycleDuration();
  const { data: cycleData, refetch: cycleRefetch } =
    useCycleControllerGetCycle();
  const deleteMutation = useCycleControllerDeleteMyCycle();
  const predictionPromise = useCycleControllerGetCyclePrediction();
  const setMutation = useCycleControllerSetMyDuration();

  useEffect(() => {
    if (durationData) setCycleDuration(durationData.data);
  }, [durationData]);

  useEffect(() => {
    if (cycleData) setCycles(cycleData.data);
  }, [cycleData]);

  const getPrediction = () =>
    predictionPromise
      .mutateAsync({ data: {} })
      .then((data) => setPrediction(data.data))
      .catch();

  const getAll = () => {
    const getDurationPromise = cycleControllerGetMyCycleDuration();
    const getCyclePromise = cycleControllerGetCycle();
    Promise.all([getDurationPromise, getCyclePromise]).then((values) => {
      setCycleDuration(values[0].data);
      setCycles(values[1].data);
      if (values[1].data.length === 0) setLoading(false);
    });
  };

  const handleAdd = () => {
    setDialogOpen(true);
    setEdit({ forEnd: false, add: false });
  };

  const handleAddEnd = (cycle: CycleDTO) => {
    setEdit({ cycle, forEnd: true, add: true });
    setDialogOpen(true);
  };

  const handleEditButton = (cycle: CycleDTO) => {
    setDialogOpen(true);
    setEdit(
      cycle.endDate === null
        ? { cycle, forEnd: false, add: false }
        : { cycle, forEnd: true, add: false }
    );
  };

  const deleteCycle = async (cycle: CycleDTO) => {
    try {
      const resp = await deleteMutation.mutateAsync({ id: cycle.id });
      if (resp) {
        toast(t('CycleDeleted'));
        cycleRefetch();
      } else {
        toast(t('DeleteFailed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('DeleteFailed'), { containerId: 'deleted' });
    }
  };

  const addCycleDuration = async () => {
    try {
      if (duration) {
        const resp = await setMutation.mutateAsync({
          data: {
            userGenerated: false,
            averageDuration: duration,
          },
        });
        if (resp) {
          toast(t('Success'));
          durationRefetch();
        } else toast('Failed', { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Error'), { containerId: 'deleted' });
    }
  };

  const handleDay = () => {
    const day = moment().startOf('day');
    const cycle = cycles[cycles.length - 1];
    let today = 0;
    let period = 0;
    if (prediction && cycles.length > 0) {
      if (cycles) {
        if (
          day.isBetween(
            moment(cycle.startDate).subtract(1, 'day'),
            moment(cycle.startDate).add(prediction.result.avg * 2 + 1, 'days')
          )
        ) {
          today = day.dayOfYear() - moment(cycle.startDate).dayOfYear() + 1;
          setDay(today.toString());
        } else setDay('-');
        if (
          day.isBetween(
            moment(cycle.startDate).subtract(1, 'day'),
            moment(cycle.startDate).add(prediction.result.avg + 1, 'days')
          )
        ) {
          period =
            moment(prediction.result.nextDates[0]).dayOfYear() -
            day.dayOfYear();
          setNextPeriod(period.toString());
        } else setNextPeriod('-');
      }
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (cycleDuration) getPrediction();
  }, [cycleDuration]);

  useEffect(() => {
    if (prediction) {
      handleDay();
      if (duration !== prediction.result.avg)
        setDuration(prediction.result.avg);
      setLoading(false);
    }
  }, [prediction]);

  useEffect(() => {
    if (
      duration &&
      cycleDuration?.userGenerated === false &&
      firstUpdate.current > 1
    ) {
      addCycleDuration();
    } else firstUpdate.current++;
  }, [duration]);

  useEffect(() => {
    if (cycleDuration) getPrediction();
  }, [cycles]);

  useEffect(() => {
    setMonth(moment(month).locale(lang === 'el-gr' ? lang : 'en-gb'));
  }, [lang]);

  if (loading) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
  return (
    <Fragment>
      {cycles.length > 0 && prediction ? (
        <Box style={{ marginTop: 50, width: 'fit-content' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography className={classes.generalBox}>
                  {t('General')}
                </Typography>
                <Box className={classes.box}>
                  <Typography
                    style={{
                      flexGrow: 1,
                      fontSize: 16,
                      color: Colors.Black,
                      paddingLeft: 8,
                    }}
                  >
                    {t('CycleDay')}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: Colors.Black,
                    }}
                  >
                    {day}
                  </Typography>
                </Box>
                <Box style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                <Box className={classes.box}>
                  <Typography
                    style={{
                      flexGrow: 1,
                      fontSize: 16,
                      color: Colors.Black,
                      paddingLeft: 8,
                    }}
                  >
                    {t('PeriodDays')}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: Colors.Black,
                    }}
                  >
                    {nextPeriod}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Paper style={{ display: 'flex', flexDirection: 'column' }}>
                <Box style={{ display: 'flex' }}>
                  <Typography
                    className={classes.generalBox}
                    style={{ flexGrow: 1 }}
                  >
                    {t('YourCycle')}
                  </Typography>
                  <IconButton
                    onClick={() => setOpen(true)}
                    style={{
                      padding: 0,
                      marginRight: 24,
                    }}
                  >
                    <EditIcon style={{ fontSize: isBelowXs ? 20 : 26 }} />
                  </IconButton>
                  <Dialog open={open === true} onClose={() => setOpen(false)}>
                    <PredictionBox
                      predictionInfo={prediction}
                      onClose={() => {
                        setOpen(false);
                        durationRefetch();
                      }}
                    />
                  </Dialog>
                </Box>
                <Box className={classes.box}>
                  <Typography
                    style={{
                      fontSize: 16,
                      color: Colors.Black,
                      paddingLeft: 8,
                    }}
                  >
                    {t('CycleDuration')}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: Colors.Black,
                      display: 'flex',
                      flexGrow: 1,
                      justifyContent: 'center',
                    }}
                  >
                    {prediction?.result.avg ?? '-'} {t('Days')}
                  </Typography>
                </Box>
                <Box style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                <Box className={classes.box}>
                  <Typography
                    style={{
                      fontSize: 16,
                      color: Colors.Black,
                      paddingLeft: 8,
                    }}
                  >
                    {t('PeriodDuration')}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: Colors.Black,
                      display: 'flex',
                      flexGrow: 1,
                      justifyContent: 'center',
                    }}
                  >
                    {prediction?.result.averageDuration ?? '-'} {t('Days')}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Paper style={{ marginTop: 50, width: 'fit-content' }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box style={{ display: 'flex' }}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: isBelowXs ? 10 : 24,
                    color: Colors.Black,
                    flexGrow: 1,
                  }}
                >
                  {t('CycleMonitor')}
                </Typography>
                <IconButton
                  onClick={handleAdd}
                  style={{ fontSize: 28, marginRight: 24 }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
              {isBelowMd && <CycleDot />}
              <Box
                style={{
                  width: 'fit-content',
                  display: 'flex',
                }}
              >
                <Box
                  style={{
                    paddingTop: 16,
                    display: 'flex',
                    border: '1px solid #E3DFFA',
                    borderRadius: 6,
                    flexDirection: 'column',
                    width: isBelowMd ? '100%' : '85%',
                    marginLeft: isBelowMd ? 0 : 24,
                    marginBottom: 40,
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button
                      startIcon={
                        <ArrowBackIosIcon
                          style={{
                            marginLeft: 8,
                          }}
                        />
                      }
                      style={{
                        marginLeft: 16,
                        backgroundColor: '#F2F2F2',
                        color: Colors.Black,
                        fontSize: isBelowXs ? 13 : 16,
                      }}
                      onClick={() =>
                        setMonth(month.clone().subtract(1, 'month'))
                      }
                    >
                      {t('CycleMonths1')}
                    </Button>
                    <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
                      {month.format('MMMM')}
                    </Typography>
                    <Button
                      endIcon={
                        <ArrowForwardIosIcon style={{ marginRight: 8 }} />
                      }
                      style={{
                        marginRight: 16,
                        backgroundColor: '#F2F2F2',
                        color: Colors.Black,
                        fontSize: isBelowXs ? 13 : 16,
                      }}
                      onClick={() => setMonth(month.clone().add(1, 'month'))}
                    >
                      {t('CycleMonths2')}
                    </Button>
                  </Box>
                  <Box
                    style={{
                      border: '1px solid #000000',
                      margin: '24px 32px 0px 32px',
                    }}
                  />
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CycleScheduler
                      currentDate={month}
                      cycles={cycles}
                      prediction={prediction}
                    />
                  </Box>
                </Box>
                {!isBelowMd && <CycleDot />}
              </Box>
            </Box>
          </Paper>
          <TableContainer component={Paper} style={{ marginTop: 30 }}>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography>{t('CycleHistory')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
              >
                <Table>
                  <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                    <TableRow>
                      <TableCell style={{ color: '#FFFFFF' }}>
                        {t('StartDate')}
                      </TableCell>
                      <TableCell style={{ color: '#FFFFFF' }}>
                        {t('EndDate')}
                      </TableCell>
                      <TableCell padding="none" />
                      <TableCell padding="none" />
                      <TableCell padding="none" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cycles
                      .map((cycle, i) => (
                        <TableRow key={i}>
                          <TableCell component="td" scope="row">
                            {moment(cycle.startDate).format(
                              DateFormats.GrNoTime
                            )}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {cycle.endDate !== null ? (
                              moment(cycle.endDate).format(DateFormats.GrNoTime)
                            ) : (
                              <Button
                                size="small"
                                variant="outlined"
                                startIcon={<Add />}
                                onClick={() => handleAddEnd(cycle)}
                              >
                                {t('Add')}
                              </Button>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            style={isBelowXs ? { padding: '0px 8px' } : {}}
                          >
                            <EditIcon
                              style={{
                                color: 'rgba(0, 0, 0, 0.58)',
                                fontSize: 18,
                                cursor: 'pointer',
                              }}
                              onClick={() => handleEditButton(cycle)}
                            />
                          </TableCell>
                          <TableCell align="center" padding="none">
                            <Box
                              style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.34)',
                                width: 2,
                                height: 40,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            style={isBelowXs ? { padding: '0px 8px' } : {}}
                          >
                            <DeleteIcon
                              style={{
                                color: 'rgba(0, 0, 0, 0.58)',
                                fontSize: 18,
                                cursor: 'pointer',
                              }}
                              onClick={() => deleteCycle(cycle)}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                      .reverse()}
                  </TableBody>
                </Table>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </TableContainer>
          <Dialog
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
              setEdit({ forEnd: false, add: false });
            }}
          >
            <DialogContent>
              <CycleBox
                cycle={edit.cycle}
                end={edit.forEnd}
                add={edit.add}
                onCancel={() => setDialogOpen(false)}
                onRefresh={() => {
                  cycleRefetch();
                  setDialogOpen(false);
                }}
              />
            </DialogContent>
          </Dialog>
        </Box>
      ) : (
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: isBelowXs ? 24 : 100,
          }}
        >
          <Box style={{ width: isBelowXs ? '95%' : '60%' }}>
            <PredictionBox
              onClose={() => {
                cycleRefetch();
                getPrediction();
              }}
            />
          </Box>
        </Box>
      )}
    </Fragment>
  );
};

interface circleProps {
  color: string;
  userColor?: string;
}
export const Circle = ({ color, userColor }: circleProps) => {
  const classes = useStyles();
  return (
    <div
      className={classes.circle}
      style={{ backgroundColor: userColor ?? color }}
    />
  );
};

const useStyles = makeStyles(() => ({
  generalBox: {
    color: Colors.Black,
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 8,
    paddingLeft: 8,
  },
  box: {
    display: 'flex',
    marginTop: 8,
    padding: '0px 8px 8px 8px',
  },
  circle: {
    width: 24,
    height: 24,
    borderRadius: 24,
  },
}));
export const Cycle = hot(_Cycle);
