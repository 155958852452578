import { Box, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  Feedback1,
  Feedback10,
  Feedback2,
  Feedback3,
  Feedback4,
  Feedback5,
  Feedback6,
  Feedback7,
  Feedback8,
  Feedback9,
} from 'assets/icons/FeedbackSVG';
import React, { useEffect, useState } from 'react';
interface FeedbackRatingProps {
  label: string;
  questionId: 1 | 2;
  onChange: (index: number) => void;
}
export const FeedbackRating = ({
  onChange,
  label,
  questionId,
}: FeedbackRatingProps) => {
  const classes = useStyles();
  const [icons, setIcons] = useState([
    <Feedback1 key={0} />,
    <Feedback2 key={1} />,
    <Feedback3 key={2} />,
    <Feedback4 key={3} />,
    <Feedback5 key={4} />,
  ]);
  useEffect(() => {
    if (questionId === 2) {
      setIcons([
        <Feedback6 key={0} />,
        <Feedback7 key={1} />,
        <Feedback8 key={2} />,
        <Feedback9 key={3} />,
        <Feedback10 key={4} />,
      ]);
    }
  }, []);
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        padding: '10px 3px 10px 3px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        style={{ fontSize: 18, fontWeight: 'bold', textAlign: 'center' }}
      >
        {label}
      </Typography>
      <Box display="flex">
        {icons.map((icon, i) => (
          <Box onClick={() => onChange(i + 1)} className={classes.icon} key={i}>
            {icon}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      cursor: 'pointer',
    },
  })
);
