import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@material-ui/core';
import React, {
  createContext,
  Fragment,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';

type ConfirmContext = (
  confirmOptions?: Partial<ConfirmOptions>,
  dialogProps?: DialogProps
) => Promise<unknown>;
interface ConfirmOptions {
  title: string;
  description: string;
  confirmationText: string;
  cancellationText: string;
}
const ConfirmContext = createContext<ConfirmContext>(
  (confirmOptions?: Partial<ConfirmOptions>, dialogProps?: DialogProps) =>
    new Promise((resolve, reject) => {})
);
const _defaultOptions: ConfirmOptions = {
  title: 'Are you sure?',
  description: '',
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
};
const _ConfirmDialogProvider = ({ children }: PropsWithChildren<{}>) => {
  const [resolveReject, setResolveReject] = useState<(() => void)[]>([]);
  const [resolve, reject] = resolveReject;
  const { t } = useTranslation();
  const [dialogProps, setDialogProps] = useState<DialogProps>();
  const [confirmOptions, setConfirmOptions] =
    useState<Partial<ConfirmOptions>>(_defaultOptions);
  const confirm = useCallback(
    (
      confirmOptions: Partial<ConfirmOptions> = _defaultOptions,
      dialogProps?: DialogProps
    ) => {
      return new Promise((resolve, reject) => {
        setDialogProps(dialogProps);
        setConfirmOptions({ ..._defaultOptions, ...confirmOptions });
        setResolveReject([resolve, reject]);
      });
    },
    []
  );
  const handleClose = () => {
    setResolveReject([]);
  };
  const handleCancel = () => {
    reject();
    handleClose();
  };
  const handleConfirm = () => {
    resolve();
    handleClose();
  };
  return (
    <Fragment>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <Dialog
        fullWidth
        {...dialogProps}
        open={resolveReject.length === 2}
        onClose={handleClose}
      >
        <DialogTitle>{confirmOptions.title}</DialogTitle>
        {confirmOptions.description && (
          <DialogContent>
            <DialogContentText>{confirmOptions.description}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleCancel}>
            {t(confirmOptions.cancellationText!)}
          </Button>
          <Button onClick={handleConfirm}>
            {t(confirmOptions.confirmationText!)}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
export const ConfirmDialogProvider = hot(_ConfirmDialogProvider);
export const useConfirm = () => useContext(ConfirmContext);
