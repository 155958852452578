import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Select as SelectAs,
  Typography,
  CircularProgress,
  Dialog,
  Popover,
  useTheme,
  useMediaQuery,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  Add,
  CalendarTodaySharp,
  Delete,
  Edit,
  ExpandMore,
  Save,
} from '@material-ui/icons';
import moment, { Moment } from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Colors, DateFormats } from 'res/Enums';
import { AddSituation, AddSituationOptions } from './AddSituation';
import SmokerIcon from 'assets/icons/smoker.png';
import NonSmokerIcon from 'assets/icons/non-smoker.png';
import BloodIcon from 'assets/icons/blood.png';
import BirthdayIcon from 'assets/icons/Birthday.svg';
import { StyledDatePicker } from 'components/DatePicker';
import { useTutorialContext } from 'context/TutorialContext';
import { Demo } from 'components/Demo';
import Select from 'react-select';
import { AutoCompleteStyle } from 'components/AutoComplete';
import {
  BasicSituation,
  SituationDTO,
  SituationMoreDTO,
  UserBodySystemDTO,
  UserDto,
  UserSituationDTO,
  VaccineDTO,
  UserVaccineDTO,
  UserHospitalizationDTO,
  BodySystemDTO,
  useBloodControllerSetMyBlood,
  useSituationControllerSetSituation,
  useMeControllerGetMe,
  useSituationControllerGetBasicSituation,
  useSituationControllerGetMySituation,
  useSituationControllerGetMyFamilyHistory,
  useSituationControllerGetMyAddiction,
  useSituationControllerGetMyVaccines,
  useSituationControllerGetMyHospital,
  useSituationControllerGetMyBodySystem,
  situationControllerGetBasicSituation,
  situationControllerGetBodyTypes,
  situationControllerGetHereditary,
  situationControllerGetMore,
  situationControllerGetMyAddiction,
  situationControllerGetMyBodySystem,
  situationControllerGetMyFamilyHistory,
  situationControllerGetMyHospital,
  situationControllerGetMySituation,
  situationControllerGetMyVaccines,
  situationControllerGetVaccineTypes,
  meControllerGetMe,
  useMeControllerUpdateMe,
  useSituationControllerDeleteMySituation,
  useSituationControllerUpdateMySituation,
  useSituationControllerCreateMyFamilyHistory,
  useSituationControllerUpdateMyFamilyHistory,
  useSituationControllerDeleteMyFamilyHistory,
  useSituationControllerCreateMyAddiction,
  useSituationControllerUpdateMyAddiction,
  useSituationControllerDeleteMyAddiction,
  useSituationControllerCreateMyVaccine,
  useSituationControllerUpdateMyVaccine,
  useSituationControllerDeleteMyVaccine,
  useSituationControllerCreateMySituation,
  useSituationControllerCreateMyHospital,
  useSituationControllerUpdateMyHospital,
  useSituationControllerDeleteMyHospital,
  useSituationControllerCreateMyBodySystem,
  useSituationControllerUpdateMyBodySystem,
  useSituationControllerDeleteMyBodySystem,
  BloodDTOType,
  SituationDTOCategory,
  UserAddictionDTOType,
  UserHospitalizationDTOType,
  CreateAddictionType,
  UserAddictionDTO,
  CreateBloodType,
  CreateFamilyHistoryFamilyType,
  CreateHospitalizationType,
  FamilyHistoryDTO,
  FamilyHistoryDTOFamilyType,
  SetSituationType,
  useSituationControllerDeleteMySmoking,
  CreateAddiction,
} from 'gen/client';

interface EditConciseProps {
  tut?: number;
}

const _EditConcise = ({ tut }: EditConciseProps) => {
  const [me, setMe] = useState<UserDto>();
  const [basic, setBasic] = useState<BasicSituation>();
  const [userSituation, setUserSituation] = useState<UserSituationDTO[]>([]);
  const [dialog, setDialog] = useState<AddSituationOptions>({
    open: false,
  });
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { openT, cont, setCont } = useTutorialContext();
  const inputEl = useRef<any | null>();
  const [temp, setTemp] = useState(0);
  const [hereditary, setHereditary] = useState<SituationDTO[] | null>(null);
  const [familyHistory, setFamilyHistory] = useState<FamilyHistoryDTO[]>([]);
  const [addiction, setAddiction] = useState<UserAddictionDTO[]>([]);
  const [vaccines, setVaccines] = useState<VaccineDTO[] | null>(null);
  const [userVaccine, setUserVaccine] = useState<UserVaccineDTO[]>([]);
  const [more, setMore] = useState<SituationMoreDTO | null>(null);
  const [hospital, setHospital] = useState<UserHospitalizationDTO[]>([]);
  const [body, setBody] = useState<BodySystemDTO[]>([]);
  const [userBody, setUserBody] = useState<UserBodySystemDTO[]>([]);
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const setBloodMutation = useBloodControllerSetMyBlood();
  const setSituationMutation = useSituationControllerSetSituation();
  const { data: meData, refetch: meRefetch } = useMeControllerGetMe();
  const { data: basicData, refetch: basicRefetch } =
    useSituationControllerGetBasicSituation();
  const { data: mySituationData, refetch: mySituationRefetch } =
    useSituationControllerGetMySituation();
  const { data: familyHistoryData, refetch: familyHisotryRefetch } =
    useSituationControllerGetMyFamilyHistory();
  const { data: addictionData, refetch: addictionRefetch } =
    useSituationControllerGetMyAddiction();
  const { data: vaccinesData, refetch: vaccinesRefetch } =
    useSituationControllerGetMyVaccines();
  const { data: hospitalData, refetch: hospitalRefetch } =
    useSituationControllerGetMyHospital();
  const { data: bodySystemData, refetch: bodySystemRefetch } =
    useSituationControllerGetMyBodySystem();
  const meUpdateMutation = useMeControllerUpdateMe();

  const changeBlood = async (e: React.ChangeEvent<{ value: unknown }>) => {
    try {
      await setBloodMutation
        .mutateAsync({ data: { type: e.target.value as CreateBloodType } })
        .then((resp) => resp && toast(t('Updated')));
      meRefetch();
    } catch (e) {}
  };
  const setSituation = async ({
    type,
    set,
  }: {
    type: SetSituationType;
    set: boolean;
  }) => {
    try {
      await setSituationMutation
        .mutateAsync({ data: { type, set } })
        .then(() => toast(t('Updated')))
        .catch();
      basicRefetch();
    } catch (e) {}
  };

  const getAll = () => {
    const getMePromise = meControllerGetMe();

    const getBasicPromise = situationControllerGetBasicSituation();

    const getSituationPromise = situationControllerGetMySituation();

    const getHereditaryPromise = situationControllerGetHereditary();

    const getFamilyHistoryPromise = situationControllerGetMyFamilyHistory();

    const getAddictionPromise = situationControllerGetMyAddiction();

    const getMorePromise = situationControllerGetMore();

    const getVaccinePromise = situationControllerGetVaccineTypes();

    const getMyVaccinesPromise = situationControllerGetMyVaccines();

    const getMyHospitalPromise = situationControllerGetMyHospital();

    const getBodySystemPromise = situationControllerGetBodyTypes();

    const getMyBodySystemPromise = situationControllerGetMyBodySystem();

    Promise.all([
      getMePromise,
      getBasicPromise,
      getSituationPromise,
      getHereditaryPromise,
      getFamilyHistoryPromise,
      getAddictionPromise,
      getMorePromise,
      getVaccinePromise,
      getMyVaccinesPromise,
      Promise.all([
        getMyHospitalPromise,
        getBodySystemPromise,
        getMyBodySystemPromise,
      ]),
    ])
      .then((values) => {
        setMe(values[0].data);
        setBasic(values[1].data);
        setUserSituation(values[2].data);
        setHereditary(values[3].data);
        setFamilyHistory(values[4].data);
        setAddiction(values[5].data);
        setMore(values[6].data);
        setVaccines(values[7].data);
        setUserVaccine(values[8].data);
        setHospital(values[9][0].data);
        setBody(values[9][1].data);
        setUserBody(values[9][2].data);
        setLoading(false);
      })
      .catch();
  };

  useEffect(() => {
    if (meData) setMe(meData.data);
  }, [meData]);

  useEffect(() => {
    if (basicData) setBasic(basicData.data);
  }, [basicData]);

  useEffect(() => {
    if (mySituationData) setUserSituation(mySituationData.data);
  }, [mySituationData]);

  useEffect(() => {
    if (familyHistoryData) setFamilyHistory(familyHistoryData.data);
  }, [familyHistoryData]);

  useEffect(() => {
    if (addictionData) setAddiction(addictionData.data);
  }, [addictionData]);

  useEffect(() => {
    if (vaccinesData) setUserVaccine(vaccinesData.data);
  }, [vaccinesData]);

  useEffect(() => {
    if (hospitalData) setHospital(hospitalData.data);
  }, [hospitalData]);

  useEffect(() => {
    if (bodySystemData) setUserBody(bodySystemData.data);
  }, [bodySystemData]);

  const filterSituation = (category: SituationDTOCategory) =>
    userSituation.filter((sit) => sit.situation.category === category);

  useEffect(() => {
    getAll();
    setTemp(cont);
    setCont(cont + 1);
  }, []);

  if (loading && temp >= 1) {
    return (
      <Box
        style={{
          display: 'flex',
          height: '80vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={70} />
      </Box>
    );
  }
  return (
    <Grid
      container
      spacing={1}
      justify="center"
      ref={inputEl}
      style={tut ? { padding: 16, paddingBottom: 0 } : {}}
    >
      <Grid item xs={12} md={6} lg={3}>
        <ItemHeader title={t('General')} />
        {me && basic && (
          <Paper className={classes.paper}>
            <Box style={{ width: 'fit-content' }}>
              <img alt="" src={BloodIcon} />
            </Box>
            <Typography>{t('BloodType')}</Typography>
            <SelectAs value={me.bloodType} onChange={changeBlood}>
              {Object.values(BloodDTOType).map((item, i) => (
                <MenuItem value={item} key={i}>
                  {item === BloodDTOType.ABneg
                    ? 'AB-'
                    : item === BloodDTOType.ABpos
                    ? 'AB+'
                    : item === BloodDTOType.Aneg
                    ? 'A-'
                    : item === BloodDTOType.Apos
                    ? 'A+'
                    : item === BloodDTOType.Bneg
                    ? 'B-'
                    : item === BloodDTOType.Bpos
                    ? 'B+'
                    : item === BloodDTOType.Oneg
                    ? 'O-'
                    : 'O+'}
                </MenuItem>
              ))}
            </SelectAs>
            <Box style={{ width: 'fit-content', marginTop: 16 }}>
              <img
                alt=""
                src={basic.smoker === true ? SmokerIcon : NonSmokerIcon}
              />
            </Box>
            <Typography>
              {basic?.smoker === true ? t('Smoker') : t('NoSmoker')}
            </Typography>
            {/* <Checkbox
              color="primary"
              checked={basic.smoker}
              onChange={() =>
                setSituation({
                  type: SetSituationType.Smoking,
                  set: !basic.smoker,
                })
              }
            /> */}
            <Box style={{ width: 'fit-content', marginTop: 16 }}>
              <img
                alt=""
                src={BirthdayIcon}
                style={{ width: 58, height: 58 }}
              />
            </Box>
            <Typography>{t('BirthDate')}</Typography>

            <StyledDatePicker
              value={moment(me.birthDate)}
              format={DateFormats.GrNoTime}
              inputProps={{
                style: {
                  fontSize: 16,
                  textAlign: 'center',
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  display: 'flex',
                  justifyContent: 'center',
                  width: '130%',
                },
              }}
              onChange={(date) => {
                meUpdateMutation
                  .mutateAsync({
                    data: {
                      ...me,
                      sex: me.sex as any,
                      birthDate: (date as Moment).format(DateFormats.ISO),
                    },
                  })
                  .then((r) => {
                    if (r) {
                      meRefetch();
                      toast(t('Updated'));
                    }
                  })
                  .catch();
              }}
            />
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ItemHeader title={t('Chronic')} />
        <Button
          startIcon={<Add />}
          onClick={() =>
            setDialog({
              open: true,
              category: SituationDTOCategory.ChronicDisease,
            })
          }
        >
          {t('Add')}
        </Button>
        {filterSituation(SituationDTOCategory.ChronicDisease).length > 0 && (
          <Paper className={classes.list}>
            <List>
              {filterSituation(SituationDTOCategory.ChronicDisease).map(
                (item, i) => (
                  <SituationListItem
                    onDelete={mySituationRefetch}
                    item={item}
                    key={i}
                  />
                )
              )}
            </List>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ItemHeader title={t('Allergies')} />
        <Button
          startIcon={<Add />}
          onClick={() =>
            setDialog({ open: true, category: SituationDTOCategory.Allergy })
          }
        >
          {t('Add')}
        </Button>
        {filterSituation(SituationDTOCategory.Allergy).length > 0 && (
          <Paper className={classes.list}>
            <List>
              {filterSituation(SituationDTOCategory.Allergy).map((item, i) => (
                <SituationListItem
                  onDelete={mySituationRefetch}
                  item={item}
                  key={i}
                />
              ))}
            </List>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ItemHeader title={t('TemporaryDiseases')} />
        <Button
          startIcon={<Add />}
          onClick={() =>
            setDialog({
              open: true,
              category: SituationDTOCategory.TemporaryDisease,
            })
          }
        >
          {t('Add')}
        </Button>
        {filterSituation(SituationDTOCategory.TemporaryDisease).length > 0 &&
          filterSituation(SituationDTOCategory.TemporaryDisease).filter(
            (item) => item.valid === true
          ).length > 0 && (
            <Paper className={classes.list}>
              <List>
                {filterSituation(SituationDTOCategory.TemporaryDisease)
                  .filter((item) => item.valid)
                  .map((item, i) => (
                    <SituationListItem
                      onDelete={mySituationRefetch}
                      item={item}
                      key={i}
                    />
                  ))}
              </List>
            </Paper>
          )}
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginTop: '7%', display: 'flex', flexDirection: 'column' }}
      >
        <Box
          style={{
            display: 'flex',
            backgroundColor: Colors.PRIMARY,
            height: 8,
            borderRadius: '4px 4px 0 0',
          }}
        />
        <ExpansionPanel defaultExpanded={false} style={{ marginTop: 0 }}>
          <ExpansionPanelSummary expandIcon={<ExpandMore />}>
            <Typography>{t('MoreHistory')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ExpansionPanel defaultExpanded={false}>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography>{t('FamilyHistory')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Grid container spacing={1} style={{ paddingLeft: 24 }}>
                  <Grid item xs={2}>
                    <Typography style={{ paddingLeft: 8, color: Colors.Black }}>
                      {t('Sigenia')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{ color: Colors.Black }}>
                      {t('Asthenia')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{ color: Colors.Black }}>
                      {t('MoreDetails')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
                {hereditary !== null && (
                  <Box>
                    <Typography
                      style={{
                        marginTop: 16,
                        paddingLeft: 32,
                        color: Colors.Black,
                        fontWeight: 'bold',
                      }}
                    >
                      {t('Father')}
                    </Typography>
                    <List>
                      <FamilyHistoryListItem
                        data={hereditary}
                        type={FamilyHistoryDTOFamilyType.Father}
                        onUpdate={familyHisotryRefetch}
                      />
                      {familyHistory
                        .filter(
                          (item) =>
                            item.familyType ===
                            FamilyHistoryDTOFamilyType.Father
                        )
                        .map((item, i) => (
                          <FamilyHistoryListItem
                            data={hereditary}
                            item={item}
                            type={FamilyHistoryDTOFamilyType.Father}
                            key={i}
                            onUpdate={() => familyHisotryRefetch()}
                          />
                        ))}
                    </List>
                    <Typography
                      style={{
                        marginTop: 8,
                        paddingLeft: 32,
                        color: Colors.Black,
                        fontWeight: 'bold',
                      }}
                    >
                      {t('Mother')}
                    </Typography>
                    <List>
                      <FamilyHistoryListItem
                        data={hereditary}
                        type={FamilyHistoryDTOFamilyType.Mother}
                        onUpdate={() => familyHisotryRefetch()}
                      />
                      {familyHistory
                        .filter(
                          (item) =>
                            item.familyType ===
                            FamilyHistoryDTOFamilyType.Mother
                        )
                        .map((item, i) => (
                          <FamilyHistoryListItem
                            data={hereditary}
                            item={item}
                            key={i}
                            type={FamilyHistoryDTOFamilyType.Mother}
                            onUpdate={() => familyHisotryRefetch()}
                          />
                        ))}
                    </List>
                    <Typography
                      style={{
                        marginTop: 8,
                        paddingLeft: 32,
                        color: Colors.Black,
                        fontWeight: 'bold',
                      }}
                    >
                      {t('Sibling')}
                    </Typography>
                    <List>
                      <FamilyHistoryListItem
                        data={hereditary}
                        type={FamilyHistoryDTOFamilyType.Sibling}
                        onUpdate={() => familyHisotryRefetch()}
                      />
                      {familyHistory
                        .filter(
                          (item) =>
                            item.familyType ===
                            FamilyHistoryDTOFamilyType.Sibling
                        )
                        .map((item, i) => (
                          <FamilyHistoryListItem
                            data={hereditary}
                            item={item}
                            key={i}
                            type={FamilyHistoryDTOFamilyType.Sibling}
                            onUpdate={() => familyHisotryRefetch()}
                          />
                        ))}
                    </List>
                    <Typography
                      style={{
                        marginTop: 8,
                        paddingLeft: 32,
                        color: Colors.Black,
                        fontWeight: 'bold',
                      }}
                    >
                      {t('Grandpa')}
                    </Typography>
                    <List>
                      <FamilyHistoryListItem
                        data={hereditary}
                        type={FamilyHistoryDTOFamilyType.Grandparent}
                        onUpdate={() => familyHisotryRefetch()}
                      />
                      {familyHistory
                        .filter(
                          (item) =>
                            item.familyType ===
                            FamilyHistoryDTOFamilyType.Grandparent
                        )
                        .map((item, i) => (
                          <FamilyHistoryListItem
                            data={hereditary}
                            item={item}
                            key={i}
                            type={FamilyHistoryDTOFamilyType.Grandparent}
                            onUpdate={() => familyHisotryRefetch()}
                          />
                        ))}
                    </List>
                  </Box>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel defaultExpanded={false}>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography>{t('Addictions')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <Typography
                      style={{ paddingLeft: 32, color: Colors.Black }}
                    >
                      {t('Category')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      style={{ marginLeft: -24, color: Colors.Black }}
                    >
                      {t('Uselevel')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      style={{ paddingLeft: 32, color: Colors.Black }}
                    >
                      {t('StartDate')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ paddingLeft: 4, color: Colors.Black }}>
                      {t('EndDate')}
                    </Typography>
                  </Grid>
                </Grid>
                <Box>
                  <Typography
                    style={{
                      marginTop: 16,
                      paddingLeft: 32,
                      color: Colors.Black,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('Alcohol')}
                  </Typography>
                  <List>
                    <AddictionListItem
                      type={UserAddictionDTOType.Alcohol}
                      onUpdate={() => addictionRefetch()}
                    />
                    {addiction
                      .filter(
                        (item) => item.type === UserAddictionDTOType.Alcohol
                      )
                      .map((item, i) => (
                        <AddictionListItem
                          item={item}
                          type={UserAddictionDTOType.Alcohol}
                          onUpdate={() => addictionRefetch()}
                          key={i}
                        />
                      ))}
                  </List>
                  <Typography
                    style={{
                      marginTop: 8,
                      paddingLeft: 32,
                      color: Colors.Black,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('Drugs')}
                  </Typography>
                  <List>
                    <AddictionListItem
                      type={UserAddictionDTOType.DrugUse}
                      onUpdate={() => addictionRefetch()}
                    />
                    {addiction
                      .filter(
                        (item) => item.type === UserAddictionDTOType.DrugUse
                      )
                      .map((item, i) => (
                        <AddictionListItem
                          item={item}
                          type={UserAddictionDTOType.DrugUse}
                          onUpdate={() => addictionRefetch()}
                          key={i}
                        />
                      ))}
                  </List>
                  <Typography
                    style={{
                      marginTop: 8,
                      paddingLeft: 32,
                      color: Colors.Black,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('Smoke')}
                  </Typography>
                  <List>
                    <AddictionListItem
                      type={UserAddictionDTOType.Smoker}
                      onUpdate={() => {
                        addictionRefetch();
                        basicRefetch();
                      }}
                    />
                    {addiction
                      .filter(
                        (item) => item.type === UserAddictionDTOType.Smoker
                      )
                      .map((item, i) => (
                        <AddictionListItem
                          item={item}
                          type={UserAddictionDTOType.Smoker}
                          onUpdate={() => {
                            addictionRefetch();
                            basicRefetch();
                          }}
                          key={i}
                        />
                      ))}
                  </List>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel defaultExpanded={false}>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography>{t('MoreDetails')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Grid container spacing={1} style={{ paddingLeft: 24 }}>
                  <Grid item xs={2}>
                    <Typography style={{ paddingLeft: 8, color: Colors.Black }}>
                      {t('Category')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{ color: Colors.Black }}>
                      {t('Vaanddiseases')}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ color: Colors.Black }}>
                      {t('StartDate')}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography style={{ color: Colors.Black }}>
                      {t('DiseaseQ')}
                    </Typography>
                  </Grid>
                </Grid>
                {more !== null && vaccines !== null && (
                  <Box>
                    <Typography
                      style={{
                        marginTop: 16,
                        paddingLeft: 32,
                        color: Colors.Black,
                        fontWeight: 'bold',
                      }}
                    >
                      {t('Kidi')}
                    </Typography>
                    <List>
                      <MoreListItem
                        data={more.kid}
                        type="ye"
                        onUpdate={() => mySituationRefetch()}
                      />
                      {userSituation
                        .filter((item) =>
                          more.kid.find((kid) => kid.id === item.situation.id)
                        )
                        .map((item, i) => (
                          <MoreListItem
                            data={more.kid}
                            item={item}
                            type="ye"
                            key={i}
                            onUpdate={() => mySituationRefetch()}
                          />
                        ))}
                    </List>
                    <Typography
                      style={{
                        marginTop: 8,
                        paddingLeft: 32,
                        color: Colors.Black,
                        fontWeight: 'bold',
                      }}
                    >
                      {t('Sexi')}
                    </Typography>
                    <List>
                      <MoreListItem
                        data={more.sex}
                        type="it"
                        onUpdate={() => mySituationRefetch()}
                      />
                      {userSituation
                        .filter((item) =>
                          more.sex.find((kid) => kid.id === item.situation.id)
                        )
                        .map((item, i) => (
                          <MoreListItem
                            data={more.sex}
                            item={item}
                            type="it"
                            key={i}
                            onUpdate={() => mySituationRefetch()}
                          />
                        ))}
                    </List>
                    <Typography
                      style={{
                        marginTop: 8,
                        paddingLeft: 32,
                        color: Colors.Black,
                        fontWeight: 'bold',
                      }}
                    >
                      {t('Vaccines')}
                    </Typography>
                    <List>
                      <MoreListItem
                        data={vaccines}
                        type="va"
                        onUpdate={() => vaccinesRefetch()}
                      />
                      {userVaccine.map((item, i) => (
                        <MoreListItem
                          data={more.kid}
                          va={item}
                          type="va"
                          key={i}
                          onUpdate={() => vaccinesRefetch()}
                        />
                      ))}
                    </List>
                  </Box>
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel defaultExpanded={false}>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography>{t('Hospitalization')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Grid container spacing={1} style={{ paddingLeft: 16 }}>
                  <Grid item xs={2}>
                    <Typography style={{ color: Colors.Black }}>
                      {t('Category')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ color: Colors.Black }}>
                      {t('StartDate')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ color: Colors.Black }}>
                      {t('EndDate')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{ color: Colors.Black }}>
                      {t('Details')}
                    </Typography>
                  </Grid>
                </Grid>
                <Box>
                  <List>
                    <HospitalListItem onUpdate={() => hospitalRefetch()} />
                    {hospital.map((item, i) => (
                      <HospitalListItem
                        item={item}
                        onUpdate={() => hospitalRefetch()}
                        key={i}
                      />
                    ))}
                  </List>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel defaultExpanded={false}>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography>{t('FormerSit')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box>
                  <List>
                    {more !== null &&
                      userSituation
                        .filter((item) => item.valid === false)
                        .filter(
                          (item) =>
                            item.situation.id !==
                            more.kid
                              .concat(more.sex)
                              .find((mores) => mores.id === item.situation.id)
                              ?.id
                        )
                        .map((item, i) => (
                          <SituationListItem
                            onDelete={mySituationRefetch}
                            item={item}
                            del={true}
                            key={i}
                          />
                        ))}
                  </List>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel defaultExpanded={false}>
              <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography>{t('BodySystems')}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{
                  padding: 0,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      paddingLeft: 24,
                    }}
                  >
                    <Typography style={{ color: Colors.Black }}>
                      {t('BodySystem')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ paddingLeft: 16 }}>
                    <Typography style={{ color: Colors.Black }}>
                      {t('Details')}
                    </Typography>
                  </Grid>
                </Grid>
                <Box>
                  <List>
                    <SystemListItem
                      data={body}
                      onUpdate={() => bodySystemRefetch()}
                    />
                    {userBody.map((item, i) => (
                      <SystemListItem
                        item={item}
                        data={body}
                        onUpdate={() => bodySystemRefetch()}
                        key={i}
                      />
                    ))}
                  </List>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      {temp < 1 && (
        <Dialog
          open={!me?.tutorial && openT === '20'}
          BackdropProps={{
            style: { backgroundColor: 'black', opacity: 0.8 },
          }}
        >
          <Popover
            open={openT === '20'}
            anchorEl={inputEl.current}
            PaperProps={{
              style: {
                width: isBelowMd ? '90vw' : '75vw',
                overflow: 'hidden',
                height: '80vh',
              },
            }}
          >
            <EditConcise tut={cont} />
            <Popover
              open={openT === '20'}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  marginLeft: '-16%',
                  marginTop: '1%',
                },
              }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                {t('Tut32')}
              </Typography>
            </Popover>
            <Demo />
          </Popover>
        </Dialog>
      )}
      <AddSituation
        options={dialog}
        onClose={() => setDialog({ ...dialog, open: false })}
        onAdd={() => mySituationRefetch()}
      />
    </Grid>
  );
};
const useStyles = makeStyles((theme) => ({
  container: {},
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  list: {
    marginTop: theme.spacing(1),
  },
}));
export const EditConcise = hot(_EditConcise);

export const ItemHeader = ({ title }: { title: string }) => {
  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 258,
        minHeight: 50,
      }}
    >
      <Box
        style={{
          display: 'flex',
          backgroundColor: Colors.PRIMARY,
          height: 8,
          borderRadius: '4px 4px 0 0',
        }}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          flex: '1 1 auto',
          alignItems: 'center',
        }}
      >
        <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {title}
        </Typography>
      </Box>
    </Paper>
  );
};
interface SituationListItemProps {
  item: UserSituationDTO;
  del?: boolean;
  onDelete: () => void;
}
const SituationListItem = ({ onDelete, item, del }: SituationListItemProps) => {
  const { t } = useTranslation();
  const deleteMutation = useSituationControllerDeleteMySituation();
  const updateMutation = useSituationControllerUpdateMySituation();

  const handleDelete = async (id: number) => {
    try {
      const r = await deleteMutation.mutateAsync({ id });
      if (r) {
        toast(t('Deleted'));
        onDelete();
      } else {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Deleted'), { containerId: 'deleted' });
    }
  };

  const handleEdit = async (id: number) => {
    try {
      const r = await updateMutation.mutateAsync({
        id,
        data: {
          situationId: id,
          valid: false,
          dateDeclared: item.dateDeclared,
        },
      });
      if (r) {
        toast(t('Updated'));
        onDelete();
      } else {
        toast(t('Failed'));
      }
    } catch (e) {
      toast(t('Error'), { containerId: 'deleted' });
    }
  };

  return (
    <ListItem>
      <ListItemText>
        <Box
          style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}
        >
          <Box style={{ flex: '1 1 auto', textAlign: 'left' }}>
            <Box
              style={{
                display: 'flex',
                maxWidth: 350,
                color: Colors.Black,
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              {item.situation.name}
            </Box>
            <Box
              style={{ marginTop: 6, color: Colors.Black, fontSize: 16 }}
            >{`${t('Declared')}: ${moment(item.dateDeclared).format(
              DateFormats.GrNoTime
            )}`}</Box>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                if (del === true) handleDelete(item.id);
                else {
                  if (
                    item.situation.category ===
                    SituationDTOCategory.TemporaryDisease
                  )
                    handleEdit(item.id);
                  else handleDelete(item.id);
                }
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
      </ListItemText>
    </ListItem>
  );
};

interface HistoryListItem {
  item?: FamilyHistoryDTO;
  data: SituationDTO[];
  type: FamilyHistoryDTOFamilyType;
  onUpdate: () => void;
}

const FamilyHistoryListItem = ({
  item,
  data,
  type,
  onUpdate,
}: HistoryListItem) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<SituationDTO | null>(null);
  const [text, setText] = useState('');
  const [editOn, setEditOn] = useState(false);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down(600));
  const createMutation = useSituationControllerCreateMyFamilyHistory();
  const updateMutation = useSituationControllerUpdateMyFamilyHistory();
  const deleteMutation = useSituationControllerDeleteMyFamilyHistory();

  const handleReset = async () => {
    setSelected(null);
    setText('');
    setEditOn(false);
  };

  const addFamilyHistory = async () => {
    try {
      if (selected !== null) {
        const resp = await createMutation.mutateAsync({
          data: {
            familyType: type as unknown as CreateFamilyHistoryFamilyType,
            comments: text,
            situation: selected,
          },
        });
        if (resp) {
          toast(t('Added'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const editFamilyHistory = async (id: number) => {
    try {
      if (selected !== null) {
        const resp = await updateMutation.mutateAsync({
          id,
          data: {
            familyType: type as unknown as CreateFamilyHistoryFamilyType,
            comments: text,
            situation: selected,
          },
        });
        if (resp) {
          toast(t('Updated'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const deleteFamilyHistory = async (id: number) => {
    try {
      const r = await deleteMutation.mutateAsync({ id });
      if (r) {
        toast(t('Deleted'));
        onUpdate();
        handleReset();
      } else {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Deleted'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (item !== undefined) {
      setSelected(item.situation);
      setText(item.comments);
    }
  }, [item]);

  return (
    <ListItem>
      <ListItemText>
        <Grid container spacing={4}>
          <Grid item xs={2} />
          <Grid item xs={4}>
            {item && selected && editOn === false ? (
              <Typography
                style={{ color: Colors.Black, fontSize: isBelowXs ? 14 : 16 }}
              >
                {selected.name}
              </Typography>
            ) : (
              <Select
                defaultValue={selected}
                value={selected && { value: selected.id, label: selected.name }}
                filterOption={(candidate, input) => {
                  if (input) {
                    return new RegExp(
                      `.*${input
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')}.*`,
                      'i'
                    ).test(
                      candidate.label
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                    );
                  }
                  return true;
                }}
                options={data.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(e: any) => {
                  setSelected(data.filter((item) => item.id === e.value)[0]);
                }}
                blurInputOnSelect={false}
                {...AutoCompleteStyle}
                placeholder={`${t('Search')}...`}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {item && selected && editOn === false ? (
              <Typography
                style={{ color: Colors.Black, fontSize: isBelowXs ? 14 : 16 }}
              >
                {text}
              </Typography>
            ) : (
              <TextField
                style={{ width: '100%' }}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            )}
          </Grid>
          <Grid
            item
            xs={2}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <IconButton
              style={{ marginRight: 8 }}
              disabled={item === undefined && selected === null}
              onClick={() => {
                item && editOn === false
                  ? setEditOn(true)
                  : item &&
                    editOn === true &&
                    (item.situation !== selected || item.comments !== text)
                  ? editFamilyHistory(item.id)
                  : item && editOn === true
                  ? setEditOn(false)
                  : addFamilyHistory();
              }}
            >
              {(item && editOn === false) ||
              (item &&
                editOn === true &&
                item.situation.id === selected?.id &&
                item.comments === text) ? (
                <Edit />
              ) : (
                <Save />
              )}
            </IconButton>
            <IconButton
              onClick={() => {
                if (item) deleteFamilyHistory(item.id);
              }}
              disabled={item === undefined}
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );
};

interface AddictionItem {
  item?: UserAddictionDTO;
  registerItem?: CreateAddiction;
  type: UserAddictionDTOType;
  register?: boolean;
  onUpdate: () => void;
  onRegister?: (item: CreateAddiction) => void;
  onDelete?: (item: CreateAddiction) => void;
}

export const AddictionListItem = ({
  item,
  type,
  register,
  registerItem,
  onUpdate,
  onRegister,
  onDelete,
}: AddictionItem) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [time, setTime] = useState<Moment | null>(null);
  const [endTime, setEndTime] = useState<Moment | null>(null);
  const [editOn, setEditOn] = useState(false);
  const [error, setError] = useState(false);
  const drop = type === UserAddictionDTOType.DrugUse ? [1, 2, 3] : [1, 2, 3, 4];
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down(1020));
  const isBelowXs = useMediaQuery(theme.breakpoints.down(600));
  const createMutation = useSituationControllerCreateMyAddiction();
  const updateMutation = useSituationControllerUpdateMyAddiction();
  const deleteMutation = useSituationControllerDeleteMyAddiction();
  const deleteSmokingMutation = useSituationControllerDeleteMySmoking();

  const handleReset = async () => {
    setValue(0);
    setTime(null);
    setEndTime(null);
    setEditOn(false);
  };

  const addAddiction = async () => {
    try {
      if (type && time) {
        const resp = await createMutation.mutateAsync({
          data: {
            type: type as unknown as CreateAddictionType,
            value: value,
            time: time?.startOf('month').format(DateFormats.ISONoTime),
            endTime: endTime?.startOf('month').format(DateFormats.ISONoTime),
          },
        });
        if (resp) {
          toast(t('Added'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const editAddiction = async (id: number) => {
    try {
      if (type && time) {
        const resp = await updateMutation.mutateAsync({
          id,
          data: {
            type: type as unknown as CreateAddictionType,
            value: value,
            time: time?.startOf('month').format(DateFormats.ISONoTime),
            endTime: endTime?.startOf('month').format(DateFormats.ISONoTime),
          },
        });
        if (resp) {
          toast(t('Updated'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const deleteAddiction = async (id: number) => {
    try {
      const r = await deleteMutation.mutateAsync({ id });
      if (r) {
        toast(t('Deleted'));
        onUpdate();
        handleReset();
      } else {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Deleted'), { containerId: 'deleted' });
    }
  };

  const deleteSmoking = async (id: number) => {
    try {
      const r = await deleteSmokingMutation.mutateAsync({ id });
      if (r) {
        toast(t('Deleted'));
        onUpdate();
        handleReset();
      } else {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Deleted'), { containerId: 'deleted' });
    }
  };

  const forRegister = async (item: CreateAddiction) => {
    handleReset();
    if (onRegister) onRegister(item);
  };

  useEffect(() => {
    if (item !== undefined) {
      setValue(item.value);
      setTime(moment(item.time));
      if (item.endTime !== null) setEndTime(moment(item.endTime));
    }
  }, [item]);

  useEffect(() => {
    if (registerItem !== undefined) {
      setValue(registerItem.value);
      setTime(moment(registerItem.time));
      if (registerItem.endTime !== undefined)
        setEndTime(moment(registerItem.endTime));
    }
  }, [registerItem]);

  useEffect(() => {
    if (time !== null && endTime !== null && endTime.isBefore(time, 'month'))
      setError(true);
    else setError(false);
  }, [time]);

  useEffect(() => {
    if (time !== null && endTime !== null && endTime.isBefore(time, 'month'))
      setError(true);
    else setError(false);
  }, [endTime]);

  return (
    <ListItem>
      <ListItemText>
        <Grid container spacing={4}>
          <Grid item xs={2} />
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              alignItems: error ? 'center' : 'flex-end',
            }}
          >
            {registerItem && editOn === false ? (
              <Typography
                style={{
                  color: Colors.Black,
                  fontSize: isBelowXs ? 12 : 16,
                  marginLeft: isBelowSm ? '-25%' : '-20%',
                }}
              >
                {t(
                  type === UserAddictionDTOType.Alcohol
                    ? 'Alcohol' + registerItem.value
                    : type === UserAddictionDTOType.DrugUse
                    ? 'Drugs' + registerItem.value
                    : 'Smoke' + registerItem.value
                )}
              </Typography>
            ) : item && editOn === false ? (
              <Typography
                style={{
                  color: Colors.Black,
                  fontSize: isBelowXs ? 12 : 16,
                  marginLeft: isBelowSm ? '-25%' : '-20%',
                }}
              >
                {t(
                  type === UserAddictionDTOType.Alcohol
                    ? 'Alcohol' + item.value
                    : type === UserAddictionDTOType.DrugUse
                    ? 'Drugs' + item.value
                    : 'Smoke' + item.value
                )}
              </Typography>
            ) : (
              <SelectAs
                value={value}
                onChange={(e) => setValue(e.target.value as number)}
                style={{
                  fontSize: !isBelowSm ? 14 : 12,
                  marginLeft: -50,
                  width: !isBelowSm ? 'inherit' : 130,
                  minWidth: !isBelowSm ? 222 : 'inherit',
                }}
                SelectDisplayProps={{
                  style: isBelowSm
                    ? {
                        width: 130,
                        overflow: 'visible',
                        whiteSpace: 'inherit',
                      }
                    : {},
                }}
              >
                <MenuItem value={0}>
                  {t(
                    type === UserAddictionDTOType.Alcohol
                      ? 'Alcohol0'
                      : type === UserAddictionDTOType.DrugUse
                      ? 'Drugs0'
                      : 'Smoke0'
                  )}
                </MenuItem>
                {drop.map((item, i) =>
                  type === UserAddictionDTOType.Smoker ? (
                    <MenuItem value={item} key={i}>
                      <Tooltip
                        title={
                          type === UserAddictionDTOType.Smoker &&
                          t('Smoke' + (item + 4)).toString()
                        }
                      >
                        <div>{t('Smoke' + item)}</div>
                      </Tooltip>
                    </MenuItem>
                  ) : (
                    <MenuItem value={item}>
                      {t(
                        type === UserAddictionDTOType.Alcohol
                          ? 'Alcohol' + item
                          : type === UserAddictionDTOType.DrugUse
                          ? 'Drugs' + item
                          : 'Smoke' + item
                      )}
                    </MenuItem>
                  )
                )}
              </SelectAs>
            )}
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              padding: !isBelowSm ? '16px 8px 16px 32px' : '16px 8px 16px 16px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {time === null || editOn === true || error === true ? (
              <StyledDatePicker
                fullWidth
                openTo="year"
                views={['year', 'month']}
                value={time}
                onChange={(date) => setTime(date as Moment)}
                label={t('StartDate')}
                showTodayButton
                error={error}
                helperText={
                  error &&
                  t('StartDate').concat(t('MustSooner')).concat(t('EndDate'))
                }
                InputProps={{
                  endAdornment: !isBelowXs && (
                    <InputAdornment position="end">
                      <CalendarTodaySharp />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ style: { fontSize: !isBelowSm ? 14 : 12 } }}
                disableFuture
              />
            ) : (
              <Typography style={isBelowXs ? { fontSize: 14 } : {}}>
                {time.format(DateFormats.MONTH)}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              padding: '16px 16px 16px 8px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {endTime === null || editOn === true || error ? (
              <StyledDatePicker
                fullWidth
                openTo="year"
                views={['year', 'month']}
                value={endTime}
                onChange={(date) => setEndTime(date as Moment)}
                label={t('EndDate')}
                showTodayButton
                error={error}
                helperText={
                  error &&
                  t('StartDate').concat(t('MustSooner')).concat(t('EndDate'))
                }
                InputProps={{
                  endAdornment: !isBelowXs && (
                    <InputAdornment position="end">
                      <CalendarTodaySharp />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ style: { fontSize: !isBelowSm ? 14 : 12 } }}
                disableFuture
              />
            ) : (
              <Typography style={isBelowXs ? { fontSize: 14 } : {}}>
                {endTime.format(DateFormats.MONTH)}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: 0,
            }}
          >
            <IconButton
              style={{ marginRight: 8, height: 'fit-content' }}
              disabled={
                (item === undefined && value === 0) ||
                time === null ||
                (registerItem === undefined && value === 0) ||
                error === true
              }
              onClick={() => {
                register && time && onRegister && !registerItem
                  ? forRegister({
                      value: value,
                      type: CreateAddictionType.Smoker,
                      time: time.startOf('month').format(DateFormats.ISONoTime),
                      endTime:
                        endTime === null
                          ? undefined
                          : endTime
                              ?.startOf('month')
                              .format(DateFormats.ISONoTime),
                    })
                  : registerItem && register && editOn === false
                  ? setEditOn(true)
                  : registerItem && register && editOn === true
                  ? setEditOn(false)
                  : item && editOn === false
                  ? setEditOn(true)
                  : item &&
                    editOn === true &&
                    item.value === value &&
                    ((item.time === null && time === null) ||
                      item.time === time?.format(DateFormats.ISONoTime)) &&
                    ((item.endTime === null && endTime === null) ||
                      item.endTime === endTime?.format(DateFormats.ISONoTime))
                  ? setEditOn(false)
                  : item &&
                    editOn === true &&
                    (item.value !== value ||
                      item.time !== time?.format(DateFormats.ISONoTime) ||
                      item.endTime !== endTime?.format(DateFormats.ISONoTime))
                  ? editAddiction(item.id)
                  : addAddiction();
              }}
            >
              {(registerItem && register && editOn === false) ||
              (registerItem &&
                register &&
                editOn === true &&
                registerItem.value === value &&
                registerItem.time === time?.format(DateFormats.ISONoTime) &&
                registerItem.endTime ===
                  endTime?.format(DateFormats.ISONoTime)) ||
              (item && editOn === false) ||
              (item &&
                editOn === true &&
                item.value === value &&
                ((item.time === null && time === null) ||
                  item.time === time?.format(DateFormats.ISONoTime)) &&
                ((item.endTime === null && endTime === null) ||
                  item.endTime === endTime?.format(DateFormats.ISONoTime))) ? (
                <Edit />
              ) : (
                <Save />
              )}
            </IconButton>
            <IconButton
              style={{ height: 'fit-content' }}
              onClick={() => {
                if (register && registerItem && onDelete)
                  onDelete(registerItem);
                else if (!item) {
                  setTime(null);
                  setEndTime(null);
                  setValue(0);
                  setError(false);
                } else if (item && type !== UserAddictionDTOType.Smoker)
                  deleteAddiction(item.id);
                else if (item) deleteSmoking(item.id);
              }}
              disabled={register ? registerItem === undefined : false}
            >
              {!item ? (
                <RefreshIcon
                  style={{
                    fontWeight: 'bold',
                    transform: 'rotateY(180deg)',
                  }}
                />
              ) : (
                <Delete />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );
};

interface MoreListItemProps {
  va?: UserVaccineDTO;
  item?: UserSituationDTO;
  data: VaccineDTO[] | SituationDTO[];
  type: string;
  onUpdate: () => void;
}

const MoreListItem = ({
  item,
  data,
  type,
  onUpdate,
  va,
}: MoreListItemProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<SituationDTO | null>(null);
  const [date, setDate] = useState<Moment | null>(null);
  const [vaccine, setVaccine] = useState<VaccineDTO | null>(null);
  const [checkbox, setCheckbox] = useState(false);
  const [editOn, setEditOn] = useState(false);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down(600));
  const createVaccineMutation = useSituationControllerCreateMyVaccine();
  const updateVaccineMutation = useSituationControllerUpdateMyVaccine();
  const deleteVaccineMutation = useSituationControllerDeleteMyVaccine();
  const createSituationMutation = useSituationControllerCreateMySituation();
  const updateSitutationMutation = useSituationControllerUpdateMySituation();
  const deleteSituationMutation = useSituationControllerDeleteMySituation();

  const handleReset = async () => {
    setSelected(null);
    setVaccine(null);
    setDate(null);
    setEditOn(false);
  };

  const addVaccine = async () => {
    try {
      if (vaccine !== null && date !== null) {
        const resp = await createVaccineMutation.mutateAsync({
          data: {
            date: date.format(DateFormats.ISONoTime),
            vaccine: vaccine,
          },
        });
        if (resp) {
          toast(t('Added'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const editVaccine = async (id: number) => {
    try {
      if (vaccine !== null && date !== null) {
        const resp = await updateVaccineMutation.mutateAsync({
          id,
          data: {
            date: date.format(DateFormats.ISONoTime),
            vaccine: vaccine,
          },
        });
        if (resp) {
          toast(t('Updated'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const deleteVaccine = async (id: number) => {
    try {
      const r = await deleteVaccineMutation.mutateAsync({ id });
      if (r) {
        toast(t('Deleted'));
        onUpdate();
        handleReset();
      } else {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Deleted'), { containerId: 'deleted' });
    }
  };

  const situationAdd = async () => {
    try {
      if (selected && date) {
        const r = await createSituationMutation.mutateAsync({
          data: {
            dateDeclared: date.format(DateFormats.ISO),
            situationId: selected.id,
            valid: checkbox,
          },
        });
        if (r) {
          toast(t('Added'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const situationDelete = async (id: number) => {
    try {
      const r = await deleteSituationMutation.mutateAsync({ id });
      if (r) {
        toast(t('Deleted'));
        onUpdate();
        handleReset();
      } else {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Deleted'), { containerId: 'deleted' });
    }
  };

  const situationEdit = async (id: number) => {
    try {
      if (date) {
        const r = await updateSitutationMutation.mutateAsync({
          id,
          data: {
            situationId: id,
            valid: checkbox,
            dateDeclared: date.format(DateFormats.ISO),
          },
        });
        if (r) {
          toast(t('Updated'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'));
        }
      }
    } catch (e) {
      toast(t('Error'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (item !== undefined) {
      setSelected(item.situation);
      setDate(moment(item.dateDeclared));
      setCheckbox(item.valid);
    }
  }, [item]);

  useEffect(() => {
    if (va !== undefined) {
      setVaccine(va.vaccine);
      setDate(moment(va.date));
    }
  }, [va]);

  return (
    <ListItem>
      <ListItemText>
        <Grid container spacing={4}>
          <Grid item xs={2} />
          <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
            {(item || va) && (selected || vaccine) && editOn === false ? (
              <Typography
                style={{
                  color: Colors.Black,
                  fontSize: isBelowXs ? 14 : 16,
                }}
              >
                {selected ? selected.name : vaccine?.name}
              </Typography>
            ) : (
              <Select
                defaultValue={selected}
                isDisabled={item !== undefined && va === undefined}
                value={
                  selected
                    ? { value: selected.id, label: selected.name }
                    : vaccine && { value: vaccine.id, label: vaccine.name }
                }
                filterOption={(candidate, input) => {
                  if (input) {
                    return new RegExp(
                      `.*${input
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')}.*`,
                      'i'
                    ).test(
                      candidate.label
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                    );
                  }
                  return true;
                }}
                options={(data as Array<SituationDTO | VaccineDTO>).map(
                  (item) => ({
                    value: item.id,
                    label: item.name,
                  })
                )}
                onChange={(e: any) => {
                  type === 'va'
                    ? setVaccine(
                        (data as Array<VaccineDTO>).filter(
                          (item) => item.id === e.value
                        )[0]
                      )
                    : setSelected(
                        (data as Array<SituationDTO>).filter(
                          (item) => item.id === e.value
                        )[0]
                      );
                }}
                blurInputOnSelect={false}
                {...AutoCompleteStyle}
                placeholder={`${t('Search')}...`}
              />
            )}
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {date === null || editOn === true ? (
              <StyledDatePicker
                fullWidth
                openTo="year"
                views={type === 'ye' ? ['year'] : ['year', 'month']}
                value={date}
                onChange={(date) => setDate(date as Moment)}
                label={type === 'va' ? t('IntakeDate') : t('ApDate')}
                showTodayButton
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodaySharp />
                    </InputAdornment>
                  ),
                }}
                disableFuture
              />
            ) : (
              <Typography style={isBelowXs ? { fontSize: 14 } : {}}>
                {date.format(type === 'ye' ? 'YYYY' : DateFormats.MONTH)}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            {(item || type !== 'va') && (
              <Checkbox
                value={checkbox}
                checked={checkbox}
                disabled={!editOn && (item !== undefined || va !== undefined)}
                onChange={() => setCheckbox(!checkbox)}
                color="primary"
              />
            )}
          </Grid>
          <Grid
            item
            xs={2}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <IconButton
              style={{ marginRight: 8 }}
              disabled={
                item === undefined &&
                va === undefined &&
                ((selected === null && vaccine === null) || date === null)
              }
              onClick={() => {
                (item || va) && editOn === false
                  ? setEditOn(true)
                  : (item &&
                      editOn === true &&
                      item.situation === selected &&
                      ((item.dateDeclared === null && date === null) ||
                        item.dateDeclared ===
                          date?.format(DateFormats.ISONoTime)) &&
                      item.valid === checkbox) ||
                    (va &&
                      editOn === true &&
                      va.vaccine === vaccine &&
                      ((va.date === null && date === null) ||
                        va.date === date?.format(DateFormats.ISONoTime)))
                  ? setEditOn(false)
                  : item &&
                    editOn === true &&
                    (item.situation !== selected ||
                      item.dateDeclared !==
                        date?.format(DateFormats.ISONoTime) ||
                      item.valid !== checkbox)
                  ? situationEdit(item.id)
                  : va &&
                    editOn === true &&
                    (va.vaccine !== vaccine ||
                      va.date !== date?.format(DateFormats.ISONoTime))
                  ? editVaccine(va.id)
                  : type === 'va'
                  ? addVaccine()
                  : situationAdd();
              }}
            >
              {((item || va) && editOn === false) ||
              (item &&
                editOn === true &&
                item.situation === selected &&
                ((item.dateDeclared === null && date === null) ||
                  item.dateDeclared === date?.format(DateFormats.ISONoTime)) &&
                item.valid === checkbox) ||
              (va &&
                editOn === true &&
                va.vaccine === vaccine &&
                ((va.vaccine === null && date === null) ||
                  va.date === date?.format(DateFormats.ISONoTime))) ? (
                <Edit />
              ) : (
                <Save />
              )}
            </IconButton>
            <IconButton
              onClick={() => {
                if ((!item && type !== 'va') || (!va && type === 'va')) {
                  if (!editOn) {
                    setDate(null);
                    setSelected(null);
                    setVaccine(null);
                    setCheckbox(false);
                  }
                } else if (item) situationDelete(item.id);
                else if (va) deleteVaccine(va.id);
              }}
            >
              {(!item && type !== 'va') || (!va && type === 'va') ? (
                <RefreshIcon
                  style={{
                    fontWeight: 'bold',
                    transform: 'rotateY(180deg)',
                  }}
                />
              ) : (
                <Delete />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );
};

interface HospitalListItemProps {
  item?: UserHospitalizationDTO;
  onUpdate: () => void;
}

const HospitalListItem = ({ item, onUpdate }: HospitalListItemProps) => {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [type, setType] = useState<UserHospitalizationDTOType | number>(0);
  const [time, setTime] = useState<Moment | null>(null);
  const [endTime, setEndTime] = useState<Moment | null>(null);
  const [editOn, setEditOn] = useState(false);
  const [error, setError] = useState(false);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down(600));
  const createMutation = useSituationControllerCreateMyHospital();
  const updateMutation = useSituationControllerUpdateMyHospital();
  const deleteMutation = useSituationControllerDeleteMyHospital();

  const handleReset = async () => {
    setText('');
    setType(0);
    setTime(null);
    setEndTime(null);
    setEditOn(false);
  };

  const addHospital = async () => {
    try {
      if (
        type !== 0 &&
        ((type === CreateHospitalizationType.Surgery &&
          time &&
          endTime !== null) ||
          time)
      ) {
        const resp = await createMutation.mutateAsync({
          data: {
            type: type as unknown as CreateHospitalizationType,
            comment: text,
            time: time.format(DateFormats.ISONoTime),
            endTime: endTime?.format(DateFormats.ISONoTime) ?? undefined,
          },
        });
        if (resp) {
          toast(t('Added'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const editHospital = async (id: number) => {
    try {
      if (
        type !== 0 &&
        ((type === CreateHospitalizationType.Surgery &&
          time &&
          endTime !== null) ||
          time)
      ) {
        const resp = await updateMutation.mutateAsync({
          id,
          data: {
            type: type as unknown as CreateHospitalizationType,
            comment: text,
            time: time.format(DateFormats.ISONoTime),
            endTime: endTime?.format(DateFormats.ISONoTime) ?? undefined,
          },
        });
        if (resp) {
          toast(t('Updated'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const deleteHospital = async (id: number) => {
    try {
      const r = await deleteMutation.mutateAsync({ id });
      if (r) {
        toast(t('Deleted'));
        onUpdate();
        handleReset();
      } else {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Deleted'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (item !== undefined) {
      setTime(moment(item.time));
      setEndTime(moment(item.endTime));
      setText(item.comment);
      setType(item.type);
    }
  }, [item]);

  useEffect(() => {
    if (time !== null && endTime !== null && endTime.isBefore(time, 'day'))
      setError(true);
    else setError(false);
  }, [time]);

  useEffect(() => {
    if (time !== null && endTime !== null && endTime.isBefore(time, 'day'))
      setError(true);
    else setError(false);
  }, [endTime]);

  return (
    <ListItem>
      <ListItemText>
        <Grid
          container
          spacing={4}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={2} style={{ display: 'flex' }}>
            {item && type && editOn === false ? (
              <Typography
                style={{
                  color: Colors.Black,
                  fontSize: isBelowXs ? 14 : 16,
                }}
              >
                {item.type === UserHospitalizationDTOType.Chemotherapy
                  ? t('Chemotherapy')
                  : item.type === UserHospitalizationDTOType.Dialysis
                  ? t('Dialysis')
                  : item.type === UserHospitalizationDTOType.Hospitalization
                  ? t('Hospitaly')
                  : t('Surgery')}
              </Typography>
            ) : (
              <SelectAs
                value={type}
                onChange={(e) =>
                  setType(e.target.value as UserHospitalizationDTOType)
                }
              >
                <MenuItem value={0}>{t('ChooseType')}</MenuItem>
                {Object.values(UserHospitalizationDTOType).map((item, i) => (
                  <MenuItem value={item} key={i}>
                    {item === UserHospitalizationDTOType.Chemotherapy
                      ? t('Chemotherapy')
                      : item === UserHospitalizationDTOType.Dialysis
                      ? t('Dialysis')
                      : item === UserHospitalizationDTOType.Hospitalization
                      ? t('Hospitaly')
                      : t('Surgery')}
                  </MenuItem>
                ))}
              </SelectAs>
            )}
          </Grid>
          <Grid item xs={2}>
            {time === null || editOn === true || error === true ? (
              <StyledDatePicker
                fullWidth
                value={time}
                onChange={(date) => setTime(date as Moment)}
                label={t('StartDate')}
                showTodayButton
                error={error}
                helperText={
                  error &&
                  t('StartDate').concat(t('MustSooner')).concat(t('EndDate'))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodaySharp />
                    </InputAdornment>
                  ),
                }}
                disableFuture
              />
            ) : (
              <Typography style={isBelowXs ? { fontSize: 14 } : {}}>
                {time.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            {endTime === null || editOn === true || error === true ? (
              <StyledDatePicker
                fullWidth
                value={endTime}
                onChange={(date) => setEndTime(date as Moment)}
                label={t('EndDate')}
                showTodayButton
                error={error}
                helperText={
                  error &&
                  t('StartDate').concat(t('MustSooner')).concat(t('EndDate'))
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodaySharp />
                    </InputAdornment>
                  ),
                }}
                disableFuture
              />
            ) : (
              <Typography style={isBelowXs ? { fontSize: 14 } : {}}>
                {endTime.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
            {item && type && editOn === false ? (
              <Typography
                style={{ color: Colors.Black, fontSize: isBelowXs ? 14 : 16 }}
              >
                {text}
              </Typography>
            ) : (
              <TextField
                style={{ width: '100%' }}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            )}
          </Grid>
          <Grid
            item
            xs={2}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <IconButton
              style={{ marginRight: 8 }}
              disabled={
                (item === undefined && type === 0) ||
                (type === CreateHospitalizationType.Surgery &&
                  (time === null || endTime === null)) ||
                time === null ||
                error === true
              }
              onClick={() => {
                item && editOn === false
                  ? setEditOn(true)
                  : item &&
                    editOn === true &&
                    item.type === type &&
                    ((item.time === null && time === null) ||
                      item.time === time?.format(DateFormats.ISONoTime)) &&
                    ((item.endTime === null && endTime === null) ||
                      item.endTime ===
                        endTime?.format(DateFormats.ISONoTime)) &&
                    ((item.comment.length === 0 && text.length === 0) ||
                      item.comment === text)
                  ? setEditOn(false)
                  : item &&
                    editOn === true &&
                    (item.type !== type ||
                      item.time !== time?.format(DateFormats.ISONoTime) ||
                      item.endTime !== endTime?.format(DateFormats.ISONoTime) ||
                      item.comment !== text)
                  ? editHospital(item.id)
                  : addHospital();
              }}
            >
              {(item && editOn === false) ||
              (item &&
                editOn === true &&
                item.type === type &&
                ((item.time === null && time === null) ||
                  item.time === time?.format(DateFormats.ISONoTime)) &&
                ((item.endTime === null && endTime === null) ||
                  item.endTime === endTime?.format(DateFormats.ISONoTime)) &&
                ((item.comment.length === 0 && text.length === 0) ||
                  item.comment === text)) ? (
                <Edit />
              ) : (
                <Save />
              )}
            </IconButton>
            <IconButton
              onClick={() => {
                if (!item) {
                  setTime(null);
                  setEndTime(null);
                  setType(0);
                  setText('');
                  setError(false);
                } else if (item) deleteHospital(item.id);
              }}
            >
              {!item ? (
                <RefreshIcon
                  style={{
                    fontWeight: 'bold',
                    transform: 'rotateY(180deg)',
                  }}
                />
              ) : (
                <Delete />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );
};

interface SystemListItemProps {
  item?: UserBodySystemDTO;
  data: BodySystemDTO[];
  onUpdate: () => void;
}

const SystemListItem = ({ item, data, onUpdate }: SystemListItemProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<BodySystemDTO | number>(0);
  const [text, setText] = useState('');
  const [editOn, setEditOn] = useState(false);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down(600));
  const createMutation = useSituationControllerCreateMyBodySystem();
  const updateMutation = useSituationControllerUpdateMyBodySystem();
  const deleteMutation = useSituationControllerDeleteMyBodySystem();

  const handleReset = async () => {
    setSelected(data[0]);
    setText('');
    setEditOn(false);
  };

  const addSystem = async () => {
    try {
      if (typeof selected !== 'number') {
        const resp = await createMutation.mutateAsync({
          data: {
            bodySystem: selected,
            comment: text,
          },
        });
        if (resp) {
          toast(t('Added'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const editSystem = async (id: number) => {
    try {
      if (typeof selected !== 'number') {
        const resp = await updateMutation.mutateAsync({
          id,
          data: {
            bodySystem: selected,
            comment: text,
          },
        });
        if (resp) {
          toast(t('Updated'));
          onUpdate();
          handleReset();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const deleteSystem = async (id: number) => {
    try {
      const r = await deleteMutation.mutateAsync({ id });
      if (r) {
        toast(t('Deleted'));
        onUpdate();
        handleReset();
      } else {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Deleted'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (item !== undefined) {
      setSelected(item.bodySystem);
      setText(item.comment);
    }
  }, [item]);

  return (
    <ListItem>
      <ListItemText>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            {item && typeof selected !== 'number' && editOn === false ? (
              <Typography
                style={{
                  color: Colors.Black,
                  fontSize: isBelowXs ? 14 : 16,
                }}
              >
                {selected && selected.system}
              </Typography>
            ) : (
              <SelectAs
                value={typeof selected === 'number' ? selected : selected.id}
                onChange={(e) =>
                  setSelected(
                    (e.target.value as number) !== 0
                      ? data.filter(
                          (item) => item.id === (e.target.value as number)
                        )[0]
                      : 0
                  )
                }
              >
                {item === undefined && (
                  <MenuItem value={0}>{t('ChooseSystem')}</MenuItem>
                )}
                {data.map((item, i) => (
                  <MenuItem value={item.id} key={i}>
                    {item.system}
                  </MenuItem>
                ))}
              </SelectAs>
            )}
          </Grid>
          <Grid item xs={4}>
            {item && typeof selected !== 'number' && editOn === false ? (
              <Typography
                style={{ color: Colors.Black, fontSize: isBelowXs ? 14 : 16 }}
              >
                {text}
              </Typography>
            ) : (
              <TextField
                style={{ width: '100%' }}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={2} />
          <Grid
            item
            xs={2}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <IconButton
              style={{ marginRight: 8 }}
              disabled={item === undefined && selected === 0}
              onClick={() => {
                item && editOn === false
                  ? setEditOn(true)
                  : item &&
                    editOn === true &&
                    item.bodySystem === selected &&
                    ((item.comment.length === 0 && text.length === 0) ||
                      item.comment === text)
                  ? setEditOn(false)
                  : item &&
                    editOn === true &&
                    (item.bodySystem !== selected || item.comment !== text)
                  ? editSystem(item.id)
                  : addSystem();
              }}
            >
              {(item && editOn === false) ||
              (item &&
                editOn === true &&
                item.bodySystem === selected &&
                ((item.comment.length === 0 && text.length === 0) ||
                  item.comment === text)) ? (
                <Edit />
              ) : (
                <Save />
              )}
            </IconButton>
            <IconButton
              onClick={() => {
                if (!item) {
                  setSelected(0);
                  setText('');
                } else if (item) deleteSystem(item.id);
              }}
            >
              {editOn || !item ? (
                <RefreshIcon
                  style={{
                    fontWeight: 'bold',
                    transform: 'rotateY(180deg)',
                  }}
                />
              ) : (
                <Delete />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </ListItemText>
    </ListItem>
  );
};
