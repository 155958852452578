import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';

export const Feedback1 = () => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1600));
  const isUpMd = useMediaQuery(theme.breakpoints.up(1499));
  return (
    <svg
      width={isBelowLg && isUpMd ? '48' : '52'}
      height={isBelowLg && isUpMd ? '48' : '52'}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39 39C46.1797 31.8203 46.1797 20.1797 39 13C31.8203 5.8203 20.1797 5.82029 13 13C5.82031 20.1797 5.8203 31.8203 13 39C20.1797 46.1797 31.8203 46.1797 39 39Z"
        fill="#84B840"
      />
      <path
        d="M35.6214 10.2979C37.4556 13.1679 38.5129 16.5557 38.5129 20.2025C38.5129 30.366 30.2699 38.5874 20.1279 38.5874C16.6106 38.5874 13.3091 37.5948 10.5039 35.8685C13.7623 40.9611 19.5022 44.3489 25.9973 44.3489C36.1609 44.3489 44.3823 36.1059 44.3823 25.964C44.3823 19.3609 40.865 13.5347 35.6214 10.2979Z"
        fill="#73AA43"
      />
      <path
        d="M10.1012 27.9491C10.1012 18.5193 17.7401 10.8805 27.1699 10.8805C32.6725 10.8805 37.5924 13.4915 40.6997 17.5483C37.7434 12.4341 32.2193 8.98157 25.8968 8.98157C16.4669 8.98157 8.82812 16.6204 8.82812 26.0502C8.82812 29.9775 10.1444 33.5811 12.367 36.4511C10.9212 33.948 10.1012 31.0349 10.1012 27.9491Z"
        fill="#93C23D"
      />
      <path
        d="M37.3925 31.5752C36.8962 29.9784 35.7094 28.9642 34.3715 28.9642C33.8536 28.9642 33.3573 29.1153 32.9042 29.3958C32.5805 29.6116 32.3215 29.9137 32.0626 30.1942C31.9331 30.3452 31.8252 30.4747 31.7173 30.5826C31.48 30.82 31.1563 31.0357 30.811 31.1652C30.1205 31.4242 29.0847 31.23 28.5237 30.4316C27.8763 29.4821 26.9485 28.9426 25.9774 28.9426C25.2654 28.9426 24.5964 29.2447 23.9922 29.8489C23.8412 29.9784 23.7333 30.151 23.6254 30.2805C23.5175 30.41 23.4312 30.5394 23.3233 30.6473C23.1291 30.8415 22.8701 30.971 22.5896 31.1005C22.2443 31.2731 21.8775 31.3163 21.5322 31.2731C21.0791 31.2084 20.6044 30.9494 20.2591 30.5826C20.1512 30.4747 20.0433 30.3452 19.9138 30.2158C19.6549 29.9137 19.3744 29.59 19.0291 29.3742C18.576 29.0937 18.0797 28.9426 17.5834 28.9426C16.2671 28.9426 15.0803 29.9352 14.5839 31.4889C14.4976 31.7694 14.6055 32.0931 14.8645 32.2226C15.0803 32.3305 15.3392 32.2873 15.5118 32.0715C16.1376 31.2731 17.195 30.561 18.2523 30.9279C18.7702 31.1005 19.2018 31.5752 19.547 32.0068C20.0649 32.611 20.7338 33.021 21.4243 33.1073C21.5322 33.1289 21.6617 33.1289 21.7912 33.1289C22.6543 33.1289 23.4743 32.6973 24.0785 31.9205L24.1864 31.7694C24.4022 31.5105 24.5533 31.2947 24.8122 31.1436C25.1143 30.9494 25.438 30.8415 25.7401 30.7984C26.0853 30.7552 26.4306 30.82 26.7758 30.9926C26.9485 31.0789 27.1211 31.1868 27.2937 31.3163C27.4664 31.4673 27.639 31.6615 27.8116 31.8557C27.9843 32.0715 28.1784 32.2873 28.4158 32.4815C28.9337 32.8915 29.5379 33.1289 30.1637 33.1289C30.8542 33.1289 31.5016 32.8484 32.0626 32.3305C32.1489 32.2442 32.3 32.0931 32.4726 31.9205C32.6884 31.6831 33.0984 31.2515 33.2063 31.1868C33.9831 30.6689 34.8247 30.6905 35.5799 31.2515C35.882 31.4673 36.1841 31.7694 36.4862 32.1363C36.6373 32.3305 36.8962 32.3952 37.1336 32.2873C37.3493 32.1794 37.4788 31.8557 37.3925 31.5752Z"
        fill="#5E4939"
      />
      <path
        d="M15.2605 19.0582L14.0703 20.2484L19.426 25.604L20.6162 24.4139L15.2605 19.0582Z"
        fill="#303030"
      />
      <path
        d="M20.6198 20.2398L19.4297 19.0496L14.074 24.4053L15.2641 25.5954L20.6198 20.2398Z"
        fill="#303030"
      />
      <path
        d="M32.5456 19.0597L31.3555 20.2499L36.7112 25.6056L37.9013 24.4154L32.5456 19.0597Z"
        fill="#303030"
      />
      <path
        d="M37.905 20.2413L36.7148 19.0511L31.3592 24.4069L32.5493 25.597L37.905 20.2413Z"
        fill="#303030"
      />
    </svg>
  );
};

export const Feedback2 = () => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1600));
  const isUpMd = useMediaQuery(theme.breakpoints.up(1499));
  return (
    <svg
      width={isBelowLg && isUpMd ? '48' : '52'}
      height={isBelowLg && isUpMd ? '48' : '52'}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.5435 28.5523C45.1625 18.654 38.475 9.3415 28.6065 7.75236C18.738 6.16323 9.42557 12.8992 7.80653 22.7976C6.18749 32.696 12.875 42.0085 22.7435 43.5976C32.612 45.1867 41.9244 38.4507 43.5435 28.5523Z"
        fill="#FFD05D"
      />
      <path
        d="M35.1054 10.2154C36.9107 13.0505 37.9514 16.3971 37.9514 19.9996C37.9514 30.0395 29.838 38.161 19.8555 38.161C16.3935 38.161 13.1439 37.1805 10.3828 35.4752C13.5899 40.5058 19.2396 43.8525 25.6326 43.8525C35.6364 43.8525 43.7285 35.7097 43.7285 25.691C43.7285 19.1469 40.2665 13.3915 35.1054 10.2154Z"
        fill="#FDC453"
      />
      <path
        d="M9.98357 27.6321C9.98357 18.3169 17.5023 10.7709 26.7839 10.7709C32.1999 10.7709 37.0425 13.3502 40.1009 17.3576C37.1911 12.3057 31.7539 8.89508 25.5308 8.89508C16.2492 8.89508 8.73047 16.4411 8.73047 25.7563C8.73047 29.6358 10.026 33.1956 12.2137 36.0307C10.7907 33.558 9.98357 30.7016 9.98357 27.6321Z"
        fill="#FFD77F"
      />
      <path
        d="M21.5885 23.0445C21.959 20.779 20.4284 18.6477 18.1698 18.2839C15.9112 17.9202 13.7798 19.4619 13.4092 21.7274C13.0387 23.9929 14.5693 26.1243 16.8279 26.488C19.0865 26.8517 21.2179 25.31 21.5885 23.0445Z"
        fill="white"
      />
      <path
        d="M20.4069 19.4273C19.6635 18.6812 18.6228 18.2336 17.4971 18.2336C16.7325 18.2336 16.0103 18.4467 15.3944 18.8091C15.267 18.9796 15.182 19.1502 15.1608 19.342C15.0758 20.216 16.2015 21.026 17.6458 21.1539C19.1113 21.2818 20.3644 20.7063 20.4281 19.8323C20.4706 19.7044 20.4494 19.5765 20.4069 19.4273Z"
        fill="#303030"
      />
      <path
        d="M37.7865 23.3737C38.3122 21.1395 36.9337 18.9008 34.7076 18.3732C32.4815 17.8457 30.2508 19.2291 29.7252 21.4633C29.1996 23.6974 30.578 25.9362 32.8041 26.4637C35.0302 26.9913 37.2609 25.6078 37.7865 23.3737Z"
        fill="white"
      />
      <path
        d="M36.6647 19.4245C35.9213 18.6785 34.8806 18.2308 33.7549 18.2308C32.9903 18.2308 32.2681 18.444 31.6522 18.8064C31.5248 18.9769 31.4398 19.1474 31.4186 19.3393C31.3336 20.2132 32.4593 21.0233 33.9036 21.1512C35.3479 21.2791 36.6222 20.7035 36.6859 19.8296C36.7284 19.7017 36.7072 19.5738 36.6647 19.4245Z"
        fill="#303030"
      />
      <path
        d="M32.3712 29.8693C31.5004 29.0166 30.3322 28.3771 28.9517 28.0361C27.6985 27.7377 26.3392 27.7163 25.0436 27.9721C23.7693 28.2279 22.5374 28.7395 21.4542 29.4643C20.3072 30.253 19.4365 31.1909 18.8205 32.2354C18.7356 32.4059 18.7568 32.5978 18.8842 32.747C18.9692 32.8323 19.0754 32.8749 19.2028 32.8749C19.2665 32.8749 19.3302 32.8536 19.394 32.8322L19.7975 32.6404C20.732 32.1714 21.6028 31.7451 22.4949 31.3827C23.5144 30.9777 24.5339 30.6793 25.5321 30.4661C26.5729 30.2743 27.6348 30.1677 28.6755 30.189C29.6525 30.2103 30.672 30.3169 32.0101 30.5727C32.2013 30.6154 32.3712 30.5088 32.4561 30.3596C32.5411 30.2103 32.4986 30.0185 32.3712 29.8693Z"
        fill="#5E4939"
      />
    </svg>
  );
};

export const Feedback3 = () => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1600));
  const isUpMd = useMediaQuery(theme.breakpoints.up(1499));
  return (
    <svg
      width={isBelowLg && isUpMd ? '48' : '52'}
      height={isBelowLg && isUpMd ? '48' : '52'}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 43.875C35.8721 43.875 43.875 35.8721 43.875 26C43.875 16.1279 35.8721 8.125 26 8.125C16.1279 8.125 8.125 16.1279 8.125 26C8.125 35.8721 16.1279 43.875 26 43.875Z"
        fill="#FFD05D"
      />
      <path
        d="M43.8716 26.0005C43.8716 35.8765 35.8669 43.8755 25.9966 43.8755C20.3771 43.8755 15.361 41.2837 12.082 37.2227C15.1487 39.7028 19.0533 41.1831 23.3042 41.1831C33.1745 41.1831 41.1792 33.184 41.1792 23.3081C41.1792 19.0572 39.6989 15.1526 37.2187 12.0859C41.2741 15.3649 43.8716 20.3811 43.8716 26.0005Z"
        fill="#FDC453"
      />
      <path
        d="M34.7083 35.8752C34.5418 36.4416 33.9363 36.8773 33.1878 36.9142C28.4006 37.1745 23.6012 37.1745 18.8141 36.9142C18.0655 36.8773 17.46 36.4405 17.2936 35.8752C16.8657 34.3726 16.8657 32.8688 17.2936 31.3662C17.46 30.7998 18.0655 30.3641 18.8141 30.3272C23.6012 30.0669 28.4006 30.0669 33.1878 30.3272C33.9363 30.3641 34.5418 30.8009 34.7083 31.3662C35.1362 32.87 35.1362 34.3726 34.7083 35.8752Z"
        fill="#5E4939"
      />
      <path
        d="M26.0021 32.7147C29.6263 32.7147 32.7566 32.6923 34.2738 30.7484C33.9934 30.5127 33.6213 30.3485 33.189 30.3272C28.4018 30.0669 23.6024 30.0669 18.8153 30.3272C18.3829 30.3485 18.0109 30.5127 17.7305 30.7495C19.2487 32.6923 22.3791 32.7147 26.0021 32.7147Z"
        fill="white"
      />
      <path
        d="M33.1879 36.9151C33.6068 36.8939 33.971 36.7408 34.2481 36.5163C32.7209 34.6014 29.605 34.5836 26.001 34.5836C22.3969 34.5836 19.2811 34.6025 17.7539 36.5163C18.031 36.7397 18.3952 36.8939 18.8141 36.9151C23.6013 37.1743 28.4007 37.1743 33.1879 36.9151Z"
        fill="white"
      />
      <path
        d="M36.8673 26.612C36.1546 25.0647 34.6006 24.0637 32.9069 24.0637C31.8623 24.0637 30.8524 24.4402 30.0625 25.1251C29.6537 25.4781 29.0381 25.4356 28.6839 25.0267C28.3309 24.619 28.3745 24.0012 28.7822 23.6481C29.9274 22.6549 31.3931 22.1086 32.9069 22.1086C35.3614 22.1086 37.6136 23.5554 38.6437 25.7942C38.8693 26.2847 38.6548 26.8645 38.1644 27.0913C37.6728 27.317 37.0919 27.1002 36.8673 26.612Z"
        fill="#3E4347"
      />
      <path
        d="M13.8384 27.0913C13.3491 26.8656 13.1334 26.2847 13.3591 25.7942C14.3892 23.5554 16.6414 22.1086 19.0959 22.1086C20.6097 22.1086 22.0754 22.6549 23.2205 23.6481C23.6283 24.0012 23.6719 24.619 23.3188 25.0267C22.9658 25.4356 22.348 25.4781 21.9402 25.1251C21.1504 24.4402 20.1404 24.0637 19.0959 24.0637C17.4022 24.0637 15.8482 25.0647 15.1354 26.612C14.9109 27.1002 14.33 27.317 13.8384 27.0913Z"
        fill="#3E4347"
      />
      <path
        d="M23.9702 19.3173C24.0417 19.772 23.7289 20.201 23.2642 20.2702C21.2264 20.6065 18.134 19.8155 16.0415 17.5018C15.7343 17.1611 15.7511 16.6248 16.103 16.3199C16.4426 16.0126 16.9688 16.0272 17.2839 16.3813C19.7182 19.0637 22.9927 18.6157 23.0161 18.6101C23.4831 18.5408 23.9121 18.8536 23.9702 19.3173Z"
        fill="#5E4939"
      />
      <path
        d="M35.8158 19.7956C32.9804 20.4893 30.1405 19.6481 28.2737 18.0327C27.9374 17.7176 27.9195 17.1869 28.2234 16.8473C28.5418 16.501 29.0725 16.482 29.4087 16.797C31.1024 18.1567 33.2508 18.6963 35.4159 18.1712C35.875 18.055 36.3186 18.3376 36.428 18.7834C36.5375 19.228 36.2605 19.6861 35.8158 19.7956Z"
        fill="#5E4939"
      />
    </svg>
  );
};

export const Feedback4 = () => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1600));
  const isUpMd = useMediaQuery(theme.breakpoints.up(1499));
  return (
    <svg
      width={isBelowLg && isUpMd ? '48' : '52'}
      height={isBelowLg && isUpMd ? '48' : '52'}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39 39C46.1797 31.8203 46.1797 20.1797 39 13C31.8203 5.8203 20.1797 5.8203 13 13C5.8203 20.1797 5.8203 31.8203 13 39C20.1797 46.1797 31.8203 46.1797 39 39Z"
        fill="#FFD05D"
      />
      <path
        d="M35.6331 10.3032C37.4673 13.1732 38.5246 16.561 38.5246 20.2078C38.5246 30.3713 30.2816 38.5927 20.1397 38.5927C16.6224 38.5927 13.3208 37.6001 10.5156 35.8738C13.774 40.9664 19.5139 44.3542 26.009 44.3542C36.1726 44.3542 44.394 36.1112 44.394 25.9693C44.4156 19.3446 40.8983 13.54 35.6331 10.3032Z"
        fill="#FDC453"
      />
      <path
        d="M10.1208 27.9331C10.1208 18.5033 17.7596 10.8645 27.1894 10.8645C32.692 10.8645 37.6119 13.4755 40.7192 17.5323C37.7845 12.4181 32.2604 8.96558 25.9163 8.96558C16.4865 8.96558 8.84766 16.6044 8.84766 26.0342C8.84766 29.9615 10.1639 33.5652 12.3865 36.4351C10.9408 33.9536 10.1208 31.0405 10.1208 27.9331Z"
        fill="#FFD77F"
      />
      <path
        d="M20.5939 21.5468C20.5939 22.7983 19.9897 23.8341 19.2345 23.8341C18.4792 23.8341 17.875 22.8199 17.875 21.5468C17.875 20.2952 18.4792 19.2595 19.2345 19.2595C19.9897 19.2595 20.5939 20.2737 20.5939 21.5468Z"
        fill="#303030"
      />
      <path
        d="M34.1447 21.5454C34.1447 22.797 33.5405 23.8328 32.7852 23.8328C32.03 23.8328 31.4258 22.8186 31.4258 21.5454C31.4258 20.2939 32.03 19.2581 32.7852 19.2581C33.5405 19.2581 34.1447 20.2723 34.1447 21.5454Z"
        fill="#303030"
      />
      <path
        d="M13.6602 27.7399C14.3507 33.9546 19.6158 38.7666 26.0247 38.7666C32.4119 38.7666 37.6771 33.933 38.3892 27.7399H13.6602Z"
        fill="#695342"
      />
      <path
        d="M17.4805 35.3785C19.7031 37.4932 22.7025 38.7664 26.0256 38.7664C29.1976 38.7664 32.0676 37.5795 34.2686 35.6375L34.1607 35.3785C25.853 31.3217 17.5884 35.3138 17.4805 35.3785Z"
        fill="#F06669"
      />
    </svg>
  );
};

export const Feedback5 = () => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1600));
  const isUpMd = useMediaQuery(theme.breakpoints.up(1499));
  return (
    <svg
      width={isBelowLg && isUpMd ? '48' : '52'}
      height={isBelowLg && isUpMd ? '48' : '52'}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39 39C46.1797 31.8203 46.1797 20.1797 39 13C31.8203 5.82031 20.1797 5.8203 13 13C5.8203 20.1797 5.8203 31.8203 13 39C20.1797 46.1797 31.8203 46.1797 39 39Z"
        fill="#FFD05D"
      />
      <path
        d="M35.6214 10.3059C37.4556 13.1759 38.5129 16.5637 38.5129 20.2105C38.5129 30.374 30.2699 38.5954 20.128 38.5954C16.6107 38.5954 13.3091 37.6028 10.5039 35.8765C13.7623 40.9691 19.5022 44.3569 25.9973 44.3569C36.1609 44.3569 44.3823 36.1139 44.3823 25.972C44.4039 19.3473 40.8866 13.5427 35.6214 10.3059Z"
        fill="#FDC453"
      />
      <path
        d="M10.1208 27.9358C10.1208 18.506 17.7596 10.8672 27.1895 10.8672C32.692 10.8672 37.6119 13.4782 40.7192 17.535C37.763 12.4208 32.2388 8.96826 25.9163 8.96826C16.4865 8.96826 8.84766 16.6071 8.84766 26.0369C8.84766 29.9642 10.1639 33.5679 12.3865 36.4378C10.9408 33.9347 10.1208 31.0432 10.1208 27.9358Z"
        fill="#FFD77F"
      />
      <path
        d="M18.1914 29.7685C18.6446 33.6958 21.9677 36.7384 26.0029 36.7384C30.0381 36.7384 33.3827 33.6958 33.8143 29.7685H18.1914Z"
        fill="#695342"
      />
      <path
        d="M20.6094 34.5813C22.012 35.9191 23.9109 36.7391 26.004 36.7391C28.0108 36.7391 29.8234 35.9839 31.226 34.7539L31.1613 34.5813C25.8961 32.035 20.6741 34.5597 20.6094 34.5813Z"
        fill="#F06669"
      />
      <path
        d="M23.4716 20.6409V20.6193C23.4716 18.9146 22.2416 17.361 20.3859 17.361C18.9185 17.361 18.077 18.4183 17.7749 19.4109C17.4943 18.4183 16.6312 17.361 15.1639 17.361C13.3081 17.361 12.0781 18.9146 12.0781 20.6193V20.6409C12.0781 21.0077 12.0781 24.7624 17.7533 27.8266C23.4716 24.784 23.4716 21.0077 23.4716 20.6409Z"
        fill="#E62728"
      />
      <path
        d="M39.9208 20.6406V20.619C39.9208 18.9143 38.6909 17.3607 36.8351 17.3607C35.3678 17.3607 34.5262 18.418 34.2241 19.4106C33.9436 18.418 33.0804 17.3607 31.6131 17.3607C29.7573 17.3607 28.5273 18.9143 28.5273 20.619V20.6406C28.5273 21.0074 28.5274 24.7621 34.2025 27.8263C39.9208 24.7837 39.9208 21.0074 39.9208 20.6406Z"
        fill="#E62728"
      />
    </svg>
  );
};

export const Feedback6 = () => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1600));
  const isUpMd = useMediaQuery(theme.breakpoints.up(1499));
  return (
    <svg
      width={isBelowLg && isUpMd ? '48' : '52'}
      height={isBelowLg && isUpMd ? '48' : '52'}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 43.875C35.8721 43.875 43.875 35.8721 43.875 26C43.875 16.1279 35.8721 8.125 26 8.125C16.1279 8.125 8.125 16.1279 8.125 26C8.125 35.8721 16.1279 43.875 26 43.875Z"
        fill="#FFB45D"
      />
      <path
        d="M28.5947 41.2804C18.7226 41.2804 10.7197 33.2775 10.7197 23.4054C10.7197 19.1303 12.2224 15.2072 14.726 12.1313C10.6989 15.4089 8.125 20.4031 8.125 26.0001C8.125 35.8722 16.1279 43.8751 26 43.8751C31.597 43.8751 36.5913 41.3011 39.8689 37.2741C36.7929 39.7776 32.8699 41.2804 28.5947 41.2804Z"
        fill="#C97B21"
      />
      <path
        d="M32.3333 9.28369L26.9463 12.3139C26.5119 12.5582 26.0101 12.6553 25.516 12.5906L15.8076 11.319C14.5205 12.2142 13.3571 13.2735 12.3472 14.4679H23.117L8.42881 22.73C8.2326 23.7907 8.125 24.8826 8.125 26.0002C8.125 26.9575 8.20223 27.8967 8.34718 28.8132L39.74 14.5685C37.7852 12.2217 35.2402 10.3857 32.3333 9.28369Z"
        fill="white"
      />
      <path
        d="M36.3779 23.2618C36.321 23.2618 36.263 23.255 36.205 23.241C32.6609 22.3682 30.2401 20.2904 30.1388 20.202C29.8381 19.9413 29.8065 19.4868 30.0667 19.1862C30.3274 18.8855 30.783 18.8528 31.0831 19.1136C31.1056 19.1327 33.3563 21.0545 36.5496 21.8412C36.9364 21.9364 37.1724 22.3272 37.0772 22.7135C36.9962 23.0422 36.7017 23.2618 36.3779 23.2618Z"
        fill="#5E4939"
      />
      <path
        d="M31.1889 35.9453C31.0549 35.9453 30.9191 35.9082 30.7981 35.8299C28.334 34.2375 23.739 34.2155 23.6929 34.2155C23.2953 34.215 22.9727 33.8923 22.9727 33.4942C22.9732 33.0966 23.2953 32.7739 23.6935 32.7739C23.899 32.7739 28.7574 32.7947 31.5808 34.6192C31.9147 34.8354 32.011 35.2814 31.7948 35.6158C31.6568 35.8293 31.4254 35.9453 31.1889 35.9453Z"
        fill="#5E4939"
      />
      <path
        d="M19.9454 27.7311C19.1493 27.7311 18.5039 27.0857 18.5039 26.2896V25.1363C18.5039 24.3402 19.1493 23.6948 19.9454 23.6948C20.7416 23.6948 21.3869 24.3402 21.3869 25.1363V26.2896C21.3869 27.0857 20.7416 27.7311 19.9454 27.7311Z"
        fill="white"
      />
      <path
        d="M19.9446 23.6948C19.8458 23.6948 19.7495 23.7049 19.6562 23.7239V25.713C19.6562 26.1906 20.0435 26.5779 20.5212 26.5779C20.9988 26.5779 21.3861 26.1906 21.3861 25.713V25.1363C21.3861 24.3402 20.7408 23.6948 19.9446 23.6948Z"
        fill="#303030"
      />
      <path
        d="M32.0548 27.7311C31.2587 27.7311 30.6133 27.0857 30.6133 26.2896V25.1363C30.6133 24.3402 31.2587 23.6948 32.0548 23.6948C32.8509 23.6948 33.4963 24.3402 33.4963 25.1363V26.2896C33.4963 27.0857 32.8509 27.7311 32.0548 27.7311Z"
        fill="white"
      />
      <path
        d="M32.0539 23.6948C31.9552 23.6948 31.8588 23.7049 31.7656 23.7239V25.713C31.7656 26.1906 32.1529 26.5779 32.6305 26.5779C33.1082 26.5779 33.4955 26.1906 33.4955 25.713V25.1363C33.4955 24.3402 32.8501 23.6948 32.0539 23.6948Z"
        fill="#303030"
      />
      <path
        d="M10.7197 23.4037C10.7197 22.7167 10.7626 22.0399 10.8382 21.373L8.42881 22.7283C8.2326 23.789 8.125 24.8809 8.125 25.9985C8.125 26.9558 8.20223 27.895 8.34718 28.8116L11.2008 27.5167C10.8895 26.1956 10.7197 24.8199 10.7197 23.4037Z"
        fill="#FFF1C4"
      />
      <path
        d="M11.8398 20.8095H25.9824L39.7389 14.5674C39.3737 14.129 38.9815 13.7148 38.5766 13.3135H25.166L11.8398 20.8095Z"
        fill="#FFF1C4"
      />
      <path
        d="M12.3477 14.4668H23.1174L21.0672 15.62H37.4208L39.7405 14.5674C39.3753 14.129 38.9831 13.7148 38.5782 13.3135H13.4249C13.0511 13.684 12.6877 14.0646 12.3477 14.4668Z"
        fill="#FFF1C4"
      />
      <path
        d="M9.28906 19.6568H42.7147C41.9902 17.749 40.9492 15.9983 39.6547 14.4673H12.349C11.0545 15.9983 10.0135 17.7491 9.28906 19.6568Z"
        fill="white"
      />
      <path
        d="M12.3516 14.4673C11.056 15.9983 10.0143 17.7487 9.28906 19.6568H11.1147C11.5105 17.8042 12.1933 16.0585 13.1146 14.4673C13.1146 14.4673 12.3516 14.4673 12.3516 14.4673Z"
        fill="#FFF1C4"
      />
    </svg>
  );
};

export const Feedback7 = () => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1600));
  const isUpMd = useMediaQuery(theme.breakpoints.up(1499));
  return (
    <svg
      width={isBelowLg && isUpMd ? '48' : '52'}
      height={isBelowLg && isUpMd ? '48' : '52'}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6.67578" y="9" width="35" height="35" rx="17.5" fill="#FFD05D" />
      <path
        d="M41.6758 26.569C41.6758 36.1996 33.8699 44 24.2448 44C18.7649 44 13.8733 41.4725 10.6758 37.5124C13.6663 39.9309 17.4739 41.3745 21.6192 41.3745C31.2444 41.3745 39.0502 33.5741 39.0502 23.9434C39.0502 19.7981 37.6067 15.9905 35.1882 13C39.1428 16.1975 41.6758 21.0891 41.6758 26.569Z"
        fill="#FDC453"
      />
      <path
        d="M33.6669 29.0584C33.5722 29.0459 33.4776 29.0191 33.386 28.9771L28.4504 26.7097C28.1374 26.5659 27.9226 26.2687 27.8844 25.9263C27.8461 25.5839 27.99 25.2467 28.2636 25.0373L32.1876 22.0339C32.6183 21.7042 33.2348 21.786 33.5644 22.2169C33.8941 22.6477 33.8121 23.2641 33.3814 23.5937L30.7527 25.6056L34.2059 27.1919C34.6988 27.4184 34.9148 28.0015 34.6884 28.4944C34.5042 28.8959 34.0831 29.1138 33.6669 29.0584Z"
        fill="#5E4939"
      />
      <path
        d="M14.6638 22.0817C14.751 22.1076 14.8359 22.1468 14.9156 22.1999L19.2159 25.065C19.4886 25.2468 19.6462 25.558 19.6312 25.8854C19.6162 26.2128 19.4308 26.5083 19.1426 26.6643L15.0085 28.9025C14.5546 29.1481 13.9875 28.9795 13.742 28.5256C13.4963 28.0718 13.665 27.5047 14.1188 27.2591L16.8883 25.7599L13.8796 23.7553C13.4501 23.4692 13.3339 22.8891 13.62 22.4596C13.8529 22.1099 14.281 21.9678 14.6638 22.0817Z"
        fill="#5E4939"
      />
      <path
        d="M29.7887 38C29.5618 38 29.3347 37.9109 29.1616 37.7327C26.6881 35.1879 22.6635 35.1878 20.1898 37.7327C19.8434 38.0891 19.2818 38.0891 18.9356 37.7327C18.5892 37.3763 18.5892 36.7985 18.9356 36.4423C22.1007 33.186 27.2508 33.1858 30.416 36.4423C30.7624 36.7987 30.7624 37.3765 30.416 37.7327C30.2426 37.9108 30.0157 38 29.7887 38Z"
        fill="#5E4939"
      />
      <path
        d="M37.2215 2.47606L31.7098 10.6759C31.3516 11.2087 31.675 11.9326 32.3108 12.0214L35.0557 12.4047L32.2777 17.5005C31.861 18.2649 32.7201 19.0903 33.4672 18.6433L43.698 12.5217C44.4206 12.0894 44.1973 10.9887 43.3633 10.8722L40.347 10.451L44.8129 4.63325C45.3574 3.92393 44.9344 2.89082 44.0488 2.76713L38.3512 1.97138C37.9091 1.90963 37.4705 2.10559 37.2215 2.47606Z"
        fill="#F95C5C"
      />
      <path
        d="M15.7057 16.0176C15.5265 16.0885 15.3163 16.0713 15.1461 15.9533L11.5541 13.463C11.3932 13.3514 11.2972 13.1679 11.2975 12.9721C11.2978 12.7763 11.3942 12.5931 11.5555 12.4819L13.5042 11.139L9.2729 8.24134C9.00122 8.05527 8.93174 7.68415 9.11789 7.41243C9.30383 7.14063 9.67508 7.07127 9.9468 7.25741L14.8943 10.6455C15.0563 10.7566 15.1533 10.9402 15.1536 11.1367C15.154 11.3331 15.0574 11.5171 14.8957 11.6286L12.9426 12.9745L15.8256 14.9732C16.0963 15.1608 16.1636 15.5323 15.9759 15.8029C15.9063 15.9034 15.8113 15.9758 15.7057 16.0176Z"
        fill="#F95C5C"
      />
      <path
        d="M17.0861 9.12777C16.8712 9.21274 16.617 9.168 16.4437 8.99373L14.1189 6.65696C13.9809 6.51817 13.9197 6.32031 13.9553 6.12779C13.991 5.93524 14.1189 5.77248 14.2976 5.69231L15.7314 5.0492L14.1664 3.49405C13.9328 3.26197 13.9316 2.88436 14.1638 2.65078C14.3957 2.41714 14.7734 2.41602 15.007 2.64817L17.1877 4.81517C17.3271 4.95364 17.3893 5.15184 17.354 5.34507C17.3188 5.5383 17.1907 5.70183 17.0114 5.7822L15.5728 6.42749L17.2892 8.15269C17.5215 8.38615 17.5205 8.76376 17.287 8.99605C17.2277 9.0549 17.1592 9.09885 17.0861 9.12777Z"
        fill="#F95C5C"
      />
    </svg>
  );
};

export const Feedback8 = () => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1600));
  const isUpMd = useMediaQuery(theme.breakpoints.up(1499));
  return (
    <svg
      width={isBelowLg && isUpMd ? '48' : '52'}
      height={isBelowLg && isUpMd ? '48' : '52'}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 43.875C35.8721 43.875 43.875 35.8721 43.875 26C43.875 16.1279 35.8721 8.125 26 8.125C16.1279 8.125 8.125 16.1279 8.125 26C8.125 35.8721 16.1279 43.875 26 43.875Z"
        fill="#FFDB5D"
      />
      <path
        d="M43.8716 26.0005C43.8716 35.8765 35.8669 43.8755 25.9966 43.8755C20.3771 43.8755 15.361 41.2837 12.082 37.2227C15.1487 39.7028 19.0533 41.1831 23.3042 41.1831C33.1745 41.1831 41.1792 33.184 41.1792 23.3081C41.1792 19.0572 39.6989 15.1526 37.2187 12.0859C41.2741 15.3649 43.8716 20.3811 43.8716 26.0005Z"
        fill="#FDC453"
      />
      <path
        d="M33.7334 34.6462C33.5951 34.8086 33.0921 34.9335 32.4703 34.944C28.4935 35.0187 24.5065 35.0187 20.5297 34.944C19.9079 34.9335 19.4049 34.8083 19.2666 34.6462C18.9111 34.2155 18.9111 33.7845 19.2666 33.3538C19.4049 33.1914 19.9079 33.0665 20.5297 33.056C24.5065 32.9813 28.4935 32.9813 32.4703 33.056C33.0921 33.0665 33.5951 33.1917 33.7334 33.3538C34.0889 33.7848 34.0889 34.2155 33.7334 34.6462Z"
        fill="#5E4939"
      />
      <path
        d="M36.8491 25.3053C35.9979 23.8296 34.3589 22.9747 32.6724 23.1295C31.6322 23.225 30.6609 23.6922 29.9369 24.4463C29.562 24.8353 28.9451 24.8492 28.5551 24.4744C28.1663 24.1006 28.1532 23.4814 28.527 23.0926C29.5766 21.9989 30.9863 21.321 32.4937 21.1826C34.9379 20.9583 37.3129 22.1932 38.5433 24.3286C38.8128 24.7963 38.6522 25.3933 38.1845 25.664C37.7156 25.9336 37.1173 25.7709 36.8491 25.3053Z"
        fill="#5E4939"
      />
      <path
        d="M13.5731 25.9216C13.0923 25.6781 12.8982 25.0896 13.1417 24.6078C14.2533 22.4083 16.5571 21.0452 19.0099 21.1353C20.5227 21.1908 21.9674 21.7906 23.0753 22.8251C23.4698 23.1929 23.4907 23.8119 23.1229 24.2064C22.7551 24.6021 22.1362 24.6218 21.7416 24.2541C20.9774 23.5407 19.982 23.1274 18.9381 23.089C17.2456 23.0269 15.6559 23.9701 14.8868 25.4902C14.6445 25.9699 14.056 26.1651 13.5731 25.9216Z"
        fill="#5E4939"
      />
    </svg>
  );
};

export const Feedback9 = () => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1600));
  const isUpMd = useMediaQuery(theme.breakpoints.up(1499));
  return (
    <svg
      width={isBelowLg && isUpMd ? '48' : '52'}
      height={isBelowLg && isUpMd ? '48' : '52'}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5 44C36.165 44 44 36.165 44 26.5C44 16.835 36.165 9 26.5 9C16.835 9 9 16.835 9 26.5C9 36.165 16.835 44 26.5 44Z"
        fill="#FFDB5D"
      />
      <path
        d="M43.9996 26.5C43.9996 36.1687 36.1629 44 26.4996 44C20.998 44 16.0871 41.4625 12.877 37.4867C15.8793 39.9148 19.702 41.364 23.8637 41.364C33.527 41.364 41.3637 33.5328 41.3637 23.864C41.3637 19.7023 39.9145 15.8797 37.4863 12.8773C41.4566 16.0875 43.9996 20.9984 43.9996 26.5Z"
        fill="#FDC453"
      />
      <path
        d="M32.9378 27.1682C34.4546 27.1682 35.6842 25.9386 35.6842 24.4218C35.6842 22.905 34.4546 21.6754 32.9378 21.6754C31.421 21.6754 30.1914 22.905 30.1914 24.4218C30.1914 25.9386 31.421 27.1682 32.9378 27.1682Z"
        fill="#725B3E"
      />
      <path
        d="M33.9343 22.7133C33.5146 22.5272 33.0768 22.5965 32.9563 22.8682C32.8358 23.1398 33.0783 23.511 33.498 23.6971C33.9176 23.8832 34.3555 23.8138 34.476 23.5422C34.5965 23.2705 34.3539 22.8994 33.9343 22.7133Z"
        fill="#5A5F63"
      />
      <path
        d="M20.0628 27.1682C21.5796 27.1682 22.8092 25.9386 22.8092 24.4218C22.8092 22.905 21.5796 21.6754 20.0628 21.6754C18.546 21.6754 17.3164 22.905 17.3164 24.4218C17.3164 25.9386 18.546 27.1682 20.0628 27.1682Z"
        fill="#725B3E"
      />
      <path
        d="M21.0593 22.7127C20.6396 22.5265 20.2018 22.5959 20.0813 22.8676C19.9608 23.1392 20.2033 23.5103 20.623 23.6965C21.0426 23.8826 21.4805 23.8132 21.601 23.5415C21.7215 23.2699 21.4789 22.8988 21.0593 22.7127Z"
        fill="#5A5F63"
      />
      <path
        d="M19.3324 33.4083C19.0021 33.0977 18.9868 32.5781 19.2974 32.2489C19.6081 31.9197 20.1265 31.9033 20.4568 32.2139C23.8463 35.4044 29.1554 35.4044 32.5449 32.2139C32.8753 31.9033 33.3937 31.9197 33.7043 32.2489C34.0149 32.5792 33.9996 33.0977 33.6693 33.4083C29.6498 37.1927 23.352 37.1927 19.3324 33.4083Z"
        fill="#725B3E"
      />
    </svg>
  );
};

export const Feedback10 = () => {
  const theme = useTheme();
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1600));
  const isUpMd = useMediaQuery(theme.breakpoints.up(1499));
  return (
    <svg
      width={isBelowLg && isUpMd ? '48' : '52'}
      height={isBelowLg && isUpMd ? '48' : '52'}
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5 44C36.165 44 44 36.165 44 26.5C44 16.835 36.165 9 26.5 9C16.835 9 9 16.835 9 26.5C9 36.165 16.835 44 26.5 44Z"
        fill="#FFDB5D"
      />
      <path
        d="M44.0017 26.5063C44.0017 36.1714 36.1679 43.9998 26.5082 43.9998C21.0087 43.9998 16.0996 41.4632 12.8906 37.4889C15.8919 39.9161 19.7131 41.3648 23.8733 41.3648C33.533 41.3648 41.3668 33.5365 41.3668 23.8713C41.3668 19.7111 39.9181 15.8899 37.4909 12.8887C41.4597 16.0976 44.0017 21.0068 44.0017 26.5063Z"
        fill="#FDC453"
      />
      <path
        d="M16.9725 25.9353C16.7669 25.5317 16.9266 25.0374 17.3302 24.8317L21.3344 22.7875L18.1625 21.1677C17.7589 20.962 17.5993 20.4677 17.8049 20.0641C18.0105 19.6605 18.5049 19.5008 18.9085 19.7064L23.511 22.0569C23.7855 22.1969 23.9583 22.4791 23.9583 22.7875C23.9583 23.096 23.7855 23.3781 23.511 23.5181L18.0761 26.293C17.6682 26.5008 17.176 26.3345 16.9725 25.9353Z"
        fill="#5E4939"
      />
      <path
        d="M34.9213 26.2935L29.4864 23.5186C29.2119 23.3786 29.0391 23.0964 29.0391 22.788C29.0391 22.4796 29.2119 22.1974 29.4864 22.0574L34.0889 19.7069C34.4936 19.5013 34.9869 19.661 35.1925 20.0646C35.3981 20.4682 35.2384 20.9625 34.8348 21.1682L31.663 22.788L35.6661 24.8322C36.0697 25.0379 36.2305 25.5322 36.0238 25.9358C35.817 26.3416 35.3227 26.498 34.9213 26.2935Z"
        fill="#5E4939"
      />
      <g opacity="0.2">
        <path
          opacity="0.2"
          d="M28.8025 37.0354C28.5098 36.7951 28.1588 36.7387 28.0185 36.9095C27.8782 37.0803 28.0017 37.4137 28.2943 37.6541C28.587 37.8945 28.938 37.9509 29.0783 37.78C29.2186 37.6092 29.0951 37.2758 28.8025 37.0354Z"
          fill="white"
        />
      </g>
      <path
        d="M34 31C34 31.7879 33.806 32.5681 33.4291 33.2961C33.0522 34.0241 32.4997 34.6855 31.8033 35.2426C31.1069 35.7998 30.2801 36.2417 29.3701 36.5433C28.4602 36.8448 27.4849 37 26.5 37C25.5151 37 24.5398 36.8448 23.6299 36.5433C22.7199 36.2417 21.8931 35.7998 21.1967 35.2426C20.5003 34.6855 19.9478 34.0241 19.5709 33.2961C19.194 32.5681 19 31.7879 19 31L34 31Z"
        fill="#5E4939"
      />
      <path
        d="M21 35.1365C22.4026 36.4744 24.3015 37.2943 26.3946 37.2943C28.4015 37.2943 30.214 36.5391 31.6167 35.3091L31.5519 35.1365C26.2867 32.5902 21.0647 35.1149 21 35.1365Z"
        fill="#F06669"
      />
    </svg>
  );
};
