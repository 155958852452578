import React from 'react';
import { hot } from 'react-hot-loader/root';
import {
  Box,
  makeStyles,
  Typography,
  Paper,
  Tooltip,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateFormats, Colors } from 'res/Enums';
import { Home, Edit, Delete } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { pdfControllerDeleteMyExam, UserPdfDTO } from 'gen/client';
interface ExamFileProps {
  exam: UserPdfDTO;
  onEdit: (exam: UserPdfDTO) => void;
  onDelete: () => void;
}
const _ExamFile = ({ exam, onEdit, onDelete }: ExamFileProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));

  const deleteFile = async () => {
    try {
      const r = await pdfControllerDeleteMyExam(exam.id);
      if (r) {
        onDelete();
        toast(t('ExamDeleted'));
      } else {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const handleFile = (rawData: UserPdfDTO) => {
    const data = rawData.fileName.split('.');
    const type = data[1];
    return type;
  };

  return (
    <Paper
      className={classes.container}
      style={isBelowXs ? { width: '100%' } : {}}
    >
      <Box display="flex" flex="1 1 auto" alignItems="center">
        <Box display="flex" width="60%">
          <Tooltip title={exam.category.name} placement="top">
            <Typography variant="h6" className={classes.title}>
              {exam.category.name}
            </Typography>
          </Tooltip>
        </Box>
        <Box display="flex">
          <IconButton>
            <Edit onClick={() => onEdit(exam)} />
          </IconButton>
          <IconButton>
            <Delete onClick={deleteFile} />
          </IconButton>
        </Box>
      </Box>
      <Typography>{moment(exam.date).format(DateFormats.GrNoTime)}</Typography>
      <Box className={classes.centerContainer}>
        <Home />
        <Box display="flex" flex="1 1 auto" marginX={2} marginY={3}>
          <Typography variant="caption" style={{ fontSize: 12, width: '100%' }}>
            {exam.medicalCenter.name}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Typography style={{ color: Colors.FadeBlack, margin: 4 }}>
        {t('Results')}:
      </Typography>
      <Box
        display="flex"
        style={{
          border: '1px solid #263238',
          borderRadius: '4px 0px 0px 4px',
        }}
      >
        <Box
          style={{
            backgroundColor: '#FF6262',
            padding: 5,
            color: Colors.IceWhite,
            borderRadius: '5px 0px 0px 5px',
          }}
        >
          <Typography>{handleFile(exam)}</Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            flex: '1 1 auto',
            justifyContent: 'center',
            color: Colors.FadeBlack,
            alignItems: 'center',
            padding: '0 5px ',
            minWidth: 0,
          }}
        >
          <a
            className={classes.title}
            href={exam.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {exam.fileName}
          </a>
        </Box>
      </Box>
    </Paper>
  );
};
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 20,
    padding: 20,
    paddingTop: 0,
    width: '30%',
    minWidth: 200,
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  centerContainer: {
    color: Colors.FadeBlack,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',
    flexGrow: 1,
  },
}));
export const ExamFile = hot(_ExamFile);
