import {
  Box,
  Container,
  Grid,
  Typography,
  Dialog,
  Popover,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import HDL from 'assets/icons/HDL_Xolisteroli.svg';
import Kardiakoi from 'assets/icons/Kardiakoi.svg';
import LDL from 'assets/icons/LDL_Xolisteroli.svg';
import Height from 'assets/icons/Height.svg';
import Weight from 'assets/icons/Weight.svg';
import Piesi from 'assets/icons/Piesi.svg';
import Ypnos from 'assets/icons/Ypnos.svg';
import Zakxaro from 'assets/icons/Zakxaro.svg';
import { AutoCompleteStyle } from 'components/AutoComplete';
import { ImageButton } from 'components/ImageButton';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Select from 'react-select';
import { Colors } from 'res/Enums';
import { useTutorialContext } from 'context/TutorialContext';
import { Demo } from 'components/Demo';
import {
  MeasurementDTO,
  useMeasurementsControllerGetMeasurementTypes,
} from 'gen/client';
interface MeasurementsProps {
  tut?: number;
}

const Measurements = ({ tut }: MeasurementsProps) => {
  const [types, setTypes] = useState<MeasurementDTO[]>([]);
  const { t } = useTranslation();
  const [temp, setTemp] = useState(0);
  const { openT, me, setCont, cont } = useTutorialContext();
  const history = useHistory();
  const inputEl = useRef<any | null>(null);
  const inputEl1 = useRef<any | null>(null);
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const { data } = useMeasurementsControllerGetMeasurementTypes();

  useEffect(() => {
    if (data) setTypes(data.data);
  }, [data]);

  useEffect(() => {
    setTemp(cont);
    setCont(cont + 1);
  }, []);

  return (
    <Container ref={inputEl1} style={tut ? { paddingTop: 16 } : {}}>
      <Typography
        style={{ color: Colors.Black, fontWeight: 'bold', marginBottom: 20 }}
      >
        {t('SearchMeasurements')}
      </Typography>
      <Grid item style={{ minHeight: 400 }} ref={inputEl}>
        {types.length > 0 && (
          <Select
            filterOption={(candidate, input) => {
              if (input) {
                return new RegExp(
                  `.*${input
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')}.*`,
                  'i'
                ).test(
                  candidate.label
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                );
              }
              return true;
            }}
            options={types.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={(e: any) => {
              history.push(`/measurements/${e.value}`);
            }}
            {...AutoCompleteStyle}
            placeholder={`${t('Search')} ${t('OfMeasurement')}...`}
          />
        )}
      </Grid>
      <Box style={{ marginTop: -300 }}>
        <Typography
          style={{
            color: '#000000',
            fontWeight: 'bold',
            fontSize: 16,
            fontFamily: 'Muli',
          }}
        >
          {t('MostCommon')}
        </Typography>
        <Grid style={{ marginTop: 20 }} container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton
              link="measurements"
              id="397"
              text={t('BloodPressure')}
              imageLeft={Piesi}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton
              link="measurements"
              id="4"
              text={t('HeartBeat')}
              imageLeft={Kardiakoi}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton
              link="measurements"
              id="191"
              text={t('Sugar')}
              imageLeft={Zakxaro}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton
              link="measurements"
              id="198"
              text={t('LDLCholesterol')}
              imageLeft={LDL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton
              link="measurements"
              id="197"
              text={t('HDLCholesterol')}
              imageLeft={HDL}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton
              link="measurements"
              id="7"
              text={t('SleepReg')}
              imageLeft={Ypnos}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton
              link="measurements"
              id="8"
              text={t('Weight')}
              imageLeft={Weight}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton
              link="measurements"
              id="9"
              text={t('Height')}
              imageLeft={Height}
            />
          </Grid>
        </Grid>
      </Box>
      <Box style={{ marginTop: 100 }}>
        {/* <Typography
          style={{
            backgroundColor: 'rgba(92, 165, 149, 0.15)',
            padding: 17,
            paddingLeft: 40,
            fontSize: 16,
            fontWeight: 'bold',
            fontFamily: 'Muli'
          }}
        >
          {t('AllMeasurement')}
        </Typography> */}
      </Box>
      {temp < 1 && (
        <Dialog
          open={!me?.tutorial && parseInt(openT) < 9 && openT !== '0'}
          BackdropProps={{
            style: { backgroundColor: 'black', opacity: 0.8 },
          }}
        >
          <Popover
            open={!me?.tutorial && openT === '7'}
            anchorEl={inputEl1.current}
            PaperProps={{ style: { height: '80vh' } }}
          >
            <Measurements tut={cont} />
            <Popover
              open={!me?.tutorial && openT === '7'}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  marginLeft: '-16%',
                  marginTop: '1%',
                  width: isBelowMd ? '60vw' : '45vw',
                  textAlign: 'center',
                },
              }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                {t('Tut16')}
              </Typography>
              {openT === '7' && <Demo />}
            </Popover>
          </Popover>
          <Popover
            open={openT === '8'}
            anchorEl={inputEl.current}
            PaperProps={{
              style: {
                width: isBelowMd ? '85%' : '70%',
                backgroundColor: 'transparent',
                WebkitTapHighlightColor: 'transparent',
                boxShadow: 'initial',
              },
            }}
          >
            <Grid style={{ minHeight: 400 }}>
              <Select
                inputValue={'σιδ'}
                menuIsOpen
                options={types.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                filterOption={(candidate, input) => {
                  if (input) {
                    return new RegExp(
                      `.*${input
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')}.*`,
                      'i'
                    ).test(
                      candidate.label
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                    );
                  }
                  return true;
                }}
                onChange={(e: any) => {
                  history.push(`/measurements/${e.value}`);
                }}
                {...AutoCompleteStyle}
                placeholder={`${t('Search')} ${t('OfMeasurement')}...`}
              />
            </Grid>
          </Popover>
          <Popover
            open={openT === '8'}
            anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                WebkitTapHighlightColor: 'transparent',
                boxShadow: 'initial',
                width: isBelowMd ? '80vw' : '45vw',
                textAlign: 'center',
                marginLeft: '-20%',
                marginTop: '8%',
              },
            }}
          >
            <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
              {t('Tut17')}
            </Typography>
            <Demo />
          </Popover>
        </Dialog>
      )}
    </Container>
  );
};

export default Measurements;
