import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { DateTimePickTheme } from './DatePicker';
import { DateTimePicker, DateTimePickerProps } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

const _StyledDateTimePicker = ({ ...props }: DateTimePickerProps) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={DateTimePickTheme}>
      <DateTimePicker
        {...props}
        cancelLabel={t('Cancel')}
        todayLabel={t('Today')}
      />
    </ThemeProvider>
  );
};
export const StyledDateTimePicker = hot(_StyledDateTimePicker);
