import Sun from 'assets/icons/sun.svg';
import Sun1 from 'assets/icons/sunSelected.svg';
import Symptoms from 'assets/icons/symptoms.svg';
import Symptoms1 from 'assets/icons/symptomsActive.svg';
import Symptoms2 from 'assets/icons/symptomsSelected.svg';
import HealthProfile from 'assets/icons/healthProfile.svg';
import HealthProfile1 from 'assets/icons/healthProfileActive.svg';
import HealthProfile2 from 'assets/icons/healthProfileSelected.svg';
import Exams from 'assets/icons/exams.svg';
import Exams1 from 'assets/icons/examsActive.svg';
import Exams2 from 'assets/icons/examsSelected.svg';
import ShortHistory from 'assets/icons/shortHistory.svg';
import ShortHistory1 from 'assets/icons/shortHistoryActive.svg';
import ShortHistory2 from 'assets/icons/shortHistorySelected.svg';
import Cycle from 'assets/icons/cycle.svg';
import Cycle1 from 'assets/icons/cycleActive.svg';
import Cycle2 from 'assets/icons/cycleSelected.svg';
import Diabetes from 'assets/icons/diabetes.svg';
import Diabetes1 from 'assets/icons/diabetesActive.svg';
import Diabetes2 from 'assets/icons/diabetesSelected.svg';
import Home from 'assets/icons/home.svg';
import Home1 from 'assets/icons/homeActive.svg';
import Home2 from 'assets/icons/homeSelected.svg';
import Medication from 'assets/icons/medication.svg';
import Medication1 from 'assets/icons/medicationActive.svg';
import Medication2 from 'assets/icons/medicationSelected.svg';
import Measurements from 'assets/icons/measurements.svg';
import Measurements1 from 'assets/icons/measurementsActive.svg';
import Measurements2 from 'assets/icons/measurementsSelected.svg';
import Calendar from 'assets/icons/calendar.svg';
import Calendar1 from 'assets/icons/calendarActive.svg';
import Calendar2 from 'assets/icons/calendarSelected.svg';
import Preventive from 'assets/icons/preventive.svg';
import Preventive1 from 'assets/icons/preventiveActive.svg';
import Preventive2 from 'assets/icons/preventiveSelected.svg';
import Fitness from 'assets/icons/Fitness.svg';
import Fitness1 from 'assets/icons/fitnessActive.svg';
import Fitness2 from 'assets/icons/fitnessSelected.svg';
import Insulin from 'assets/icons/insulin.svg';
import Insulin1 from 'assets/icons/insulinActive.svg';
import Insulin2 from 'assets/icons/insulinSelected.svg';
import HealthScore from 'assets/icons/healthScore.svg';
import HealthScore1 from 'assets/icons/healthScoreActive.svg';
import HealthScore2 from 'assets/icons/healthScoreSelected.svg';
import Folder from 'assets/icons/folder.svg';
import Folder1 from 'assets/icons/folderSelected.svg';
import Health from 'assets/icons/health.svg';
import Health1 from 'assets/icons/healthSelected.svg';
import More from 'assets/icons/more.svg';
import More1 from 'assets/icons/moreSelected.svg';
import Covid from 'assets/icons/covid.png';
import Covid1 from 'assets/icons/covidActive.png';
import Covid2 from 'assets/icons/covidSelected.png';
import Pregnancy from 'assets/icons/pregnancy.png';
import Pregnancy1 from 'assets/icons/pregnancyActive.png';
import Pregnancy2 from 'assets/icons/pregnancySelected.png';
import Diagnosis from 'assets/icons/diagnosis.png';
import Diagnosis1 from 'assets/icons/diagnosisActive.png';
import Diagnosis2 from 'assets/icons/diagnosisSelected.png';
import React from 'react';

export interface NavItem {
  label: string;
  icon: any;
  pageComponent?: React.ReactNode;
  url: string;
}
export interface NavCategory {
  label: string;
  icon: any;
  items: NavItem[];
}
export const navCategories: NavCategory[] = [
  {
    label: 'MyDay',
    icon: {
      idle: <img alt="" src={Sun} />,
      selected: <img alt="" src={Sun1} />,
    },
    items: [
      {
        label: 'Home',
        url: '/',
        icon: {
          idle: <img alt="" src={Home} />,
          active: <img alt="" src={Home1} />,
          selected: <img alt="" src={Home2} />,
        },
      },
      {
        label: 'Medication',
        url: '/medication',
        icon: {
          idle: <img alt="" src={Medication} />,
          active: <img alt="" src={Medication1} />,
          selected: <img alt="" src={Medication2} />,
        },
      },
      {
        label: 'Measurements',
        url: '/measurements',
        icon: {
          idle: <img alt="" src={Measurements} />,
          active: <img alt="" src={Measurements1} />,
          selected: <img alt="" src={Measurements2} />,
        },
      },
      {
        label: 'Symptoms',
        url: '/symptoms',
        icon: {
          idle: <img alt="" src={Symptoms} />,
          active: <img alt="" src={Symptoms1} />,
          selected: <img alt="" src={Symptoms2} />,
        },
      },
      {
        label: 'Calendar',
        url: '/calendar',
        icon: {
          idle: <img alt="" src={Calendar} />,
          active: <img alt="" src={Calendar1} />,
          selected: <img alt="" src={Calendar2} />,
        },
      },
      // {
      //   label: 'Room',
      //   url: '/cross',
      //   icon: {
      //     idle: <img alt="" src={Home} />,
      //     active: <img alt="" src={Home} />,
      //     selected: <img alt="" src={Home} />
      //   }
      // }
    ],
  },
  {
    label: 'Folder',
    icon: {
      idle: <img alt="" src={Folder} />,
      selected: <img alt="" src={Folder1} />,
    },
    items: [
      {
        label: 'ShortHistory',
        url: '/concise',
        icon: {
          idle: <img alt="" src={ShortHistory} />,
          active: <img alt="" src={ShortHistory1} />,
          selected: <img alt="" src={ShortHistory2} />,
        },
      },
      {
        label: 'Exams',
        url: '/exams',
        icon: {
          idle: <img alt="" src={Exams} />,
          active: <img alt="" src={Exams1} />,
          selected: <img alt="" src={Exams2} />,
        },
      },
      {
        label: 'HealthProfile',
        url: '/healthProfile',
        icon: {
          idle: <img alt="" src={HealthProfile} />,
          active: <img alt="" src={HealthProfile1} />,
          selected: <img alt="" src={HealthProfile2} />,
        },
      },
    ],
  },
  {
    label: 'Health',
    icon: {
      idle: <img alt="" src={Health} />,
      selected: <img alt="" src={Health1} />,
    },
    items: [
      {
        label: 'HealthScore',
        url: '/health',
        icon: {
          idle: (
            <img alt="" src={HealthScore} style={{ width: 24, height: 24 }} />
          ),
          active: (
            <img alt="" src={HealthScore1} style={{ width: 24, height: 24 }} />
          ),
          selected: (
            <img alt="" src={HealthScore2} style={{ width: 24, height: 24 }} />
          ),
        },
      },
      {
        label: 'Preventive',
        url: '/preventive',
        icon: {
          idle: <img alt="" src={Preventive} />,
          active: <img alt="" src={Preventive1} />,
          selected: <img alt="" src={Preventive2} />,
        },
      },
      {
        label: 'SelfDiagnosis',
        url: '/diagnosis',
        icon: {
          idle: <img alt="" src={Diagnosis} />,
          active: <img alt="" src={Diagnosis1} />,
          selected: <img alt="" src={Diagnosis2} />,
        },
      },
      {
        label: 'Fitness',
        url: '/fitness',
        icon: {
          idle: <img alt="" src={Fitness} />,
          active: <img alt="" src={Fitness1} />,
          selected: <img alt="" src={Fitness2} />,
        },
      },
    ],
  },
  {
    label: 'More',
    icon: {
      idle: <img alt="" src={More} />,
      selected: <img alt="" src={More1} />,
    },
    items: [
      {
        label: 'Covid-19',
        url: '/covid-19',
        icon: {
          idle: <img alt="" src={Covid} />,
          active: <img alt="" src={Covid1} />,
          selected: <img alt="" src={Covid2} />,
        },
      },
      {
        label: 'Cycle',
        url: '/cycle',
        icon: {
          idle: <img alt="" src={Cycle} />,
          active: <img alt="" src={Cycle1} />,
          selected: <img alt="" src={Cycle2} />,
        },
      },
      {
        label: 'Diabetes',
        url: '/diabetes',
        icon: {
          idle: <img alt="" src={Diabetes} />,
          active: <img alt="" src={Diabetes1} />,
          selected: <img alt="" src={Diabetes2} />,
        },
      },
      {
        label: 'Insulin',
        url: '/insulin',
        icon: {
          idle: <img alt="" src={Insulin} />,
          active: <img alt="" src={Insulin1} />,
          selected: <img alt="" src={Insulin2} />,
        },
      },
      {
        label: 'Pregnancy',
        url: '/pregnancy',
        icon: {
          idle: <img alt="" src={Pregnancy} />,
          active: <img alt="" src={Pregnancy1} />,
          selected: <img alt="" src={Pregnancy2} />,
        },
      },
      // {
      //   label: 'Kid',
      //   url: '/kid',
      //   icon: {
      //     idle: <img alt="" src={Covid} />,
      //     active: <img alt="" src={Covid1} />,
      //     selected: <img alt="" src={Covid2} />,
      //   },
      // },
    ],
  },
];
