import { Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Circle } from 'Pages/Cycle';
import { Colors } from 'res/Enums';
const _CycleDot = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowX = useMediaQuery(theme.breakpoints.down(700));
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: isBelowMd ? 'row' : 'column',
        width: isBelowMd ? '100%' : '15%',
      }}
    >
      <Box
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          flexDirection: isBelowMd ? 'row' : 'column',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: isBelowXs ? 'column' : isBelowMd ? 'row' : 'column',
          }}
        >
          <Box
            style={{
              display: 'flex',
              marginBottom: 24,
              marginLeft: isBelowX ? 6 : 16,
            }}
          >
            <Circle color={'#EF84FD'} />
            <Typography
              style={{
                paddingLeft: 8,
                color: Colors.Black,
                fontSize: 14,
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {t('PrePeriod')}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              marginBottom: 24,
              marginLeft: isBelowX ? 6 : 16,
            }}
          >
            <Circle color={'#EC4969'} />
            <Typography
              style={{
                paddingLeft: 8,
                color: Colors.Black,
                fontSize: 14,
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {t('PeriodDay')}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: isBelowXs ? 'column' : isBelowMd ? 'row' : 'column',
          }}
        >
          <Box
            style={{
              display: 'flex',
              marginBottom: 24,
              marginLeft: isBelowX ? 6 : 16,
            }}
          >
            <Circle color={'#A3A0FB'} />
            <Typography
              style={{
                paddingLeft: 8,
                color: Colors.Black,
                fontSize: 14,
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {t('PostPeriod')}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              marginBottom: 24,
              marginLeft: isBelowX ? 6 : 16,
            }}
          >
            <Circle color={'#86CDF8'} />
            <Typography
              style={{
                paddingLeft: 8,
                color: Colors.Black,
                fontSize: 14,
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {t('PeakOvulation')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export const CycleDot = hot(_CycleDot);
