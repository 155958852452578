import {
  Box,
  makeStyles,
  ListItem,
  Typography,
  IconButton,
  ThemeProvider,
} from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Add } from '@material-ui/icons';
import moment, { Moment } from 'moment';
import { useTimetableContext } from 'context/TimetableContext';
import { uuid } from 'uuidv4';
import { TimetableRowValues } from './TimetableRowValues';
import { DateFormats } from 'res/Enums';
import { DateTimePickTheme } from 'components/DatePicker';
interface MedicationTimetableRowProps {
  date: Moment;
}
const _MedicationTimetableRow = ({ date }: MedicationTimetableRowProps) => {
  const classes = useStyles();
  const { timetableRows, setRows } = useTimetableContext();

  const filteredRows = timetableRows
    .sort((a, b) => moment(a.time).diff(moment(b.time), 'days'))
    .filter((row) => moment(row.time).isSame(date, 'day'));

  const addNewRow = () => {
    setRows([
      ...timetableRows,
      {
        uid: uuid(),
        dosage: 'dosage',
        time: date.format(DateFormats.ISO),
        frequency: 168,
      },
    ]);
  };

  return (
    <ListItem>
      <Box className={classes.item}>
        <Box className={classes.itemTitle}>
          <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>
            {date.format('dddd')}
          </Typography>
          <IconButton size="small" color="primary" onClick={addNewRow}>
            <Add />
          </IconButton>
        </Box>
        {filteredRows.map((row, i) => (
          <Box key={uuid()}>
            <ThemeProvider theme={DateTimePickTheme}>
              <TimetableRowValues row={row} first={i === 0} />
            </ThemeProvider>
          </Box>
        ))}
      </Box>
    </ListItem>
  );
};
const useStyles = makeStyles(() => ({
  item: {
    border: '2px solid #5CA595',
    borderRadius: 5,
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    padding: 12,
  },
  itemTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    flex: '1 1 auto',
    justifyContent: 'space-between',
  },
}));
export const MedicationTimetableRow = hot(_MedicationTimetableRow);
