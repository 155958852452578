import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import moment from 'moment';
import React, { Fragment } from 'react';
import { DateFormats, Colors } from 'res/Enums';
import { useTranslation } from 'react-i18next';
import { uuid } from 'uuidv4';
import { Delete } from '@material-ui/icons';
import { MedicationTimetableView } from './Timetable/MedicationTimetableView';
import { DeleteToast } from 'components/Toasts';
import { toast } from 'react-toastify';
import { Circle } from 'Pages/Cycle';
import theme from '../../assets/theme';
import {
  useMedicationControllerDeleteMyMedication,
  UserMedicationDTO,
} from 'gen/client';
interface MedicationHistoryProps {
  history: UserMedicationDTO[];
  onDelete: () => void;
}
export const MedicationHistory = ({
  history,
  onDelete,
}: MedicationHistoryProps) => {
  const { t } = useTranslation();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const deleteMyMedication = useMedicationControllerDeleteMyMedication();
  const deleteMedication = async (id: number) => {
    DeleteToast().then(async () => {
      try {
        const r = await deleteMyMedication.mutateAsync({ id });
        if (r) {
          toast(t('Deleted'));
          onDelete();
        } else {
          toast(t('DeleteFailed'), { containerId: 'deleted' });
        }
      } catch (e) {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    });
  };
  return (
    <Box style={{ width: '100%', overflow: 'auto' }}>
      <Table>
        <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
          <TableRow>
            <TableCell
              size={isBelowSm ? 'small' : 'medium'}
              style={{
                color: Colors.IceWhite,
              }}
            >
              {t('Name')}
            </TableCell>
            <TableCell
              size={isBelowSm ? 'small' : 'medium'}
              style={{ color: Colors.IceWhite }}
            >
              {`${t('Shape')}/${t('Color')}`}
            </TableCell>
            <TableCell
              size={isBelowSm ? 'small' : 'medium'}
              style={{ textAlign: 'center', color: Colors.IceWhite }}
            >
              {t('Dosage')}
            </TableCell>
            <TableCell
              size={isBelowSm ? 'small' : 'medium'}
              style={{ textAlign: 'center', color: Colors.IceWhite }}
            >
              {t('Frequency')}
            </TableCell>
            <TableCell
              size={isBelowSm ? 'small' : 'medium'}
              style={{ color: Colors.IceWhite }}
            >
              {t('StartDate')}
            </TableCell>
            <TableCell
              size={isBelowSm ? 'small' : 'medium'}
              style={{ color: Colors.IceWhite }}
            >
              {t('EndDate')}
            </TableCell>
            <TableCell size={isBelowSm ? 'small' : 'medium'} />
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((item) => (
            <TableRow key={uuid()}>
              <TableCell size={isBelowSm ? 'small' : 'medium'}>
                <Box style={{ fontSize: isBelowSm ? 12 : 'inherit' }}>
                  {item.medicine.name}
                </Box>
              </TableCell>
              <TableCell size={isBelowSm ? 'small' : 'medium'}>
                {(() => {
                  const res = chooseIcon(item);
                  if (res) {
                    return res;
                  } else {
                    return (
                      <Circle
                        color={item.medicine.color.code}
                        userColor={item.color.code}
                      />
                    );
                  }
                })()}
              </TableCell>
              {item.repeatedNormally ? (
                <Fragment>
                  <TableCell
                    size={isBelowSm ? 'small' : 'medium'}
                    style={{
                      textAlign: 'center',
                      fontSize: isBelowSm ? 12 : 'inherit',
                    }}
                  >
                    {item.timetable[0].dosage}
                  </TableCell>
                  <TableCell
                    size={isBelowSm ? 'small' : 'medium'}
                    style={{
                      textAlign: 'center',
                      fontSize: isBelowSm ? 12 : 'inherit',
                    }}
                  >
                    {`${item.timetable[0].frequency} ${t('Hours')}`}
                  </TableCell>
                </Fragment>
              ) : (
                <Fragment>
                  <TableCell
                    size={isBelowSm ? 'small' : 'medium'}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <MedicationTimetableView timetableRows={item.timetable} />
                  </TableCell>
                  <TableCell
                    size={isBelowSm ? 'small' : 'medium'}
                    style={{ textAlign: 'center' }}
                  >
                    <MedicationTimetableView timetableRows={item.timetable} />
                  </TableCell>
                </Fragment>
              )}
              <TableCell
                size={isBelowSm ? 'small' : 'medium'}
                style={{
                  fontSize: isBelowSm ? 12 : 'inherit',
                }}
              >
                {moment(item.startDate).format(DateFormats.GR)}
              </TableCell>

              {item.duration === 10000 &&
              moment(item.endDate).isSameOrAfter(moment()) ? (
                <TableCell
                  size={isBelowSm ? 'small' : 'medium'}
                  style={{
                    fontSize: isBelowSm ? 18 : 24,
                    fontWeight: 'bold',
                  }}
                >
                  &infin;
                </TableCell>
              ) : (
                <TableCell
                  size={isBelowSm ? 'small' : 'medium'}
                  style={{
                    fontSize: isBelowSm ? 12 : 'inherit',
                  }}
                >
                  {moment(item.endDate).format(DateFormats.GR)}
                </TableCell>
              )}
              <TableCell size={isBelowSm ? 'small' : 'medium'}>
                <IconButton>
                  <Delete
                    fontSize={isBelowSm ? 'small' : 'default'}
                    onClick={() => deleteMedication(item.id)}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
export const chooseIcon = (med: UserMedicationDTO) => {
  const { id } = med.shape;
  const { injection, pill, drops, ointment } = medIconGroups;
  if (injection.includes(id)) return medIcons.syringe;
  if (pill.includes(id)) return medIcons.pill(med.color.code);
  if (drops.includes(id)) return medIcons.drops;
  if (ointment.includes(id)) return medIcons.ointment;
  return false;
};

export const medIconGroups = {
  injection: [3],
  drops: [12, 34, 21],
  ointment: [10, 13, 15],
  pill: [8, 11],
};

export const medIcons = {
  pill: (code: string) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M6.34486 23.9999C4.65405 23.9999 3.06394 23.3414 1.86888 22.1454C0.673341 20.9503 0.0148392 19.3605 0.0148392 17.6699C0.0148392 15.9793 0.673341 14.3895 1.86888 13.1935L7.53823 7.52362C7.8548 7.20705 8.41526 7.20705 8.73184 7.52362L16.4902 15.2829C16.82 15.6125 16.82 16.1467 16.4902 16.4763L10.8208 22.1454C9.62529 23.3414 8.03566 23.9999 6.34486 23.9999ZM8.13491 9.31392L3.06225 14.3871C2.1857 15.2639 1.70275 16.4302 1.70275 17.6699C1.70275 18.9096 2.18521 20.075 3.06225 20.952C3.93929 21.8288 5.10464 22.312 6.34486 22.312C7.58435 22.312 8.75043 21.8291 9.62722 20.952L14.6999 15.8796L8.13491 9.31392Z"
          fill={code}
        />
        <path
          d="M15.8938 15.8794L21.5634 10.2095C23.7057 8.06717 23.7057 4.59356 21.5634 2.45119C19.421 0.308587 15.9474 0.308587 13.8048 2.45119L8.1352 8.12078L15.8938 15.8794Z"
          fill="#F1F1F1"
        />
        <path
          d="M15.8938 16.7236C15.6699 16.7236 15.4553 16.6347 15.2969 16.4763L7.53852 8.717C7.20867 8.38738 7.20867 7.85324 7.53852 7.52363L13.2079 1.85453C14.4034 0.658502 15.993 0 17.6838 0C19.3749 0 20.9648 0.658502 22.1601 1.85453C23.3556 3.04958 24.0141 4.63945 24.0141 6.33002C24.0141 8.02058 23.3556 9.61069 22.1601 10.8065L16.4905 16.4765C16.3321 16.6347 16.1174 16.7236 15.8938 16.7236ZM9.32857 8.12031L15.8938 14.6863L20.9664 9.61311C21.843 8.73607 22.326 7.56975 22.326 6.33002C22.326 5.09053 21.8435 3.92493 20.9664 3.04814C20.0894 2.1711 18.9241 1.68815 17.6838 1.68815C16.4444 1.68815 15.2785 2.1711 14.4015 3.04814L9.32857 8.12031Z"
          fill={code}
        />
        <path
          d="M19.9411 5.6913C19.6719 5.6913 19.4072 5.5626 19.2438 5.3245C18.849 4.74762 18.1972 4.40376 17.5006 4.40376C16.9367 4.40376 16.4072 4.62302 16.0082 5.02194C15.6784 5.35179 15.1443 5.35179 14.8146 5.02194C14.485 4.69232 14.485 4.15818 14.8146 3.82857C15.5323 3.11066 16.4859 2.71585 17.5006 2.71585C18.7545 2.71585 19.9271 3.33403 20.6368 4.37019C20.9 4.75486 20.802 5.28007 20.4171 5.54376C20.2715 5.64349 20.1054 5.6913 19.9411 5.6913Z"
          fill="#DDDDDD"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H24V24H0V0Z" fill={code} />
        </clipPath>
      </defs>
    </svg>
  ),
  syringe: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.06944 19.9306C3.93214 19.7933 3.70958 19.7933 3.57223 19.9306L0.102975 23.3998C-0.034325 23.5371 -0.034325 23.7597 0.102975 23.897C0.240275 24.0343 0.462843 24.0343 0.60019 23.897L4.06944 20.4278C4.20674 20.2904 4.20674 20.0679 4.06944 19.9306Z"
        fill="#AAC3D6"
      />
      <path
        d="M6.36275 19.1661L4.83388 17.6372C4.69658 17.4999 4.47402 17.4999 4.33667 17.6372L2.80781 19.1661C2.67051 19.3034 2.67051 19.5259 2.80781 19.6633L4.33667 21.1922C4.47397 21.3295 4.69654 21.3295 4.83388 21.1922L6.36275 19.6633C6.50005 19.5259 6.50005 19.3034 6.36275 19.1661Z"
        fill="#E9F5FF"
      />
      <path
        d="M21.4452 3.74527L20.2547 2.55476C20.1174 2.41746 19.8949 2.41746 19.7575 2.55476L16.9891 5.32316C16.8518 5.46046 16.8518 5.68303 16.9891 5.82038L18.1796 7.01084C18.3169 7.14814 18.5394 7.14814 18.6768 7.01084L21.4452 4.24243C21.5825 4.10513 21.5825 3.88257 21.4452 3.74527Z"
        fill="#AAC3D6"
      />
      <path
        d="M18.9282 9.47629L14.5237 5.07181C14.3865 4.9346 14.1638 4.9346 14.0265 5.07181L3.80319 15.2951C3.16746 15.9308 3.16774 16.9654 3.80352 17.6011L6.39887 20.1964C7.03465 20.8322 8.06911 20.8325 8.70489 20.1968L18.9282 9.97341C19.0654 9.83625 19.0654 9.61349 18.9282 9.47629Z"
        fill="#E9F5FF"
      />
      <path
        d="M18.9282 9.97344L8.70483 20.1968C8.0691 20.8325 7.03459 20.8322 6.39881 20.1965L5.4048 19.2024C6.04157 19.8392 7.07373 19.8391 7.7105 19.2024L17.9338 8.97906C18.0711 8.84185 18.0711 8.6191 17.9338 8.48184L18.9282 9.47622C19.0654 9.61348 19.0654 9.83623 18.9282 9.97344Z"
        fill="#CDEAF7"
      />
      <path
        d="M17.3211 7.86937L16.1307 6.6789C15.9934 6.5416 15.7708 6.5416 15.6334 6.6789L12.865 9.44736C12.7277 9.58466 12.7277 9.80722 12.865 9.94457L14.0555 11.135C14.1928 11.2723 14.4153 11.2723 14.5527 11.135L17.3211 8.36658C17.4584 8.22923 17.4584 8.00667 17.3211 7.86937Z"
        fill="#AAC3D6"
      />
      <path
        d="M11.5067 7.59158L3.80319 15.2951C3.16746 15.9308 3.16774 16.9653 3.80352 17.6011L6.39886 20.1964C7.03465 20.8322 8.06911 20.8325 8.70489 20.1968L16.4085 12.4932L11.5067 7.59158Z"
        fill="#B9E7F6"
      />
      <path
        d="M15.8794 3.71611C15.3686 3.20531 14.5373 3.20531 14.0265 3.71611C13.5158 4.22692 13.5158 5.05822 14.0265 5.56899L18.431 9.97346C18.9418 10.4843 19.7731 10.4843 20.2839 9.97346C20.7946 9.46265 20.7946 8.63135 20.2839 8.12059L15.8794 3.71611Z"
        fill="#4F667A"
      />
      <path
        d="M23.6168 3.20529L20.7946 0.383212C20.2835 -0.127925 19.4525 -0.12755 18.9418 0.383212C18.431 0.893974 18.4306 1.72499 18.9418 2.23608L21.7639 5.05817C22.275 5.5693 23.106 5.56893 23.6167 5.05817C24.1275 4.5474 24.1279 3.71643 23.6168 3.20529Z"
        fill="#4F667A"
      />
      <path
        d="M16.4084 12.4932L8.70483 20.1968C8.0691 20.8325 7.03459 20.8323 6.39881 20.1965L5.4048 19.2024C6.04157 19.8392 7.07373 19.8391 7.7105 19.2024L15.4141 11.4988L16.4084 12.4932Z"
        fill="#93D8E4"
      />
    </svg>
  ),
  ointment: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.54393 21.5818C7.98628 21.185 7.13427 20.4756 5.67654 20.9448C3.97306 21.4931 3.66223 20.5318 3.9063 20.0801L3.82652 19.0918L2.84178 18.8684C0.037067 19.6514 0.705598 23.0383 3.03394 23.0383C4.79703 23.0383 5.34359 22.2498 6.53207 22.4083C7.53981 22.5427 8.12619 23.0136 8.90288 23.0383C10.5815 23.0917 10.8899 21.8336 10.8899 21.8336C10.8899 21.8336 9.57961 22.3187 8.54393 21.5818Z"
        fill="#F5F7FA"
      />
      <path
        d="M14.8613 1.75803L22.1362 10.0387L10.1978 18.5567L4.95799 12.5924L14.8613 1.75803Z"
        fill="#F7CFA0"
      />
      <path
        d="M14.8613 1.75803L13.9158 2.79246L20.2819 10.0387L9.48338 17.7434L10.1979 18.5567L22.1362 10.0387L14.8613 1.75803Z"
        fill="#F5AB81"
      />
      <path
        d="M15.5622 0L23.9605 9.55942L22.698 10.6781L14.2996 1.11867L15.5622 0Z"
        fill="#EE9B3A"
      />
      <path
        d="M4.7096 16.2455L6.55772 18.3491L10.1979 18.5567L4.95799 12.5924L4.7096 16.2455Z"
        fill="#EE9B3A"
      />
      <path
        d="M2.36351 18.3241L4.21167 20.4278L6.55772 18.3491L4.7096 16.2454L2.36351 18.3241Z"
        fill="#F7CFA0"
      />
      <path
        d="M5.64602 20.0367C5.54946 20.0367 5.4534 19.9968 5.3847 19.9185L2.9674 17.167C2.67071 16.8293 3.19312 16.3703 3.48981 16.7081L5.90711 19.4596C6.09365 19.6719 5.92486 20.0367 5.64602 20.0367Z"
        fill="#EE9B3A"
      />
    </svg>
  ),
  drops: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4266 10.395C13.4248 10.3901 13.4227 10.3854 13.4203 10.3807L8.07056 0.0696561C8.04834 0.0269062 8.00423 0 7.956 0C7.90776 0 7.86365 0.0269062 7.84143 0.0696561L2.49174 10.3807C2.4893 10.3854 2.48719 10.3901 2.48541 10.395C1.9216 11.9046 1.9793 13.5433 2.64802 15.0092C3.31669 16.4752 4.51631 17.593 6.02587 18.1567C6.64415 18.3876 7.29215 18.5149 7.95206 18.5349C7.95337 18.535 7.95468 18.535 7.956 18.535C7.95731 18.535 7.95862 18.535 7.95993 18.5349C8.61979 18.5148 9.26784 18.3875 9.88603 18.1567C11.3955 17.593 12.5952 16.4752 13.2639 15.0092C13.9326 13.5433 13.9904 11.9046 13.4266 10.395Z"
        fill="#7C87F1"
      />
      <path
        d="M8.36849 17.5004C7.08613 16.2368 6.37265 14.5492 6.35938 12.7487C6.35934 12.7429 6.35966 12.7371 6.36037 12.7313L7.79816 0.153076L2.49174 10.3807C2.4893 10.3853 2.48719 10.3901 2.48541 10.395C1.9216 11.9046 1.9793 13.5433 2.64802 15.0092C3.31669 16.4752 4.51631 17.5929 6.02587 18.1566C6.64415 18.3875 7.29215 18.5149 7.95205 18.5349C7.95336 18.535 7.95468 18.535 7.95599 18.535C7.9573 18.535 7.95861 18.535 7.95993 18.5349C8.441 18.5202 8.91566 18.4479 9.37719 18.3207C9.0193 18.0827 8.68152 17.8088 8.36849 17.5004Z"
        fill="#5A68E2"
      />
      <path
        d="M21.707 13.1615C21.7052 13.1567 21.7031 13.1519 21.7007 13.1473L19.0629 8.06319C19.0407 8.02044 18.9966 7.99353 18.9483 7.99353C18.9001 7.99353 18.856 8.02039 18.8338 8.06319L16.196 13.1473C16.1936 13.1519 16.1914 13.1567 16.1897 13.1615C15.9056 13.9222 15.9347 14.748 16.2717 15.4866C16.6085 16.2253 17.213 16.7886 17.9738 17.0727C18.2853 17.189 18.6119 17.2531 18.9444 17.2633C18.9457 17.2633 18.947 17.2633 18.9483 17.2633C18.9497 17.2633 18.951 17.2633 18.9523 17.2633C19.2847 17.2531 19.6112 17.189 19.9228 17.0727C21.4929 16.4863 22.2933 14.7318 21.707 13.1615Z"
        fill="#5A68E2"
      />
      <path
        d="M21.707 13.1615C21.7052 13.1567 21.7031 13.1519 21.7007 13.1473L19.0629 8.06319C19.0407 8.02044 18.9966 7.99353 18.9483 7.99353C18.9481 7.99353 18.9478 7.99362 18.9475 7.99362L17.9092 14.0321C17.9082 14.0378 17.9076 14.0435 17.9073 14.0492C17.8634 14.943 18.17 15.8003 18.7709 16.4632C19.0283 16.7473 19.3268 16.9806 19.654 17.1586C19.7446 17.1341 19.8344 17.1058 19.9228 17.0727C21.4929 16.4863 22.2933 14.7317 21.707 13.1615Z"
        fill="#7C87F1"
      />
      <path
        d="M16.4489 21.1943L14.6913 17.8066C14.6691 17.7638 14.625 17.7369 14.5767 17.7369C14.5285 17.7369 14.4844 17.7638 14.4622 17.8066L12.7044 21.1943C12.7019 21.1989 12.6998 21.2037 12.698 21.2086C12.2991 22.2771 12.8437 23.4711 13.9123 23.8703C14.1243 23.9493 14.3465 23.993 14.5728 24C14.5741 24 14.5754 24 14.5767 24C14.5781 24 14.5794 24 14.5807 24C14.8068 23.993 15.0289 23.9494 15.2411 23.8702C16.3097 23.4712 16.8543 22.2771 16.4553 21.2085C16.4534 21.2036 16.4513 21.1989 16.4489 21.1943Z"
        fill="#5A68E2"
      />
      <path
        d="M16.4552 21.2085C16.4534 21.2036 16.4513 21.1989 16.4489 21.1943L14.6913 17.8066C14.6691 17.7638 14.625 17.7369 14.5767 17.7369C14.563 17.7369 14.5498 17.7396 14.5372 17.7437L13.8156 21.989C13.8146 21.9947 13.814 22.0006 13.8137 22.0065C13.7759 22.8097 14.1502 23.5385 14.7498 23.9878C14.9174 23.9689 15.0821 23.9296 15.2411 23.8703C16.3096 23.4711 16.8543 22.2771 16.4552 21.2085Z"
        fill="#7C87F1"
      />
    </svg>
  ),
};
