import { Box } from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import {
  Scheduler,
  MonthView,
  Appointments,
} from '@devexpress/dx-react-scheduler-material-ui';
import moment, { Moment } from 'moment';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { DateFormats } from 'res/Enums';
import { useAppContext } from 'context/AppContext';
import { CycleDTO, PredictionDTO } from 'gen/client';

interface CycleSchedulerProps {
  currentDate: Moment;
  prediction: PredictionDTO;
  cycles: CycleDTO[];
}
interface ModifiedCycle {
  endDate: string;
  title: string;
  name: string;
  id: number;
  startDate: string;
}
const _CycleScheduler = ({
  currentDate,
  prediction,
  cycles,
}: CycleSchedulerProps) => {
  const { lang } = useAppContext();
  const thisCycle: ModifiedCycle[] = cycles
    .map((item) =>
      !item.endDate
        ? {
            ...item,
            endDate: moment(item.startDate)
              .add(prediction.result.averageDuration - 1, 'days')
              .format(DateFormats.ISONoTime),
            title: '',
            name: 'period',
          }
        : { ...item, title: '', name: 'period', allDay: true }
    )
    .concat(
      prediction.result.nextDates.map((item) => ({
        startDate: item,
        endDate: moment(item)
          .add(prediction.result.averageDuration - 1, 'days')
          .format(DateFormats.ISONoTime),
        title: '',
        name: 'period',
        id: 0,
      }))
    )
    .reduce<ModifiedCycle[]>((prev, cur) => {
      return prev.concat([
        {
          ...cur,
          endDate: moment(cur.endDate)
            .add(1, 'd')
            .format(DateFormats.ISONoTime),
        },
        {
          startDate: moment(cur.startDate)
            .subtract(3, 'day')
            .format(DateFormats.ISONoTime),
          endDate: moment(cur.startDate).add(1).format(DateFormats.ISONoTime),
          title: '',
          name: 'before',
          id: 0,
        },
        {
          startDate: moment(cur.endDate)
            .add(1, 'day')
            .format(DateFormats.ISONoTime),
          endDate: moment(cur.endDate)
            .add(4, 'day')
            .format(DateFormats.ISONoTime),
          title: '',
          name: 'after',
          id: 0,
        },
      ]);
    }, [])
    .sort((a, b) => moment(a.startDate).diff(moment(b.startDate)))
    .reduce<ModifiedCycle[]>((prev, cur, i, src) => {
      if (cur.name === 'period') {
        if (i === 1) {
          return prev.concat(cur);
        } else if (
          !moment(cur.startDate)
            .subtract(15, 'd')
            .isBetween(
              moment(src[i - 4].startDate),
              moment(src[i - 2].endDate),
              'days',
              '[]'
            ) ||
          !moment(cur.startDate)
            .subtract(12, 'd')
            .isBetween(
              moment(src[i - 4].startDate),
              moment(src[i - 2].endDate),
              'days',
              '[]'
            )
        ) {
          return prev.concat(
            ...[
              cur,
              {
                startDate: moment(cur.startDate)
                  .subtract(15, 'd')
                  .format(DateFormats.ISONoTime),
                endDate: moment(cur.startDate)
                  .subtract(12, 'd')
                  .format(DateFormats.ISONoTime),
                title: '',
                name: 'ovulation',
                id: cur.id,
              },
            ]
          );
        } else {
          return prev.concat(cur);
        }
      } else {
        return prev.concat(cur);
      }
    }, [])
    .sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

  return (
    <Box>
      <Scheduler data={thisCycle} locale={lang}>
        <ViewState currentDate={currentDate.format()} />
        <MonthView />
        <Appointments appointmentComponent={Appointment} />
      </Scheduler>
    </Box>
  );
};

const Appointment = ({ children, style, data, ...restProps }: any) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      backgroundColor:
        data.name === 'period'
          ? 'rgb(236, 73, 105)'
          : data.name === 'before'
          ? 'rgb(239, 132, 253)'
          : data.name === 'after'
          ? 'rgb(163, 160, 251)'
          : data.name === 'ovulation'
          ? 'rgb(134, 205, 248)'
          : '#fffff',
    }}
  >
    {children}
  </Appointments.Appointment>
);

export const CycleScheduler = hot(_CycleScheduler);
