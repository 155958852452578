import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Colors } from 'res/Enums';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

export const DateTimePickTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: { textTransform: 'none' },
      containedPrimary: {
        color: Colors.IceWhite,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: Colors.FadeBlack,
      },
    },
    MuiPickersDay: {
      day: {
        color: Colors.FadeBlack,
      },
    },
    MuiTypography: {
      body1: { fontSize: 16 },
      h3: {
        fontSize: '2.5em',
      },
      h4: {
        fontSize: '2em',
      },
    },
    MuiInputLabel: {
      root: {
        color: Colors.Black,
      },
    },
    //.MuiInputLabel-root-475
  },
  palette: {
    primary: { main: Colors.PRIMARY, contrastText: Colors.IceWhite },
    secondary: { main: Colors.SECONDARY },
    text: { primary: Colors.PRIMARY, secondary: Colors.White },
  },
});

const _StyledDatePicker = ({ ...props }: DatePickerProps) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={DateTimePickTheme}>
      <DatePicker
        {...props}
        cancelLabel={t('Cancel')}
        todayLabel={t('Today')}
      />
    </ThemeProvider>
  );
};
export const StyledDatePicker = hot(_StyledDatePicker);
