import { Box, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { ExitToApp, Person, BugReport as BugIcon } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import LogoBig from 'assets/icons/logo-big.png';
import clsx from 'clsx';
import React, { PropsWithChildren, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Colors } from 'res/Enums';
import { navCategories } from 'res/navCategories';
import { NavigationList } from './NavigationList';
import TopNav from './TopNav';
import { useAuthContext } from 'context/AuthContext';
import { useTutorialContext } from 'context/TutorialContext';
import { ChangeLanguageButton } from 'Pages/Intro/ChangeLanguageButton';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: { flexGrow: 1 },
  logo: { width: 130, height: 'auto', cursor: 'pointer' },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    backgroundColor: Colors.IceWhite,
    // minHeight: '40px !important',,
  },

  content: {
    overflow: 'auto',
    flexGrow: 1,
    paddingTop: theme.spacing(3),
  },
  main: {
    overflow: 'auto',
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: Colors.IceWhite,
  },
}));
interface Props extends RouteComponentProps {
  tutorial: boolean;
}
const Nav = ({ children, history, tutorial }: PropsWithChildren<Props>) => {
  const classes = useStyles();
  const [tooltip, setTooltip] = useState(true);
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const context = useTutorialContext();
  const { logout } = useAuthContext();
  const inputEl = useRef<SVGSVGElement | null>(null);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawer = () => {
    if (open === false) setOpen(true);
    else if (open === true) setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setTooltip(false);
    }, 5000);
  }, []);

  useEffect(() => {
    if (context.openT === '2' && !open && !isBelowMd) handleDrawer();
    if (context.openT === '6' && !open && !isBelowMd) handleDrawer();
  }, [context.openT]);

  return (
    <div
      className={classes.root}
      style={tutorial ? { width: 'calc(100vw - 250px)' } : {}}
    >
      <AppBar
        position="fixed"
        // className={clsx(classes.appBar, {
        //   [classes.appBarShift]: open// context.removeTokens();
        // })}
        className={classes.appBar}
        color="secondary"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // className={clsx(classes.menuButton, {
            //   [classes.hide]: open
            // })}
            className={classes.menuButton}
          >
            <MenuIcon ref={inputEl} onClick={handleDrawer} />
          </IconButton>
          <Box className={classes.title}>
            <img
              alt="Healthier"
              src={LogoBig}
              className={classes.logo}
              onClick={() => {
                history.push('/');
              }}
            />
          </Box>
          {/* <Notifications /> */}

          {/* <ChangeLanguageButton /> */}
          {/* <Tooltip
            onPointerEnter={() => setTooltip(true)}
            onPointerLeave={() => setTooltip(false)}
            open={tooltip}
            arrow
            title={
              <Typography style={{ fontSize: 20 }}>
                {t('reportBug').toString()}
              </Typography>
            }
          >
            <IconButton onClick={() => setReportDialog(true)}>
              <BugIcon />
            </IconButton>
          </Tooltip> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isBelowMd ? 'temporary' : 'permanent'}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        style={tutorial ? { position: 'absolute' } : {}}
        onClose={() => setOpen(false)}
        open={open}
      >
        <div className={classes.toolbar}>
          {/* <IconButton onClick={handleDrawer}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
        </div>
        <Divider />
        <List
          style={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            overflow: tutorial ? 'hidden' : 'initial',
          }}
        >
          <NavigationList
            categories={navCategories}
            drawer={handleDrawerOpen}
            tut={tutorial === true ? tutorial : false}
            screen={
              isBelowMd && open
                ? true
                : isBelowMd && !open
                ? false
                : !isBelowMd
                ? false
                : false
            }
          />
        </List>
        <List>
          <ListItem button onClick={() => history.push('/profile')}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText>{t('Profile')}</ListItemText>
          </ListItem>
          <ListItem button onClick={() => logout()}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText>{t('Logout')}</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <main
        className={classes.content}
        style={tutorial ? { paddingTop: 8 } : {}}
      >
        <div className={classes.toolbar} />
        <TopNav />
        {!tutorial ? (
          <div className={classes.main}>{children}</div>
        ) : (
          <div style={{ height: 8, backgroundColor: Colors.IceWhite }} />
        )}
      </main>
    </div>
  );
};
export default withRouter(Nav);
