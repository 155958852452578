import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useConfirm } from 'components/ConfirmDialogProvider';
import { ExamsDrawer } from 'components/Exams/ExamsDrawer';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Colors, DateFormats } from 'res/Enums';
import DescriptionIcon from '@material-ui/icons/Description';
import { CloudUpload, ExpandMore, ExpandLess } from '@material-ui/icons';
import { StyledDatePicker } from 'components/DatePicker';
import moment, { Moment } from 'moment';
import {
  pregnancyControllerGetMyPregnancyExams,
  PregnancyDTO,
  PregnancyPdfDTO,
  usePdfControllerDeleteMyExam,
  usePdfControllerGetMyExams,
  usePregnancyControllerCreateMyPregnancyPdfRelation,
  usePregnancyControllerDeleteMyPregnancyPdf,
  usePregnancyControllerGetPregnancyPdf,
  UserPdfDTO,
  UserPregnancyPdfDTO,
} from 'gen/client';
interface PregnancyExamsProps {
  pregnancy: PregnancyDTO;
}

const _PregnancyExams = ({ pregnancy }: PregnancyExamsProps) => {
  const { t } = useTranslation();
  const [rawPdf, setRawPdf] = useState<PregnancyPdfDTO[]>([]);
  const [pdf, setPdf] = useState<PregnancyPdfDTO[]>([]);
  const [userExams, setUserExams] = useState<UserPregnancyPdfDTO[]>([]);
  const [exams, setExams] = useState<UserPdfDTO[]>([]);
  const [add, setAdd] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [expanded, setExpanded] = useState(-1);
  const [date, setDate] = useState(moment());
  const [examToEdit, setExamToEdit] = useState<UserPdfDTO | undefined>();
  const [selectedPregnancyExam, setSelectedPregnancyExam] =
    useState<PregnancyPdfDTO>({ id: 0, name: '', description: '' });
  const [selectedUserExam, setSelectedUserExam] =
    useState<UserPregnancyPdfDTO | undefined>();
  const confirm = useConfirm();
  const firstUpdate = useRef(true);
  const { data: pdfData } = usePregnancyControllerGetPregnancyPdf();
  const { data: examsData, refetch: examsRefetch } =
    usePdfControllerGetMyExams();
  const deleteMutation = usePregnancyControllerDeleteMyPregnancyPdf();
  const createRelatioMutation =
    usePregnancyControllerCreateMyPregnancyPdfRelation();
  const deleteExamsMutation = usePdfControllerDeleteMyExam();
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowXs = useMediaQuery(theme.breakpoints.down(860));
  const isBelowLg = useMediaQuery(theme.breakpoints.between(1280, 1600));

  const compare = (a: PregnancyPdfDTO, b: PregnancyPdfDTO) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  };

  const compareTime = (a: any, b: any) => {
    if (moment(a.date).isBefore(b.date)) {
      return 1;
    }
    if (moment(a.date).isAfter(b.date)) {
      return -1;
    }
    return 0;
  };

  const getExams = async () => {
    try {
      const data = await pregnancyControllerGetMyPregnancyExams();
      if (data) {
        const tempData = data.data.filter((item) =>
          pregnancy.endDate
            ? moment(item.date).isSameOrAfter(pregnancy?.startDate, 'day') &&
              moment(item.date).isSameOrBefore(pregnancy?.endDate, 'day')
            : moment(item.date).isSameOrAfter(pregnancy?.startDate, 'day')
        );
        setUserExams(tempData);
        if (pdf.length > 0 && rawPdf.length > 0) {
          const temp = [...rawPdf];
          for (let i = 0; i < tempData.length; i++) {
            temp.push(
              rawPdf.filter((item) => item.id === tempData[i].pdfCategory)[0]
            );
          }
          temp.sort(compare);
          setPdf(temp);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (examsData) setExams(examsData.data);
  }, [examsData]);

  useEffect(() => {
    getExams();
    if (pregnancy.endDate) setDate(moment(pregnancy.endDate));
  }, [pregnancy]);

  const handleDelete = async (pdf: UserPregnancyPdfDTO) => {
    try {
      userExams.filter((item) => item.pdfId !== null && pdf.id === item.id)
        .length > 0 &&
        confirm({
          title: t('PregnancyPdfD3'),
          description: t('PregnancyPdfD4'),
        })
          .then((e) => deleteFile(pdf))
          .catch();
      const response = await deleteMutation.mutateAsync({ id: pdf.id });
      if (response) {
        toast(t('ExamDeleted'));
        getExams();
      } else {
        toast(t('FailedDeleting'), { containerId: 'deleted' });
      }
    } catch (error) {}
  };

  const handleAdd = async (pdf: PregnancyPdfDTO) => {
    try {
      setSelectedPregnancyExam(pdf);
      setDialog(true);
    } catch (error) {}
  };

  const handlePdf = async () => {
    setDialog(false);
    setAdd(true);
  };

  const handleUpload = async (pdf: PregnancyPdfDTO, i: number) => {
    setSelectedPregnancyExam(pdf);
    setSelectedUserExam(
      userExams.filter((item) => item.pdfCategory === pdf.id)[i]
    );
    setAdd(true);
  };

  const addPregnancyPdf = async (id?: any) => {
    try {
      const resp = await createRelatioMutation.mutateAsync({
        data: {
          pregnancyCategory: selectedPregnancyExam,
          userPdf: id,
          date: date.format(DateFormats.ISONoTime),
        },
      });
      if (resp) {
        toast(t('Success'));
        getExams();
        setDialog(false);
      } else {
        toast(t('FailedAdding'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('FailedAdding'), { containerId: 'deleted' });
    }
  };

  const deleteFile = async (file: UserPregnancyPdfDTO) => {
    try {
      if (file.pdfId) {
        const r = await deleteExamsMutation.mutateAsync({ id: file.pdfId });
        if (r) {
          toast(t('ExamDeleted'));
          getExams();
        } else {
          toast(t('Failed'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (pdfData) {
      setRawPdf(pdfData.data);
      setPdf(pdfData.data);
    }
  }, [pdfData]);

  useEffect(() => {
    examsRefetch();
  }, []);

  useEffect(() => {
    if (pdf.length > 0 && firstUpdate.current === true) {
      firstUpdate.current = false;
      getExams();
    }
  }, [pdf]);

  return (
    <Box style={{ overflow: 'auto' }}>
      <Table size="small">
        <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
          <TableRow>
            <TableCell
              padding={isBelowXs || isBelowLg ? 'none' : 'default'}
              style={
                isBelowXs || isBelowLg
                  ? {
                      color: '#FFFFFF',
                      fontSize: 13,
                      padding: 0,
                      paddingLeft: 6,
                    }
                  : { color: '#FFFFFF' }
              }
            >
              {t('ExamName')}
            </TableCell>
            <TableCell
              padding={isBelowXs || isBelowLg ? 'none' : 'default'}
              style={
                isBelowXs || isBelowLg
                  ? {
                      color: '#FFFFFF',
                      fontSize: 13,
                      padding: 0,
                      paddingLeft: 6,
                    }
                  : { color: '#FFFFFF' }
              }
            >
              {t('Date')}
            </TableCell>
            <TableCell
              padding={isBelowXs || isBelowLg ? 'none' : 'default'}
              style={
                isBelowXs || isBelowLg
                  ? {
                      color: '#FFFFFF',
                      fontSize: 13,
                      padding: 0,
                      paddingLeft: 6,
                    }
                  : { color: '#FFFFFF' }
              }
            >
              {t('Preventivetitle')}
            </TableCell>
            <TableCell
              padding={isBelowXs || isBelowLg ? 'none' : 'default'}
              style={
                isBelowXs || isBelowLg
                  ? {
                      color: '#FFFFFF',
                      fontSize: 13,
                      padding: 0,
                      paddingLeft: 6,
                    }
                  : { color: '#FFFFFF' }
              }
            >
              {t('PregnancyExams')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {pdf.map((row, i, array) => (
            <Fragment key={i}>
              <TableRow>
                <TableCell
                  style={
                    isBelowXs || isBelowLg
                      ? { fontSize: 13, padding: 0, paddingLeft: 6 }
                      : {}
                  }
                >
                  {row.name}
                </TableCell>
                <TableCell
                  padding={isBelowXs || isBelowLg ? 'none' : 'default'}
                  style={
                    isBelowXs || isBelowLg
                      ? { fontSize: 13, padding: 0, textAlign: 'center' }
                      : {}
                  }
                >
                  {userExams.filter((exam) => exam.pdfCategory === row.id)
                    .length > 0 &&
                  array.filter((pdf, j) => pdf.id === row.id && i === j + 1)
                    .length > 0
                    ? moment(
                        userExams
                          .sort(compareTime)
                          .filter((exam) => exam.pdfCategory === row.id)
                          .filter(
                            (exam, g, arr) =>
                              arr[
                                i -
                                  array.findIndex(
                                    (pdf) => pdf.id === exam.pdfCategory
                                  ) -
                                  1
                              ] === exam
                          )[0]?.date ?? undefined
                      ).format(DateFormats.GrNoTime)
                    : ''}
                </TableCell>
                <TableCell
                  padding={isBelowXs || isBelowLg ? 'none' : 'default'}
                  style={
                    isBelowXs || isBelowLg
                      ? {
                          fontSize: 13,
                          textAlign: 'center',
                          padding: 0,
                          paddingLeft: 6,
                        }
                      : { textAlign: 'center' }
                  }
                >
                  <Checkbox
                    color="primary"
                    checked={
                      userExams.filter((exam) => exam.pdfCategory === row.id)
                        .length > 0 &&
                      array.filter((pdf, j) => pdf.id === row.id && i === j + 1)
                        .length > 0
                    }
                    onChange={(e) =>
                      !e.target.checked
                        ? userExams.filter(
                            (item) =>
                              item.pdfId !== undefined &&
                              row.id === item.pdfCategory
                          )
                          ? confirm({
                              title: t('PregnancyPdfD1'),
                              description: t('PregnancyPdfD2'),
                            })
                              .then(() =>
                                handleDelete(
                                  userExams
                                    .filter(
                                      (item) => row.id === item.pdfCategory
                                    )
                                    .filter(
                                      (exam, g, arr) =>
                                        arr[
                                          i -
                                            array.findIndex(
                                              (pdf) =>
                                                pdf.id === exam.pdfCategory
                                            ) -
                                            1
                                        ] === exam
                                    )[0]
                                )
                              )
                              .catch()
                          : handleDelete(
                              userExams
                                .filter((item) => row.id === item.pdfCategory)
                                .filter(
                                  (exam, g, arr) =>
                                    arr[
                                      i -
                                        array.findIndex(
                                          (pdf) => pdf.id === exam.pdfCategory
                                        ) -
                                        1
                                    ] === exam
                                )[0]
                            )
                        : handleAdd(row)
                    }
                  />
                </TableCell>
                <TableCell
                  padding={isBelowXs || isBelowLg ? 'none' : 'default'}
                  style={
                    isBelowXs || isBelowLg
                      ? {
                          fontSize: 13,
                          textAlign: 'center',
                          padding: 0,
                          paddingLeft: 6,
                        }
                      : { textAlign: 'center' }
                  }
                >
                  {userExams.filter((exam) => exam.pdfCategory === row.id)
                    .length > 0 &&
                    array.filter((pdf, j) => pdf.id === row.id && i === j + 1)
                      .length > 0 && (
                      <IconButton>
                        {exams.filter((exam) => {
                          return userExams
                            .sort(compareTime)
                            .filter((item) => item.pdfCategory === row.id)
                            .filter(
                              (item, g, arr) =>
                                arr[
                                  i -
                                    array.findIndex(
                                      (pdf) => pdf.id === item.pdfCategory
                                    ) -
                                    1
                                ] === item
                            )
                            .filter((item) => item.pdfId === exam.id)[0];
                        }).length > 0 ? (
                          <DescriptionIcon
                            type="button"
                            onClick={(e) => {
                              window.open(
                                exams.filter(
                                  (item) =>
                                    userExams
                                      .sort(compareTime)
                                      .filter(
                                        (item) => item.pdfCategory === row.id
                                      )
                                      .filter(
                                        (item, g, arr) =>
                                          arr[
                                            i -
                                              array.findIndex(
                                                (pdf) =>
                                                  pdf.id === item.pdfCategory
                                              ) -
                                              1
                                          ] === item
                                      )[0].pdfId === item.id
                                )[0].url,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            }}
                          />
                        ) : (
                          <CloudUpload
                            onClick={() =>
                              handleUpload(
                                row,
                                i -
                                  array.findIndex((pdf) => pdf.id === row.id) -
                                  1
                              )
                            }
                          />
                        )}
                      </IconButton>
                    )}
                </TableCell>
                <TableCell
                  padding={isBelowXs || isBelowLg ? 'none' : 'default'}
                  style={
                    isBelowXs || isBelowLg
                      ? { fontSize: 13, padding: 0, paddingLeft: 6 }
                      : {}
                  }
                >
                  {expanded === i ? (
                    <ExpandLess onClick={() => setExpanded(-1)} />
                  ) : (
                    <ExpandMore onClick={() => setExpanded(i)} />
                  )}
                </TableCell>
              </TableRow>
              {expanded === i && (
                <TableRow style={{ color: Colors.Black, padding: '0px 8px' }}>
                  <TableCell colSpan={5}>{row.description}</TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <Drawer
        open={add}
        anchor="right"
        PaperProps={{ style: { width: '30vw', minWidth: 430 } }}
      >
        <ExamsDrawer
          open={add}
          time={checkBox ? date : undefined}
          onClose={() => {
            setExamToEdit(undefined);
            setAdd(false);
            if (
              userExams.filter(
                (item) => item.pdfCategory !== selectedPregnancyExam.id
              ).length > 0 ||
              userExams.length === 0
            )
              setDialog(true);
            setCheckBox(false);
          }}
          exam={examToEdit}
          userExam={selectedUserExam?.id ?? undefined}
          onUpload={(resp) => {
            getExams();
            examsRefetch();
            setAdd(false);
            if (resp !== undefined && resp?.resp === true) {
              addPregnancyPdf(resp.id);
            }
            setSelectedPregnancyExam({ id: 0, name: '', description: '' });
            setCheckBox(false);
          }}
          onDelete={() => {
            examsRefetch();
            getExams();
            setAdd(false);
            setCheckBox(false);
          }}
          pregnancy={true}
          pregnancyType={selectedPregnancyExam}
        />
      </Drawer>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogTitle
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          {t('PregnancyPdfQ')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('PregnancyPdfQ1').concat(selectedPregnancyExam.name).concat(';')}
          </DialogContentText>
          <StyledDatePicker
            disableFuture
            format={DateFormats.GrNoTime}
            label={t('DateOfExam')}
            value={date}
            onChange={(date) => setDate(date as Moment)}
            autoOk
            fullWidth
            minDate={
              pregnancy !== undefined
                ? moment(pregnancy.startDate)
                : moment('1900-01-01')
            }
            maxDate={
              pregnancy !== undefined && pregnancy.endDate
                ? moment(pregnancy.endDate)
                : moment()
            }
          />
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography style={{ color: Colors.Black }}>
              {t('PregnancyPdfQ2')}
            </Typography>
            <Checkbox
              color="primary"
              checked={checkBox}
              onClick={() => setCheckBox(!checkBox)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialog(false);
              setDate(moment());
              setCheckBox(false);
              setSelectedPregnancyExam({ id: 0, name: '', description: '' });
            }}
          >
            {t('Cancel')}
          </Button>
          <Button onClick={() => (checkBox ? handlePdf() : addPregnancyPdf())}>
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({}));
export const PregnancyExams = hot(_PregnancyExams);
