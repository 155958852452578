import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Theme,
  List,
  Popover,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useAppContext } from 'context/AppContext';
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Colors } from 'res/Enums';
import { NavCategory, NavItem, navCategories } from 'res/navCategories';
import { useTutorialContext } from 'context/TutorialContext';
import { Demo } from './Demo';

interface NavigationListProps {
  drawer: () => void;
  categories: NavCategory[];
  tut: boolean;
  screen: boolean;
}
const _NavigationList = ({
  categories,
  drawer,
  tut,
  screen,
}: NavigationListProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [panel, setPanel] = useState(10);
  const [selected, setSelected] = useState<NavItem>();
  const [cat, setCat] = useState<NavCategory>();
  const { navCat, setNavCategory } = useAppContext();
  const tutorial = useTutorialContext();
  const inputEl1 = useRef<HTMLInputElement | null>(null);
  const inputEl2 = useRef<HTMLInputElement | null>(null);
  const inputEl3 = useRef<HTMLInputElement | null>(null);
  const inputEl4 = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));

  const setNavRefresh = () => {
    const nav = history.location.pathname.split('/');
    const pathName = `/${nav[1]}`;
    const found = navCat.find((item, i) => {
      if (item.url === `${pathName}`) return item.url;
    });
    setSelected(found);
  };

  const setCatRefresh = () => {
    const cat = navCategories.find((element) => {
      return element.items.find((e) => e === selected);
    });
    setCat(cat);
  };

  useEffect(() => {
    setCatRefresh();
  }, [selected]);

  useEffect(() => {
    setNavRefresh();
  }, [navCat]);

  useEffect(() => {
    setNavRefresh();
  }, [history.location.pathname]);

  useEffect(() => {
    if (tutorial.openT === '2') {
      setPanel(10);
    }
    if (tutorial.openT === '3') {
      setPanel(0);
      drawer();
    }
    if (tutorial.openT === '4') setPanel(1);
    if (tutorial.openT === '5') setPanel(2);
    if (tutorial.openT === '6') setPanel(3);
    if (tutorial.me?.tutorial === false && parseInt(tutorial.openT) > 6)
      setPanel(0);
    if (tutorial.me?.tutorial === false && parseInt(tutorial.openT) > 17)
      setPanel(1);
    if (tutorial.me?.tutorial === false && parseInt(tutorial.openT) > 21)
      setPanel(2);
  }, [tutorial.openT]);

  return (
    <Fragment>
      {categories.map((category, i) => (
        <ListItem disableGutters button key={i}>
          <ExpansionPanel
            expanded={panel === i}
            onChange={() => {
              panel !== i ? setPanel(i) : setPanel(10);
              setNavRefresh();
            }}
            style={{
              width: 250,
              backgroundColor: 'unset',
              boxShadow: 'unset',
            }}
          >
            <ExpansionPanelSummary
              classes={{
                root:
                  tutorial.me?.tutorial === false &&
                  panel !== i &&
                  panel !== 10 &&
                  parseInt(tutorial.openT) < 7
                    ? classes.summary
                    : category === cat
                    ? classes.sum
                    : classes.summary,
                expanded:
                  tutorial.me?.tutorial === false &&
                  panel === i &&
                  parseInt(tutorial.openT) < 7
                    ? classes.sum
                    : classes.expanded,
                content: classes.content,
              }}
            >
              <ListItem
                onClick={() => drawer()}
                style={{ padding: 0, paddingLeft: 16 }}
              >
                <ListItemIcon>
                  {tutorial.me?.tutorial === false &&
                  panel !== i &&
                  panel !== 10 &&
                  parseInt(tutorial.openT) < 7
                    ? category.icon.idle
                    : category === cat && panel !== i
                    ? category.icon.selected
                    : category.icon.idle}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style:
                      tutorial.me?.tutorial === false &&
                      panel !== i &&
                      panel !== 10 &&
                      parseInt(tutorial.openT) < 7
                        ? { fontSize: 14, color: Colors.Black }
                        : category === cat && panel !== i
                        ? { fontSize: 14, color: Colors.IceWhite }
                        : { fontSize: 14, color: Colors.Black },
                  }}
                >
                  {t(category.label)}
                </ListItemText>
              </ListItem>
            </ExpansionPanelSummary>
            <Divider />
            <ExpansionPanelDetails
              style={{ display: 'flex', flexDirection: 'column', padding: 0 }}
              ref={
                i === 0
                  ? inputEl1
                  : i === 1
                  ? inputEl2
                  : i === 2
                  ? inputEl3
                  : inputEl4
              }
            >
              <List component="nav">
                {category.items.map((item, i) => (
                  <ListItem
                    key={i}
                    selected={item === selected}
                    button
                    style={{ color: Colors.Black }}
                    classes={{ selected: classes.selected }}
                    onClick={(e) => {
                      history.push(item.url);
                      setNavCategory(category.items);
                      drawer();
                    }}
                  >
                    <ListItemIcon>
                      {item === selected ? item.icon.selected : item.icon.idle}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ style: { fontSize: 14 } }}
                    >
                      {t(item.label)}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </ListItem>
      ))}
      {tutorial.me?.tutorial === false &&
        tutorial.openT === '2' &&
        tut &&
        (isBelowMd ? screen === true : true) && (
          <Fragment>
            <Popover
              open={tutorial.me.tutorial === false}
              anchorOrigin={{
                horizontal: screen ? 'center' : 'left',
                vertical: screen ? 'top' : 'center',
              }}
              PaperProps={{
                style: {
                  marginLeft: screen ? '-12%' : 300,
                  marginTop: screen ? '18%' : '-14%',
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  width: screen ? '50vw' : '33vw',
                },
              }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                {t('Tut5')}
              </Typography>
            </Popover>
            {tutorial.me.tutorial === false && <Demo />}
          </Fragment>
        )}
      {tutorial.me?.tutorial === false &&
        (tutorial.openT === '6' ||
          tutorial.openT === '3' ||
          tutorial.openT === '4' ||
          tutorial.openT === '5') &&
        tut &&
        (isBelowMd ? screen === true : true) && (
          <Fragment>
            <Popover
              open={tutorial.me?.tutorial === false}
              anchorEl={inputEl1.current}
              PaperProps={{
                style: {
                  marginLeft: 40,
                  marginTop:
                    tutorial.openT === '4'
                      ? -24
                      : tutorial.openT === '5'
                      ? 60
                      : tutorial.openT === '3'
                      ? 0
                      : 140,
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  width: screen ? '50vw' : '30vw',
                  textAlign: 'center',
                },
              }}
              anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                {tutorial.openT === '3'
                  ? t('Tut7')
                  : tutorial.openT === '4'
                  ? t('Tut10')
                  : tutorial.openT === '5'
                  ? t('Tut12')
                  : t('Tut15')}
              </Typography>
              {tutorial.me.tutorial === false && tutorial.openT !== '3' && (
                <Demo />
              )}
            </Popover>
            <Popover
              open={tutorial.me.tutorial === false && tutorial.openT === '3'}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              PaperProps={{
                style: {
                  marginTop: 155,
                  marginLeft: screen ? '-6' : '-10%',
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  width: '60vw',
                },
              }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                {t('Tut6')}
              </Typography>
              {tutorial.me.tutorial === false && <Demo />}
            </Popover>
          </Fragment>
        )}
    </Fragment>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  summary: {
    padding: 0,
    margin: '0px !important',
  },
  sum: {
    padding: 0,
    margin: '0px !important',
    backgroundColor: '#5ca595 !important',
  },
  expanded: {
    margin: '0px !important',
    backgroundColor: 'unset !important',
  },
  content: { margin: 0 },
  selected: {
    backgroundColor: '#5ca595 !important',
    color: '#F5F6F7 !important',
  },
}));
export const NavigationList = hot(_NavigationList);
