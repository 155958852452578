import { meControllerUpdateMe, UpdateUser } from 'gen/client';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useEffect,
} from 'react';
import { useAuthContext } from './AuthContext';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { AuthStatus } from 'res/Enums';
import { useMeControllerGetMe, UserDto } from './../gen/client';

interface TutorialContextProps {
  me: UserDto | undefined;
  openT: string;
  getMe: () => void;
  setOpenT: React.Dispatch<React.SetStateAction<string>>;
  cont: number;
  setCont: React.Dispatch<React.SetStateAction<number>>;
}
const initialContext: TutorialContextProps = {
  me: undefined,
  openT: '0',
  cont: 0,
  getMe: () => {},
  setOpenT: () => {},
  setCont: () => {},
};
export const TutorialContext = createContext(initialContext);

const _TutorialProvider = ({ children }: PropsWithChildren<{}>) => {
  const [me, setMe] = useState<UserDto | undefined>(undefined);
  const [openT, setOpenT] = useState('0');
  const [cont, setCont] = useState(0);
  const { authStatus } = useAuthContext();
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down(720));
  const { data, refetch: getMe } = useMeControllerGetMe();

  useEffect(() => {
    if (data) setMe(data.data);
  }, [data]);

  const handleEnd = (tut: boolean) => {
    meControllerUpdateMe({
      ...(me as unknown as UpdateUser),
      tutorial: tut,
    })
      .then(() => {
        getMe();
      })
      .catch();
  };

  useEffect(() => {
    if (authStatus === AuthStatus.LOGGED_OUT) {
      setOpenT('0');
      setMe(undefined);
      setCont(0);
    } else if (AuthStatus.LOGGED_IN) {
      getMe();
    }
  }, [authStatus]);

  useEffect(() => {
    if (parseInt(openT) > 0 && isBelowMd) handleEnd(true);
  }, [isBelowMd]);

  return (
    <TutorialContext.Provider
      value={{
        me,
        openT,
        cont,
        getMe,
        setOpenT,
        setCont,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
};
export const TutorialProvider = _TutorialProvider;
export const useTutorialContext = () => useContext(TutorialContext);
