import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import chatIcon from 'assets/icons/chat.png';
import React, { useEffect, useState } from 'react';
import { uuid } from 'uuidv4';
import { EventMessageDialog } from './EventMessageDialog';
import { useTranslation } from 'react-i18next';
import { DateFormats } from 'res/Enums';
import moment from 'moment';
import {
  UserDto,
  useEventsControllerGetMyEventMessages,
  UserMessagesDto,
  useEventsControllerReadMessages,
} from './../../gen/client';
interface WelcomeProps {
  me: UserDto;
}
export const WelcomeMessage = ({ me }: WelcomeProps) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<UserMessagesDto[]>([]);
  const { t } = useTranslation();
  const { data, isLoading, refetch } = useEventsControllerGetMyEventMessages();
  const readMessages = useEventsControllerReadMessages();
  useEffect(() => {
    if (data) {
      setMessages(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (messages.length > 0) {
      readMessages.mutateAsync({
        data: {
          messages,
          now: moment().format(DateFormats.ISO),
        },
      });
    }
  }, [messages]);

  if (isLoading) return <CircularProgress />;
  return (
    <Paper className={classes.root}>
      {me && (
        <Box display="flex" flexDirection="column" flex="1 1 auto">
          <Box display="flex">
            <img alt="" src={chatIcon} style={{ marginRight: '5%' }} />
            <Typography>
              {t('Hi')} <b>{me.name}</b>
            </Typography>
          </Box>
          <Box marginTop={2}>
            {messages.length > 0 ? (
              messages.map((message) => (
                <EventMessageDialog
                  key={uuid()}
                  event={message}
                  onDecline={() => refetch()}
                />
              ))
            ) : (
              <Box> {t('Welcome')} </Box>
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      color: 'black',
      position: 'relative',
      display: 'flex',
      flex: '1 1 auto',
    },
  })
);
