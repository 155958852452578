import {
  Box,
  Button,
  CircularProgress,
  Container,
  createMuiTheme,
  Divider,
  Drawer,
  FormLabel,
  Grid,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { FitnessDTO, UserFitnessDTO } from 'gen/client';
import clsx from 'clsx';
import { StyledBarChart } from 'components/BarChart';
import { FilterBar, useFilter } from 'components/Filter/FilterBar';
import { FitnessBox } from 'components/Fitness/FitnessBox';
import { ShortInfo } from 'components/Fitness/ShortInfo';
import { Statistics } from 'components/Fitness/Statistics';
import { TablePaginationActions } from 'components/TablePaginationActions';
import moment from 'moment';
import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Tooltip as TooltipChart, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';
import { EmptyPage } from 'components/EmptyPage';
import Empty from 'assets/icons/fitnessEmpty.svg';
import {
  useFitnessControllerDeleteMyFitness,
  useFitnessControllerGetFitnessTypes,
  useFitnessControllerGetMyFitnessByType,
} from 'gen/client';

const SelectTheme = createMuiTheme({
  overrides: {
    MuiList: {
      padding: { padding: 0, paddingTop: 0, paddingBottom: 0 },
      root: { width: '100%' },
    },
    MuiButton: {
      root: { textTransform: 'none' },
      containedPrimary: {
        color: Colors.IceWhite,
      },
    },
  },
  palette: {
    primary: { main: Colors.PRIMARY },
    secondary: { main: Colors.SECONDARY },
    text: { primary: Colors.PRIMARY, secondary: Colors.FadeBlack },
  },
});

const Fitness = () => {
  const [FitnessTypes, setFitnessTypes] = useState<FitnessDTO[]>([]);
  const [activeFitness, setActiveFitness] = useState<FitnessDTO | null>(null);
  const [userFitness, setUserFitness] = useState<UserFitnessDTO[]>([]);
  const [filterData, setFilterData] = useState<UserFitnessDTO[]>([]);
  const [filterDataDistance, setFilterDataDistance] = useState<
    UserFitnessDTO[]
  >([]);
  const [filterDataTime, setFilterDataTime] = useState<UserFitnessDTO[]>([]);
  const [rawDistance, setRawDistance] = useState<UserFitnessDTO[]>([]);
  const [rawTime, setRawTime] = useState<UserFitnessDTO[]>([]);
  const [id, setID] = useState('1');
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down(850));
  const isBelowXs = useMediaQuery(theme.breakpoints.down(600));
  const isBelowMin = useMediaQuery(theme.breakpoints.down(500));
  const isBelowXl = useMediaQuery(theme.breakpoints.down(1500));
  const deleteMyFitness = useFitnessControllerDeleteMyFitness();
  const {
    data: fitnessData,
    refetch: fitnessRefetch,
    isLoading: fitnessLoading,
  } = useFitnessControllerGetMyFitnessByType(id);
  const { data: fitnessTypes, isLoading: typesLoading } =
    useFitnessControllerGetFitnessTypes();

  useEffect(() => {
    if (fitnessData) {
      setUserFitness(fitnessData.data);
      setFilterData(fitnessData.data);
    }
  }, [fitnessData]);

  useEffect(() => {
    if (fitnessTypes) {
      setFitnessTypes(fitnessTypes.data);
      setActiveFitness(
        fitnessTypes.data.filter((fitness) => fitness.id === parseInt(id))[0]
      );
    }
  }, [fitnessTypes]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fitnessToEdit, setFitnessToEdit] = useState<UserFitnessDTO>();
  const [activeFilter, setActiveFilter] = useFilter();
  const [activeFilter2, setActiveFilter2] = useFilter();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteFitness = async (id: number) => {
    try {
      const response = await deleteMyFitness.mutateAsync({ id });
      if (response) {
        toast(t('FitnessDeleted'));
        fitnessRefetch();
      } else {
        toast(t('FailedDeleting'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const filterDistance = async (rawData: UserFitnessDTO[]) => {
    const data = rawData.filter((e) => e.distance !== null);
    setRawDistance(data);
  };

  const filterTime = async (rawData: UserFitnessDTO[]) => {
    const data = rawData.filter((e) => e.activityTime !== null);
    setRawTime(data);
  };

  useEffect(() => {
    setActiveFitness(
      FitnessTypes.filter((fitness) => fitness.id === parseInt(id))[0]
    );
  }, [id]);

  useEffect(() => {
    if (activeFitness !== null) fitnessRefetch();
  }, [activeFitness]);

  useEffect(() => {
    filterDistance(filterData);
    filterTime(filterData);
  }, [filterData]);

  if (activeFitness && !fitnessLoading && !typesLoading) {
    return (
      <ThemeProvider theme={SelectTheme}>
        <Box className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={!isBelowSm ? 2 : !isBelowXs ? 8 : 6}>
              <Typography
                style={{
                  fontSize: isBelowXl && !isBelowSm ? 18 : 20,
                  fontWeight: 'bold',
                  color: Colors.Black,
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {t('FitStat')}
              </Typography>
            </Grid>
            {!isBelowSm && (
              <Fragment>
                <Grid item xs={3}>
                  <Paper
                    style={{
                      background: 'rgba(196, 196, 196, 0.27)',
                      height: '100%',
                      width:
                        isBelowXl && !isBelowSm
                          ? '100%'
                          : isBelowSm && !isBelowXs
                          ? '80%'
                          : '125%',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '-25%',
                    }}
                  >
                    <List style={{ padding: 0 }}>
                      <ListItem>
                        <Grid container spacing={2}>
                          {!(isBelowXl && !isBelowSm) && (
                            <Grid
                              item
                              xs={7}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexGrow: 1,
                              }}
                            >
                              <FormLabel style={{ fontSize: 14 }}>
                                {t('FitnessType')} :
                              </FormLabel>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={isBelowXl && !isBelowSm ? 12 : 5}
                            style={{
                              display: 'flex',
                              justifyContent:
                                isBelowXl && !isBelowSm ? 'center' : 'flex-end',
                            }}
                          >
                            <Select
                              value={id}
                              onChange={(e) => setID(e.target.value as string)}
                              style={{
                                fontSize: isBelowSm ? 0 : 14,
                                fontWeight: 'bold',
                                width: isBelowSm
                                  ? '-webkit-fill-available'
                                  : undefined,
                              }}
                            >
                              {FitnessTypes.map((fitness, i) => (
                                <MenuItem
                                  className={classes.item}
                                  value={fitness.id}
                                  key={i}
                                >
                                  {t(fitness.name)}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    style={{
                      background: 'rgba(196, 196, 196, 0.27)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      width:
                        isBelowXl && !isBelowSm
                          ? 'calc(540px - 12vw)'
                          : isBelowSm && !isBelowXs
                          ? '130%'
                          : 'calc(540px - 8vw)',
                      marginLeft:
                        isBelowXl && !isBelowSm
                          ? '-15%'
                          : isBelowSm && !isBelowXs
                          ? '-20%'
                          : 0,
                    }}
                  >
                    <FilterBar<UserFitnessDTO>
                      data={userFitness}
                      enables={{ date: true }}
                      onFilter={(data) => setFilterData(data)}
                      dateKey="time"
                      activeFilter={activeFilter2}
                      setActiveFilter={setActiveFilter2}
                    />
                    {/* <List style={{ padding: 0 }}>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid item xs={7}>
                        <FormLabel style={{ fontSize: 14 }}>
                          Δείξε μου στάτιστικά
                        </FormLabel>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Select
                          value={time}
                          onChange={e => setTime(e.target.value as TimeProps)}
                          style={{
                            fontSize: 14,
                            fontWeight: 'bold'
                          }}
                        >
                          <MenuItem
                            className={classes.item}
                            value={TimeProps.DAY}
                          >
                            Day
                          </MenuItem>
                          <MenuItem
                            className={classes.item}
                            value={TimeProps.LASTDAY}
                          >
                            Yesterday
                          </MenuItem>
                          <MenuItem
                            className={classes.item}
                            value={TimeProps.WEEK}
                          >
                            Week
                          </MenuItem>
                          <MenuItem
                            className={classes.item}
                            value={TimeProps.FORTNIGHT}
                          >
                            Fortnight
                          </MenuItem>
                          <MenuItem
                            className={classes.item}
                            value={TimeProps.MONTH}
                          >
                            Month
                          </MenuItem>
                          <MenuItem
                            className={classes.item}
                            value={TimeProps.THREEMONTH}
                          >
                            Last 3 Months
                          </MenuItem>
                          <MenuItem
                            className={classes.item}
                            value={TimeProps.ALL}
                          >
                            All
                          </MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List> */}
                  </Paper>
                </Grid>
              </Fragment>
            )}
            <Grid item xs={!isBelowSm ? 3 : !isBelowXs ? 4 : 6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon style={{ fontSize: 22 }} />}
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  height: '100%',
                  width: '100%',
                  padding: '0px 6px',
                  marginLeft: isBelowSm ? 0 : 'calc(200px - 10vw)',
                }}
                onClick={() => setOpen(true)}
              >
                {t('FitnessReg')}
              </Button>
              <Drawer
                anchor="right"
                open={open}
                onClose={() => {
                  setOpen(false);
                  setFitnessToEdit(undefined);
                }}
              >
                <FitnessBox
                  onAdd={() => {
                    fitnessRefetch();
                    setOpen(false);
                  }}
                  onEdit={() => {
                    fitnessRefetch();
                    setOpen(false);
                    setFitnessToEdit(undefined);
                  }}
                  onCancel={() => {
                    setFitnessToEdit(undefined);
                    setOpen(false);
                  }}
                  fitness={fitnessToEdit}
                  active={activeFitness}
                  types={FitnessTypes}
                />
              </Drawer>
            </Grid>
            {isBelowSm && (
              <Fragment>
                <Grid item xs={!isBelowMin ? 6 : 12}>
                  <Paper
                    style={{
                      background: 'rgba(196, 196, 196, 0.27)',
                      height: '100%',
                      width:
                        isBelowSm && !isBelowXs
                          ? '80%'
                          : isBelowSm && !isBelowMin
                          ? '85'
                          : '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: isBelowMin ? '8px 0px' : 0,
                    }}
                  >
                    <List style={{ padding: 0 }}>
                      <ListItem>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={7}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexGrow: 1,
                            }}
                          >
                            <FormLabel
                              style={{ fontSize: isBelowXs ? 12 : 14 }}
                            >
                              {t('FitnessType')} :
                            </FormLabel>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Select
                              value={id}
                              onChange={(e) => setID(e.target.value as string)}
                              style={{
                                fontSize: isBelowSm ? 0 : 14,
                                fontWeight: 'bold',
                                width: isBelowSm
                                  ? '-webkit-fill-available'
                                  : undefined,
                              }}
                            >
                              {FitnessTypes.map((fitness, i) => (
                                <MenuItem
                                  className={classes.item}
                                  value={fitness.id}
                                  key={i}
                                >
                                  {t(fitness.name)}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>
                <Grid item xs={!isBelowMin ? 6 : 12}>
                  <Paper
                    style={{
                      background: 'rgba(196, 196, 196, 0.27)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      padding: '16px 0px',
                      width:
                        isBelowSm && !isBelowXs
                          ? '130%'
                          : isBelowSm && !isBelowMin
                          ? '125%'
                          : '100%',
                      marginLeft: isBelowSm && !isBelowMin ? '-20%' : 0,
                    }}
                  >
                    <FilterBar<UserFitnessDTO>
                      data={userFitness}
                      enables={{ date: true }}
                      onFilter={(data) => setFilterData(data)}
                      dateKey="time"
                      activeFilter={activeFilter2}
                      setActiveFilter={setActiveFilter2}
                    />
                  </Paper>
                </Grid>
              </Fragment>
            )}
          </Grid>
          <Statistics fitness={filterData} active={activeFitness} />
          <Container maxWidth={false} className={classes.container1}>
            {userFitness.length > 0 ? (
              <Box
                display="flex"
                className={classes.padd}
                style={isBelowXs ? { padding: 0 } : {}}
              >
                <Box
                  display="flex"
                  style={{
                    width: isBelowXs ? '100%' : isBelowSm ? '75%' : '60%',
                  }}
                >
                  <ShortInfo latest={userFitness[0]} />
                </Box>
                {!isBelowXs && (
                  <Divider
                    style={{
                      marginTop: 50,
                      backgroundColor: '#E3DFFA',
                    }}
                  />
                )}
                {activeFitness.hasDistance === true && (
                  <Box
                    className={clsx([classes.graph])}
                    style={isBelowXs ? { padding: 1 } : {}}
                  >
                    <FilterBar<UserFitnessDTO>
                      data={rawDistance}
                      enables={{ group: true }}
                      onFilter={(data) => setFilterDataDistance(data)}
                      dateKey="time"
                      dataKey="distance"
                      activeFilter={activeFilter}
                      setActiveFilter={setActiveFilter}
                    />
                    {filterDataDistance.length > 0 ? (
                      <StyledBarChart
                        width={filterDataDistance.length < 3 ? 200 : undefined}
                        aspect={3}
                        data={filterDataDistance
                          .map(({ time, activityTime, distance, ...rest }) => ({
                            ...rest,
                            time: moment(time).format(DateFormats.GR),
                            distance: distance && distance,
                          }))
                          .slice(0, 100)
                          .reverse()}
                        dataKey="distance"
                      >
                        <XAxis
                          dataKey="time"
                          axisLine={false}
                          tickLine={false}
                          tick={{
                            fontSize: isBelowXs ? 12 : 16,
                          }}
                        />
                        <YAxis
                          dataKey="distance"
                          axisLine={false}
                          tickLine={false}
                          tick={{
                            fontSize: isBelowXs ? 12 : 16,
                          }}
                          label={{
                            value: 'Km',
                            angle: -90,
                            offset: 10,
                            position: 'insideLeft',
                            textAnchor: 'middle',
                            fontSize: isBelowXs ? 12 : 16,
                          }}
                        />
                        <TooltipChart
                          formatter={(datakey, name) => [
                            datakey,
                            t('Distance'),
                          ]}
                        />
                      </StyledBarChart>
                    ) : (
                      <Box
                        style={{
                          display: 'flex',
                          height: '65%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 16,
                          fontWeight: 'bold',
                          color: Colors.Black,
                        }}
                      >
                        {t('NoFilter')}
                      </Box>
                    )}
                  </Box>
                )}
                <Box
                  className={clsx([classes.graph])}
                  style={
                    isBelowXs
                      ? { padding: 2, marginTop: 24 }
                      : { marginTop: 24 }
                  }
                >
                  <FilterBar<UserFitnessDTO>
                    data={rawTime}
                    enables={{ group: true }}
                    onFilter={(data) => setFilterDataTime(data)}
                    dateKey="time"
                    dataKey="activityTime"
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                  />
                  {filterDataTime.length > 0 ? (
                    <StyledBarChart
                      width={filterDataTime.length < 3 ? 200 : undefined}
                      aspect={3}
                      data={filterDataTime
                        .map(({ time, activityTime, distance, ...rest }) => ({
                          ...rest,
                          time: moment(time).format(DateFormats.GR),
                          activityTime:
                            activityTime && Math.round(activityTime / 60),
                        }))
                        .slice(0, 100)
                        .reverse()}
                      dataKey="activityTime"
                    >
                      <XAxis
                        dataKey="time"
                        axisLine={false}
                        tickLine={false}
                        tick={{
                          fontSize: isBelowXs ? 12 : 16,
                        }}
                      />
                      <YAxis
                        dataKey="activityTime"
                        axisLine={false}
                        tickLine={false}
                        tick={{
                          fontSize: isBelowXs ? 12 : 16,
                        }}
                        label={{
                          value: 'Min',
                          angle: -90,
                          offset: 10,
                          position: 'insideLeft',
                          textAnchor: 'middle',
                          fontSize: isBelowXs ? 12 : 16,
                        }}
                      />
                      <TooltipChart
                        formatter={(datakey, name) => [
                          datakey,
                          t('ActivityTime'),
                        ]}
                      />
                    </StyledBarChart>
                  ) : (
                    <Box
                      style={{
                        display: 'flex',
                        height: '65%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: Colors.Black,
                      }}
                    >
                      {t('NoFilter')}
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Box style={{ width: '58%' }}>
                <EmptyPage image={Empty} text={t('Fit')} text1={t('Welfit')} />
              </Box>
            )}
          </Container>
          {userFitness.length > 0 && (
            <Box>
              <Paper style={{ marginTop: 30, overflow: 'auto' }}>
                <Table>
                  <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                    <TableRow>
                      {!isBelowXs && <TableCell padding="checkbox" />}
                      {activeFitness.hasDistance === true && (
                        <TableCell
                          size={isBelowSm ? 'small' : 'medium'}
                          style={{ color: '#FFFFFF' }}
                        >
                          {t('Distance')} ({t('Meters')})
                        </TableCell>
                      )}
                      <TableCell
                        size={isBelowSm ? 'small' : 'medium'}
                        style={{ color: '#FFFFFF' }}
                      >
                        {t('ActivityTime')} ({t('Minutes')})
                      </TableCell>
                      {activeFitness.hasDistance === false && (
                        <Fragment>
                          <TableCell
                            size={isBelowSm ? 'small' : 'medium'}
                            style={{ color: '#FFFFFF' }}
                          >
                            {t('FitnessInten')}
                          </TableCell>
                          <TableCell
                            size={isBelowSm ? 'small' : 'medium'}
                            style={{ color: '#FFFFFF' }}
                          >
                            {t('Comments')}
                          </TableCell>
                        </Fragment>
                      )}
                      <TableCell
                        size={isBelowSm ? 'small' : 'medium'}
                        style={{ color: '#FFFFFF' }}
                      >
                        {t('Date')}
                      </TableCell>
                      <TableCell
                        size={isBelowSm ? 'small' : 'medium'}
                        style={{ color: '#FFFFFF' }}
                      >
                        {t('Time')}
                      </TableCell>
                      <TableCell
                        size={isBelowSm ? 'small' : 'medium'}
                        padding="none"
                      />
                      <TableCell
                        size={isBelowSm ? 'small' : 'medium'}
                        padding="none"
                      />
                      <TableCell
                        size={isBelowSm ? 'small' : 'medium'}
                        padding="none"
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? filterData.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : filterData
                    ).map((row, i) => (
                      <TableRow key={i}>
                        {!isBelowXs && <TableCell padding="checkbox" />}
                        {activeFitness.hasDistance === true && (
                          <TableCell>
                            {row.distance !== null && row.distance
                              ? row.distance
                              : ' - '}
                          </TableCell>
                        )}
                        <TableCell>
                          {row.activityTime !== null && row.activityTime
                            ? Math.round(row.activityTime / 60)
                            : ' - '}
                        </TableCell>
                        {activeFitness.hasDistance === false && (
                          <Fragment>
                            <TableCell padding="none">
                              {row.activityScore}
                            </TableCell>
                            <TableCell padding="none">
                              {row.comments.length < 15 ? (
                                row.comments
                              ) : (
                                <Tooltip
                                  title={row.comments}
                                  classes={{ tooltip: classes.customWidth }}
                                >
                                  <Box
                                    style={{
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      maxWidth: 190,
                                    }}
                                  >
                                    {row.comments}
                                  </Box>
                                </Tooltip>
                              )}
                            </TableCell>
                          </Fragment>
                        )}
                        <TableCell>
                          {moment(row.time).format(DateFormats.GrNoTime)}
                        </TableCell>
                        <TableCell>
                          {moment(row.time).format(DateFormats.TIME)}
                        </TableCell>
                        <TableCell
                          align="center"
                          padding="none"
                          style={isBelowXs ? { padding: '0px 8px' } : {}}
                        >
                          <EditIcon
                            style={{
                              color: 'rgba(0, 0, 0, 0.58)',
                              fontSize: 18,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setFitnessToEdit(row);
                              setOpen(true);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center" padding="none">
                          <Box
                            style={{
                              backgroundColor: 'rgba(0, 0, 0, 0.34)',
                              width: 2,
                              height: 40,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          padding="none"
                          style={isBelowXs ? { padding: '0px 8px' } : {}}
                        >
                          <DeleteIcon
                            style={{
                              color: 'rgba(0, 0, 0, 0.58)',
                              fontSize: 18,
                              cursor: 'pointer',
                            }}
                            onClick={async () => {
                              await deleteFitness(row.id);
                              setFitnessToEdit(undefined);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          10,
                          20,
                          100,
                          { label: 'All', value: -1 },
                        ]}
                        labelRowsPerPage={t('RowsText')}
                        count={filterData.length}
                        onChangePage={handleChangePage}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Paper>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    );
  } else {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    fontSize: 14,
    fontWeight: 'bold',
    background: 'rgba(196, 196, 196, 0.27)',
    color: Colors.Black,
  },
  container1: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '3%',
  },
  padd: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    width: '100%',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
  graph: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
  },
  customWidth: {
    maxWidth: 500,
  },
}));

export default Fitness;
