import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { CSSProperties } from '@material-ui/styles';
import clsx from 'clsx';
import { StyledLineChart } from 'components/LineChart';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { Tooltip, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';
import { useTutorialContext } from 'context/TutorialContext';
import { tutorialData } from 'Pages/MeasurementsID';
import {
  measurementsControllerGetMyMeasurementByType,
  useMeasurementsControllerGetMyMeasurementByType,
  UserMeasurementDTO,
} from 'gen/client';

interface LatestMeasurementProps {
  latest: UserMeasurementDTO;
  style?: CSSProperties;
}
const _LatestMeasurement = ({
  latest,
  history,
  style,
}: LatestMeasurementProps & RouteComponentProps) => {
  const classes = useStyles();
  const [measurements, setMeasurements] = useState<UserMeasurementDTO[]>([]);
  const { t } = useTranslation();

  const theme = useTheme();
  const isBelow = useMediaQuery(theme.breakpoints.down(1620));
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  useEffect(() => {
    const resp = measurementsControllerGetMyMeasurementByType(
      latest.measurement.id.toString()
    );
    resp.then((data) => setMeasurements(data.data));
  }, []);
  return (
    <Paper style={{ ...style }} className={classes.container}>
      <Box>
        <Box display="flex" flexGrow={1} className={clsx([classes.padd])}>
          <Box
            display="flex"
            flexGrow={3}
            style={
              !isBelowXs
                ? {
                    width: 500,
                  }
                : {}
            }
            onClick={() => {
              if (isBelowXs)
                return history.push(`/measurements/${latest.measurement.id}`);
            }}
          >
            <ShortInfo latest={latest} />
          </Box>
          {!isBelowXs && (
            <Box flexGrow={2}>
              {isBelow ? (
                <IconButton
                  className={classes.addButton}
                  onClick={() =>
                    history.push(`/measurements/${latest.measurement.id}`)
                  }
                >
                  <Add />
                </IconButton>
              ) : (
                <Button
                  startIcon={<Add />}
                  variant="contained"
                  size="small"
                  className={classes.addButton}
                  style={{ lineHeight: 1.4 }}
                  onClick={() =>
                    history.push(`/measurements/${latest.measurement.id}`)
                  }
                >
                  {t('AddMeasurement')}
                </Button>
              )}
            </Box>
          )}
        </Box>
        <Box className={clsx([classes.padd])}>
          <Box>
            <StyledLineChart
              data={measurements
                .map(({ time, ...rest }) => ({
                  ...rest,
                  time: moment(time).format(DateFormats.GR),
                }))
                .slice(0, 100)
                .reverse()}
              dataKey={latest.measurement.id === 397 ? 'high' : 'value'}
              dataKey2={latest.measurement.id === 397 ? 'low' : undefined}
            >
              <XAxis
                dataKey="time"
                axisLine={false}
                tickLine={false}
                tick={{
                  fontSize: isBelowXs ? 12 : 16,
                }}
              />
              <YAxis
                dataKey={latest.measurement.id === 397 ? 'high' : 'value'}
                axisLine={false}
                tickLine={false}
                tick={{
                  fontSize: isBelowXs ? 12 : 16,
                }}
                label={{
                  value:
                    latest.measurement.limits.length > 0
                      ? latest.measurement.limits[0].unit
                      : undefined,
                  angle: -90,
                  offset: 10,
                  position: 'insideLeft',
                  textAnchor: 'middle',
                  fontSize: isBelowXs ? 12 : 16,
                }}
              />
              {latest.measurement.id === 397 && (
                <YAxis
                  dataKey="low"
                  axisLine={false}
                  tickLine={false}
                  label={{
                    value:
                      latest.measurement.limits.length > 0
                        ? latest.measurement.limits[0].unit
                        : undefined,
                    angle: -90,
                    offset: 10,
                    position: 'insideLeft',
                    textAnchor: 'middle',
                  }}
                />
              )}
              <Tooltip
                formatter={(datakey, name) => [
                  datakey,
                  name === 'low'
                    ? t('Low')
                    : name === 'high'
                    ? t('High')
                    : t('Value'),
                ]}
              />
            </StyledLineChart>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export const ShortInfo = ({ latest }: LatestMeasurementProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { me } = useTutorialContext();

  return latest || me?.tutorial === false ? (
    <Box className={classes.latestInfo}>
      <Box
        className={clsx([classes.column, classes.valueBox])}
        style={
          (latest && latest.measurement.id === 397) || me?.tutorial === false
            ? isBelowXs
              ? { padding: 4 }
              : { padding: 8 }
            : isBelowXs
            ? { padding: 0 }
            : {}
        }
      >
        {latest && latest.measurement.id === 397 ? (
          <Box display="flex">
            <Box
              className={classes.pressBox}
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: isBelowXs ? 4 : 8,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '100%',
                  height: isBelowXs ? 35 : 45,
                  width: isBelowXs ? 35 : 45,
                  backgroundColor: '#5CA595',
                  fontSize: 18,
                  fontWeight: 'bold',
                  fontFamily: 'Rubik',
                }}
              >
                {latest.high}
              </Box>
              {!isBelowXs && (
                <Typography
                  style={{ marginTop: 5, fontSize: 14, fontWeight: 'bold' }}
                >
                  {t('High')}
                </Typography>
              )}
            </Box>
            <Box
              className={classes.pressBox}
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: isBelowXs ? 4 : 8,
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '100%',
                  height: isBelowXs ? 35 : 45,
                  width: isBelowXs ? 35 : 45,
                  backgroundColor: '#9FC131',
                  fontSize: 18,
                  fontWeight: 'bold',
                  fontFamily: 'Rubik',
                }}
              >
                {latest.low}
              </Box>
              {!isBelowXs && (
                <Typography
                  style={{ marginTop: 5, fontSize: 14, fontWeight: 'bold' }}
                >
                  {t('Low')}
                </Typography>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              fontSize: isBelowXs ? 26 : 34,
              fontWeight: 'bold',
              minWidth: isBelowXs ? 60 : 80,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {me?.tutorial === false
              ? tutorialData[tutorialData.length - 1].value
              : latest.value}
          </Box>
        )}
        <Box style={{ fontSize: 16 }}>
          {latest && latest.measurement.limits.length > 0
            ? latest.measurement.limits[0].unit
            : 'bpm'}
        </Box>
      </Box>
      <Box
        className={classes.column}
        justifyContent="space-evenly"
        paddingLeft={2}
        style={isBelowXs ? { paddingLeft: 6 } : {}}
      >
        <Box style={{ fontSize: isBelowXs ? 16 : 17 }}>
          {me?.tutorial === false ? t('bpm') : latest.measurement.name}
        </Box>
        <Box style={{ fontSize: isBelowXs ? 14 : 16, color: 'gray' }}>
          {t('MeasurementAt')}
        </Box>
        <Box style={{ fontSize: isBelowXs ? 16 : 18, color: Colors.FadeBlack }}>
          {me?.tutorial === false
            ? moment(tutorialData[tutorialData.length - 1].time).format(
                DateFormats.GR
              )
            : moment(latest.time).format(DateFormats.GR)}
        </Box>
      </Box>
    </Box>
  ) : (
    <Fragment />
  );
};
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    padd: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
      border: '1px solid #E3DFFA',
    },
    valueBox: {
      alignItems: 'center',
      padding: theme.spacing(3),
      borderRight: '2px solid #F5F6F7',
      color: Colors.FadeBlack,
      justifyContent: 'center',
    },
    latestInfo: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      display: 'flex',
      flexGrow: 1,
    },
    addButton: {
      backgroundColor: '#F5F6F7',
      border: ' 1px solid rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      borderRadius: 90,
      margin: '0px 10px',
      float: 'right',
      [theme.breakpoints.down('lg')]: {
        fontSize: 13,
      },
    },
    pressBox: {
      alignItems: 'center',
      color: Colors.FadeBlack,
      fontSize: 24,
      fontWeight: 'bold',
      margin: theme.spacing(1),
      textAlign: 'center',
    },
  })
);

export const LatestMeasurement = hot(withRouter(_LatestMeasurement));
