import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Colors } from 'res/Enums';
interface CarouselProps {
  children: React.ReactNode[];
  delay?: number;
}
export const Carousel = ({ children, delay = 15000 }: CarouselProps) => {
  const [childIndex, setChildIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (children.length - 1 > childIndex) {
        setChildIndex(childIndex + 1);
      } else {
        setChildIndex(0);
      }
    }, delay);
    return () => clearInterval(interval);
  }, [childIndex]);
  return (
    <Box width="100%" position="relative" height="100%">
      <Box
        style={{
          zIndex: 10,
          position: 'absolute',
          top: 52,
          left: 75,
        }}
      >
        {children.map((_, i) => (
          <Circle
            key={i}
            color={Colors.White}
            value={i}
            selected={i === childIndex}
            onClick={(value) => setChildIndex(value)}
          />
        ))}
      </Box>

      {children[childIndex]}
    </Box>
  );
};

interface CircleProps {
  color: string;
  value: number;
  selected: boolean;
  onClick: (value: number) => void;
}
const Circle = ({ color, value, selected, onClick }: CircleProps) => {
  return (
    <div
      onClick={() => onClick(value)}
      style={{
        height: 10,
        width: 10,
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: selected ? color : Colors.Gray,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        margin: 3,
        cursor: 'pointer',
      }}
    />
  );
};
