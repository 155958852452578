import React, { useState, useEffect } from 'react';
import { Select, MenuItem, makeStyles } from '@material-ui/core';
import {
  MedicineShapeDto,
  useMedicationControllerGetMedicineShapes,
} from 'gen/client';

interface MedicationShapePickerProps {
  selected: MedicineShapeDto;
  onChange: (shape: MedicineShapeDto) => void;
}

export const MedicineShapePicker = ({
  selected,
  onChange,
}: MedicationShapePickerProps) => {
  const [shapes, setShapes] = useState<MedicineShapeDto[]>([]);
  const [selectedShape, setSelectedShape] = useState(selected);
  const classes = useStyles();
  const { data: shapesData } = useMedicationControllerGetMedicineShapes();
  useEffect(() => {
    if (shapesData) setShapes(shapesData.data);
  }, [shapesData]);
  useEffect(() => setSelectedShape(selected), [selected]);

  return (
    <Select
      fullWidth
      value={selectedShape.id}
      classes={{ selectMenu: classes.menu }}
      onChange={(e) =>
        onChange(
          shapes.find(
            (item) => item.id === parseInt(e.target.value as string)
          ) as MedicineShapeDto
        )
      }
    >
      {shapes.map((shape, i) => (
        <MenuItem key={i} value={shape.id}>
          {shape.type}
        </MenuItem>
      ))}
    </Select>
  );
};

const useStyles = makeStyles(() => ({
  menu: {
    display: 'flex',
    alignContent: 'center',
  },
}));
