import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import moment, { Moment } from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Colors, DateFormats } from 'res/Enums';
import CloseIcon from '@material-ui/icons/Close';
import { StyledDateTimePicker } from 'components/DateTimePicker';
import { useTutorialContext } from 'context/TutorialContext';
import { StyledTutorialDateTimePicker } from './../TutorialDateTimePicker';
import {
  MeasurementDTO,
  UserMeasurementDTO,
  CreateMeasurementSpecial as Special,
  useMeasurementsControllerUpdateMyMeasurement,
  UpdateMeasurementSpecial,
  CreateMeasurementSpecial,
} from 'gen/client';
import {
  useMeasurementsControllerCreateMyMeasurement,
  PregnancyDTO,
} from './../../gen/client';

interface MeasurementsBoxProps extends RouteComponentProps<{ id: string }> {
  onAdd: () => void;
  onEdit: () => void;
  onCancel: () => void;
  measurement?: UserMeasurementDTO;
  active: MeasurementDTO;
  lastMeasurement?: UserMeasurementDTO;
  tutorial?: boolean;
  drawer?: boolean;
  pregnancy?: PregnancyDTO;
}

const _MeasurementsBox = ({
  measurement,
  onAdd,
  onEdit,
  onCancel,
  active,
  tutorial,
  drawer,
  pregnancy,
}: MeasurementsBoxProps) => {
  const activeMeasurement = active;
  const [time, setTime] = useState(moment());
  const [value, setValue] = useState<string>('');
  const [high, setHigh] = useState<string>('');
  const [low, setLow] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState(Special.INDIFFERENT);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('sm'));
  const { openT, setOpenT } = useTutorialContext();
  const [temp, setTemp] = useState('');
  const createMutation = useMeasurementsControllerCreateMyMeasurement();
  const updateMutation = useMeasurementsControllerUpdateMyMeasurement();

  const newMeasurement = async () => {
    try {
      const response = await createMutation.mutateAsync({
        data: {
          high: parseFloat(high),
          low: parseFloat(low),
          value: parseFloat(value),
          time: time.format(DateFormats.ISO),
          special: selectedValue,
          measurement: activeMeasurement,
        },
      });
      if (response) {
        toast(t('MeasurementAdded'));
        handleRefresh();
        onAdd();
      } else {
        toast(t('FailedAdding'), { containerId: 'deleted' });
      }
    } catch (error) {
      toast(t('Problem'), { containerId: 'deleted' });
    }
  };
  const exp = new RegExp(/^[+-]?([0-9]+([.][0-9]{0,2})?|[.][0-9]{1,2})$/);

  const updateMeasurement = async (measurement: UserMeasurementDTO) => {
    try {
      const response = await updateMutation.mutateAsync({
        id: measurement.id,
        data: {
          value: parseFloat(value),
          time: time.format(DateFormats.ISO),
          special: selectedValue as unknown as UpdateMeasurementSpecial,
          high: parseFloat(high),
          low: parseFloat(low),
          measurement: measurement.measurement,
        },
      });
      if (response) {
        toast(t('MeasurementEdited'));
        handleRefresh();
        onEdit();
      }
    } catch (error) {
      toast(t('Problem'), { containerId: 'deleted' });
    }
  };

  const handleRefresh = async () => {
    setValue('');
    setTime(moment());
    setSelectedValue(Special.INDIFFERENT);
    // setHigh('0');
    setHigh('');
    setLow('');
  };
  useEffect(() => {
    if (measurement) {
      if (activeMeasurement.id === 397) {
        if (measurement.high && measurement.low) {
          setHigh(measurement.high.toString());
          setLow(measurement.low.toString());
        }
      }
      setValue(measurement.value.toString());
      setSelectedValue(
        measurement.special as unknown as CreateMeasurementSpecial
      );
      if (pregnancy !== undefined && pregnancy.UserPregnancy.length > 0) {
        setTime(moment(pregnancy.UserPregnancy[0].birthDate));
      } else setTime(moment(measurement.time));
      setEditMode(true);
    } else {
      if (activeMeasurement.id === 397) {
        // setHigh('0');
        setHigh('');
        setLow('');
      }
      setValue('');
      setSelectedValue(Special.INDIFFERENT);
      if (pregnancy !== undefined && pregnancy.UserPregnancy.length > 0) {
        setTime(moment(pregnancy.UserPregnancy[0].birthDate));
      } else setTime(moment());
      setEditMode(false);
    }
  }, [measurement]);

  useEffect(() => {
    // if (high === '-1') setHigh('0');
    // if (low === '-1') setLow('0');
    if (parseInt(high) > 30) setHigh((parseInt(high) / 10).toString());
    if (parseInt(low) > 30) setLow((parseInt(low) / 10).toString());
    if (parseInt(low) >= parseInt(high)) setError(true);
    else setError(false);
    if (parseInt(high) < 0) setHigh(''); //prosoxi sto -1 ean xanabi
    if (parseInt(low) < 0) setLow(''); //prosoxi sto -1 ean xanabi
  }, [high, low]);

  useEffect(() => {
    if (
      (activeMeasurement.id === 4 ||
        activeMeasurement.id === 191 ||
        activeMeasurement.id === 198 ||
        activeMeasurement.id === 197 ||
        activeMeasurement.id === 7 ||
        activeMeasurement.id === 8 ||
        activeMeasurement.id === 9) &&
      parseFloat(value) <= 0
    )
      setValue('');
  }, [value]);

  useEffect(() => {
    if (tutorial === true) setValue('70');
  }, [tutorial]);

  useEffect(() => {
    if (parseInt(temp) <= parseInt(openT)) {
      setTemp(openT);
      if (openT === '11') setOpenT('12');
      if (openT === '13') setOpenT('14');
    } else {
      if (openT === '11') setOpenT('10');
      if (openT === '13') setOpenT('12');
      setTemp(openT);
    }
  }, [openT]);

  const classes = useStyles();
  return (
    <Fragment>
      <Box display="flex" className={classes.addBox}>
        <Box
          style={{
            display: 'flex',
            paddingRight: isBelowXs ? 16 : 30,
            alignItems: 'center',
          }}
        >
          {editMode === true ? (
            <Box style={{ flexGrow: 1 }}>
              <IconButton
                onClick={() => {
                  handleRefresh();
                  setEditMode(false);
                  onCancel();
                }}
                style={{ marginLeft: 16 }}
              >
                <CloseIcon style={{ fontSize: 30 }} />
              </IconButton>
            </Box>
          ) : (
            <Box style={{ flexGrow: 1 }} />
          )}
          <IconButton style={{ fontSize: 20 }} onClick={handleRefresh}>
            <RefreshIcon
              fontSize="inherit"
              style={{
                fontWeight: 'bold',
                color: Colors.PRIMARY,
                transform: 'rotateY(180deg)',
              }}
            />
          </IconButton>
          <Typography
            style={{
              fontSize: isBelowXs ? 12 : 14,
              fontWeight: 'bold',
              color: '#263238',
            }}
          >
            {t('CleanData')}
          </Typography>
        </Box>
        {activeMeasurement.id === 397 ? (
          <Fragment>
            <Typography
              style={{
                marginTop: isBelowXs ? 8 : 30,
                color: Colors.PRIMARY,
                fontWeight: 600,
                fontSize: 18,
                paddingLeft: 40,
              }}
            >
              {t('High')}
            </Typography>
            <TextField
              className={classes.text}
              value={high}
              type="number"
              error={error}
              helperText={
                error &&
                t('High')
                  .concat(' ')
                  .concat(t('BloodPressure'))
                  .concat(t('MustHigher'))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      color: 'rgba(0, 0, 0, 0.34)',
                      fontSize: 16,
                    }}
                  >
                    {activeMeasurement !== null && activeMeasurement.limits[0]
                      ? activeMeasurement.limits[0].unit
                      : undefined}
                  </InputAdornment>
                ),
              }}
              placeholder={t('Placeholder')}
              onChange={(e) => setHigh(e.target.value)}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Typography
              style={{
                marginTop: 30,
                color: '#263238',
                fontWeight: 600,
                fontSize: 18,
                paddingLeft: 40,
              }}
            >
              {activeMeasurement.name}
            </Typography>
            <TextField
              className={classes.text}
              value={value}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      color: 'rgba(0, 0, 0, 0.34)',
                      fontSize: 16,
                    }}
                  >
                    {activeMeasurement !== null && activeMeasurement.limits[0]
                      ? activeMeasurement.limits[0].unit
                      : undefined}
                  </InputAdornment>
                ),
              }}
              placeholder={t('Placeholder')}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </Fragment>
        )}
        {activeMeasurement.id === 191 && (
          <Box
            style={{
              margin: '20px 40px 0px 40px',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: 2,
            }}
          >
            <Typography
              style={{
                paddingTop: isBelowXs ? 4 : 10,
                fontSize: isBelowXs ? 13 : 16,
                fontWeight: 'bold',
                paddingLeft: isBelowXs ? 4 : 10,
              }}
            >
              {t('Food')}
            </Typography>
            <FormControl
              component="fieldset"
              style={{ paddingLeft: isBelowXs ? 8 : 16 }}
            >
              <RadioGroup
                value={selectedValue}
                onChange={(e) =>
                  setSelectedValue(
                    ((e) => {
                      switch (e.target.value as string) {
                        case Special.INDIFFERENT.toString():
                          return Special.INDIFFERENT;
                        case Special.AFTER.toString():
                          return Special.AFTER;
                        case Special.BEFORE.toString():
                          return Special.BEFORE;
                        default:
                          return Special.INDIFFERENT;
                      }
                    })(e)
                  )
                }
                row
              >
                <FormControlLabel
                  value={Special.INDIFFERENT}
                  control={
                    <Radio
                      size={isBelowXs ? 'small' : 'medium'}
                      style={
                        isBelowXs
                          ? {
                              color: Colors.PrimaryLight,
                              padding: '9px 0px 9px 9px',
                            }
                          : { color: Colors.PrimaryLight }
                      }
                    />
                  }
                  label={t('Other')}
                  classes={{ label: classes.radio }}
                  style={{ marginRight: isBelowXs ? 4 : 20 }}
                />
                <FormControlLabel
                  value={Special.BEFORE}
                  control={
                    <Radio
                      size={isBelowXs ? 'small' : 'medium'}
                      style={
                        isBelowXs
                          ? {
                              color: Colors.PrimaryLight,
                              padding: '9px 0px 9px 9px',
                            }
                          : { color: Colors.PrimaryLight }
                      }
                    />
                  }
                  label={t('Before')}
                  classes={{ label: classes.radio }}
                  style={{ marginRight: isBelowXs ? 4 : 20 }}
                />
                <FormControlLabel
                  value={Special.AFTER}
                  control={
                    <Radio
                      size={isBelowXs ? 'small' : 'medium'}
                      style={
                        isBelowXs
                          ? {
                              color: Colors.PrimaryLight,
                              padding: '9px 0px 9px 9px',
                            }
                          : { color: Colors.PrimaryLight }
                      }
                    />
                  }
                  label={t('After')}
                  classes={{ label: classes.radio }}
                  style={{ marginRight: isBelowXs ? 4 : 20 }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}
        {activeMeasurement.id === 397 && (
          <Fragment>
            <Typography
              style={{
                marginTop: 30,
                color: '#9FC131',
                fontWeight: 600,
                fontSize: 18,
                paddingLeft: 40,
              }}
            >
              {t('Low')}
            </Typography>
            <TextField
              className={classes.text}
              value={low}
              type="number"
              error={error}
              helperText={
                error &&
                t('High')
                  .concat(' ')
                  .concat(t('BloodPressure'))
                  .concat(t('MustHigher'))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      color: 'rgba(0, 0, 0, 0.34)',
                      fontSize: 16,
                    }}
                  >
                    {activeMeasurement !== null && activeMeasurement.limits[0]
                      ? activeMeasurement.limits[0].unit
                      : undefined}
                  </InputAdornment>
                ),
              }}
              placeholder={t('Placeholder')}
              onChange={(e) => setLow(e.target.value)}
            />
          </Fragment>
        )}
        <Typography
          style={{
            margin: '20px 40px 0px 40px',
            color: '#263238',
            fontSize: 18,
            fontWeight: 600,
          }}
        >
          {t('DateTime')}
        </Typography>
        {tutorial === true && (openT === '10' || openT === '9') ? (
          <StyledTutorialDateTimePicker
            autoOk
            autoFocus
            style={{
              margin: isBelowXs ? '0px 40px 20px 40px' : '0px 40px 100px 40px',
            }}
            open={openT === '10'}
            openTo={'date'}
            value={time}
            margin="normal"
            format={DateFormats.GR}
            onChange={(date) => setTime(date as Moment)}
            animateYearScrolling
            disableFuture
          />
        ) : tutorial === true && openT === '12' ? (
          <StyledTutorialDateTimePicker
            autoOk
            autoFocus
            style={{
              margin: isBelowXs ? '0px 40px 20px 40px' : '0px 40px 100px 40px',
            }}
            open={openT === '12'}
            openTo={'year'}
            value={time}
            margin="normal"
            format={DateFormats.GR}
            onChange={(date) => setTime(date as Moment)}
            animateYearScrolling
            disableFuture
          />
        ) : tutorial === true && openT === '14' ? (
          <StyledTutorialDateTimePicker
            autoOk
            autoFocus
            style={{
              margin: isBelowXs ? '0px 40px 20px 40px' : '0px 40px 100px 40px',
            }}
            open={openT === '14'}
            openTo={'hours'}
            value={time}
            margin="normal"
            format={DateFormats.GR}
            onChange={(date) => setTime(date as Moment)}
            animateYearScrolling
            disableFuture
          />
        ) : (
          <StyledDateTimePicker
            autoOk
            autoFocus
            style={{
              margin: isBelowXs ? '0px 40px 20px 40px' : '0px 40px 100px 40px',
            }}
            value={time}
            margin="normal"
            format={DateFormats.GR}
            onChange={(date) => setTime(date as Moment)}
            animateYearScrolling
            minDate={
              pregnancy !== undefined
                ? moment(pregnancy.startDate)
                : moment('1900-01-01')
            }
            maxDate={
              pregnancy !== undefined && pregnancy.UserPregnancy.length > 0
                ? moment(pregnancy.UserPregnancy[0].birthDate)
                : moment()
            }
            disableFuture
          />
        )}
        {editMode === true && measurement ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateMeasurement(measurement);
              setEditMode(false);
              onCancel();
            }}
            classes={{ label: classes.label }}
            style={drawer ? { margin: '0px 40px' } : {}}
            disabled={
              // (activeMeasurement.id === 397 && (high === '0' || low === '0')) ||
              (activeMeasurement.id === 397 && (high === '' || low === '')) ||
              (activeMeasurement.id !== 397 && value === '') ||
              error
            }
          >
            {t('EditMeasurement')}
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            endIcon={<AddIcon style={{ marginLeft: 20, fontSize: 40 }} />}
            onClick={() => {
              newMeasurement();
              onCancel();
            }}
            disabled={
              // (activeMeasurement.id === 397 && (high === '0' || low === '0')) ||
              (activeMeasurement.id === 397 && (high === '' || low === '')) ||
              (activeMeasurement.id !== 397 &&
                (value === '' || !exp.test(value))) ||
              error
            }
          >
            {t('RecordMeasurement')}
          </Button>
        )}
      </Box>
    </Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  addBox: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
    flexDirection: 'column',
    height: 'fit-content',
  },
  text: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  label: {
    padding: '2% 0% 2% 0%',
  },
  radio: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
}));
export const MeasurementsBox = withRouter(_MeasurementsBox);
