import {
  Box,
  Button,
  Dialog,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Timetable } from 'gen/client';
import { uuid } from 'uuidv4';
import { groupBy, Dictionary } from 'underscore';
import moment from 'moment';
import theme from '../../../assets/theme';
interface MedicationTimetableViewProps {
  timetableRows: Timetable[];
}
const _MedicationTimetableView = ({
  timetableRows,
}: MedicationTimetableViewProps) => {
  const [dialog, setDialog] = useState(false);
  const [data, setData] = useState<Dictionary<Timetable[]>>();
  const { t } = useTranslation();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    const temp = groupBy(
      timetableRows.sort((a, b) => moment(a.time).day() - moment(b.time).day()),
      (row) => moment(row.time).format('dddd')
    );
    setData(temp);
  }, [timetableRows]);
  return (
    <Box>
      <Button
        variant="outlined"
        size={isBelowSm ? 'small' : 'medium'}
        style={{
          fontSize: isBelowSm ? 12 : 'inherit',
        }}
        color="primary"
        onClick={() => setDialog(true)}
      >
        {t('TimetableLabel')}
      </Button>
      <Dialog open={dialog} onClose={() => setDialog(false)} fullWidth>
        <DialogTitle>{t('DetailedDosages')}</DialogTitle>
        <DialogContentText style={{ margin: 20 }}>
          {data &&
            Object.keys(data).map((day) => (
              <Box key={uuid()} marginBottom={5}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{day}</TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('Time')}</TableCell>
                      <TableCell>{t('Dosage')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data[day].map((row: Timetable) => (
                      <TableRow key={uuid()}>
                        <TableCell>
                          {moment(row.time).format('HH:mm')}
                        </TableCell>
                        <TableCell>{row.dosage}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ))}
        </DialogContentText>
      </Dialog>
    </Box>
  );
};
export const MedicationTimetableView = hot(_MedicationTimetableView);
