import { createContext, PropsWithChildren, useState, useContext } from 'react';
import React from 'react';
import { TimetableItem } from 'components/Medication/Timetable/MedicationTimetable';

interface TimetableContext {
  timetableRows: TimetableItem[];
  setRows: (rows: TimetableItem[]) => void;
}

const initContext: TimetableContext = {
  timetableRows: [],
  setRows: () => {},
};
const TimetableContext = createContext(initContext);

export const TimetableProvider = ({ children }: PropsWithChildren<{}>) => {
  const [rows, _setRows] = useState<TimetableItem[]>([]);
  const setRows = (rows: TimetableItem[]) => _setRows(rows);
  return (
    <TimetableContext.Provider value={{ timetableRows: rows, setRows }}>
      {children}
    </TimetableContext.Provider>
  );
};
export const useTimetableContext = () => useContext(TimetableContext);
