import { Grid, CircularProgress, Box } from '@material-ui/core';
import Nav from 'components/Nav';
import { Cross } from 'Pages/Cross';
import { Cycle } from 'Pages/Cycle';
import Diabetes from 'Pages/Diabetes';
import { Exams } from 'Pages/Exams';
import Fitness from 'Pages/Fitness';
import { RequestReset } from 'Pages/Forgot/RequestReset';
import { ResetPassword } from 'Pages/Forgot/ResetPassword';
import { HealthScore } from 'Pages/HealthScore';
import { Home } from 'Pages/Home';
import { Insulin } from 'Pages/Insulin';
import { IntroPage } from 'Pages/Intro/IntroPage';
import Login from 'Pages/Intro/Login';
import { Register } from 'Pages/Intro/Register';
import Measurements from 'Pages/Measurements';
import { MeasurementsID } from 'Pages/MeasurementsID';
import { Medication } from 'Pages/Medication';
import { Preventive } from 'Pages/Preventive';
import { Concise } from 'Pages/Profile/Consise';
import { EditConcise } from 'Pages/Profile/EditConcise';
import { Profile } from 'Pages/Profile/Profile';
import Symptom from 'Pages/Symptom';
import { SymptomID } from 'Pages/SymptomID';
import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthStatus } from 'res/Enums';
import './App.css';
import { RegisterOptional } from 'components/RegisterOptional';
import { EditProfile } from 'Pages/Profile/EditProfile';
import { Calendar } from 'Pages/Calendar';
import { RegisterNeeded } from 'components/RegisterNeeded';
import { useAuthContext } from './context/AuthContext';
import { Covid } from 'Pages/Covid';
import { AboutUs } from './Pages/Profile/AboutUs';
import { Terms } from 'res/Terms';
import LogoAnim from 'assets/icons/logoanim.gif';
import { Pregnancy } from 'Pages/Pregnancy';
import { Kid } from 'Pages/Kid';
import { Diagnosis } from 'Pages/Diagnosis';
const mainProps = {
  toastClassName: 'toast-main',
  progressClassName: 'toast-prog',
  autoClose: 3600,
};
const deletedProps = {
  containerId: 'deleted',
  toastClassName: 'toast-delete-main',
  progressClassName: 'toast-delete-prog',
  autoClose: 1500,
};
export const App = () => {
  const { authStatus } = useAuthContext();
  return (
    <Fragment>
      <ToastContainer enableMultiContainer {...mainProps} />
      <ToastContainer enableMultiContainer {...deletedProps} />
      {authStatus === AuthStatus.LOGGED_OUT && (
        <Switch>
          <Route path="/login" exact>
            <IntroPage>
              <Login />
            </IntroPage>
          </Route>
          <Route path="/register" exact>
            <IntroPage>
              <Register />
            </IntroPage>
          </Route>

          <Route path="/request" exact component={RequestReset} />
          <Route path="/reset" exact component={ResetPassword} />
          <Redirect to="/login" />
        </Switch>
      )}

      {authStatus === AuthStatus.REGISTERED && (
        <Switch>
          <Route path="/registeroptional" exact>
            <IntroPage>
              <RegisterOptional />
            </IntroPage>
          </Route>
          <Redirect to="/registeroptional" />
        </Switch>
      )}

      {authStatus === AuthStatus.GOOGLEREGISTERED && (
        <Switch>
          <Route path="/registerneeded" exact>
            <IntroPage>
              <RegisterNeeded />
            </IntroPage>
          </Route>
          <Redirect to="registerneeded" />
        </Switch>
      )}

      {authStatus === AuthStatus.LOGGED_IN ? (
        <Switch>
          {/* gia programming  sto registeroptional xoris na kanoume register*/}
          {/* <Route path="/registeroptional" exact>
            <IntroPage>
              <RegisterOptional />
            </IntroPage>
          </Route> */}
          <Nav tutorial={false}>
            <Grid container>
              <Grid item md={10} sm={11} xs={12} style={{ margin: 'auto' }}>
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/measurements" exact component={Measurements} />
                  <Route path="/medication" exact component={Medication} />
                  <Route path="/exams" exact component={Exams} />
                  <Route path="/symptoms" exact component={Symptom} />
                  <Route path="/fitness" exact component={Fitness} />
                  <Route
                    path="/measurements/:id"
                    exact
                    component={MeasurementsID}
                  />
                  <Route path="/symptoms/:id" exact component={SymptomID} />
                  {/* <Route path="/fitness/:id" exact component={FitnessID} /> */}
                  <Route path="/preventive" exact component={Preventive} />
                  <Route path="/health" exact component={HealthScore} />
                  <Route path="/cycle" exact component={Cycle} />
                  <Route path="/diabetes" exact component={Diabetes} />
                  <Route path="/insulin" exact component={Insulin} />
                  <Route path="/profile" exact component={Profile} />
                  <Route path="/editprofile" exact component={EditProfile} />
                  <Route path="/healthProfile" exact component={EditConcise} />
                  <Route path="/concise" exact component={Concise} />
                  <Route path="/calendar" exact component={Calendar} />
                  <Route path="/cross" exact component={Cross} />
                  <Route path="/covid-19" exact component={Covid} />
                  <Route path="/about" exact component={AboutUs} />
                  <Route path="/terms" exact component={Terms} />
                  <Route path="/pregnancy" exact component={Pregnancy} />
                  {/* <Route path="/kid" exact component={Kid} /> */}
                  <Route path="/diagnosis" exact component={Diagnosis} />
                  <Redirect to="/" />
                </Switch>
              </Grid>
            </Grid>
          </Nav>
        </Switch>
      ) : (
        authStatus === AuthStatus.PENDING && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={LogoAnim} alt="Healthier" />
          </Box>
        )
      )}
    </Fragment>
  );
};
