import {
  Box,
  makeStyles,
  TextFieldProps,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Alarm, Clear } from '@material-ui/icons';
import { Colors, dosage, DateFormats } from 'res/Enums';
import { TimePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import LabBottle from 'assets/icons/LabBottle.svg';
import { uuid } from 'uuidv4';
import { useTimetableContext } from 'context/TimetableContext';
import { TimetableItem } from './MedicationTimetable';
interface TimetableRowValuesProps {
  row: TimetableItem;
  first: boolean;
}
const _TimetableRowValues = ({
  row,
  first = false,
}: TimetableRowValuesProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { timetableRows, setRows } = useTimetableContext();
  const TimeInput = (props: TextFieldProps) => (
    <Box className={classes.input} onClick={props.onClick}>
      <Box paddingRight={1}>
        <Alarm
          style={{
            display: 'flex',
            alignSelf: 'center',
            color: Colors.FadeBlack,
          }}
        />
      </Box>
      <Box>{props.value as string}</Box>
    </Box>
  );

  const handleTimeChange = (time: Moment) => {
    setRows(
      timetableRows.map((item) => {
        if (item.uid === row.uid) {
          return { ...item, time: moment(time).format(DateFormats.ISO) };
        }
        return item;
      })
    );
  };
  const handleDosageChange = (dosage: string) => {
    setRows(
      timetableRows.map((item) => {
        if (item.uid === row.uid) {
          return { ...item, dosage };
        }
        return item;
      })
    );
  };
  const handleRemoveRow = () => {
    setRows(timetableRows.filter((item) => item.uid !== row.uid));
  };
  return (
    <Box className={classes.valuesCont}>
      <Box>
        <TimePicker
          label={t('Time')}
          value={row.time}
          onChange={(time) => handleTimeChange(time as Moment)}
          minutesStep={15}
          TextFieldComponent={TimeInput}
        />
      </Box>
      <Box className={classes.input}>
        <img
          src={LabBottle}
          style={{
            color: Colors.FadeBlack,
            marginRight: 6,
          }}
        />
        <Select
          value={row.dosage}
          onChange={(e) => handleDosageChange(e.target.value as string)}
          //   input={<InputBase />}
        >
          <MenuItem value={'dosage'}>
            <em>{t('Dosage')}</em>
          </MenuItem>
          {dosage.map((item) => (
            <MenuItem key={uuid()} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box style={{ width: 30, height: 30 }}>
        {first && row.dosage !== 'dosage' ? (
          <IconButton
            size="small"
            style={{ alignSelf: 'center' }}
            onClick={() => handleDosageChange('dosage')}
          >
            <Clear />
          </IconButton>
        ) : (
          !first && (
            <IconButton
              size="small"
              style={{ alignSelf: 'center' }}
              onClick={handleRemoveRow}
            >
              <Clear />
            </IconButton>
          )
        )}
      </Box>
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  valuesCont: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-evenly',
    paddingTop: 3,
  },
  input: {
    display: 'flex',
    padding: 10,
    backgroundColor: Colors.IceWhite,
    borderRadius: 4,
    alignItems: 'center',
    maxHeight: 40,
    color: Colors.FadeBlack,
    cursor: 'pointer',
  },
}));
export const TimetableRowValues = hot(_TimetableRowValues);
