import { createMuiTheme } from '@material-ui/core/styles';
import { Colors } from 'res/Enums';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: ['IBM Plex Sans', 'Roboto'].join(','),
    fontSize: 16,
  },
  overrides: {
    MuiTab: {
      root: { textTransform: 'none' },
    },
    MuiButton: {
      root: { textTransform: 'none' },
      containedPrimary: {
        color: Colors.IceWhite,
      },
    },
    MuiInputBase: {
      root: { color: Colors.FadeBlack, fontSize: 18 },
    },
    MuiTableCell: {
      body: { color: 'rgba(0, 0, 0, 0.87)' },
    },
    MuiTypography: {
      body1: { fontSize: 16 },
      h3: {
        fontSize: '2.5em',
      },
      h4: {
        fontSize: '2em',
      },
    },
    MuiFormHelperText: {
      root: { fontSize: 12 },
    },
  },
  palette: {
    primary: { main: Colors.PRIMARY },
    secondary: { main: Colors.SECONDARY },
    text: { primary: Colors.PRIMARY, secondary: Colors.FadeBlack },
  },
});

export default theme;
