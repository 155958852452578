import MomentUtils from '@date-io/moment';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { ConfirmDialogProvider } from 'components/ConfirmDialogProvider';
import { StateProvider, useAppContext } from 'context/AppContext';
import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import 'res/locales/locales';
import 'typeface-roboto';
import { App } from './App';
import theme from './assets/theme';
import * as serviceWorker from './serviceWorker';
import { LastLocationProvider } from 'react-router-last-location';
import 'moment/locale/el';
import 'moment/locale/en-gb';
import moment from 'moment';
import { MuiPickersUtilsProviderProps } from '@material-ui/pickers/MuiPickersUtilsProvider';
import { Locale } from 'res/Enums';
import { AuthContextProvider } from 'context/AuthContext';
import { TutorialProvider } from 'context/TutorialContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
moment.locale('en');
export const getApiAddress = () => {
  // eslint-disable-next-line no-undef
  return process.env['REACT_APP_STAGE'] === 'production'
    ? 'https://api-prod.healthier-app.com'
    : process.env['REACT_APP_STAGE'] === 'development'
    ? 'https://api.healthier-app.com'
    : 'http://localhost:5000';
};
axios.interceptors.request.use((config) => {
  return {
    ...config,
    baseURL: getApiAddress(),
    withCredentials: true,
  };
});
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});
export const MuiPickerProvider = ({
  children,
  ...rest
}: PropsWithChildren<MuiPickersUtilsProviderProps>) => {
  const { lang } = useAppContext();
  return (
    <MuiPickersUtilsProvider
      {...rest}
      locale={lang === Locale.GR ? 'el' : 'en-gb'}
    >
      {children}
    </MuiPickersUtilsProvider>
  );
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <StateProvider>
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
            <MuiPickerProvider utils={MomentUtils}>
              <ConfirmDialogProvider>
                <LastLocationProvider>
                  <TutorialProvider>
                    <App />
                  </TutorialProvider>
                </LastLocationProvider>
              </ConfirmDialogProvider>
            </MuiPickerProvider>
          </QueryClientProvider>
        </AuthContextProvider>
      </StateProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
