import {
  Box,
  Button,
  InputAdornment,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { CalendarTodaySharp } from '@material-ui/icons';
import { useConfirm } from 'components/ConfirmDialogProvider';
import moment, { Moment } from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DateFormats } from 'res/Enums';
import { StyledDatePicker } from 'components/DatePicker';
import {
  CycleDTO,
  useCycleControllerCreateMyCycle,
  useCycleControllerUpdateMyCycle,
} from 'gen/client';

interface CycleBoxProps {
  cycle?: CycleDTO;
  end?: boolean;
  add?: boolean;
  onCancel: () => void;
  onRefresh: () => void;
}
const _CycleBox = ({
  cycle,
  onCancel,
  onRefresh,
  end = false,
  add = false,
}: CycleBoxProps) => {
  const { t } = useTranslation();
  const [dates, setDates] = useState<{
    startDate: Moment;
    endDate: Moment | null;
  }>({
    startDate: moment(),
    endDate: null,
  });
  const [error, setError] = useState(false);
  const confirm = useConfirm();
  const addMutation = useCycleControllerCreateMyCycle();
  const updateMutation = useCycleControllerUpdateMyCycle();

  const addCycle = async () => {
    try {
      const resp = await addMutation.mutateAsync({
        data:
          dates.endDate === null
            ? {
                startDate: dates.startDate.format(DateFormats.ISO),
              }
            : {
                startDate: dates.startDate.format(DateFormats.ISO),
                endDate: dates.endDate.format(DateFormats.ISO),
              },
      });
      if (resp) {
        toast(t('CycleAdded'));
        onRefresh();
      } else {
        toast(t('FailedAdding'), { containerId: 'deleted' });
        onCancel();
      }
    } catch (e) {
      toast(t('FailedAdding'), { containerId: 'deleted' });
      onCancel();
    }
  };
  const editCycle = async () => {
    try {
      const resp =
        cycle &&
        dates.endDate &&
        (await updateMutation.mutateAsync({
          id: cycle.id,
          data: {
            startDate: dates.startDate.format(DateFormats.ISO),
            endDate: dates.endDate.format(DateFormats.ISO),
          },
        }));
      if (resp) {
        toast(t('CycleUpdated'));
        onRefresh();
      } else {
        toast(t('EditFailed'), { containerId: 'deleted' });
        onCancel();
      }
    } catch (e) {
      toast(t('EditFailed'), { containerId: 'deleted' });
      onCancel();
    }
  };
  const addEnd = async () => {
    try {
      const resp =
        cycle &&
        dates.endDate &&
        (await updateMutation.mutateAsync({
          id: cycle.id,
          data: {
            startDate: dates.startDate.format(DateFormats.ISO),
            endDate: dates.endDate.format(DateFormats.ISO),
          },
        }));
      if (resp) {
        toast(t('CycleAdded'));
        onRefresh();
      } else {
        toast(t('FailedAdding'), { containerId: 'deleted' });
        onCancel();
      }
    } catch (e) {
      toast(t('FailedAdding'), { containerId: 'deleted' });
      onCancel();
    }
  };

  useEffect(() => {
    if (end && cycle) {
      setDates({
        startDate: moment(cycle.startDate),
        endDate: moment(cycle.startDate).add(4, 'days'),
      });
    } else if (cycle) {
      setDates({
        startDate: moment(cycle.startDate),
        endDate: moment(cycle.endDate),
      });
    }
  }, [cycle]);

  useEffect(() => {
    if (dates.endDate) {
      if (dates.startDate.isSameOrAfter(dates.endDate, 'day')) setError(true);
      else setError(false);
    }
  }, [dates]);

  return (
    <Box>
      <List>
        {(!cycle || (cycle && (end === false || add === false))) && (
          <Fragment>
            <ListItem>
              <StyledDatePicker
                fullWidth
                error={error}
                disableFuture
                value={dates.startDate}
                onChange={(date) =>
                  setDates({ ...dates, startDate: date as Moment })
                }
                label={t('StartDate')}
                showTodayButton
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodaySharp />
                    </InputAdornment>
                  ),
                }}
              />
            </ListItem>
          </Fragment>
        )}
        {cycle && end === true && (
          <ListItem>
            <StyledDatePicker
              fullWidth
              error={error}
              value={dates.endDate}
              onChange={(date) => {
                if (cycle) {
                  if (
                    Math.abs(
                      moment(cycle.startDate).diff(date as Moment, 'days')
                    ) > 7
                  ) {
                    confirm({
                      title: t('CycleSure'),
                      description: t('CycleDescr'),
                    })
                      .then(() =>
                        setDates({ ...dates, endDate: date as Moment })
                      )
                      .catch();
                  } else {
                    setDates({ ...dates, endDate: date as Moment });
                  }
                } else setDates({ ...dates, endDate: date as Moment });
              }}
              label={t('EndDate')}
              disableFuture
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodaySharp />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
        )}
      </List>
      {error && (
        <Box>
          <Typography style={{ fontSize: 14, color: 'red' }}>
            {t('PeriodError')}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end">
        <Button onClick={onCancel}>{t('Cancel')}</Button>
        {cycle && ((end && !add) || (!end && !add)) ? (
          <Button onClick={editCycle} disabled={error}>
            {t('Edit')}
          </Button>
        ) : (
          <Button onClick={end ? addEnd : addCycle} disabled={error}>
            {t('Add')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
export const CycleBox = hot(_CycleBox);
