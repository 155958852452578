import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Dialog,
  Popover,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import { StyledBarChart } from 'components/BarChart';
import { FilterBar, useFilter } from 'components/Filter/FilterBar';
import { ShortInfo } from 'components/Home/LatestMeasurement';
import { StyledLineChart } from 'components/LineChart';
import { MeasurementsBox } from 'components/Measurements/MeasurementsBox';
import { TablePaginationActions } from 'components/TablePaginationActions';
import moment from 'moment';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Tooltip, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';
import { isUndefined, isNull } from 'util';
import { EmptyPage } from 'components/EmptyPage';
import Empty from 'assets/icons/measurementsEmpty.svg';
import { useTutorialContext } from 'context/TutorialContext';
import {
  MeasurementDTO,
  useMeasurementsControllerDeleteMyMeasurement,
  useMeasurementsControllerGetMyMeasurementByType,
  UserMeasurementDTO,
} from 'gen/client';
import { useMeasurementsControllerGetMeasurementByType } from './../gen/client';

interface TutorialMeasurement {
  id: number;
  time: string;
  value: number;
}

export const tutorialData: TutorialMeasurement[] = [
  {
    id: 1,
    time: '2019-01-09T16:29:40',
    value: 75,
  },
  {
    id: 2,
    time: '2019-01-12T16:29:40',
    value: 78,
  },
  {
    id: 3,
    time: '2019-01-13T16:29:40',
    value: 74,
  },
  {
    id: 4,
    time: '2019-01-16T16:29:40',
    value: 108,
  },
  {
    id: 5,
    time: '2019-01-28T16:29:40',
    value: 81,
  },
  {
    id: 6,
    time: '2019-02-01T16:29:40',
    value: 85,
  },
];

export const MeasurementsID = withRouter(({ match, history }) => {
  const [activeMeasurement, setActiveMeasurement] =
    useState<MeasurementDTO | null>(null);
  const [rawData, setRawData] = useState<UserMeasurementDTO[]>([]);
  const [userMeasurements, setUserMeasurements] = useState<
    UserMeasurementDTO[]
  >([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [measurementToEdit, setMeasurementToEdit] =
    useState<UserMeasurementDTO>();
  const [activeFilter, setActiveFilter] = useFilter();
  const { t } = useTranslation();
  const {
    data: userMeasurementsData,
    isLoading: userMeasurementsLoading,
    refetch: userMeasurementsRefetch,
  } = useMeasurementsControllerGetMyMeasurementByType(match.params.id);
  const { data: measurementsData, isLoading: measurementsLoading } =
    useMeasurementsControllerGetMeasurementByType(match.params.id);
  const deleteMutation = useMeasurementsControllerDeleteMyMeasurement();

  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const inputEl = useRef<any | null>(null);
  const { openT, me } = useTutorialContext();
  const [screen, setScreen] = useState(false);

  useEffect(() => {
    if (userMeasurementsData && measurementsData) {
      setActiveMeasurement(measurementsData.data);
      setUserMeasurements(userMeasurementsData.data);
      setRawData(userMeasurementsData.data);
    }
  }, [userMeasurementsData, measurementsData]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteMeasurement = async (id: number) => {
    try {
      const response = await deleteMutation.mutateAsync({ id });
      if (response) {
        toast(t('MeasurementDeleted'));
        userMeasurementsRefetch();
      } else {
        toast(t('FailedDeleting'), { containerId: 'deleted' });
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (!isNull(inputEl.current) && openT === '9' && isBelowSm) {
      window.scrollTo(0, inputEl.current.offsetTop - 100);
      setScreen(true);
    }
  }, [inputEl.current]);

  useEffect(() => {
    if (!isNull(inputEl.current) && parseInt(openT) > 9 && isBelowSm)
      window.scrollTo(0, 0);
  }, [openT]);

  const classes = useStyles();
  if (
    activeMeasurement !== null &&
    !userMeasurementsLoading &&
    !measurementsLoading
  ) {
    return (
      <Container className={classes.container}>
        <Box display="flex" flexDirection="row">
          <Button
            variant="text"
            endIcon={<ArrowBack style={{ fontSize: 30 }} />}
            style={{
              color: Colors.FadeBlack,
            }}
            onClick={() => history.push('/measurements')}
          />
          <Typography
            style={{
              paddingLeft: 30,
              color: '#000000',
              fontSize: isBelowXs ? 24 : 30,
              fontWeight: 'bold',
            }}
          >
            {activeMeasurement.name}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {rawData.length > 0 || me?.tutorial === false ? (
            <Grid item md={7} xs={12}>
              <Box style={{ margin: '8px 0px' }}>
                <Typography
                  style={{
                    padding: '16px 0px',
                    color: Colors.Black,
                    fontSize: isBelowXs ? 18 : 20,
                    fontWeight: 'bold',
                  }}
                >
                  {t('Diagram')}
                </Typography>
              </Box>
              <Box
                className={classes.padd}
                style={isBelowXs ? { padding: 0 } : {}}
              >
                <Box
                  display="flex"
                  style={{ width: isBelowXs ? '100%' : '60%' }}
                >
                  <ShortInfo latest={userMeasurements[0]} />
                </Box>
                <Divider
                  style={{
                    marginTop: '3%',
                    backgroundColor: '#E3DFFA',
                  }}
                />
                {activeMeasurement.id === 397 ? (
                  <Box
                    className={clsx([classes.graph])}
                    style={isBelowXs ? { padding: 6 } : {}}
                  >
                    <FilterBar<UserMeasurementDTO>
                      data={rawData}
                      enables={{ time: true, date: true }}
                      onFilter={(data) => setUserMeasurements(data)}
                      dateKey="time"
                      activeFilter={activeFilter}
                      setActiveFilter={setActiveFilter}
                    />
                    {userMeasurements.length > 0 ? (
                      <StyledLineChart
                        data={userMeasurements
                          .map(({ time, ...rest }) => ({
                            ...rest,
                            time: moment(time).format(DateFormats.GR),
                          }))
                          .slice(0, 100)
                          .reverse()}
                        dataKey="high"
                        dataKey2="low"
                      >
                        <XAxis
                          dataKey="time"
                          axisLine={false}
                          tickLine={false}
                        />
                        <YAxis
                          dataKey="high"
                          axisLine={false}
                          tickLine={false}
                          label={{
                            value:
                              activeMeasurement !== null &&
                              !isUndefined(activeMeasurement.limits[0])
                                ? activeMeasurement.limits[0].unit
                                : undefined,
                            angle: -90,
                            offset: 10,
                            position: 'insideLeft',
                            textAnchor: 'middle',
                          }}
                        />
                        <YAxis
                          dataKey="low"
                          axisLine={false}
                          tickLine={false}
                          label={{
                            value:
                              activeMeasurement !== null &&
                              !isUndefined(activeMeasurement.limits[0])
                                ? activeMeasurement.limits[0].unit
                                : undefined,
                            angle: -90,
                            offset: 10,
                            position: 'insideLeft',
                            textAnchor: 'middle',
                          }}
                        />
                        <Tooltip
                          formatter={(datakey, name) => [
                            datakey,
                            name === 'low' ? t('Low') : t('High'),
                          ]}
                        />
                      </StyledLineChart>
                    ) : (
                      <Box
                        style={{
                          display: 'flex',
                          height: '65%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 16,
                          fontWeight: 'bold',
                          color: Colors.Black,
                        }}
                      >
                        {t('NoFilter')}
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box
                    className={clsx([classes.graph])}
                    style={isBelowXs ? { padding: 6 } : {}}
                  >
                    <FilterBar<UserMeasurementDTO>
                      data={rawData}
                      enables={
                        activeMeasurement.id === 191
                          ? { date: true, special: true }
                          : { date: true }
                      }
                      onFilter={(data) => setUserMeasurements(data)}
                      dateKey="time"
                      activeFilter={activeFilter}
                      setActiveFilter={setActiveFilter}
                    />
                    {userMeasurements.length > 0 || me?.tutorial === false ? (
                      <StyledBarChart
                        width={userMeasurements.length < 3 ? 100 : undefined}
                        data={
                          me?.tutorial === false
                            ? tutorialData.map(({ time, ...rest }) => ({
                                ...rest,
                                time: moment(time).format(DateFormats.GR),
                              }))
                            : userMeasurements
                                .map(({ time, ...rest }) => ({
                                  ...rest,
                                  time: moment(time).format(DateFormats.GR),
                                }))
                                .slice(0, 100)
                                .reverse()
                        }
                        dataKey="value"
                      >
                        <XAxis
                          dataKey="time"
                          axisLine={false}
                          tickLine={false}
                        />
                        <YAxis
                          dataKey="value"
                          axisLine={false}
                          tickLine={false}
                          label={{
                            value:
                              activeMeasurement !== null &&
                              !isUndefined(activeMeasurement.limits[0])
                                ? activeMeasurement.limits[0].unit
                                : undefined,
                            angle: -90,
                            offset: 10,
                            position: 'insideLeft',
                            textAnchor: 'middle',
                          }}
                        />
                        <Tooltip
                          formatter={(datakey, name) => [datakey, t('Value')]}
                        />
                      </StyledBarChart>
                    ) : (
                      <Box
                        style={{
                          display: 'flex',
                          height: '65%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 16,
                          fontWeight: 'bold',
                          color: Colors.Black,
                        }}
                      >
                        {t('NoFilter')}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          ) : (
            <Grid item md={7} xs={12}>
              <EmptyPage
                image={Empty}
                text={t('Measurements')}
                text1={t('WelcomeMeasurements')}
              />
            </Grid>
          )}
          <Grid item md={5} xs={12} ref={inputEl}>
            <Box style={{ margin: '8px 0px' }}>
              <Typography
                style={{
                  padding: '16px 0px',
                  color: Colors.Black,
                  fontSize: !isBelowXs ? 20 : 18,
                  fontWeight: 'bold',
                }}
              >
                {t('RecordMeasurement')}
              </Typography>
            </Box>
            <MeasurementsBox
              onAdd={() => {
                userMeasurementsRefetch();
              }}
              onEdit={() => {
                userMeasurementsRefetch();
              }}
              onCancel={() => setMeasurementToEdit(undefined)}
              measurement={measurementToEdit}
              active={activeMeasurement}
              lastMeasurement={userMeasurements[0]}
            />
          </Grid>
        </Grid>
        {(rawData.length > 0 || me?.tutorial === false) && (
          <Box>
            <Paper style={{ marginTop: 30, overflow: 'auto' }}>
              <Box style={{ margin: '8px 0px' }}>
                <FilterBar<UserMeasurementDTO>
                  data={rawData}
                  enables={
                    activeMeasurement.id === 191
                      ? { date: true, special: true }
                      : activeMeasurement.id === 397
                      ? { date: true, time: true }
                      : { date: true }
                  }
                  onFilter={(data) => setUserMeasurements(data)}
                  dateKey="time"
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                />
              </Box>
              <Table>
                <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                  <TableRow>
                    {!isBelowXs && (
                      <TableCell padding="checkbox">
                        {/* <Checkbox /> */}
                      </TableCell>
                    )}
                    {activeMeasurement.id !== 397 ? (
                      <Fragment>
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('Value')} {activeMeasurement.limits[0]?.unit ?? ''}
                        </TableCell>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('High')}
                        </TableCell>
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('Low')}
                        </TableCell>
                      </Fragment>
                    )}
                    <TableCell style={{ color: '#FFFFFF' }}>
                      {t('Date')}
                    </TableCell>
                    <TableCell style={{ color: '#FFFFFF' }}>
                      {t('Time')}
                    </TableCell>
                    {activeMeasurement.id === 191 && (
                      <TableCell style={{ color: '#FFFFFF' }}>
                        {t('Details')}
                      </TableCell>
                    )}
                    <TableCell padding="none" />
                    <TableCell padding="none" />
                    <TableCell padding="none" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {me?.tutorial === false
                    ? tutorialData.map((row, i) => (
                        <TableRow key={i}>
                          {!isBelowXs && (
                            <TableCell padding="checkbox">
                              {/* <Checkbox color="primary" /> */}
                            </TableCell>
                          )}
                          <TableCell>{row.value}</TableCell>
                          <TableCell>
                            {moment(row.time).format(DateFormats.GrNoTime)}
                          </TableCell>
                          <TableCell>
                            {moment(row.time).format(DateFormats.TIME)}
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            style={isBelowXs ? { padding: '0px 8px' } : {}}
                          >
                            <EditIcon
                              style={{
                                color: 'rgba(0, 0, 0, 0.58)',
                                fontSize: 18,
                                cursor: 'pointer',
                              }}
                            />
                          </TableCell>
                          <TableCell align="center" padding="none">
                            <Box
                              style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.34)',
                                width: 2,
                                height: 40,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            style={isBelowXs ? { padding: '0px 8px' } : {}}
                          >
                            <DeleteIcon
                              style={{
                                color: 'rgba(0, 0, 0, 0.58)',
                                fontSize: 18,
                                cursor: 'pointer',
                              }}
                              onClick={async () => {
                                await deleteMeasurement(row.id);
                                setMeasurementToEdit(undefined);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    : (rowsPerPage > 0
                        ? userMeasurements.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : userMeasurements
                      ).map((row, i) => (
                        <TableRow key={i}>
                          {!isBelowXs && (
                            <TableCell padding="checkbox">
                              {/* <Checkbox color="primary" /> */}
                            </TableCell>
                          )}
                          {activeMeasurement.id !== 397 ? (
                            <Fragment>
                              <TableCell>{row.value}</TableCell>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <TableCell>{row.high}</TableCell>
                              <TableCell>{row.low}</TableCell>
                            </Fragment>
                          )}
                          <TableCell>
                            {moment(row.time).format(DateFormats.GrNoTime)}
                          </TableCell>
                          <TableCell>
                            {moment(row.time).format(DateFormats.TIME)}
                          </TableCell>
                          {activeMeasurement.id === 191 && (
                            <TableCell>
                              {row.special === 'BEFORE'
                                ? t('Before')
                                : row.special === 'AFTER'
                                ? t('After')
                                : t('Other')}
                            </TableCell>
                          )}
                          <TableCell
                            align="center"
                            padding="none"
                            style={isBelowXs ? { padding: '0px 8px' } : {}}
                          >
                            <EditIcon
                              style={{
                                color: 'rgba(0, 0, 0, 0.58)',
                                fontSize: 18,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                window.scrollTo(0, 0);
                                setMeasurementToEdit(row);
                              }}
                            />
                          </TableCell>
                          <TableCell align="center" padding="none">
                            <Box
                              style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.34)',
                                width: 2,
                                height: 40,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            style={isBelowXs ? { padding: '0px 8px' } : {}}
                          >
                            <DeleteIcon
                              style={{
                                color: 'rgba(0, 0, 0, 0.58)',
                                fontSize: 18,
                                cursor: 'pointer',
                              }}
                              onClick={async () => {
                                await deleteMeasurement(row.id);
                                setMeasurementToEdit(undefined);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        20,
                        100,
                        { label: 'All', value: -1 },
                      ]}
                      labelRowsPerPage={t('RowsText')}
                      count={userMeasurements.length}
                      onChangePage={handleChangePage}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          </Box>
        )}
        <Dialog
          open={
            !me?.tutorial &&
            parseInt(openT) < 15 &&
            openT !== '0' &&
            ((screen === true && isBelowSm) || (screen === false && !isBelowSm))
          }
          BackdropProps={{
            style: { backgroundColor: 'black', opacity: 0.8 },
          }}
        >
          <Popover
            open={parseInt(openT) < 15}
            anchorEl={inputEl.current}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                WebkitTapHighlightColor: 'transparent',
                boxShadow: 'initial',
                width: isBelowSm ? '90vw' : '34vw',
              },
            }}
          >
            <Box style={{ padding: 12 }}>
              <Box style={{ margin: '8px 0px' }}>
                <Typography
                  style={{
                    padding: '16px 0px',
                    color: Colors.Black,
                    fontSize: !isBelowXs ? 20 : 18,
                    fontWeight: 'bold',
                  }}
                >
                  {t('RecordMeasurement')}
                </Typography>
              </Box>
              <Popover
                open={openT === '9' && !me?.tutorial}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                PaperProps={{
                  style: {
                    backgroundColor: 'transparent',
                    WebkitTapHighlightColor: 'transparent',
                    boxShadow: 'initial',
                    marginLeft: '2%',
                    marginTop: isBelowSm ? '5%' : '10%',
                    width: '50vw',
                  },
                }}
              >
                <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                  {t('Tut18')}
                </Typography>
              </Popover>
              <MeasurementsBox
                onAdd={() => {
                  userMeasurementsRefetch();
                }}
                onEdit={() => {
                  userMeasurementsRefetch();
                }}
                onCancel={() => setMeasurementToEdit(undefined)}
                measurement={measurementToEdit}
                active={activeMeasurement}
                lastMeasurement={userMeasurements[0]}
                tutorial={true}
              />
            </Box>
          </Popover>
        </Dialog>
      </Container>
    );
  } else {
    return (
      <Container
        style={{
          display: 'flex',
          height: '80vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={70} />
      </Container>
    );
  }
});

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  container1: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  padd: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    backgroundColor: '#FFFFFF',
  },
  addBox: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
    marginLeft: 10,
    flexDirection: 'column',
    width: '42%',
    height: 'fit-content',
  },
  text: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  graph: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    height: '100%',
  },
}));
