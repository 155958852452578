import React, { useState, useEffect, Fragment, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import {
  IconButton,
  makeStyles,
  Select,
  MenuItem,
  Typography,
  Button,
  Box,
  LinearProgress,
  useTheme,
  useMediaQuery,
  Popover,
  Container,
} from '@material-ui/core';
import { Clear, CloudUpload } from '@material-ui/icons';
import {
  PdfCategoryDTO,
  MedicalCenterDTO,
  UserPdfDTO,
  MedicalCenterDTOType,
  PregnancyPdfDTO,
  PdfResponseDTO,
  usePdfControllerGetMedicalCenters,
  usePdfControllerGetPdfCategories,
  pdfControllerUpdateExam,
  pregnancyControllerGetPregnancyPdf,
} from 'gen/client';
import { useTranslation } from 'react-i18next';
import { Colors, DateFormats } from 'res/Enums';
import moment, { Moment } from 'moment';
import { uploadFile, uploadPregnancyFile } from 'res/helpers';
import { toast } from 'react-toastify';
import { isUndefined } from 'util';
import { StyledDatePicker } from 'components/DatePicker';
import { useAuthContext } from 'context/AuthContext';
import { Demo } from 'components/Demo';
import { useTutorialContext } from 'context/TutorialContext';
import Cursor from 'assets/icons/cursor.png';

interface ExamsDrawerProps {
  open: boolean;
  onClose: () => void;
  onUpload: (resp?: PdfResponseDTO) => void;
  onDelete: () => void;
  exam?: UserPdfDTO;
  pregnancy?: boolean;
  pregnancyType?: PregnancyPdfDTO;
  time?: Moment;
  userExam?: number;
}
const _ExamsDrawer = ({
  open,
  onClose,
  onUpload,
  exam,
  pregnancy,
  pregnancyType,
  time,
  userExam,
}: ExamsDrawerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const [progress, setProgress] = useState(0);
  const [unique, setUnique] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedPregnancyExam, setSelectedPregnancyExam] =
    useState<PregnancyPdfDTO>({ id: 0, name: '', description: '' });
  const [medicalCenters, setMedicalCenters] = useState<MedicalCenterDTO[]>([]);
  const [selectedExam, setSelectedExam] = useState<PdfCategoryDTO>({
    id: 0,
    name: '',
  });
  const [selectedCenter, setSelectedCenter] = useState<{
    id: number;
    name: string;
  }>({
    id: 0,
    name: '',
  });
  const [selectedType, setSelectedType] =
    useState<number | MedicalCenterDTOType>(0);
  const [selectedRegion, setSelectedRegion] = useState<number | string>(0);
  const [pregnancyExams, setPregnancyExams] = useState<PregnancyPdfDTO[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [examCategories, setExamCategories] = useState<PdfCategoryDTO[]>([]);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { openT, me } = useTutorialContext();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const { data: medicalCentersData } = usePdfControllerGetMedicalCenters();
  const { data: examCategoriesData } = usePdfControllerGetPdfCategories();

  const handleRefresh = async () => {
    setSelectedDate(moment());
    setSelectedExam({ id: 0, name: '' });
    setSelectedCenter({ id: 0, name: '' });
    setFile(null);
  };

  const handleFile = (rawData: File) => {
    const data = rawData.type.split('/');
    const type = data[1];
    return type;
  };
  useEffect(() => {
    if (medicalCentersData && examCategoriesData) {
      setMedicalCenters(medicalCentersData.data);
      setExamCategories(
        examCategoriesData.data.filter((item) =>
          pregnancy === true ? item.id === 29 : true
        )
      );
    }
  }, [medicalCentersData, examCategoriesData]);

  // const addPregnancyPdf = async () => {
  //   try {
  //     const response = await apiClient().createMyPregnancyPdf({})
  //   } catch (error) {

  //   }
  // }

  const startUploadFile = () => {
    if (file !== null) {
      if (file.size <= 10 * 1000 * 1000) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('categoryId', selectedExam.id.toString());
        formData.append('centerId', selectedCenter.id.toString());
        formData.append('date', selectedDate.format(DateFormats.ISO));
        uploadFile(
          formData,
          (percent) => {
            setProgress(percent);
          },
          token ?? ''
        ).then((response) => {
          setProgress(0);
          if (response.data) {
            toast(t('ExamAdded'));
            handleRefresh();
            onClose();
            onUpload(response.data);
          } else {
            toast(t('UploadFailed'), { containerId: 'deleted' });
            onClose();
          }
        });
      } else {
        toast(t('SizeLimit10'), { containerId: 'deleted' });
      }
    }
  };

  const startUploadFilePregnancy = () => {
    if (file !== null) {
      if (file.size <= 10 * 1000 * 1000) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('categoryId', selectedExam.id.toString());
        formData.append('centerId', selectedCenter.id.toString());
        formData.append('date', selectedDate.format(DateFormats.ISO));
        formData.append(
          'pregnancyCategoryId',
          selectedPregnancyExam.id.toString()
        );
        formData.append(
          'pregnancyCategoryName',
          selectedPregnancyExam.name.toString()
        );
        formData.append(
          'pregnancyCategoryName',
          selectedPregnancyExam.name.toString()
        );
        formData.append(
          'pregnancyCategoryName',
          selectedPregnancyExam.name.toString()
        );
        if (time !== undefined)
          formData.append(
            'pregnancyCategoryDate',
            time.format(DateFormats.ISONoTime)
          );
        if (pregnancyType !== undefined)
          formData.append(
            'pregnancyCategoryDescription',
            pregnancyType.description
          );
        if (userExam !== undefined) formData.append('id', userExam.toString());
        uploadPregnancyFile(
          formData,
          (percent) => {
            setProgress(percent);
          },
          token ?? ''
        ).then((response) => {
          setProgress(0);
          if (response.data) {
            toast(t('ExamAdded'));
            handleRefresh();
            onUpload();
          } else {
            toast(t('UploadFailed'), { containerId: 'deleted' });
            onClose();
          }
        });
      } else {
        toast(t('SizeLimit10'), { containerId: 'deleted' });
      }
    }
  };

  const updateExam = async () => {
    try {
      if (!isUndefined(exam)) {
        const r = await pdfControllerUpdateExam({
          id: exam.id,
          category: selectedExam,
          medicalCenter: selectedCenter,
          date: selectedDate.format(DateFormats.ISO),
        });
        if (r) {
          toast(t('ExamUpdated'));
          handleRefresh();
          onClose();
          onUpload();
        } else {
          toast(t('UploadFailed'), { containerId: 'deleted' });
          onClose();
        }
      }
    } catch (e) {}
  };

  // const handleDelete = () => {
  //   if (!isUndefined(exam)) {
  //     apiClient
  //       .deleteMyExam(exam.id)
  //       .then(resp => {
  //         if (resp) {
  //           toast(t('DeletedExam'), { containerId: 'deleted' });
  //           onDelete();
  //         } else {
  //           toast(t('FailedDelete'), { containerId: 'deleted' });
  //         }
  //       })
  //       .catch(() => toast(t('FailedDelete'), { containerId: 'deleted' }));
  //   }
  // };

  const inputEl = useRef<any | null>(null);

  useEffect(() => {
    const getPregnancyExams = async () => {
      try {
        const data = await pregnancyControllerGetPregnancyPdf();
        setPregnancyExams(data.data);
      } catch (error) {}
    };

    if (pregnancy) getPregnancyExams();
  }, []);

  useEffect(() => {
    if (!isUndefined(exam)) {
      setSelectedCenter(exam.medicalCenter);
      setSelectedExam(exam.category);
      setSelectedDate(moment(exam.date));
    } else {
      setSelectedCenter({ id: 0, name: '' });
      setSelectedExam({ id: 0, name: '' });
      setSelectedDate(moment());
    }
  }, [exam]);

  useEffect(() => {
    if (medicalCenters.length > 0) {
      const unique = [...new Set(medicalCenters.map((item) => item.region))];
      setTimeout(() => {
        setUnique(unique);
      }, 200);
    }
  }, [medicalCenters]);

  useEffect(() => {
    if (
      selectedType === 0 ||
      medicalCenters.filter((item) => item.type !== selectedType)
    ) {
      setSelectedRegion(0);
      setSelectedCenter({ id: 0, name: '' });
    }
  }, [selectedType]);

  useEffect(() => {
    if (selectedRegion === 0) {
      setSelectedCenter({ id: 0, name: '' });
    } else if (
      selectedRegion === t('Other') &&
      selectedType === MedicalCenterDTOType.MEDICALCENTER
    )
      setSelectedCenter({ id: 189, name: '' });
    else if (
      selectedRegion === t('Other') &&
      selectedType === MedicalCenterDTOType.HOSPITAL
    )
      setSelectedCenter({ id: 1095, name: '' });
  }, [selectedRegion]);

  useEffect(() => {
    if (openT === '18') onClose();
  }, [openT]);

  useEffect(() => {
    if (examCategories.length === 1) setSelectedExam(examCategories[0]);
  }, [examCategories]);

  useEffect(() => {
    if (pregnancyType !== undefined) setSelectedPregnancyExam(pregnancyType);
  }, [pregnancyType]);

  return (
    <Container style={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 30,
          marginBottom: '10%',
        }}
      >
        <Typography
          style={
            !isBelowXs
              ? {
                  color: Colors.FadeBlack,
                  fontSize: 30,
                  fontWeight: 'bold',
                  paddingLeft: 30,
                  flexGrow: 1,
                }
              : {
                  color: Colors.FadeBlack,
                  fontSize: 24,
                  fontWeight: 'bold',
                  paddingLeft: 15,
                  flexGrow: 1,
                  display: 'flex',
                  textAlign: 'center',
                  alignItems: 'center',
                }
          }
        >
          {isUndefined(exam) ? t('UploadYourExams') : t('EditYourExams')}
        </Typography>
        <IconButton
          onClick={() => {
            handleRefresh();
            onClose();
          }}
          style={{ marginRight: 20 }}
        >
          <Clear style={{ fontSize: 30 }} />
        </IconButton>
      </Box>
      <Box style={{ margin: '0 10%' }}>
        <Box style={{ padding: '8px 16px' }}>
          <Select
            fullWidth
            value={selectedExam.id}
            onChange={(exam) =>
              setSelectedExam({
                id: parseInt(exam.target.value as string),
                name: exam.target.name as string,
              })
            }
          >
            {!pregnancy && (
              <MenuItem value={0} disabled>
                {t('ChooseExam')}
              </MenuItem>
            )}
            {examCategories.map((cat, i) => (
              <MenuItem
                key={i}
                value={cat.id}
                style={isBelowXs ? { fontSize: 12.8 } : {}}
              >
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {pregnancy && (
          <Box style={{ padding: '8px 16px' }}>
            <Select
              fullWidth
              value={selectedPregnancyExam.id}
              onChange={(exam) => {
                setSelectedPregnancyExam({
                  id: parseInt(exam.target.value as string),
                  name: pregnancyExams.filter(
                    (item) => item.id === exam.target.value
                  )[0].name,
                  description: pregnancyExams.filter(
                    (item) => item.id === exam.target.value
                  )[0].description,
                });
              }}
            >
              {/* <MenuItem value={0} disabled>
                {t('ChooseExam')}
              </MenuItem>
              {pregnancyExams.map((cat, i) => (
                <MenuItem
                  key={i}
                  value={cat.id}
                  style={isBelowXs ? { fontSize: 12.8 } : {}}
                >
                  {cat.name}
                </MenuItem>
              ))} */}
              <MenuItem
                value={selectedPregnancyExam.id}
                style={isBelowXs ? { fontSize: 12.8 } : {}}
              >
                {selectedPregnancyExam.name}
              </MenuItem>
            </Select>
          </Box>
        )}
        <Box style={{ padding: '8px 16px', marginTop: '7%' }}>
          <Select
            fullWidth
            value={selectedType}
            style={isBelowXs ? { maxWidth: 220 } : {}}
            onChange={(e) =>
              setSelectedType(e.target.value as MedicalCenterDTOType)
            }
          >
            <MenuItem value={0} style={isBelowXs ? { fontSize: 12.8 } : {}}>
              <Typography
                style={{
                  fontSize: isBelowXs ? 12.8 : 16,
                }}
              >
                {t('CenterType')}
              </Typography>
            </MenuItem>
            <MenuItem value={MedicalCenterDTOType.HOSPITAL}>
              <Typography
                style={{
                  fontSize: isBelowXs ? 12.8 : 16,
                }}
              >
                {t('Hospital')}
              </Typography>
            </MenuItem>
            <MenuItem value={MedicalCenterDTOType.MEDICALCENTER}>
              <Typography
                style={{
                  fontSize: isBelowXs ? 12.8 : 16,
                }}
              >
                {t('ExamCenter')}
              </Typography>
            </MenuItem>
          </Select>
        </Box>
        {selectedType !== 0 && (
          <Box style={{ padding: '8px 16px', marginTop: '7%' }}>
            <Select
              fullWidth
              value={selectedRegion}
              style={isBelowXs ? { maxWidth: 220 } : {}}
              onChange={(e) =>
                setSelectedRegion(e.target.value as string | number)
              }
            >
              <MenuItem value={0} style={isBelowXs ? { fontSize: 12.8 } : {}}>
                <Typography
                  style={{
                    fontSize: isBelowXs ? 12.8 : 16,
                  }}
                >
                  {t('Region')}
                </Typography>
              </MenuItem>
              {unique
                .filter((cat) => cat !== null)
                .map((cat, i) => (
                  <MenuItem key={i} value={cat}>
                    <Typography
                      style={{
                        fontSize: isBelowXs ? 12.8 : 16,
                      }}
                    >
                      {cat}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </Box>
        )}
        {selectedRegion !== 0 && (
          <Box style={{ padding: '8px 16px', marginTop: '7%' }}>
            <Select
              fullWidth
              value={selectedCenter.id}
              style={isBelowXs ? { maxWidth: 220 } : {}}
              onChange={(center) =>
                setSelectedCenter({
                  id: parseInt(center.target.value as string),
                  name: center.target.name as string,
                })
              }
            >
              <MenuItem value={0} disabled>
                <Typography
                  style={{
                    fontSize: isBelowXs ? 12.8 : 16,
                  }}
                >
                  {t(
                    selectedType === 'HOSPITAL'
                      ? 'ChooseHospital'
                      : 'ChooseCenter'
                  )}
                </Typography>
              </MenuItem>
              {medicalCenters
                .filter(
                  (center) =>
                    (selectedType !== 0
                      ? center.type === selectedType ||
                        (selectedType === MedicalCenterDTOType.MEDICALCENTER &&
                          center.id === 189) ||
                        (selectedType === MedicalCenterDTOType.HOSPITAL &&
                          center.id === 1095)
                      : center) &&
                    (selectedRegion === center.region ||
                      (selectedType === MedicalCenterDTOType.MEDICALCENTER &&
                        center.id === 189) ||
                      (selectedType === MedicalCenterDTOType.HOSPITAL &&
                        center.id === 1095))
                )
                .map((center, i) => (
                  <MenuItem key={i} value={center.id}>
                    <Typography
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: isBelowXs ? 12.8 : 16,
                      }}
                    >
                      {center.name}
                      {center.city && ' - '}
                      {center.city}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </Box>
        )}
        <Box style={{ padding: '8px 16px', marginTop: '7%' }}>
          <StyledDatePicker
            disableFuture
            format={DateFormats.GrNoTime}
            label={t('DateOfExam')}
            value={selectedDate}
            onChange={(date) => setSelectedDate(date as Moment)}
            autoOk
            fullWidth
            InputLabelProps={{ style: { fontSize: 20 } }}
          />
        </Box>
        {isUndefined(exam) && (
          <Box style={{ marginTop: '5%', padding: '8px 16px' }}>
            <Box className={classes.uploadContainer} maxWidth="100%">
              <Box
                style={{
                  display: 'flex',
                  fontSize: isBelowXs ? 16 : 18,
                  fontWeight: 'bold',
                  paddingLeft: isBelowXs ? 4 : 20,
                  flexGrow: 1,
                  marginRight: isBelowXs ? (file !== null ? 6 : 16) : 0,
                }}
              >{`${t('Exams')}:`}</Box>
              {file !== null ? (
                <Fragment>
                  <Box
                    display="flex"
                    style={{
                      border: '1px solid #263238',
                      borderRadius: '4px 0px 0px 4px',
                      margin: isBelowXs
                        ? '10px 10px 10px 0px'
                        : '10px 20px 10px 10px',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      style={{
                        backgroundColor: '#FF6262',
                        padding: isBelowXs ? 4 : 5,
                        color: Colors.IceWhite,
                        borderRadius: '5px 0px 0px 5px',
                      }}
                    >
                      <Typography style={isBelowXs ? { fontSize: 14 } : {}}>
                        {handleFile(file)}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flex: '1 1 auto',
                        justifyContent: 'center',
                        color: Colors.FadeBlack,
                        alignItems: 'center',
                        padding: '0 5px ',
                        minWidth: 0,
                        maxWidth: isBelowXs ? 84 : 190,
                      }}
                    >
                      <Box className={classes.title}>{file.name}</Box>
                    </Box>
                  </Box>
                  <Box style={{ marginRight: isBelowXs ? 4 : 8 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="label"
                      style={{ padding: 4, fontSize: isBelowXs ? 12 : 16 }}
                    >
                      {t('Change')}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e: any) =>
                          setFile(e.target.files[0] as File)
                        }
                      />
                    </Button>
                  </Box>
                </Fragment>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                  ref={inputEl}
                  style={
                    !isBelowXs
                      ? {
                          fontSize: 16,
                          fontWeight: 'bold',
                          margin: '10px 20px 10px 0px',
                          minWidth: 'fit-content',
                        }
                      : {
                          fontSize: 14,
                          fontWeight: 'bold',
                          margin: '10px 20px 10px 0px',
                          minWidth: 'fit-content',
                          padding: 4,
                        }
                  }
                >
                  {t('ChooseFile')}
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e: any) => setFile(e.target.files[0] as File)}
                  />
                </Button>
              )}
            </Box>
          </Box>
        )}
        <Box style={{ padding: '8px 16px' }}>
          {isUndefined(exam) ? (
            <Box
              style={{
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                marginTop: '7%',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={pregnancy ? startUploadFilePregnancy : startUploadFile}
                startIcon={<CloudUpload />}
                disabled={
                  file === null ||
                  selectedCenter.id === 0 ||
                  selectedExam.id === 0
                }
              >
                {t('UploadExam')}
              </Button>
              <LinearProgress
                style={{ marginTop: 3 }}
                variant="determinate"
                value={progress}
              />
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={updateExam}
              style={{ marginTop: '7%' }}
            >
              {t('EditExam')}
            </Button>
          )}
        </Box>
        {/* {!isUndefined(exam) && (
          <ListItem style={{ marginTop: '2%' }}>
            <Button
              variant="outlined"
              startIcon={<Delete />}
              fullWidth
              style={{ backgroundColor: 'red', color: Colors.IceWhite }}
              onClick={handleDelete}
            >
              {t('Delete')}
            </Button>
          </ListItem>
        )} */}
      </Box>
      <Popover
        open={!me?.tutorial && openT === '19'}
        anchorOrigin={{
          horizontal: isBelowMd ? 'left' : 'center',
          vertical: 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            WebkitTapHighlightColor: 'transparent',
            boxShadow: 'initial',
            marginLeft: isBelowMd ? '2%' : '-20%',
            marginTop: '-8%',
            width: '33vw',
            textAlign: 'center',
          },
        }}
      >
        <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
          {t('Tut29')}
        </Typography>
      </Popover>
      <Popover
        open={!me?.tutorial && openT === '19'}
        anchorEl={inputEl.current}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            WebkitTapHighlightColor: 'transparent',
            boxShadow: 'initial',
          },
        }}
      >
        <img alt="" src={Cursor} style={{ width: 42 }} />
      </Popover>
      {!me?.tutorial && <Demo />}
    </Container>
  );
};

const useStyles = makeStyles(() => ({
  drawer: {
    minWidth: '30vw',
  },
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    backgroundColor: Colors.IceWhite,
    border: '1px solid rgba(38, 50, 56, 0.12)',
    borderRadius: 2,
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
export const ExamsDrawer = hot(_ExamsDrawer);
