import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import moment, { Moment } from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Colors, DateFormats } from 'res/Enums';
import CloseIcon from '@material-ui/icons/Close';
import { StyledDateTimePicker } from 'components/DateTimePicker';
import {
  InsulinDTO,
  UserInsulinDTO,
  CreateInsulinSpecial as Special,
  useInsulinControllerGetInsulinTypes,
  useInsulinControllerCreateMyInsulin,
  UpdateInsulinSpecial,
  CreateInsulinSpecial,
} from 'gen/client';
import { useInsulinControllerUpdateMyInsulin } from './../../gen/client';

interface InsulinBoxProps {
  onAdd: () => void;
  onEdit: () => void;
  onCancel: () => void;
  insulin?: UserInsulinDTO;
  lastInsulin?: UserInsulinDTO;
}

const InsulinBox = ({
  insulin,
  onAdd,
  onEdit,
  onCancel,
  lastInsulin,
}: InsulinBoxProps) => {
  const [quantity, setQuantity] = useState<string>('');
  const [types, setTypes] = useState<InsulinDTO[]>([]);
  const [type, setType] = useState<InsulinDTO>({ id: 1, name: 'type1' });
  const [number, setNumber] = useState(0);
  const [time, setTime] = useState(moment());
  const [selectedValue, setSelectedValue] = useState(Special.INDIFFERENT);
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { data: typesData, isLoading: typesLoading } =
    useInsulinControllerGetInsulinTypes();
  const createMutation = useInsulinControllerCreateMyInsulin();
  const updateMutation = useInsulinControllerUpdateMyInsulin();

  const handleRefresh = async () => {
    setQuantity('');
    setTime(moment());
    setSelectedValue(Special.INDIFFERENT);
    setType({ id: 1, name: 'type1' });
    setNumber(0);
  };

  useEffect(() => {
    if (typesData) setTypes(typesData.data);
  }, [typesData]);

  const newInsulin = async () => {
    try {
      const response = await createMutation.mutateAsync({
        data: {
          quantity: parseInt(quantity),
          insulin: type,
          time: time.format(DateFormats.ISO),
          special: selectedValue,
        },
      });
      if (response) {
        toast(t('MeasurementAdded'));
        handleRefresh();
        onAdd();
      } else {
        toast(t('FailedAdding'), { containerId: 'deleted' });
      }
    } catch (error) {
      toast(t('Problem'), { containerId: 'deleted' });
    }
  };

  const updateInsulin = async (insulin: UserInsulinDTO) => {
    try {
      const response = await updateMutation.mutateAsync({
        id: insulin.id,
        data: {
          quantity: parseInt(quantity),
          insulin: insulin.insulin,
          time: time.format(DateFormats.ISO),
          special: selectedValue as unknown as UpdateInsulinSpecial,
        },
      });
      if (response) {
        toast(t('MeasurementEdited'));
        handleRefresh();
        onEdit();
      }
    } catch (error) {
      toast(t('Problem'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (insulin) {
      setQuantity(insulin.quantity.toString());
      setTime(moment(insulin.time));
      setType(insulin.insulin);
      setNumber(insulin.insulin.id);
      setSelectedValue(insulin.special as unknown as CreateInsulinSpecial);
      setEditMode(true);
    } else {
      setQuantity('');
      setTime(moment());
      setType({ id: 1, name: 'type1' });
      setSelectedValue(Special.INDIFFERENT);
      setEditMode(false);
    }
  }, [insulin]);

  useEffect(() => {
    if (parseInt(quantity) <= 0) setQuantity('');
  }, [quantity]);

  return (
    <Box display="flex" className={classes.addBox}>
      <Box
        style={{
          display: 'flex',
          paddingRight: isBelowXs ? 16 : 30,
          alignItems: 'center',
        }}
      >
        {editMode === true ? (
          <Box style={{ flexGrow: 1 }}>
            <IconButton
              onClick={() => {
                handleRefresh();
                setEditMode(false);
                onCancel();
              }}
              style={{ marginLeft: 16 }}
            >
              <CloseIcon style={{ fontSize: 30 }} />
            </IconButton>
          </Box>
        ) : (
          <Box style={{ flexGrow: 1 }} />
        )}
        <IconButton style={{ fontSize: 20 }} onClick={handleRefresh}>
          <RefreshIcon
            fontSize="inherit"
            style={{
              fontWeight: 'bold',
              color: Colors.PRIMARY,
              transform: 'rotateY(180deg)',
            }}
          />
        </IconButton>
        <Typography
          style={{
            fontSize: isBelowXs ? 12 : 14,
            fontWeight: 'bold',
            color: '#263238',
          }}
        >
          {t('CleanData')}
        </Typography>
      </Box>
      <Fragment>
        <Typography
          style={{
            marginTop: 30,
            color: '#263238',
            fontWeight: 600,
            fontSize: 18,
            paddingLeft: 40,
          }}
        >
          {t('Insulin')}
        </Typography>
        <TextField
          className={classes.text}
          value={quantity}
          type="number"
          onChange={(e) => setQuantity(e.target.value)}
        />
      </Fragment>
      <Box style={{ display: 'flex', marginTop: 16, justifyContent: 'center' }}>
        <Select
          value={number}
          style={{ margin: '0 40px' }}
          fullWidth
          classes={{ select: classes.select }}
          onChange={(e) => {
            setNumber(e.target.value as number);
            setType(
              types.find((item) => item.id === e.target.value) as InsulinDTO
            );
          }}
        >
          <MenuItem hidden disabled value={0}>
            {t('ChooseInsulinType')}
          </MenuItem>
          {types.map((shape, i) => (
            <MenuItem key={i} value={shape.id}>
              {shape.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        style={{
          margin: '20px 40px 0px 40px',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 2,
        }}
      >
        <Typography
          style={{
            paddingTop: isBelowXs ? 4 : 10,
            fontSize: isBelowXs ? 13 : 16,
            fontWeight: 'bold',
            paddingLeft: isBelowXs ? 4 : 10,
          }}
        >
          {t('Food')}
        </Typography>
        <FormControl
          component="fieldset"
          style={{ paddingLeft: isBelowXs ? 8 : 16 }}
        >
          <RadioGroup
            value={selectedValue}
            onChange={(e) =>
              setSelectedValue(
                ((e) => {
                  switch (e.target.value as string) {
                    case Special.INDIFFERENT.toString():
                      return Special.INDIFFERENT;
                    case Special.AFTER.toString():
                      return Special.AFTER;
                    case Special.BEFORE.toString():
                      return Special.BEFORE;
                    default:
                      return Special.INDIFFERENT;
                  }
                })(e)
              )
            }
            row
          >
            <FormControlLabel
              value={Special.INDIFFERENT}
              control={
                <Radio
                  style={
                    isBelowXs
                      ? {
                          color: Colors.PrimaryLight,
                          padding: '9px 0px 9px 9px',
                        }
                      : { color: Colors.PrimaryLight }
                  }
                />
              }
              label={t('Other')}
              classes={{ label: classes.radio }}
              style={{ marginRight: isBelowXs ? 4 : 20 }}
            />
            <FormControlLabel
              value={Special.BEFORE}
              control={
                <Radio
                  style={
                    isBelowXs
                      ? {
                          color: Colors.PrimaryLight,
                          padding: '9px 0px 9px 9px',
                        }
                      : { color: Colors.PrimaryLight }
                  }
                />
              }
              label={t('Before')}
              classes={{ label: classes.radio }}
              style={{ marginRight: isBelowXs ? 4 : 20 }}
            />
            <FormControlLabel
              value={Special.AFTER}
              control={
                <Radio
                  style={
                    isBelowXs
                      ? {
                          color: Colors.PrimaryLight,
                          padding: '9px 0px 9px 9px',
                        }
                      : { color: Colors.PrimaryLight }
                  }
                />
              }
              label={t('After')}
              classes={{ label: classes.radio }}
              style={{ marginRight: isBelowXs ? 4 : 20 }}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Typography
        style={{
          margin: '20px 40px 0px 40px',
          color: '#263238',
          fontSize: 18,
          fontWeight: 'bold',
        }}
      >
        {t('DateTime')}
      </Typography>
      <Fragment>
        <StyledDateTimePicker
          autoOk
          autoFocus
          style={{ margin: '0px 40px 50px 40px' }}
          value={time}
          margin="normal"
          format={DateFormats.GR}
          onChange={(date) => setTime(date as Moment)}
          animateYearScrolling
          disableFuture
        />
      </Fragment>
      {editMode === true && insulin ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            updateInsulin(insulin);
            setEditMode(false);
            onCancel();
          }}
          classes={{ label: classes.label }}
          disabled={quantity === '' || number === 0}
        >
          {t('InsulinEdit')}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          endIcon={<AddIcon style={{ marginLeft: 20, fontSize: 40 }} />}
          onClick={() => {
            newInsulin();
            onCancel();
          }}
          disabled={quantity === '' || number === 0}
        >
          {t('InsulinAdd')}
        </Button>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  addBox: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
    flexDirection: 'column',
    height: 'fit-content',
    width: '100%',
  },
  text: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  label: {
    padding: '2% 0% 2% 0%',
  },
  select: {
    fontSize: 15,
  },
  radio: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
  },
}));
export default hot(InsulinBox);
