import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Colors, DateFormats } from 'res/Enums';
import { Formik, Form } from 'formik';
import { FormTextField } from 'components/Forms/FormTextField';
import { FormikSelectField } from 'components/Forms/FormSelectField';
import { sexSelectItems } from 'Pages/Intro/Register';
import * as Yup from 'yup';
import { isUndefined } from 'util';
import { FormDateField } from 'components/Forms/FormDateField';
import { toast } from 'react-toastify';
import { Help } from '@material-ui/icons';
import { ChangePassword } from './ChangePassword';
import moment from 'moment';
import {
  useMeControllerGetMe,
  UserDto,
  UpdateUserSex,
  meControllerUpdateMe,
} from 'gen/client';
const _EditProfile = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [me, setMe] = useState<UserDto>();
  const { data: meData } = useMeControllerGetMe();

  const handleBasicProfile = (values: UserDto) => {
    meControllerUpdateMe({
      ...values,
      birthDate: moment(values.birthDate).format(DateFormats.ISO),
      sex: values.sex as unknown as UpdateUserSex,
    })
      .then(() => toast(t('Updated')))
      .catch();
  };
  useEffect(() => {
    if (meData) setMe(meData.data);
  }, [meData]);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('ReEmail2')).required(t('ReEmail')),
    name: Yup.string().min(3, t('ReName2')).required(t('ReName')),
    surname: Yup.string().min(3, t('ReSurname2')).required(t('ReSurname')),
    sex: Yup.string().required(t('ReSex')),
  });
  return (
    <Grid container spacing={3}>
      {me ? (
        <Fragment>
          <Grid className={classes.cols} item xs={12} md={6}>
            <Typography variant="h6" className={classes.header}>
              {t('BasicInfo')}
            </Typography>
            <Formik
              initialValues={me}
              onSubmit={handleBasicProfile}
              validationSchema={validationSchema}
            >
              {({ isValid, dirty, errors }) => {
                return (
                  <Form>
                    <FormTextField
                      margin="normal"
                      variant="outlined"
                      label="Email"
                      name="email"
                      disabled
                      error={!isUndefined(errors.email)}
                    />
                    <FormTextField
                      margin="normal"
                      variant="outlined"
                      label={t('Name')}
                      name="name"
                      error={!isUndefined(errors.name)}
                    />
                    <FormTextField
                      margin="normal"
                      variant="outlined"
                      label={t('Surname')}
                      name="surname"
                      error={!isUndefined(errors.surname)}
                    />
                    <FormikSelectField
                      items={sexSelectItems}
                      name="sex"
                      label={t('Sex')}
                    />
                    <Grid
                      container
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Grid item xs={11}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <FormTextField
                              margin="normal"
                              variant="outlined"
                              label={t('EmergencyNumber')}
                              name="emergency.emergencyNumber"
                              InputLabelProps={{
                                classes: {
                                  outlined: classes.outlined,
                                  shrink: classes.shrink,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <FormTextField
                              margin="normal"
                              variant="outlined"
                              label={t('EmergencyName')}
                              name="emergency.contactName"
                              InputLabelProps={{
                                classes: {
                                  outlined: classes.outlined,
                                  shrink: classes.shrink,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={
                            <Typography style={{ fontSize: 14 }}>
                              {t('EmergencyTip')}
                            </Typography>
                          }
                        >
                          <Box>
                            <IconButton>
                              <Help />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <FormDateField name="birthDate" label={t('BirthDate')} />
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!dirty || !isValid}
                    >
                      {t('Update')}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
          <Grid className={classes.cols} item xs={12} md={6}>
            <Typography variant="h6" className={classes.header}>
              {t('ChangePassword')}
            </Typography>
            <ChangePassword />
          </Grid>
        </Fragment>
      ) : (
        <CircularProgress />
      )}
    </Grid>
  );
};
const useStyles = makeStyles(() => ({
  cols: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    color: Colors.FadeBlack,
    fontWeight: 'bold',
  },
  outlined: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: -25,
    transform: 'translate(0, 24px) scale(1)',
  },
  shrink: {
    transform: 'translate(14px, -6px) scale(0.75)',
    marginTop: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}));
export const EditProfile = hot(_EditProfile);
