import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
i18n.use(initReactI18next).init({
  resources: {
    'en-GB': {
      translation: {
        Login: 'Login',
        Username: 'Username',
        Password: 'Password',
        Logout: 'Logout',
        MeasurementTime: 'Measurement Time',
        MeasurementValue: 'Measurement Value',
        Measurements: 'Measurements',
        Measurement: 'Measurement',
        OfMeasurement: 'Measurement',
        Home: 'Home',
        MeasurementName: 'Measurement Name',
        Value: 'Value',
        Search: 'Search',
        OfMedication: 'Medication',
        nextDosage: 'Next Dosage',
        MedicationUpdated: 'Medication Updated',
        ClearData: 'Clear Data',
        ForLife: 'For Life',
        StartDate: 'Start Date',
        EndDate: 'End Date',
        MedHistory: 'Medical History',
        EditMedication: 'Edit Medication',
        AddMedication: 'Add Medication',
        Frequency: 'Frequency',
        Medication: 'Medication',
        MyDay: 'My Day',
        ExamFolder: 'Exams Folder',
        ExamFolderEmtpyText:
          'Your Exams folder is empty. Begin by uploading your medical exams.',
        MeasurementEmptyText:
          'You have`t register any measurements about your health yet',
        SymptomsEmptyText: 'You haven`t add for this relevant symptom',
        AddExam: 'Add Exam',
        ExamGo: 'Go to your Exams Page',
        Sugar: 'Blood Sugar',
        UploadYourExams: 'Upload your exams',
        DateOfExam: 'Date of your exam',
        MeasurementAdded: 'Your Measurement was successfully Added',
        MeasurementEdited: 'Your Measurement was successfully Edited',
        FailedAdding: 'There was an Error',
        AllExams: 'All your exams',
        Results: 'Results',
        Exams: 'Exams',
        EditYourExams: 'Edit your exam',
        ExamUpdated: 'Updated successfully',
        ExamAdded: 'Added successfully',
        ChooseExam: 'Exam Category',
        Choose: 'Options',
        ChooseCenter: 'Medical Center',
        DeletedExam: 'Exam deleted',
        Delete: 'Delete',
        Deleted: 'Deleted',
        DeleteFailed: 'Delete Failed',
        MeasurementDeleted: 'Measurement Deleted',
        FailedDeleting: 'There was an Error with delete',
        NoteOfDay: 'Your note for ',
        Symptoms: 'Symptoms',
        SymptomDeleted: 'Symptom Deleted',
        SymptomAdded: 'Your Symptom was successfully Added',
        SymptomEdited: 'Your Symptom was successfully Edited',
        Fitness: 'Fitness',
        FitnessDeleted: 'Fitness Deleted',
        FitnessAdded: 'Your Fitness was successfully Added',
        FitnessEdited: 'Your Fitness was successfully Edited',
        MeasurementAt: 'Last Entry at:',
        MostCommon: 'Most Common Measurements',
        BloodPressure: 'Blood Pressure',
        HeartBeat: 'Heart Beat Rate',
        LDLCholesterol: 'LDL Cholesterol',
        HDLCholesterol: 'HDL Cholesterol',
        SleepReg: 'Sleep Duration',
        AllMeasurement: 'All Your Measurements',
        High: 'High',
        Low: 'Low',
        Date: 'Date',
        Time: 'Time',
        Details: 'Details',
        Problem: 'There was a problem',
        CleanData: 'Clean Data',
        Food: 'Food',
        DateTime: 'Date and Time',
        EditMeasurement: 'Edit Measurement',
        CancelEdit: 'Cancel Edit',
        RecordMeasurement: 'Record New Measurement',
        Fatigue: 'Fatigue',
        Constipation: 'Constipation',
        Nausea: 'Nausea',
        Diarrhea: 'Diarrhea',
        Vomiting: 'Vomiting',
        Hyperglycaemia: 'Hyperglycaemia',
        Hypoglycaemia: 'Hypoglycaemia',
        Migraine: 'Migraine',
        Itching: 'Itching Intensity',
        Headache: 'Headache Level',
        Pain: 'Pain Level',
        Fever: 'Fever',
        Dyspnea: 'Dyspnea',
        Heartburn: 'Heartburn',
        MoodSwings: 'Mood Swings',
        FrequentUrination: 'Frequent Urination',
        Comments: 'Comments',
        Running: 'Running',
        Swimming: 'Swimming',
        Walking: 'Walking',
        Gym: 'Gym',
        Dancing: 'Dancing',
        ActivityTime: 'Activity Duration',
        Distance: 'Distance',
        FitnessGrade: 'Fitness Grade:',
        FitnessBegun: 'you started the Fitness',
        FitnessEnded: 'you ended the Fitness',
        FitnessInten: 'Fitness intensity',
        Minutes: 'Minutes',
        Seconds: 'Seconds',
        RowsText: 'Rows per Page:',
        SymptomFace: 'Add and monitor your symptoms',
        Health: 'Well Being',
        MeasurementTable: 'Measurements Table',
        FeedbackReg: 'Your feedback has been registered',
        QPhysically: 'How are you feeling physically today?',
        QEmotionally: 'How are you feeling emotionaly today?',
        FirstQuestion: 'Physical Score',
        SecondQuestion: 'Emotional Score',
        HealthScore: 'Health Score',
        UpcomingDates: 'Upcoming Dates',
        Add: 'Add',
        Edit: 'Edit',
        Average: 'Average',
        Journal: 'Journal',
        Folder: 'Folder',
        ShortHistory: 'Short History',
        HealthProfile: 'Health Profile',
        Preventive: 'Preventive Exams',
        More: 'More',
        Cycle: 'Menstrual Cycle',
        Diabetes: 'Diabetes',
        Insulin: 'Insulin',
        Placeholder: 'Measurement Value',
        TimeOF: 'Time of the Day',
        Questions: 'Questions of the Day',
        Or: 'Or',
        Create: 'Sign Up',
        Forgot: 'Forgot your username or password?',
        CreateAccount: 'CREATE AN ACCOUNT',
        Sex: 'Sex',
        Register: 'Register',
        ReUsername: 'Required Username',
        ReUsername2: 'Username must be at least 3 characters',
        RePassword: 'Required Password',
        RePassword2: 'Password must be at least 8 characters',
        RePassword3: 'It is not the same with the Password above',
        ReName: 'Required Name',
        ReSurname: 'Required Surname',
        ReSurname2: 'Surname must be at least 3 characters',
        ReEmail: 'Required Email',
        ReEmail2: 'Invalid Email Address',
        ReSex: 'You need to choose Sex',
        ReBirthDate: 'You need to add birth date',
        ReTerms: 'You need to accept the terms of service before continuing',
        Temperature: 'Temperature',
        Humidity: 'Humidity',
        Air: 'Air Quality',
        Profile: 'Profile',
        Protection: 'Data and Protection',
        Management: 'Account Management',
        DeleteAccount: 'Delete Account',
        Failed: 'Failed',
        EmailSent: 'Email sent successfully',
        EmailNotFound: 'Account with this email not found',
        Room: 'Room Conditions',
        Declared: 'Added at',
        Greek: 'Greek',
        English: 'English',
        Name: 'Name',
        Surname: 'Surname',
        EmergencyNumber: 'Emergency Phone',
        EmergencyName: 'Name of the responsible person',
        BirthDate: 'Birth date',
        BasicInfo: 'Basic Information',
        ChangePassword: 'Change Password',
        EmergencyTip:
          'The information for the person you want contacted in case of emergency',
        RepeatPassword: 'Repeat Password',
        SearchFrom: 'From date...',
        SearchTo: 'To date...',
        ExamCenter: 'Medical Center',
        ExamCategory: 'Exam Category',
        ExamDeleted: 'Deleted successfully',
        ResetFilter: 'Reset Filter',
        UploadExam: 'Upload Exam',
        ChooseFile: 'Choose File',
        SizeLimit10: 'File size must be less than 10mb',
        NoteAdded: 'Note added',
        Duration: 'Duration',
        Days: 'Days',
        Hours: 'Hours',
        Color: 'Color',
        NoFilter: 'There are no values that fulfil the criteria ',
        NoData: 'There are no values yet, start by adding new values ',
        RequestPasswordReset: 'Request Password Reset',
        SendRequest: 'Send Request',
        AddSymptom: 'Add a new Symptom',
        TimetableTitle: 'Select detailed dosage and frequency',
        SignIn: 'Sign In',
        Dosages: 'Dosages',
        Dosage: 'Dosage',
        DetailedDosages: 'Detailed dosage table',
        Other: 'Other',
        Before: 'Before eating',
        After: 'After eating',
        TheFood: 'Food',
        Meters: 'Km',
        StopMedication: 'Cancel your medication',
        Yes: 'Yes',
        No: 'No',
        Hi: 'Hi',
        MoreInfo: 'There aren`t enough Information yet',
        Smoker: 'Smoker',
        NoSmoker: 'Not Smoker',
        NoListed: 'There are no listed ',
        Allergies: 'Allergies',
        Aller: 'Allergies',
        ChronicDisease: 'Chronic Diseases',
        Chronic: 'Chronic Diseases',
        Diseases: 'Diseases',
        PQuestion1: 'When was your last Period?',
        PQuestion2: 'Average cycle duration',
        PQuestion3: 'Average period duration',
        PAsk1: 'Submit your menstrual cycle',
        PAsk2: 'Edit your menstrual cycle',
        CycleSubmit: 'Submit your Cycle',
        SaveChanges: 'Save Changes',
        General: 'General infomations',
        YourCycle: 'Your menstrual cycle',
        CycleMonitor: 'Monitor your menstrual cycle',
        CycleDay: 'Day of Menstrual Cycle',
        PeriodDays: 'Days until next Period',
        CycleDuration: 'Cycle Duration',
        PeriodDuration: 'Period Duration',
        CycleMonths1: 'Last month',
        CycleMonths2: 'Next month',
        CycleHistory: 'Period History',
        CycleSure: 'Edit Cycle?',
        CycleDescr: 'Are you sure you want to edit this Cycle?',
        CycleUpdated: 'Your period has been updated',
        Female: 'Female',
        Male: 'Male',
        Calendar: 'Calendar',
        Nomed: 'There is no current medication listed',
        AddMeasurement: 'Add New Measurement',
        FreqAndDosage: 'Frequency and Dosage',
        Quantity: 'Quantity',
        FreqEvery: 'Frequency, every',
        TimetableLabel: 'Timetable',
        UserInfo: 'Info ',
        TemporaryDiseases: 'Other diseases',
        Welmed: 'Welcome, begin add your medicines',
        Proi: 'Morning',
        Mesimeri: ' Noon',
        Vrady: 'Night',
        All: 'All',
        Week: 'Weeks',
        Month: 'Months',
        Year: 'Years',
        Rest: 'Other Diseases',
        Note: 'Notes',
        ExamName: 'Exam Name',
        Description: ' Exam Description',
        Treximo: 'Run',
        Perpatima: 'Walk',
        SumDuration: 'Sum Duration',
        SumDistance: 'Sum Distance',
        AvgDistperday: 'Avg Distance',
        AvgDirperday: 'Avg Duration',
        CheckBox: 'You need to check the Box',
        SkipNoSave: 'Skip without Save',
        Register2Added: 'Your initial register is complete',
        Welcome: 'Welcome to Healthier!',
        Save: 'Save',
        WelcomeMeasurements: 'You haven`t register any measurements yet',
        Weight: 'Weight',
        Height: 'Height',
        PlaceNote: 'PlaceNote',
        Reg: 'I confirm that I have read and agree to the',
        Terms: 'Terms of use',
        FitnessReg: 'Add your Fintess',
        FitnessRegEdit: 'Change your Fitness',
        MustHigher: ' must be high than low blood pressure',
        MustSooner: ' must be sooner than ',
        Policy: 'Privacy Policy',
        Rmu: 'and receive emails and updates',
        SaveCont: 'Save and continue',
        Epil: 'Choose',
        Smoking: 'Are you smoking?',
        BloodType: 'Blood type',
        FitStat: 'Statistics',
        OtherDiseases: 'There aren`t any other registered diseases',
        Change: 'Change',
        ChangeGraph1: 'Data table',
        ChangeGraph2: 'Graph',
        Hypoglucemia: 'Hypoglucemia',
        Hyperglucemia: 'Hyperglucemia',
        Type: 'Type',
        ChooseInsulinType: 'Choose an insulin type',
        PrePeriod: 'Pre period',
        PeriodDay: 'Period days',
        PostPeriod: 'Post period',
        PeakOvulation: 'Peak ovulation',
        DuplicateUser: 'There is already a registered account with this email.',
        AutoC: 'Calculate Automatically',
        Cancel: 'Cancel',
        EditExam: 'Edit Exam',
        AddedPreventive: 'Preventive checked',
        DeletedPreventive: 'Preventive unchecked',
        RememberMe: 'Stay Signed In',
        NoOptions: 'No Options',
        Undo: 'Undo',
        Deleting: 'Deleting',
        km: 'km',
        MoreDetails: 'More details',
        Fit: 'Fitness',
        Welfit: 'Welcome, insert your fitness data',
        FitnessType: 'Fitness Type',
        MedicationAdded: 'Medication added succesfully',
        MedicationStopped: 'Medication Stopped',
        cm: 'cm',
        CycleAdded: 'Cycle Added',
        ExamsTitle: 'Exams History',
        ExamsText: 'Register, edit and search for your medical examinations',
        YourComment: 'Your comment',
        Diagram: 'Values diagram',
        DiagramT: 'Values table',
        InsulinAdd: 'Add new dosage',
        InsulinEdit: 'Edit your dosage',
        Shape: 'Shape',
        Score: 'Your answer',
        CycleError: 'Cycle duration must be at least 15',
        Changed: 'Changed',
        And: 'And',
        reportBug: 'Report a bug',
        From: 'From...',
        To: 'To...',
        Success: 'Successfully added',
        Error: 'Unsuccessfully added',
        OtherEvents: 'Other Events',
        LoginFail: 'Failed to Login',
        GeneralSuccess: 'Successful entry',
        GoogleEntry: 'Save and continue',
        PeriodError: 'End day can`t be same or before start day',
        CycleDeleted: 'Your cycle was deleted successfully',
        InsulinType: 'Insulin type',
        GoToCalendar: 'Add other notes and symptoms by going to Calendar',
        Hematochezia: 'Hematochezia',
        Cramps: 'Cramps',
        Ageusia: 'Loss of Taste',
        Anosmia: 'Anosmia',
        LastMeasurement: 'Your last measurements',
        Preventivetitle: 'Complete the Exam',
        EditFailed: 'There was an error while editing',
        SearchMeasurements: 'Search for all measurements in the list',
        Covid1: 'Disease management',
        Covid2: 'I have been diagnosed with the virus',
        Covid3:
          'It is possible that i suffer from the virus but i have not been diagnosed with it',
        Covid4:
          'I have come in contact with a confirmed case of the virus in the last 14 days',
        Covid5: 'Manage quarantine/travel restrictions',
        Covid6: 'I have been put in a mandatory quarantine for 14 days',
        Covid7: 'I have been rectricted in traveling',
        Covid8: 'Days remaining:',
        Covid9: 'You have exercised ',
        Covid10: ' from ',
        Covid11: ' days of the travel restrictions',
        Covid12: 'Manage your symptoms',
        Covid13: 'Based on your profile, you belong to a high risk group',
        Covid14: 'Taken the first shot of the vaccine',
        Covid15: 'Taken the second shot of the vaccine',
        Covid16: 'Taken the third shot of the vaccine',
        Covid17: 'Taken the fourth shot of the vaccine',
        SoreThroat: 'Sore throat',
        Cough: 'Cough',
        Snuffles: 'Snuffles',
        Dyspepsia: 'Dyspepsia',
        OtherSymptoms: 'Other symptoms',
        AboutUs: 'About us',
        VisitUsAt: 'Visit us at',
        FindTerms: 'Look up our terms of agreement',
        Here: 'here',
        CompanySite: 'company site',
        LastSymptom: 'Last time you exhibited this symptom',
        SeeMore: 'See more',
        Tutorial: 'Tutorial',
        Tut1: 'Welcome to Healthier!',
        Tut2: 'Here is a tutorial to our site',
        Tut3: 'Click here to start the Tutorial',
        Tut4: 'Click here to skip the Tutorial',
        Exit: 'Exit',
        Continue: 'Continue',
        NextStep: 'Go to the next step',
        bpm: 'Heart Beats',
        The: 'the',
        MailList: 'I agree to receive informational email',
        PasswordChanged: 'Password changed',
        TokenExpired: 'Reset timer has expired',
        ExamHistory: 'Exam History',
        CenterType: 'Center type',
        Region: 'Region',
        Under: 'Bottom limit',
        Over: 'Upper limit',
        Age: 'Age',
        SelfDiagnosis: 'Self Diagnosis Test',
        Test1: '10 year Cardiovascular Risk',
        Test2: 'Stroke Risk',
        Test3: 'Osteoporosis Risk',
        DoTakePressureMedication:
          'Do you receive treatment for Blood Pressure?',

        HDL: 'HDL Cholesterol',
        Systolic: 'Systolic Blood Pressure',
        Cholesterol: 'Cholesterol',
        Overweight: 'Your weight?',
        Overweight0: 'Healthy',
        Overweight1: 'Slightly overweight',
        Overweight2: 'Obese',
        Estrogen: 'Estrogen',
        Prior: 'Prior use',
        NoPrior: 'No prior use',
        Race: 'Race',
        Black: 'Black',
        RArthritis: 'Rheumatoid Arthritis',
        Present: 'Present',
        Absent: 'Absent',
        FractureHistory: 'Fracture History',
        NonTraumatic0: 'No nontraumatic',
        NonTraumatic1: '1 nontraumatic',
        NonTraumatic2: '2 nontraumatic',
        NonTraumatic3: '3 or more nontraumatic',
        Calculate: 'Calculate',
        StartDiagnosis: 'Start Self-Diagnosis',
        Birth: 'Birth',
        Abortion: 'Abortion',
        Miscarriage: 'Miscarriage',
        OtherPregnancy: 'Other reason for ending',
        PregnancyOptions: 'Pregnancy Options',
        PregnancyReason: 'Pregnancy reason',
        End: 'End',
        PregnancyDelete: 'Delete Pregnancy',
        PregnancyBack: 'Go back to your pregnancy',
      },
    },
    'el-GR': {
      translation: {
        The: 'την',
        Login: 'ΣΥΝΔΕΣΗ',
        Username: 'Όνομα χρήστη',
        Password: 'Κωδικός πρόσβασης',
        Logout: 'Αποσύνδεση',
        MeasurementTime: 'Ώρα μέτρησης',
        MeasurementValue: 'Τιμή μέτρησης',
        Measurements: 'Μετρήσεις υγείας',
        Measurement: 'Μέτρηση',
        OfMeasurement: 'μέτρησης',
        Home: 'Αρχική',
        MeasurementName: 'Μέτρηση',
        Value: 'Τιμή',
        Search: 'Αναζήτηση',
        OfMedication: 'φαρμακεύτικης αγωγής',
        nextDosage: 'Επόμενη δόση',
        MedicationUpdated: 'Η αγώγη ενημερώθηκε',
        ClearData: 'Καθαρισμός',
        ForLife: 'Χωρίς προκαθορισμένη διάρκεια',
        StartDate: 'Ημερομηνία έναρξης',
        EndDate: 'Ημερομηνία τέλους',
        MedHistory: 'Ιστορικό φαρμακευτικής αγωγής',
        EditMedication: 'Αποθήκευση φαρμακευτικής αγωγής',
        AddMedication: 'Προσθήκη φαρμακευτικής αγωγής',
        Frequency: 'Συχνότητα δόσης',
        Medication: 'Φαρμακευτική αγωγή',
        MyDay: 'Καθημερινά',
        ExamFolder: 'Ιατρικός φάκελος',
        ExamFolderEmtpyText: 'Δεν έχεις αποθηκεύσει ακόμα κάποια εξέταση.',
        MeasurementEmptyText:
          'Δεν έχεις καταγράψει κάποια μέτρηση σχετικά με την υγεία σου',
        SymptomsEmptyText:
          'Δεν έχεις καταχωρήσει ακόμα κάποιο σχετικό σύμπτωμα.',
        AddExam: 'Καταχώρηση εξέτασης',
        ExamGo: 'Πήγαινε στον ιατρικό σου φάκελο',
        Sugar: 'Σάκχαρο',
        UploadYourExams: 'Προσθήκη εξέτασης',
        DateOfExam: 'Ημερομηνία εξέτασης',
        MeasurementAdded: 'Επιτυχής προσθήκη μέτρησης',
        MeasurementEdited: 'Επιτυχής ενημέρωση μέτρησης',
        FailedAdding: 'Υπήρξε λάθος με την εγγραφή',
        AllExams: 'Σύνολο εξετάσεων',
        Results: 'Αποτελέσματα',
        Exams: 'Εξετάσεις',
        EditYourExams: 'Επεξέργασια εξέτασης',
        ExamUpdated: 'Επιτυχής ενημέρωση',
        ExamAdded: 'Επιτυχής αποθήκευση',
        ChooseExam: 'Επιλογή κατηγορίας',
        Choose: 'Επιλογή',
        ChooseCenter: 'Επιλογή ιατρικού κέντρου',
        ChooseHospital: 'Επιλογή νοσοκομείου',
        DeletedExam: 'Διαγραφή εξέτασης',
        Delete: 'Διαγραφή',
        Deleted: 'Διαγράφηκε',
        DeleteFailed: 'Μη επιτυχής διαγραφή ',
        MeasurementDeleted: 'Επιτυχής διαγραφή μέτρησης',
        FailedDeleting: 'Υπήρξε λάθος με την διαγραφή',
        NoteOfDay: 'Η σημείωσή σου για τις ',
        Symptoms: 'Συμπτώματα',
        SymptomDeleted: 'Επιτυχής διαγραφή συμπτώματος',
        SymptomAdded: 'Επιτυχής προσθήκη συμπτώματος',
        SymptomEdited: 'Επιτυχής ενημέρωση συμπτώματος',
        Fitness: 'Άσκηση',
        FitnessDeleted: 'Επιτυχής διαγραφή άσκησης',
        FitnessAdded: 'Επιτυχής προσθήκη άσκησης',
        FitnessEdited: 'Επιτυχής ενημέρωση άσκησης',
        MeasurementAt: 'Η τελευταία καταχώρηση έγινε στις:',
        MostCommon: 'Πιο συνηθισμένες μετρήσεις',
        BloodPressure: 'Πίεση',
        HeartBeat: 'Καρδιακοί παλμοί',
        LDLCholesterol: 'LDL χοληστερόλη',
        HDLCholesterol: 'HDL χοληστερόλη',
        SleepReg: 'Διάρκεια ύπνου',
        AllMeasurement: 'Όλες οι μετρήσεις',
        High: 'Συστολική',
        Low: 'Διαστολική',
        Date: 'Ημερομηνία',
        Time: 'Ώρα',
        Details: 'Λεπτομέρειες',
        Problem: 'Υπήρξε κάποιο πρόβλημα',
        CleanData: 'Καθαρισμός δεδομένων',
        Food: 'Φαγητό',
        DateTime: 'Ημερομηνία και ώρα',
        EditMeasurement: 'Αλλαγή μέτρησης',
        CancelEdit: 'Ακύρωση αλλαγής',
        RecordMeasurement: 'Καταχώρηση νέας μέτρησης',
        Fatigue: 'Κόπωση',
        Constipation: 'Δυσκοιλιότητα',
        Nausea: 'Ναυτία',
        Diarrhea: 'Διάρροια',
        Vomiting: 'Εμετός',
        Hyperglycaemia: 'Υπεργλυκαιμία',
        Hypoglycaemia: 'Υπογλυκαιμία',
        Migraine: 'Ημικρανία',
        Itching: 'Ένταση κνησμού',
        Headache: 'Επίπεδο πονοκέφαλου',
        Pain: 'Επίπεδο πόνου',
        Fever: 'Πυρετός',
        Dyspnea: 'Δύσπνοια',
        Heartburn: 'Καούρες',
        MoodSwings: 'Εναλλαγές διάθεσης',
        FrequentUrination: 'Συχνή ούρηση',
        Comments: 'Σχόλια',
        Running: 'Τρέξιμο',
        Swimming: 'Κολύμβηση',
        Walking: 'Περπάτημα',
        Gym: 'Γυμναστήριο',
        Dancing: 'Χορός',
        ActivityTime: 'Χρόνος άσκησης',
        Distance: 'Απόσταση',
        FitnessGrade: 'Βαθμολογία δραστηριότητας:',
        FitnessBegun: 'που ξεκινήσατε την άσκηση',
        FitnessEnded: 'που τελειώσατε την άσκηση',
        FitnessInten: 'Ένταση άσκησης',
        Minutes: 'λεπτά',
        Seconds: 'Δευτερόλεπτα',
        RowsText: 'Σειρές ανα σελίδα',
        SymptomFace: 'Καταχώρηση και παρακολούθηση συμπτωμάτων',
        Health: 'Ευεξία',
        MeasurementTable: 'Πινακας μετρήσεων',
        FeedbackReg: 'Επιτυχής καταχώρηση',
        QPhysically: 'Πόσο υγιής και δυνατός νιώθεις σήμερα;',
        QEmotionally: 'Πώς είναι η διάθεσή σου;',
        FirstQuestion: 'Σωματική βαθμολογία',
        SecondQuestion: 'Συναισθηματική βαθμολογία',
        HealthScore: 'Health Score',
        UpcomingDates: 'Επόμενες αρχές κύκλου',
        Add: 'Προσθήκη',
        Edit: 'Επεξεργασία',
        Average: 'Μέσος όρος',
        Journal: 'Ήμερολόγιο',
        Folder: 'Φάκελος',
        ShortHistory: 'Συνοπτικό ιστορικό',
        HealthProfile: 'Προφίλ υγείας',
        Preventive: 'Προληπτικές εξετάσεις',
        More: 'Περισσότερα',
        Cycle: 'Έμμηνος κύκλος',
        Diabetes: 'Διαβήτης',
        Insulin: 'Ινσουλίνη',
        Placeholder: 'Τιμή μέτρησης',
        TimeOF: 'Χρονική στιγμή',
        Questions: 'Οι Ερωτήσεις της ημέρας',
        Or: 'ή',
        Create: 'Δημιουργία λογαριασμού',
        Forgot: 'Ξέχασες τον κωδικό σου;',
        CreateAccount: 'ΔΗΜΙΟΥΡΓΙΑ ΛΟΓΑΡΙΑΣΜΟΥ',
        Sex: 'Φύλο',
        Register: 'Εγγραφή',
        ReUsername: 'Απαιτούμενο όνομα χρήστη',
        ReUsername2: 'Το όνομα χρήστη πρέπει να έχει τουλάχιστον 3 χαρακτήρες',
        RePassword: 'Απαιτείται κωδικός',
        RePassword2: 'Ο Κωδικός πρέπει να έχει τουλάχιστον 8 χαρακτήρες',
        RePassword3: 'Δεν είναι το ίδιο με τον παραπάνω κωδικό πρόσβασης',
        ReName: 'Απαιτείται όνομα',
        ReName2: 'Το όνομα πρέπει να έχει τουλάχιστον 2 χαρακτήρες',
        ReSurname: 'Απαιτείται επώνυμο',
        ReSurname2: 'Το επώνυμο πρέπει να έχει τουλάχιστον 3 χαρακτήρες',
        ReEmail: 'Απαιτείται email',
        ReEmail2: 'Μη έγκυρη διεύθυνση email',
        ReSex: 'Πρέπει να επιλέξεις φύλο',
        ReBirthDate: 'Πρέπει να βάλεις ημερομηνία γέννησης',
        ReTerms: 'Πρέπει να αποδεχτείς τους όρους χρήσης για να συνεχίσεις',
        Temperature: 'Θερμοκρασία',
        Humidity: 'Υγρασία',
        Air: 'Ποιότητα αέρα',
        Profile: 'Προφίλ',
        Protection: 'Δεδομένα και προστασία',
        Management: 'Διαχείριση λογαριασμού',
        DeleteAccount: 'Διαγραφή λογαριασμού',
        Failed: 'Απέτυχε',
        EmailSent: 'Το email απεστάλει',
        EmailNotFound: 'Δεν βρέθηκε λογαριασμός με αυτό το email',
        Room: 'Συνθήκες δωματίου',
        Declared: 'Προστέθηκε στις',
        Greek: 'Ελληνικά',
        English: 'Αγγλικά',
        Surname: 'Επώνυμο',
        Name: 'Όνομα',
        EmergencyNumber: 'Τηλέφωνο εκτάκτου ανάγκης',
        EmergencyName: 'Όνομα επαφής έκτακτης ανάγκης',
        BirthDate: 'Ημερομηνία γέννησης',
        BasicInfo: 'Βασικές πληροφορίες',
        ChangePassword: 'Αλλαγή κωδικού',
        EmergencyTip:
          'Τα στοιχεία του προσώπου που θέλεις να ενημερωθεί εάν υπάρχει κάποια έκτακτη ανάγκη.',
        RepeatPassword: 'Επανάληψη κωδικού πρόσβασης',
        SearchTo: 'Αναζήτηση μέχρι...',
        SearchFrom: 'Αναζήτηση από...',
        ExamCenter: 'Εξεταστικό κέντρο',
        ExamCategory: 'Κατηγορία εξέτασης',
        ExamDeleted: 'Επιτυχής διαγραφή',
        ResetFilter: 'Επαναφορά φίλτρου',
        UploadExam: 'Υποβολή',
        ChooseFile: 'Επιλογή αρχείου',
        SizeLimit10: 'Το αρχείο πρέπει να είναι μικρότερο από 10mb',
        Duration: 'Διάρκεια',
        Days: 'Ημέρες',
        Hours: 'Ώρες',
        Color: 'Χρώμα',
        NoFilter: 'Δεν υπάρχουν τιμές που να ικανοποιούν τα κριτήρια',
        NoData:
          'Δεν υπάρχουν ακόμα στοιχεία, ξεκίνησε καταχωρώντας καινούργια στοιχεία',
        NoteAdded: 'Η σημείωση προστέθηκε',
        RequestPasswordReset: 'Αίτημα επαναφοράς κωδικού πρόσβασης',
        SendRequest: 'Αποστολή αιτήματος',
        AddSymptom: 'Προσθήκη νέου συμπτώματος',
        TimetableTitle: 'Επιλογή σύνθετης δοσολογίας και συχνότητας',
        SignIn: 'Σύνδεση',
        Dosages: 'δόσεις',
        Dosage: 'Δόση',
        DetailedDosages: 'Αναλυτικές δόσεις',
        Other: 'Άλλο',
        Before: 'Πριν το φαγητό',
        After: 'Μετά το φαγητό',
        TheFood: 'το Φαγητό',
        Meters: 'χλμ',
        StopMedication: 'Διακοπή αγωγής',
        Yes: 'Ναί',
        No: 'Όχι',
        Hi: 'Γειά σου',
        MoreInfo: 'Δεν υπάρχουν ακόμα αρκετά στοιχεία',
        Smoker: 'Καπνιστής',
        NoSmoker: 'Μη καπνιστής',
        NoListed: 'Δεν υπάρχουν καταγεγραμμένες ',
        Allergies: 'Αλλεργίες',
        Aller: 'Αλλεργιών',
        allergi: 'αλλεργίες',
        ChronicDisease: 'Χρόνιων παθήσεων',
        chronicdis: 'χρόνιες παθήσεις',
        Chronic: 'Χρόνιες παθήσεις',
        Diseases: 'Παθήσεις',
        PQuestion1: 'Πότε ήταν η τελευταία σου περίοδος;',
        PQuestion2: 'Μέση διάρκεια κύκλου',
        PQuestion3: 'Μέση διάρκεια περιόδου',
        PAsk1: 'Καταχώρηση κύκλου περιόδου',
        PAsk2: 'Επεξεργασία στοιχείων περιόδου',
        CycleSubmit: 'Καταχώρηση κύκλου',
        SaveChanges: 'Αποθήκευση αλλαγών',
        General: 'Γενικές πληροφορίες',
        YourCycle: 'Ο Έμμηνος κύκλος σου',
        CycleMonitor: 'Παρακολούθηση του κύκλου σου',
        CycleDay: 'Ημέρα κύκλου',
        PeriodDays: 'Ημέρες για την επόμενη περίοδο',
        CycleDuration: 'Διάρκεια κύκλου',
        PeriodDuration: 'Διάρκεια περιόδου',
        CycleMonths1: 'Προηγούμενος μήνας',
        CycleMonths2: 'Επόμενος μήνας',
        CycleHistory: 'Ιστορικό περιόδου',
        CycleSure: 'Θέλεις να τροποποιήσεις τον κύκλο;',
        CycleDescr: 'Σίγουρα θέλεις να τροποποιήσεις αυτο τον Κύκλο;',
        CycleUpdated: 'Η περίοδος σου ενημερώθηκε',
        Female: 'Γυναίκα',
        Male: 'Άνδρας',
        Calendar: ' Ημερολόγιο',
        Nomed: 'Δεν υπάρχει καταχωρημένη κάποια τρέχουσα φαρμακευτική αγωγή',
        AddMeasurement: ' Προσθήκη νέας μέτρησης',
        FreqAndDosage: 'Συχνότητα και δοσολογία',
        Quantity: 'Ποσότητα',
        FreqEvery: 'Συχνότητα, κάθε',
        TimetableLabel: 'Χρονοδιάγραμμα',
        UserInfo: 'Πληροφορίες χρήστη',
        TemporaryDiseases: 'Λοιπές παθήσεις',
        Welmed: 'Δεν υπάρχει καταχωρημένη κάποια ενεργή φαρμακευτική αγωγή',
        Proi: 'Πρωί',
        Mesimeri: 'Μεσημέρι',
        Vrady: 'Βράδυ',
        All: 'Όλες',
        Week: 'Εβδομάδες',
        Month: 'Μήνες',
        Year: 'Χρόνια',
        Rest: 'Λοιπές παθήσεις',
        Note: 'Σημειώσεις',
        ExamName: 'Όνομα εξέτασης',
        Description: 'Περιγραφή εξέτασης',
        Treximo: 'Τρέξιμο',
        Perpatima: 'Περπάτημα',
        SumDuration: 'Συνολική διάρκεια',
        SumDistance: 'Συνολική απόσταση',
        AvgDistperday: 'Μέση απόσταση',
        AvgDirperday: 'Μέση διάρκεια',
        CheckBox: 'Πρέπει να επιλέξετε το κουτί',
        SkipNoSave: 'Συνέχεια χωρίς αποθήκευση',
        Register2Added: 'Επιτυχημένη εγγραφή',
        Welcome: 'Καλώς ήρθες στο Healthier!',
        Save: 'Αποθήκευση',
        WelcomeMeasurements: 'Δεν έχεις καταχωρίσει ακόμα κάποια μέτρηση',
        Weight: 'Βάρος',
        Height: 'Ύψος',
        PlaceNote: 'Άλλες σημαντικές πληροφορίες',
        Reg: 'Επιβεβαιώνω ότι συμφωνώ με τους ',
        Terms: 'Όρους χρήσης ',
        TermsPolicy: 'Όροι χρήσης και Πολιτική απορρήτου',
        FitnessReg: 'Καταχώρηση νέας προπόνησης',
        FitnessRegEdit: 'Αλλαγή προπόνησης',
        MustHigher: ' πρέπει να είναι μεγαλύτερη απο την διαστολική',
        MustSooner: ' πρέπει να είναι νωρίτερα από την ',
        Policy: 'Πολιτική απορρήτου',
        Rmu: 'και να λαμβάνω μηνύματα και ενημερώσεις',
        SaveCont: 'Αποθήκευση και είσοδος',
        Epil: ' Επιλογή',
        Smoking: 'Είσαι καπνιστής;',
        BloodType: 'Ομάδα αίματος',
        FitStat: 'Στατιστικά',
        OtherDiseases: 'Δεν υπάρχουν άλλες καταγεγραμμένες παθήσεις',
        Change: 'Αλλαγή',
        ChangeGraph1: 'Πίνακας δεδομένων',
        ChangeGraph2: 'Γράφημα',
        Hypoglucemia: 'Υπογλυκαιμία',
        Hyperglucemia: 'Υπεργλυκαιμία',
        Type: 'Τύπος',
        ChooseInsulinType: 'Διαλέξτε τύπο ινσουλίνης',
        PrePeriod: 'Πριν την περίοδο',
        PeriodDay: 'Ημέρες περιόδου',
        PostPeriod: 'Μετά την περίοδο',
        PeakOvulation: 'Ωορηξία',
        DuplicateUser: 'Υπάρχει ήδη καταχωρημένος λογαριασμός με αυτο το email',
        AutoC: 'Αυτόματος υπολογισμός',
        Cancel: 'Ακύρωση',
        EditExam: 'Τροποποίηση εξέτασης',
        AddedPreventive:
          'Η προληπτική εξέταση προστέθηκε σε αυτές που έχουν γίνει',
        DeletedPreventive:
          'Η προληπτική εξέταση αφαιρέθηκε από αυτές που έχουν γίνει',
        RememberMe: 'Να παραμένω συνδεδεμένος',
        NoOptions: 'Κανένα αποτέλεσμα',
        Undo: 'Αναίρεση',
        Deleting: 'Διαγραφή',
        km: 'χλμ',
        MoreDetails: 'Περισσότερες πληροφορίες',
        Fit: 'Άσκηση',
        Welfit: 'Δεν έχεις ακόμα καταχωρήσει κάποια προπόνηση',
        FitnessType: 'Είδος άσκησης',
        MedicationAdded: 'Η φαρμακευτική αγωγή προστέθηκε επιτυχώς',
        MedicationStopped: 'Η φαρμακευτική αγωγή διακόπηκε ',
        cm: 'εκ',
        CycleAdded: 'Η περίοδος προστέθηκε',
        ExamsTitle: 'Ιστορικό εξετάσεων',
        ExamsText:
          'Καταχώρηση, επεξεργασία και αναζήτηση των ιατρικών σου εξετάσεων',
        YourComment: 'Το σχόλιό σου',
        Diagram: 'Διάγραμμα τιμών',
        DiagramT: 'Πίνακας τιμών',
        InsulinAdd: 'Καταχώρηση νέας δόσης',
        InsulinEdit: 'Αλλαγή δόσης',
        Shape: 'Σχήμα',
        Score: 'Η απάντησή σου',
        CycleError: 'Πρέπει να είναι πάνω από 14 ημέρες',
        Changed: 'Επιτυχής αλλαγή',
        reportBug: 'Αναφορά σφάλματος',
        From: 'Από...',
        To: 'Μέχρι...',
        Success: 'Επιτυχής εγγραφή',
        Error: 'Μη επιτυχής εγγραφή',
        OtherEvents: 'Λοιπά γεγονότα',
        LoginFail: 'Αποτυχία σύνδεσης',
        GeneralSuccess: 'Επιτυχής καταχώρηση',
        GoogleEntry: 'Αποθήκευση και συνέχεια',
        PeriodError:
          'Η ημερομηνία τέλους πρέπει να είναι μετά την ημερομηνία έναρξης',
        CycleDeleted: 'Ο κύκλος σου διαγράφηκε',
        Added: 'Επιτυχής προσθήκη',
        Today: 'Σήμερα',
        Settings: 'Ρυθμίσεις',
        Update: 'Τροποποίηση',
        Updated: 'Τροποποίηση επιτυχής',
        InsulinType: 'Τύπος ινσουλίνης',
        GoToCalendar:
          'Πρόσθεσε λοιπές σημειώσεις και συμπτώματα στο ημερολόγιο',
        Hematochezia: 'Αίμα στις κενώσεις',
        Cramps: 'Κράμπες',
        Ageusia: 'Αγευσία',
        Anosmia: 'Ανοσμία',
        LastMeasurement: 'Οι τελευταίες μετρήσεις σου',
        Preventivetitle: 'Ολοκλήρωση εξέτασης',
        EditFailed: 'Υπήρξε λάθος με την επεξεργασία',
        SearchMeasurements: 'Αναζήτηση όλων των μετρήσεων από λίστα',
        Covid1: 'Διαχείριση ασθένειας',
        Covid2: 'Έχω διαγνωστεί με τον ιό',
        Covid3: 'Είναι πιθανό να πάσχω απο τον ιό αλλά δεν έχω διαγνωσθεί',
        Covid4:
          'Έχω έρθει σε επαφή με επιβεβαιωμένο κρούσμα τις τελευταίες 14 ημέρες',
        Covid5: 'Διαχείριση καραντίνας/περιορισμού μετακινήσεων',
        Covid6: 'Έχω τεθεί σε υποχρεωτική καραντίνα 14 ημερών',
        Covid7: 'Έχω τεθεί σε περιορισμό μετακίνησης',
        Covid8: 'Ημέρες που απομένουν:',
        Covid9: 'Έχεις αθληθεί ',
        Covid10: ' από ',
        Covid11: ' ημέρες του περιορισμού',
        Covid12: 'Διαχείριση συμπτωμάτων',
        Covid13: 'Με βάση το προφίλ σου ανήκεις σε ομάδα υψηλού κινδύνου',
        Covid14: 'Εχω κάνει την πρώτη δόση του εμβολίου',
        Covid15: 'Εχω κάνει την δεύτερη δόση του εμβολίου',
        Covid16: 'Εχω κάνει την τρίτη δόση του εμβολίου',
        Covid17: 'Εχω κάνει την τέταρτη δόση του εμβολίου',
        SoreThroat: 'Πονόλαιμος',
        Cough: 'Βήχας',
        Snuffles: 'Καταρροή',
        Dyspepsia: 'Δυσπεψία',
        OtherSymptoms: 'Λοιπά συμπτώματα',
        AboutUs: 'Σχετικά με εμάς',
        VisitUsAt: 'Επισκευτείτε μας στο',
        FindTerms: 'Δείτε του όρους χρήσης',
        Here: 'εδώ',
        CompanySite: 'εταιρικό site',
        LastSymptom: 'Η τελευταία φορά που εμφάνισες αυτό το σύμπτωμα',
        SeeMore: 'Δες περισσότερα',
        Tutorial: 'Οδηγίες χρήσης',
        ExitMess:
          'Εάν διακόψεις τον οδηγό χρήσης μπορείς αργότερα να τον ξεκινήσεις εκ νέου απο το προφίλ σου',
        Tut1: 'Καλώς ήρθες στο Healthier!',
        Tut2: 'Προκειμένου να χρησιμοποιήσεις πιο εύκολα την εφαρμογή, έχουμε ετοιμάσει ένα σύντομο οδηγό χρήσης',
        Tut3: 'Συνέχεια',
        Tut4: 'Κλείσιμο οδηγού',
        Exit: 'Έξοδος',
        Continue: 'Συνέχεια',
        NextStep: 'Πήγαινε στο επόμενο βήμα',
        Tut5: 'Όλες οι λειτουργίες είναι χωρισμένες σε 4 κατηγορίες όπως φαίνεται στο αριστερό μενού',
        Tut6: 'Οι επιμέρους λειτουργίες φαίνονται και στο πάνω μέρος της οθόνης για γρήγορη μετάβαση',
        Tut7: 'Από την ενότητα "Καθημερινά" στο πλαϊνό μενού μπορείς να πλοηγηθείς στη διαχείριση φαρμακευτικής αγωγής, στις μετρήσεις υγείας, στη διαχείριση συμπτωμάτων και στο ημερολόγιο',
        Tut10:
          'Στην κατηγορία αυτή μπορείς να δεις μια συνοπτική καρτέλα με τις κύριες πληροφορίες σχετικά με την υγεία σου, να ανεβάσεις αρχεία εξετάσεων και να συμπληρώσεις το προφίλ υγείας σου με τυχόν ασθένειες ή αλλεργίες',
        Tut12:
          'Στην κατηγορία αυτή συμπεριλαμβάνονται λειτουργίες για την άσκηση, προληπτικές εξετάσεις ανάλογα με το προφίλ σου και ο προσωπικός σου δείκτης υγείας και ευεξίας Health Score',
        Tut15:
          'Τέλος, σε αυτή την κατηγορία θα βρεις άλλες χρήσιμες λειτουργίες για την αποτελεσματική διαχείριση της υγείας σου',
        Tut16:
          'Σε αυτή την οθόνη μπορείς να βρεις όλες τις μετρήσεις υγείας σου και τα αποτελέσματα των εξετάσεών σου',
        Tut17:
          ' Γράφοντας στο πεδίο αναζήτησης θα εντοπίσεις εύκολα τη μέτρηση που θες. Για εύκολη πρόσβαση στις πιο συνηθισμένες μετρήσεις μπορείς να χρησιμοποιήσεις και τα αντίστοιχα κουμπια.',
        Tut18:
          'Χρησιμοποιώντας τη φόρμα στα δεξιά μπορείς να προσθέσεις τιμή και ημερομηνία μέτρησης',
        Tut20: 'Πατώντας την ημέρα επιλέγεις ημερομηνία',
        Tut21: 'Πατώντας το έτος επιλέγεις χρονολογία',
        Tut22: 'Πατώντας την ώρα επιλέγεις ώρα, λεπτά και πμ ή μμ',
        Tut23:
          'Σε αυτή την οθόνη μπορείς να προσθέσεις και να διαχειριστείς τη φαρμακευτική σου αγωγή',
        Tut25:
          'Συμπλήρωσε εύκολα όλα τα στοιχεία της αγωγής σου. Εάν το πρόγραμμα της αγωγής σου είναι πιο σύνθετο χρησιμοποίησε την επιλογή "Χρονοδιάγραμμα"',
        Tut26: 'Εδώ μπορείς να ανεβάσεις τις εξετάσεις σου',
        Tut27:
          'Σύτομα θα μπορείς εάν ανεβάσεις ένα αρχείο PDF θα μπορείς, επίσης, να βρεις τα επιμέρους αποτελέσματα και στη λειτουργία "Μετρήσεις Υγείας"',
        Tut29: 'Επέλεξε το αρχείο που θες να ανεβάσεις από τη συσκευή σου',
        Tut30:
          'Ο δείκτης Health Score σου δίνει άμεση εικόνα για το σύνολο της υγείας σου και το πόσο αποτελεσματικά τη διαχειρίζεσαι.',
        Tut32:
          'Στο προφίλ υγείας μπορείς να διαχειριστείς παθήσεις και αλλεργίες',
        Tut33:
          'Με αυτή την καρτέλα μπορείς άμεσα να δείξεις όλα τα βασικά στοιχεία του ιατρικού σου προφίλ σε κάποιον τρίτο όποτε χρειαστεί',
        Tut34:
          'Η λίστα με τις απαραίτητες προληπτικές εξετάσεις ανανεώνεται αυτόματα με βάση το προφίλ σου',
        Tut36:
          'Στην αρχική σελίδα θα βρεις μια σύνοψη από τα πιο σημαντικά νέα για σήμερα και ένα μήνυμα προσαρμοσμένο στις ανάγκες σου.',
        TutEnd: 'Επιτυχής ολοκλήρωση του οδηγού χρήσης',
        TutSure: 'Θέλεις να κλείσεις τον οδηγό χρήσης;',
        TutSure1:
          'Σίγουρα θέλεις να κλείσεις τον οδηγό χρήσης μας; Αργότερα θα μπορέσεις να τον ξεκινήσεις εκ νέου απο το προφιλ σου.',
        TutSure2: 'Θέλεις να αρχίσεις τον οδηγό χρήσης;',
        TutSure3:
          'Θέλεις να δεις τον οδηγό χρήσης του Healthier; Θα μεταφερθείς στην αρχική σελίδα για να ξεκινήσει η διαδικασία.',
        bpm: 'Καρδιακοί Παλμοί',
        DeleteAc: 'Διαγραφή λογαριασμού',
        DeleteAc1:
          'Θέλεις σίγουρα να σβήσεις τον λογαριασμό σου; Αυτή η ενέργεια είναι μη αναστρέψιμη.',
        And: 'και',
        MailList: 'Δέχομαι να λαμβάνω ενημερωτικά email',
        PasswordChanged: 'Επιτυχής αλλαγή κωδικού',
        TokenExpired: 'Ο χρόνος αλλαγής έληξε',
        ExamHistory: 'Ιστορικό Εξετάσεων',
        CenterType: 'Τύπος κέντρου',
        Region: 'Περιοχή',
        Hospital: 'Νοσοκομείο',
        Intro1:
          'Το healthier είναι ο ολοκληρωμένος προσωπικός σου βοηθός διαχείρισης υγείας. Οργάνωσε το ιατρικό σου ιστορικό και τη φαρμακευτική σου αγωγή εύκολα και αποτελεσματικά.',
        Intro2: 'Ολοκληρωμένη',
        Intro3: 'διαχείριση υγείας',
        Intro4: 'Οργάνωσε',
        Intro5: 'κάθε πτυχή της υγείας σου',
        Intro6:
          'Στο healthier θα βρεις λειτουργίες σχετικές με κάθε πτυχή της υγείας σου είτε έχεις κάποια πάθηση είτε θες να βελτιώσεις την υγεία και ευεξία σου και να προλάβεις πιθανά προβλήματα υγείας.',
        FitnessComment: 'Με περιγραφή: ',
        HealthierScore:
          'To Healthier Score είναι ενας δείκτης που υπολογίζει όλες τις παραμέτρους της υγείας σου προκειμένου να σου δώσει μια συνολική εικόνα της υγείας σου και του πόσο καλά τη διαχειρίζεσαι!',
        Optional1: 'Σακχαρώδης διαβήτης τύπου 2',
        Optional2: 'Καρδιακή ανεπάρκεια',
        Optional3: 'Υπέρταση',
        Optional4: 'Χρόνια νεφροπάθεια',
        Optional5: 'Κολπική μαρμαριγή',
        Optional6: 'Καρδιαγγειακά νοσήματα',
        NewPassword: 'Καινούργιος κωδικός πρόσβασης',
        About1:
          'Το Healthier αποτελεί έναν έξυπνο, φορητό, προσώπικο βοηθό διαχείρισης υγείας που προσαρμόζεται στις ανάγκες σου και παρέχει στοχευμένες οδηγίες και χρήσιμα δεδομένα ώστε να διαχειρίζεσαι αποτελεσματικά κάθε πτυχή της υγείας σου.',
        About2: 'Καλώς ήρθες σε μια πιο υγιή ζωή!',
        About3: 'Κατασκευαστής: TechApps Healthier',
        About4: 'Έκδοση εφαρμογής: 1.2',
        About5: 'Επικοινωνία: info@healthier-app.com',
        MoreHistory: 'Αναλυτικά στοιχεία ιστορικού',
        FamilyHistory: 'Οικογενειακό ιστορικό',
        PreAsk1: 'Καταχώρηση εγκυμοσύνης',
        PreAsk2: 'Επεξεργασία στοιχείων εγκυμοσύνης',
        Pregnancy: 'Εγκυμοσύνη',
        NewPregnancy: 'Νέα εγκυμοσύνη',
        PregnancyAdded: 'Η εγκυμοσύνη προστέθηκε',
        PregnancyEdited: 'Η εγκυμοσύνη τροποποιήθηκε',
        PregnancyInfo1: 'Πληροφορίες για το έμβρυο',
        PregnancyInfo2: 'Πληροφορίες για την μητέρα',
        PregnancyInfo3: 'Περισσότερες πληροφορίες',
        PregnancyHistory: 'Προηγούμενες κυήσεις',
        PregnancyBirthDate: 'Ημερομηνία γέννησης παιδιού',
        KidAdded: 'Το παιδί προστέθηκε',
        KidBorn: 'Γέννηση παιδιού',
        KidBornSuccess: 'Να σας ζήσει!',
        DeliveryDate: 'Ημερομηνία ολοκλήρωσης',
        PregnancyExams: 'Αρχείο εξετάσεων',
        PregnancyPdfQ: 'Καταχώρηση εξέτασης',
        PregnancyPdfQ1: 'Ολοκλήρωσες την εξέταση ',
        PregnancyPdfQ2:
          'Θέλεις να προσθέσεις και κάποιο αρχείο των εξετάσεών σου;',
        PregnancyQ: 'Ποιό είναι το βάρος σου',
        PregnancyPdfD1: 'Διαγραφή εξέτασης εγκυμοσύνης',
        PregnancyPdfD2:
          'Αυτή η ενέργεια θα σβήσει αυτή την εξέταση απο τις εξετάσεις εγκυμοσύνης που έχεις πραγματοποιήσει. Θέλεις να συνεχίσεις;',
        PregnancyPdfD3: 'Διαγραφή συνημμένου αρχείου',
        PregnancyPdfD4:
          'Αύτη η ενέργεια θα σβήσει το συνημμένο αρχείο της εξέτασης που μόλις σβήσατε απο της εξετάσεις εγκυμοσύνης που έχεις πραγματοποιήσει, θέλεις να συνεχίσεις;',
        QWeight: 'Πόσο είναι το βάρος σου',
        QHeight: 'Πόσο είναι το ύψος σου',
        Under: 'Κάτω όριο',
        Over: 'Πάνω όριο',
        Sigenia: 'Συγγένεια',
        Asthenia: 'Κληρονομική Ασθένεια',
        Mother: 'Μητέρα',
        Father: 'Πατέρας',
        Sibling: 'Αδέρφια',
        Grandpa: 'Παππούδες και γιαγιάδες',
        Addictions: 'Εξαρτήσεις και εθισμοί',
        Category: 'Κατηγορία',
        Uselevel: 'Επίπεδο χρήσης',
        Alcohol: 'Αλκοόλ',
        Drugs: 'Ναρκωτικές ουσίες',
        Smoke: 'Κάπνισμα',
        Kidi: 'Παιδικά νοσήματα',
        Sexi: 'Αφροδίσια νοσήματα',
        Vaccines: 'Εμβόλια',
        Hospitalization: 'Νοσηλείες και χειρουργεία',
        Surgery: 'Χειρουργείο',
        Hospitaly: 'Νοσηλεία',
        Chemotherapy: 'Χημειοθεραπεία',
        Dialysis: 'Αιμοκάθαρση',
        FormerSit: 'Λοιπές παλαιοτερες παθήσεις',
        BodySystems: 'Καταγραφή λεπτομερειών ανά σύστημα',
        BodySystem: 'Συστήματα',
        Alcohol0: 'Μηδενική/Χαμηλή κατανάλωση',
        Alcohol1: 'Περιστασιακή κατανάλωση',
        Alcohol2: 'Τακτική κατανάλωση',
        Alcohol3: 'Καθημερινή καταναλωση',
        Alcohol4: 'Υψηλή κατανάλωση',
        Drugs0: 'Μη χρήση',
        Drugs1: 'Περιστασιακή χρήση',
        Drugs2: 'Τακτική χρήση',
        Drugs3: 'Καθημερινή χρήση',
        Smoke0: 'Μη καπνιστής',
        Smoke1: 'Σπάνιο κάπνισμα',
        Smoke2: 'Περιστασιακό κάπνισμα',
        Smoke3: 'Τακτικό κάπνισμα',
        Smoke4: 'Υψηλό κάπνισμα',
        Smoke5: '1-3 τσιγάρα την ημέρα',
        Smoke6: '4-8 τσιγάρα την ημέρα',
        Smoke7: '8-15 τσιγάρα την ημέρα',
        Smoke8: 'πάνω απο 15 τσιγάρα την ημέρα',
        IntakeDate: 'Ημερομηνία λήψης',
        ApDate: 'Ημερομηνία εμφάνισης',
        Vaanddiseases: 'Νοσήματα και εμβόλια',
        DiseaseQ: 'Ενεργή πάθηση',
        ChooseType: 'Επιλογή τύπου',
        ChooseSystem: 'Επιλογή συστήματος',
        Age: 'Ηλικία',
        Overweight: 'Σύμφωνα με το δείκτη μάζας σώματός σου είσαι',
        Overweight0: 'Κανονικό/ Υγιές βάρος',
        Overweight1: 'Ελαφρώς υπέρβαρος',
        Overweight2: 'Παχύσαρκος',
        Exercise: 'Πόσο συχνά ασκείσαι;',
        Exercise0: 'Σπάνια ή Ποτέ',
        Exercise1: 'Μερικές Φορές',
        Exercise2: 'Τακτικά',
        LastPressureHigh: 'Τελευταία μέτρηση συστολικής πίεσης',
        LastPressureLow: 'Τελευταία μέτρηση διαστολικής πίεσης',
        Diabetic: 'Είσαι διαβητικός;',
        Diabetic0: 'Μη διαβητικός',
        Diabetic1: 'Οριακός',
        Diabetic2: 'Διαβητικός',
        Atrial: 'Έχεις κολπική μαρμαρυγη ή τακτικές αρρυθμίες',
        Atrial0: 'Δεν έχω μαρμαρυγή/ αρρυθμίες',
        Atrial1: 'Δεν γνωρίζω',
        Atrial2: 'Ναι',
        StrokeHistory: 'Υπάρχει ιστορικό εγκεφαλικού στην οικογένειά σου;',
        StrokeHistory0: 'Όχι, δεν υπάρχει',
        StrokeHistory1: 'Δεν γνωρίζω',
        StrokeHistory2: 'Ναι',
        Smoking2: 'Είσαι καπνιστής;',
        SmokingYes: 'Τακτικός καπνιστής',
        SmokingNo: 'Μη καπνιστής',
        TryingQuit: 'Προσπαθώ να το κόψω',
        LastCholesterol: 'Τελευταία μέτρηση χοληστερόλης',
        SelfDiagnosis: 'Test αυτοδιάγνωσης',
        Test1: '10ετής Καρδιαγγειακός Κίνδυνος',
        Test2: 'Κίνδυνος εγκεφαλικού επεισοδίου',
        Test3: 'Κίνδυνος Οστεοπόρωσης',
        DoTakePressureMedication:
          'Λαμβάνεις αγωγή για την αρτηριακή σου πίεση;',
        HDL: 'HDL Χοληστερόλη',
        Systolic: 'Συστολική πίεση',
        Cholesterol: 'Ολική χοληστερόλη',
        Estrogen: 'Κάνεις ή έχεις κάνει χρήση οιστρογόνων;',
        Prior: 'Ναί',
        NoPrior: 'Όχι',
        Race: 'Ανήκεις στη λευκή φυλή;',
        Black: 'Μαύρος/η',
        RArthritis: 'Πάσχεις από ρευματοειδή αρθρίτιδα;',
        Present: 'Υπάρχει',
        Absent: 'Δεν υπάρχει',
        FractureHistory: 'Έχεις ιστορικό καταγμάτων;',
        NonTraumatic0: 'Κανένα μη τραυματικό κάταγμα',
        NonTraumatic1: '1 μη τραυματικό κάταγμα',
        NonTraumatic2: '2 μη τραυματικό κάταγμα',
        NonTraumatic3: '3 ή περισσότερα μη τραυματικά κατάγματα',
        Calculate: 'Υπολογισμός',
        StartDiagnosis: 'Έναρξη αυτοδιάγνωσης',
        RegisterSmoke: 'Καταχώρηση περιοδών καπνίσματος',
        Birth: 'Γέννηση',
        Abortion: 'Διακοπή κύησης',
        Miscarriage: 'Αποβολή',
        OtherPregnancy: 'Άλλος λόγος ολοκλήρωσης',
        PregnancyOptions: 'Διαχείριση εγκυμοσύνης',
        PregnancyReason: 'Λόγος ολοκλήρωσης',
        End: 'Ολοκλήρωση',
        PregnancyDelete: 'Διαγραφή εγκυμοσύνης',
        PregnancyBack1: 'Πίσω σε τρέχουσα κύηση',
        PregnancyBack2: 'Πίσω σε διαχείριση εγκυμοσύνης',
        LowRisk: 'χαμηλός ',
        ModerateRisk: 'σχετικά αυξημένος',
        HighRisk: 'υψηλός',
      },
    },
  },
  fallbackLng: 'el-gr',
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});
export default i18n;
