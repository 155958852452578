import {
  Box,
  makeStyles,
  Theme,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Paper,
} from '@material-ui/core';
import { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import logoBig from 'assets/icons/logo-big.png';
import { useLocation } from 'react-router-dom';
import { CreateBugReport, meControllerCreateBugReport } from 'gen/client';
import { Colors } from 'res/Enums';
const _AboutUs = () => {
  const classes = useStyles();
  const [reportDialog, setReportDialog] = useState(false);
  const [report, setReport] = useState<CreateBugReport>();
  const { t } = useTranslation();
  const location = useLocation();

  const createReport = () => {
    if (report) {
      meControllerCreateBugReport(report)
        .then(() => {
          setReportDialog(false);
          setReport(undefined);
        })
        .catch();
    }
  };

  return (
    <Box>
      <Box
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img
          style={{ width: '70%', maxWidth: 400, marginBottom: 15 }}
          alt=""
          src={logoBig}
        />
        <Paper style={{ maxWidth: 400, padding: 10 }}>
          <Typography style={{ color: Colors.FadeBlack, marginBottom: 20 }}>
            {t('About1')}
          </Typography>
          <Typography>{t('About2')}</Typography>
        </Paper>
        <Paper style={{ marginTop: 10, maxWidth: 400, padding: 10 }}>
          <Typography style={{ color: Colors.FadeBlack }}>
            {t('About3')}
          </Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http:///www.healthier-app.com"
          >
            www.healthier-app.com
          </a>
          <Typography style={{ color: Colors.FadeBlack }}>
            {t('About4')}
          </Typography>
          <Typography style={{ color: Colors.FadeBlack }}>
            {t('About5')}
          </Typography>
        </Paper>
      </Box>
      <Dialog open={reportDialog} onClose={() => setReportDialog(false)}>
        <DialogTitle>{t('reportBug')}</DialogTitle>
        <DialogContent>
          <TextField
            value={report?.text}
            onChange={(e) =>
              setReport({
                text: e.target.value.toString(),
                relatedPage: location.pathname,
              })
            }
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setReport({ text: '', relatedPage: '' });
              setReportDialog(false);
            }}
          >
            {t('Cancel')}
          </Button>
          <Button variant="outlined" color="primary" onClick={createReport}>
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({}));
export const AboutUs = hot(_AboutUs);
