import { Box, makeStyles, useMediaQuery, IconButton } from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Colors } from 'res/Enums';
import { Add, Remove } from '@material-ui/icons';
import theme from '../assets/theme';
interface StyledNumberPickerProps {
  value: number;
  unit?: string;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
}
const _StyledNumberPicker = ({
  value,
  unit,
  onChange,
  min,
  max,
}: StyledNumberPickerProps) => {
  const classes = useStyles();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const add = () => {
    if (max) {
      max > value && onChange(value + 1);
    } else {
      onChange(value + 1);
    }
  };
  const subtract = () => {
    if (min) {
      min < value && onChange(value - 1);
    } else {
      onChange(value - 1);
    }
  };
  return (
    <Box className={classes.container}>
      <IconButton
        color="secondary"
        onClick={subtract}
        style={{
          backgroundColor: Colors.PRIMARY,
          height: isBelowSm ? 20 : 30,
          width: isBelowSm ? 20 : 30,
        }}
      >
        <Remove fontSize={isBelowSm ? 'small' : 'default'} />
      </IconButton>
      <Box className={classes.inner}>
        <Box display="flex" flexGrow={2} justifyContent="center">
          {value}
        </Box>
        {unit && (
          <Box
            display="flex"
            flexGrow={3}
            justifyContent="flex-end"
            color={Colors.Gray}
            fontSize={isBelowSm ? 12 : 16}
          >
            {unit}
          </Box>
        )}
      </Box>
      <IconButton
        onClick={add}
        color="secondary"
        style={{
          backgroundColor: Colors.PRIMARY,
          height: isBelowSm ? 20 : 30,
          width: isBelowSm ? 20 : 30,
        }}
      >
        <Add fontSize={isBelowSm ? 'small' : 'default'} />
      </IconButton>
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
  },
  button: {
    height: 29,
    width: 29,
    borderRadius: '100%',
    backgroundColor: Colors.PRIMARY,
    color: Colors.IceWhite,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0 6px',
  },
  inner: {
    borderRadius: 4,
    border: '1px solid #5CA595',
    display: 'flex',
    flexGrow: 3,
    height: '100%',
    alignItems: 'center',
    padding: '0 6px',
    color: Colors.FadeBlack,
  },
}));
export const StyledNumberPicker = hot(_StyledNumberPicker);
