import {
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Theme,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  meControllerSetShortNote,
  useMeControllerGetShortNote,
  ShortNote,
} from 'gen/client';
const _ShortNoteField = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [note, setNote] = useState<ShortNote | null>(null);
  const { data: noteData } = useMeControllerGetShortNote();
  const addNote = () => {
    if (note) {
      meControllerSetShortNote(note)
        .then((resp) =>
          resp.data
            ? toast(t('NoteAdded'))
            : toast(t('AddFailed'), { containerId: 'deleted' })
        )
        .catch(() => toast(t('AddFailed'), { containerId: 'deleted' }));
    }
  };

  useEffect(() => {
    if (noteData) setNote(noteData.data);
  }, [noteData]);
  return (
    <Paper className={classes.wrap}>
      <IconButton
        size="small"
        style={{ float: 'right' }}
        color="primary"
        onClick={addNote}
        disabled={!note}
      >
        <Save />
      </IconButton>
      <TextField
        fullWidth
        multiline
        rows={6}
        placeholder={`${t('PlaceNote')}...`}
        value={note?.shortNote}
        onChange={(e) => setNote({ id: note?.id, shortNote: e.target.value })}
      />
    </Paper>
  );
};
export const ShortNoteField = hot(_ShortNoteField);
const useStyles = makeStyles((theme: Theme) => ({
  wrap: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));
