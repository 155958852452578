import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Colors } from 'res/Enums';

interface ImageButtonProps {
  text: string;
  imageLeft?: string;
  id: string;
  link: string;
}

export const ImageButton = withRouter(
  ({
    text,
    id,
    imageLeft,
    history,
    link
  }: ImageButtonProps & RouteComponentProps) => {
    return (
      <div
        onClick={() => history.push(`/${link}/${id}`)}
        style={{
          display: 'flex',
          color: '#000000',
          cursor: 'pointer',
          border: '1px solid rgba(0, 0, 0, 0.08)',
          boxShadow: '0px 2px 10px rgba(240, 102, 105, 0.04)',
          borderRadius: 6,
          alignItems: 'center',
          fontSize: 14,
          fontWeight: 'bold',
          backgroundColor: Colors.White,
          padding: '17px 0',
          flexWrap: 'wrap'
        }}
      >
        <img style={{ paddingRight: 20, paddingLeft: 15 }} src={imageLeft} />
        <Typography
          style={{
            flex: '1 1 auto',
            fontSize: 14
          }}
        >
          {text}
        </Typography>
        <ChevronRight />
      </div>
    );
  }
);
