import {
  Box,
  Button,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { Colors } from 'res/Enums';
import { authControllerReset } from 'gen/client';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const _ResetPassword = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const token = query.get('token');
  const [password, setPassword] = useState<{
    newPass: string;
    repeat: string;
  }>({ newPass: '', repeat: '' });
  const [newError, setNewError] = useState({
    error: false,
    message: '',
  });
  const [repeatError, setRepeatError] = useState({
    error: false,
    message: '',
  });
  const setNewPass = (pass: string) => {
    setPassword({ ...password, newPass: pass });
  };
  const setRepeatPass = (pass: string) => {
    setPassword({ ...password, repeat: pass });
  };
  const sendRequest = async () => {
    try {
      if (token) {
        const r = await authControllerReset({
          pass: password.newPass,
          repeat: password.repeat,
          token,
        });
        if (r.data.sent) {
          toast(t(r.data.message));
          history.push('/login');
        } else {
          toast(t(r.data.message), { containerId: 'deleted' });
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (password.newPass.length < 8 && password.newPass !== '') {
      setNewError({
        error: true,
        message: t('PasswordErrorValidation'),
      });
    } else {
      setNewError({ error: false, message: '' });
    }
    if (password.repeat !== password.newPass) {
      setRepeatError({ error: true, message: t('PasswordNotSame') });
    } else {
      setRepeatError({ error: false, message: '' });
    }
  }, [password]);

  return (
    <Box className={classes.root}>
      <Paper className={classes.wrapper}>
        <Typography align="center" variant="h5">
          {t('RequestPasswordReset')}
        </Typography>
        <TextField
          label={t('NewPassword')}
          type="password"
          value={password.newPass}
          onChange={(e) => setNewPass(e.target.value)}
          error={newError.error}
          helperText={newError.message}
        />
        <TextField
          label={t('RepeatPassword')}
          type="password"
          value={password.repeat}
          onChange={(e) => setRepeatPass(e.target.value)}
          error={repeatError.error}
          helperText={repeatError.message}
        />
        <Box textAlign="center">
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 10 }}
            disabled={
              repeatError.error ||
              newError.error ||
              password.newPass.length === 0 ||
              password.repeat.length === 0 ||
              token === null
            }
            onClick={sendRequest}
          >
            {t('SendRequest')}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    backgroundColor: Colors.IceWhite,
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '35%',
    minWidth: 200,
    padding: theme.spacing(2),
  },
}));
export const ResetPassword = hot(_ResetPassword);
