import {
  Box,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Tooltip,
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import {
  MedicalCenterDTO,
  MedicalCenterDTOType,
  PdfCategoryDTO,
} from 'gen/client';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { DateSelect } from './DateSelect';
import { Filter } from './Filter';
import {
  usePdfControllerGetMedicalCenters,
  usePdfControllerGetPdfCategories,
} from 'gen/client';
interface ActiveFilters {
  date: {
    from: { active: boolean; value: Moment };
    to: { active: boolean; value: Moment };
  };
  center: number;
  category: number;
  region: number | string;
  type: MedicalCenterDTOType | number;
}
interface ExamsFilterBarProps<T> {
  data: T[];
  onFilter: (data: T[]) => void;
}
const initActive = {
  center: 0,
  category: 0,
  region: 0,
  type: 0,
  date: {
    from: { active: false, value: moment() },
    to: { active: false, value: moment() },
  },
};
const _ExamsFilterBar = <T extends { [key: string]: any }>({
  data,
  onFilter,
}: ExamsFilterBarProps<T>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [centers, setCenters] = useState<MedicalCenterDTO[]>([]);
  const [examCategories, setExamCategories] = useState<PdfCategoryDTO[]>([]);
  const [activeFilters, setActiveFilters] = useState<ActiveFilters>(initActive);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [unique, setUnique] = useState<any[]>([]);
  const { data: medicalCenterData } = usePdfControllerGetMedicalCenters();
  const { data: pdfCategoriesData } = usePdfControllerGetPdfCategories();

  useEffect(() => {
    if (medicalCenterData && pdfCategoriesData) {
      setCenters(medicalCenterData.data);
      setExamCategories(pdfCategoriesData.data);
    }
  }, [medicalCenterData, pdfCategoriesData]);
  const callFilters = () => {
    let filters = new Filter<T>(data, 'date');
    filters =
      activeFilters.category !== 0
        ? filters.byExamCategory(activeFilters.category)
        : filters;

    filters =
      activeFilters.center !== 0
        ? filters.byMedicalCenter(activeFilters.center)
        : filters;
    filters =
      activeFilters.region !== 0 && typeof activeFilters.region !== 'number'
        ? filters.byRegion(activeFilters.region)
        : filters;
    filters =
      activeFilters.type !== 0 && typeof activeFilters.type !== 'number'
        ? filters.byMedicalType(activeFilters.type)
        : filters;

    filters = activeFilters.date.from.active
      ? filters.isSameAfter(activeFilters.date.from.value)
      : filters;
    filters = activeFilters.date.to.active
      ? filters.isSameBefore(activeFilters.date.to.value)
      : filters;

    onFilter(filters.get());
  };
  useEffect(() => {
    callFilters();
  }, [activeFilters]);

  useEffect(() => {
    if (centers.length > 0) {
      const unique = [...new Set(centers.map((item) => item.region))];
      setTimeout(() => {
        setUnique(unique);
      }, 200);
    }
  }, [centers]);

  useEffect(() => {
    if (
      activeFilters.region === t('Other') &&
      activeFilters.type === MedicalCenterDTOType.MEDICALCENTER
    )
      setActiveFilters({ ...activeFilters, center: 189 });
    else if (
      activeFilters.region === t('Other') &&
      activeFilters.type === MedicalCenterDTOType.HOSPITAL
    )
      setActiveFilters({ ...activeFilters, center: 1095 });
    else setActiveFilters({ ...activeFilters, center: 0 });
  }, [activeFilters.region]);

  useEffect(() => {
    setActiveFilters({ ...activeFilters, region: 0 });
  }, [activeFilters.type]);

  return (
    <Box className={classes.wrapper}>
      <DateSelect
        onSelect={(dates) =>
          setActiveFilters({ ...activeFilters, date: { ...dates } })
        }
        resetDates={activeFilters !== initActive ? undefined : true}
        temp={{
          from: {
            active: activeFilters.date.from.active,
            value: activeFilters.date.from.value,
          },
          to: {
            active: activeFilters.date.to.active,
            value: activeFilters.date.to.value,
          },
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: 'fit-content',
            }}
          >
            <Box
              className={classes.selectWrap}
              style={{ marginBottom: activeFilters.type !== 0 ? 2 : 0 }}
            >
              <Select
                placeholder={t('CenterType')}
                className={classes.selectComp}
                classes={{ root: classes.root }}
                value={activeFilters.type}
                style={
                  isBelowXs
                    ? { fontSize: 11, width: 132 }
                    : activeFilters.type !== 0
                    ? { width: 180, height: 18, fontSize: 12 }
                    : { width: 180, fontSize: 12 }
                }
                onChange={(e) =>
                  setActiveFilters({
                    ...activeFilters,
                    type: e.target.value as MedicalCenterDTOType,
                  })
                }
              >
                <MenuItem
                  value={0}
                  style={
                    isBelowXs
                      ? { fontSize: 12.8 }
                      : activeFilters.type !== 0
                      ? { fontSize: 12.8 }
                      : { fontSize: 12.8 }
                  }
                >
                  <Typography
                    style={{
                      fontSize: isBelowXs
                        ? 12.8
                        : activeFilters.type !== 0
                        ? 12.8
                        : 12.8,
                    }}
                  >
                    {t('CenterType')}
                  </Typography>
                </MenuItem>
                <MenuItem value={MedicalCenterDTOType.HOSPITAL}>
                  <Typography
                    style={{
                      fontSize: isBelowXs
                        ? 12.8
                        : activeFilters.type !== 0
                        ? 12.8
                        : 12.8,
                    }}
                  >
                    {t('Hospital')}
                  </Typography>
                </MenuItem>
                <MenuItem value={MedicalCenterDTOType.MEDICALCENTER}>
                  <Typography
                    style={{
                      fontSize: isBelowXs
                        ? 12.8
                        : activeFilters.type !== 0
                        ? 12.8
                        : 12.8,
                    }}
                  >
                    {t('ExamCenter')}
                  </Typography>
                </MenuItem>
              </Select>
            </Box>
            {activeFilters.type !== 0 && (
              <Box
                className={classes.selectWrap}
                style={{ marginBottom: activeFilters.region !== '' ? 2 : 0 }}
              >
                <Select
                  placeholder={t('Region')}
                  className={classes.selectComp}
                  classes={{ root: classes.root }}
                  value={activeFilters.region}
                  style={
                    isBelowXs
                      ? { fontSize: 11, width: 132 }
                      : activeFilters.region !== ''
                      ? {
                          width: 180,
                          height: 18,
                          marginBottom: 2,
                          fontSize: 12.8,
                        }
                      : { width: 180, height: 26, fontSize: 12.8 }
                  }
                  onChange={(e) =>
                    setActiveFilters({
                      ...activeFilters,
                      region: e.target.value as string,
                    })
                  }
                >
                  <MenuItem
                    value={0}
                    style={
                      isBelowXs
                        ? { fontSize: 12.8 }
                        : activeFilters.region !== ''
                        ? { fontSize: 12.8 }
                        : { fontSize: 12.8 }
                    }
                  >
                    <em>{t('Region')}</em>
                  </MenuItem>
                  {unique
                    .filter((cat) => cat !== null)
                    .map((cat, i) => (
                      <MenuItem key={i} value={cat}>
                        <Typography
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: isBelowXs
                              ? 12.8
                              : activeFilters.region !== ''
                              ? 12.8
                              : 12.8,
                          }}
                        >
                          {cat}
                        </Typography>
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            )}
            {activeFilters.region !== 0 && (
              <Box
                className={classes.selectWrap}
                style={isBelowXs ? { marginRight: 8 } : {}}
              >
                <Select
                  placeholder={t('ExamCenter')}
                  className={classes.selectComp}
                  classes={{ root: classes.root }}
                  value={activeFilters.center}
                  style={
                    isBelowXs
                      ? { fontSize: 11, width: 124 }
                      : activeFilters.center !== 0
                      ? {
                          width: 180,
                          height: 18,
                          marginBottom: 2,
                          fontSize: 12.8,
                        }
                      : { width: 180, height: 26, fontSize: 12.8 }
                  }
                  onChange={(e) =>
                    setActiveFilters({
                      ...activeFilters,
                      center: parseInt(e.target.value as string),
                    })
                  }
                >
                  <MenuItem
                    value={0}
                    style={
                      isBelowXs
                        ? { fontSize: 12.8 }
                        : activeFilters.center !== 0
                        ? { fontSize: 12.8 }
                        : { fontSize: 12.8 }
                    }
                  >
                    <em>
                      {t(
                        activeFilters.type ===
                          MedicalCenterDTOType.MEDICALCENTER
                          ? 'ExamCenter'
                          : 'Hospital'
                      )}
                    </em>
                  </MenuItem>
                  {centers
                    .filter(
                      (center) =>
                        (activeFilters.type !== 0
                          ? center.type === activeFilters.type ||
                            (activeFilters.type ===
                              MedicalCenterDTOType.MEDICALCENTER &&
                              center.id === 189) ||
                            (activeFilters.type ===
                              MedicalCenterDTOType.HOSPITAL &&
                              center.id === 1095)
                          : center) &&
                        (activeFilters.region === center.region ||
                          (activeFilters.type ===
                            MedicalCenterDTOType.MEDICALCENTER &&
                            center.id === 189) ||
                          (activeFilters.type ===
                            MedicalCenterDTOType.HOSPITAL &&
                            center.id === 1095))
                    )
                    // .filter(
                    //   (center) =>
                    //     (center.region === activeFilters.region &&
                    //       center.type === activeFilters.type) ||
                    //     center.id === 189 ||
                    //     center.id === 1095
                    // )
                    // .filter((item) =>
                    //   activeFilters.region === t('Other') &&
                    //   activeFilters.type === MedicalCenterDTOType.MEDICALCENTER
                    //     ? item.id === 189
                    //     : activeFilters.region === t('Other') &&
                    //       activeFilters.type === MedicalCenterDTOType.HOSPITAL
                    //     ? item.id === 1095
                    //     : item
                    // )
                    .map((center, i) => (
                      <MenuItem key={i} value={center.id}>
                        <Typography
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: isBelowXs
                              ? 12.8
                              : activeFilters.region !== ''
                              ? 12.8
                              : 12.8,
                          }}
                        >
                          {center.name} {center.city && ' - '}
                          {center.city}
                        </Typography>
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            )}
          </Box>
          <Box className={classes.selectWrap}>
            <Select
              placeholder={t('ExamCategory')}
              className={classes.selectComp}
              classes={{ root: classes.root }}
              value={activeFilters.category}
              style={
                isBelowXs
                  ? { fontSize: 11, width: 132 }
                  : { width: 180, fontSize: 12.8 }
              }
              onChange={(e) =>
                setActiveFilters({
                  ...activeFilters,
                  category: parseInt(e.target.value as string),
                })
              }
            >
              <MenuItem value={0} style={isBelowXs ? { fontSize: 12.8 } : {}}>
                <em>{t('ExamCategory')}</em>
              </MenuItem>
              {examCategories.map((cat, i) => (
                <MenuItem key={i} value={cat.id}>
                  <Typography
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: 12.8,
                    }}
                  >
                    {cat.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </DateSelect>
      <Tooltip title={t('ResetFilter').toString()}>
        <IconButton
          style={isBelowXs ? { padding: 0 } : {}}
          onClick={() => setActiveFilters(initActive)}
        >
          <Refresh />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
export const ExamsFilterBar = hot(_ExamsFilterBar);
const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
  },
  selectWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  selectComp: {
    backgroundColor: 'rgba(196, 196, 196, 0.27)',
    fontSize: 14,
    height: 45,
    [theme.breakpoints.down('sm')]: {
      height: 30,
    },
  },
  root: {
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
}));
