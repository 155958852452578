import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import {
  UserDto,
  UpdateUser,
  meControllerGetMe,
  meControllerUpdateMe,
  meControllerDisableAccount,
} from 'gen/client';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Colors, DateFormats } from 'res/Enums';
import { useAuthContext } from 'context/AuthContext';
import { Person, MenuBook } from '@material-ui/icons';
import { useConfirm } from 'components/ConfirmDialogProvider';
import { isUndefined } from 'util';
import moment from 'moment';

const _Profile = () => {
  const { logout } = useAuthContext();
  const [me, setMe] = useState<UserDto>();
  const [ready, setReady] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const confirm = useConfirm();
  const [loading, setLoading] = useState(true);
  const getMe = async () => {
    try {
      await meControllerGetMe().then((data) => setMe(data.data));
    } catch (e) {}
  };

  const handleEnd = async (tut: boolean) => {
    const resp = await meControllerUpdateMe({
      ...(me as unknown as UpdateUser),
      tutorial: tut,
    }).catch();
    if (resp) setReady(true);
  };

  const disableAccount = async () => {
    if (!isUndefined(me)) {
      const resp = await meControllerDisableAccount({
        user: me as unknown as UpdateUser,
        date: moment().format(DateFormats.ISONoTime),
      }).catch();
      if (resp) logout();
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (!isUndefined(me)) setLoading(false);
  }, [me]);

  useEffect(() => {
    if (ready) {
      history.push('/success');
      window.location.reload(false);
    }
  }, [ready]);
  if (loading) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="column">
        <Typography className={classes.name}>{me?.name}</Typography>
        <Typography className={classes.name}>{me?.surname}</Typography>
      </Box>
      <Box style={{ marginTop: 50 }}>
        <Typography className={classes.title}>{t('Profile')}</Typography>
      </Box>
      <Box style={{ marginTop: 60 }}>
        <Typography className={classes.title}>{t('Settings')}</Typography>
        <Button
          startIcon={<LockIcon />}
          size="large"
          onClick={() => history.push('/terms')}
          style={{
            marginTop: 10,
            fontSize: 18,
            color: Colors.FadeBlack,
            fontFamily: 'Roboto',
          }}
        >
          {t('Protection')}
        </Button>
      </Box>
      <Box style={{ marginTop: 10 }}>
        <Button
          startIcon={<SettingsIcon />}
          size="large"
          style={{
            marginTop: 10,
            fontSize: 18,
            color: Colors.FadeBlack,
            fontFamily: 'Roboto',
          }}
          onClick={() => history.push('/editprofile')}
        >
          {t('Management')}
        </Button>
      </Box>
      <Box style={{ marginTop: 10 }}>
        <Button
          startIcon={<MenuBook />}
          size="large"
          style={{
            marginTop: 10,
            fontSize: 18,
            color: Colors.FadeBlack,
            fontFamily: 'Roboto',
          }}
          onClick={() => {
            confirm({
              title: t('TutSure2'),
              description: t('TutSure3'),
              cancellationText: t('Cancel'),
            }).then(() => {
              handleEnd(false);
            });
          }}
        >
          {t('Tutorial')}
        </Button>
      </Box>
      <Box style={{ marginTop: 10 }}>
        <Button
          startIcon={<Person />}
          size="large"
          style={{
            marginTop: 10,
            fontSize: 18,
            color: Colors.FadeBlack,
            fontFamily: 'Roboto',
          }}
          onClick={() => history.push('/about')}
        >
          {t('AboutUs')}
        </Button>
      </Box>
      <Box
        style={{
          marginTop: 125,
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          onClick={() => {
            confirm({
              title: t('DeleteAc'),
              description: t('DeleteAc1'),
              cancellationText: t('Cancel'),
            }).then(() => disableAccount());
          }}
          variant="outlined"
          style={{
            fontSize: 16,
            fontFamily: 'Roboto',
            color: 'red',
            border: '1px solid red',
          }}
        >
          {t('DeleteAccount')}
        </Button>
      </Box>
      <Box />
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  name: {
    fontSize: 34,
    color: Colors.Black,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 24,
    color: '#263238',
    fontWeight: 'bold',
  },
  button: {},
}));
export const Profile = hot(_Profile);
