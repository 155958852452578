import React, { useEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import {
  Accordion,
  AccordionDetails,
  Paper,
  CircularProgress,
  Typography,
  AccordionSummary,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Checkbox,
  Box,
  Dialog,
  Popover,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { uniqueId } from 'underscore';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  preventiveControllerAddPreventive,
  preventiveControllerDeletePreventive,
  usePreventiveControllerGetMyPreventive,
} from 'gen/client';
import { useTutorialContext } from 'context/TutorialContext';
import { Demo } from 'components/Demo';
import { Colors } from 'res/Enums';

interface PreventiveProps {
  tut?: number;
}

interface PreventiveFormmated {
  id?: number;
  examId: number;
  dateAdded?: string;
  name: string;
  notes: string;
}
interface Preventive {
  data: Record<string, PreventiveFormmated[]>;
}
const _Preventive = ({ tut }: PreventiveProps) => {
  const { t } = useTranslation();
  const { data, isLoading, refetch } = usePreventiveControllerGetMyPreventive();
  const [expanded, setExpanded] = useState(moment().year());
  const { openT, me, cont, setCont } = useTutorialContext();
  const [temp, setTemp] = useState(0);
  const inputEl = useRef<any | null>();
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));

  const onClick = async (exam: PreventiveFormmated) => {
    try {
      if (exam.id) {
        await preventiveControllerDeletePreventive(exam.id);
        refetch();
      } else {
        await preventiveControllerAddPreventive({ examID: exam.examId });
        refetch();
      }
    } catch (error) {}
  };

  useEffect(() => {
    setTemp(cont);
    setCont(cont + 1);
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <Paper ref={inputEl}>
      <Box style={{ padding: 5, textAlign: 'center' }}>
        <Typography variant="h6" color="primary">
          {t('Preventive')}
        </Typography>
      </Box>
      {data &&
        Object.keys(data.data.data)
          .reverse()
          .map((key) => (
            <Accordion
              key={uniqueId()}
              expanded={expanded === parseInt(key)}
              onChange={() => setExpanded(parseInt(key))}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>{key}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ overflowX: 'auto' }}>
                <Typography>
                  <Table key={uniqueId()}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('ExamName')}</TableCell>
                        <TableCell>{t('Description')}</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(data.data as Preventive).data[key].map((exam: any) => (
                        <TableRow key={uniqueId()}>
                          <TableCell>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {exam.name}
                            </Typography>
                          </TableCell>
                          <TableCell>{exam.notes}</TableCell>
                          <Checkbox
                            color="primary"
                            onClick={() => onClick(exam)}
                            checked={Boolean(exam.dateAdded)}
                            disabled={moment().year() !== parseInt(key)}
                          />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
      {temp < 1 && (
        <Dialog
          open={!me?.tutorial && openT === '23'}
          BackdropProps={{
            style: { backgroundColor: 'black', opacity: 0.8 },
          }}
        >
          <Popover
            open={openT === '23'}
            anchorEl={inputEl.current}
            PaperProps={{
              style: {
                marginLeft: -16,
                width: isBelowMd ? '90vw' : '75vw',
                height: '80vh',
                overflow: 'hidden',
              },
            }}
          >
            <Preventive tut={cont} />
            <Popover
              open={openT === '23'}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  marginLeft: '-16%',
                  marginTop: '1%',
                  width: isBelowMd ? '60vw' : '45vw',
                  textAlign: 'center',
                },
              }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                {t('Tut34')}
              </Typography>
            </Popover>
            <Demo />
          </Popover>
        </Dialog>
      )}
    </Paper>
  );
};

export const Preventive = hot(_Preventive);
