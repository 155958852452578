import {
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Paper,
  Divider,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Drawer,
  Tooltip as TooltipTable,
  IconButton,
  Select,
  MenuItem,
} from '@material-ui/core';
import clsx from 'clsx';
import { StyledLineChart } from 'components/LineChart';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Tooltip as TooltipChart, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';
import { TablePaginationActions } from 'components/TablePaginationActions';
import { toast } from 'react-toastify';
import { ShortInfo } from 'components/Symptoms/ShortInfo';
import { FilterBar, GroupProps, useFilter } from 'components/Filter/FilterBar';
import { StyledBarChart } from 'components/BarChart';
import { SymptomsBox } from 'components/Symptoms/SymptomBox';
import AddIcon from '@material-ui/icons/Add';
import {
  PregnancyDTO,
  SymptomDTO,
  UserSymptomDTO,
  useSymptomControllerDeleteMySymptom,
} from 'gen/client';

interface PregnancySymptoms {
  rawData: UserSymptomDTO[];
  onChange: (measurement: SymptomDTO) => void;
  onRefresh: () => void;
  activeSymptom: SymptomDTO[];
  pregnancy?: PregnancyDTO;
}

const _PregnancySymptoms = ({
  rawData,
  activeSymptom,
  pregnancy,
  onRefresh,
  onChange,
}: PregnancySymptoms) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editOn, setEditOn] = useState(false);
  const [symptom, setSymptom] = useState<SymptomDTO>(activeSymptom[0]);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState<UserSymptomDTO[]>([]);
  const [activeFilter, setActiveFilter] = useFilter();
  const deleteMutation = useSymptomControllerDeleteMySymptom();

  const [symptomToEdit, setSymptomToEdit] = useState<UserSymptomDTO>();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteSymptom = async (id: number) => {
    try {
      const response = await deleteMutation.mutateAsync({ id });
      if (response) {
        toast(t('SymptomDeleted'));
        onRefresh();
      } else {
        toast(t('FailedDeleting'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (symptomToEdit !== undefined) setOpen(true);
  }, [symptomToEdit]);

  useEffect(() => {
    onChange(symptom);
  }, [symptom]);

  return (
    <Box>
      {editOn === false ? (
        <Fragment>
          <Paper>
            <Box
              display="flex"
              className={classes.padd}
              style={isBelowXs ? { padding: 0 } : {}}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  className={clsx([classes.graph])}
                  style={{ width: isBelowSm ? '85%' : '50%' }}
                >
                  <Select
                    value={symptom.id}
                    onChange={(e) =>
                      setSymptom(
                        activeSymptom.filter(
                          (item) => item.id === e.target.value
                        )[0]
                      )
                    }
                  >
                    {activeSymptom.map((type, i) => (
                      <MenuItem value={type.id} key={i}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <IconButton onClick={() => setOpen(true)}>
                  <AddIcon />
                </IconButton>
              </Box>
              <Divider
                style={{
                  marginTop: '3%',
                  backgroundColor: '#E3DFFA',
                }}
              />
              {rawData.length > 0 && (
                <Box display="flex" style={{ width: '100%' }}>
                  <ShortInfo latest={rawData[0]} />
                </Box>
              )}
              <Divider
                style={{
                  marginTop: '3%',
                  backgroundColor: '#E3DFFA',
                }}
              />
              {symptom.isBoolean === false ? (
                <Box
                  className={clsx([classes.graph])}
                  style={isBelowXs ? { padding: 6 } : {}}
                >
                  {rawData.length > 0 ? (
                    <StyledLineChart
                      data={rawData
                        .map(({ time, ...rest }) => ({
                          ...rest,
                          time: moment(time).format(DateFormats.GR),
                        }))
                        .slice(0, 100)
                        .reverse()}
                      dataKey="value"
                    >
                      <XAxis dataKey="time" axisLine={false} tickLine={false} />
                      <YAxis
                        dataKey="value"
                        axisLine={false}
                        tickLine={false}
                        domain={symptom.id === 12 ? [35, 42] : [0, 'auto']}
                      />
                      <TooltipChart
                        formatter={(datakey, name) => [datakey, t('Value')]}
                      />
                    </StyledLineChart>
                  ) : (
                    <Box
                      style={{
                        display: 'flex',
                        height: '65%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: Colors.Black,
                      }}
                    >
                      {t('NoFilter')}
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  className={clsx([classes.graph])}
                  style={isBelowXs ? { padding: 6 } : {}}
                >
                  <FilterBar<UserSymptomDTO>
                    data={rawData}
                    enables={{ group: true, date: true }}
                    onFilter={(data, group) =>
                      setFilterData(
                        ((_data) => {
                          if (
                            data.length > 1 &&
                            group !== GroupProps.ALL &&
                            group !== GroupProps.YEAR
                          ) {
                            let sorted = data.sort((a, b) =>
                              moment(a.time).diff(moment(b.time))
                            );
                            let temp: UserSymptomDTO[] = sorted;
                            sorted.forEach((item, i) => {
                              if (sorted[i + 1]) {
                                const next = sorted[i + 1];
                                const diff = Math.abs(
                                  moment(item.time).diff(
                                    next.time,
                                    group === GroupProps.MONTH
                                      ? 'month'
                                      : group === GroupProps.WEEK
                                      ? 'week'
                                      : 'day'
                                  )
                                );
                                if (diff > 1) {
                                  const newDates: string[] = [];
                                  for (let i = 1; i < diff; i++) {
                                    newDates.push(
                                      group === GroupProps.WEEK
                                        ? moment(item.time)
                                            .startOf('week')
                                            .add(i, 'weeks')
                                            .format(DateFormats.ISO)
                                        : moment(item.time)
                                            .add(
                                              i,
                                              group === GroupProps.MONTH
                                                ? 'month'
                                                : 'day'
                                            )
                                            .format(DateFormats.ISO)
                                    );
                                  }
                                  temp = temp.concat(
                                    ...(newDates.map((item) => ({
                                      time: item,
                                      value: 0,
                                    })) as unknown as UserSymptomDTO[])
                                  );
                                }
                              }
                            });
                            return temp.sort((a, b) =>
                              moment(b.time).diff(moment(a.time))
                            );
                          }
                          return _data;
                        })(data)
                      )
                    }
                    dateKey="time"
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                  />
                  {filterData.length > 0 ? (
                    <StyledBarChart
                      width={filterData.length < 3 ? 100 : undefined}
                      data={filterData
                        .map(({ time, ...rest }) => ({
                          ...rest,
                          time: moment(time).format(DateFormats.GR),
                        }))
                        .slice(0, 100)
                        .reverse()}
                      dataKey="value"
                    >
                      <XAxis dataKey="time" axisLine={false} tickLine={false} />
                      <YAxis
                        dataKey="value"
                        axisLine={false}
                        tickLine={false}
                      />
                      <TooltipChart
                        formatter={(datakey, name) => [datakey, t('Quantity')]}
                      />
                    </StyledBarChart>
                  ) : (
                    <Box
                      style={{
                        display: 'flex',
                        height: '65%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: Colors.Black,
                      }}
                    >
                      {t('NoFilter')}
                    </Box>
                  )}
                </Box>
              )}
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '2%',
                }}
              >
                <Button
                  variant="text"
                  style={{ padding: 0 }}
                  onClick={() => {
                    if (editOn === false) setEditOn(true);
                    else setEditOn(false);
                  }}
                >
                  {t('ChangeGraph1')}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Fragment>
      ) : (
        <Fragment>
          <Box
            display="flex"
            className={classes.padd}
            style={isBelowXs ? { padding: 0 } : {}}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Box
                className={clsx([classes.graph])}
                style={{ width: isBelowSm ? '85%' : '50%' }}
              >
                <Select
                  value={symptom.id}
                  onChange={(e) =>
                    setSymptom(
                      activeSymptom.filter(
                        (item) => item.id === e.target.value
                      )[0]
                    )
                  }
                >
                  {activeSymptom.map((type, i) => (
                    <MenuItem value={type.id} key={i}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <IconButton onClick={() => setOpen(true)}>
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
          <Box style={{ margin: '8px 0px' }}>
            <Typography
              style={{
                padding: 16,
                color: Colors.Black,
                fontSize: isBelowXs ? 18 : 20,
                fontWeight: 'bold',
              }}
            >
              {t('DiagramT')}
            </Typography>
          </Box>
          <Paper style={{ overflow: 'auto' }}>
            <Table>
              <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                <TableRow>
                  {!isBelowXs && <TableCell padding="checkbox" />}
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('Comments')}
                  </TableCell>
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('Date')}
                  </TableCell>
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('Time')}
                  </TableCell>
                  <TableCell padding="none" />
                  <TableCell padding="none" />
                  <TableCell padding="none" />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? rawData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rawData
                ).map((row, i) => (
                  <TableRow key={i}>
                    {!isBelowXs && <TableCell padding="checkbox" />}
                    <TableCell padding="none">
                      {row.description.length < 15 ? (
                        row.description
                      ) : (
                        <TooltipTable
                          title={row.description}
                          classes={{ tooltip: classes.customWidth }}
                        >
                          <Box
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              maxWidth: 190,
                            }}
                          >
                            {row.description}
                          </Box>
                        </TooltipTable>
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(row.time).format(DateFormats.GrNoTime)}
                    </TableCell>
                    <TableCell>
                      {moment(row.time).format(DateFormats.TIME)}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      style={isBelowXs ? { padding: '0px 8px' } : {}}
                    >
                      <EditIcon
                        style={{
                          color: 'rgba(0, 0, 0, 0.58)',
                          fontSize: 18,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          window.scrollTo(0, 0);
                          setSymptomToEdit(row);
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <Box
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, 0.34)',
                          width: 2,
                          height: 40,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      style={isBelowXs ? { padding: '0px 8px' } : {}}
                    >
                      <DeleteIcon
                        style={{
                          color: 'rgba(0, 0, 0, 0.58)',
                          fontSize: 18,
                          cursor: 'pointer',
                        }}
                        onClick={async () => {
                          await deleteSymptom(row.id);
                          setSymptomToEdit(undefined);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      20,
                      100,
                      { label: 'All', value: -1 },
                    ]}
                    classes={{
                      spacer: classes.spacer,
                      input: classes.input,
                    }}
                    labelRowsPerPage={t('RowsText')}
                    count={rawData.length}
                    onChangePage={handleChangePage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '2%',
                paddingBottom: '2%',
                paddingRight: 16,
              }}
            >
              <Button
                variant="text"
                style={{ padding: 0 }}
                onClick={() => {
                  setEditOn(false);
                }}
              >
                {t('ChangeGraph2')}
              </Button>
            </Box>
          </Paper>
        </Fragment>
      )}
      <Drawer
        open={open}
        anchor="right"
        PaperProps={{ style: { width: '30vw', minWidth: 430 } }}
        onClose={() => setOpen(false)}
      >
        <Box style={{ padding: '16px 48px 0px' }}>
          <SymptomsBox
            onAdd={() => {
              onRefresh();
              setOpen(false);
            }}
            onEdit={() => {
              onRefresh();
              setOpen(false);
            }}
            onCancel={() => {
              setSymptomToEdit(undefined);
              setOpen(false);
            }}
            drawer
            symptom={symptomToEdit}
            active={symptom}
            lastSymptom={rawData[0]}
            pregnancy={pregnancy}
          />
        </Box>
      </Drawer>
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: 60,
  },
  graph: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    height: '100%',
  },
  padd: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
  spacer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  input: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
    },
  },
  customWidth: {
    maxWidth: 500,
  },
}));
export const PregnancySymptoms = hot(_PregnancySymptoms);
