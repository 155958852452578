import {
  Box,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Button,
} from '@material-ui/core';
import { UserMedicationDTO, GetNextDosagesDTO } from 'gen/client';
import moment, { Moment } from 'moment';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'res/Enums';
import { isNullOrUndefined, isUndefined } from 'util';
import { useHistory } from 'react-router';
import { Add } from '@material-ui/icons';
import { chooseIcon } from 'components/Medication/MedicationHistory';
import { Circle } from 'Pages/Cycle';

interface NextMedicationProps {
  med: UserMedicationDTO[] | GetNextDosagesDTO[];
  date?: Moment;
}

export const NextMedication = ({ med, date }: NextMedicationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const filteredData = (item: GetNextDosagesDTO) => {
    return item.dosages.filter(
      (time) =>
        moment(time).isSameOrBefore(moment(date).endOf('day')) &&
        moment(time).isSameOrAfter(moment(date).startOf('day'))
    );
  };

  const filteredMeds = (item: GetNextDosagesDTO[] | UserMedicationDTO[]) => {
    if (!isUndefined(item[0].nextTake)) return item;
    else
      return (item as Array<GetNextDosagesDTO>).filter((time) =>
        moment(time.dosages[0]).isSameOrBefore(moment(date).endOf('day'))
      );
  };

  return !isNullOrUndefined(med) && med.length > 1 ? (
    <Fragment>
      {(filteredMeds(med) as Array<GetNextDosagesDTO>).map((item, i) => (
        <Paper
          key={i}
          className={classes.paper}
          style={{
            display:
              isUndefined(item.nextTake) && filteredData(item).length < 1
                ? 'none'
                : 'flex',
          }}
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            {(() => {
              const res = chooseIcon(item as unknown as UserMedicationDTO);
              if (!res)
                return (
                  <Box marginRight={3}>
                    <Circle color={item.color.code} />
                  </Box>
                );
              return <Box marginX={1}> {res} </Box>;
            })()}
          </Box>
          {!isUndefined(item.nextTake) ? (
            <Box>
              <Box style={{ color: '#2B387C', fontWeight: 'bold' }}>{`${moment(
                item.nextTake?.time
              ).format('HH:mm D-MMMM')}| ${t('nextDosage')} | ${
                item.nextTake?.dosage
              } ${t('Dosages')}`}</Box>
              <Box>
                <Typography
                  style={{ color: Colors.FadeBlack, fontWeight: 'bold' }}
                >
                  {item.medicine.name}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box>
                <Typography style={{ color: '#2B387C', fontWeight: 'bold' }}>
                  {item.medicine.name}
                </Typography>
              </Box>
              <Box style={{ color: Colors.FadeBlack }}>
                {filteredData(item).map((dosage, i) => {
                  if (i < filteredData(item).length - 1)
                    return moment(dosage).format('HH:mm').concat(' | ');
                  else return moment(dosage).format('HH:mm');
                })}
              </Box>
            </Box>
          )}
        </Paper>
      ))}
    </Fragment>
  ) : !isNullOrUndefined(med) && med.length > 0 ? (
    <Fragment>
      {(filteredMeds(med) as Array<GetNextDosagesDTO>).map((item, i) => (
        <Paper
          key={i}
          className={classes.paper}
          style={{
            display:
              isUndefined(item.nextTake) && filteredData(item).length < 1
                ? 'none'
                : 'flex',
          }}
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            {(() => {
              const res = chooseIcon(item as unknown as UserMedicationDTO);
              if (!res)
                return (
                  <Box marginRight={3}>
                    <Circle color={item.color.code} />
                  </Box>
                );
              return <Box marginX={1}> {res} </Box>;
            })()}
          </Box>
          {!isUndefined(item.nextTake) ? (
            <Box>
              <Box style={{ color: '#2B387C', fontWeight: 'bold' }}>{`${moment(
                item.nextTake?.time
              ).format('HH:mm D-MMMM')}| ${t('nextDosage')} | ${
                item.nextTake?.dosage
              } ${t('Dosages')}`}</Box>
              <Box>
                <Typography
                  style={{ color: Colors.FadeBlack, fontWeight: 'bold' }}
                >
                  {item.medicine.name}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box>
                <Typography style={{ color: '#2B387C', fontWeight: 'bold' }}>
                  {item.medicine.name}
                </Typography>
              </Box>
              <Box style={{ color: Colors.FadeBlack }}>
                {filteredData(item).map((dosage, i) => {
                  if (i < filteredData(item).length - 1)
                    return moment(dosage).format('HH:mm').concat(' | ');
                  else return moment(dosage).format('HH:mm');
                })}
              </Box>
            </Box>
          )}
        </Paper>
      ))}
    </Fragment>
  ) : (
    <Paper className={classes.paper}>
      <Box>
        <Typography style={{ marginBottom: 5, color: Colors.Black }}>
          {t('Nomed')}
        </Typography>
        <Button
          endIcon={<Add />}
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => history.push('/medication')}
        >
          {t('AddMedication')}
        </Button>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: '#F3F8FF',
    padding: theme.spacing(2),
    marginBottom: 16,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
  },
  circle: {
    width: 24,
    height: 24,
    background: '#D63F3F',
    borderRadius: 24,
    marginRight: theme.spacing(2),
  },
}));
