import { Colors } from 'res/Enums';
import { makeStyles, Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { isUndefined } from 'util';

interface EmptyPageProps {
  image: string;
  text: string;
  text1: string;
  text2?: string;
  onPress?: () => void;
}

export const EmptyPage = ({
  onPress,
  image,
  text,
  text1,
  text2,
}: EmptyPageProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Box style={{ alignSelf: 'center' }}>
        <img alt="" src={image} style={{ height: 264, width: 264 }} />
      </Box>
      <Typography
        style={{
          fontSize: 21,
          fontWeight: 'bold',
          color: Colors.Black,
          justifyContent: 'center',
          display: 'flex',
          marginTop: 20,
        }}
      >
        {text}
      </Typography>
      <Typography
        style={{
          fontSize: 16,
          color: Colors.Black,
          marginTop: 15,
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        {text1}
      </Typography>
      {!isUndefined(text2) && (
        <Box
          style={{ marginTop: 25, justifyContent: 'center', display: 'flex' }}
        >
          <Button color="primary" variant="contained" onClick={onPress}>
            {text2}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingTop: 100,
  },
}));
