import { MedicalCenterDTOType } from 'gen/client';
import moment, { Moment } from 'moment';
import { groupBy } from 'underscore';
import { GroupProps } from './FilterBar';

export class Filter<T extends { [key: string]: any }> {
  private data!: T[];
  private dateKey: string;
  private endDateKey: string;
  private dataKey: string;
  private numeric: boolean;

  constructor(
    data: T[],
    dateKey = 'date',
    dataKey = 'value',
    numeric = true,
    endDateKey = 'endDate'
  ) {
    this.data = data;
    this.dateKey = dateKey;
    this.endDateKey = endDateKey;
    this.dataKey = dataKey;
    this.numeric = numeric;
  }
  ascending() {
    this.data = this.data
      .slice()
      .sort((a, b) => moment(b[this.dateKey]).diff(moment(a[this.dateKey])));
    return this;
  }
  descending() {
    this.data = this.data
      .slice()
      .sort((a, b) => moment(a[this.dateKey]).diff(moment(b[this.dateKey])));
    return this;
  }
  isSameBefore(date = moment()) {
    this.data = this.data.filter((item) =>
      moment(item[this.dateKey]).isSameOrBefore(date, 'day')
    );
    return this;
  }
  isSameAfter(date = moment()) {
    this.data = this.data.filter((item) =>
      moment(item[this.dateKey]).isSameOrAfter(date, 'day')
    );
    return this;
  }

  isBetween(startDate = moment(), endDate = moment()) {
    this.data = this.data.filter((item) =>
      moment(item[this.dateKey]).isBetween(startDate, endDate, 'day')
    );
    return this;
  }
  isAfterMed(fromDate: Moment) {
    this.data = this.data.filter((item) =>
      moment(item[this.endDateKey]).isSameOrAfter(fromDate, 'd')
    );
    return this;
  }
  isBeforeMed(toDate: Moment) {
    this.data = this.data.filter((item) =>
      moment(item[this.dateKey]).isSameOrBefore(toDate, 'd')
    );
    return this;
  }
  isBetweenDateSpace(startDate: Moment, endDate: Moment) {
    this.data = this.data.filter(
      (item) =>
        !moment(item[this.dateKey]).isBefore(startDate) ||
        !moment(item[this.endDateKey]).isAfter(endDate)
    );

    return this;
  }
  search(value: string, key: string) {
    const keys = key.split('.');
    this.data = this.data.filter((item) =>
      (item[keys[0]][keys[1]] as string).match(new RegExp(`.*${value}.*`, 'i'))
    );
    return this;
  }
  night() {
    this.data = this.data.filter((item) => {
      let hour = parseInt(moment(item[this.dateKey]).format('HHmm'));
      return (hour >= 0 && hour < 500) || (hour > 1800 && hour <= 2400);
    });
    return this;
  }
  morning() {
    this.data = this.data.filter((item) => {
      let hour = parseInt(moment(item[this.dateKey]).format('HHmm'));
      return hour >= 500 && hour <= 1200;
    });
    return this;
  }
  evening() {
    this.data = this.data.filter((item) => {
      let hour = parseInt(moment(item[this.dateKey]).format('HHmm'));
      return hour >= 1200 && hour <= 1800;
    });
    return this;
  }
  filterBy<K>(option: K, key: string) {
    this.data = this.data.filter((item) => item[key] === option);
    return this;
  }
  groupBy(by: GroupProps, avg = false) {
    const grouped = groupBy(this.data, (item) =>
      moment(item[this.dateKey]).format(by)
    );
    const temp = Object.keys(grouped).map((day) =>
      grouped[day].reduce((acc, item, i) => {
        if (acc.length === 0) {
          if (this.numeric === true) return item;
          else
            return {
              ...item,
              [this.dateKey]: item[this.dateKey],
              [this.dataKey]: 1,
            } as T;
        } else {
          if (this.numeric === true)
            return {
              ...item,
              [this.dateKey]: item[this.dateKey],
              [this.dataKey]: acc[this.dataKey] + item[this.dataKey],
            } as T;
          else
            return {
              ...item,
              [this.dateKey]: item[this.dateKey],
              [this.dataKey]: i < 2 ? 2 : acc[this.dataKey] + 1,
            } as T;
        }
      }, [] as unknown as T)
    );
    //TODO avg
    this.data = this.normalizeDate(this.dateKey, temp, by);
    return this;
  }
  byExamCategory(categoryId: number) {
    this.data = this.data.filter((exam) => exam.category.id === categoryId);
    return this;
  }
  byMedicalCenter(centerId: number) {
    this.data = this.data.filter((exam) => exam.medicalCenter.id === centerId);
    return this;
  }
  byMedicalType(type: MedicalCenterDTOType) {
    this.data = this.data.filter((exam) => exam.medicalCenter.type === type);
    return this;
  }
  byRegion(regionId: string) {
    this.data = this.data.filter(
      (exam) => exam.medicalCenter.region === regionId
    );
    return this;
  }
  private normalizeDate = (dateKey: string, data: T[], by: GroupProps) => {
    return data.map((item) => ({
      ...item,
      [dateKey]: moment(item[dateKey])
        .startOf(by === GroupProps.WEEK ? 'week' : 'day')
        .format(
          (() => {
            switch (by) {
              case GroupProps.YEAR:
                return 'YYYY';
              case GroupProps.MONTH:
                return 'YYYY-MM';
              case GroupProps.WEEK:
                return 'YYYY-MM-DD';
              case GroupProps.DAY:
                return 'YYYY-MM-DD';
            }
          })()
        ),
    }));
  };
  get() {
    return this.data;
  }
  set(data: T[]) {
    this.data = data;
    return this;
  }
}
