import { Box, IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Alarm,
  ArrowDropDown,
  ArrowRight,
  Delete,
  Stop,
} from '@material-ui/icons';
import { UserMedicationDTO } from 'gen/client';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Colors, DateFormats } from 'res/Enums';
import { DeleteToast } from '../Toasts';
import theme from '../../assets/theme';
import {
  medicationControllerStopMedication,
  useMedicationControllerDeleteMyMedication,
} from 'gen/client';
interface MedicationActiveProps {
  data: UserMedicationDTO[];
  openDrawer: boolean;
  onUpdated: () => void;
  onOpen: (data: UserMedicationDTO) => void;
}
export const MedicationActive = ({
  data,
  openDrawer,
  onUpdated,
  onOpen,
}: MedicationActiveProps) => {
  const classes = useStyles();
  const [medicine, setMedicine] = useState(-1);

  return (
    <Box className={classes.wrapper}>
      {data.map((row, i) => (
        <MedicationActiveRow
          onUpdated={() => onUpdated()}
          onOpen={(data) => onOpen(data)}
          onClose={(data) => setMedicine(data)}
          medicine={medicine}
          key={i}
          data={row}
          openDrawer={openDrawer}
          ky={i}
        />
      ))}
    </Box>
  );
};

interface MedicationActiveRowProps {
  data: UserMedicationDTO;
  openDrawer: boolean;
  onUpdated: () => void;
  onOpen: (data: UserMedicationDTO) => void;
  ky: number;
  medicine: number;
  onClose: (data: number) => void;
}
const MedicationActiveRow = ({
  data,
  openDrawer,
  ky,
  medicine,
  onUpdated,
  onOpen,
  onClose,
}: MedicationActiveRowProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const smLess = useMediaQuery(theme.breakpoints.down('sm'));
  const deleteMedication = useMedicationControllerDeleteMyMedication();
  const stopMedication = async (id: number) => {
    try {
      const r = await medicationControllerStopMedication(id);
      if (r.data) {
        toast(t('MedicationStopped'));
        onUpdated();
      } else {
        toast(t('Failed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const opening = () => {
    setOpen(true);
    onClose(ky);
  };

  useEffect(() => {
    if (open) onOpen(data);
  }, [open]);

  useEffect(() => {
    if (ky === medicine) {
      setOpen(openDrawer);
    }
  }, [openDrawer]);

  return (
    <Box display="flex" flexDirection="column" className={classes.container}>
      {smLess ? (
        <Box className={classes.containerInfo}>
          <Box className={classes.center} onClick={opening}>
            <Alarm fontSize="small" />
          </Box>
          <Box
            style={{ display: 'flex', flexDirection: 'column' }}
            onClick={opening}
          >
            <Box style={{ fontSize: 12 }}>{`${moment(
              data.nextTake?.time
            ).format(DateFormats.GR)}| ${t('nextDosage')} | ${
              data.nextTake?.dosage
            } ${t('Dosages')}`}</Box>
            <Box fontSize={11} fontWeight="bold" fontStyle="italic">
              {data.medicine.name}
            </Box>
          </Box>
          <Box display="flex">
            <Tooltip title={t('StopMedication').toString()}>
              <IconButton onClick={() => stopMedication(data.id)}>
                <Stop fontSize="small" />
              </IconButton>
            </Tooltip>
            <IconButton
              size="small"
              onClick={async () => {
                DeleteToast().then(async () => {
                  try {
                    const r = await deleteMedication.mutateAsync({
                      id: data.id,
                    });
                    if (r.data) {
                      toast(t('Deleted'), { containerId: 'deleted' });
                      onUpdated();
                    } else {
                      toast(t('DeleteFailed'));
                    }
                  } catch (e) {
                    toast(t('Failed'), { containerId: 'deleted' });
                  }
                });
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box className={classes.containerInfo}>
          <Box className={classes.center} onClick={opening}>
            <Alarm />
          </Box>
          <Box className={classes.inner} onClick={opening}>
            <Box>{`${moment(data.nextTake?.time).format(DateFormats.GR)}| ${t(
              'nextDosage'
            )} | ${data.nextTake?.dosage} ${t('Dosages')}`}</Box>
            <Box>{data.medicine.name}</Box>
          </Box>
          <Tooltip title={t('StopMedication').toString()}>
            <IconButton onClick={() => stopMedication(data.id)}>
              <Stop />
            </IconButton>
          </Tooltip>
          <IconButton
            size="small"
            onClick={async () => {
              DeleteToast().then(async () => {
                try {
                  const r = await deleteMedication.mutateAsync({ id: data.id });
                  if (r.data) {
                    toast(t('Deleted'), { containerId: 'deleted' });
                    onUpdated();
                  } else {
                    toast(t('DeleteFailed'));
                  }
                } catch (e) {
                  toast(t('Failed'), { containerId: 'deleted' });
                }
              });
            }}
          >
            <Delete />
          </IconButton>
          <IconButton
            className={classes.center}
            aria-label="delete"
            size="small"
            onClick={opening}
          >
            {!open ? (
              <ArrowRight fontSize="inherit" />
            ) : (
              <ArrowDropDown fontSize="inherit" />
            )}
          </IconButton>
        </Box>
      )}
      {/* <MedicationDrawer
        userData={data}
        open={open}
        onClose={() => setOpen(false)}
        onUpdated={onUpdated}
      /> */}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: Colors.BlueOpaque,
    border: '1px solid',
    borderColor: Colors.BlueOpaque,
    borderRadius: 12,
    paddingRight: 6,
    paddingLeft: 6,
    margin: '10px 0',
    fontSize: 16,
    color: Colors.BlueText,
  },
  containerInfo: {
    display: 'flex',
  },
  inner: {
    marginLeft: 10,
    width: '100%',
    cursor: 'pointer',
  },
  center: {
    display: 'flex',
    margin: 10,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  wrapper: {
    width: 'fit-content',
    flex: '1 0 auto',
    maxHeight: '70vh',
    overflowY: 'auto',
  },
}));
