import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { PregnancyBox } from 'components/Pregnancy/PregnancyBox';
import React, { Fragment, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { CalendarTodaySharp, Clear, ExpandMore } from '@material-ui/icons';
import { Colors, DateFormats } from 'res/Enums';
import moment, { Moment } from 'moment';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import ListIcon from '@material-ui/icons/List';
import DoneIcon from '@material-ui/icons/Done';
import { toast } from 'react-toastify';
import { StyledDatePicker } from 'components/DatePicker';
import { PregnancyMeasurements } from 'components/Pregnancy/PregnancyMeasurements';
import { PregnancySymptoms } from 'components/Pregnancy/PregnancySymptoms';
import { PregnancyExams } from 'components/Pregnancy/PregnancyExams';
import {
  MeasurementDTO,
  PregnancyDetailsDTO,
  PregnancyDTO,
  UserMeasurementDTO,
  Bmidto,
  SymptomDTO,
  UserSymptomDTO,
  usePregnancyControllerGetMyPregnancy,
  measurementsControllerGetMyMeasurementByType,
  symptomControllerGetMySymptomsByType,
  pregnancyControllerCreateMyKids,
  useSymptomControllerGetMySymptomsByType,
  symptomControllerGetSymptomTypes,
  measurementsControllerGetMeasurementTypes,
  usePregnancyControllerGetPregnancyDetails,
  pregnancyControllerGetMyPregnancy,
  pregnancyControllerGetMyPregnancyMeasurements,
  pregnancyControllerGetMyBMI,
  PregnancyDetailsDTOType as type,
  UpdatePregnancyType,
  usePregnancyControllerUpdateMyPregnancy,
  usePregnancyControllerDeleteMyPregnancy,
} from 'gen/client';

const _Pregnancy = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [userPregnancy, setUserPregnancy] = useState<PregnancyDTO[]>([]);
  const [pregnancy, setPregnancy] = useState<PregnancyDTO | undefined>();
  const [details, setDetails] = useState<PregnancyDetailsDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [week, setWeek] = useState(0);
  const [birthDate, setBirthDate] = useState(moment());
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [option, setOption] = useState<UpdatePregnancyType | number>(0);
  const [dialogComment, setDialogComment] = useState('');
  const [measurementsTypes, setMeasurementsTypes] = useState<MeasurementDTO[]>(
    []
  );
  const [historyPregnancy, setHistoryPregnancy] =
    useState<PregnancyDTO | undefined>();
  const [measurements, setMeasurements] = useState<UserMeasurementDTO[]>([]);
  const [weight, setWeight] = useState<UserMeasurementDTO[]>([]);
  const [BMI, setBMI] = useState<Bmidto>();
  const [measurementId, setMeasurementId] = useState<MeasurementDTO>();
  const [symptomsTypes, setSymptomsTypes] = useState<SymptomDTO[]>([]);
  const [symptoms, setSymptoms] = useState<UserSymptomDTO[]>([]);
  const [symptomsId, setSymptomsId] = useState<SymptomDTO>();
  const deleteMutation = usePregnancyControllerDeleteMyPregnancy();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const { data: pregnancyData, refetch: pregnancyRefetch } =
    usePregnancyControllerGetMyPregnancy();
  const { data: symptomsData, refetch: symptomsRefetch } =
    useSymptomControllerGetMySymptomsByType(
      symptomsId ? `${symptomsId.id}` : '4'
    );
  const { data: pregnancyDetailsData, refetch: pregnancyDetailsRefetch } =
    usePregnancyControllerGetPregnancyDetails(week);
  const updateMutation = usePregnancyControllerUpdateMyPregnancy();

  useEffect(() => {
    if (pregnancyDetailsData) setDetails(pregnancyDetailsData.data);
  }, [pregnancyDetailsData]);

  useEffect(() => {
    if (symptomsData)
      setSymptoms(
        symptomsData.data.filter((item) =>
          historyPregnancy !== undefined
            ? moment(item.time).isSameOrAfter(pregnancy?.startDate, 'day') &&
              moment(item.time).isSameOrBefore(pregnancy?.endDate, 'day')
            : moment(item.time).isSameOrAfter(pregnancy?.startDate, 'day')
        )
      );
  }, [symptomsData]);

  useEffect(() => {
    if (pregnancyData) {
      setUserPregnancy(pregnancyData.data);
      setPregnancy(pregnancyData.data.filter((item) => !item.endDate)[0]);
    }
  }, [pregnancyData]);

  const getMeasurements = async (id?: number) => {
    try {
      const data = await measurementsControllerGetMyMeasurementByType(
        id !== undefined ? id.toString() : `${measurementId?.id}`
      );
      if (measurementId?.id !== 4) {
        if (id !== undefined)
          setWeight(
            data.data.filter((item) =>
              historyPregnancy !== undefined
                ? moment(item.time).isSameOrAfter(
                    pregnancy?.startDate,
                    'day'
                  ) &&
                  moment(item.time).isSameOrBefore(pregnancy?.endDate, 'day')
                : moment(item.time).isSameOrAfter(pregnancy?.startDate, 'day')
            )
          );
        else
          setMeasurements(
            data.data.filter((item) =>
              historyPregnancy !== undefined
                ? moment(item.time).isSameOrAfter(
                    pregnancy?.startDate,
                    'day'
                  ) &&
                  moment(item.time).isSameOrBefore(pregnancy?.endDate, 'day')
                : moment(item.time).isSameOrAfter(pregnancy?.startDate, 'day')
            )
          );
      }
    } catch (error) {}
  };

  const finishPregnancy = async () => {
    try {
      if (pregnancy !== undefined && typeof option !== 'number') {
        const resp1 = await updateMutation.mutateAsync({
          id: pregnancy.id,
          data: {
            startDate: pregnancy.startDate,
            endDate: birthDate.format(DateFormats.ISONoTime),
            comments: dialogComment,
            type: option,
          },
        });
        if (resp1 && option === UpdatePregnancyType.BIRTH) {
          toast(t('Updated'));
          const resp = await pregnancyControllerCreateMyKids({
            birthdate: birthDate.format(DateFormats.ISONoTime),
            userPregnancyId: pregnancy.id,
          });
          if (resp) {
            toast(t('ExamUpdated'), {
              onClose: () => setOpen(false),
              autoClose: 8000,
            });
            pregnancyRefetch();
            setSuccess(true);
          } else {
            toast(t('FailedAdding'), { containerId: 'deleted' });
          }
        } else if (resp1) {
          toast(t('Updated'));
          pregnancyRefetch();
          setOpen(false);
        } else {
          toast(t('FailedAdding'), { containerId: 'deleted' });
        }
      }
    } catch (e) {
      toast(t('FailedAdding'), { containerId: 'deleted' });
    }
  };

  const getPregnancyMeasurements = async () => {
    if (pregnancy) {
      const data = await pregnancyControllerGetMyPregnancyMeasurements(
        pregnancy.startDate
      );
      const getBMI = await pregnancyControllerGetMyBMI({
        weight: data.data[0].value,
        height: data.data[1].value,
      });
      setBMI(getBMI.data);
    }
  };

  const getAll = () => {
    const getPregnancyPromise = pregnancyControllerGetMyPregnancy();

    const getMeasurementsPromise =
      measurementsControllerGetMyMeasurementByType('4');

    const getSymptomsPromise = symptomControllerGetMySymptomsByType('1');

    const getSymtomsTypesPromise = symptomControllerGetSymptomTypes();

    const getMeasurementsTypesPromise =
      measurementsControllerGetMeasurementTypes();

    Promise.all([
      getMeasurementsTypesPromise,
      getMeasurementsPromise,
      getSymtomsTypesPromise,
      getSymptomsPromise,
      getPregnancyPromise,
    ]).then((values) => {
      setMeasurementsTypes(values[0].data);
      if (values[0].data[0].id !== 4) setMeasurementId(values[0].data[0]);
      setMeasurements(values[1].data);
      setSymptomsTypes(values[2].data);
      setSymptomsId(values[2].data[0]);
      setSymptoms(values[3].data);
      setUserPregnancy(values[4].data);
      setPregnancy(values[4].data.filter((item) => !item.endDate)[0]);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (historyPregnancy !== undefined) setPregnancy(historyPregnancy);
  }, [historyPregnancy]);

  useEffect(() => {
    if (pregnancy !== undefined) {
      setWeek(
        moment().year() > moment(pregnancy.startDate).year()
          ? Math.floor(
              (moment().dayOfYear() +
                moment(pregnancy.startDate).endOf('year').dayOfYear() -
                moment(pregnancy.startDate).dayOfYear()) /
                7
            ) + 1
          : Math.floor(
              (moment().dayOfYear() - moment(pregnancy.startDate).dayOfYear()) /
                7
            ) + 1
      );
      getMeasurements(8);
      getMeasurements();
      symptomsRefetch();
      getPregnancyMeasurements();
    }
  }, [pregnancy]);

  useEffect(() => {
    if (week > 41) setWeek(40);
    if (week !== 0) pregnancyDetailsRefetch();
  }, [week]);

  useEffect(() => {
    if (open === true) setSuccess(false);
  }, [open]);

  useEffect(() => {
    if (pregnancy !== undefined) getMeasurements();
  }, [measurementId]);

  useEffect(() => {
    if (pregnancy !== undefined) symptomsRefetch();
  }, [symptomsId]);

  if (loading) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
  return (
    <Fragment>
      {(userPregnancy.length === 0 ||
        userPregnancy.filter((item) => !item.endDate).length === 0) &&
      historyPregnancy === undefined ? (
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: isBelowXs ? 24 : 100,
          }}
        >
          <Box style={{ width: isBelowXs ? '95%' : '60%' }}>
            <PregnancyBox onSuccess={() => pregnancyRefetch()} />
          </Box>
        </Box>
      ) : (
        week > 0 &&
        pregnancy && (
          <Box
            style={{
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '0px 8px',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {historyPregnancy !== undefined && (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={() => {
                      setHistoryPregnancy(undefined);
                      setPregnancy(
                        userPregnancy.filter((item) => !item.endDate)[0]
                      );
                    }}
                  >
                    <ArrowBackIcon style={{ fontSize: 36, color: 'black' }} />
                  </IconButton>
                  <Typography style={{ color: Colors.Black }}>
                    {t(
                      userPregnancy.filter((item) => !item.endDate).length > 0
                        ? 'PregnancyBack1'
                        : 'PregnancyBack2'
                    )}
                  </Typography>
                </Box>
              )}
              {historyPregnancy === undefined ? (
                <IconButton onClick={() => setOpen(true)}>
                  <ListIcon
                    color="primary"
                    style={{ fontSize: 36, color: 'black' }}
                  />
                </IconButton>
              ) : (
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography style={{ color: Colors.Black }}>
                    {t('PregnancyDelete')}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      // setHistoryPregnancy(undefined);
                      // setPregnancy(
                      //   userPregnancy.filter(
                      //     (item) => item.UserPregnancy.length === 0
                      //   )[0]
                      // );
                      deleteMutation
                        .mutateAsync({ id: historyPregnancy.id })
                        .then((resp) => {
                          if (resp) {
                            pregnancyRefetch();
                            setHistoryPregnancy(undefined);
                            toast(t('Deleted'));
                          } else toast(t('Failed'), { containerId: 'deleted' });
                        });
                    }}
                  >
                    <DeleteIcon style={{ fontSize: 35 }} />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Grid container spacing={6}>
              <Grid
                item
                lg={5}
                md={12}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Paper
                  style={{
                    marginLeft: 8,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexGrow: 1,
                      justifyContent: 'center',
                    }}
                  >
                    <IconButton
                      onClick={() => week !== 1 && setWeek(week - 1)}
                      style={{ visibility: week === 1 ? 'hidden' : 'visible' }}
                    >
                      <DoubleArrowIcon
                        style={
                          isBelowXs
                            ? { transform: 'rotateY(180deg)', fontSize: 22 }
                            : { transform: 'rotateY(180deg)' }
                        }
                      />
                    </IconButton>
                    <Typography
                      style={{
                        margin: '16px 8px 24px 8px',
                        color: Colors.Black,
                        fontSize: isBelowXs ? 18 : 22,
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      {week > 40 ? '40+' : week}
                      {week <= 40 && 'η'} εβδομάδα εγκυμοσύνης
                    </Typography>
                    <IconButton
                      onClick={() => setWeek(week + 1)}
                      style={{ visibility: week > 40 ? 'hidden' : 'visible' }}
                    >
                      <DoubleArrowIcon
                        style={isBelowXs ? { fontSize: 22 } : {}}
                      />
                    </IconButton>
                  </Box>
                  <Typography className={classes.generalBox}>
                    {t('PregnancyInfo1')}
                  </Typography>
                  {details !== null &&
                    details
                      .filter((item) => item.type === type.fetus)
                      .map((item, i) => (
                        <Typography className={classes.box} key={i}>
                          {item.text}
                        </Typography>
                      ))}
                  <Box style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                  <Typography className={classes.generalBox}>
                    {t('PregnancyInfo2')}
                  </Typography>
                  {details !== null &&
                    details
                      .filter((item) => item.type === type.mother)
                      .map((item, i) => (
                        <Typography className={classes.box} key={i}>
                          {item.text}
                        </Typography>
                      ))}
                  <Box style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                  <Typography className={classes.generalBox}>
                    {t('PregnancyInfo3')}
                  </Typography>
                  {details !== null &&
                    details
                      .filter((item) => item.type === type.information)
                      .map((item, i) => (
                        <Typography className={classes.box} key={i}>
                          {item.text}
                        </Typography>
                      ))}
                  <Box style={{ border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                </Paper>
                <Paper
                  style={{
                    marginLeft: 8,
                    marginTop: 24,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box className={classes.right}>{t('Weight')}</Box>
                  <PregnancyMeasurements
                    onRefresh={() => getMeasurements(8)}
                    onChange={(item: MeasurementDTO) => setMeasurementId(item)}
                    activeMeasurement={measurementsTypes.filter(
                      (item) => item.id === 8
                    )}
                    rawData={weight}
                    weight={true}
                    bmi={BMI}
                    pregnancy={pregnancy}
                    week={week}
                  />
                </Paper>
              </Grid>
              <Grid
                item
                lg={7}
                md={12}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Paper>
                  <Box className={classes.right}>{t('Measurements')}</Box>
                  <PregnancyMeasurements
                    onRefresh={getMeasurements}
                    onChange={(item: MeasurementDTO) => setMeasurementId(item)}
                    activeMeasurement={measurementsTypes.filter(
                      (item) =>
                        item.id === 8 ||
                        item.id === 191 ||
                        item.id === 202 ||
                        item.id === 203 ||
                        item.id === 212 ||
                        item.id === 220 ||
                        item.id === 216 ||
                        item.id === 204 ||
                        item.id === 215 ||
                        item.id === 398 ||
                        item.id === 193 ||
                        item.id === 194 ||
                        item.id === 195 ||
                        item.id === 206 ||
                        item.id === 207
                    )}
                    rawData={measurements}
                    week={week}
                    pregnancy={pregnancy}
                  />
                </Paper>
                <Paper style={{ marginTop: 24 }}>
                  <Box className={classes.right}>{t('Symptoms')}</Box>
                  <PregnancySymptoms
                    onRefresh={symptomsRefetch}
                    onChange={(item: SymptomDTO) => setSymptomsId(item)}
                    activeSymptom={symptomsTypes.filter(
                      (item) =>
                        item.id < 4 ||
                        item.id === 5 ||
                        (item.id > 20 && item.id < 25) ||
                        item.id === 15 ||
                        item.id === 10
                    )}
                    rawData={symptoms}
                    pregnancy={pregnancy}
                  />
                </Paper>
                <Paper style={{ marginTop: 24 }}>
                  <ExpansionPanel defaultExpanded={true}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      className={classes.right}
                      classes={{
                        content: classes.content,
                        expandIcon: classes.color,
                      }}
                    >
                      <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>
                        {t('Exams')}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      style={{
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <PregnancyExams pregnancy={pregnancy} />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        )
      )}
      {userPregnancy.length > 0 && (
        <Grid item xs={12} style={{ marginTop: 50, minWidth: 480 }}>
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography>{t('PregnancyHistory')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
            >
              <Box style={{ width: '100%', overflow: 'auto' }}>
                <Table>
                  <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                    <TableRow>
                      {!isBelowXs && <TableCell padding="checkbox" />}
                      <TableCell style={{ color: '#FFFFFF' }}>
                        {t('Pregnancy')}
                      </TableCell>
                      <TableCell style={{ color: '#FFFFFF' }}>
                        {t('DeliveryDate')}
                      </TableCell>
                      <TableCell style={{ color: '#FFFFFF' }}>
                        {t('Type')}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userPregnancy
                      .filter((item) => item.endDate)
                      .map((row, i) => (
                        <TableRow key={i}>
                          {!isBelowXs && <TableCell padding="checkbox" />}
                          <TableCell>
                            {moment(row.startDate).format(DateFormats.GrNoTime)}
                          </TableCell>
                          <TableCell>
                            {moment(row.endDate).format(DateFormats.GrNoTime)}
                          </TableCell>
                          <TableCell>
                            {row.type === UpdatePregnancyType.BIRTH
                              ? t('Birth')
                              : row.type === UpdatePregnancyType.ABORTION
                              ? t('Abortion')
                              : row.type === UpdatePregnancyType.MISCARRIAGE
                              ? t('Miscarriage')
                              : t('Other')}
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            style={isBelowXs ? { padding: '0px 8px' } : {}}
                          >
                            <ArrowRightAltIcon
                              style={{
                                color: 'rgba(0, 0, 0, 0.58)',
                                fontSize: 32,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                window.scrollTo(0, 0);
                                setHistoryPregnancy(row);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      )}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setOption(0);
          setBirthDate(moment());
          setDialogComment('');
        }}
      >
        <DialogContent>
          {success === false ? (
            <Fragment>
              <Typography
                style={{
                  width: '100%',
                  textAlign: 'center',
                  marginBottom: 16,
                  fontSize: 22,
                }}
              >
                {t('PregnancyOptions')}
              </Typography>
              <StyledDatePicker
                fullWidth
                disableFuture
                value={birthDate}
                onChange={(date) => setBirthDate(date as Moment)}
                label={t(
                  option === UpdatePregnancyType.BIRTH
                    ? 'PregnancyBirthDate'
                    : 'EndDate'
                )}
                InputLabelProps={{ style: { width: '120%' } }}
                showTodayButton
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodaySharp />
                    </InputAdornment>
                  ),
                }}
              />
              <Select
                value={option}
                onChange={(e) =>
                  setOption(e.target.value as UpdatePregnancyType)
                }
                style={{ width: '100%', marginTop: 16 }}
              >
                <MenuItem value={0}>{t('PregnancyReason')}</MenuItem>
                {Object.values(UpdatePregnancyType).map((item, i) => (
                  <MenuItem value={item} key={i}>
                    {item === UpdatePregnancyType.BIRTH
                      ? t('Birth')
                      : item === UpdatePregnancyType.ABORTION
                      ? t('Abortion')
                      : item === UpdatePregnancyType.MISCARRIAGE
                      ? t('Miscarriage')
                      : t('OtherPregnancy')}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                value={dialogComment}
                label={t('Comments')}
                onChange={(e) => setDialogComment(e.target.value)}
                style={{ width: '100%' }}
              />
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 24,
                }}
              >
                <Button
                  style={{ width: '50%' }}
                  variant="contained"
                  color="primary"
                  onClick={finishPregnancy}
                  disabled={option === 0}
                >
                  {t('End')}
                </Button>
                <Button
                  style={{ width: '40%' }}
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    deleteMutation
                      .mutateAsync({
                        id: pregnancy!.id,
                      })
                      .then((resp) => {
                        if (resp) {
                          pregnancyRefetch();
                          setHistoryPregnancy(undefined);
                          toast(t('Deleted'));
                        } else toast(t('Failed'), { containerId: 'deleted' });
                      })
                  }
                >
                  {t('Delete')}
                </Button>
              </Box>
            </Fragment>
          ) : (
            success === true &&
            option === UpdatePregnancyType.BIRTH && (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '30px 0px',
                }}
              >
                <DoneIcon color="primary" style={{ fontSize: 100 }} />
                <Typography
                  style={{ marginTop: 16, fontSize: 20, fontWeight: 'bold' }}
                >
                  {t('KidBornSuccess')}
                </Typography>
              </Box>
            )
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
const useStyles = makeStyles(() => ({
  generalBox: {
    color: Colors.Black,
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 8,
    paddingLeft: 8,
  },
  box: {
    fontSize: 16,
    color: Colors.Black,
    padding: '0px 8px 8px 16px',
  },
  right: {
    width: '100%',
    backgroundColor: Colors.PRIMARY,
    color: Colors.IceWhite,
    textAlign: 'center',
    padding: '4px 0px',
    fontSize: 18,
    fontWeight: 'bold',
  },
  item: {
    fontSize: 14,
    fontWeight: 'bold',
    background: 'rgba(196, 196, 196, 0.27)',
    color: Colors.Black,
  },
  content: {
    margin: '4px 0px',
    justifyContent: 'center',
  },
  color: {
    color: Colors.White,
    padding: 0,
    marginRight: 20,
  },
}));
export const Pregnancy = hot(_Pregnancy);
