import { TextField, OutlinedTextFieldProps } from '@material-ui/core';
import React from 'react';
import { Field, ErrorMessage } from 'formik';
interface FormTextFieldProps extends OutlinedTextFieldProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
}
const _FormTextField = ({
  name,
  label,
  type = 'text',
  required = false,
  ...rest
}: FormTextFieldProps) => {
  return (
    <Field
      required={required}
      autoComplete="off"
      as={TextField}
      label={label}
      name={name}
      fullWidth
      type={type}
      helperText={<ErrorMessage name={name} />}
      {...rest}
    />
  );
};
export const FormTextField = _FormTextField;
