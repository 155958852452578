import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BirthdayIcon from 'assets/icons/Birthday.svg';
import { Colors } from 'res/Enums';
import {
  diagnosisControllerBeingOsteoDiagnosis,
  useDiagnosisControllerGetOsteoData,
} from 'gen/client';

interface Group {
  estrogen: number;
  race: number;
  arthritis: number;
  fracture: number;
  weight?: number;
}
export const OsteoDiagnosis = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, isLoading } = useDiagnosisControllerGetOsteoData();
  const [groups, setGroups] = useState<Group>({
    estrogen: 0,
    race: 5,
    arthritis: 0,
    fracture: 0,
    weight: data?.data.weight,
  });
  const [result, setResult] = useState<String>();
  const handleSend = async () => {
    try {
      const res = await diagnosisControllerBeingOsteoDiagnosis(groups);
      setResult(res.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => setGroups({ ...groups, weight: data?.data.weight }), [data]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box className={classes.main}>
      <Typography style={{ fontSize: 14, marginBottom: 10 }}>
        Η οστεοπόρωση είναι μεταβολικό νόσημα που εξαρτάται άμεσα από την ηλικία
        και επηρεάζει εκατομμύρια ανθρώπους σε όλο τον κόσμο. Εάν είσαι γυναίκα,
        χρησιμοποιώντας το παρακάτω ερωτηματολόγιο μπορείς να διαπιστώσεις εάν
        είσαι σε κίνδυνο ή όχι. Εάν ισχύουν κάποιοι από αυτούς τους παράγοντες
        κινδύνου στην περίπτωσή σου, είναι σκόπιμο να συζητήσεις για την υγεία
        των οστών σου με τον ιατρό σου, ο οποίος ενδέχεται να προτείνει
        περαιτέρω εξετάσεις για την αξιολόγηση της κατάστασής σου. Σε αντίθετη
        περίπτωση είναι σκόπιμο να διατηρείς έναν υγιεινό τρόπο ζωής και να
        συνεχίσεις να ελέγχεις τους παράγοντες κινδύνου στο μέλλον.
      </Typography>
      <Typography
        variant="h6"
        style={{
          marginBottom: 20,
          textAlign: 'center',
          backgroundColor: Colors.White,
        }}
      >
        Συμπλήρωσε τα παρακάτω στοιχεία για να ενημερωθείς για το επίπεδο του
        κινδύνου οστεοπόρωσης.
      </Typography>
      <Chip
        style={{ marginBottom: 5, maxWidth: 150 }}
        avatar={
          <Avatar>
            <img src={BirthdayIcon} alt="" style={{ width: '140%' }} />
          </Avatar>
        }
        color="primary"
        label={`${t('Age')}: ${data?.data.age}`}
      />
      <TextField
        style={{ marginBottom: 10, maxWidth: 150 }}
        color="primary"
        label={t('Weight') + ' kg'}
        defaultValue={data?.data.weight}
        onChange={(val) =>
          setGroups({ ...groups, weight: parseInt(val.target.value) })
        }
      />
      <FormControl>
        <FormLabel>{t('Estrogen')}</FormLabel>
        <RadioGroup
          value={groups.estrogen}
          onChange={(val) =>
            setGroups({ ...groups, estrogen: parseInt(val.target.value) })
          }
        >
          <FormControlLabel
            value={0}
            control={<Radio color="primary" />}
            label={t('Yes')}
          />
          <FormControlLabel
            value={1}
            control={<Radio color="primary" />}
            label={t('NoPrior')}
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel>{t('Race')}</FormLabel>
        <RadioGroup
          value={groups.race}
          onChange={(val) =>
            setGroups({ ...groups, race: parseInt(val.target.value) })
          }
        >
          <FormControlLabel
            value={0}
            control={<Radio color="primary" />}
            label={t('No')}
          />
          <FormControlLabel
            value={5}
            control={<Radio color="primary" />}
            label={t('Yes')}
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel>{t('RArthritis')}</FormLabel>
        <RadioGroup
          value={groups.arthritis}
          onChange={(val) =>
            setGroups({ ...groups, arthritis: parseInt(val.target.value) })
          }
        >
          <FormControlLabel
            value={4}
            control={<Radio color="primary" />}
            label={t('Yes')}
          />
          <FormControlLabel
            value={0}
            control={<Radio color="primary" />}
            label={t('No')}
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel>{t('FractureHistory')}</FormLabel>
        <RadioGroup
          value={groups.fracture}
          onChange={(val) =>
            setGroups({ ...groups, fracture: parseInt(val.target.value) })
          }
        >
          <FormControlLabel
            value={0}
            control={<Radio color="primary" />}
            label={t('NonTraumatic0')}
          />
          <FormControlLabel
            value={4}
            control={<Radio color="primary" />}
            label={t('NonTraumatic1')}
          />
          <FormControlLabel
            value={8}
            control={<Radio color="primary" />}
            label={t('NonTraumatic2')}
          />
          <FormControlLabel
            value={12}
            control={<Radio color="primary" />}
            label={t('NonTraumatic3')}
          />
        </RadioGroup>
      </FormControl>
      <Box>
        <Button
          color="primary"
          variant="contained"
          disabled={groups.weight ? false : true}
          onClick={handleSend}
        >
          {t('StartDiagnosis')}
        </Button>
      </Box>
      <Dialog open={result !== undefined} onClose={() => setResult(undefined)}>
        <DialogTitle>Αποτελέσμα αυτοδιάγνωσης</DialogTitle>
        <DialogContent>{`Σύμφωνα με τις απαντήσεις σου ο κίνδυνος εγκεφαλικού επεισοδίου είναι ${t(
          result as string
        )}`}</DialogContent>
      </Dialog>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
