import {
  Box,
  makeStyles,
  Theme,
  Grid,
  Paper,
  Checkbox,
  Typography,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import React, { useState, useEffect, useReducer } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { ItemHeader } from './Profile/EditConcise';
import { StyledDatePicker } from 'components/DatePicker';
import moment, { Moment } from 'moment';
import { DateFormats, Colors } from 'res/Enums';
import { CalendarTodaySharp, ChevronRight } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { isNull, isUndefined } from 'util';
import {
  CreateCovidType,
  useCovidControllerCreateMyCovid,
  useCovidControllerDeleteMyCovid,
  useCovidControllerGetMyCovid,
  useCovidControllerUpdateMyCovid,
  useFitnessControllerGetMyFitness,
  useMeControllerGetMe,
  UserCovidDTO,
  UserCovidDTOType,
  UserDto,
  UserFitnessDTO,
  UserSituationDTO,
  UserSymptomDTO,
  useSituationControllerGetMySituation,
  useSymptomControllerGetMySymptomByWeek,
} from 'gen/client';

interface CovidBoxes {
  checkbox1: boolean;
  checkbox2: boolean;
  checkbox3: boolean;
  checkbox4: boolean;
  checkbox5: boolean;
  checkbox6: boolean;
  checkbox7: boolean;
  checkbox8: boolean;
  checkbox9: boolean;
}

interface CovidDates {
  dates1: Moment | null;
  dates2: Moment | null;
  dates3: Moment | null;
  dates4: Moment | null;
  dates5: Moment | null;
  dates6: Moment | null;
  dates7: Moment | null;
  dates8: Moment | null;
  dates9: Moment | null;
}

const _Covid = () => {
  const [covid, setCovid] = useState<UserCovidDTO[]>([]);
  const [symptoms, setSymptoms] = useState<UserSymptomDTO[]>([]);
  const [fitness, setFitness] = useState<UserFitnessDTO[]>([]);
  const [latest, setLatest] = useState<UserCovidDTOType | undefined>(undefined);
  const [me, setMe] = useState<UserDto>();
  const [situation, setSituation] = useState<UserSituationDTO[]>([]);
  const [open, setOpen] = useReducer(
    (state: CovidBoxes, action: CovidBoxes) => {
      return {
        checkbox1: action.checkbox1,
        checkbox2: action.checkbox2,
        checkbox3: action.checkbox3,
        checkbox4: action.checkbox4,
        checkbox5: action.checkbox5,
        checkbox6: action.checkbox6,
        checkbox7: action.checkbox7,
        checkbox8: action.checkbox8,
        checkbox9: action.checkbox9,
      };
    },
    {
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      checkbox7: false,
      checkbox8: false,
      checkbox9: false,
    }
  );
  const [dates, setDates] = useReducer(
    (state: CovidDates, action: CovidDates) => {
      return {
        dates1: action.dates1,
        dates2: action.dates2,
        dates3: action.dates3,
        dates4: action.dates4,
        dates5: action.dates5,
        dates6: action.dates6,
        dates7: action.dates7,
        dates8: action.dates8,
        dates9: action.dates9,
      };
    },
    {
      dates1: null,
      dates2: null,
      dates3: null,
      dates4: null,
      dates5: null,
      dates6: null,
      dates7: null,
      dates8: null,
      dates9: null,
    }
  );
  const [checkbox, setCheckbox] = useReducer(
    (state: CovidBoxes, action: CovidBoxes) => {
      return {
        checkbox1: action.checkbox1,
        checkbox2: action.checkbox2,
        checkbox3: action.checkbox3,
        checkbox4: action.checkbox4,
        checkbox5: action.checkbox5,
        checkbox6: action.checkbox6,
        checkbox7: action.checkbox7,
        checkbox8: action.checkbox8,
        checkbox9: action.checkbox9,
      };
    },
    {
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      checkbox7: false,
      checkbox8: false,
      checkbox9: false,
    }
  );
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    data: covidData,
    isLoading: covidLoading,
    refetch: covidRefetch,
  } = useCovidControllerGetMyCovid();
  const { data: symptomsData, isLoading: symptomsLoading } =
    useSymptomControllerGetMySymptomByWeek(moment().format(DateFormats.ISO));
  const { data: situationData, isLoading: situationLoading } =
    useSituationControllerGetMySituation();
  const { data: fitnessData, isLoading: fitnessLoading } =
    useFitnessControllerGetMyFitness();
  const { data: meData, isLoading: meLoading } = useMeControllerGetMe();
  const deleteMutation = useCovidControllerDeleteMyCovid();
  const updateMutation = useCovidControllerUpdateMyCovid();
  const createMutation = useCovidControllerCreateMyCovid();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (covidData) {
      setCovid(covidData.data);
      setCheckbox({
        checkbox1:
          covidData.data.filter((item) => item.type === 'type1').length > 0,
        checkbox2:
          covidData.data.filter((item) => item.type === 'type2').length > 0,
        checkbox3:
          covidData.data.filter((item) => item.type === 'type3').length > 0,
        checkbox4:
          covidData.data.filter((item) => item.type === 'type4').length > 0,
        checkbox5:
          covidData.data.filter((item) => item.type === 'type5').length > 0,
        checkbox6:
          covidData.data.filter((item) => item.type === 'type6').length > 0,
        checkbox7:
          covidData.data.filter((item) => item.type === 'type7').length > 0,
        checkbox8:
          covidData.data.filter((item) => item.type === 'type8').length > 0,
        checkbox9:
          covidData.data.filter((item) => item.type === 'type9').length > 0,
      });
      setDates({
        dates1: !isUndefined(
          covidData.data.filter((item) => item.type === 'type1')[0]?.date ??
            undefined
        )
          ? moment(
              covidData.data.filter((item) => item.type === 'type1')[0].date
            )
          : null,
        dates4: !isUndefined(
          covidData.data.filter((item) => item.type === 'type2')[0]?.date ??
            undefined
        )
          ? moment(
              covidData.data.filter((item) => item.type === 'type2')[0].date
            )
          : null,
        dates5: !isUndefined(
          covidData.data.filter((item) => item.type === 'type3')[0]?.date ??
            undefined
        )
          ? moment(
              covidData.data.filter((item) => item.type === 'type3')[0].date
            )
          : null,
        dates2: !isUndefined(
          covidData.data.filter((item) => item.type === 'type4')[0]?.date ??
            undefined
        )
          ? moment(
              covidData.data.filter((item) => item.type === 'type4')[0].date
            )
          : null,
        dates3: !isUndefined(
          covidData.data.filter((item) => item.type === 'type5')[0]?.date ??
            undefined
        )
          ? moment(
              covidData.data.filter((item) => item.type === 'type5')[0].date
            )
          : null,
        dates6: !isUndefined(
          covidData.data.filter((item) => item.type === 'type6')[0]?.date ??
            undefined
        )
          ? moment(
              covidData.data.filter((item) => item.type === 'type6')[0].date
            )
          : null,
        dates7: !isUndefined(
          covidData.data.filter((item) => item.type === 'type7')[0]?.date ??
            undefined
        )
          ? moment(
              covidData.data.filter((item) => item.type === 'type7')[0].date
            )
          : null,
        dates8: !isUndefined(
          covidData.data.filter((item) => item.type === 'type8')[0]?.date ??
            undefined
        )
          ? moment(
              covidData.data.filter((item) => item.type === 'type8')[0].date
            )
          : null,
        dates9: !isUndefined(
          covidData.data.filter((item) => item.type === 'type9')[0]?.date ??
            undefined
        )
          ? moment(
              covidData.data.filter((item) => item.type === 'type9')[0].date
            )
          : null,
      });
    }
  }, [covidData]);

  useEffect(() => {
    if (situationData) setSituation(situationData.data);
  }, [situationData]);

  useEffect(() => {
    if (symptomsData) setSymptoms(symptomsData.data);
  }, [symptomsData]);

  useEffect(() => {
    if (fitnessData) setFitness(filtered(fitnessData.data));
  }, [fitnessData]);

  useEffect(() => {
    if (meData) setMe(meData.data);
  }, [meData]);

  const filtered = (data: UserFitnessDTO[]) => {
    return data.filter((item) =>
      moment(item.time).isBetween(
        moment().subtract(15, 'days').endOf('day'),
        moment().add(1, 'days').startOf('day')
      )
    );
  };

  const deleteCovid = async (id: string) => {
    try {
      const resp = await deleteMutation.mutateAsync({ id });
      if (resp) {
        toast(t('Deleted'));
        covidRefetch();
        setDates(
          id === 'type1'
            ? { ...dates, dates1: null }
            : id === 'type2'
            ? { ...dates, dates4: null }
            : id === 'type3'
            ? { ...dates, dates5: null }
            : id === 'type4'
            ? { ...dates, dates2: null }
            : id === 'type5'
            ? { ...dates, dates3: null }
            : id === 'type6'
            ? { ...dates, dates6: null }
            : id === 'type7'
            ? { ...dates, dates7: null }
            : id === 'type8'
            ? { ...dates, dates8: null }
            : { ...dates, dates9: null }
        );
      } else {
        toast(t('DeleteFailed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('DeleteFailed'), { containerId: 'deleted' });
    }
  };

  const updateCovid = async (id: string, date: string) => {
    try {
      const resp = await updateMutation.mutateAsync({
        id,
        data: { date: date },
      });
      if (resp) {
        toast(t('Updated'));
        covidRefetch();
      } else {
        toast(t('EditFailed'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const addCovid = async (id: number) => {
    try {
      const resp = await createMutation.mutateAsync({
        data: {
          date:
            Object.values(dates)[id - 1]?.format(DateFormats.ISO) ?? undefined,
          type:
            id === 1
              ? CreateCovidType.type1
              : id === 2
              ? CreateCovidType.type4
              : id === 3
              ? CreateCovidType.type5
              : id === 4
              ? CreateCovidType.type2
              : id === 5
              ? CreateCovidType.type3
              : id === 6
              ? CreateCovidType.type6
              : id === 7
              ? CreateCovidType.type7
              : id === 8
              ? CreateCovidType.type8
              : CreateCovidType.type9,
        },
      });
      if (resp) {
        toast(t('Success'));
        covidRefetch();
      } else toast('Failed', { containerId: 'deleted' });
    } catch (e) {
      toast(t('Error'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (!isUndefined(latest)) {
      if (
        (latest === UserCovidDTOType.type1 && !isNull(dates.dates1)) ||
        (latest === UserCovidDTOType.type2 && !isNull(dates.dates4)) ||
        (latest === UserCovidDTOType.type3 && !isNull(dates.dates5)) ||
        (latest === UserCovidDTOType.type4 && !isNull(dates.dates2)) ||
        (latest === UserCovidDTOType.type5 && !isNull(dates.dates3)) ||
        (latest === UserCovidDTOType.type6 && !isNull(dates.dates6)) ||
        (latest === UserCovidDTOType.type7 && !isNull(dates.dates7)) ||
        (latest === UserCovidDTOType.type8 && !isNull(dates.dates8)) ||
        (latest === UserCovidDTOType.type9 && !isNull(dates.dates9))
      )
        addCovid(
          latest === UserCovidDTOType.type1
            ? 1
            : latest === UserCovidDTOType.type2
            ? 4
            : latest === UserCovidDTOType.type3
            ? 5
            : latest === UserCovidDTOType.type4
            ? 2
            : latest === UserCovidDTOType.type5
            ? 3
            : latest === UserCovidDTOType.type6
            ? 6
            : latest === UserCovidDTOType.type7
            ? 7
            : latest === UserCovidDTOType.type8
            ? 8
            : 9
        );
    }
  }, [checkbox]);

  useEffect(() => {
    if (!isUndefined(latest)) {
      if (latest === UserCovidDTOType.type1) {
        setCheckbox({ ...checkbox, checkbox1: true });
      } else if (latest === UserCovidDTOType.type2)
        setCheckbox({ ...checkbox, checkbox2: true });
      else if (latest === UserCovidDTOType.type3)
        setCheckbox({ ...checkbox, checkbox3: true });
      else if (latest === UserCovidDTOType.type4)
        setCheckbox({ ...checkbox, checkbox4: true });
      else if (latest === UserCovidDTOType.type5)
        setCheckbox({ ...checkbox, checkbox5: true });
      else if (latest === UserCovidDTOType.type6)
        setCheckbox({ ...checkbox, checkbox6: true });
      else if (latest === UserCovidDTOType.type7)
        setCheckbox({ ...checkbox, checkbox7: true });
      else if (latest === UserCovidDTOType.type8)
        setCheckbox({ ...checkbox, checkbox8: true });
      else if (latest === UserCovidDTOType.type9)
        setCheckbox({ ...checkbox, checkbox9: true });
    }
  }, [latest]);

  useEffect(() => {
    if (checkbox.checkbox2 === false && dates.dates4) {
      setCheckbox({ ...checkbox, checkbox2: true });
      addCovid(4);
    } else if (checkbox.checkbox1 === false && dates.dates1) {
      setCheckbox({ ...checkbox, checkbox1: true });
      addCovid(1);
    } else if (checkbox.checkbox3 === false && dates.dates5) {
      setCheckbox({ ...checkbox, checkbox3: true });
      addCovid(5);
    } else if (checkbox.checkbox4 === false && dates.dates2) {
      setCheckbox({ ...checkbox, checkbox4: true });
      addCovid(2);
    } else if (checkbox.checkbox5 === false && dates.dates3) {
      setCheckbox({ ...checkbox, checkbox5: true });
      addCovid(3);
    } else if (checkbox.checkbox6 === false && dates.dates6) {
      setCheckbox({ ...checkbox, checkbox6: true });
      addCovid(6);
    } else if (checkbox.checkbox7 === false && dates.dates7) {
      setCheckbox({ ...checkbox, checkbox7: true });
      addCovid(7);
    } else if (checkbox.checkbox8 === false && dates.dates8) {
      setCheckbox({ ...checkbox, checkbox8: true });
      addCovid(8);
    } else if (checkbox.checkbox9 === false && dates.dates9) {
      setCheckbox({ ...checkbox, checkbox9: true });
      addCovid(9);
    }
  }, [dates]);

  useEffect(() => {
    if (
      !covidLoading &&
      !situationLoading &&
      !fitnessLoading &&
      !meLoading &&
      !symptomsLoading
    )
      setLoading(false);
  }, [
    covidLoading,
    situationLoading,
    fitnessLoading,
    meLoading,
    symptomsLoading,
  ]);

  if (loading === true) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
  return (
    <Grid container spacing={1} justify="center">
      <Grid item xs={12} md={6} lg={4}>
        <ItemHeader title={t('Covid1')} />
        <Paper className={classes.paper}>
          {((!isUndefined(me) && me.age > 60) ||
            situation.filter(
              (item) =>
                item.situation.id === 5 ||
                item.situation.id === 7 ||
                item.situation.id === 8 ||
                item.situation.id === 9 ||
                item.situation.id === 10 ||
                item.situation.id === 11 ||
                item.situation.id === 12 ||
                item.situation.id === 13 ||
                item.situation.id === 14 ||
                item.situation.id === 15 ||
                item.situation.id === 16 ||
                item.situation.id === 17 ||
                item.situation.id === 18
            ).length > 0) && (
            <Box style={{ display: 'flex', width: '100%', marginLeft: 4 }}>
              <Box
                style={{
                  color: '#787878',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: isBelowXs ? 14 : 16,
                }}
              >
                {t('Covid13')}
              </Box>
            </Box>
          )}
          <Box className={classes.box} style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              color="primary"
              style={isBelowXs ? { padding: '9px 0px' } : {}}
              checked={checkbox.checkbox1}
              onChange={(e) => {
                if (e.target.checked) setLatest(UserCovidDTOType.type1);
                else {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox1: false });
                  deleteCovid('type1');
                }
              }}
            />
            <Typography
              className={classes.typo}
              style={
                isBelowXs ? { fontSize: 13, flexGrow: 1 } : { flexGrow: 1 }
              }
            >
              {t('Covid2')}
            </Typography>
            <StyledDatePicker
              value={dates.dates1}
              open={
                (checkbox.checkbox1 && isNull(dates.dates1)) || open.checkbox1
              }
              onClose={() => {
                if (checkbox.checkbox1 && isNull(dates.dates1)) {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox1: false });
                } else setOpen({ ...open, checkbox1: false });
              }}
              style={{
                display: 'none',
              }}
              format={DateFormats.GrNoTime}
              onChange={(date) => {
                setDates({ ...dates, dates1: date as Moment });
                setCheckbox({ ...checkbox });
                if (
                  covid.filter((item) => item.type === 'type1').length > 0 &&
                  checkbox.checkbox1
                )
                  updateCovid(
                    'type1',
                    date?.format(DateFormats.ISO) ??
                      moment().format(DateFormats.ISO)
                  );
              }}
              animateYearScrolling
              disableFuture
              autoOk
              autoFocus
            />
            {isNull(dates.dates1) ? (
              <Box
                style={{
                  height: '100%',
                  display: 'flex',
                  padding: '8px 0px 8px 8px',
                }}
              >
                <CalendarTodaySharp style={{ fontSize: 25 }} />
              </Box>
            ) : (
              <Typography
                onClick={() => setOpen({ ...open, checkbox1: true })}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {dates.dates1?.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Box>
          <Box className={classes.box} style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              color="primary"
              style={isBelowXs ? { padding: '9px 0px' } : {}}
              checked={checkbox.checkbox2}
              onChange={(e) => {
                if (e.target.checked) setLatest(UserCovidDTOType.type2);
                else {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox2: false });
                  deleteCovid('type2');
                }
              }}
            />
            <Typography
              className={classes.typo}
              style={
                isBelowXs ? { fontSize: 13, flexGrow: 1 } : { flexGrow: 1 }
              }
            >
              {t('Covid3')}
            </Typography>
            <StyledDatePicker
              value={dates.dates4}
              open={
                (checkbox.checkbox2 && isNull(dates.dates4)) || open.checkbox2
              }
              onClose={() => {
                if (checkbox.checkbox2 && isNull(dates.dates4)) {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox2: false });
                } else setOpen({ ...open, checkbox2: false });
              }}
              style={{
                display: 'none',
              }}
              format={DateFormats.GrNoTime}
              onChange={(date) => {
                setDates({ ...dates, dates4: date as Moment });
                setCheckbox({ ...checkbox });
                if (
                  covid.filter((item) => item.type === 'type2').length > 0 &&
                  checkbox.checkbox2
                )
                  updateCovid(
                    'type2',
                    date?.format(DateFormats.ISO) ??
                      moment().format(DateFormats.ISO)
                  );
              }}
              animateYearScrolling
              disableFuture
              autoOk
              autoFocus
            />
            {isNull(dates.dates4) ? (
              <Box
                style={{
                  height: '100%',
                  display: 'flex',
                  padding: '8px 0px 8px 8px',
                }}
              >
                <CalendarTodaySharp style={{ fontSize: 25 }} />
              </Box>
            ) : (
              <Typography
                onClick={() => setOpen({ ...open, checkbox2: true })}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {dates.dates4?.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Box>
          <Box className={classes.box} style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              color="primary"
              style={isBelowXs ? { padding: '9px 0px' } : {}}
              checked={checkbox.checkbox3}
              onChange={(e) => {
                if (e.target.checked) setLatest(UserCovidDTOType.type3);
                else {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox3: false });
                  deleteCovid('type3');
                }
              }}
            />
            <Typography
              className={classes.typo}
              style={
                isBelowXs ? { fontSize: 13, flexGrow: 1 } : { flexGrow: 1 }
              }
            >
              {t('Covid4')}
            </Typography>
            <StyledDatePicker
              value={dates.dates5}
              open={
                (checkbox.checkbox3 && isNull(dates.dates5)) || open.checkbox3
              }
              onClose={() => {
                if (checkbox.checkbox3 && isNull(dates.dates5)) {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox3: false });
                } else setOpen({ ...open, checkbox3: false });
              }}
              style={{
                display: 'none',
              }}
              format={DateFormats.GrNoTime}
              onChange={(date) => {
                setDates({ ...dates, dates5: date as Moment });
                setCheckbox({ ...checkbox });
                if (
                  covid.filter((item) => item.type === 'type3').length > 0 &&
                  checkbox.checkbox3
                )
                  updateCovid(
                    'type3',
                    date?.format(DateFormats.ISO) ??
                      moment().format(DateFormats.ISO)
                  );
              }}
              animateYearScrolling
              disableFuture
              autoOk
              autoFocus
            />
            {isNull(dates.dates5) ? (
              <Box
                style={{
                  height: '100%',
                  display: 'flex',
                  padding: '8px 0px 8px 8px',
                }}
              >
                <CalendarTodaySharp style={{ fontSize: 25 }} />
              </Box>
            ) : (
              <Typography
                onClick={() => setOpen({ ...open, checkbox3: true })}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {dates.dates5?.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Box>
          <Box className={classes.box} style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              color="primary"
              style={isBelowXs ? { padding: '9px 0px' } : {}}
              checked={checkbox.checkbox6}
              onChange={(e) => {
                if (e.target.checked) setLatest(UserCovidDTOType.type6);
                else {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox6: false });
                  deleteCovid('type6');
                }
              }}
            />
            <Typography
              className={classes.typo}
              style={
                isBelowXs ? { fontSize: 13, flexGrow: 1 } : { flexGrow: 1 }
              }
            >
              {t('Covid14')}
            </Typography>
            <StyledDatePicker
              value={dates.dates6}
              open={
                (checkbox.checkbox6 && isNull(dates.dates6)) || open.checkbox6
              }
              onClose={() => {
                if (checkbox.checkbox6 && isNull(dates.dates6)) {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox6: false });
                } else setOpen({ ...open, checkbox6: false });
              }}
              style={{
                display: 'none',
              }}
              format={DateFormats.GrNoTime}
              onChange={(date) => {
                setDates({ ...dates, dates6: date as Moment });
                setCheckbox({ ...checkbox });
                if (
                  covid.filter((item) => item.type === 'type6').length > 0 &&
                  checkbox.checkbox6
                )
                  updateCovid(
                    'type6',
                    date?.format(DateFormats.ISO) ??
                      moment().format(DateFormats.ISO)
                  );
              }}
              animateYearScrolling
              disableFuture
              autoOk
              autoFocus
            />
            {isNull(dates.dates6) ? (
              <Box
                style={{
                  height: '100%',
                  display: 'flex',
                  padding: '8px 0px 8px 8px',
                }}
              >
                <CalendarTodaySharp style={{ fontSize: 25 }} />
              </Box>
            ) : (
              <Typography
                onClick={() => setOpen({ ...open, checkbox6: true })}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {dates.dates6?.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Box>
          <Box className={classes.box} style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              color="primary"
              style={isBelowXs ? { padding: '9px 0px' } : {}}
              checked={checkbox.checkbox7}
              onChange={(e) => {
                if (e.target.checked) setLatest(UserCovidDTOType.type7);
                else {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox7: false });
                  deleteCovid('type7');
                }
              }}
            />
            <Typography
              className={classes.typo}
              style={
                isBelowXs ? { fontSize: 13, flexGrow: 1 } : { flexGrow: 1 }
              }
            >
              {t('Covid15')}
            </Typography>
            <StyledDatePicker
              value={dates.dates7}
              open={
                (checkbox.checkbox7 && isNull(dates.dates7)) || open.checkbox7
              }
              onClose={() => {
                if (checkbox.checkbox7 && isNull(dates.dates7)) {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox7: false });
                } else setOpen({ ...open, checkbox7: false });
              }}
              style={{
                display: 'none',
              }}
              format={DateFormats.GrNoTime}
              onChange={(date) => {
                setDates({ ...dates, dates7: date as Moment });
                setCheckbox({ ...checkbox });
                if (
                  covid.filter((item) => item.type === 'type7').length > 0 &&
                  checkbox.checkbox7
                )
                  updateCovid(
                    'type7',
                    date?.format(DateFormats.ISO) ??
                      moment().format(DateFormats.ISO)
                  );
              }}
              animateYearScrolling
              disableFuture
              autoOk
              autoFocus
            />
            {isNull(dates.dates7) ? (
              <Box
                style={{
                  height: '100%',
                  display: 'flex',
                  padding: '8px 0px 8px 8px',
                }}
              >
                <CalendarTodaySharp style={{ fontSize: 25 }} />
              </Box>
            ) : (
              <Typography
                onClick={() => setOpen({ ...open, checkbox7: true })}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {dates.dates7?.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Box>
          <Box className={classes.box} style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              color="primary"
              style={isBelowXs ? { padding: '9px 0px' } : {}}
              checked={checkbox.checkbox8}
              onChange={(e) => {
                if (e.target.checked) setLatest(UserCovidDTOType.type8);
                else {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox8: false });
                  deleteCovid('type8');
                }
              }}
            />
            <Typography
              className={classes.typo}
              style={
                isBelowXs ? { fontSize: 13, flexGrow: 1 } : { flexGrow: 1 }
              }
            >
              {t('Covid16')}
            </Typography>
            <StyledDatePicker
              value={dates.dates8}
              open={
                (checkbox.checkbox8 && isNull(dates.dates8)) || open.checkbox8
              }
              onClose={() => {
                if (checkbox.checkbox8 && isNull(dates.dates8)) {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox8: false });
                } else setOpen({ ...open, checkbox8: false });
              }}
              style={{
                display: 'none',
              }}
              format={DateFormats.GrNoTime}
              onChange={(date) => {
                setDates({ ...dates, dates8: date as Moment });
                setCheckbox({ ...checkbox });
                if (
                  covid.filter((item) => item.type === 'type8').length > 0 &&
                  checkbox.checkbox8
                )
                  updateCovid(
                    'type8',
                    date?.format(DateFormats.ISO) ??
                      moment().format(DateFormats.ISO)
                  );
              }}
              animateYearScrolling
              disableFuture
              autoOk
              autoFocus
            />
            {isNull(dates.dates8) ? (
              <Box
                style={{
                  height: '100%',
                  display: 'flex',
                  padding: '8px 0px 8px 8px',
                }}
              >
                <CalendarTodaySharp style={{ fontSize: 25 }} />
              </Box>
            ) : (
              <Typography
                onClick={() => setOpen({ ...open, checkbox8: true })}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {dates.dates8?.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Box>
          <Box className={classes.box} style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              color="primary"
              style={isBelowXs ? { padding: '9px 0px' } : {}}
              checked={checkbox.checkbox9}
              onChange={(e) => {
                if (e.target.checked) setLatest(UserCovidDTOType.type9);
                else {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox9: false });
                  deleteCovid('type9');
                }
              }}
            />
            <Typography
              className={classes.typo}
              style={
                isBelowXs ? { fontSize: 13, flexGrow: 1 } : { flexGrow: 1 }
              }
            >
              {t('Covid17')}
            </Typography>
            <StyledDatePicker
              value={dates.dates9}
              open={
                (checkbox.checkbox9 && isNull(dates.dates9)) || open.checkbox9
              }
              onClose={() => {
                if (checkbox.checkbox9 && isNull(dates.dates9)) {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox9: false });
                } else setOpen({ ...open, checkbox9: false });
              }}
              style={{
                display: 'none',
              }}
              format={DateFormats.GrNoTime}
              onChange={(date) => {
                setDates({ ...dates, dates9: date as Moment });
                setCheckbox({ ...checkbox });
                if (
                  covid.filter((item) => item.type === 'type9').length > 0 &&
                  checkbox.checkbox9
                )
                  updateCovid(
                    'type9',
                    date?.format(DateFormats.ISO) ??
                      moment().format(DateFormats.ISO)
                  );
              }}
              animateYearScrolling
              disableFuture
              autoOk
              autoFocus
            />
            {isNull(dates.dates9) ? (
              <Box
                style={{
                  height: '100%',
                  display: 'flex',
                  padding: '8px 0px 8px 8px',
                }}
              >
                <CalendarTodaySharp style={{ fontSize: 25 }} />
              </Box>
            ) : (
              <Typography
                onClick={() => setOpen({ ...open, checkbox9: true })}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {dates.dates9?.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ItemHeader title={t('Covid12')} />
        <Paper className={classes.paper} style={{ alignItems: 'flex-start' }}>
          <Grid container>
            {symptoms.filter((item) => item.symptom.id === 12).length > 0 && (
              <Grid item xs={12}>
                <SymptomPaper
                  symptom={symptoms.filter((item) => item.symptom.id === 12)[0]}
                  name="Fever"
                  id={12}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <SymptomPaper
                symptom={symptoms.filter((item) => item.symptom.id === 13)[0]}
                name="Dyspnea"
                id={13}
              />
            </Grid>
            <Grid item xs={12}>
              <SymptomPaper
                symptom={symptoms.filter((item) => item.symptom.id === 18)[0]}
                name="SoreThroat"
                id={18}
              />
            </Grid>
            <Grid item xs={12}>
              <SymptomPaper
                symptom={symptoms.filter((item) => item.symptom.id === 16)[0]}
                name="Ageusia"
                id={16}
              />
            </Grid>
            <Grid item xs={12}>
              <SymptomPaper
                symptom={symptoms.filter((item) => item.symptom.id === 17)[0]}
                name="Anosmia"
                id={17}
              />
            </Grid>
          </Grid>
          <Box style={{ display: 'flex', width: '100%' }}>
            <Grid container>
              <Grid item xs={11} style={{ display: 'flex', width: '100%' }}>
                <Typography
                  style={{
                    color: Colors.Black,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    marginLeft: 27,
                  }}
                >
                  {t('OtherSymptoms')}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Box
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    style={
                      isBelowXs
                        ? { padding: 8, paddingLeft: 2 }
                        : { padding: 8 }
                    }
                    onClick={() => history.push('/symptoms')}
                  >
                    <ChevronRight style={{ fontSize: 32 }} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <ItemHeader title={t('Covid5')} />
        <Paper className={classes.paper}>
          <Box className={classes.box} style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              color="primary"
              style={isBelowXs ? { padding: '9px 0px' } : {}}
              checked={checkbox.checkbox4}
              onChange={(e) => {
                if (e.target.checked) setLatest(UserCovidDTOType.type4);
                else {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox4: false });
                  deleteCovid('type4');
                }
              }}
            />
            <Typography
              className={classes.typo}
              style={
                isBelowXs ? { fontSize: 13, flexGrow: 1 } : { flexGrow: 1 }
              }
            >
              {t('Covid6')}
            </Typography>
            <StyledDatePicker
              value={dates.dates2}
              open={
                (checkbox.checkbox4 && isNull(dates.dates2)) || open.checkbox4
              }
              onClose={() => {
                if (checkbox.checkbox4 && isNull(dates.dates2)) {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox4: false });
                } else setOpen({ ...open, checkbox4: false });
              }}
              style={{
                display: 'none',
              }}
              format={DateFormats.GrNoTime}
              onChange={(date) => {
                setDates({ ...dates, dates2: date as Moment });
                setCheckbox({ ...checkbox });
                if (
                  covid.filter((item) => item.type === 'type4').length > 0 &&
                  checkbox.checkbox4
                )
                  updateCovid(
                    'type4',
                    date?.format(DateFormats.ISO) ??
                      moment().format(DateFormats.ISO)
                  );
              }}
              animateYearScrolling
              disableFuture
              autoOk
              autoFocus
            />
            {isNull(dates.dates2) ? (
              <Box
                style={{
                  height: '100%',
                  display: 'flex',
                  padding: '8px 0px 8px 8px',
                }}
              >
                <CalendarTodaySharp style={{ fontSize: 25 }} />
              </Box>
            ) : (
              <Typography
                onClick={() => setOpen({ ...open, checkbox4: true })}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {dates.dates2?.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Box>
          {covid.filter((item) => item.type === 'type4').length > 0 &&
            !isUndefined(
              covid.filter((item) => item.type === 'type4')[0].date
            ) && (
              <Box
                style={{
                  color: '#787878',
                  width: '100%',
                  display: 'flex',
                  fontSize: isBelowXs ? 14 : 16,
                }}
              >
                {t('Covid8')}{' '}
                {moment().dayOfYear() +
                  moment().year() * 365 -
                  moment(
                    covid.filter((item) => item.type === 'type4')[0].date
                  ).dayOfYear() -
                  moment(
                    covid.filter((item) => item.type === 'type4')[0].date
                  ).year() *
                    365 <
                15
                  ? 14 -
                    (moment().dayOfYear() +
                      moment().year() * 365 -
                      moment(
                        covid.filter((item) => item.type === 'type4')[0].date
                      ).dayOfYear() -
                      moment(
                        covid.filter((item) => item.type === 'type4')[0].date
                      ).year() *
                        365)
                  : 0}
              </Box>
            )}
          <Box className={classes.box} style={{ justifyContent: 'flex-start' }}>
            <Checkbox
              color="primary"
              style={isBelowXs ? { padding: '9px 0px' } : {}}
              checked={checkbox.checkbox5}
              onChange={(e) => {
                if (e.target.checked) setLatest(UserCovidDTOType.type5);
                else {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox5: false });
                  deleteCovid('type5');
                }
              }}
            />
            <Typography
              className={classes.typo}
              style={
                isBelowXs ? { fontSize: 13, flexGrow: 1 } : { flexGrow: 1 }
              }
            >
              {t('Covid7')}
            </Typography>
            <StyledDatePicker
              value={dates.dates3}
              open={
                (checkbox.checkbox5 && isNull(dates.dates3)) || open.checkbox5
              }
              onClose={() => {
                if (checkbox.checkbox5 && isNull(dates.dates3)) {
                  setLatest(undefined);
                  setCheckbox({ ...checkbox, checkbox5: false });
                } else setOpen({ ...open, checkbox5: false });
              }}
              style={{
                display: 'none',
              }}
              format={DateFormats.GrNoTime}
              onChange={(date) => {
                setDates({ ...dates, dates3: date as Moment });
                setCheckbox({ ...checkbox });
                if (
                  covid.filter((item) => item.type === 'type5').length > 0 &&
                  checkbox.checkbox5
                )
                  updateCovid(
                    'type5',
                    date?.format(DateFormats.ISO) ??
                      moment().format(DateFormats.ISO)
                  );
              }}
              animateYearScrolling
              disableFuture
              autoOk
              autoFocus
            />
            {isNull(dates.dates3) ? (
              <Box
                style={{
                  height: '100%',
                  display: 'flex',
                  padding: '8px 0px 8px 8px',
                }}
              >
                <CalendarTodaySharp style={{ fontSize: 25 }} />
              </Box>
            ) : (
              <Typography
                onClick={() => setOpen({ ...open, checkbox5: true })}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: 14,
                  cursor: 'pointer',
                }}
              >
                {dates.dates3?.format(DateFormats.GrNoTime)}
              </Typography>
            )}
          </Box>
          {covid.filter((item) => item.type === 'type5').length > 0 &&
            !isUndefined(
              covid.filter((item) => item.type === 'type5')[0].date
            ) && (
              <Box style={{ display: 'flex', width: '100%', marginLeft: 4 }}>
                <Box
                  style={{
                    color: '#787878',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: isBelowXs ? 14 : 16,
                  }}
                >
                  {t('Covid9')}
                  {fitness.length}
                  {t('Covid10')}14{t('Covid11')}
                </Box>
                <IconButton
                  style={{ padding: 0, marginLeft: 8 }}
                  onClick={() => history.push('/fitness')}
                >
                  <ChevronRight style={{ fontSize: 32 }} />
                </IconButton>
              </Box>
            )}
        </Paper>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  box: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  typo: {
    display: 'flex',
    alignItems: 'center',
    color: Colors.Black,
    marginLeft: theme.spacing(2),
  },
}));

const SymptomPaper = ({
  symptom,
  name,
  id,
}: {
  symptom: UserSymptomDTO;
  name: string;
  id: number;
}) => {
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={11} style={{ display: 'flex', width: '100%' }}>
        <Typography
          style={{
            display: 'flex',
            padding: '12px 27px',
            paddingRight: 10,
            color: Colors.Black,
            fontWeight: 'bold',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          {t(name)}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            style={isBelowXs ? { padding: 8, paddingLeft: 2 } : { padding: 8 }}
            onClick={() => history.push(`/symptoms/${id}`)}
          >
            <ChevronRight style={{ fontSize: 32 }} />
          </IconButton>
        </Box>
      </Grid>
      {!isUndefined(symptom) && (
        <Grid
          item
          xs={12}
          style={{ display: 'flex', margin: isBelowXs ? 0 : '0px 24px' }}
        >
          <Paper
            style={{
              display: 'flex',
              backgroundColor: '#F3F8FF',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <Typography
              style={{
                paddingLeft: 10,
                color: Colors.Black,
                fontSize: 14,
                fontWeight: 500,
                paddingTop: 6,
              }}
            >
              {t('LastSymptom')}
            </Typography>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                style={{
                  display: 'flex',
                  padding: isBelowXs
                    ? '16px 10px 16px 16px'
                    : '8px 8px 8px 10px',
                  color: Colors.Black,
                  textAlign: 'center',
                  alignItems: 'center',
                  fontSize: isBelowXs ? 14 : 16,
                }}
              >
                {moment(symptom.time).format(DateFormats.GrNoTime)}
              </Typography>
              {symptom.symptom.isBoolean === false && (
                <Typography
                  style={{
                    marginRight: isBelowXs ? 0 : 10,
                    padding: '8px 27px 8px 8px',
                    paddingLeft: 0,
                    color: Colors.Black,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexGrow: 1,
                  }}
                >
                  {symptom.symptom.id !== 12
                    ? symptom.value.toString().concat('/10')
                    : symptom.value}
                </Typography>
              )}
            </Box>
            {symptom.description.length > 0 ? (
              <Tooltip title={symptom.description}>
                <Box
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    paddingLeft: 10,
                    paddingBottom: 6,
                    maxWidth: symptom.symptom.isBoolean === true ? 250 : 'none',
                  }}
                >
                  <Box
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: isBelowSm ? 350 : 220,
                    }}
                  >
                    {symptom.description}
                  </Box>
                </Box>
              </Tooltip>
            ) : (
              <Box style={{ visibility: 'hidden', paddingBottom: 6 }}>Temp</Box>
            )}
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
export const Covid = hot(_Covid);
