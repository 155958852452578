import { Box, makeStyles, Button } from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router';
import { MessageDtoMessageType, UserMessagesDto } from 'gen/client';
interface EventMessageDialogProps {
  event: UserMessagesDto;
  onDecline: () => void;
}
const _EventMessageDialog = ({ event, onDecline }: EventMessageDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Box className={classes.wrapper}>
      <Box>{ReactHtmlParser(event.text)}</Box>
      {event.message.messageType === MessageDtoMessageType.Link && (
        <Box style={{ alignSelf: 'flex-end', margin: '10px 0px 0px 0px' }}>
          <Button
            onClick={() => history.push(event.message.link)}
            style={{ paddingBottom: 0 }}
          >
            {t('SeeMore')}
          </Button>
        </Box>
      )}
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
}));
export const EventMessageDialog = hot(_EventMessageDialog);
