import {
  Box,
  CircularProgress,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';
import { FeedbackRating } from 'components/FeedbackRating';
import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Colors, DateFormats } from 'res/Enums';
import { ChevronRight } from '@material-ui/icons';
import { useHistory } from 'react-router';
import moment from 'moment';
import {
  scoreControllerCreateMyFeedback,
  useScoreControllerGetMyScore,
} from './../../gen/client';
enum FeedbackType {
  First = 1,
  Second = 2,
}
// interface FeedbackAction {
//   type: FeedbackType;
//   payload: number;
// }
// interface FeedbackState {
//   first: number;
//   second: number;
// }
// function feedbackReducer(
//   state: FeedbackState,
//   action: FeedbackAction
// ): FeedbackState {
//   switch (action.type) {
//     case FeedbackType.First:
//       return { ...state, first: action.payload };
//     case FeedbackType.Second:
//       return { ...state, second: action.payload };
//     default:
//       return state;
//   }
// }
interface FeedbackProps {
  TopMargin?: number;
  question?: number;
  big?: number;
  redirect?: boolean;
  onSubmit?: () => void;
}
export const Feedback1 = ({ TopMargin, onSubmit, question }: FeedbackProps) => {
  const classes = useStyles({ TopMargin });
  // const [feedback, setFeedback] = useReducer(feedbackReducer, {
  //   first: 2,
  //   second: 2,
  // });
  const { t } = useTranslation();
  const handleFeedbackChange = async (
    score: number,
    question: FeedbackType
  ) => {
    try {
      const r = await scoreControllerCreateMyFeedback({
        score,
        question: question,
        time: moment().format(DateFormats.ISO),
      });
      if (r.status) {
        toast(t('FeedbackReg'));
        // setFeedback({ type: question, payload: score });
        if (onSubmit) onSubmit();
      } else {
        toast(t('FailedAdding'), { containerId: 'delete' });
      }
    } catch (e) {}
  };

  return (
    <Paper className={classes.root} style={{ minWidth: 200 }}>
      <Box display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column">
          <FeedbackRating
            label={question === 1 ? t('QPhysically') : t('QEmotionally')}
            questionId={question === 1 ? 1 : 2}
            onChange={(index) => {
              question === 1
                ? handleFeedbackChange(index, FeedbackType.First)
                : handleFeedbackChange(index, FeedbackType.Second);
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export const Feedback2 = ({
  TopMargin,
  big,
  redirect = false,
}: FeedbackProps) => {
  const classes = useStyles({ TopMargin });
  const [score, setScore] = useState(0);
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, isLoading } = useScoreControllerGetMyScore();

  useEffect(() => {
    if (data) {
      if (data.data && data.data.length > 0) {
        setScore(data.data[0].value);
      } else {
        setScore(-1);
      }
    }
  }, [data]);
  return (
    <Paper
      className={classes.root}
      style={{
        minHeight: 200,
        minWidth: 170,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box className={classes.rightUp}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            style={
              !isBelowSm
                ? {
                    padding: '8px 8px',
                    textAlign: 'center',
                    fontSize: 20,
                    fontWeight: 'bold',
                    color: Colors.FadeBlack,
                  }
                : {
                    fontSize: 21,
                    fontWeight: 'bold',
                    color: Colors.FadeBlack,
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    paddingLeft: 24,
                    justifyContent: 'center',
                  }
            }
          >
            {t('HealthScore')}
          </Typography>
          {redirect && (
            <Tooltip title={t('MoreDetails').toString()}>
              <ChevronRight
                className={classes.chevron}
                onClick={() => history.push('/health')}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
      {score !== -1 ? (
        <Box className={classes.rightDown} style={{ zIndex: 100 }}>
          <Box className={classes.wrapper}>
            {!isLoading && (
              <Fragment>
                <CircularProgress
                  thickness={5}
                  // size={isBelowSm ? 120 : 160}
                  size={big ? big : 150}
                  variant="static"
                  value={score}
                />
                <Box className={classes.scoreCont}>
                  <Typography
                    className={classes.score}
                    style={big ? { fontSize: 36 } : {}}
                  >{`${score}%`}</Typography>
                </Box>
              </Fragment>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          className={classes.rightDown}
          style={{
            zIndex: 100,
            height: '60%',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              color: Colors.PRIMARY,
              padding: '0px 16px 0px 16px',
              textAlign: 'center',
            }}
          >
            {t('MoreInfo')}
          </Typography>
        </Box>
      )}
      {!redirect && (
        <Box
          style={{
            textAlign: 'center',
            fontSize: isBelowSm ? 14 : 16,
            margin: '16px 0px',
            padding: '0px 8px',
          }}
        >
          {t('HealthierScore')}
        </Box>
      )}
    </Paper>
  );
};

interface StyleProps {
  TopMargin?: number;
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    marginTop: (props) => props.TopMargin ?? theme.spacing(3),
    color: 'black',
    position: 'relative',
    width: '100%',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
  scoreCont: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  score: {
    fontSize: 24,
    fontWeight: 'bold',
    color: Colors.FadeBlack,
  },
  rightUp: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  rightDown: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'center',
  },
  helperText: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  chevron: {
    cursor: 'pointer',
    '&:hover': {
      color: Colors.PRIMARY,
    },
  },
}));
