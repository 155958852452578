import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import React, { Fragment } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { FormTextField } from 'components/Forms/FormTextField';
import * as Yup from 'yup';
import { isUndefined } from 'util';
import { toast } from 'react-toastify';
import { authControllerChangePassword } from 'gen/client';
const _ChangePassword = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const initValues = {
    password: '',
    repeat: '',
  };
  const validation = Yup.object().shape({
    password: Yup.string().min(8, t('RePassword2')).required(t('RePassword')),
    repeat: Yup.string()
      .oneOf([Yup.ref('password'), null], t('RePassword3'))
      .min(8, t('RePassword2'))
      .required(t('RePassword')),
  });
  const requestReset = (values: typeof initValues) => {
    authControllerChangePassword(values)
      .then(() => {
        toast(t('Changed'));
      })
      .catch();
  };
  return (
    <Fragment>
      <Formik
        initialValues={initValues}
        onSubmit={requestReset}
        validationSchema={validation}
      >
        {({ dirty, isValid, errors }) => (
          <Form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <FormTextField
              variant="outlined"
              type="password"
              margin="normal"
              name="password"
              label={t('Password')}
              InputLabelProps={{ style: isBelowXs ? { fontSize: 16 } : {} }}
              error={!isUndefined(errors.password)}
            />
            <FormTextField
              variant="outlined"
              type="password"
              margin="normal"
              name="repeat"
              label={t('RepeatPassword')}
              InputLabelProps={{ style: isBelowXs ? { fontSize: 16 } : {} }}
              error={!isUndefined(errors.repeat)}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={!dirty || !isValid}
              type="submit"
            >
              {t('ChangePassword')}
            </Button>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};
export const ChangePassword = hot(_ChangePassword);
