import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
  Popover,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import BirthdayIcon from 'assets/icons/birthday.png';
import BloodIcon from 'assets/icons/blood.png';
import SmokerIcon from 'assets/icons/smoker.png';
import React, { useEffect, useState, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { ItemHeader } from './EditConcise';
import { ShortNoteField } from './ShortNote';
import { Colors } from 'res/Enums';
import { NextMedication } from 'components/Home/NextMedication';
import { useTutorialContext } from 'context/TutorialContext';
import { Demo } from 'components/Demo';
import {
  BasicSituation,
  BloodDTOType,
  meControllerGetMe,
  medicationControllerGetMyMedication,
  situationControllerGetBasicSituation,
  situationControllerGetMySituation,
  SituationDTOCategory,
  UserDto,
  UserMedicationDTO,
  UserSituationDTO,
} from 'gen/client';

interface ConciseProps {
  tut?: number;
}

const _Consise = ({ tut }: ConciseProps) => {
  const [me, setMe] = useState<UserDto>();
  const classes = useStyles();
  const { t } = useTranslation();
  const [userSituation, setUserSituation] = useState<UserSituationDTO[]>([]);
  const [activeMedication, setActiveMedication] = useState<UserMedicationDTO[]>(
    []
  );
  const { openT } = useTutorialContext();
  const [basic, setBasic] = useState<BasicSituation>();
  const [loading, setLoading] = useState(true);
  const inputEl = useRef<any | null>();
  const [temp, setTemp] = useState(0);
  const { cont, setCont } = useTutorialContext();
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));

  const filterSituation = (category: SituationDTOCategory) =>
    userSituation.filter((sit) => sit.situation.category === category);

  const getAll = () => {
    const getBasicPromise = situationControllerGetBasicSituation();

    const getActiveMedicationPromise = medicationControllerGetMyMedication({
      activeMedication: true,
    });

    const getSituationPromise = situationControllerGetMySituation();

    const getMePromise = meControllerGetMe();

    Promise.all([
      getBasicPromise,
      getActiveMedicationPromise,
      getSituationPromise,
      getMePromise,
    ])
      .then((values) => {
        setBasic(values[0].data);
        setActiveMedication(values[1].data);
        setUserSituation(values[2].data);
        setMe(values[3].data);
        setLoading(false);
      })
      .catch();
  };

  useEffect(() => {
    getAll();
    setTemp(cont);
    setCont(cont + 1);
  }, []);

  if (loading) {
    return (
      <Box
        style={{
          display: 'flex',
          height: '80vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={70} />
      </Box>
    );
  }
  return (
    <Grid
      container
      spacing={1}
      style={
        tut
          ? { padding: 16, paddingBottom: 0, justifyContent: 'center' }
          : { justifyContent: 'center' }
      }
      ref={inputEl}
    >
      <Grid item xs={12}>
        <ItemHeader title={t('UserInfo')} />
        <Paper className={classes.list}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '5px 0',
              flexWrap: 'wrap',
            }}
          >
            <Box
              marginX={2}
              display="flex"
              flexDirection="column"
              textAlign="center"
              style={{ alignItems: 'center' }}
            >
              <Box style={{ width: 'fit-content' }}>
                <img alt="" src={SmokerIcon} />
              </Box>
              <Typography>
                {basic?.smoker === true ? t('Smoker') : t('NoSmoker')}
              </Typography>
            </Box>
            <Box
              marginX={3}
              display="flex"
              flexDirection="column"
              textAlign="center"
            >
              <img alt="" src={BloodIcon} />
              <Typography>
                {me?.bloodType === BloodDTOType.ABneg
                  ? 'AB-'
                  : me?.bloodType === BloodDTOType.ABpos
                  ? 'AB+'
                  : me?.bloodType === BloodDTOType.Aneg
                  ? 'A-'
                  : me?.bloodType === BloodDTOType.Apos
                  ? 'A+'
                  : me?.bloodType === BloodDTOType.Bneg
                  ? 'B-'
                  : me?.bloodType === BloodDTOType.Bpos
                  ? 'B+'
                  : me?.bloodType === BloodDTOType.Oneg
                  ? 'O-'
                  : 'O+'}
              </Typography>
            </Box>
            <Box
              marginX={3}
              display="flex"
              flexDirection="column"
              textAlign="center"
            >
              <img alt="" src={BirthdayIcon} />
              <Typography>{me?.age}</Typography>
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <ItemHeader title={t('Medication')} />
        {activeMedication.length > 0 && (
          <Box
            display="flex"
            style={{ flexDirection: 'column', marginTop: 16 }}
          >
            <NextMedication med={activeMedication} />
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <ItemHeader title={t('Chronic')} />
        <Paper className={classes.list}>
          {filterSituation(SituationDTOCategory.ChronicDisease).length > 0 ? (
            <List>
              {filterSituation(SituationDTOCategory.ChronicDisease)
                .filter((item) => item.valid === true)
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemText style={{ color: Colors.Black }}>
                      {item.situation.name}
                    </ListItemText>
                  </ListItem>
                ))}
            </List>
          ) : (
            <Typography style={{ padding: 16, color: Colors.Black }}>
              {t('NoListed')}
              {t('chronicdis')}
            </Typography>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <ItemHeader title={t('Allergies')} />
        <Paper className={classes.list}>
          {filterSituation(SituationDTOCategory.Allergy).length > 0 ? (
            <List>
              {filterSituation(SituationDTOCategory.Allergy)
                .filter((item) => item.valid === true)
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemText style={{ color: Colors.Black }}>
                      {item.situation.name}
                    </ListItemText>
                  </ListItem>
                ))}
            </List>
          ) : (
            <Typography style={{ padding: 16, color: Colors.Black }}>
              {t('NoListed')}
              {t('allergi')}
            </Typography>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <ItemHeader title={t('Rest')} />
        <Paper className={classes.list}>
          {filterSituation(SituationDTOCategory.TemporaryDisease).length > 0 ||
          filterSituation(SituationDTOCategory.Disability).length > 0 ||
          filterSituation(SituationDTOCategory.Situation).length > 0 ? (
            <List>
              {filterSituation(SituationDTOCategory.TemporaryDisease)
                .filter((item) => item.valid === true)
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemText style={{ color: Colors.Black }}>
                      {item.situation.name}
                    </ListItemText>
                  </ListItem>
                ))}
              {filterSituation(SituationDTOCategory.Disability)
                .filter((item) => item.valid === true)
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemText style={{ color: Colors.Black }}>
                      {item.situation.name}
                    </ListItemText>
                  </ListItem>
                ))}
              {filterSituation(SituationDTOCategory.Situation)
                .filter((item) => item.valid === true)
                .map((item, i) => (
                  <ListItem key={i}>
                    <ListItemText style={{ color: Colors.Black }}>
                      {item.situation.name}
                    </ListItemText>
                  </ListItem>
                ))}
            </List>
          ) : (
            <Typography style={{ padding: 16, color: Colors.Black }}>
              {t('OtherDiseases')}
            </Typography>
          )}
        </Paper>
      </Grid>
      {me?.emergency !== null && (
        <Grid item xs={12}>
          <ItemHeader title={t('EmergencyNumber')} />
          <Paper className={classes.list}>
            <Typography style={{ padding: 16, color: Colors.Black }}>
              {me?.emergency?.emergencyNumber}
            </Typography>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <ItemHeader title={t('Note')} />
        <ShortNoteField />
      </Grid>
      {temp < 1 && (
        <Dialog
          open={!me?.tutorial && openT === '21'}
          BackdropProps={{
            style: { backgroundColor: 'black', opacity: 0.8 },
          }}
        >
          <Popover
            open={openT === '21'}
            anchorEl={inputEl.current}
            PaperProps={{
              style: { width: isBelowMd ? '90vw' : '75vw', overflow: 'hidden' },
            }}
          >
            <Concise tut={cont} />
            <Popover
              open={openT === '21'}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  marginLeft: '-16%',
                  marginTop: '1%',
                  width: isBelowMd ? '60vw' : '45vw',
                  textAlign: 'center',
                },
              }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                {t('Tut33')}
              </Typography>
            </Popover>
            <Demo />
          </Popover>
        </Dialog>
      )}
    </Grid>
  );
};
const useStyles = makeStyles((theme) => ({
  container: {
    border: '3px solid #5CA595',
    padding: 10,
  },
  list: {
    marginTop: theme.spacing(1),
  },
}));
export const Concise = hot(_Consise);
