import {
  Box,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Dialog,
  Popover,
} from '@material-ui/core';
import { Feedback1, Feedback2 } from 'components/Home/Feedback';
import { StyledLineChart } from 'components/LineChart';
import { ShortInfo } from 'components/WellBeing/ShortInfo';
import moment from 'moment';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Tooltip, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';
import { useTutorialContext } from 'context/TutorialContext';
import { Demo } from 'components/Demo';
import { isUndefined } from 'util';
import {
  UserFeedbackDTO,
  UserScoreDTO,
  useScoreControllerGetFeedbackByQuestionID,
  useScoreControllerGetMyScore,
} from 'gen/client';

interface HealthScoreProps {
  tut?: number;
}

const _HealthScore = ({ tut }: HealthScoreProps) => {
  const [score, setScore] = useState<UserScoreDTO[]>([]);
  const [firstQuestion, setFirstQuestion] = useState<UserFeedbackDTO[]>([]);
  const [secondQuestion, setSecondQuestion] = useState<UserFeedbackDTO[]>([]);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const { me, openT, cont, setCont } = useTutorialContext();
  const inputEl = useRef<any | null>();
  const [temp, setTemp] = useState(0);
  const { data: scoreData, isLoading: scoreLoading } =
    useScoreControllerGetMyScore();
  const {
    data: firstQuestionData,
    isLoading: firstLoading,
    refetch: refetchFirst,
  } = useScoreControllerGetFeedbackByQuestionID(1);
  const {
    data: secondQuestionData,
    isLoading: secondLoading,
    refetch: refetchSecond,
  } = useScoreControllerGetFeedbackByQuestionID(2);

  const getMyFeedback = () => {
    refetchFirst();
    refetchSecond();
  };

  useEffect(() => {
    if (firstQuestionData && scoreData && secondQuestionData) {
      setScore(scoreData.data);
      setFirstQuestion(firstQuestionData.data);
      setSecondQuestion(secondQuestionData.data);
    }
  }, [scoreData, firstQuestionData, secondQuestionData]);

  useEffect(() => {
    setTemp(cont);
    setCont(cont + 1);
  }, []);

  if (scoreLoading || firstLoading || secondLoading) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
  return (
    <Container
      className={classes.container}
      ref={inputEl}
      style={!isUndefined(tut) ? { padding: 16, paddingBottom: 0 } : {}}
    >
      <Typography
        style={{
          paddingLeft: 10,
          paddingBottom: 10,
          color: '#000000',
          fontSize: 30,
          fontWeight: 'bold',
        }}
      >
        Health Score
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} style={{ display: 'flex' }}>
          <Feedback2 onSubmit={() => getMyFeedback()} TopMargin={0} big={220} />
        </Grid>
        {score.length > 0 ? (
          <Fragment>
            <Grid item xs={12} md={6}>
              <Box display="flex" className={classes.padd}>
                <Typography
                  style={{
                    fontSize: 22,
                    fontWeight: 'bold',
                    color: Colors.Black,
                    marginTop: '2%',
                    paddingLeft: 20,
                    marginBottom: '3%',
                  }}
                >
                  {t('HealthScore')}
                </Typography>
                <StyledLineChart
                  aspect={isBelowSm ? 3 : 2}
                  data={score
                    .map(({ date, ...rest }) => ({
                      ...rest,
                      date: moment(date).format(DateFormats.GR),
                    }))
                    .slice(0, 100)
                    .reverse()}
                  dataKey="value"
                >
                  <XAxis dataKey="date" axisLine={false} tickLine={false} />
                  <YAxis dataKey="value" axisLine={false} tickLine={false} />
                  <Tooltip
                    formatter={(datakey, name) => [datakey, t('HealthScore')]}
                  />
                </StyledLineChart>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: 'flex', minWidth: 260 }}
            >
              <Feedback1
                onSubmit={() => getMyFeedback()}
                question={1}
                TopMargin={0}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: 'flex', minWidth: 260 }}
            >
              <Feedback1
                onSubmit={() => getMyFeedback()}
                question={2}
                TopMargin={0}
              />
            </Grid>
          </Fragment>
        ) : (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
            }}
          >
            <Grid item xs={12} style={{ display: 'flex', minWidth: 260 }}>
              <Feedback1
                onSubmit={() => getMyFeedback()}
                question={1}
                TopMargin={0}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', minWidth: 260, marginTop: 16 }}
            >
              <Feedback1
                onSubmit={() => getMyFeedback()}
                question={2}
                TopMargin={0}
              />
            </Grid>
          </Box>
        )}
      </Grid>
      <Grid container spacing={4} style={{ marginTop: '1%' }}>
        <Grid item xs={12} md={6} style={{ minWidth: 260 }}>
          <Box display="flex" className={classes.padd}>
            {firstQuestion.length > 0 && (
              <Fragment>
                <Box display="flex" className={classes.info}>
                  <Box
                    display="flex"
                    style={{ width: isBelowXs ? '100%' : '60%' }}
                  >
                    <ShortInfo latest={firstQuestion[0]} />
                  </Box>
                  <Divider
                    style={{
                      marginTop: '5%',
                      backgroundColor: '#E3DFFA',
                    }}
                  />
                  <StyledLineChart
                    data={firstQuestion
                      .map(({ date, ...rest }) => ({
                        ...rest,
                        date: moment(date).format(DateFormats.GR),
                      }))
                      .slice(0, 100)
                      .reverse()}
                    dataKey="score"
                  >
                    <XAxis dataKey="date" axisLine={false} tickLine={false} />
                    <YAxis
                      dataKey="score"
                      axisLine={false}
                      tickLine={false}
                      domain={[1, 5]}
                    />
                    <Tooltip
                      formatter={(datakey, name) => [datakey, t('Score')]}
                    />
                  </StyledLineChart>
                </Box>
              </Fragment>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" className={classes.padd}>
            {secondQuestion.length > 0 && (
              <Fragment>
                <Box display="flex" className={classes.info}>
                  <Box
                    display="flex"
                    style={{ width: isBelowXs ? '100%' : '60%' }}
                  >
                    <ShortInfo latest={secondQuestion[0]} />
                  </Box>
                  <Divider
                    style={{
                      marginTop: '5%',
                      backgroundColor: '#E3DFFA',
                    }}
                  />
                  <StyledLineChart
                    data={secondQuestion
                      .map(({ date, ...rest }) => ({
                        ...rest,
                        date: moment(date).format(DateFormats.GR),
                      }))
                      .slice(0, 100)
                      .reverse()}
                    dataKey="score"
                  >
                    <XAxis dataKey="date" axisLine={false} tickLine={false} />
                    <YAxis
                      dataKey="score"
                      axisLine={false}
                      tickLine={false}
                      domain={[1, 5]}
                    />
                    <Tooltip
                      formatter={(datakey, name) => [datakey, t('Score')]}
                    />
                  </StyledLineChart>
                </Box>
              </Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
      {temp < 1 && (
        <Dialog
          open={!me?.tutorial && openT === '22'}
          BackdropProps={{
            style: { backgroundColor: 'black', opacity: 0.8 },
          }}
        >
          <Popover
            open={openT === '22'}
            anchorEl={inputEl.current}
            PaperProps={{
              style: {
                height: '80vh',
                width: isBelowMd ? '90vw' : '70vw',
                overflow: 'hidden',
              },
            }}
          >
            <HealthScore tut={cont} />
            <Popover
              open={openT === '22'}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  marginLeft: '-16%',
                  marginTop: '1%',
                  width: isBelowMd ? '60vw' : '45vw',
                  textAlign: 'center',
                },
              }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                {t('Tut30')}
              </Typography>
            </Popover>
            {me?.tutorial === false && <Demo />}
          </Popover>
        </Dialog>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  padd: {
    border: '1px solid #E3DFFA',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    height: '100%',
  },
  info: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
}));
export const HealthScore = hot(_HealthScore);
