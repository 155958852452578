import {
  Box,
  makeStyles,
  Theme,
  Grid,
  Paper,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Checkbox,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Colors, DateFormats } from 'res/Enums';
import moment, { Moment } from 'moment';
import { StyledDatePicker } from './DatePicker';
import { CalendarTodaySharp } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useTutorialContext } from 'context/TutorialContext';
import { Terms } from 'res/Terms';
import {
  meControllerUpdateMe,
  RegisterDtoSex,
  UpdateUserSex,
  useMeControllerGetMe,
  UserDto,
} from 'gen/client';
import { useAuthContext } from 'context/AuthContext';

const _RegisterNeeded = () => {
  const history = useHistory();
  const { googleRegister } = useAuthContext();
  const [birthday, setBirthday] = useState<Moment | null>(null);
  const [sex, setSex] = useState<UpdateUserSex | number>(1);
  const [me, setMe] = useState<UserDto>();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isBelowXl = useMediaQuery(theme.breakpoints.down('lg'));
  const isUpMd = useMediaQuery(theme.breakpoints.up('sm'));
  const { setCont } = useTutorialContext();
  const [ready, setReady] = useState(false);
  const [terms, setTerms] = useState(false);
  const [mailList, setMailList] = useState(false);
  const [termsDialog, setTermsDialog] = useState(false);
  const { data: meData } = useMeControllerGetMe();

  useEffect(() => {
    if (meData) setMe(meData.data);
  }, [meData]);

  useEffect(() => {
    if (ready) {
      googleRegister();
    }
  }, [ready]);

  if (!me) {
    return (
      <Box
        style={{
          display: 'flex',
          height: '80vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={70} />
      </Box>
    );
  } else
    return (
      <Box className={classes.container}>
        <Grid container spacing={5} style={{ marginTop: 50 }}>
          <Grid item xs={12}>
            <Paper
              style={{
                background: Colors.IceWhite,
                display: 'flex',
              }}
            >
              <Typography
                style={{
                  flexGrow: 1,
                  fontSize: isBelowXs || (isBelowXl && isUpMd) ? 13 : 18,
                  padding:
                    isBelowXs || (isBelowXl && isUpMd)
                      ? '10px 0px 10px 8px'
                      : '10px 0px 10px 20px',
                  color: Colors.PrimaryLight,
                  textAlign: isBelowXs ? 'center' : 'inherit',
                }}
              >
                {t('BirthDate')}:
              </Typography>
              <StyledDatePicker
                value={birthday}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: isBelowXs ? 4 : isBelowXl && isUpMd ? 0 : 6,
                }}
                openTo="year"
                format={DateFormats.GrNoTime}
                onChange={(date) => setBirthday(date as Moment)}
                disableFuture
                autoOk
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        paddingBottom: birthday ? 8 : 0,
                        display: !birthday ? 'flex' : 'none',
                        color: Colors.PRIMARY,
                      }}
                    >
                      <CalendarTodaySharp
                        style={isBelowXs ? { fontSize: 18 } : {}}
                      />
                    </InputAdornment>
                  ),
                  style: {
                    width: isBelowXs
                      ? !birthday
                        ? 20
                        : isBelowXl && isUpMd
                        ? 70
                        : 88
                      : !birthday
                      ? 30
                      : isBelowXl && isUpMd
                      ? 70
                      : 88,
                    marginRight: isBelowXs
                      ? !birthday
                        ? 4
                        : 35
                      : !birthday
                      ? 30
                      : 0,
                    color: Colors.Black,
                    fontSize: isBelowXl && isUpMd ? 13 : 16,
                  },
                }}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{
                background: Colors.IceWhite,
                display: 'flex',
              }}
            >
              <Typography
                style={{
                  flexGrow: 1,
                  fontSize: isBelowXs || (isBelowXl && isUpMd) ? 13 : 18,
                  padding: '10px 0px 10px 20px',
                  color: Colors.PrimaryLight,
                }}
              >
                {t('Sex')}:
              </Typography>
              <Select
                value={sex}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                  setSex(e.target.value as UpdateUserSex);
                }}
                style={{
                  fontSize: isBelowXs || (isBelowXl && isUpMd) ? 13 : 18,
                }}
              >
                <MenuItem hidden disabled value={1}>
                  {t('Sex')}
                </MenuItem>
                {Object.values(RegisterDtoSex).map(
                  (item, i) =>
                    i < 2 && (
                      <MenuItem key={i} value={item}>
                        {item === RegisterDtoSex.Female
                          ? t('Female')
                          : t('Male')}
                      </MenuItem>
                    )
                )}
              </Select>
            </Paper>
          </Grid>
          <Grid xs={12}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'lighter',
                color: Colors.FadeBlack,
                padding: '0px 16px',
              }}
            >
              <Checkbox
                checked={terms}
                onChange={(e) =>
                  !e.target.checked ? setTerms(false) : setTerms(true)
                }
                color="primary"
              />
              {t('Reg') + ' '}
              <Link
                href="#"
                underline="none"
                style={{ color: Colors.BlueText, fontWeight: 'normal' }}
                onClick={() => setTermsDialog(true)}
              >
                {t('Terms')} {t('And')} {t('The')} {t('Policy')}
              </Link>
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 'lighter',
                color: Colors.FadeBlack,
                padding: '0px 16px',
              }}
            >
              <Checkbox
                checked={mailList}
                onChange={(e) =>
                  !e.target.checked ? setMailList(false) : setMailList(true)
                }
                color="primary"
              />
              {t('MailList')}
            </Typography>
          </Grid>
        </Grid>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '50px 0px 110px 0px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              fontSize: isBelowXs ? 15 : 16,
              marginTop: 24,
              padding: isBelowXs ? 20 : '20px 50px 20px 50px',
            }}
            disabled={!birthday || sex === 1 || terms === false}
            onClick={() => {
              if (birthday && sex !== 1) {
                meControllerUpdateMe({
                  ...me,
                  sex: typeof sex !== 'number' ? sex : UpdateUserSex.Male,
                  birthDate: birthday.format(DateFormats.ISO),
                  mailChimp: true,
                  terms: moment().format(DateFormats.ISO),
                })
                  .then(() => {
                    setCont(0);
                    setReady(true);
                  })
                  .catch();
              }
            }}
          >
            {t('GoogleEntry')}
          </Button>
        </Box>
        <Dialog open={termsDialog} onClose={() => setTermsDialog(false)}>
          <DialogTitle style={{ textAlign: 'center' }}>
            {t('TermsPolicy')}
          </DialogTitle>
          <DialogContent>
            <Terms />
          </DialogContent>
        </Dialog>
      </Box>
    );
};
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
}));
export const RegisterNeeded = hot(_RegisterNeeded);
