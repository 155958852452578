import { CSSProperties } from '@material-ui/styles';
import React, { PropsWithChildren } from 'react';
import { hot } from 'react-hot-loader/root';
import { Line, LineChart, LineChartProps, ResponsiveContainer } from 'recharts';
import { Colors } from 'res/Enums';
import { useMediaQuery } from '@material-ui/core';
import theme from '../assets/theme';

interface StyledLineChartProps extends LineChartProps {
  dataKey: string;
  dataKey2?: string;
  aspect?: number;
}
const _StyledLineChart = ({
  style,
  children,
  dataKey2,
  aspect,
  ...rest
}: PropsWithChildren<StyledLineChartProps>) => {
  const isBelowXs = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ResponsiveContainer width="100%" aspect={aspect ?? 2}>
      <LineChart {...rest} style={{ ...styles, ...style }} key={Math.random()}>
        {children}
        <Line
          type="monotone"
          dataKey={rest.dataKey}
          stroke={Colors.PrimaryLight}
          strokeWidth={isBelowXs ? 3 : 4}
        />
        {dataKey2 && (
          <Line
            dataKey={dataKey2}
            type="monotone"
            stroke="#9FC131"
            strokeWidth={isBelowXs ? 3 : 4}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

const styles: CSSProperties = {};
export const StyledLineChart = hot(_StyledLineChart);
