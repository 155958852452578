import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import { StyledBarChart } from 'components/BarChart';
import { FilterBar, GroupProps, useFilter } from 'components/Filter/FilterBar';
import { StyledLineChart } from 'components/LineChart';
import { ShortInfo } from 'components/Symptoms/ShortInfo';
import { SymptomsBox } from 'components/Symptoms/SymptomBox';
import { TablePaginationActions } from 'components/TablePaginationActions';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip as TooltipChart, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';
import { EmptyPage } from 'components/EmptyPage';
import Empty from 'assets/icons/symptomsEmpty.svg';
import {
  SymptomDTO,
  UserSymptomDTO,
  useSymptomControllerDeleteMySymptom,
  useSymptomControllerGetMySymptomsByType,
  useSymptomControllerGetSymptomById,
} from 'gen/client';

export const SymptomID = withRouter(({ match, history }) => {
  const [activeSymptom, setActiveSymptom] = useState<SymptomDTO | null>(null);
  const [userSymptoms, setUserSymptoms] = useState<UserSymptomDTO[]>([]);
  const [filterData, setFilterData] = useState<UserSymptomDTO[]>([]);
  const [filtered, setFiltered] = useState<UserSymptomDTO[]>([]);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    data: userSymptomsData,
    isLoading: userSymptomsLoading,
    refetch: userSymptomsRefetch,
  } = useSymptomControllerGetMySymptomsByType(match.params.id);
  const { data: activeSymptomData, isLoading: activeSymptomLoading } =
    useSymptomControllerGetSymptomById(match.params.id);
  const deleteMutation = useSymptomControllerDeleteMySymptom();

  useEffect(() => {
    if (userSymptomsData) {
      setUserSymptoms(userSymptomsData.data);
      setFilterData(userSymptomsData.data);
      setFiltered(userSymptomsData.data);
    }
  }, [userSymptomsData]);

  useEffect(() => {
    if (activeSymptomData) setActiveSymptom(activeSymptomData.data);
  }, [activeSymptomData]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [symptomToEdit, setSymptomToEdit] = useState<UserSymptomDTO>();
  const [activeFilter, setActiveFilter] = useFilter();
  const [activeFilter2, setActiveFilter2] = useFilter();
  const { t } = useTranslation();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteSymptom = async (id: number) => {
    try {
      const response = await deleteMutation.mutateAsync({ id });
      if (response) {
        toast(t('SymptomDeleted'));
        userSymptomsRefetch();
      } else {
        toast(t('FailedDeleting'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  const classes = useStyles();
  if (activeSymptom !== null && !userSymptomsLoading) {
    return (
      <Container className={classes.container}>
        <Box display="flex" flexDirection="row">
          <Button
            variant="text"
            endIcon={<ArrowBack style={{ fontSize: 30 }} />}
            style={{
              color: Colors.FadeBlack,
            }}
            onClick={() => history.push('/symptoms')}
          />
          <Typography
            style={{
              paddingLeft: 30,
              color: '#000000',
              fontSize: isBelowXs ? 24 : 30,
              fontWeight: 'bold',
            }}
          >
            {activeSymptom.name}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {userSymptoms.length > 0 ? (
            <Grid item md={7} xs={12}>
              <Box style={{ margin: '8px 0px' }}>
                <Typography
                  style={{
                    padding: '16px 0px',
                    color: Colors.Black,
                    fontSize: isBelowXs ? 18 : 20,
                    fontWeight: 'bold',
                  }}
                >
                  {t('Diagram')}
                </Typography>
              </Box>
              <Box
                className={classes.padd}
                style={isBelowXs ? { padding: 0 } : {}}
              >
                <Box
                  display="flex"
                  style={{ width: isBelowXs ? '100%' : '60%' }}
                >
                  <ShortInfo latest={userSymptoms[0]} />
                </Box>
                <Divider
                  style={{
                    marginTop: '3%',
                    backgroundColor: '#E3DFFA',
                  }}
                />
                {activeSymptom.isBoolean === false ? (
                  <Box
                    className={clsx([classes.graph])}
                    style={isBelowXs ? { padding: 6 } : {}}
                  >
                    <FilterBar<UserSymptomDTO>
                      data={userSymptoms}
                      enables={{ date: true }}
                      onFilter={(data) => setFilterData(data)}
                      dateKey="time"
                      activeFilter={activeFilter}
                      setActiveFilter={setActiveFilter}
                    />
                    {filterData.length > 0 ? (
                      <StyledLineChart
                        data={filterData
                          .map(({ time, ...rest }) => ({
                            ...rest,
                            time: moment(time).format(DateFormats.GR),
                          }))
                          .slice(0, 100)
                          .reverse()}
                        dataKey="value"
                      >
                        <XAxis
                          dataKey="time"
                          axisLine={false}
                          tickLine={false}
                        />
                        <YAxis
                          dataKey="value"
                          axisLine={false}
                          tickLine={false}
                          domain={
                            activeSymptom.id === 12 ? [35, 42] : [0, 'auto']
                          }
                        />
                        <TooltipChart
                          formatter={(datakey, name) => [datakey, t('Value')]}
                        />
                      </StyledLineChart>
                    ) : (
                      <Box
                        style={{
                          display: 'flex',
                          height: '65%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 16,
                          fontWeight: 'bold',
                          color: Colors.Black,
                        }}
                      >
                        {t('NoFilter')}
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box
                    className={clsx([classes.graph])}
                    style={isBelowXs ? { padding: 6 } : {}}
                  >
                    <FilterBar<UserSymptomDTO>
                      data={userSymptoms}
                      enables={{ group: true, date: true }}
                      onFilter={(data, group) =>
                        setFilterData(
                          ((_data) => {
                            if (
                              data.length > 1 &&
                              group !== GroupProps.ALL &&
                              group !== GroupProps.YEAR
                            ) {
                              let sorted = data.sort((a, b) =>
                                moment(a.time).diff(moment(b.time))
                              );
                              let temp: UserSymptomDTO[] = sorted;
                              sorted.forEach((item, i) => {
                                if (sorted[i + 1]) {
                                  const next = sorted[i + 1];
                                  const diff = Math.abs(
                                    moment(item.time).diff(
                                      next.time,
                                      group === GroupProps.MONTH
                                        ? 'month'
                                        : group === GroupProps.WEEK
                                        ? 'week'
                                        : 'day'
                                    )
                                  );
                                  if (diff > 1) {
                                    const newDates: string[] = [];
                                    for (let i = 1; i < diff; i++) {
                                      newDates.push(
                                        group === GroupProps.WEEK
                                          ? moment(item.time)
                                              .startOf('week')
                                              .add(i, 'weeks')
                                              .format(DateFormats.ISO)
                                          : moment(item.time)
                                              .add(
                                                i,
                                                group === GroupProps.MONTH
                                                  ? 'month'
                                                  : 'day'
                                              )
                                              .format(DateFormats.ISO)
                                      );
                                    }
                                    temp = temp.concat(
                                      ...(newDates.map((item) => ({
                                        time: item,
                                        value: 0,
                                      })) as unknown as UserSymptomDTO[])
                                    );
                                  }
                                }
                              });
                              return temp.sort((a, b) =>
                                moment(b.time).diff(moment(a.time))
                              );
                            }
                            return _data;
                          })(data)
                        )
                      }
                      dateKey="time"
                      activeFilter={activeFilter}
                      setActiveFilter={setActiveFilter}
                    />
                    {filterData.length > 0 ? (
                      <StyledBarChart
                        width={filterData.length < 3 ? 100 : undefined}
                        data={filterData
                          .map(({ time, ...rest }) => ({
                            ...rest,
                            time: moment(time).format(DateFormats.GR),
                          }))
                          .slice(0, 100)
                          .reverse()}
                        dataKey="value"
                      >
                        <XAxis
                          dataKey="time"
                          axisLine={false}
                          tickLine={false}
                        />
                        <YAxis
                          dataKey="value"
                          axisLine={false}
                          tickLine={false}
                        />
                        <TooltipChart
                          formatter={(datakey, name) => [
                            datakey,
                            t('Quantity'),
                          ]}
                        />
                      </StyledBarChart>
                    ) : (
                      <Box
                        style={{
                          display: 'flex',
                          height: '65%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: 16,
                          fontWeight: 'bold',
                          color: Colors.Black,
                        }}
                      >
                        {t('NoFilter')}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          ) : (
            <Grid item md={7} xs={12}>
              <EmptyPage
                image={Empty}
                text={t('Symptoms')}
                text1={t('SymptomsEmptyText')}
              />
            </Grid>
          )}
          <Grid item md={5} xs={12}>
            <Box style={{ margin: '8px 0px' }}>
              <Typography
                style={{
                  padding: '16px 0px',
                  color: Colors.Black,
                  fontSize: !isBelowXs ? 20 : 18,
                  fontWeight: 'bold',
                }}
              >
                {t('RecordMeasurement')}
              </Typography>
            </Box>
            <SymptomsBox
              onAdd={() => {
                userSymptomsRefetch();
              }}
              onEdit={() => {
                userSymptomsRefetch();
              }}
              onCancel={() => setSymptomToEdit(undefined)}
              symptom={symptomToEdit}
              active={activeSymptom}
              lastSymptom={userSymptoms[0]}
            />
          </Grid>
        </Grid>
        {userSymptoms.length > 0 && (
          <Box>
            <Paper style={{ marginTop: 30, overflow: 'auto' }}>
              <Box style={{ margin: '8px 0px' }}>
                <FilterBar<UserSymptomDTO>
                  data={userSymptoms}
                  enables={{ date: true }}
                  onFilter={(data) => setFiltered(data)}
                  dateKey="time"
                  activeFilter={activeFilter2}
                  setActiveFilter={setActiveFilter2}
                />
              </Box>
              <Table>
                <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                  <TableRow>
                    {!isBelowXs && <TableCell padding="checkbox" />}
                    {activeSymptom.isBoolean === false && (
                      <TableCell style={{ color: '#FFFFFF' }}>
                        {t('Value')}
                      </TableCell>
                    )}
                    <TableCell style={{ color: '#FFFFFF' }}>
                      {t('Comments')}
                    </TableCell>
                    <TableCell style={{ color: '#FFFFFF' }}>
                      {t('Date')}
                    </TableCell>
                    <TableCell style={{ color: '#FFFFFF' }}>
                      {t('Time')}
                    </TableCell>
                    <TableCell padding="none" />
                    <TableCell padding="none" />
                    <TableCell padding="none" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    // (activeFilter.group === GroupProps.ALL
                    //   ? rowsPerPage > 0
                    //     ? filterData.slice(
                    //         page * rowsPerPage,
                    //         page * rowsPerPage + rowsPerPage
                    //       )
                    //     : filterData
                    //   : rowsPerPage > 0
                    //   ? userSymptoms.slice(
                    //       page * rowsPerPage,
                    //       page * rowsPerPage + rowsPerPage
                    //     )
                    //   : userSymptoms
                    (rowsPerPage > 0
                      ? filtered.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : filtered
                    ).map((row, i) => (
                      <TableRow key={i}>
                        {!isBelowXs && <TableCell padding="checkbox" />}
                        {activeSymptom.isBoolean === false && (
                          <TableCell>{row.value}</TableCell>
                        )}
                        <TableCell padding="none">
                          {row.description.length < 15 ? (
                            row.description
                          ) : (
                            <Tooltip
                              title={row.description}
                              classes={{ tooltip: classes.customWidth }}
                            >
                              <Box
                                style={{
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  maxWidth: 190,
                                }}
                              >
                                {row.description}
                              </Box>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(row.time).format(DateFormats.GrNoTime)}
                        </TableCell>
                        <TableCell>
                          {moment(row.time).format(DateFormats.TIME)}
                        </TableCell>
                        <TableCell
                          align="center"
                          padding="none"
                          style={isBelowXs ? { padding: '0px 8px' } : {}}
                        >
                          <EditIcon
                            style={{
                              color: 'rgba(0, 0, 0, 0.58)',
                              fontSize: 18,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              window.scrollTo(0, 0);
                              setSymptomToEdit(row);
                            }}
                          />
                        </TableCell>
                        <TableCell align="center" padding="none">
                          <Box
                            style={{
                              backgroundColor: 'rgba(0, 0, 0, 0.34)',
                              width: 2,
                              height: 40,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          padding="none"
                          style={isBelowXs ? { padding: '0px 8px' } : {}}
                        >
                          <DeleteIcon
                            style={{
                              color: 'rgba(0, 0, 0, 0.58)',
                              fontSize: 18,
                              cursor: 'pointer',
                            }}
                            onClick={async () => {
                              await deleteSymptom(row.id);
                              setSymptomToEdit(undefined);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        10,
                        20,
                        100,
                        { label: 'All', value: -1 },
                      ]}
                      labelRowsPerPage={t('RowsText')}
                      count={filtered.length}
                      onChangePage={handleChangePage}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          </Box>
        )}
      </Container>
    );
  } else {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
});

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  container1: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  padd: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    backgroundColor: '#FFFFFF',
  },
  graph: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    height: '100%',
  },
  addBox: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
    marginLeft: 10,
    flexDirection: 'column',
    width: '42%',
    height: 'fit-content',
  },
  text: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  customWidth: {
    maxWidth: 500,
  },
}));
