import { makeStyles, Paper, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HeartDiagnosis } from './Diagnosis/HeartDiagnosis';
import { OsteoDiagnosis } from './Diagnosis/OsteoDiagnosis';
import { StrokeDiagnosis } from './Diagnosis/StrokeDiagnosis';

export const Diagnosis = () => {
  const [tabValue, setTabValue] = useState('1');
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Paper>
      <TabContext value={tabValue}>
        <TabList
          onChange={(_, val) => setTabValue(val)}
          indicatorColor="primary"
          textColor="primary"
          classes={{
            root: classes.root,
            scroller: classes.scroller,
            flexContainer: classes.flexContainer,
          }}
        >
          <Tab label={t('Test1')} value="1" />
          <Tab label={t('Test2')} value="2" />
          <Tab label={t('Test3')} value="3" />
        </TabList>
        <TabPanel value="1">
          <HeartDiagnosis />
        </TabPanel>
        <TabPanel value="2">
          <StrokeDiagnosis />
        </TabPanel>
        <TabPanel value="3">
          <OsteoDiagnosis />
        </TabPanel>
      </TabContext>
    </Paper>
  );
};
const useStyles = makeStyles((theme) => ({
  iconLabelWrapper: {
    flexDirection: 'row',
  },
  root: {
    justifyContent: 'center',
  },
  scroller: {
    flexGrow: 1,
  },
  flexContainer: {
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  container: {
    justifyContent: 'center',
  },
}));
