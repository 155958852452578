import { CSSProperties } from '@material-ui/styles';
import React, { PropsWithChildren } from 'react';
import { hot } from 'react-hot-loader/root';
import {
  Area,
  AreaChart,
  AreaChartProps,
  ComposedChart,
  ResponsiveContainer,
  Scatter,
} from 'recharts';
import { Colors } from 'res/Enums';
import { useMediaQuery } from '@material-ui/core';
import theme from '../assets/theme';

interface StyledPregnancyChartProps extends AreaChartProps {
  dataKey: string;
  dataKey2?: string;
  dataKey3?: string;
  aspect?: number;
}
const _StyledPregnancyChart = ({
  style,
  children,
  dataKey2,
  dataKey3,
  aspect,
  ...rest
}: PropsWithChildren<StyledPregnancyChartProps>) => {
  const isBelowXs = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ResponsiveContainer width="100%" aspect={aspect ?? 2}>
      <ComposedChart
        {...rest}
        style={{ ...styles, ...style }}
        key={Math.random()}
      >
        {children}
        {dataKey2 && (
          <Area
            dataKey={dataKey2}
            type="monotone"
            stroke="cornflowerblue"
            strokeOpacity={3}
            fillOpacity={0.08}
            fill="cornflowerblue"
            strokeWidth={isBelowXs ? 3 : 4}
          />
        )}
        {dataKey3 && (
          <Area
            dataKey={dataKey3}
            type="monotone"
            stroke="cornflowerblue"
            fillOpacity={3}
            fill="white"
            strokeWidth={isBelowXs ? 3 : 4}
          />
        )}
        <Scatter
          dataKey={rest.dataKey}
          stroke={Colors.PrimaryLight}
          fill={Colors.PRIMARY}
          strokeWidth={isBelowXs ? 3 : 4}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const styles: CSSProperties = {};
export const StyledPregnancyChart = hot(_StyledPregnancyChart);
