import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { SymptomDTO, UserSymptomDTO } from 'gen/client';
import SymptomFace from 'assets/icons/SymptomFace.svg';
import { ImageButton } from 'components/ImageButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Colors } from 'res/Enums';

interface Symptom {
  selectedType: SymptomDTO;
  data: UserSymptomDTO[];
}

const Symptom = ({ history }: RouteComponentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container>
      <Box
        style={{
          display: 'Flex',
          flexDirection: 'row',
          paddingTop: '2.5%',
          paddingLeft: '2%',
        }}
      >
        <img alt="" src={SymptomFace} />
        <Typography
          style={{
            paddingLeft: 10,
            color: Colors.Black,
            fontSize: 22,
            fontWeight: 'bold',
          }}
        >
          {t('SymptomFace')}
        </Typography>
      </Box>
      <Box style={{ marginTop: '2.5%' }}>
        <Typography
          style={{
            color: '#000000',
            fontWeight: 'bold',
            fontSize: 22,
          }}
        >
          {t('Symptoms')}
        </Typography>
        <Grid style={{ marginTop: 20 }} container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="1" text={t('Fatigue')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="2" text={t('Constipation')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="3" text={t('Nausea')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="4" text={t('Diarrhea')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="5" text={t('Vomiting')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="6" text={t('Hyperglycaemia')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="7" text={t('Hypoglycaemia')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="8" text={t('Migraine')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="9" text={t('Itching')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="10" text={t('Headache')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="11" text={t('Pain')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="12" text={t('Fever')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="13" text={t('Dyspnea')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="14" text={t('Hematochezia')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="15" text={t('Cramps')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="16" text={t('Ageusia')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="17" text={t('Anosmia')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="18" text={t('SoreThroat')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="19" text={t('Cough')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="20" text={t('Snuffles')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="21" text={t('Dyspepsia')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="22" text={t('Heartburn')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton link="symptoms" id="23" text={t('MoodSwings')} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ImageButton
              link="symptoms"
              id="24"
              text={t('FrequentUrination')}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          marginTop: 24,
        }}
      >
        <Link
          underline="always"
          style={{
            color: Colors.BlueText,
            fontWeight: 'normal',
            fontSize: isBelowXs ? 16 : 20,
            cursor: 'pointer',
            textAlign: 'center',
          }}
          onClick={() => {
            history.push('/calendar');
          }}
        >
          {t('GoToCalendar')}
        </Link>
      </Box>
      {/* <Box style={{ marginTop: 100 }}>
        <Typography
          style={{
            backgroundColor: 'rgba(92, 165, 149, 0.15)',
            padding: 17,
            paddingLeft: 40,
            fontSize: 20,
            fontWeight: 'bold'
          }}
        >
          {t('AllMeasurement')}
        </Typography>
      </Box> */}
    </Container>
  );
};
export default withRouter(Symptom);
