import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  LatestEmviromentals,
  useEnviromentalControllerGetMyLatestEnviromentals,
} from 'gen/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, DateFormats } from 'res/Enums';

export const Cross = () => {
  const [latest, setLatest] = useState<LatestEmviromentals>();
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: envData } = useEnviromentalControllerGetMyLatestEnviromentals();

  useEffect(() => {
    if (envData) setLatest(envData.data);
  }, [envData]);

  return (
    <Container>
      <Box style={{ marginTop: 32, display: 'flex', justifyContent: 'center' }}>
        <Typography
          style={{
            color: Colors.Black,
            paddingTop: '4%',
            fontSize: 28,
            fontWeight: 'bold',
          }}
        >
          {t('Room')}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Typography
                style={{
                  margin: '7% 0% 0% 0%',
                  display: 'flex',
                  justifyContent: 'center',
                  color: Colors.Black,
                  fontSize: 22,
                  fontWeight: 'bold',
                }}
              >
                {t('Temperature')}
              </Typography>
              <Typography
                style={{
                  margin: '7% 0% 7% 0%',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 22,
                }}
              >
                {latest?.type1?.value ?? '-'}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Typography
                style={{
                  margin: '7% 0% 0% 0%',
                  display: 'flex',
                  justifyContent: 'center',
                  color: Colors.Black,
                  fontSize: 22,
                  fontWeight: 'bold',
                }}
              >
                {t('Humidity')}
              </Typography>
              <Typography
                style={{
                  margin: '7% 0% 7% 0%',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 22,
                }}
              >
                {latest?.type2?.value ?? '-'}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Typography
                style={{
                  margin: '7% 0% 0% 0%',
                  display: 'flex',
                  justifyContent: 'center',
                  color: Colors.Black,
                  fontSize: 22,
                  fontWeight: 'bold',
                }}
              >
                {t('Air')}
              </Typography>
              <Typography
                style={{
                  margin: '7% 0% 7% 0%',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 22,
                }}
              >
                {latest?.type3?.value ?? '-'}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          marginTop: 16,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          style={{ color: Colors.Black, fontSize: 20, fontWeight: 'lighter' }}
        >
          {t('MeasurementAt')}
          {latest?.type1?.time
            ? moment(latest?.type1.time).format(DateFormats.GR)
            : '-'}
        </Typography>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: 80,
    display: 'flex',
  },
}));
