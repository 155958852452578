import {
  Box,
  Container,
  Divider,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import InsulinBox from 'components/Diabetes/InsulinBox';
import { ShortInfo } from 'components/Diabetes/ShortInfo';
import { FilterBar, useFilter } from 'components/Filter/FilterBar';
import { StyledLineChart } from 'components/LineChart';
import { TablePaginationActions } from 'components/TablePaginationActions';
import {
  useInsulinControllerDeleteMyInsulin,
  useInsulinControllerGetMyInsulin,
  UserInsulinDTO,
} from 'gen/client';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip as TooltipChart, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';

export const Insulin = withRouter(({ history }) => {
  const [userInsulin, setUserInsulin] = useState<UserInsulinDTO[]>([]);
  const [filterData, setFilterData] = useState<UserInsulinDTO[]>([]);
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { data, isLoading, refetch } = useInsulinControllerGetMyInsulin();
  const deleteMutation = useInsulinControllerDeleteMyInsulin();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [insulinToEdit, setInsulinToEdit] = useState<UserInsulinDTO>();
  const [activeFilter, setActiveFilter] = useFilter();
  const { t } = useTranslation();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteInsulin = async (id: number) => {
    try {
      const response = await deleteMutation.mutateAsync({ id });
      if (response) {
        toast(t('InsulinDeleted'));
        refetch();
      } else {
        toast(t('FailedDeleting'), { containerId: 'deleted' });
      }
    } catch (e) {
      toast(t('Failed'), { containerId: 'deleted' });
    }
  };

  useEffect(() => {
    if (data) {
      setUserInsulin(data.data);
      setFilterData(data.data);
    }
  }, [data]);
  const classes = useStyles();

  if (isLoading) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
  return (
    <Container className={classes.container}>
      <Box display="flex" flexDirection="row">
        <Typography
          style={{
            paddingLeft: 30,
            color: '#000000',
            fontSize: isBelowXs ? 24 : 30,
            fontWeight: 'bold',
          }}
        >
          {t('Insulin')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {userInsulin.length > 0 && (
          <Grid item md={7} xs={12}>
            <Box style={{ margin: '8px 0px' }}>
              <Typography
                style={{
                  padding: '16px 0px',
                  color: Colors.Black,
                  fontSize: isBelowXs ? 18 : 20,
                  fontWeight: 'bold',
                }}
              >
                {t('Diagram')}
              </Typography>
            </Box>
            <Box
              className={classes.padd}
              style={isBelowXs ? { padding: 0 } : {}}
            >
              <Box display="flex" style={{ width: isBelowXs ? '100%' : '60%' }}>
                <ShortInfo latest={userInsulin[0]} />
              </Box>
              <Divider
                style={{
                  marginTop: '3%',
                  backgroundColor: '#E3DFFA',
                }}
              />
              <Box
                className={clsx([classes.graph])}
                style={isBelowXs ? { padding: 6 } : {}}
              >
                <FilterBar<UserInsulinDTO>
                  data={userInsulin}
                  enables={{ date: true, special: true }}
                  onFilter={(data) => setFilterData(data)}
                  dateKey="time"
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                />
                {filterData.length > 0 ? (
                  <StyledLineChart
                    data={filterData
                      .map(({ time, ...rest }) => ({
                        ...rest,
                        time: moment(time).format(DateFormats.GR),
                      }))
                      .slice(0, 100)
                      .reverse()}
                    dataKey="quantity"
                  >
                    <XAxis dataKey="time" axisLine={false} tickLine={false} />
                    <YAxis
                      dataKey="quantity"
                      axisLine={false}
                      tickLine={false}
                    />
                    <TooltipChart
                      formatter={(datakey, name) => [datakey, t('Quantity')]}
                    />
                  </StyledLineChart>
                ) : (
                  <Box
                    style={{
                      display: 'flex',
                      height: '65%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: Colors.Black,
                    }}
                  >
                    {t('NoFilter')}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item md={5} xs={12}>
          <Box style={{ margin: '8px 0px' }}>
            <Typography
              style={{
                padding: '16px 0px',
                color: Colors.Black,
                fontSize: !isBelowXs ? 20 : 18,
                fontWeight: 'bold',
              }}
            >
              {t('InsulinAdd')}
            </Typography>
          </Box>
          <InsulinBox
            onAdd={() => {
              refetch();
            }}
            onEdit={() => {
              refetch();
            }}
            onCancel={() => setInsulinToEdit(undefined)}
            insulin={insulinToEdit}
            lastInsulin={userInsulin[0]}
          />
        </Grid>
      </Grid>
      {userInsulin.length > 0 && (
        <Box>
          <Paper style={{ marginTop: 30, overflow: 'auto' }}>
            <Box style={{ margin: '8px 0px' }}>
              <FilterBar<UserInsulinDTO>
                data={userInsulin}
                enables={{ date: true, special: true }}
                onFilter={(data) => setFilterData(data)}
                dateKey="time"
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            </Box>
            <Table>
              <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                <TableRow>
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('Value')}
                  </TableCell>
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('Date')}
                  </TableCell>
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('Time')}
                  </TableCell>
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('Details')}
                  </TableCell>
                  <TableCell style={{ color: '#FFFFFF' }}>
                    {t('InsulinType')}
                  </TableCell>
                  <TableCell padding="none" />
                  <TableCell padding="none" />
                  <TableCell padding="none" />
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filterData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filterData
                ).map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell>
                      {moment(row.time).format(DateFormats.GrNoTime)}
                    </TableCell>
                    <TableCell>
                      {moment(row.time).format(DateFormats.TIME)}
                    </TableCell>
                    <TableCell>
                      {row.special === 'BEFORE'
                        ? t('Before')
                        : row.special === 'AFTER'
                        ? t('After')
                        : t('Other')}
                    </TableCell>
                    <TableCell>{row.insulin.name}</TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      style={isBelowXs ? { padding: '0px 8px' } : {}}
                    >
                      <EditIcon
                        style={{
                          color: 'rgba(0, 0, 0, 0.58)',
                          fontSize: 18,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          window.scrollTo(0, 0);
                          setInsulinToEdit(row);
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      style={isBelowXs ? { padding: '0px 8px' } : {}}
                    >
                      <Box
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, 0.34)',
                          width: 2,
                          height: 40,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" padding="none">
                      <DeleteIcon
                        style={{
                          color: 'rgba(0, 0, 0, 0.58)',
                          fontSize: 18,
                          cursor: 'pointer',
                        }}
                        onClick={async () => {
                          await deleteInsulin(row.id);
                          setInsulinToEdit(undefined);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      20,
                      100,
                      { label: 'All', value: -1 },
                    ]}
                    labelRowsPerPage={t('RowsText')}
                    count={userInsulin.length}
                    onChangePage={handleChangePage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Box>
      )}
    </Container>
  );
});

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  container1: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  padd: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    backgroundColor: '#FFFFFF',
  },
  graph: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
  },
  addBox: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
    marginLeft: 10,
    flexDirection: 'column',
    width: '42%',
    height: 'fit-content',
  },
  text: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  customWidth: {
    maxWidth: 500,
  },
}));
