import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  MenuItem,
  Slider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import Image from 'assets/icons/FitnessAdd.svg';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Colors, DateFormats } from 'res/Enums';
import { StyledDateTimePicker } from 'components/DateTimePicker';
import Select from '@material-ui/core/Select/Select';
import {
  FitnessDTO,
  useFitnessControllerCreateMyFitness,
  useFitnessControllerGetFitnessById,
  useFitnessControllerUpdateMyFitness,
  UserFitnessDTO,
} from 'gen/client';

interface FitnessBoxProps extends RouteComponentProps<{ id: string }> {
  onAdd: () => void;
  onEdit: () => void;
  onCancel: () => void;
  fitness?: UserFitnessDTO;
  active: FitnessDTO;
  types: FitnessDTO[];
  lastFitness?: UserFitnessDTO;
}

const _FitnessBox = ({
  fitness,
  onAdd,
  onEdit,
  onCancel,
  active,
  lastFitness,
  types,
}: FitnessBoxProps) => {
  const [activeFitness, setActiveFitness] = useState<FitnessDTO>(active);
  const [time, setTime] = useState(moment());
  const [distance, setDistance] = useState<string | undefined>('');
  const [comments, setComments] = useState('');
  const [activityTime, setActivityTime] = useState<number>();
  const [sec, setSec] = useState<string>('');
  const [min, setMin] = useState<string>('');
  const [hour, setHour] = useState<string>('');
  const [activityScore, setActivityScore] = useState(1);
  const [endTime, setEndTime] = useState(moment());
  const [editMode, setEditMode] = useState(false);
  const [selectedType, setSelectedType] = useState(active.id.toString());
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { data: activeData, refetch: getActiveFitness } =
    useFitnessControllerGetFitnessById(selectedType);

  const createMyFitness = useFitnessControllerCreateMyFitness();
  const updateMyFitness = useFitnessControllerUpdateMyFitness();
  // const getActiveFitness = async () => {
  //   try {
  //     const data1 = await apiClient().getFitnessById(selectedType);
  //     setActiveFitness(data1);
  //   } catch (e) {}
  // };

  useEffect(() => {
    if (activeData) {
      setActiveFitness(activeData.data);
    }
  }, [activeData]);

  const newFitness = async () => {
    try {
      const response = await createMyFitness.mutateAsync({
        data: {
          distance: distance ? parseFloat(distance) : undefined,
          time: time.format(DateFormats.ISO),
          fitness: activeFitness,
          comments: comments,
          activityTime: activityTime,
          activityScore: activityScore,
          endTime: endTime.toISOString(),
        },
      });
      if (response.data) {
        toast(t('FitnessAdded'));
        handleRefresh();
        onAdd();
      } else {
        toast(t('FailedAdding'), { containerId: 'delete' });
      }
    } catch (error) {
      toast(t('Problem'), { containerId: 'deleted' });
    }
  };

  const updateFitness = async (fitness: UserFitnessDTO) => {
    try {
      const response = await updateMyFitness.mutateAsync({
        id: fitness.id,
        data: {
          distance: distance ? parseFloat(distance) : undefined,
          time: time.format(DateFormats.ISO),
          fitness: fitness.fitness,
          comments: comments,
          activityTime: activityTime,
          activityScore: activityScore,
          endTime: endTime.toISOString(),
        },
      });
      if (response.data) {
        toast(t('FitnessEdited'));
        handleRefresh();
        onEdit();
      }
    } catch (error) {
      toast(t('Problem'));
    }
  };

  const handleTime = async () => {
    if (hour === '' && min === '' && sec === '') setActivityTime(undefined);
    else {
      setActivityTime(
        parseInt(hour !== '' ? hour : '0') * 3600 +
          parseInt(min !== '' ? min : '0') * 60 +
          parseInt(sec !== '' ? sec : '0')
      );
    }
  };

  const handleChange = (
    event: any,
    newValue: React.SetStateAction<number> | React.SetStateAction<number[]>
  ) => {
    setActivityScore(newValue as number);
  };

  const handleRefresh = async () => {
    setDistance('');
    setTime(moment());
    setComments('');
    setActivityScore(1);
    setActivityTime(undefined);
    setHour('');
    setMin('');
    setSec('');
    setEndTime(moment());
  };

  useEffect(() => {
    if (fitness) {
      if (fitness.distance !== null) {
        if (fitness.distance) setDistance(fitness.distance.toString());
        else setDistance(undefined);
      }
      setTime(moment(fitness.time));
      setComments(fitness.comments);
      if (fitness.activityScore !== null)
        setActivityScore(fitness.activityScore);
      if (fitness.activityTime !== null) {
        setActivityTime(fitness.activityTime);
        if (fitness.activityTime) {
          setHour(Math.floor(fitness.activityTime / 3600).toString());
          setMin(
            Math.floor(
              (fitness.activityTime -
                Math.floor(fitness.activityTime / 3600) * 3600) /
                60
            ).toString()
          );
          setSec(
            Math.floor(
              fitness.activityTime -
                Math.floor(fitness.activityTime / 3600) * 3600 -
                Math.floor(
                  (fitness.activityTime -
                    Math.floor(fitness.activityTime / 3600) * 3600) /
                    60
                ) *
                  60
            ).toString()
          );
        }
      }
      setEndTime(moment(fitness.endTime));
      setEditMode(true);
    } else {
      setDistance('');
      setTime(moment());
      setComments('');
      setActivityScore(1);
      setActivityTime(undefined);
      setHour('');
      setMin('');
      setSec('');
      setEndTime(moment());
      setEditMode(false);
    }
  }, [fitness]);

  useEffect(() => {
    getActiveFitness();
  }, [selectedType]);

  useEffect(() => {
    handleTime();
  }, [hour, min, sec]);

  useEffect(() => {
    if (distance && parseInt(distance) <= 0) setDistance('');
    if (parseInt(hour) <= 0) setHour('');
    if (parseInt(min) <= 0) setMin('');
    if (parseInt(sec) <= 0) setSec('');
  }, [distance, hour, min, sec]);

  const classes = useStyles();
  return (
    <Container style={{ display: 'flex', flexDirection: 'column' }}>
      <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '5%' }}>
        <img alt="" src={Image} />
        <Typography
          style={{
            fontSize: isBelowXs ? 20 : 34,
            fontWeight: 'bold',
            color: Colors.Black,
            marginLeft: 16,
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
          }}
        >
          {!fitness ? t('FitnessReg') : t('FitnessRegEdit')}
        </Typography>
        <IconButton
          onClick={() => {
            if (editMode === true) {
              handleRefresh();
              setEditMode(false);
              onCancel();
            } else onCancel();
          }}
          style={{ paddingRight: 16 }}
        >
          <CloseIcon style={{ fontSize: isBelowXs ? 30 : 40 }} />
        </IconButton>
      </Box>
      <Box display="flex" className={classes.addBox}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            paddingRight: 30,
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              color: '#263238',
            }}
          >
            {t('CleanData')}
          </Typography>
          <IconButton style={{ fontSize: 20 }} onClick={handleRefresh}>
            <RefreshIcon
              fontSize="inherit"
              style={{
                fontWeight: 'bold',
                color: Colors.PRIMARY,
                transform: 'rotateY(180deg)',
              }}
            />
          </IconButton>
        </Box>
        {activeFitness.hasDistance === true && (
          <Box
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Grid
              container
              // className={classes.text}
              className={classes.grid}
            >
              <Grid
                item
                xs={5}
                sm={4}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  style={{
                    color: Colors.Black,
                    fontWeight: 'bold',
                    fontSize: 16,
                    paddingLeft: isBelowXs ? 4 : 20,
                  }}
                >
                  {t('Distance')}
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8}>
                <TextField
                  style={{ width: '100%' }}
                  value={distance}
                  type="number"
                  inputProps={{ style: isBelowXs ? { fontSize: 14 } : {} }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          color: 'rgba(0, 0, 0, 0.34)',
                          fontSize: 16,
                        }}
                      >
                        {activeFitness !== null && activeFitness.units
                          ? t('Meters')
                          : undefined}
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('Placeholder')}
                  onChange={(e) => setDistance(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Grid
            container
            // className={classes.text}
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Typography
                style={{
                  color: Colors.Black,
                  fontWeight: 'bold',
                  fontSize: 16,
                  paddingLeft: 20,
                }}
              >
                {t('ActivityTime')}
              </Typography>
            </Grid>
            {/* <Grid item xs={8}>
              <TextField
                style={{ width: '100%' }}
                value={activityTime}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        color: 'rgba(0, 0, 0, 0.34)',
                        fontSize: 16
                      }}
                    >
                      {activeFitness !== null ? 'seconds' : undefined}
                    </InputAdornment>
                  )
                }}
                placeholder={t('Placeholder')}
                onChange={e => setActivityTime(e.target.value)}
              />
            </Grid> */}
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'space-around' }}
            >
              <Box textAlign="center">
                <TextField
                  value={hour}
                  type="number"
                  placeholder="00"
                  autoFocus
                  inputProps={{
                    style: { textAlign: 'center' },
                  }}
                  onChange={(e) => setHour(e.target.value)}
                  style={{
                    maxWidth: 50,
                    border: '2px solid #5CA595',
                    borderRadius: 6,
                  }}
                />
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    marginTop: 8,
                  }}
                >
                  {t('Hours')}
                </Typography>
              </Box>
              <Box textAlign="center">
                <TextField
                  value={min}
                  type="number"
                  placeholder="00"
                  autoFocus
                  inputProps={{
                    style: { textAlign: 'center' },
                  }}
                  onChange={(e) => setMin(e.target.value)}
                  style={{
                    maxWidth: 50,
                    border: '2px solid #5CA595',
                    borderRadius: 6,
                  }}
                />
                <Typography
                  style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    marginTop: 8,
                  }}
                >
                  {t('Minutes')}
                </Typography>
              </Box>
              <Box textAlign="center">
                <TextField
                  value={sec}
                  type="number"
                  placeholder="00"
                  autoFocus
                  inputProps={{
                    style: { textAlign: 'center' },
                  }}
                  onChange={(e) => setSec(e.target.value)}
                  style={{
                    maxWidth: 50,
                    border: '2px solid #5CA595',
                    borderRadius: 6,
                  }}
                />
                <Typography
                  style={{ fontSize: 12, fontWeight: 'bold', marginTop: 8 }}
                >
                  {t('Seconds')}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'space-around' }}
            >
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  paddingLeft: lang === 'gr' ? 16 : 0,
                }}
              >
                {t('Hours')}
              </Typography>
              <Typography
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  paddingLeft: lang === 'gr' ? 35 : 0,
                }}
              >
                {t('Minutes')}
              </Typography>
              <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
                {t('Seconds')}
              </Typography>
            </Grid> */}
          </Grid>
        </Box>
        {/* <Box
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Grid
            container
            // className={classes.text}
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Typography
                style={{
                  color: Colors.Black,
                  fontWeight: 'bold',
                  fontSize: 16,
                  paddingLeft: 20
                }}
              >
                {t('Comments').concat(':')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%', paddingRight: 20, paddingLeft: 20 }}
                value={comments}
                multiline
                onChange={e => setComments(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box> */}
        {activeFitness.hasDistance === false && (
          <Box
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Grid
              container
              // className={classes.text}
              className={classes.grid}
            >
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: Colors.Black,
                    fontWeight: 'bold',
                    fontSize: 16,
                    paddingLeft: 20,
                  }}
                >
                  {t('FitnessInten')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Slider
                  valueLabelDisplay="on"
                  step={1}
                  marks
                  min={1}
                  max={5}
                  value={activityScore}
                  onChange={handleChange}
                  style={{ padding: 0, margin: '50px 30px 10px 30px' }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Grid
            container
            // className={classes.text}
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Typography
                style={{
                  color: Colors.Black,
                  fontWeight: 'bold',
                  fontSize: 16,
                  paddingLeft: 20,
                }}
              >
                {t('DateTime')} {t('FitnessBegun')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledDateTimePicker
                autoOk
                autoFocus
                style={{ paddingLeft: 20, paddingRight: 20, width: '100%' }}
                value={time}
                margin="normal"
                format={DateFormats.GR}
                onChange={(date) => setTime(date as Moment)}
                animateYearScrolling
                disableFuture
              />
            </Grid>
          </Grid>
        </Box>
        {activeFitness.hasDistance === false && (
          <Box
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Grid
              container
              // className={classes.text}
              className={classes.grid}
            >
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: Colors.Black,
                    fontWeight: 'bold',
                    fontSize: 16,
                    paddingLeft: 20,
                  }}
                >
                  {t('Comments')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <TextField
                  style={{ width: '100%', padding: '0px 20px' }}
                  value={comments}
                  multiline
                  onChange={(e) => setComments(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          {editMode === false && (
            <Grid
              container
              // className={classes.text}
              className={classes.grid}
            >
              <Grid
                item
                xs={4}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  style={{
                    color: Colors.Black,
                    fontWeight: 'bold',
                    fontSize: 16,
                    paddingLeft: isBelowXs ? 4 : 20,
                  }}
                >
                  {t('FitnessType')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingRight: 16,
                }}
              >
                <Select
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value as string)}
                >
                  {types.map((fitness, i) => (
                    <MenuItem
                      className={classes.item}
                      value={fitness.id}
                      key={i}
                    >
                      {t(fitness.name)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          )}
        </Box>

        {editMode === true && fitness ? (
          <Button
            style={{
              marginTop: '10%',
              marginLeft: 50,
              marginRight: 50,
              fontSize: isBelowXs ? 13 : 14,
              marginBottom: isBelowXs ? 16 : 0,
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              updateFitness(fitness);
              setEditMode(false);
            }}
            disabled={
              (activeFitness.hasDistance === true &&
                distance === '' &&
                hour === '' &&
                min === '' &&
                sec === '') ||
              (activeFitness.hasDistance === false &&
                hour === '' &&
                min === '' &&
                sec === '')
            }
            // disabled={
            //   (activeFitness.hasDistance === true &&
            //     !(
            //       (hour !== '' && min !== '' && sec !== '') ||
            //       (distance !== '' && (hour !== '' || min !== '' || sec !== '')
            //         ? hour !== '' && min !== '' && sec !== ''
            //         : distance !== '')
            //     )) ||
            //   (activeFitness.hasDistance === false &&
            //     (hour === '' || min === '' || sec === ''))
            // }
          >
            {t('EditMeasurement')}
          </Button>
        ) : (
          <Button
            style={{
              marginTop: '10%',
              marginLeft: 50,
              marginRight: 50,
              fontSize: isBelowXs ? 13 : 14,
              marginBottom: isBelowXs ? 16 : 0,
            }}
            variant="contained"
            color="primary"
            endIcon={
              <AddIcon
                style={{
                  marginLeft: isBelowXs ? 0 : 20,
                  fontSize: isBelowXs ? 30 : 40,
                }}
              />
            }
            onClick={() => {
              newFitness();
            }}
            disabled={
              (activeFitness.hasDistance === true &&
                distance === '' &&
                hour === '' &&
                min === '' &&
                sec === '') ||
              (activeFitness.hasDistance === false &&
                hour === '' &&
                min === '' &&
                sec === '')
            }
            // disabled={
            //   (activeFitness.hasDistance === true &&
            //     !(
            //       (hour !== '' && min !== '' && sec !== '') ||
            //       (distance !== '' && (hour !== '' || min !== '' || sec !== '')
            //         ? hour !== '' && min !== '' && sec !== ''
            //         : distance !== '')
            //     )) ||
            //   (activeFitness.hasDistance === false &&
            //     (hour === '' || min === '' || sec === ''))
            // }
          >
            {t('FitnessReg')}
          </Button>
        )}
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  addBox: {
    backgroundColor: '#FFFFFF',
    // border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 6,
    marginLeft: 10,
    flexDirection: 'column',
    // width: '42%',
    height: 'fit-content',
    marginTop: '5%',
  },
  text: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  grid: {
    display: 'flex',
    // flexDirection: 'row',
    marginTop: '3%',
    width: '80%',
    background: '#F5F6F7',
    padding: '10px 0px 10px 0px',
    border: '1px solid rgba(38, 50, 56, 0.12)',
    borderRadius: 2,
  },
  item: {
    fontSize: 14,
    fontWeight: 'bold',
    background: 'rgba(196, 196, 196, 0.27)',
    color: Colors.Black,
  },
}));
export const FitnessBox = withRouter(_FitnessBox);
