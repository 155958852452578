import {
  Box,
  createStyles,
  makeStyles,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import clsx from 'clsx';
import { UserFitnessDTO } from 'gen/client';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, DateFormats } from 'res/Enums';

interface LatestFitnessProps {
  latest: UserFitnessDTO;
  style?: CSSProperties;
}
export const ShortInfo = ({ latest }: LatestFitnessProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className={classes.latestInfo}>
      {latest.fitness.hasDistance === true ? (
        <Box
          className={clsx([classes.column, classes.valueBox])}
          style={isBelowXs ? { padding: 0 } : {}}
        >
          <Box
            style={{
              fontSize: isBelowXs ? 26 : 34,
              fontWeight: 'bold',
              minWidth: isBelowXs ? 60 : 80,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {latest.distance === null && latest.activityTime
              ? Math.round(latest.activityTime / 60)
              : latest.distance}
          </Box>
          <Box style={{ fontSize: 14 }}>
            {latest.fitness.units && latest.distance !== null
              ? latest.fitness.units
              : t('Minutes')}
          </Box>
        </Box>
      ) : (
        <Box className={clsx([classes.column, classes.valueBox])}>
          <Box style={{ fontSize: 34, fontWeight: 'bold' }}>
            {latest.activityTime ? Math.round(latest.activityTime / 60) : ''}
          </Box>
          <Box style={{ fontSize: 14 }}>{t('Minutes')}</Box>
        </Box>
      )}
      <Box
        className={classes.column}
        justifyContent="space-evenly"
        paddingLeft={2}
        style={isBelowXs ? { paddingLeft: 6 } : {}}
      >
        <Box style={{ fontSize: isBelowXs ? 16 : 17 }}>
          {latest.fitness.name}
        </Box>
        <Box style={{ fontSize: isBelowXs ? 14 : 16, color: 'gray' }}>
          {t('MeasurementAt')}
        </Box>
        <Box style={{ fontSize: isBelowXs ? 16 : 18, color: Colors.FadeBlack }}>
          {moment(latest.time).format(DateFormats.GR)}
          {latest.comments !== '' &&
            (latest.comments && latest.comments.length < 40 ? (
              <Box
                style={{ fontSize: 14, fontWeight: 'lighter', color: 'gray' }}
              >
                {t('Comments')}: {latest.comments}
              </Box>
            ) : (
              <Tooltip
                title={t('FitnessComment').concat(latest.comments)}
                classes={{ tooltip: classes.customWidth }}
              >
                <Box
                  style={{ fontSize: 16, fontWeight: 'lighter', color: 'gray' }}
                >
                  {t('Comments')}:
                </Box>
              </Tooltip>
            ))}
        </Box>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    padd: {
      padding: theme.spacing(2),
      border: '1px solid #E3DFFA',
    },
    valueBox: {
      alignItems: 'center',
      padding: theme.spacing(3),
      borderRight: '2px solid #F5F6F7',
      color: Colors.FadeBlack,
      justifyContent: 'center',
    },
    latestInfo: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      display: 'flex',
      flexGrow: 1,
    },
    addButton: {
      backgroundColor: '#F5F6F7',
      border: ' 1px solid rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      borderRadius: 90,
      margin: '0px 10px',
      float: 'right',
      width: 'auto',
    },
    pressBox: {
      alignItems: 'center',
      color: Colors.FadeBlack,
      fontSize: 24,
      fontWeight: 'bold',
      margin: theme.spacing(1),
      textAlign: 'center',
    },
    customWidth: {
      maxWidth: 500,
    },
  })
);
