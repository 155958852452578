import divider from 'assets/icons/Divider.png';
import logo from 'assets/icons/logo.svg';
import { Box, makeStyles, Grid, Hidden } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader/root';
import { Carousel } from 'components/Carousel/Carousel';
import { IntroPage1 } from 'components/Carousel/IntroPage1';
import { IntroPage2 } from 'components/Carousel/IntroPage2';
interface IntroPageProps {
  children: ReactNode;
}
const _IntroPage = ({ children }: IntroPageProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Hidden smDown>
        <Grid item md={8} className={classes.itemLeft}>
          <Carousel>
            <IntroPage1 />
            <IntroPage2 />
          </Carousel>
          <img alt="" src={divider} className={classes.divider} />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={4} className={classes.itemRight}>
        {/* <ChangeLanguageButton /> */}
        <Box className={classes.rightWrapper}>
          <Box className={classes.logoWrapper}>
            <img alt="" src={logo} />
          </Box>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#ffffff',
    height: '100%',
  },
  itemLeft: {
    position: 'relative',
  },
  itemRight: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
  },
  divider: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
  },
  rightWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: '1 1 auto',
    margin: '0 20%',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexGrow: 1,
  },
}));
export const IntroPage = hot(_IntroPage);
