import {
  Box,
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  Divider,
  Grid,
  useTheme,
  useMediaQuery,
  Tooltip as TooltipTable,
} from '@material-ui/core';
import clsx from 'clsx';
import { FilterBar, useFilter, GroupProps } from 'components/Filter/FilterBar';
import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Tooltip, XAxis, YAxis } from 'recharts';
import { Colors, DateFormats } from 'res/Enums';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { StyledBarChart } from 'components/BarChart';
import { ShortInfo as ShortInfoSymptom } from 'components/Symptoms/ShortInfo';
import { MeasurementDTO, UserSymptomDTO } from 'gen/client';

interface GraphProps {
  active: MeasurementDTO;
  symptoms: UserSymptomDTO[];
  id: string;
}

const Graph = ({ active, symptoms, id }: GraphProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editOn, setEditOn] = useState(false);
  const [filterData, setFilterData] = useState<UserSymptomDTO[]>([]);
  const [activeFilter, setActiveFilter] = useFilter();
  const [activeFilter2, setActiveFilter2] = useFilter();
  const classes = useStyles();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box style={{ width: '100%' }}>
      <Box>
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: Colors.Black,
            fontWeight: 'bold',
            fontSize: isBelowXs ? 20 : 26,
          }}
        >
          {id === 'hypo' ? t('Hypoglucemia') : t('Hyperglucemia')}
        </Typography>
        <Grid container spacing={isBelowXs ? 0 : 3}>
          <Grid item md={12} xs={12}>
            {editOn === false ? (
              <Fragment>
                <Box style={{ margin: '8px 0px' }}>
                  <Typography
                    style={{
                      padding: '16px 0px',
                      color: Colors.Black,
                      fontSize: isBelowXs ? 18 : 20,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('Diagram')}
                  </Typography>
                </Box>
                <Paper>
                  <Box
                    display="flex"
                    className={classes.padd}
                    style={isBelowXs ? { padding: 0 } : {}}
                  >
                    {symptoms.length > 0 && (
                      <Box
                        display="flex"
                        style={{ width: isBelowXs ? '100%' : '60%' }}
                      >
                        <ShortInfoSymptom latest={symptoms[0]} />
                      </Box>
                    )}
                    <Divider
                      style={{
                        marginTop: '3%',
                        backgroundColor: '#E3DFFA',
                      }}
                    />
                    <Box
                      className={clsx([classes.graph])}
                      style={isBelowXs ? { padding: 6 } : {}}
                    >
                      <FilterBar<UserSymptomDTO>
                        data={symptoms}
                        enables={{ group: true, date: true }}
                        onFilter={(data, group) =>
                          setFilterData(
                            ((_data) => {
                              if (
                                data.length > 1 &&
                                group !== GroupProps.ALL &&
                                group !== GroupProps.YEAR
                              ) {
                                let sorted = data.sort((a, b) =>
                                  moment(a.time).diff(moment(b.time))
                                );
                                let temp: UserSymptomDTO[] = sorted;
                                sorted.forEach((item, i) => {
                                  if (sorted[i + 1]) {
                                    const next = sorted[i + 1];
                                    const diff = Math.abs(
                                      moment(item.time).diff(
                                        next.time,
                                        group === GroupProps.MONTH
                                          ? 'months'
                                          : group === GroupProps.WEEK
                                          ? 'weeks'
                                          : 'days'
                                      )
                                    );
                                    if (diff > 1) {
                                      const newDates: string[] = [];
                                      for (let i = 1; i < diff; i++) {
                                        newDates.push(
                                          group === GroupProps.WEEK
                                            ? moment(item.time)
                                                .startOf('week')
                                                .add(i, 'weeks')
                                                .format(DateFormats.ISO)
                                            : moment(item.time)
                                                .add(
                                                  i,
                                                  group === GroupProps.MONTH
                                                    ? 'month'
                                                    : 'day'
                                                )
                                                .format(DateFormats.ISO)
                                        );
                                      }
                                      temp = temp.concat(
                                        ...(newDates.map((item) => ({
                                          time: item,
                                          value: 0,
                                        })) as unknown as UserSymptomDTO[])
                                      );
                                    }
                                  }
                                });
                                return temp.sort((a, b) =>
                                  moment(b.time).diff(moment(a.time))
                                );
                              }
                              return _data;
                            })(data)
                          )
                        }
                        dateKey="time"
                        numeric={false}
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                      />
                      {filterData.length > 0 ? (
                        <StyledBarChart
                          width={filterData.length < 3 ? 100 : undefined}
                          data={filterData
                            .map(({ time, ...rest }) => ({
                              ...rest,
                              time: moment(time).format(DateFormats.GR),
                            }))
                            .slice(0, 100)
                            .reverse()}
                          dataKey="value"
                        >
                          <XAxis
                            dataKey="time"
                            axisLine={false}
                            tickLine={false}
                          />
                          <YAxis
                            dataKey="value"
                            axisLine={false}
                            tickLine={false}
                          />
                          <Tooltip
                            formatter={(datakey) => [datakey, t('Value')]}
                          />
                        </StyledBarChart>
                      ) : symptoms.length > 0 ? (
                        <Box
                          style={{
                            display: 'flex',
                            height: '65%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: Colors.Black,
                          }}
                        >
                          {t('NoFilter')}
                        </Box>
                      ) : (
                        <Box
                          style={{
                            display: 'flex',
                            height: '65%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: Colors.Black,
                          }}
                        >
                          {t('NoData')}
                        </Box>
                      )}
                    </Box>
                    <Box
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '2%',
                      }}
                    >
                      <Button
                        variant="text"
                        style={{ padding: 0 }}
                        onClick={() => {
                          if (editOn === false) setEditOn(true);
                          else setEditOn(false);
                        }}
                      >
                        {t('ChangeGraph1')}
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Fragment>
            ) : (
              <Fragment>
                <Box style={{ margin: '8px 0px' }}>
                  <Typography
                    style={{
                      padding: '16px 0px',
                      color: Colors.Black,
                      fontSize: isBelowXs ? 18 : 20,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('DiagramT')}
                  </Typography>
                </Box>
                <Paper style={{ overflow: 'auto' }}>
                  <Box style={{ margin: '8px 0px' }}>
                    <FilterBar<UserSymptomDTO>
                      data={symptoms}
                      enables={{ date: true }}
                      onFilter={(data) => setFilterData(data)}
                      dateKey="time"
                      numeric={false}
                      activeFilter={activeFilter2}
                      setActiveFilter={setActiveFilter2}
                    />
                  </Box>
                  <Table>
                    <TableHead style={{ backgroundColor: Colors.PRIMARY }}>
                      <TableRow>
                        {!isBelowXs && <TableCell padding="checkbox" />}
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('Comments')}
                        </TableCell>
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('Date')}
                        </TableCell>
                        <TableCell style={{ color: '#FFFFFF' }}>
                          {t('Time')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? symptoms.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : symptoms
                      ).map((row, i) => (
                        <TableRow key={i}>
                          {!isBelowXs && <TableCell padding="checkbox" />}
                          <TableCell padding="none">
                            {row.description.length < 15 ? (
                              row.description
                            ) : (
                              <TooltipTable
                                title={row.description}
                                classes={{ tooltip: classes.customWidth }}
                              >
                                <Box
                                  style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: 190,
                                  }}
                                >
                                  {row.description}
                                </Box>
                              </TooltipTable>
                            )}
                          </TableCell>
                          <TableCell>
                            {moment(row.time).format(DateFormats.GrNoTime)}
                          </TableCell>
                          <TableCell>
                            {moment(row.time).format(DateFormats.TIME)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[
                            10,
                            20,
                            100,
                            { label: 'All', value: -1 },
                          ]}
                          classes={{
                            spacer: classes.spacer,
                            input: classes.input,
                          }}
                          labelRowsPerPage={t('RowsText')}
                          count={symptoms.length}
                          onChangePage={handleChangePage}
                          page={page}
                          rowsPerPage={rowsPerPage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                  <Box
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '2%',
                      paddingBottom: '2%',
                      paddingRight: 16,
                    }}
                  >
                    <Button
                      variant="text"
                      style={{ padding: 0 }}
                      onClick={() => {
                        setEditOn(false);
                      }}
                    >
                      {t('ChangeGraph2')}
                    </Button>
                  </Box>
                </Paper>
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  graph: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    height: '100%',
  },
  padd: {
    padding: theme.spacing(2),
    border: '1px solid #E3DFFA',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
  },
  spacer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  input: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
    },
  },
  customWidth: {
    maxWidth: 500,
  },
}));

export default hot(Graph);
