import {
  Box,
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
  Dialog,
  Popover,
  Drawer,
} from '@material-ui/core';
import { Add, ExpandMore } from '@material-ui/icons';
import { MedicationActive } from 'components/Medication/MedicationActive';
import { MedicationDrawer } from 'components/Medication/MedicationDrawer';
import { MedicationHistory } from 'components/Medication/MedicationHistory';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterBar, useFilter } from 'components/Filter/FilterBar';
import { Colors } from 'res/Enums';
import { EmptyPage } from 'components/EmptyPage';
import Empty from 'assets/icons/medicationEmpty.svg';
import theme from '../assets/theme';
import { useTutorialContext } from 'context/TutorialContext';
import { Demo } from 'components/Demo';
import {
  medicationControllerGetMyMedication,
  UserMedicationDTO,
} from 'gen/client';

interface MedicationProps {
  tut?: number;
}

export const Medication = ({ tut }: MedicationProps) => {
  const [loading, setLoading] = useState(true);
  const [activeFilter, setActiveFilter] = useFilter();
  const [activeMedication, setActiveMedication] = useState<UserMedicationDTO[]>(
    []
  );
  const [rawHistory, setRawHistory] = useState<UserMedicationDTO[]>([]);
  const [history, setHistory] = useState<UserMedicationDTO[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const isBelowLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { openT, me, cont, setCont } = useTutorialContext();
  const inputEl = useRef<any | null>();
  const [temp, setTemp] = useState(0);
  const [timetable, setTimetable] = useState(false);
  const [editData, setEditData] = useState<UserMedicationDTO>();

  const handleDrawer = () => {
    if (timetable === false) setTimetable(true);
    else if (timetable === true) setTimetable(false);
  };

  const getActiveMedication = () =>
    medicationControllerGetMyMedication({ activeMedication: true })
      .then((data) => setActiveMedication(data.data))
      .catch();

  const getHistory = () =>
    medicationControllerGetMyMedication({ activeMedication: false })
      .then((data) => {
        setRawHistory(data.data);
        setHistory(data.data);
      })
      .catch();

  const getMedication = () => {
    getActiveMedication();
    getHistory();
  };

  useEffect(() => {
    const getActiveMedicationPromise = medicationControllerGetMyMedication({
      activeMedication: true,
    });
    const getHistoryPromise = medicationControllerGetMyMedication({
      activeMedication: false,
    });
    Promise.all([getActiveMedicationPromise, getHistoryPromise])
      .then((values) => {
        setActiveMedication(values[0].data);
        setRawHistory(values[1].data);
        setHistory(values[1].data);
        setLoading(false);
      })
      .catch(() => {});
    setTemp(cont);
    setCont(cont + 1);
  }, []);

  const [e, setE] = useState('');

  useEffect(() => {
    if (openT === '16') {
      setDrawerOpen(true);
    }
    if (openT === '16') setE('16');
    else setE('');
    if (openT === '15' && e === '16') {
      setTemp(0);
      setCont(cont + 1);
    }
    if (openT === '17' && e !== '16') setDrawerOpen(true);
  }, [openT]);
  if (loading) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
  return (
    <Grid container spacing={5} ref={inputEl}>
      {activeMedication.length > 0 ? (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 16,
            }}
          >
            <Button
              fullWidth={isBelowSm}
              variant="contained"
              onClick={() => setDrawerOpen(true)}
              size={isBelowSm ? 'small' : 'medium'}
              style={{
                color: Colors.IceWhite,
                backgroundColor: Colors.PRIMARY,
                fontSize: isBelowSm ? 13 : 15,
              }}
            >
              <Add fontSize={isBelowSm ? 'small' : 'default'} />
              {t('AddMedication')}
            </Button>
          </Box>
          {activeMedication.length > 0 && (
            <Box display="flex">
              <MedicationActive
                onUpdated={() => getMedication()}
                data={activeMedication}
                openDrawer={editData ? drawerOpen : false}
                onOpen={(data) => {
                  setDrawerOpen(true);
                  setEditData(data);
                }}
              />
            </Box>
          )}
        </Grid>
      ) : (
        <Box
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <EmptyPage
            image={Empty}
            text={t('Medication')}
            text1={t('Welmed')}
            text2={t('AddMedication')}
            onPress={() => setDrawerOpen(true)}
          />
        </Box>
      )}
      {rawHistory.length > 0 && (
        <Grid item xs={12}>
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <Typography>{t('MedHistory')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ padding: 0, display: 'flex', flexDirection: 'column' }}
            >
              <FilterBar<UserMedicationDTO>
                data={rawHistory}
                enables={{ date: true, search: true }}
                onFilter={(data) => setHistory(data)}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                searchKey="medicine.name"
                dateKey="startDate"
                medicalDateSearch
              />
              <MedicationHistory
                history={history}
                onDelete={() => getMedication()}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      )}
      {temp < 1 && (
        <Dialog
          open={!me?.tutorial && parseInt(openT) < 18 && openT !== '0'}
          BackdropProps={{
            style: { backgroundColor: 'black', opacity: 0.8 },
          }}
        >
          <Popover
            open={parseInt(openT) < 16}
            anchorEl={inputEl.current}
            PaperProps={{
              style: { width: isBelowMd ? '90vw' : '70vw', height: '80vh' },
            }}
          >
            <Medication tut={cont} />
            <Popover
              open={parseInt(openT) < 16}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  marginLeft: '-16%',
                  marginTop: '2%',
                  textAlign: 'center',
                  width: isBelowMd ? '60vw' : '33vw',
                },
              }}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                {t('Tut23')}
              </Typography>
            </Popover>
            {openT === '15' && <Demo />}
          </Popover>
        </Dialog>
      )}
      <Drawer
        open={drawerOpen}
        anchor="right"
        PaperProps={{
          style: {
            width:
              timetable === true && isBelowMd
                ? '65vw'
                : timetable === true && isBelowLg
                ? '50vw'
                : timetable === true
                ? '40vw'
                : '35vw',
            minWidth: 450,
          },
        }}
        onClose={() => {
          setDrawerOpen(false);
          setEditData(undefined);
        }}
      >
        <MedicationDrawer
          userData={editData}
          onClose={() => {
            setEditData(undefined);
            setDrawerOpen(false);
          }}
          onUpdated={() => {
            setEditData(undefined);
            getMedication();
            getActiveMedication();
          }}
          onTimetable={() => handleDrawer()}
        />
      </Drawer>
    </Grid>
  );
};
