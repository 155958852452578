import { Container } from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Colors } from './Enums';
const _Terms = () => {
  return (
    <Container style={{ fontSize: 14, color: Colors.Black }}>
      <h3>HEALTHIER APPLICATION – Όροι χρήσης και πολιτική απορρήτου</h3>
      <p>
        ΠΑΡΑΚΑΛΟΥΜΕ ΔΙΑΒΑΣΤΕ ΠΡΟΣΕΚΤΙΚΑ ΚΑΙ ΑΠΟΔΕΧΘΕΙΤΕ ΤΟΥΣ ΟΡΟΥΣ ΧΡΗΣΗΣ ΠΡΙΝ
        ΧΡΗΣΙΜΟΠΟΙΗΣΕΤΕ ΤΗΝ ΕΦΑΡΜΟΓΗ “HEALTHIER” ΕΙΤΕ ΣΕ ΕΞΥΠΝΗ ΦΟΡΗΤΗ ΣΥΣΚΕΥΗ
        (SMARTPHONE) ΕΙΤΕ ΜΕΣΩ ΤΗΣ ΣΧΕΤΙΚΗΣ ΙΣΤΟΣΕΛΙΔΑΣ (www.healthier-app.com).
      </p>
      <p>
        <h3>
          <u>Α. Εισαγωγή και σύντομη περιγραφή λογισμικού</u>
        </h3>
        <p>
          Η εφαρμογή “Healthier” είναι ένα καινοτόμο λογισμικό που δίνει την
          δυνατότητα σε χρήστες να διατηρούν και να διαχειριστούν δεδομένα και
          πληροφορίες σχετικές με την υγεία τους σύμφωνα με τις ανάγκες τους.
          Αποτελεί έναν αποτελεσματικό και ασφαλή τρόπο διαχείρισης των ιατρικών
          δεδομένων και παρακολούθησης της εξέλιξης της υγείας και του well
          being τους, προσφέροντας, μεταξύ άλλων, δυνατότητες υπενθυμίσεων και
          προτάσεων αναφορικά με την διαχείριση της υγείας και το ευρύτερο well
          being των χρηστών. Η Ν. Δ. ΒΑΣΙΛΑΚΗΣ ΚΑΙ ΣΙΑ Ο.Ε. (διακριτικός τίτλος
          “TECHAPPS HEALTHIER” και εφεξής καλουμενη “ΕΤΑΙΡΕΙΑ”) είναι Ελληνική
          ομόρρυθμος εταιρεία με έδρα την Αθήνα, οδός Ιπποκράτους 56, ΤΚ 10680
          και ΑΦΜ 800824840. Η ΕΤΑΙΡΕΙΑ αναπτύσει και έχει την πλήρη κυριότητα
          της εφαρμογής “HEALTHIER” την οποία προσφέρει για χρήση τόσο μέσω
          κινητών, όσο και μέσω του σχετικού ιστοτοπου σύμφωνα με τους παρόντες
          Όρους Χρήσης (“Συμφωνία”). Η εφαρμογή και ο ιστότοπος αναφέρονται ως
          από κοινού ως “ΕΦΑΡΜΟΓΗ”. Χρησιμοποιώντας την ΕΦΑΡΜΟΓΉ και /ή κάνοντας
          κλικ στο κουμπί «Συμφωνώ», συμφωνείτε ανεπιφύλακτα να ακολουθείτε και
          δεσμεύεστε νομικά με τους παρόντες Όρους Χρήσης. Εάν δεν συμφωνείτε με
          τους Όρους Χρήσης καθώς και με την Πολιτική Απορρήτου, και δεν είστε
          διατεθειμένοι να δεσμευθείτε από αυτούς, δεν μπορείτε να
          χρησιμοποιήσετε την ΕΦΑΡΜΟΓΗ. Παρακαλείσθε να μην εγκαταστήσετε την
          ΕΦΑΡΜΟΓΗ και/ή να την διαγράψτε άμεσα από το κινητό σας τηλέφωνο και
          να μην τη χρησιμοποιήσετε καθ’ οιονδήποτε τρόπο
        </p>
      </p>
      <p>
        <h3>
          <u>Β. Όροι χρήσης</u>
        </h3>
        <h4>1. ΠΡΟΒΛΕΠΟΜΕΝΗ ΧΡΗΣΗ </h4>
        <p>
          Η εφαρμογή επιτρέπει στους χρήστες να καταγράφουν, να αποθηκεύουν, να
          μοιράζονται και να διαχειρίζονται πληροφορίες που σχετίζονται με την
          υγεία τους. Παρέχει ειδοποιήσεις για τη λήψη φαρμάκων σύμφωνα με το
          πρόγραμμα φαρμακευτικής αγωγής που δηλώνει ο ίδιος ο χρήστης. Επίσης,
          με βάση τα δεδομένα που αποθηκεύει ο χρήστης, παρέχει ειδοποιήσεις
          σχετικά με δυνητικά επικίνδυνες καταστάσεις που θα μπορούσαν να
          απαιτήσουν την προσοχή ενός γιατρού. Παρέχει επίσης γενικές
          πληροφορίες πρόληψης, όπως αυτές ορίζονται από τον Παγκόσμιο οργανισμό
          υγείας (Π.Ο.Υ.). Η εφαρμογή δεν πρέπει σε καμία περίπτωση να θεωρηθεί
          ότι παρέχει διάγνωση ή υποδείξεις για ιατρική περίθαλψη και δεν
          εκτελεί μετρήσεις σχετικές με την υγεία. Επιπλέον δεν προορίζεται να
          χρησιμοποιηθεί ως εργαλείο διάγνωσης ή ιατρικός εξοπλισμός.
        </p>
        <h4>2. ΑΠΑΙΤΗΣΕΙΣ ΓΙΑ ΧΡΗΣΗ </h4>
        <p>
          Για τη χρήση της ΕΦΑΡΜΟΓΗΣ απαιτείται να διαθέτετε συμβατό υπολογιστή
          ή/και φορητή συσκευή τύπου smartphone, καθώς και πρόσβαση στο
          διαδίκτυο. Τέλη και χρεώσεις ενδέχεται να ισχύσουν για τη χρήση της
          υπηρεσίας Internet ή κινητής τηλεφωνίας και μπορεί να χρειαστεί να
          αγοράσετε υλικό ή λογισμικό για να επιτρέψετε στις συσκευές σας να
          έχουν πρόσβαση στην εφαρμογή. Με αποδοχή του παρόντος συμφωνείται ότι
          είστε υπεύθυνος για την τήρηση αυτών των απαιτήσεων και τυχόν χρεώσεων
          αυτού του είδους για τη χρήση του διαδικτύου καθώς και τυχόν συναφών
          αμοιβών, επιβαρύνσεων ή εξόδων. Ο χρήστης με την αποδοχή των όρων
          δέχεται ότι η ΕΦΑΡΜΟΓΉ παρέχεται όπως έχει και δεν έχει οποιαδήποτε
          εγγύηση για την καταλληλότητα της για οποιοδήποτε σκοπό, για την
          αδιάλειπτη διαθεσιμότητα της αλλά και για την απουσία λαθών. Η
          ΕΤΑΙΡΕΊΑ δεν εγγυάται ότι η ΕΦΑΡΜΟΓΗ θα καλύψει όλες τις απαιτήσεις
          του χρήστη. Με βάση την ισχύουσα κάθε φορά νομοθεσία η ΕΤΑΙΡΕΙΑ
          αποποιείται κάθε ευθύνη που μπορεί να σχετίζεται με εγγυήσεις, όρους ή
          ανταπόκριση της ΕΦΑΡΜΟΓΗΣ σε συγκεκριμένες προδιαγραφές. Την ευθύνη
          για τον έλεγχο και την διασφάλιση ότι η ΕΦΑΡΜΟΓΗ καλύπτει τις ανάγκες
          του, την έχει αποκλειστικά ο χρήστης.
        </p>
        <h4>3. ΠΕΡΙΟΡΙΣΜΟΣ ΕΥΘΥΝΗΣ</h4>
        <p>
          <h4>α) Η ΕΦΑΡΜΟΓΗ ΑΠΟΤΕΛΕΙ ΕΝΑ ΔΕΥΤΕΡΕΥΟΝ ΕΡΓΑΛΕΙΟ ΥΠΕΝΘΥΜΙΣΗΣ</h4>
          <p>
            Η εφαρμογή προορίζεται ως ένα χρήσιμο βοηθητικό σύστημα υπενθύμισης
            που μπορείτε να χρησιμοποιήσετε για τη λήψη των φαρμάκων σας, καθώς
            και άλλες σχετικές ειδοποιήσεις. Δεν πρέπει να βασίζεστε στην
            Εφαρμογή ως το κύριο εργαλείο για να καθορίσετε εάν και πότε να
            παίρνετε φάρμακα. Αρμόδιος για την κατάρτιση του φαρμακευτικού σας
            προγράμματος είναι μόνο ο εκάστοτε ιατρός σας. Όπως περιγράφεται
            περαιτέρω στην ενότητα "Τεχνολογία, Υποστήριξη" παρακάτω, η εφαρμογή
            ενδέχεται να μην λειτουργήσει όπως προβλέπεται. Συγκεκριμένα, η
            εφαρμογή δεν θα λειτουργήσει σωστά, μεταξύ άλλων αιτιών, εάν η
            συσκευή σας έχει σπάσει ή απενεργοποιηθεί, εάν το λογισμικό
            εφαρμογής δεν είναι ενεργοποιημένο ή εάν κάποιο υλικό ή λογισμικό
            στη συσκευή σας εμποδίζει την εφαρμογή να λειτουργεί όπως
            προβλέπεται. Η συντήρηση των κινητών και υπολογιστικών συσκευών σας
            είναι ευθύνη σας. Αναγνωρίζετε ότι η Εφαρμογή και η χρησιμότητα
            οποιασδήποτε ειδοποίησης ή κοινοποίησης εξαρτάται από τις
            πληροφορίες που εισάγετε στην Εφαρμογή. Σε τελική ανάλυση, είστε
            αποκλειστικά υπεύθυνοι για τη διασφάλιση της σωστής λήψης των
            φαρμάκων στις κατάλληλες ώρες και στις κατάλληλες δοσολογίες.
          </p>
          <h4>
            β) Η ΕΦΑΡΜΟΓΗ ΔΕΝ ΠΑΡΕΧΕΙ ΙΑΤΡΙΚΕΣ ΣΥΜΒΟΥΛΕΣ ΟΎΤΕ ΘΑ ΠΡΈΠΕΙ ΝΑ
            ΧΡΗΣΙΜΟΠΟΙΕΊΤΑΙ ΩΣ ΕΡΓΑΛΕΙΟ ΔΙΑΓΝΩΣΗΣ{' '}
          </h4>
          <p>
            Πληροφορίες, συμβουλές, συστάσεις, μηνύματα, σχόλια, αναρτήσεις,
            κείμενο, γραφικά, λογισμικό, φωτογραφίες, βίντεο, δεδομένα και άλλο
            υλικό διατίθενται από εμάς ή τρίτους μέσω της εφαρμογής (συλλογικά
            "Περιεχόμενο"). Το σύνολο του περιεχομένου προορίζεται μόνο για
            ενημερωτικούς σκοπούς. Δεν περιλαμβάνεται ή δεν πρέπει να λαμβάνεται
            υπόψη ή να χρησιμοποιείται ως υποκατάστατο για ιατρικές συμβουλές,
            φροντίδα, διάγνωση ή θεραπεία. Η Εφαρμογή δεν αποτελεί πρακτική
            ιατρικής, νοσηλευτικής ή άλλης επαγγελματικής συμβουλευτικής υγείας,
            διάγνωσης ή θεραπείας. Η εφαρμογή δεν συνταγογραφεί, δεν χορηγεί,
            ούτε συμπληρώνει συνταγές. Η παροχή πληροφοριών ή η ενεργοποίηση του
            συστήματος υπενθύμισης σε σχέση με οποιοδήποτε φάρμακο δεν αποτελεί
            σε καμία περίπτωση σύσταση ή έγκριση για χρήση του. Η εφαρμογή δεν
            προσδιορίζει οποιαδήποτε αλληλεπίδραση φαρμάκων και φαρμάκων και
            συνεπώς δεν έχει σκοπό να σας προειδοποιήσει σε οποιαδήποτε
            αλληλεπίδραση φαρμάκων-φαρμάκων που μπορεί να είναι επιβλαβή για
            σας, παρά το ότι ενδέχεται σε συγκεκριμένες περιπτώσεις να σας
            παρέχει ανάλογη πληροφόρηση με αποκλειστικό σκοπό την ενημέρωσή σας
            προκειμένου να απευθυνθείτε στον ιατρό σας. Η απουσία προειδοποίησης
            για ένα συγκεκριμένο συνδυασμό φαρμάκων ή φαρμάκων δεν σημαίνει και
            δεν πρέπει να ερμηνεύεται ως ένδειξη ότι ένας συνδυασμός φαρμάκων ή
            φαρμάκων είναι ασφαλής, κατάλληλος ή αποτελεσματικός για εσάς ή για
            οποιονδήποτε άλλο. Επιπλέον, η Εφαρμογή δεν αποσκοπεί στην παροχή
            πληροφοριών δοσολογίας και δεν έχει ευθύνη για τυχόν σφάλματα στις
            ληφθείσες δοσολογίες ή στο πρόγραμμα φαρμακευτικης αγωγής όπως αυτό
            καταγράφεται από τον ίδιο το χρήστη. Ακόμα η Εφαρμογή δεν αποσκοπεί
            στην παροχή πληροφοριών σχετικά με ενημερώσεις για εξετάσεις, και
            δεν είμαστε υπεύθυνοι για τυχόν σφάλματα στις μετρήσεις σας. Η
            εμπιστοσύνη σε οποιεσδήποτε πληροφορίες παρέχονται μέσω της χρήσης
            της Εφαρμογής είναι αποκλειστικά με δική σας ευθύνη. Πάντα ζητήστε
            τη συμβουλή του γιατρού σας ή άλλου κατάλληλου παροχέα υπηρεσιών
            υγείας για οποιεσδήποτε ερωτήσεις μπορεί να έχετε σχετικά με την
            προσωπική σας υγεία, τις ιατρικές καταστάσεις ή τη φαρμακευτική σας
            αγωγή και πριν αρχίσετε ή διακόψετε οποιαδήποτε πορεία θεραπείας ή
            φαρμακευτικής αγωγής. Ποτέ μην παραβλέπετε, αποφεύγετε ή
            καθυστερείτε να λάβετε ιατρικές συμβουλές από το γιατρό σας ή άλλο
            εξειδικευμένο πάροχο υγειονομικής περίθαλψης εξαιτίας οποιασδήποτε
            πληροφορίας έχετε λάβει ή αποκτήσει μέσω της χρήσης της Εφαρμογής.
            Εάν έχετε ή υποψιάζεστε ότι έχετε κάποιο ιατρικό πρόβλημα ή
            κατάσταση, επικοινωνήστε αμέσως με έναν εξειδικευμένο επαγγελματία
            υγείας.
          </p>
          <h4>γ) ΑΝΕΠΙΘΥΜΗΤΕΣ ΕΝΕΡΓΕΙΕΣ ΜΕ ΙΑΤΡΙΚΑ ΠΡΟΙΟΝΤΑ</h4>
          <p>
            Εάν έχετε μια απροσδόκητη ή αρνητική αντίδραση (ή «ανεπιθύμητη
            ενέργεια») που μπορεί να σχετίζεται με τη χρήση ενός ιατρικού
            προϊόντος, είναι σημαντικό να ενημερώσετε το γιατρό σας και επίσης
            να το αναφέρετε στην εταιρεία που παράγει ή πουλά το προϊόν έτσι
            μπορεί να διερευνήσει το περιστατικό. Το προϊόν θα πρέπει να
            περιλαμβάνει τον αριθμό τηλεφώνου της εταιρείας στην ετικέτα.{' '}
          </p>
          <h4>δ) ΟΡΘΟΤΗΤΑ ΔΕΔΟΜΕΝΩΝ</h4>
          <p>
            Η ΕΤΑΙΡΙΑ καταβάλλει κάθε δυνατή προσπάθεια, ώστε το σύνολο του
            περιεχομένου και οι πληροφορίες που εμφανίζονται στην ΕΦΑΡΜΟΓΉ να
            χαρακτηρίζονται, κατά το δυνατόν, από ακρίβεια, σαφήνεια, ορθότητα,
            πληρότητα, επικαιρότητα και διαθεσιμότητα, ωστόσο δεν εγγυάται ότι
            οι σελίδες, οι υπηρεσίες, οι επιλογές και τα περιεχόμενα θα
            παρέχονται χωρίς διακοπή και χωρίς σφάλματα, καθώς και ότι τα λάθη
            θα διορθώνονται. Επίσης, η ΕΤΑΙΡΕΊΑ δεν εγγυάται την ορθότητα των
            δεδομένων που παρέχονται από τον χρήστη στην ΕΦΑΡΜΟΓΉ. Ο χρήστης
            είναι ο μόνος υπεύθυνος για την σωστή καταγραφή τους. Η ΕΤΑΙΡΕΙΑ δεν
            φέρει καμία ευθύνη για την ορθότητα και την ποιότητα των
            καταχωρήσεων αυτών.{' '}
          </p>
        </p>
        <h4>4. ΙΣΤΟΣΕΛΙΔΕΣ ΚΑΙ ΥΛΙΚΑ ΑΠΟ ΤΡΙΤΟΥΣ </h4>
        <p>
          Οι αναφορές ή οι σύνδεσμοι εντός της Εφαρμογής σε εφαρμογές τρίτων ή
          στους ιστότοπους ή σε πληροφορίες τους παρέχονται μόνο για διευκόλυνση
          και σε καμία περίπτωση δεν σημαίνουν ότι υποστηρίζουμε, χορηγούμε ή
          συνιστούμε οποιοδήποτε υλικό, προϊόν ή υπηρεσία τρίτου μέρους. Δεν
          παρέχουμε εγγυήσεις σχετικά με το περιεχόμενο, την ακρίβεια, την
          πληρότητα, την ευπρέπεια, τη νομιμότητα, τη μη παραβίαση, την ποιότητα
          ή οποιαδήποτε άλλη πτυχή του υλικού σε τέτοιους ιστότοπους,
          περιεχόμενο, δεδομένα, πληροφορίες, εφαρμογές ή υλικά τρίτων μερών.
          Δεν είμαστε υπεύθυνοι και δεν αναλαμβάνουμε καμία ευθύνη σε σχέση με
          το περιεχόμενο, τις πρακτικές απορρήτου τρίτων μερών.
        </p>
        <h4>5. ΤΕΧΝΟΛΟΓΙΑ, ΥΠΟΣΤΗΡΙΞΗ </h4>
        <p>
          Δεν εγγυόμαστε ότι η εφαρμογή θα λειτουργεί στο κινητό σας ή τον
          υπολογιστή σας ή ότι θα είναι συμβατή με το υλικό ή το λογισμικό σε
          οποιαδήποτε συσκευή. Οι πληροφορίες διαβιβάζονται σε εσάς μέσω ενός
          μέσου που είναι πέρα ​​από τον έλεγχο και τη δικαιοδοσία της
          ΕΤΑΙΡΕΙΑΣ. Πολλοί παράγοντες, συμπεριλαμβανομένης της διαθεσιμότητας
          του δικτύου, ενδέχεται να επηρεάσουν τις ειδοποιήσεις ή να
          παρεμποδίσουν με άλλον τρόπο τη λειτουργία της εφαρμογής. Η ΕΤΑΙΡΕΙΑ
          δεν εγγυάται και συνεπώς δεν αναλαμβάνει καμία ευθύνη για τυχόν
          σφάλματα, παραλείψεις, καθυστερήσεις, αποτυχίες, διακοπές, απώλεια
          οποιωνδήποτε δεδομένων, ειδοποιήσεων ή άλλων πληροφοριών που
          μεταδίδονται σε σχέση με τη χρήση της την Εφαρμογή, ιδίως όσον αφορά
          τυχόν αποτυχία του συστήματος υπενθυμίσεων να λειτουργήσει όπως
          αναμένεται, συμπεριλαμβανομένης, ενδεικτικά, της μη παράδοσης
          οποιωνδήποτε ειδοποιήσεων. Η ΕΤΑΙΡΕΙΑ δεν έχει καμία υποχρέωση να
          παρέχει τεχνική υποστήριξη ή συντήρηση για την Εφαρμογή. Οποιαδήποτε
          στιγμή και για οποιονδήποτε λόγο, χωρίς ειδοποίηση ή ευθύνη, μπορεί να
          τροποποιήσει ή να διακόψει την υποστήριξη της εφαρμογής ή οποιοδήποτε
          τμήμα της ή να επιβάλλει περιορισμούς στη χρήση ή την πρόσβαση σε
          αυτη. Η ΕΤΑΙΡΕΙΑ λαμβανει κάθε εύλογο μέτρο και προφυλάξεις για να
          διατηρήσει την εφαρμογή χωρίς ιούς ή άλλο επιβλαβή ή κακόβουλο κώδικα.
          Ωστόσο, δεν μπορεί να εγγυηθεί ότι αρχεία από εξωτερικές πηγές που
          μπορεί να είναι διαθέσιμα για λήψη μέσω της εφαρμογής θα είναι
          απαλλαγμένα από ανάλογο κακόβουλο λογισμικό και θα πρέπει να
          λαμβάνονται με την ανάληψη αποκλειστικής ευθύνης από το χρήστη.
        </p>
        <h4>6. ΤΡΟΠΟΠΟΙΗΣΕΙΣ ΤΗΣ ΠΑΡΟΥΣΑΣ ΣΥΜΦΩΝΙΑΣ</h4>
        <p>
          Η ΕΤΑΙΡΕΙΑ διατηρεί το δικαίωμα να ενημέρωσης ή να τροποποίησης των
          Όρων Χρήσης, ανά πάσα στιγμή και για οποιονδήποτε λόγο, χωρίς ποινή ή
          ευθύνη σε εσάς ή σε τρίτους. Σε αυτή την περίπτωση θα ενημερωθείτε με
          σχετικό μήνυμα μέσω της ΕΦΑΡΜΟΓΉΣ. Συνεχίζοντας να χρησιμοποιείτε την
          Εφαρμογή μετά από οποιεσδήποτε τέτοιες αλλαγές, συμφωνείτε άνευ όρων
          να ακολουθείτε και να δεσμεύεστε από τους Όρους Χρήσης όπως αυτοί θα
          έχουν τροποποιηθεί. Για αυτούς τους λόγους, σας συνιστούμε να ελέγχετε
          περιοδικά τους Όρους Χρήσης.
        </p>
        <h4>7. ΑΠΟΡΡΗΤΟ</h4>
        <p>
          Οι προσωπικές πληροφορίες που αποθηκεύετε μέσω της χρήσης της
          εφαρμογής και όποια τυχόν άλλα προσωπικά σας δεδομένα έρθουν σε γνώση
          της ΕΤΑΙΡΕΙΑΣ διέπονται από την Πολιτική Προστασίας Προσωπικών
          Δεδομένων(GDPR). Η ΕΤΑΙΡΕΙΑ σέβεται το ιδιωτικό σας απόρρητο και
          διατηρείτε την πλήρη και απαρέγκλιτη κυριότητα των δεδομένων σας. Μια
          πλήρης δήλωση της τρέχουσας πολιτικής απορρήτου μας μπορεί να βρεθεί
          [ΚΛΙΚ ΕΔΩ]. Η εταιρεία διατηρεί το δικαίωμα να επικαιροποιεί τις
          πολιτικές και τις πρακτικές απορρήτου και διαχείρισης δεδομένων κατά
          διαστήματα. Ο χρήστης θα ενημερώνεται για κάθε σχετική αλλαγή και
          συνέχιση της χρήσης της ΕΦΑΡΜΟΓΉΣ από εκείνο το σημείο και έπειτα
          ισοδυναμεί με αποδοχή της νέας πολιτικής.
        </p>
        <h4>8. ΛΟΓΑΡΙΑΣΜΟΣ ΚΑΙ ΚΩΔΙΚΟΣ ΠΡΟΣΒΑΣΗΣ </h4>
        <p>
          Η ΕΦΑΡΜΟΓΗ απαιτεί τη δημιουργία λογαριασμού χρήστη για την αποθήκευση
          προσωπικών σας πληροφοριών. Συμφωνείτε να διατηρείτε ακριβείς και
          ολοκληρωμένες τις τρέχουσες πληροφορίες σας. Είστε υπεύθυνοι για την
          ασφάλεια και την εμπιστευτικότητα του λογαριασμού και του κωδικού
          πρόσβασής σας στην εφαρμογής, καθώς και κάθε δραστηριότητας στον
          λογαριασμό σας. Η αποκλειστική σας ευθύνη είναι να παρακολουθείτε και
          να ελέγχετε την πρόσβαση και τη χρήση του λογαριασμού και του κωδικού
          πρόσβασής σας και να ενημερώνετε εγκαίρως την ΕΤΑΙΡΕΙΑ για οποιαδήποτε
          ανάγκη απενεργοποίησης οποιουδήποτε λογαριασμού ή κωδικού πρόσβασης
          σας ή σε περίπτωση που τα στοιχεία πρόσβασής σας έχουν εκτεθεί σε
          κακόβουλη χρήση.
        </p>
        <h4>9. ΑΔΕΙΑ ΧΡΗΣΗΣ</h4>
        <p>
          Σας παρέχετε μια περιορισμένη, μη αποκλειστική, μη εκχωρήσιμη, άδεια
          χρήσης για την πρόσβαση και χρήση της Εφαρμογής σύμφωνα με τους όρους
          και τις προϋποθέσεις που εδώ αναγράφονται. Αυτή η άδεια χρήσης είναι
          μόνο για προσωπική σας και μη εμπορική χρήση και μόνο για τη διάρκεια
          της παρούσας Συμφωνίας. Στο βαθμό που δεν περιορίζεται βάσει
          οποιουδήποτε εφαρμοστέου νόμου ή κανονισμού, σας παρέχεται άδεια να
          κάνετε προσωρινά λήψη ενός αντιγράφου της εφαρμογής για προσωπική, μη
          εμπορική χρήση μόνο σε κινητή συσκευή που έχετε στην κατοχή σας ή
          ελέγχετε. Δεν μπορείτε να διανείμετε ή να κάνετε την εφαρμογή
          διαθέσιμη για χρήση από άλλους σε πολλές συσκευές ταυτόχρονα. Σύμφωνα
          με αυτήν την άδεια, εκτός εάν και μόνο στον βαθμό που οποιοσδήποτε από
          τους ακόλουθους περιορισμούς απαγορεύεται από το εφαρμοστέο δίκαιο,
          δεν επιτρέπεται:
          <ul>
            <li>
              να δανείζεται, να μισθώνετε, να πωλείται, να αναδιανέμετε, να
              εκχωρείτε ή να μεταβιβάζετε με οποιονδήποτε άλλο τρόπο την
              εφαρμογή ή το δικαίωμα λήψης ή χρήσης της εφαρμογής.
            </li>
            <li>
              να χρησιμοποιήσετε την εφαρμογή για οποιονδήποτε εμπορικό σκοπό
            </li>
            <li>
              να αντιγράψετε, να εξαγάγετε τον πηγαίο κώδικα της εφαρμογής,
              τυχόν ενημερώσεις εφαρμογών ή οποιοδήποτε τμήμα της εφαρμογής ή
              των ενημερώσεων ή να επιχειρήσετε να κάνετε κάποιο από τα
              παραπάνω.
            </li>
            <li>
              να τροποποιήσετε ή να δημιουργήσετε παράγωγα έργα της εφαρμογής,
              τυχόν ενημερώσεις εφαρμογών ή οποιοδήποτε τμήμα της εφαρμογής ή
              ενημερώσεις.
            </li>
            <li>
              Κατάργηση πνευματικών δικαιωμάτων ή άλλων ιδιοκτησιακών σημειώσεων
              από οποιαδήποτε μέρος της εφαρμογής. ή
            </li>
            <li>
              να μεταφέρετε το περιεχόμενο ή τα υλικά από την εφαρμογή ή την
              τοποθεσία σε οποιονδήποτε άλλο ή να "αντικατοπτρίζεται" την ίδια
              σε άλλο διακομιστή.
            </li>
          </ul>
          Εάν παραβιάσετε κάποιον από αυτούς τους περιορισμούς, αυτή η άδεια θα
          τερματιστεί αυτομάτως και ενδέχεται να υποβληθείτε σε δίωξη.
        </p>
        <h4>10. ΙΔΙΟΚΤΗΣΙΑ</h4>
        <p>
          Η ΕΤΑΙΡΕΙΑ διατηρεί όλα τα δικαιώματα ιδιοκτησίας, χρήσης και
          διαχείρισης της Εφαρμογής που δεν σας παραχωρούνται συγκεκριμένα σε
          αυτήν την Συμφωνία. Δεν σας μεταβιβάζεται κανένας τίτλος ή δικαιώματα
          ιδιοκτησίας ή πνευματικής ιδιοκτησίας εφαρμογή, τυχόν ενημερώσεις ή
          παράγωγα έργα της εφαρμογής ή οποιαδήποτε δικαιώματα πνευματικής
          ιδιοκτησίας, δικαιώματα ευρεσιτεχνίας ή εμπορικά σήματα που
          ενσωματώνονται ή χρησιμοποιούνται σε σχέση με την εφαρμογή.
        </p>
        <h4>11. ΠΝΕΥΜΑΤΙΚΑ ΔΙΚΑΙΩΜΑΤΑ ΚΑΙ ΕΜΠΟΡΙΚΑ ΣΗΜΑΤΑ</h4>
        <p>
          Συμφωνείτε ότι η εφαρμογή περιέχει ιδιόκτητο περιεχόμενο, πληροφορίες
          και υλικό που προστατεύονται από πνευματική ιδιοκτησία και άλλους
          νόμους διεθνώς. Συνεπώς, τα σήματα υπηρεσιών, τα εμπορικά ονόματα, και
          τα προϊόντα είναι ιδιοκτησία της ΕΤΑΙΡΕΙΑΣ. Δεν χορηγείται άδεια
          χρήσης εμπορικού σήματος. Η πρόσβασή σας στην Εφαρμογή δεν σας
          επιτρέπει να χρησιμοποιείτε οποιοδήποτε όνομα, λογότυπο ή σήμα της με
          οποιονδήποτε τρόπο. Επιπλέον, όλο το λογισμικό της εφαρμογής είναι
          ιδιοκτησία της ΕΤΑΙΡΕΙΑΣ και προστατεύεται από τους νόμους περί
          πνευματικών δικαιωμάτων. Οποιαδήποτε χρήση της Εφαρμογής ή του
          περιεχομένου της που δεν επιτρέπεται ρητά από τους παρόντες Όρους
          Χρήσης αποτελεί παραβίαση της παρούσας Συμφωνίας και ενδέχεται να
          παραβιάζει τους νόμους περί πνευματικών δικαιωμάτων, εμπορικών σημάτων
          και άλλων νόμων. Όλα τα δικαιώματα που δεν σας έχουν χορηγηθεί ρητώς
          σε αυτήν την Συμφωνία, δεσμεύονται από την ΕΤΑΙΡΕΙΑ.
        </p>
        <h4>12. ΣΥΛΛΟΓΗ ΠΛΗΡΟΦΟΡΙΩΝ ΚΑΙ ΧΡΗΣΗ</h4>
        <p>
          Ενώ μπορείτε να χρησιμοποιήσετε ορισμένες λειτουργίες του ιστότοπου
          χωρίς εγγραφή, η χρήση της εφαρμογής και ορισμένα εργαλεία του
          ιστότοπου απαιτούν εγγραφή. Εάν χρησιμοποιείτε τον ιστότοπό μας χωρίς
          να εγγραφείτε, οι μοναδικές πληροφορίες που συλλέγουμε θα είναι μη
          προσωπικές πληροφορίες μέσω της χρήσης cookies, web beacons και
          συλλογής δεδομένων πλοήγησης. Εάν επιλέξετε να εγγραφείτε , θα σας
          ζητηθεί να δώσετε προσωπικές πληροφορίες και μπορεί επίσης να σας
          ζητηθεί να καταχωρίσετε προσωπικές πληροφορίες υγείας. Συλλέγουμε μη
          προσωπικές πληροφορίες σχετικά με τη χρήση του ιστότοπου με τη χρήση
          διαφόρων τεχνολογιών, όπως cookies, web beacons και συλλογή δεδομένων
          πλοήγησης (αρχεία καταγραφής, αρχεία καταγραφής διακομιστών,
          clickstream). Οι πληροφορίες που συλλέγονται μέσω των διαφόρων
          τεχνολογιών μας επιτρέπουν να διευκολύνουμε τη χρήση και να
          διασφαλίσουμε την τεχνική λειτουργία του ιστότοπου, να δημιουργούμε
          δυναμικά και να παρέχουμε στοχοθετημένο περιεχόμενο, να παρακολουθούμε
          στατιστικά τη χρήση του ιστότοπου και να βελτιώνουμε τη συνολική
          ποιότητα των εμπειριών των χρηστών μας. Τα περισσότερα προγράμματα
          περιήγησης δεν δέχονται αυτόματα τα cookies αλλά σας επιτρέπουν να
          τροποποιήσετε τις ρυθμίσεις ασφαλείας, ώστε να μπορείτε να εγκρίνετε ή
          να απορρίπτετε τα cookies κατά περίπτωση ή να απορρίπτετε όλα τα
          cookies. Αν απορρίπτετε τα cookies από τον ιστότοπό μας, ορισμένα μέρη
          του ιστότοπου ενδέχεται να μην λειτουργούν σωστά για εσάς.
        </p>
        <h4>13. ΣΥΛΛΟΓΗ ΚΑΙ ΧΡΗΣΗ ΤΩΝ ΜΗ ΠΡΟΣΩΠΙΚΩΝ ΣΑΣ ΠΛΗΡΟΦΟΡΙΩΝ </h4>
        <p>
          Όπως περιγράφεται παρακάτω, η εφαρμογή μπορεί να συγκεντρώνει αυτόματα
          ορισμένες πληροφορίες σχετικά με εσάς όταν την χρησιμοποιείτε, όπως
          τον τύπο υπολογιστικής ή κινητής συσκευής που χρησιμοποιείτε, τη
          διεύθυνση IP της συσκευής σας ή / και το μοναδικό αναγνωριστικό
          συσκευής, το λειτουργικό σύστημα της συσκευής σας και το πρόγραμμα
          περιήγησης που χρησιμοποιείτε.
        </p>
        <h4>
          14. ΣΥΛΛΟΓΗ ΚΑΙ ΧΡΗΣΗ ΠΛΗΡΟΦΟΡΙΩΝ ΠΡΟΣΩΠΙΚΗΣ ΑΝΑΓΝΩΡΙΣΗΣ ΚΑΙ
          ΠΡΟΣΩΠΙΚΗΣ ΥΓΕΙΑΣ
        </h4>
        <p>
          Κάθε φορά που υποβάλετε πληροφορίες μέσω της εφαρμογής ή του ιστότοπού
          μας, συναινείτε στη συλλογή, αποθήκευση, χρήση, συνδυασμό, διαγραφή
          πληροφοριών σύμφωνα με την παρούσα Πολιτική Απορρήτου.
        </p>
        <p>
          Όταν καταχωρείτε ή ενημερώνετε τις πληροφορίες σας στον ιστότοπό μας ή
          μέσω της εφαρμογής μας, συλλέγουμε προσωπικά στοιχεία, όπως:
        </p>
        <ul>
          <li>όνομα και επώνυμο,</li>
          <li>διεύθυνση ηλεκτρονικού ταχυδρομείου,</li>
          <li>ηλικία.</li>
        </ul>
        <p>
          Κατά τη διάρκεια ή μετά την εγγραφή σας σε εμάς, μπορείτε να επιλέξετε
          να χρησιμοποιήσετε ορισμένα χαρακτηριστικά της Εφαρμογής που θα σας
          επιτρέψουν να εισαγάγετε άλλες πληροφορίες, όπως τα φάρμακα που
          παίρνετε, την συχνότητα λήψης του φαρμάκου, τις μετρήσεις υγείας, τις
          εξετάσεις σας, στοιχεία για τις ασθένειες που έχετε, αλλεργίες ή άλλες
          καταστάσεις, συμπτώματα που έχετε.
        </p>
        <h4>α) ΔΕΔΟΜΕΝΑ ΠΟΥ ΑΠΟΘΗΚΕΥΟΝΤΑΙ</h4>
        <p>
          Τα προσωπικά δεδομένα που συλλέγουμε από τους χρήστες μας
          περιορίζονται αυστηρώς στα απαραίτητα για την ορθή λειτουργία της
          εφαρμογής. Τα δεδομένα αυτά ενδεικτικά είναι:
        </p>
        <ul>
          <li>Πληροφορίες για την ηλικία, το φύλο, στοιχεία επικοινωνίας </li>
          <li> Φαρμακευτική αγωγή </li>
          <li> Καθημερινές μετρήσεις υγείας </li>
          <li> Αποτελέσματα μετρήσεων από εξετάσεις</li>
          <li> Καταγραφή συμπτωμάτων </li>
          <li> Χρόνιες παθήσεις </li>
          <li> Αλλεργίες </li>
          <li> Παροδικές παθήσεις</li>
          <li> Ομάδα αίματος </li>
          <li> Συνήθειες όπως το κάπνισμα </li>
          <li>Καταγραφή των προληπτικών εξετάσεων που έχουν πραγματοποιηθεί</li>
          <li> Καταγραφή άσκησης </li>
          <li> Τιμές σακχάρου και ινσουλίνης</li>
        </ul>
        <p>
          Η εφαρμογή δύναται να συλλέγει και άλλα αντίστοιχης φύσης δεδομένα
          ανάλογα με τις ανάγκες του χρήστη.Τα δεδομένα που συλλέγονται
          αποθηκεύονται στην βάση δεδομένων στην οποία δεν έχει κανένας πρόσβαση
          και τα αρχεία που μπορούν να οδηγήσουν στην ταυτότητα του χρήστη είναι
          κρυπτογραφημένα. Είστε υπεύθυνοι για την εξασφάλιση της ακρίβειας και
          της πληρότητας των πληροφοριών προσωπικής ταυτοποίησης και κάθε άλλης
          πληροφορίας που παρέχετε σε σχέση με τη χρήση της Εφαρμογής.
          Ανακριβείς ή ελλιπείς πληροφορίες θα επηρεάσουν τη λειτουργία της
          Εφαρμογής για εσάς καθώς και τις πληροφορίες που λαμβάνετε από την
          ΕΦΑΡΜΟΓΗ, όπως και τη δυνατότητά επικοινωνίας μαζί σας.
        </p>
        <h4>
          β) ΠΕΡΙΟΡΙΣΜΟΣ ΚΟΙΝΗΣ ΧΡΗΣΗ ΤΩΝ ΠΡΟΣΩΠΙΚΩΝ ΣΑΣ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΤΡΙΤΟΥΣ
        </h4>
        <p>
          Η εφαρμογή δεν διαμοιράζεται ευαίσθητα προσωπικά σας δεδομένα με
          τρίτους και εσείς παραμένετε οι κύριοι των δεδομένων αυτών και
          υπεύθυνοι για την μη αποκάλυψη τους. Σε περίπτωση που αυτό κατ’
          εξαίρεση απαιτηθεί θα ζητηθεί η συναίνεσή σας εκ των προτέρων. Αν
          συναινέσετε να μεταφέρετε από εμάς τα προσωπικά σας στοιχεία σε άλλη
          εταιρεία, λάβετε υπόψη ότι οι παρεχόμενες πληροφορίες θα υπόκεινται
          στις πρακτικές απορρήτου της εν λόγω εταιρείας και δεν θα πλέον
          βρίσκονται υπό τον έλεγχό μας.
        </p>
        <h4>γ) ΜΗ ΔΙΑΜΟΙΡΑΣΜΟΣ ΔΕΔΟΜΕΝΩΝ</h4>
        <p>
          Η εταιρεία δεσμεύεται να μην αποκαλύψει τα προσωπικά σας δεδομένα και
          να μη τα χρησιμοποιήσει για σκοπούς άλλους πέραν αυτών για τους
          οποίους έχουν συλλεγεί,εκτός αν συντρέχει υποχρέωση εκ του νόμου ή
          συμμόρφωση σε δικαστική απόφαση ή εντολή. Τα ευαίσθητα ιατρικά
          δεδομένα υγείας σας προστατεύονται από την κείμενη νομοθεσία περί
          ιατρικού απορρήτου και τηρούνται σύμφωνα με τις διατάξεις του αρ. 7
          παρ. 2 περ. δ Ν. 2472/1997.{' '}
        </p>
        <h4>δ) ΧΡΗΣΗ ΣΥΓΚΕΝΤΡΩΤΙΚΩΝ ΔΕΔΟΜΕΝΩΝ </h4>
        <p>
          Συγκεντρωτικές, ανώνυμες πληροφορίες που έχουν εισαχθεί από τους
          χρήστες, συμπεριλαμβανομένων προσωπικών πληροφοριών υγείας και
          πληροφοριών σχετικά με τη χρήση της Εφαρμογής ενδέχεται να
          αποκαλυφθούν και να χρησιμοποιηθούν από εμάς και από τρίτους για
          ερευνητικούς ή εμπορικούς σκοπούς. Αυτά τα συγκεντρωτικά δεδομένα δεν
          περιέχουν πληροφορίες που θα μπορούσαν να χρησιμοποιηθούν για την
          αναγνώρισή σας.{' '}
        </p>
        <h4>ε) ΔΙΑΤΗΡΗΣΗ ΔΕΔΟΜΕΝΩΝ ΚΑΙ ΔΙΟΡΘΩΣΕΙΣ</h4>
        <p>
          Ανά πάσα στιγμή, μπορείτε να διακόψετε τη συλλογή των πληροφοριών σας,
          καταργώντας την εγκατάσταση της εφαρμογής και αποφεύγοντας τη χρήση
          του ιστότοπου. Μπορείτε να επικοινωνήσετε μαζί μας όπως καθορίζεται
          παρακάτω στην ενότητα "Πώς να επικοινωνήσετε μαζί μας" για την
          ενημέρωση ή τη διόρθωση προσωπικών προσωπικών πληροφοριών ή προσωπικών
          πληροφοριών υγείας που μας έχετε παράσχει. Θα διατηρήσουμε τις
          πληροφορίες σας όσο συνεχίζετε να χρησιμοποιείτε τον ιστότοπο ή την
          εφαρμογή μας και για εύλογο χρονικό διάστημα μετά. Κατόπιν αιτήματός
          σας, θα διαγράψουμε τις πληροφορίες προσωπικής αναγνώρισης ή
          προσωπικής σας υγείας από τις ενεργές βάσεις δεδομένων μας. Σε
          περίπτωση διαγραφής του λογαριασμού σας, το σύνολο των δεδομένων σας
          θα διαγράφεται από τη βάση δεδομένων μας μετά από το πέρας χρονικής
          περιόδου 3 μηνών, εκτός αν κάτι τέτοιο απαιτηθεί από εσάς νωρίτερα ή
          αν επανενεργοποιήσετε το λογαριασμό σας μέσα σε αυτό το διάστημα.
        </p>
        <h4>στ) ΑΣΦΑΛΕΙΑ</h4>
        <p>
          Λαμβάνουμε όλα τα μέτρα για να προστατεύσουμε τις προσωπικά
          αναγνωρίσιμες πληροφορίες σας με την κρυπτογράφηση αυτών των
          πληροφοριών και προστατεύουμε αυτές τις πληροφορίες από απώλεια, κακή
          χρήση και μη εξουσιοδοτημένη πρόσβαση, αποκάλυψη, μετατροπή ή
          καταστροφή. Αν διαπιστώσουμε παραβίαση της ασφάλειας που θέτει σε
          κίνδυνο τα προσωπικά σας στοιχεία, θα κάνουμε όλες τις
          δημοσιοποιημένες γνωστοποιήσεις, σύμφωνα με την ικανότητά μας να
          καθορίσουμε το εύρος της παραβίασης, την ανάγκη αποκατάστασης της
          ακεραιότητας του συστήματος και των αναγκών των αρχών επιβολής του
          νόμου. Ωστόσο, πρέπει να έχετε κατά νου ότι καμία μετάδοση στο
          Διαδίκτυο δεν είναι ποτέ απόλυτα ασφαλής ή χωρίς λάθη. Συγκεκριμένα,
          το ηλεκτρονικό ταχυδρομείο που αποστέλλεται προς ή από εμάς μπορεί να
          μην είναι ασφαλές, για αυτό παρακαλώ μην χρησιμοποιείτε το ηλεκτρονικό
          μας ταχυδρομείο εάν επιθυμείτε να διατηρήσετε την επικοινωνία σας
          ιδιωτική. Επιπλέον, αυτή η πολιτική απορρήτου δεν ισχύει για
          περιεχόμενο, επιχειρηματικές πληροφορίες, ιδέες, έννοιες ή εφευρέσεις
          που μας στέλνετε μέσω ηλεκτρονικού ταχυδρομείου. Εάν θέλετε να
          διατηρήσετε ιδιωτικά περιεχόμενα ή επιχειρηματικές πληροφορίες, ιδέες
          ή εφευρέσεις, μην τις στείλετε σε εμάς μέσω ηλεκτρονικού ταχυδρομείου.
          Επιπλέον, δεν μπορούμε να διασφαλίσουμε τις μεταδόσεις μετά την
          αποστολή τους σε εσάς. Για παράδειγμα, εάν μοιράζεστε τον υπολογιστή ή
          το κινητό σας τηλέφωνο με κάποιον, όπως ένα μέλος της οικογένειας ή
          έναν φίλο, αυτό το άτομο μπορεί να έχει πρόσβαση ή να βλέπει τις
          πληροφορίες που λαμβάνετε από εμάς, συμπεριλαμβανομένων των μηνυμάτων
          υπενθύμισης. Επιπλέον, ενδέχεται να είναι σε θέση να διαβάσουν τις
          μεταδόσεις μας σε εσάς, όπως τα μηνύματα υπενθύμισης, εάν ο
          υπολογιστής ή το κινητό σας τηλέφωνο είναι ορατό. Επομένως, παρακαλώ
          σκεφτείτε την προσωπική και κοινή χρήση των υπολογιστών και των
          κινητών συσκευών σας όταν χρησιμοποιείτε την εφαρμογή μας. Έχουμε
          εφαρμόσει διοικητικές, τεχνικές και φυσικές εγγυήσεις για να
          αποτρέψουμε την μη εξουσιοδοτημένη πρόσβαση, χρήση ή αποκάλυψη των
          Προσωπικών σας Δεδομένων. Οι πληροφορίες σας αποθηκεύονται σε ασφαλείς
          διακομιστές και δεν είναι διαθέσιμες στο κοινό. Περιορίζουμε την
          πρόσβαση των στοιχείων σας μόνο σε εκείνους τους υπαλλήλους ή
          συνεργάτες που πρέπει να γνωρίζουν τις πληροφορίες προκειμένου να
          καταστήσουν δυνατή τη διεξαγωγή της συμφωνίας μεταξύ μας. Πρέπει να
          μας βοηθήσετε να αποτρέψουμε την μη εξουσιοδοτημένη πρόσβαση στο
          λογαριασμό σας, προστατεύοντας τον κωδικό σας με τον κατάλληλο τρόπο
          και περιορίζοντας την πρόσβαση στο λογαριασμό σας (για παράδειγμα,
          αποσυνδέοντας τη μετά την ολοκλήρωση της πρόσβασής σας στο λογαριασμό
          σας). Θα είστε αποκλειστικά υπεύθυνοι για την εμπιστευτικότητα του
          κωδικού σας και για κάθε χρήση του κωδικού πρόσβασης και του
          λογαριασμού σας, συμπεριλαμβανομένης οποιασδήποτε μη εξουσιοδοτημένης
          χρήσης. Ενώ επιδιώκουμε να προστατέψουμε τις πληροφορίες σας για να
          διασφαλίσουμε ότι διατηρούνται εμπιστευτικές, δεν μπορούμε να
          εγγυηθούμε απόλυτα την ασφάλειά τους. Θα πρέπει να γνωρίζετε ότι
          υπάρχει πάντοτε κάποιος κίνδυνος στη μετάδοση πληροφοριών μέσω του
          Διαδικτύου. Παρόλο που προσπαθούμε να προστατέψουμε τα Προσωπικά σας
          Δεδομένα, δεν μπορούμε να διασφαλίσουμε ή να εγγυηθούμε την ασφάλεια
          και το απόρρητο των προσωπικών σας πληροφοριών ή άλλου περιεχομένου
          που μεταδίδεται χρησιμοποιώντας την υπηρεσία και το κάνετε με δική σας
          ευθύνη
        </p>
        <h4>ΠΩΣ ΜΠΟΡΕΙΤΕ ΝΑ ΕΠΙΚΟΙΝΩΝΗΣΕΤΕ ΜΑΖΙ ΜΑΣ </h4>
        <p>
          <p>
            Αν έχετε ερωτήσεις, σχόλια, αιτήματα σχετικά με τους όρους χρήσης ή
            το απόρρητο της εφαρμογής μας , παρακαλώ επικοινωνήστε μαζί μας στο
            :
          </p>
          <p>
            TechApps Healthier <br /> Ιπποκράτους 56, 10860 <br /> +30 213
            0450591 <br /> info@healthier-app.com
          </p>
          <p>Αυτοί οι όροι χρήσης ενημερώθηκαν τελευταία φορά στις 25/9/2020</p>
        </p>
        <h4>ΑΛΛΑΓΕΣ ΣΤΟΥΣ ΟΡΟΥΣ ΧΡΗΣΗΣ</h4>
        <p>
          Οι όροι χρήσης ενδέχεται να αλλάζουν κατά καιρούς χωρίς προειδοποίηση
          και σας συνιστούμε να ελέγχετε τακτικά αυτούς τους όρους πριν μας
          παρέχετε προσωπικές πληροφορίες. Οι ενημερωμένες εκδόσεις αυτής της
          πολιτικής θα δημοσιεύονται στον ιστότοπό μας.
        </p>
        <h4>ΑΠΟΠΟΙΗΣΗ ΕΥΘΥΝΗΣ</h4>
        <p>
          Με τη συμμετοχή σε αυτή τη Σύμβαση και τη χρήση του Λογισμικού, στο
          βαθμό που επιτρέπεται από το νόμο, συμφωνείτε ότι θα υπερασπιστείτε,
          θα αποζημιώσετε την Εταιρεία και θα διατηρήσετε τους δικαιοπάροχους
          της και τους μητρικούς οργανισμούς κάθε τέτοιου μέρους, τις
          θυγατρικές, τις συνεργαζόμενες εταιρείες, τους διανομείς, τους
          συνεργαζόμενους (διανομείς) εταίρους, τους μετόχους, τα στελέχη, τους
          διευθυντές, τους υπαλλήλους, εκπροσώπους, τα μέλη, τους δικηγόρους,
          τους δικαιοδόχους, τους πράκτορες, ή άλλους που εμπλέκονται στη
          δημιουργία, τη χορηγία, την προώθηση, ή κάθε άλλη μορφή διάθεσης του
          λογισμικού, αβλαβείς από και ενάντια σε οποιεσδήποτε και όλες τις
          αξιώσεις, τα έξοδα, τις ζημιές, απώλειες, υποχρεώσεις και δαπάνες
          (συμπεριλαμβανομένων αμοιβών και εξόδων δικηγόρων) που προκύπτουν από
          ή σε σχέση με: (i) οποιαδήποτε ποινική, ειδική, έμμεση ή επακόλουθη
          ζημιά ή απώλεια, οποιαδήποτε απώλεια παραγωγής, απώλεια κέρδους,
          απώλεια εσόδων, απώλεια συμβολαίου, απώλεια ή ζημία στην πελατεία ή τη
          φήμη, απώλεια αποζημίωσης, (ii) οποιαδήποτε ανακρίβεια σχετικά με τις
          πληροφορίες σας, (iii) οποιεσδήποτε (άμεσες, έμμεσες, επακόλουθες ή
          ποινικές) βλάβες, απώλειες ή έξοδα που υπεστήκατε, που
          πραγματοποιήθηκαν ή που καταβλήθηκαν από εσάς (συμπεριλαμβανομένων
          αμοιβών και εξόδων δικηγόρων) σύμφωνα με, που προκύπτουν από ή σε
          σχέση με τη χρήση, την αδυναμία χρήσης, ή την καθυστέρηση, ή ιό του
          Λογισμικού μας και/ή του διακομιστή που φιλοξενείται το Λογισμικό,
          (vi) οποιαδήποτε πληροφορία σε αυτό το Λογισμικό που μερικές φορές
          συνδέεται με εξωτερικούς δικτυακούς τόπους στους οποίους οι υπηρεσίες
          μας δεν έχουν κανέναν έλεγχο και για τους οποίους δεν αναλαμβάνουμε
          καμία ευθύνη, (vii) οποιαδήποτε παραβίαση χρήστη ή παραβίαση
          οποιουδήποτε όρου της παρούσας Σύμβασης ή οποιασδήποτε εφαρμοστέας
          νομοθεσίας ή κανονισμού, είτε αναφέρονται είτε όχι στο παρόν.
        </p>
        <h4>ΕΦΑΡΜΟΣΤΕΟ ΔΙΚΑΙΟ - ΑΡΜΟΔΙΟΤΗΤΑ - ΤΕΛΙΚΕΣ ΔΙΑΤΑΞΕΙΣ</h4>
        <p>
          I. Οι παρόντες όροι και προϋποθέσεις διέπονται από τις διατάξεις του
          Ελληνικού Δικαίου, όπως εκάστοτε ισχύουν και ερμηνεύονται σύμφωνα με
          τους κανόνες της καλής πίστης, των συναλλακτικών ηθών και του
          οικονομικού και κοινωνικού σκοπού του δικαιώματος.
        </p>
        <p>
          II. Η ακυρότητα συγκεκριμένου όρου της παρούσης δε θίγει την ισχύ των
          υπολοίπων όρων, παρά παύει αυτοδικαίως να ισχύει.
        </p>
        <p>
          III. Οποιαδήποτε παράλειψη άσκησης συγκεκριμένου δικαιώματος ή όρου
          από την ΕΦΑΡΜΟΓΗ που απορρέει από το παρόν δεν συνιστά παραίτηση από
          αυτό.
        </p>
        <p>
          IV. Αρμόδια δικαστήρια για την επίλυση οποιασδήποτε τυχόν διαφοράς που
          απορρέει από το παρόν ορίζονται τα Δικαστήρια Αθηνών.
        </p>
        <p>
          Ο χρήστης παρέχει την συγκατάθεσή του ειδικώς και ελευθέρως στην
          ΕΦΑΡΜΟΓΗ να χρησιμοποιεί τα προσωπικά του στοιχεία και τα στοιχεία
          επικοινωνίας του, με σκοπό την ενημέρωσή του για τα προϊόντα ή τις
          υπηρεσίες
        </p>
        <h4>ΑΝΩΤΕΡΑ ΒΙΑ</h4>
        <p>
          1. Τα μέρη δεν ευθύνονται έναντι αλλήλων για την αθέτηση, αδυναμία ή
          καθυστέρηση στην εκπλήρωση των υποχρεώσεών τους που απορρέουν από την
          παρούσα για λόγους και αιτίες που οφείλονται στην επέλευση γεγονότος
          ανωτέρας βίας. Σε περίπτωση επέλευσης γεγονότων ανωτέρας βίας, η
          υποχρέωση παροχής της Υπηρεσίας αναστέλλεται, εφόσον αυτή είναι
          αδύνατη ή υπερβολικά επαχθής για την ΕΦΑΡΜΟΓΗΣ, έως τη λήξη των
          γεγονότων που προκάλεσαν την αδυναμία. Ανωτέρα βία συνιστά κάθε
          γεγονός ανεξάρτητο από τη βούληση των μερών που είναι αντικειμενικά
          απρόβλεπτο και δεν είναι δυνατόν να προληφθεί µε μέτρα άκρας
          επιμέλειας και σύνεσης, όπως ενδεικτικά: πόλεμος, ταραχές, θεομηνίες
          πυρκαγιές, εκρήξεις, πράξεις δολιοφθοράς, εμπορικοί αποκλεισμοί,
          απεργίες, πράξεις Ελληνικών, Κοινοτικών ή άλλων αρχών.
        </p>
        <p>
          2. Το μέρος που εμποδίζεται στην εκπλήρωση των υποχρεώσεών του,
          οφείλει να γνωστοποιήσει στο άλλο μέρος άμεσα και πάντως εντός σαράντα
          οκτώ (48) ωρών το γεγονός ανωτέρας βίας και να λάβει κάθε πρόσφορο
          μέτρο εντός των δυνατοτήτων του για να συμμορφωθεί όσο το δυνατόν
          συντομότερα και πληρέστερα στους όρους της παρούσας. Εάν οι συνθήκες
          ανωτέρας βίας διαρκούν για χρονικό διάστημα μεγαλύτερο των τριάντα
          (30) ημερολογιακών ημερών οποιοδήποτε από τα συμβαλλόμενα μέρη
          δικαιούται να καταγγείλει αζημίως την παρούσα με έγγραφη ειδοποίηση
          προς τον αντισυμβαλλόμενό του.
        </p>
      </p>
      <p>
        <h3>
          <u>Γ. ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ</u>
        </h3>
        <p>
          Στο παρόν περιγράφονται οι πρακτικές απορρήτου για τις συσκευές, τις
          εφαρμογές, το λογισμικό, τους ιστότοπους, τα API, τα προϊόντα και τις
          υπηρεσίες μας (οι "Υπηρεσίες"). Θα ενημερωθείτε για τα δεδομένα που
          συλλέγουμε, τον τρόπο με τον οποίο τα χρησιμοποιούμε, τους ελέγχους
          που σας παρέχουμε επί των πληροφοριών σας και τα μέτρα που λαμβάνουμε
          για να τα διατηρήσουμε ασφαλή.
        </p>
        <h3>
          <u>ΠΛΗΡΟΦΟΡΙΕΣ ΠΟΥ ΣΥΛΛΕΓΟΥΜΕ</u>
          <p>
            Όταν χρησιμοποιείτε τις Υπηρεσίες μας, συλλέγουμε τους ακόλουθους
            τύπους πληροφοριών:
          </p>
        </h3>
        <h4>Α) ΠΛΗΡΟΦΟΡΙΕΣ ΛΟΓΑΡΙΑΣΜΟΥ</h4>
        <p>
          Απαιτούνται ορισμένες πληροφορίες για τη δημιουργία λογαριασμού στις
          Υπηρεσίες μας, όπως το όνομά σας, η διεύθυνση ηλεκτρονικού
          ταχυδρομείου, ο κωδικός πρόσβασης, η ημερομηνία γέννησης, το φύλο, και
          σε ορισμένες περιπτώσεις στοιχεία της υγεία σας όπως αν είστε
          καπνιστής, αν έχετε χρόνιες παθήσεις ή αλλεργίες, την ομάδα αίματος.
          Αυτές είναι οι μόνες πληροφορίες που χρειάζεται να παρέχεται για τη
          δημιουργία ενός λογαριασμού σε εμάς.
        </p>
        <h4>Β) ΕΠΙΠΛΕΟΝ ΠΛΗΡΟΦΟΡΙΕΣ</h4>
        <p>
          Για να βελτιώσετε την εμπειρία σας ή να ενεργοποιήσετε συγκεκριμένες
          δυνατότητες των Υπηρεσιών, μπορείτε να επιλέξετε να μας παράσχετε
          πρόσθετες πληροφορίες, όπως τα αρχεία καταγραφής σας για τα φάρμακα
          που λαμβάνεται, παρακολούθηση γυναικείας υγείας ή διαβήτη, το αρχείο
          καταγραφής σας με τις μετρήσεις υγείας ή/και τα συμπτώματα σας,
          στοιχεία σχετικά με τις χρόνιες ασθένειες σας και τις αλλεργίες σας,
          στοιχεία που αφορούν την άσκηση σας καθώς και διάφορες σημειώσεις σας.
        </p>
        <h3>
          <u>ΠΛΗΡΟΦΟΡΙΕΣ ΛΑΜΒΑΝΟΥΜΕ ΑΠΟ ΤΗ ΧΡΗΣΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ ΜΑΣ</u>
        </h3>
        <h4>ΠΛΗΡΟΦΟΡΙΕΣ ΧΡΗΣΗΣ</h4>
        <p>
          Όταν αποκτάτε πρόσβαση ή χρησιμοποιείτε τις Υπηρεσίες μας, λαμβάνουμε
          συγκεκριμένες πληροφορίες χρήσης ή δραστηριότητας δικτύου. Αυτό
          περιλαμβάνει πληροφορίες σχετικά με την αλληλεπίδρασή σας με τις
          Υπηρεσίες μας και τις πληροφορίες που εμπεριέχονται σε cookies που
          έχουν δημιουργηθεί από τις Υπηρεσίες μας.
        </p>
        <h4>ΠΛΗΡΟΦΟΡΙΕΣ ΠΟΥ ΛΑΜΒΑΝΟΥΜΕ ΑΠΟ ΤΡΙΤΑ ΜΕΡΗ</h4>
        <p>
          Εάν επιλέξετε να συνδέσετε τον λογαριασμό σας στις Υπηρεσίες μας με
          τον λογαριασμό σας σε άλλη υπηρεσία, ενδέχεται να λάβουμε πληροφορίες
          από την άλλη υπηρεσία. Για παράδειγμα, εάν συνδεθείτε μέσω Google,
          ενδέχεται να λάβουμε πληροφορίες όπως το όνομά σας, την εικόνα προφίλ,
          το εύρος ηλικιών, τη γλώσσα, τη διεύθυνση ηλεκτρονικού ταχυδρομείου.
          Μπορείτε επίσης να επιλέξετε να μας παραχωρήσετε πρόσβαση στα δεδομένα
          άσκησης ή δραστηριότητάς σας από άλλη υπηρεσία. Μπορείτε να
          σταματήσετε να κοινοποιείτε τις πληροφορίες από την άλλη υπηρεσία
          καταργώντας την πρόσβασή μας σε αυτήν την άλλη υπηρεσία. Η εφαρμογή
          μας δεν διατηρεί πρόσθετες πληροφορίες που προέρχονται από τρίτα μέρη
          όταν αυτές δεν είναι απαραίτητες για τη σωστή λειτουργία της.
        </p>
        <h3>
          <u>ΠΩΣ ΧΡΗΣΙΜΟΠΟΙΟΥΜΕ ΠΛΗΡΟΦΟΡΙΕΣ</u>
        </h3>
        <p>
          Χρησιμοποιούμε τις πληροφορίες που συλλέγουμε για τους ακόλουθους
          σκοπούς;
        </p>
        <h4>Α) ΠΑΡΟΧΗ ΚΑΙ ΣΥΝΤΗΡΗΣΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ</h4>
        <p>
          Χρησιμοποιώντας τις πληροφορίες που συλλέγουμε, είμαστε σε θέση να σας
          παρέχουμε τις Υπηρεσίες και να τηρούμε τη σύμβαση των Όρων Χρήσης μαζί
          σας. Για παράδειγμα, πρέπει να χρησιμοποιήσουμε τις πληροφορίες σας
          για να σας παρέχουμε ολοκληρωμένο προφίλ υγείας και να σας δώσουμε τις
          κατάλληλες συμβουλές.
        </p>
        <h4>Β) ΒΕΛΤΙΩΣΗ, ΠΡΟΣΩΠΙΚΟΠΟΙΗΣΗ ΚΑΙ ΑΝΑΠΤΥΞΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ</h4>
        <p>
          <p>
            Χρησιμοποιούμε τις πληροφορίες που συλλέγουμε για τη βελτίωση και
            την εξατομίκευση των Υπηρεσιών και για την ανάπτυξη νέων. Για
            παράδειγμα, χρησιμοποιούμε τις πληροφορίες για την αποστολή
            εξατομικευμένων σημειώσεων και tips.
          </p>
          <p>Ορίστε μερικά παραδείγματα:</p>
          <p>
            Πληροφορίες όπως το ύψος, το βάρος, το φύλο και η ηλικία μας μας
            επιτρέπουν να βελτιώσουμε την ακρίβεια των καθημερινών στατιστικών
            άσκησης και δραστηριότητάς σας.
          </p>
          <p>
            Με βάση τα δεδομένα σχετικά με τα συμπτώματα σας, ενδέχεται να σας
            ενημερώσουμε σχετικά με πιθανούς παράγοντες κινδύνου μέσω σχετικού
            μηνύματος ή να σας συμβουλέψουμε να απευθυνθείτε στον ιατρό σας.
          </p>
        </p>
        <h4>Γ) ΕΠΙΚΟΙΝΩΝΙΑ ΜΕ ΕΣΑΣ</h4>
        <p>
          Χρησιμοποιούμε τα στοιχεία σας όταν απαιτείται για να σας στείλουμε
          ειδοποιήσεις υπηρεσίας και να σας απαντήσουμε όταν επικοινωνείτε μαζί
          μας. Χρησιμοποιούμε επίσης τις πληροφορίες σας για την προώθηση νέων
          δυνατοτήτων ή προϊόντων που πιστεύουμε ότι θα σας ενδιαφέρουν.
          Μπορείτε να ελέγξετε τις επικοινωνίες μάρκετινγκ και τις περισσότερες
          ειδοποιήσεις υπηρεσίας χρησιμοποιώντας τις προτιμήσεις ειδοποιήσεων
          στις ρυθμίσεις λογαριασμού ή μέσω του συνδέσμου "Διαγραφή από τη
          λίστα" σε ένα μήνυμα ηλεκτρονικού ταχυδρομείου.
        </p>
        <h4>ΠΡΟΩΘΗΣΗ ΑΣΦΑΛΕΙΑΣ</h4>
        <p>
          <p>
            Χρησιμοποιούμε τις πληροφορίες που συλλέγουμε για να προωθήσουμε την
            ασφάλεια των Υπηρεσιών, των χρηστών μας και άλλων μερών. Για
            παράδειγμα, ενδέχεται να χρησιμοποιήσουμε τις πληροφορίες για έλεγχο
            ταυτότητας χρηστών, διευκόλυνση ασφαλών πληρωμών, προστασία από
            απάτη και κατάχρηση, απάντηση σε νομικό αίτημα ή αξίωση, διεξαγωγή
            ελέγχων και επιβολή των όρων και πολιτικών μας.
          </p>
          <p>
            Χρησιμοποιούμε cookies και παρόμοιες τεχνολογίες για τους σκοπούς
            που περιγράφονται παραπάνω. Για περισσότερες πληροφορίες, διαβάστε
            στη συνέχεια του εγγράφου τη δήλωση χρήσης cookie.
          </p>
        </p>
        <h4>ΠΩΣ ΚΟΙΝΟΠΟΙΟΥΝΤΑΙ ΟΙ ΠΛΗΡΟΦΟΡΙΕΣ</h4>
        <p>
          Ποτέ δεν πουλάμε τα προσωπικά στοιχεία των χρηστών μας. Δεν
          κοινοποιούμε τα προσωπικά σας στοιχεία εκτός από τις περιορισμένες
          περιπτώσεις που περιγράφονται παρακάτω.
        </p>
        <h4>ΟΤΑΝ ΣΥΜΦΩΝΗΣΕΤΕ Ή ΖΗΤΗΣΕΤΕ ΝΑ ΜΟΙΡΑΣΤΟΥΝ</h4>
        <p>
          Μπορείτε να μας καθοδηγήσετε να μοιραστούμε τις πληροφορίες σας με
          άλλους τρόπους, για παράδειγμα, όταν παραχωρείτε σε τρίτη εφαρμογή
          πρόσβαση στον λογαριασμό σας. Να θυμάστε ότι η από μέρους τους χρήση
          των πληροφοριών σας θα διέπεται από τις πολιτικές και τους όρους
          απορρήτου τους. Μπορείτε να ανακαλέσετε τη συγκατάθεσή σας για κοινή
          χρήση με εφαρμογές τρίτων ή προγράμματα ευεξίας υπαλλήλων
          χρησιμοποιώντας τις ρυθμίσεις του λογαριασμού σας.
        </p>
        <h4>ΓΙΑ ΝΟΜΙΚΟΥΣ ΛΟΓΟΥΣ ΓΙΑ ΤΗΝ ΠΡΟΛΗΨΗ ΤΟΥ ΑΠΑΤΗΣ</h4>
        <p>
          Μπορούμε να διατηρήσουμε ή να αποκαλύψουμε πληροφορίες σχετικά με εσάς
          για να συμμορφωθείτε με νόμο, κανονισμό, νομική διαδικασία ή
          κυβερνητικό αίτημα. να διεκδικήσουμε νομικά δικαιώματα ή να
          υπερασπιστούμε τις νομικές αξιώσεις ή για να αποτρέψουμε, να
          εντοπίσουμε ή να ερευνήσουμε παράνομη δραστηριότητα, απάτη, κατάχρηση,
          παραβιάσεις των όρων μας ή απειλές για την ασφάλεια των Υπηρεσιών ή τη
          φυσική ασφάλεια οποιουδήποτε ατόμου.
        </p>
        <h3>
          <u>
            ΤΑ ΔΙΚΑΙΩΜΑΤΑ ΣΑΣ, ΠΡΟΣΒΑΣΗ ΚΑΙ ΕΛΕΓΧΟΣ ΤΩΝ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ ΣΑΣ
          </u>
        </h3>
        <p>
          Σας παρέχουμε ρυθμίσεις και εργαλεία λογαριασμού για πρόσβαση και
          έλεγχο των προσωπικών σας δεδομένων, όπως περιγράφεται παρακάτω:
        </p>
        <p>
          <u>
            Σας παρέχουμε ρυθμίσεις και εργαλεία λογαριασμού για πρόσβαση και
            έλεγχο των προσωπικών σας δεδομένων, όπως περιγράφεται παρακάτω:
          </u>
          Εάν συνδεθείτε στον λογαριασμό σας, μπορείτε να αποκτήσετε πρόσβαση σε
          όλα τα προσωπικά σας στοιχεία. Χρησιμοποιώντας τις ρυθμίσεις του
          λογαριασμού σας, μπορείτε επίσης να κάνετε λήψη πληροφοριών σε μια
          μορφή αρχείου που χρησιμοποιείται συνήθως.
        </p>
        <p>
          <h3>
            <u>ΔΙΑΤΗΡΗΣΗ ΔΕΔΟΜΕΝΩΝ</u>
          </h3>
          <p>
            Διατηρούμε τα στοιχεία του λογαριασμού σας, όπως το όνομα, τη
            διεύθυνση ηλεκτρονικού ταχυδρομείου και τον κωδικό πρόσβασής σας,
            για όσο διάστημα ο λογαριασμός σας υπάρχει, επειδή χρειαζόμαστε για
            τη λειτουργία του λογαριασμού σας. Διατηρούμε άλλες πληροφορίες,
            όπως τα δεδομένα φαρμακευτικής αγωγής ή μετρήσεων υγείας, έως ότου
            χρησιμοποιήσετε τις ρυθμίσεις ή τα εργαλεία του λογαριασμού σας για
            να διαγράψετε τα δεδομένα ή τον λογαριασμό σας, επειδή
            χρησιμοποιούμε αυτά τα δεδομένα για να σας παρέχουμε τα προσωπικά
            σας στατιστικά στοιχεία και άλλες πτυχές των Υπηρεσιών. Διατηρούμε
            επίσης πληροφορίες σχετικά με εσάς και τη χρήση των Υπηρεσιών από
            εσάς για όσο διάστημα είναι απαραίτητο για τα νόμιμα επιχειρηματικά
            μας συμφέροντα, για νομικούς λόγους, και για την αποτροπή βλάβης,
            όπως περιγράφεται στις σχετικές ενότητες.
          </p>
          <h3>
            <u>ΔΗΛΩΣΗ ΓΙΑ ΧΡΗΣΗ COOKIES</u>
          </h3>
          <h4>ΤΙ ΕΙΝΑΙ ΤΑ COOKIES;</h4>
          <p>
            Τα cookies είναι μικρά αρχεία κειμένου που αποθηκεύονται στον
            εξοπλισμό τερματικού που διαθέτετε (υπολογιστή ή φορητή συσκευή)
            όταν επισκέπτεστε συγκεκριμένες διαδικτυακές τοποθεσίες.
          </p>
          <h4>ΓΙΑΤΙ ΧΡΗΣΙΜΟΠΟΙΟΥΝΤΑΙ ΤΑ COOKIES</h4>
          <p>
            <p>
              Τα cookies μπορεί να χρησιμοποιούνται για πολλούς διαφορετικούς
              σκοπούς. Σε πρώτο στάδιο, τα cookies ενδέχεται να απαιτούνται για
              τη σωστή λειτουργία της διαδικτυακής τοποθεσίας.
            </p>
            <p>
              Τα cookies μπορούν επίσης να χρησιμοποιηθούν για την ανάλυση του
              τρόπου με τον οποίο χρησιμοποιείται μια διαδικτυακή τοποθεσία, την
              καταμέτρηση του αριθμού επισκεπτών, καθώς και τη λήψη πληροφοριών
              για τον τρόπο με τον οποίο μπορεί να βελτιωθεί μια διαδικτυακή
              τοποθεσία.
            </p>
          </p>
          <h4>ΠΟΙΑ ΕΙΔΗ COOKIES ΧΡΗΣΙΜΟΠΟΙΟΥΝΤΑΙ ΑΠΟ ΤΟΝ ΙΣΤΟΤΟΠΟ ΜΑΣ</h4>
          <p>
            <p>ΠΟΙΑ ΕΙΔΗ COOKIES ΧΡΗΣΙΜΟΠΟΙΟΥΝΤΑΙ ΑΠΟ ΤΟΝ ΙΣΤΟΤΟΠΟ ΜΑΣ</p>
            <p>Απαραίτητα Cookies</p>
            <p>
              Ως απαραίτητα cookies νοούνται τα απολύτως αναγκαία για τη
              λειτουργία των ιστοτοπων της TECHAPPS HEALTHIER. Σας επιτρέπουν να
              περιηγηθείτε στον ιστότοπο και να κάνετε χρήση των λειτουργιών που
              σας παρέχουμε. Τα cookies συνεδρίας είναι προσωρινά αρχεία cookies
              τα οποία διαγράφονται όταν απενεργοποιείτε τον περιηγητή σας. Όταν
              τον επανεκκινήσετε και επισκεφτείτε εκ νέου τον ιστότοπο που
              δημιούργησε το cookie, o ιστότοπος θα σας αντιμετωπίσει σαν ένα
              νέο επισκέπτη. Για διατήρηση της σύνδεσής σας ενδέχεται να
              αποθηκευθεί cookie μεγαλύτερης χρονικής διάρκειας κατόπιν εντολής
              σας («Να παραμένω συνδεδεμένος»)
            </p>
            <p>Cookies Ανάλυσης</p>
            <p>Cookies Ανάλυσης</p>
            <p>
              Μπορείτε να βρείτε περαιτέρω πληροφορίες σχετικά με τον τρόπο
              απενεργοποίησης των cookies ή προσαρμογής των ρυθμίσεων των
              cookies για το πρόγραμμα περιήγησης που χρησιμοποιείτε από την
              παρακάτω λίστα:
            </p>
            <p>
              <ul>
                <li>
                  Google Chrome:
                  https://support.google.com/chrome/answer/95647?hl=en
                </li>
                <li>
                  Firefox:
                  https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                </li>
                <li>
                  nternet Explorer:
                  http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies
                </li>
                <li>
                  Safari: http://help.apple.com/safari/mac/8.0/#/sfri11471
                </li>
              </ul>
            </p>
          </p>
          <h3>
            <u>ΑΣΦΑΛΕΙΑ ΠΛΗΡΟΦΟΡΙΩΝ</u>
          </h3>
          <p>
            Εργαζόμαστε σκληρά για να διατηρήσουμε τα δεδομένα σας ασφαλή.
            Χρησιμοποιούμε έναν συνδυασμό τεχνικών, διοικητικών και φυσικών
            ελέγχων για τη διατήρηση της ασφάλειας των δεδομένων σας. Αυτό
            περιλαμβάνει τη χρήση Transport Layer Security ("TLS") για την
            κρυπτογράφηση πολλών από τις Υπηρεσίες μας. Ωστόσο, καμία μέθοδος
            μετάδοσης ή αποθήκευσης δεδομένων δεν είναι απολύτως ασφαλής.
          </p>
          <h3>
            <u>ΕΥΡΩΠΑΪΚΕΣ ΓΝΩΣΤΟΠΟΙΗΣΕΙΣ ΠΡΟΣΤΑΣΙΑΣ</u>
          </h3>
          <p>
            <p>
              Η προστασία απορρήτου διέπετε από τον Γενικό Κανονισμό Προστασίας
              Δεδομένων της Ευρωπαϊκής Ένωσης («GDPR»).
            </p>
            <p>
              Η TechApps Healthier, μια Ελληνική εταιρεία, είναι ο ελεγκτής
              δεδομένων σας και παρέχει τις Υπηρεσίες. Για τα στοιχεία
              επικοινωνίας μας, ανατρέξτε στην ενότητα Ποιοι είμαστε και πώς να
              επικοινωνήσετε μαζί μας.
            </p>
          </p>
          <h4>ΥΓΕΙΑ ΚΑΙ ΑΛΛΕΣ ΕΙΔΙΚΕΣ ΚΑΤΗΓΟΡΙΕΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ</h4>
          <p>
            <p>
              Στο βαθμό που οι πληροφορίες που συλλέγουμε είναι δεδομένα υγείας
              ή άλλη ειδική κατηγορία προσωπικών δεδομένων που υπόκεινται στον
              GDPR, ζητάμε τη ρητή συγκατάθεσή σας για την επεξεργασία των
              δεδομένων. Λαμβάνουμε αυτήν τη συγκατάθεση ξεχωριστά όταν
              πραγματοποιείτε ενέργειες που οδηγούν στη λήψη των δεδομένων μας,
              για παράδειγμα, όταν συνδέσετε τη συσκευή σας με τον λογαριασμό
              σας από άλλη υπηρεσία. Μπορείτε να χρησιμοποιήσετε τις ρυθμίσεις
              και τα εργαλεία του λογαριασμού σας για να αποσύρετε τη
              συγκατάθεσή σας ανά πάσα στιγμή, συμπεριλαμβανομένης της διακοπής
              της χρήσης μιας δυνατότητας, της κατάργησης της πρόσβασής μας σε
              υπηρεσία τρίτου μέρους, της αποσύνδεσης της συσκευής σας ή της
              διαγραφής των δεδομένων ή του λογαριασμού σας.
            </p>
            <p>
              Οι νομικές μας βάσεις για την επεξεργασία προσωπικών δεδομένων
            </p>
            <p>
              Η επεξεργασία των προσωπικών σας δεδομένων, υπόκεινται στον
              κανονισμό GDPR, ως εξής:
            </p>
            <p>
              Όταν έχετε δώσει τη συγκατάθεσή σας, μπορείτε να την αποσύρετε ανά
              πάσα στιγμή χρησιμοποιώντας τις ρυθμίσεις του λογαριασμού σας και
              άλλα εργαλεία.
            </p>
            <p>
              Όταν η επεξεργασία είναι απαραίτητη για την εκτέλεση συμβολαίου
              μαζί σας, όπως οι Όροι Παροχής Υπηρεσιών. και
            </p>
            <p>
              Τα νόμιμα επιχειρηματικά μας συμφέροντα, όπως η βελτίωση, η
              εξατομίκευση και η ανάπτυξη των Υπηρεσιών, η εμπορία νέων
              δυνατοτήτων ή προϊόντων που μπορεί να ενδιαφέρουν και η προώθηση
              της ασφάλειας και προστασίας όπως περιγράφεται στην ενότητα Πώς
              χρησιμοποιούμε τις πληροφορίες.
            </p>
          </p>
          <h4>ΠΩΣ ΝΑ ΑΣΚΗΣΕΤΕ ΤΑ ΝΟΜΙΜΑ ΔΙΚΑΙΩΜΑΤΑ ΣΑΣ</h4>
          <p>
            <p>
              Ανατρέξτε στην ενότητα «Τα δικαιώματά σας για πρόσβαση και έλεγχο
              των προσωπικών σας δεδομένων» για το πώς οι ρυθμίσεις και τα
              εργαλεία του λογαριασμού σας σάς επιτρέπουν να ασκήσετε τα
              δικαιώματά σας βάσει του GDPR για πρόσβαση και έλεγχο των
              προσωπικών σας δεδομένων.
            </p>
            <p>
              Εκτός από τους διάφορους ελέγχους που προσφέρουμε, σε ορισμένες
              περιπτώσεις, μπορείτε να επιδιώξετε να περιορίσετε την επεξεργασία
              των δεδομένων σας ή να αντιταχθείτε στην επεξεργασία των δεδομένων
              σας βάσει των νόμιμων συμφερόντων μας, όπως περιγράφεται στην
              ενότητα Πώς χρησιμοποιούμε τις πληροφορίες. Σύμφωνα με το GDPR,
              έχετε γενικό δικαίωμα να αντιταχθείτε στη χρήση των πληροφοριών
              σας για σκοπούς άμεσου μάρκετινγκ. Η δήλωση χρήσης cookie
              περιγράφει τις επιλογές σας για τον έλεγχο του τρόπου με τον οποίο
              εμείς και οι συνεργάτες μας χρησιμοποιούμε cookies και παρόμοιες
              τεχνολογίες για τη διαφήμιση. Λάβετε υπόψη ότι μπορείτε πάντα να
              διαγράψετε τον λογαριασμό σας ανά πάσα στιγμή.
            </p>
            <p>
              Εάν χρειάζεστε περαιτέρω βοήθεια σχετικά με τα δικαιώματά σας,
              επικοινωνήστε με τον υπεύθυνο προστασίας δεδομένων και θα
              εξετάσουμε το αίτημά σας σύμφωνα με τους ισχύοντες νόμους. Έχετε
              επίσης το δικαίωμα να υποβάλετε καταγγελία στην τοπική αρχή
              προστασίας δεδομένων.
            </p>
          </p>
          <h3>
            <u>ΠΟΙΟΙ ΕΙΜΑΣΤΕ ΚΑΙ ΠΩΣ ΝΑ ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΕ ΜΑΣ</u>
          </h3>
          <p>
            Εάν έχετε απορίες σχετικά με αυτήν την πολιτική ή χρειάζεστε βοήθεια
            για την άσκηση των δικαιωμάτων απορρήτου σας, επικοινωνήστε με τον
            υπεύθυνο προστασίας δεδομένων στη διεύθυνση Ιπποκράτους 56, Αθήνα ,
            2130450591, email: info@healthier-app.com - Ν. Δ. ΒΑΣΙΛΑΚΗΣ ΚΑΙ ΣΙΑ
            Ο.Ε
          </p>
        </p>
      </p>
    </Container>
  );
};
export const Terms = hot(_Terms);
