import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  Popover,
  Drawer,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { ExamFile } from 'components/Exams/ExamFile';
import { ExamsDrawer } from 'components/Exams/ExamsDrawer';
import { ExamsFilterBar } from 'components/Filter/ExamsFilterBar';
import { LoadingPage } from 'components/LoadingPage';
import React, { useEffect, useState, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Colors } from 'res/Enums';
import { EmptyPage } from 'components/EmptyPage';
import Empty from 'assets/icons/examsEmpty.svg';
import { useTutorialContext } from 'context/TutorialContext';
import { Demo } from 'components/Demo';
import { isUndefined } from 'util';
import {
  pdfControllerGetMyExams,
  usePdfControllerGetMyExams,
  UserPdfDTO,
} from 'gen/client';

interface ExamsProps {
  tut: number;
}

const _Exams = ({ tut }: ExamsProps) => {
  const [rawData, setRawData] = useState<UserPdfDTO[]>([]);
  const [exams, setExams] = useState<UserPdfDTO[]>([]);
  const [examToEdit, setExamToEdit] = useState<UserPdfDTO | undefined>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const { openT, me, cont, setCont } = useTutorialContext();
  const [temp, setTemp] = useState(0);
  const inputEl = useRef<any | null>(null);
  const {
    data: examsData,
    isLoading,
    refetch: getMyExams,
  } = usePdfControllerGetMyExams();

  useEffect(() => {
    if (examsData) {
      setRawData(examsData.data);
      setExams(examsData.data);
    }
  }, [examsData]);
  useEffect(() => {
    setTemp(cont);
    setCont(cont + 1);
  }, []);

  const [e, setE] = useState('');

  useEffect(() => {
    if (openT === '19') setDrawerOpen(true);
    if (openT === '19') setE('19');
    else setE('');
    if (openT === '18' && e === '19') {
      setTemp(0);
      setCont(cont + 1);
    }
    if (openT === '19' && e !== '19') setDrawerOpen(true);
  }, [openT]);

  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <Grid
      container
      style={
        !isUndefined(tut)
          ? {
              display: 'flex',
              flexDirection: 'column',
              padding: '16px 16px 0px 16px',
            }
          : { display: 'flex', flexDirection: 'column' }
      }
      ref={inputEl}
    >
      <Box
        display="flex"
        flex="1 1 auto"
        style={{
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: isBelowXs ? 'column' : 'row',
        }}
      >
        <Box display="flex" flex="1 1 auto" flexDirection="column">
          <Typography
            style={{
              fontSize: 21,
              fontWeight: 'bold',
              color: Colors.FadeBlack,
            }}
          >
            {t('ExamsTitle')}
          </Typography>
          <Typography
            style={{
              fontSize: 16,
              color: Colors.FadeBlack,
              marginBottom: isBelowXs ? 16 : 0,
            }}
          >
            {t('ExamsText')}
          </Typography>
        </Box>
        <Box
          style={{
            marginBottom: 24,
            display: 'flex',
            justifyContent: isBelowXs ? 'center' : 'flex-start',
          }}
        >
          {rawData.length > 0 && (
            <Button
              color="primary"
              variant="contained"
              style={{ color: Colors.IceWhite }}
              startIcon={<Add />}
              onClick={() => setDrawerOpen(true)}
            >
              {t('AddExam')}
            </Button>
          )}
        </Box>
      </Box>
      {rawData.length > 0 ? (
        <Box className={classes.examsContainer}>
          <ExamsFilterBar data={rawData} onFilter={(data) => setExams(data)} />
          <Typography
            variant="h5"
            style={{ color: Colors.FadeBlack, marginTop: 20 }}
          >
            {t('AllExams')}
          </Typography>
          <Box className={classes.fileList}>
            {exams.map((exam, i) => (
              <ExamFile
                key={i}
                exam={exam}
                onEdit={(exam) => {
                  setExamToEdit(exam);
                  setDrawerOpen(true);
                }}
                onDelete={() => getMyExams()}
              />
            ))}
          </Box>
        </Box>
      ) : (
        <EmptyPage
          onPress={() => setDrawerOpen(true)}
          text={t('ExamFolder')}
          text1={t('ExamFolderEmtpyText')}
          text2={t('AddExam')}
          image={Empty}
        />
      )}
      {temp < 1 && (
        <Dialog
          open={!me?.tutorial && parseInt(openT) < 20 && openT !== '0'}
          BackdropProps={{
            style: { backgroundColor: 'black', opacity: 0.8 },
          }}
        >
          <Popover
            open={parseInt(openT) < 19}
            anchorEl={inputEl.current}
            PaperProps={{
              style: { width: isBelowMd ? '85vw' : '70vw', height: '80vh' },
            }}
          >
            <Exams tut={cont} />
            <Popover
              open={parseInt(openT) < 19}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  marginLeft: '-16%',
                  marginTop: 4,
                  width: isBelowMd ? '60vw' : '50vw',
                },
              }}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 22 }}>
                {t('Tut26')}
              </Typography>
              <Typography
                style={{ color: Colors.IceWhite, fontSize: 18, marginTop: 10 }}
              >
                {t('Tut27')}
              </Typography>
            </Popover>
            {openT === '18' && <Demo />}
          </Popover>
        </Dialog>
      )}
      <Drawer
        open={drawerOpen}
        anchor="right"
        PaperProps={{ style: { width: '30vw', minWidth: 430 } }}
        onClose={() => setDrawerOpen(false)}
      >
        <ExamsDrawer
          open={drawerOpen}
          onClose={() => {
            setExamToEdit(undefined);
            setDrawerOpen(false);
          }}
          exam={examToEdit}
          onUpload={() => {
            getMyExams();
            setDrawerOpen(false);
          }}
          onDelete={() => {
            getMyExams();
            setDrawerOpen(false);
          }}
        />
      </Drawer>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  examsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
export const Exams = hot(_Exams);
