export enum DateFormats {
  GR = 'DD/MM/YYYY HH:mm',
  GrNoTime = 'DD/MM/YYYY',
  US = 'MM/DD/YYYY HH:mm',
  SQL = 'YYYY/MM/DD HH:mm:ss',
  TIME = 'h:mm a',
  ISO = 'YYYY-MM-DDTHH:mm:ss',
  ISONoTime = 'YYYY-MM-DD',
  MONTH = 'MMMM YYYY',
}
export interface ObjectIndexer {
  [key: string]: string | number;
}
export enum AuthStatus {
  LOGGED_OUT = 'Logged out',
  LOGGED_IN = 'Logged in',
  PENDING = 'Pending',
  REGISTERED = 'Just Registered',
  GOOGLEREGISTERED = 'Google Registered',
}

export enum Locale {
  EN = 'en-gb',
  GR = 'el-gr',
}
export enum Colors {
  SECONDARY = '#ffffff',
  PRIMARY = '#5ca595',
  PrimaryLight = '#5CA595',
  BlueOpaque = '#deeafb',
  BlueText = '#2863cd',
  IceWhite = '#F5F6F7',
  Gray = '#C4C4C4',
  White = '#ffffff',
  FadeBlack = '#263238',
  Black = '#000000',
}

export enum Address {
  Local = 'http://localhost:5000',
  Deployment = '',
}
export const dosage = ['1/2', '1', '2', '3'];
