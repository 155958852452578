import {
  Box,
  CircularProgress,
  useTheme,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import {
  Scheduler,
  MonthView,
  Appointments,
  DateNavigator,
  Toolbar,
  TodayButton,
} from '@devexpress/dx-react-scheduler-material-ui';
import { ViewState, AppointmentModel } from '@devexpress/dx-react-scheduler';
import moment, { Moment } from 'moment';
import { Colors, DateFormats } from 'res/Enums';
import { uniq } from 'underscore';
import { useAppContext } from 'context/AppContext';
import { StyledDatePicker } from 'components/DatePicker';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  GetNextDosagesDTO,
  medicationControllerGetNextDosagesCalendar,
  noteControllerGetMyNotesMonth,
  symptomControllerGetMySymptomsByMonth,
} from 'gen/client';
interface CalendarSchedulerProps {
  onSelectDate: (date: Moment) => void;
}
const _CalendarScheduler = ({ onSelectDate }: CalendarSchedulerProps) => {
  const { lang } = useAppContext();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [currentDate, setCurrentDate] = useState(moment());
  const [notes, setNotes] = useState<AppointmentModel[]>([]);
  const [symptoms, setSymptoms] = useState<AppointmentModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeMedication, setActiveMedication] = useState<GetNextDosagesDTO[]>(
    []
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const getAllRef = useRef(false);

  const getAll = () => {
    const getDosagesPromise = medicationControllerGetNextDosagesCalendar({
      now: selectedDate.clone().startOf('month').format(DateFormats.ISONoTime),
      until: selectedDate.clone().endOf('month').format(DateFormats.ISONoTime),
    });

    const getNotesPromise = noteControllerGetMyNotesMonth(
      selectedDate.format(DateFormats.ISONoTime)
    );

    const getSymptomsPromise = symptomControllerGetMySymptomsByMonth(
      selectedDate.format(DateFormats.ISONoTime)
    );
    Promise.all([getDosagesPromise, getNotesPromise, getSymptomsPromise]).then(
      (values) => {
        setActiveMedication(values[0].data);
        setNotes(
          values[1].data.map((item) => ({
            startDate: item.time,
            endDate: moment(item.time)
              .add(1, 'd')
              .format(DateFormats.ISONoTime),
          }))
        );
        setSymptoms(
          values[2].data.map((item) => ({
            startDate: item.time,
            endDate: moment(item.time)
              .add(1, 'd')
              .format(DateFormats.ISONoTime),
          }))
        );
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (getAllRef.current) getAll();
    else getAllRef.current = true;
  }, [selectedDate]);

  useEffect(() => {
    onSelectDate(currentDate);
    setSelectedDate(currentDate);
  }, [currentDate]);

  if (loading) {
    return (
      <Box
        style={{
          height: 700,
          backgroundColor: Colors.White,
          width: isBelowXs ? '100vw' : '65vw',
        }}
      >
        <CircularProgress
          style={{ position: 'fixed', top: '50vh', left: '50vw' }}
          size={72}
        />
      </Box>
    );
  }
  return (
    <Scheduler
      locale={lang}
      data={uniq(notes.concat(symptoms), (item) => item.startDate)}
    >
      <ViewState
        currentDate={selectedDate.toDate()}
        onCurrentDateChange={(date) => setSelectedDate(moment(date))}
      />
      <MonthView
        timeTableCellComponent={(props) =>
          CellComponent(props, currentDate, activeMedication, (date) =>
            setCurrentDate(date)
          )
        }
      />
      <Toolbar />
      <DateNavigator
        rootComponent={(props) =>
          NavigatorComponent(selectedDate, (date) => setSelectedDate(date))
        }
      />
      <TodayButton messages={{ today: t('Today') }} />
      <Appointments
        appointmentComponent={(props) => {
          return (
            <Appointments.Appointment
              {...props}
              style={{
                background: 'transparent',
                cursor: 'pointer',
                border: 'none',
              }}
              onClick={() => setCurrentDate(moment(props.data.startDate))}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Box
                  style={{
                    backgroundColor: 'red',
                    height: 20,
                    width: 20,
                    borderRadius: '100%',
                  }}
                />
              </Box>
            </Appointments.Appointment>
          );
        }}
      />
    </Scheduler>
  );
};
const CellComponent = (
  props: MonthView.TimeTableCellProps,
  selectedDate: Moment,
  activeMedication: GetNextDosagesDTO[],
  callback: (date: Moment) => void
) => (
  <MonthView.TimeTableCell
    {...props}
    style={{
      background: selectedDate.isSame(moment(props.startDate), 'day')
        ? Colors.PrimaryLight
        : 'inherit',
      color: searchOneDosageExists(moment(props.startDate), activeMedication)
        ? 'blue'
        : Colors.FadeBlack,
      cursor: 'pointer',
    }}
    onClick={() => callback(moment(props.startDate))}
  />
);
const NavigatorComponent = (day: Moment, callback: (date: Moment) => void) => {
  const [date, setDate] = useState(day);
  const theme = useTheme();
  const isBelowS = useMediaQuery(theme.breakpoints.down(700));
  useEffect(() => {
    callback(date);
  }, [date]);
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: isBelowS ? 'center' : 'flex-start',
      }}
    >
      <StyledDatePicker
        value={date}
        onChange={(date) => setDate(date as Moment)}
        style={{
          border: '1px solid rgba(0, 0, 0, 0.23)',
          marginLeft: 20,
          padding: isBelowS ? '0px 10px 4px 10px' : '4px 10px',
          width: isBelowS ? 135 : 165,
          height: isBelowS ? 'fit-content' : '100%',
        }}
        format="MMMM YYYY"
        inputProps={{
          style: {
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: isBelowS ? 13 : 16,
          },
        }}
      />
      <IconButton
        style={{ padding: '8px 16px' }}
        onClick={() =>
          setDate(moment(date).subtract(1, 'months').startOf('month'))
        }
      >
        <ArrowBack style={{ height: 26 }} />
      </IconButton>
      <IconButton
        style={{ padding: '8px 16px' }}
        onClick={() => setDate(moment(date).add(1, 'months').startOf('month'))}
      >
        <ArrowForward style={{ height: 26 }} />
      </IconButton>
    </Box>
  );
};
const searchOneDosageExists = (
  date: Moment,
  activeMedication: GetNextDosagesDTO[]
): boolean => {
  let flag = false;
  for (let med of activeMedication) {
    if (
      med.dosages
        .map((item) => moment(item).format(DateFormats.ISONoTime))
        .includes(date.format(DateFormats.ISONoTime))
    ) {
      flag = true;
    }
    if (flag) break;
  }
  return flag;
};
export const CalendarScheduler = hot(_CalendarScheduler);
