import { Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import Heart from 'assets/icons/Heart-big.svg';
import Stethoscope from 'assets/icons/Stetho-big.svg';
import { Colors } from 'res/Enums';
import { useTranslation } from 'react-i18next';
const _IntroPage2 = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isBelowXl = useMediaQuery(theme.breakpoints.down(1200));
  return (
    <Box
      height="100%"
      style={{
        backgroundColor: Colors.IceWhite,
        position: 'relative',
      }}
    >
      <img
        alt=""
        src={Stethoscope}
        style={{ position: 'absolute', left: 75, top: '15%' }}
      />
      <Box
        style={{
          color: Colors.Black,
          position: 'absolute',
          left: 75,
          top: 410,
        }}
      >
        <Typography align="left" variant="h4">
          {t('Intro4')}
        </Typography>
        <Typography align="left" variant="h2">
          {t('Intro5')}
        </Typography>
        <Typography
          align="left"
          style={{ fontSize: '3vh', maxWidth: '80%', marginTop: 50 }}
        >
          {t('Intro6')}
        </Typography>{' '}
        <img
          alt=""
          src={Heart}
          style={{
            position: 'absolute',
            left: 350,
            top: isBelowXl ? '-60%' : '-78%',
          }}
        />
      </Box>
    </Box>
  );
};
export const IntroPage2 = hot(_IntroPage2);
