import Axios from 'axios';
import { getApiAddress } from 'index';
import { Column } from 'material-table';
import i18n from './locales/locales';
import { Moment } from 'moment';

export function getTableColumns<T extends object>(data: T[]) {
  return Object.keys(data[0]).map((key) => {
    return {
      title: i18n.t(key),
      field: key,
      type: isDateKey(key) ? 'date' : 'string',
    };
  }) as Column<T>[];
}

export function isDateKey(date: string): boolean {
  const exp = new RegExp(/.*(Date).*|.*(Time).*/);
  return exp.test(date);
}
export function isDate<T>(date: T | string | number): boolean {
  const EnExp = new RegExp(/[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]).*/);
  return EnExp.test(date as string);
}
export const uploadFile = (
  file: FormData,
  progress: (percent: number) => void,
  token: string
) => {
  return Axios.post(getApiAddress() + '/me/pdf', file, {
    headers: { Authorization: `bearer ${token}` },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      progress(percentCompleted);
    },
  });
};

export const uploadPregnancyFile = (
  file: FormData,
  progress: (percent: number) => void,
  token: string
) => {
  return Axios.post(getApiAddress() + '/me/pregnancy/userpdf', file, {
    headers: { Authorization: `bearer ${token}` },
    onUploadProgress: (progressEvent) => {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      progress(percentCompleted);
    },
  });
};

export function unpair(n: number) {
  const low = ((n % 1000) - 100) / 10;
  const high = (n - (n % 1000) - 100000) / 10000;

  return {
    High: high,
    Low: low,
  };
}

export function pair(high: number, low: number) {
  high = transform(high);
  low = transform(low);
  high = round(high, 1);
  low = round(low, 1);

  return 100100 + low * 10 + high * 10000;
}

export function round(number: number, precision: number) {
  const factor = Math.pow(10, precision);
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
}

const transform = function (number: number) {
  while (number > 30) {
    number = number / 10;
  }
  return number;
};
export async function delay(delayInms: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}
export const getDays = (date: Moment) => {
  const dates: Moment[] = [];
  for (let i = 0; i < 7; i++) {
    dates.push(date.set({ hour: 11, minute: 0, second: 0 }).clone());
    date = date.add(1, 'day');
  }
  return dates;
};
