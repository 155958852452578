import {
  Paper,
  Typography,
  Button,
  Box,
  Popover,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { Colors } from 'res/Enums';
import { useTutorialContext } from 'context/TutorialContext';
import { useHistory } from 'react-router';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { toast } from 'react-toastify';
import { useConfirm } from './ConfirmDialogProvider';
import { meControllerUpdateMe, UpdateUser } from 'gen/client';

const _Demo = () => {
  const { t } = useTranslation();
  const { openT, setOpenT, setCont, me, getMe } = useTutorialContext();
  const history = useHistory();
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('sm'));
  const confirm = useConfirm();

  const handleEnd = (tut: boolean) => {
    meControllerUpdateMe({
      ...(me as UpdateUser),
      tutorial: tut,
    })
      .then(() => {
        toast(t('TutEnd'));
        getMe();
      })
      .catch();
  };

  return (
    <Popover
      open={true}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      PaperProps={{
        style:
          isBelowSm && openT === '9'
            ? {
                backgroundColor: 'transparent',
                WebkitTapHighlightColor: 'transparent',
                boxShadow: 'initial',
                marginTop: '55vh',
              }
            : {
                backgroundColor: 'transparent',
                WebkitTapHighlightColor: 'transparent',
                boxShadow: 'initial',
              },
      }}
    >
      <Box
        style={{
          width: '97vw',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            backgroundColor: Colors.Black,
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 24px',
          }}
        >
          <Typography
            style={{
              color: Colors.IceWhite,
              padding: '12px 32px 8px 32px',
              textAlign: 'center',
              fontSize: 24,
            }}
          >
            {t('Tutorial')}
          </Typography>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 8,
              marginTop: 8,
            }}
          >
            {openT !== '1' && (
              <IconButton style={{ padding: 0 }}>
                <ArrowBackIosIcon
                  onClick={() => {
                    setOpenT((parseInt(openT) - 1).toString());
                    setCont(0);
                    if (openT === '7') history.push('/');
                    if (openT === '9') history.push('/measurements');
                    if (openT === '15') history.push('/measurements/4');
                    if (openT === '18') history.push('/medication');
                    if (openT === '20') history.push('/exams');
                    if (openT === '21') history.push('/healthProfile');
                    if (openT === '22') history.push('/concise');
                    if (openT === '23') history.push('/health');
                  }}
                  style={{ color: Colors.IceWhite, fontSize: 38 }}
                />
              </IconButton>
            )}
            <Box style={{ margin: '0px 8px', display: 'flex' }}>
              <Typography
                style={{
                  color: Colors.IceWhite,
                  fontSize: 20,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {openT === '12'
                  ? 11
                  : parseInt(openT) > 12
                  ? parseInt(openT) - 2
                  : parseInt(openT)}
              </Typography>
              <Typography
                style={{ padding: '0px 8px', fontSize: 24, fontWeight: 'bold' }}
              >
                /
              </Typography>
              <Typography
                style={{
                  color: Colors.IceWhite,
                  fontSize: 20,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                21
              </Typography>
            </Box>
            {openT !== '23' && openT !== '24' && (
              <IconButton style={{ padding: 0 }}>
                <ArrowForwardIosIcon
                  onClick={() => {
                    setOpenT((parseInt(openT) + 1).toString());
                    setCont(0);
                    if (openT === '6') history.push('/measurements');
                    if (openT === '8') history.push('/measurements/4');
                    if (openT === '14') history.push('/medication');
                    if (openT === '17') history.push('/exams');
                    if (openT === '19') history.push('/healthProfile');
                    if (openT === '20') history.push('/concise');
                    if (openT === '21') history.push('/health');
                    if (openT === '22') history.push('/preventive');
                  }}
                  style={{ color: Colors.IceWhite, fontSize: 38 }}
                />
              </IconButton>
            )}
          </Box>
          <Box
            style={{
              width: '100%',
              margin: '6px 0px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ width: 'fit-content', padding: '4px 24px' }}
              onClick={() => {
                if (parseInt(openT) < 23)
                  confirm({
                    title: t('TutSure'),
                    description: t('TutSure1'),
                    cancellationText: t('Cancel'),
                  }).then(() => {
                    handleEnd(true);
                    history.push('/success');
                  });
                else {
                  handleEnd(true);
                  history.push('/success');
                }
              }}
            >
              {t('Exit')}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Popover>
  );
};

export const Demo = hot(_Demo);
