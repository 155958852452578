import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  Tooltip,
  useMediaQuery,
  useTheme,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateFormats, Colors } from 'res/Enums';
import { NextMedication } from 'components/Home/NextMedication';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router';
import { Note } from 'components/Home/Note';
import { CalendarScheduler } from 'components/Calendar/CalendarScheduler';
import {
  GetNextDosagesDTO,
  symptomControllerGetMySymptomByDay,
  UserSymptomDTO,
} from 'gen/client';
import { medicationControllerGetNextDosages } from './../gen/client';

const _Calendar = () => {
  const [date, setDate] = useState(moment().locale('gr'));
  const [symptoms, setSymptoms] = useState<UserSymptomDTO[]>([]);
  const [medication, setMedication] = useState<GetNextDosagesDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isAboveMd = useMediaQuery(theme.breakpoints.up('lg'));
  const isAboveXl = useMediaQuery(theme.breakpoints.up(1600));
  const isBelowMd = useMediaQuery(theme.breakpoints.down('sm'));
  const getAllRef = useRef(0);
  const getAll = () => {
    const getUserSymptoms = symptomControllerGetMySymptomByDay(
      date.format(DateFormats.ISO)
    );

    const getMedication = medicationControllerGetNextDosages({
      until: moment(date).format('MM-YYYY'),
      now: moment(date).startOf('day').format(DateFormats.ISO),
    });

    Promise.all([getUserSymptoms, getMedication]).then((values) => {
      setSymptoms(values[0].data);
      setMedication(values[1].data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (getAllRef.current > 1 && !loading) {
      getAll();
    } else {
      getAllRef.current = getAllRef.current + 1;
    }
  }, [date]);

  if (loading) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
  return (
    <Box>
      <Grid container style={{ flexDirection: 'column' }}>
        <Paper style={{ width: 'fit-content', marginTop: 50 }}>
          <Grid
            item
            xs={12}
            style={{
              width: 'fit-content',
              margin: isBelowXs ? 0 : '20px 50px',
            }}
          >
            <Box style={{ display: 'flex' }}>
              {!isBelowXs && (
                <Typography
                  style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}
                >
                  {t('Calendar')}
                </Typography>
              )}
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: isBelowXs ? 8 : 0,
                }}
              >
                <Box
                  className={classes.circle}
                  style={{ backgroundColor: '#2460CB' }}
                />
                <Typography
                  style={{
                    marginLeft: 8,
                    color: '#2460CB',
                    fontSize: isBelowXs ? 14 : 16,
                  }}
                >
                  {t('Medication')}
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '3%',
                }}
              >
                <Box
                  className={classes.circle}
                  style={{ backgroundColor: '#FE1010' }}
                />
                <Typography
                  style={{
                    marginLeft: 8,
                    color: Colors.Black,
                    fontSize: isBelowXs ? 14 : 16,
                  }}
                >
                  {t('OtherEvents')}
                </Typography>
              </Box>
            </Box>
            <CalendarScheduler onSelectDate={(date) => setDate(date)} />
          </Grid>
        </Paper>
        <Grid item xs={12} style={{ margin: '30px 0px' }}>
          <Typography
            style={{
              fontSize: isBelowXs ? 18 : 22,
              fontWeight: 600,
              color: Colors.Black,
            }}
          >
            {t('Date')}: {moment(date).format('DD MMM YYYY')}
          </Typography>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Paper>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '16px 0px',
                }}
              >
                <Typography
                  style={{
                    flexGrow: 1,
                    fontSize: isBelowXs ? 13 : 14,
                    fontWeight: 'bold',
                    paddingLeft: '5%',
                    textAlign: isBelowXs ? 'center' : 'initial',
                  }}
                >
                  {t('Medication')}
                </Typography>
                <Button
                  startIcon={<AddIcon style={isBelowXs ? { margin: 0 } : {}} />}
                  onClick={() => {
                    history.push('/medication');
                  }}
                  style={{
                    fontWeight: 'bold',
                    color: Colors.Black,
                    marginRight: '5%',
                    fontSize: isBelowXs ? 12 : 16,
                    visibility: medication.length > 0 ? 'initial' : 'hidden',
                  }}
                >
                  {t('AddMedication')}
                </Button>
              </Box>
              {medication.length === 0 ? (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '6%',
                  }}
                >
                  <Button
                    startIcon={
                      <AddIcon style={isBelowXs ? { margin: 0 } : {}} />
                    }
                    style={{ height: 'fit-content' }}
                    onClick={() => {
                      history.push('/medication');
                    }}
                  >
                    {t('AddMedication')}
                  </Button>
                </Box>
              ) : (
                <Box style={{ padding: '0% 5% 5% 5%' }}>
                  <NextMedication med={medication} date={date} />
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '16px 0px',
                }}
              >
                <Typography
                  style={{
                    flexGrow: 1,
                    fontSize: 14,
                    fontWeight: 'bold',
                    paddingLeft: '5%',
                    textAlign:
                      isBelowXs && symptoms.length > 0 ? 'center' : 'initial',
                  }}
                >
                  {t('Symptoms')}
                </Typography>
                <Button
                  startIcon={<AddIcon style={isBelowXs ? { margin: 0 } : {}} />}
                  onClick={() => {
                    history.push('/symptoms');
                  }}
                  style={{
                    display:
                      isBelowXs && symptoms.length === 0 ? 'none' : 'inherit',
                    fontWeight: 'bold',
                    color: Colors.Black,
                    marginRight: '5%',
                    fontSize: isBelowXs ? 12 : 16,
                    visibility: symptoms.length > 0 ? 'initial' : 'hidden',
                  }}
                >
                  {t('AddSymptom')}
                </Button>
              </Box>
              {symptoms.length === 0 ? (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '6%',
                  }}
                >
                  <Button
                    startIcon={
                      <AddIcon style={isBelowXs ? { margin: 0 } : {}} />
                    }
                    style={{ height: 'fit-content' }}
                    onClick={() => {
                      history.push('/symptoms');
                    }}
                  >
                    {t('AddSymptom')}
                  </Button>
                </Box>
              ) : (
                <Box style={{ padding: '0% 5% 5% 5%' }}>
                  {symptoms.map((item, i) => (
                    <Paper
                      key={i}
                      style={{
                        display: 'flex',
                        backgroundColor: '#F3F8FF',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        marginBottom: 16,
                      }}
                    >
                      <Typography
                        style={
                          symptoms[i].symptom.isBoolean === true
                            ? {
                                display: 'flex',
                                padding: 27,
                                paddingRight: 10,
                                color: Colors.Black,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                alignItems: 'center',
                              }
                            : {
                                display: 'flex',
                                padding: 27,
                                paddingRight: 10,
                                color: Colors.Black,
                                fontWeight: 'bold',
                                textAlign: 'center',
                                alignItems: 'center',
                              }
                        }
                      >
                        {item.symptom.name}
                      </Typography>
                      {symptoms[i].description.length > 0 && (
                        <Tooltip
                          title={symptoms[i].description}
                          // classes={{ tooltip: classes.customWidth }}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent:
                                isAboveXl || (isBelowMd && !isBelowXs)
                                  ? 'center'
                                  : 'flex-start',
                              flexGrow: 1,
                              paddingRight: 10,
                              maxWidth:
                                symptoms[i].symptom.isBoolean === true
                                  ? 250
                                  : 'none',
                            }}
                          >
                            <Box
                              style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: isBelowXs
                                  ? 80
                                  : isAboveMd
                                  ? 200
                                  : isBelowMd && !isBelowXs
                                  ? 250
                                  : 100,
                              }}
                            >
                              {symptoms[i].description}
                            </Box>
                          </Box>
                        </Tooltip>
                      )}
                      {symptoms[i].symptom.isBoolean === false && (
                        <Typography
                          style={{
                            marginRight: '3%',
                            padding: '5%',
                            paddingLeft: 0,
                            color: Colors.Black,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexGrow: 1,
                          }}
                        >
                          {symptoms[i].symptom.id !== 12
                            ? item.value.toString().concat('/10')
                            : item.value}
                        </Typography>
                      )}
                    </Paper>
                  ))}
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
        <Grid item sm={8} xs={12} md={5}>
          <Note date={date.format(DateFormats.ISO)} />
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  circle: {
    width: 12,
    height: 12,
    borderRadius: 12,
  },
}));
export const Calendar = hot(_Calendar);
