import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Typography,
  Dialog,
  Popover,
  Button,
} from '@material-ui/core';
import { Feedback1, Feedback2 } from 'components/Home/Feedback';
import { LatestMeasurement } from 'components/Home/LatestMeasurement';
import { NextMedication } from 'components/Home/NextMedication';
import { Note } from 'components/Home/Note';
import { WelcomeMessage } from 'components/Home/WelcomeMessage';
import { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { EmptyPage } from 'components/EmptyPage';
import Empty from 'assets/icons/measurementsEmpty.svg';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Colors } from 'res/Enums';
import { useTutorialContext } from 'context/TutorialContext';
import Nav from 'components/Nav';
import { Demo } from 'components/Demo';
import { useConfirm } from 'components/ConfirmDialogProvider';
import { toast } from 'react-toastify';
import {
  useMeasurementsControllerLatestMeasurements,
  useMeControllerUpdateMe,
  useMedicationControllerGetMyMedication,
  UserMeasurementDTO,
  UpdateUser,
  UserMedicationDTO,
  measurementsControllerLatestMeasurements,
} from './../gen/client';

interface HomeProps {
  tut?: number;
}
export const Home = ({ tut }: HomeProps) => {
  const [latest, setLatest] = useState<UserMeasurementDTO[]>([]);
  const theme = useTheme();
  const { me, getMe, openT, setOpenT, cont, setCont } = useTutorialContext();
  const [temp, setTemp] = useState(0);
  const inputEl = useRef<any | null>(null);
  const isBelowLg = useMediaQuery(theme.breakpoints.down(1500));
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
  const [med, setMed] = useState<UserMedicationDTO[] | null>(null);
  const confirm = useConfirm();
  const history = useHistory();
  const { t } = useTranslation();
  const { data: medicine } = useMedicationControllerGetMyMedication({
    activeMedication: true,
  });
  const [loading, setLoading] = useState(true);

  const updateMe = useMeControllerUpdateMe();
  const [e, setE] = useState('');

  const filterMed = async (medication: UserMedicationDTO[]) => {
    const data = medication.filter((e) =>
      moment(medication[0].nextTake?.time).isSame(
        moment(e.nextTake?.time),
        'hour'
      )
    );
    setMed(data);
  };

  useEffect(() => {
    if (medicine !== undefined && medicine.data) {
      filterMed(medicine.data);
    }
  }, [medicine]);

  useEffect(() => {
    const latestMeasurements = measurementsControllerLatestMeasurements();
    latestMeasurements.then((data) => {
      setLatest(data.data);
      setLoading(false);
    });
  }, []);

  const handleEnd = (tut: boolean) => {
    updateMe
      .mutateAsync({
        data: { ...(me as unknown as UpdateUser), tutorial: tut },
      })
      .then(() => {
        toast(t('TutEnd'));
        getMe();
      });
  };

  useEffect(() => {
    if (!me) getMe();
    setTemp(cont);
    setCont(cont + 1);
  }, []);

  useEffect(() => {
    if (openT === '2') setE('2');
    else setE('');
    if (openT === '1' && e === '2') {
      setTemp(0);
      setCont(cont + 1);
    }
  }, [openT]);

  if (!me) {
    return (
      <CircularProgress
        style={{ position: 'fixed', top: '50vh', left: '50vw' }}
        size={72}
      />
    );
  }
  return (
    <Fragment>
      <Grid
        container
        spacing={4}
        ref={inputEl}
        style={!!tut ? { padding: '0px 16px' } : {}}
      >
        <Grid item sm={12} md={5}>
          <Typography
            style={{ color: Colors.Black, padding: 24, paddingLeft: 0 }}
            // ref={inputEl}
          >
            {t('General')}
          </Typography>
          <Box
            style={{
              marginBottom: 32,
              display: 'flex',
              justifyContent: 'center',
              flex: '1 1 auto',
            }}
          >
            <WelcomeMessage me={me} />
          </Box>
          {med && <NextMedication med={med.length > 0 ? med : []} />}
          <Grid container spacing={1}>
            {isBelowLg && (
              <Grid item xs={12} style={{ display: 'flex', minWidth: 260 }}>
                <Feedback2 redirect TopMargin={0} />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              lg={!isBelowLg ? 7 : 12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <Feedback1 question={1} TopMargin={0} />
              <Feedback1 question={2} TopMargin={16} />
            </Grid>
            {!isBelowLg && (
              <Grid
                item
                sm={12}
                lg={!isBelowLg ? 5 : 12}
                style={{ display: 'flex' }}
              >
                <Feedback2 redirect TopMargin={0} />
              </Grid>
            )}
          </Grid>
          <Note />
        </Grid>
        <Grid item sm={12} md={7} style={{ width: '100%' }}>
          <Typography
            style={{ color: Colors.Black, padding: 24, paddingLeft: 0 }}
          >
            {t('LastMeasurement')}
          </Typography>
          {loading ? (
            <CircularProgress
              style={{ position: 'absolute', top: '40%', left: '50%' }}
              size={70}
            />
          ) : latest.length > 0 ? (
            <Fragment>
              <LatestMeasurement latest={latest[0]} />
              {latest.length > 1 && <LatestMeasurement latest={latest[1]} />}
            </Fragment>
          ) : (
            <Box
              style={{
                display: 'flex',
                alignSelf: 'center',
                // width: '-webkit-fill-available'
              }}
            >
              <EmptyPage
                onPress={() => history.push('/measurements')}
                text={t('Measurements')}
                text1={t('MeasurementEmptyText')}
                text2={t('AddMeasurement')}
                image={Empty}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      {me?.tutorial === false && parseInt(openT) < 7 && temp < 1 && (
        <Dialog
          open={!me.tutorial}
          disableBackdropClick
          BackdropProps={{
            style: { backgroundColor: 'black', opacity: 0.8 },
          }}
        >
          <Popover
            open={!me.tutorial && openT === '0'}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'center',
            }}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                WebkitTapHighlightColor: 'transparent',
                boxShadow: 'initial',
                marginLeft: isBelowMd ? '-8%' : '-16%',
                marginTop: isBelowMd ? '-16%' : '-8%',
                width: isBelowMd ? '80vw' : '33vw',
                textAlign: 'center',
              },
            }}
          >
            <Typography style={{ color: Colors.IceWhite, fontSize: 22 }}>
              {t('Tut1')}
            </Typography>
            <Typography
              style={{ color: Colors.IceWhite, fontSize: 20, marginTop: 8 }}
            >
              {t('Tut2')}
            </Typography>
            <Typography
              style={{ color: Colors.IceWhite, fontSize: 16, marginTop: 16 }}
            >
              {t('ExitMess')}
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  confirm({
                    title: t('TutSure'),
                    description: t('TutSure1'),
                    cancellationText: t('Cancel'),
                  }).then(() => {
                    handleEnd(true);
                    history.push('/success');
                  });
                }}
                style={{ marginTop: 16 }}
              >
                {t('Tut4')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenT('1')}
                style={{ marginTop: 16, marginLeft: 16 }}
              >
                {t('Tut3')}
              </Button>
            </Box>
          </Popover>
          <Popover
            open={openT === '1'}
            anchorEl={inputEl.current}
            PaperProps={{
              style: {
                overflow: 'hidden',
                width: isBelowLg ? '90vw' : isBelowMd ? '95vw' : '80vw',
                height: '80vh',
              },
            }}
          >
            <Home tut={cont} />
            <Popover
              open={openT === '1'}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  WebkitTapHighlightColor: 'transparent',
                  boxShadow: 'initial',
                  marginLeft: '-16%',
                  marginTop: '1%',
                  width: isBelowLg ? '70vw' : isBelowMd ? '90vw' : '45vw',
                  textAlign: 'center',
                },
              }}
            >
              <Typography style={{ color: Colors.IceWhite, fontSize: 20 }}>
                {t('Tut36')}
              </Typography>
              <Demo />
            </Popover>
          </Popover>
          <Popover
            open={
              (!me.tutorial && openT === '2') ||
              openT === '3' ||
              openT === '4' ||
              openT === '5' ||
              openT === '6'
            }
            PaperProps={{ style: { marginLeft: 234 } }}
          >
            <Nav tutorial={true} />
          </Popover>
        </Dialog>
      )}
    </Fragment>
  );
};
