import {
  Box,
  createStyles,
  makeStyles,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import clsx from 'clsx';
import { UserSymptomDTO } from 'gen/client';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, DateFormats } from 'res/Enums';

interface LatestSymptomProps {
  latest: UserSymptomDTO;
  style?: CSSProperties;
}
export const ShortInfo = ({ latest }: LatestSymptomProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className={classes.latestInfo}>
      {latest.symptom.isBoolean === false && (
        <Box
          className={clsx([classes.column, classes.valueBox])}
          style={isBelowXs ? { padding: 0 } : {}}
        >
          <Box
            style={{
              fontSize: isBelowXs ? 26 : 34,
              fontWeight: 'bold',
              minWidth: isBelowXs ? 60 : 80,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {latest.value}
          </Box>
        </Box>
      )}
      <Box
        className={classes.column}
        justifyContent="space-evenly"
        paddingLeft={2}
        style={isBelowXs ? { paddingLeft: 6 } : {}}
      >
        <Box style={{ fontSize: isBelowXs ? 16 : 17 }}>
          {latest.symptom.name}
        </Box>
        <Box style={{ fontSize: isBelowXs ? 14 : 16, color: 'gray' }}>
          {t('MeasurementAt')}
        </Box>
        <Box style={{ fontSize: isBelowXs ? 16 : 18, color: Colors.FadeBlack }}>
          {moment(latest.time).format(DateFormats.GR)}
        </Box>
        {latest.description !== '' &&
          (latest.description.length < 40 ? (
            <Box style={{ fontSize: 16, fontWeight: 'lighter', color: 'gray' }}>
              {t('Comments')}: {latest.description}
            </Box>
          ) : (
            <Tooltip
              title={t('FitnessComment').concat(latest.description)}
              classes={{ tooltip: classes.customWidth }}
            >
              <Box
                style={{ fontSize: 16, fontWeight: 'lighter', color: 'gray' }}
              >
                {t('Comments')}:
              </Box>
            </Tooltip>
          ))}
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
    },
    padd: {
      padding: theme.spacing(2),
      border: '1px solid #E3DFFA',
    },
    valueBox: {
      alignItems: 'center',
      padding: theme.spacing(3),
      borderRight: '2px solid #F5F6F7',
      color: Colors.FadeBlack,
      justifyContent: 'center',
    },
    latestInfo: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      display: 'flex',
      flexGrow: 1,
    },
    addButton: {
      backgroundColor: '#F5F6F7',
      border: ' 1px solid rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      borderRadius: 90,
      margin: '0px 10px',
      float: 'right',
      width: 'auto',
    },
    pressBox: {
      alignItems: 'center',
      color: Colors.FadeBlack,
      fontSize: 24,
      fontWeight: 'bold',
      margin: theme.spacing(1),
      textAlign: 'center',
    },
    customWidth: {
      maxWidth: 500,
    },
  })
);
