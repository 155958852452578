import {
  Box,
  Button,
  Paper,
  TextField,
  Theme,
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DateFormats, Colors } from 'res/Enums';
import {
  CreateNoteDto,
  useNoteControllerCreateMyNote,
  useNoteControllerGetMyNotes,
} from 'gen/client';

interface NoteProps {
  date?: string;
}

const _Note = ({ date }: NoteProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [note, setNote] = useState<CreateNoteDto>({
    text: '',
    time: date ?? moment().format(DateFormats.ISO),
  });
  const theme = useTheme();
  const isBelowXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { data, refetch } = useNoteControllerGetMyNotes({
    date: moment(!date ? undefined : date).format(DateFormats.ISONoTime),
  });
  const createMutation = useNoteControllerCreateMyNote();

  const saveNote = async () => {
    try {
      const r = await createMutation.mutateAsync({ data: note });
      if (r.status) {
        toast(t('NoteAdded'));
      } else toast(t('FailedAdding'), { containerId: 'deleted' });
    } catch (e) {}
  };

  useEffect(() => {
    if (date && data) {
      if (data.data.length > 0) setNote(data.data[0]);
      else setNote({ text: '', time: moment().format(DateFormats.ISO) });
    } else if (data) {
      if (data.data.length > 0)
        setNote({
          text: data.data[0].text,
          time: data.data[0].time,
          id: data.data[0].id,
        });
      else setNote({ text: '', time: moment(date).format(DateFormats.ISO) });
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [date]);

  return (
    <Paper className={classes.root}>
      <Typography
        style={{
          fontSize: 20,
          fontWeight: 'bold',
          color: Colors.Black,
          marginBottom: 16,
        }}
      >
        Σημειώσεις ημέρας
      </Typography>
      <TextField
        value={note?.text}
        multiline
        rowsMax={6}
        label={
          !date
            ? t('NoteOfDay').concat(
                moment().format(DateFormats.GrNoTime).toString()
              )
            : t('NoteOfDay').concat(
                moment(date).format(DateFormats.GrNoTime).toString()
              )
        }
        InputLabelProps={{ style: { fontSize: isBelowXs ? 14 : 20 } }}
        rows={2}
        onChange={(e) => setNote({ ...note, text: e.target.value.toString() })}
      />
      <Box textAlign="center" style={{ marginTop: 8 }}>
        <Button
          startIcon={<Save />}
          color="primary"
          variant="contained"
          onClick={saveNote}
          style={{ marginTop: 5 }}
        >
          {t('Save')}
        </Button>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
}));
export const Note = hot(_Note);
