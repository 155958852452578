import React, {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  useEffect,
} from 'react';
import { AuthStatus } from 'res/Enums';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import {
  authControllerGetGoogleRefreshToken,
  authControllerLogout,
  authControllerRefreshToken,
} from 'gen/client';
interface AuthContext {
  authStatus: AuthStatus;
  token?: string;
  login: (token: string, redirect?: boolean) => void;
  loginGoogle: (token: string, redirect?: boolean) => void;
  logout: () => void;
  registerEnd: () => void;
  googleRegister: () => void;
}
const AuthContext = createContext<AuthContext>({
  authStatus: AuthStatus.PENDING,
  login: () => {},
  loginGoogle: () => {},
  logout: () => {},
  registerEnd: () => {},
  googleRegister: () => {},
});
export const useAuthContext = () => useContext(AuthContext);
export const AuthContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.PENDING);
  const [token, setToken] = useState<string>();
  const history = useHistory();
  const login = async (token: string, redirect = false) => {
    setToken(token);
    if (redirect === false) setAuthStatus(AuthStatus.REGISTERED);
    else setAuthStatus(AuthStatus.LOGGED_IN);
    const decoded = jwt.decode(token) as {
      [key: string]: any;
    };
    if (!decoded.complete) {
      setAuthStatus(AuthStatus.GOOGLEREGISTERED);
      history.push('/registerneeded');
    } else if (redirect) history.push('/');
  };
  const loginGoogle = async (token: string, redirect = false) => {
    setToken(token);
    setTimeout(async () => {
      await authControllerGetGoogleRefreshToken().catch((e) =>
        console.error(e)
      );
      setAuthStatus(AuthStatus.LOGGED_IN);
      const decoded = jwt.decode(token) as {
        [key: string]: any;
      };
      if (!decoded.complete) {
        setAuthStatus(AuthStatus.GOOGLEREGISTERED);
        history.push('/registerneeded');
      } else if (redirect) history.push('/');
    }, 100);
  };

  const registerEnd = async () => {
    setAuthStatus(AuthStatus.LOGGED_IN);
    history.push('/');
  };

  const googleRegister = async () => {
    setAuthStatus(AuthStatus.REGISTERED);
    history.push('/registeroptional');
  };

  const logout = async () => {
    try {
      await authControllerLogout();
      history.push('/login');
      setToken(undefined);
      setAuthStatus(AuthStatus.LOGGED_OUT);
    } catch (e) {}
  };

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use((config) => {
      return {
        ...config,
        withCredentials: true,
        headers: token
          ? {
              ...config.headers,
              Authorization: `bearer ${token}`,
            }
          : config.headers,
      };
    });

    return () => {
      axios.interceptors.request.eject(interceptorId);
    };
  }, [token]);

  useEffect(() => {
    if (token) {
      const decoded = jwt.decode(token) as {
        [key: string]: any;
      };

      if (decoded && decoded.complete) {
        const diff = moment(decoded['exp'] * 1000).diff(moment(), 'ms') - 5000;
        const interval = setInterval(() => {
          authControllerRefreshToken()
            .then((resp) => {
              setToken(resp.data.token);
            })
            .catch(() => {
              setAuthStatus(AuthStatus.LOGGED_OUT);
              if (history.location.pathname !== '/reset')
                history.push('/login');
            });
        }, diff);
        return () => clearInterval(interval);
      }
    }
  }, [token]);

  useEffect(() => {
    if (!token) {
      setTimeout(() => {
        authControllerRefreshToken()
          .then((resp) => {
            setToken(resp.data.token);
            const decoded = jwt.decode(resp.data.token) as {
              [key: string]: any;
            };
            setAuthStatus(AuthStatus.LOGGED_IN);
            if (!decoded.complete) {
              setAuthStatus(AuthStatus.GOOGLEREGISTERED);
              history.push('/registerneeded');
            }
          })
          .catch(() => {
            setAuthStatus(AuthStatus.LOGGED_OUT);
            // if (history.location.pathname !== '/reset') {
            //   history.push('/login');
            //   console.log('hi i am here');
            // }
          });
      }, 1000);
    } else {
      setAuthStatus(AuthStatus.LOGGED_IN);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authStatus,
        token,
        login,
        logout,
        loginGoogle,
        registerEnd,
        googleRegister,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
