import { CSSProperties } from '@material-ui/styles';
import React, { PropsWithChildren } from 'react';
import { hot } from 'react-hot-loader/root';
import { Bar, BarChart, BarChartProps, ResponsiveContainer } from 'recharts';
import { Colors } from 'res/Enums';

interface StyledBarChartProps extends BarChartProps {
  aspect?: number;
  dataKey: string;
  dataKey2?: string;
  width?: number;
}
const _StyledBarChart = ({
  style,
  children,
  aspect,
  dataKey2,
  width,
  ...rest
}: PropsWithChildren<StyledBarChartProps>) => {
  return (
    <ResponsiveContainer width="100%" aspect={aspect ?? 2}>
      <BarChart barSize={width} {...rest} style={{ ...styles, ...style }}>
        {children}
        <Bar dataKey={rest.dataKey} fill={Colors.PRIMARY} />
        {dataKey2 && <Bar dataKey={dataKey2} fill="#9FC131" />}
      </BarChart>
    </ResponsiveContainer>
  );
};

const styles: CSSProperties = {};
export const StyledBarChart = hot(_StyledBarChart);
